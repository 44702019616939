import _ from "lodash";
import * as config from "../config";
import authService from "../BookingBugServices/LoginService";

class ResourceService {

  async getServices() {
    const companyId = `${config.COMPANY_ID}`;
    const authToken = authService.getToken();

    const url = `${config.BOOKING_BUG_API}/${companyId}/services`;
    var reqHeaders = new Headers();
    reqHeaders.append("Content-Type", "application/json");
    reqHeaders.append("App-Key", config.APP_KEY);
    reqHeaders.append("App-Id", config.APP_ID);
    reqHeaders.append("Auth-Token", authToken);
    const response = await fetch(url, {
      method: "GET",
      headers: reqHeaders
    });

    if (!response.ok) {
      //config.errorPageRedirect(response);
      throw new Error(
        `ResourcesService getServices failed, HTTP status ${
        response.status
        }`
      );
    }

    const data = await response.json();

    return _.get(data, "_embedded.services");

  }

  async getResourceServices(serviceId, startDate, endDate) {
    const companyId = `${config.COMPANY_ID}`;
    const authToken = authService.getToken();

    const url = `${
      config.BOOKING_BUG_API
      }/${companyId}/time_data?service_id=${serviceId}&date=${startDate}&end_date=${endDate}`;

    var reqHeaders = new Headers();
    reqHeaders.append("Content-Type", "application/json");
    reqHeaders.append("App-Key", config.APP_KEY);
    reqHeaders.append("App-Id", config.APP_ID);
    reqHeaders.append("Auth-Token", authToken);
    // reqHeaders.append("Cache-Control", "no-cache");

    const response = await fetch(url, {
      method: "GET",
      headers: reqHeaders
    });

    if (!response.ok) {
      //config.errorPageRedirect(response);
      throw new Error(
        `ResourcesService getResourceSlots failed, HTTP status ${
        response.status
        }`
      );
    }

    const data = await response.json();

    return _.get(data, "_embedded.events");
  }

  async getBookings(start_date, end_date) {
    const companyId = `${config.COMPANY_ID}`;
    const authToken = authService.getToken();

    const url = `${config.BOOKINGBUG_ADMIN_ENDPOINT}/${companyId}/bookings`;

    var reqHeaders = new Headers();
    reqHeaders.append("Content-Type", "application/json");
    reqHeaders.append("App-Key", config.APP_KEY);
    reqHeaders.append("App-Id", config.APP_ID);
    reqHeaders.append("Auth-Token", authToken);
    // reqHeaders.append("Cache-Control", "no-cache");

    const response = await fetch(
      url + "?start_date=" + start_date + "&end_date=" + end_date,
      {
        method: "GET",
        headers: reqHeaders
      }
    );

    if (!response.ok) {
      //config.errorPageRedirect(response);
      throw new Error(
        `ResourcesService getBookings failed, HTTP status ${response.status}`
      );
    }

    const data = await response.json();

    return _.get(data, "_embedded.bookings");
  }

  async getBookingCancelReasons() {
    //implementation would have to retrieve reasons from concierge api configuration
    //ie umbraco back office
    const result = [
      { value: 1, label: "Not needed" },
      { value: 2, label: "Wrong Booking" },
      { value: 3, label: "Meeting canceled" }
    ];

    return await result;
  }

  async cancelBooking(id, reason) {
    const companyId = `${config.COMPANY_ID}`;
    const authToken = authService.getToken();

    const url = `${
      config.BOOKINGBUG_ADMIN_ENDPOINT
      }/${companyId}/bookings/${id}/cancel`;

    let data = {
      notify: false,
      cancel_reason: reason
    };
    var reqHeaders = new Headers();
    reqHeaders.append("Content-Type", "application/json");
    reqHeaders.append("App-Key", config.APP_KEY);
    reqHeaders.append("App-Id", config.APP_ID);
    reqHeaders.append("Auth-Token", authToken);
    reqHeaders.append("Cache-Control", "no-cache");

    const response = await fetch(url, {
      method: "POST",
      headers: reqHeaders,
      body: JSON.stringify(data)
    });

    if (!response.ok) {
      throw new Error(
        `ResourcesService cancelBookings failed, HTTP status ${response.status}`
      );
    }

    const result = await response.json();

    return result;
  }

  async updateBooking(id, date, time, duration) {
    const companyId = `${config.COMPANY_ID}`;
    const authToken = authService.getToken();

    const url = `${
      config.BOOKINGBUG_ADMIN_ENDPOINT
      }/${companyId}/bookings/${id}`;

    let data = {
      date: date,
      time: time,
      duration: duration,
      notify: false
    };
    var reqHeaders = new Headers();
    reqHeaders.append("Content-Type", "application/json");
    reqHeaders.append("App-Key", config.APP_KEY);
    reqHeaders.append("App-Id", config.APP_ID);
    reqHeaders.append("Auth-Token", authToken);
    reqHeaders.append("Cache-Control", "no-cache");

    const response = await fetch(url, {
      method: "PUT",
      headers: reqHeaders,
      body: JSON.stringify(data)
    });

    if (!response.ok) {
      throw new Error(
        `ResourcesService updateBookings failed, HTTP status ${response.status}`
      );
    }

    const result = await response.json();

    return result;
  }

  async updateBookingResource(id, data) {
    const companyId = `${config.COMPANY_ID}`;
    const authToken = authService.getToken();

    const url = `${
      config.BOOKINGBUG_ADMIN_ENDPOINT
      }/${companyId}/bookings/${id}`;

    // let data = {
    //   date: date,
    //   time: time,
    //   duration: duration,
    //   notify: false
    // };
    var reqHeaders = new Headers();
    reqHeaders.append("Content-Type", "application/json");
    reqHeaders.append("App-Key", config.APP_KEY);
    reqHeaders.append("App-Id", config.APP_ID);
    reqHeaders.append("Auth-Token", authToken);
    reqHeaders.append("Cache-Control", "no-cache");

    const response = await fetch(url, {
      method: "PUT",
      headers: reqHeaders,
      body: JSON.stringify(data)
    });

    if (!response.ok) {
      throw new Error(
        `ResourcesService updateBookingResource failed, HTTP status ${response.status}`
      );
    }

    const result = await response.json();

    return result;
  }
  async timeAvailability(serviceId, date) {
    const companyId = `${config.COMPANY_ID}`;
    const authToken = authService.getToken();

    const url = `${
      config.BOOKING_BUG_API
      }/${companyId}/time_data?service_id=${serviceId}&date=${date}`;

    var reqHeaders = new Headers();
    reqHeaders.append("Content-Type", "application/json");
    reqHeaders.append("App-Key", config.APP_KEY);
    reqHeaders.append("App-Id", config.APP_ID);
    reqHeaders.append("Auth-Token", authToken);
    reqHeaders.append("Cache-Control", "no-cache");

    const response = await fetch(url, {
      method: "GET",
      headers: reqHeaders
    });

    if (!response.ok) {
      throw new Error(
        `ResourcesService timeAvailability failed, HTTP status ${
        response.status
        }`
      );
    }

    const data = await response.json();

    return _.get(data, "_embedded.events");
  }

  async getBookingErrors() {
    const response = [
      { value: 1, label: "Time exceeds allowed booking" },
      { value: 2, label: "Cancellation time limit has passed" },
      { value: 3, label: "Time selected not available" }
    ];

    return await response;
  }

  async getBookingDetails(memberId, bookingId) {
    const companyId = `${config.COMPANY_ID}`;
    const authToken = authService.getToken();

    const url = `${
      config.BOOKING_BUG_API
      }/${companyId}/members/${memberId}/bookings/${bookingId}`;

    var reqHeaders = new Headers();
    reqHeaders.append("Content-Type", "application/json");
    reqHeaders.append("App-Key", config.APP_KEY);
    reqHeaders.append("App-Id", config.APP_ID);
    reqHeaders.append("Auth-Token", authToken);
    reqHeaders.append("Cache-Control", "no-cache");

    const response = await fetch(url, {
      method: "GET",
      headers: reqHeaders
    });

    if (!response.ok) {
      throw new Error(
        `ResourcesService timeAvailability failed, HTTP status ${
        response.status
        }`
      );
    }

    const data = await response.json();

    return data;
  }

  async getBookingQuestions(groupId) {
    const companyId = `${config.COMPANY_ID}`;
    const authToken = authService.getToken();


    const url = `${
      config.BOOKING_BUG_API
      }/${companyId}/questions?detail_group_id=${groupId}`;

    var reqHeaders = new Headers();
    reqHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    reqHeaders.append("App-Key", config.APP_KEY);
    reqHeaders.append("App-Id", config.APP_ID);
    reqHeaders.append("Auth-Token", authToken);

    const response = await fetch(
      url,
      {
        method: "GET",
        headers: reqHeaders
      }
    );

    if (!response.ok) {
      //config.errorPageRedirect(response);
      throw new Error(
        `ResourcesService getBookingQuestions failed, HTTP status ${response.status}`
      );
    }

    const data = await response.json();

    return _.get(data, "questions");
  }

  async blockResource(resourceId, start_date, end_date) {
    const companyId = `${config.COMPANY_ID}`;
    const authToken = authService.getToken();

    const url = `${
      config.BOOKINGBUG_ADMIN_ENDPOINT
      }/${companyId}/resources/${resourceId}/block?start_time=${start_date}&end_time=${end_date}`;

    // let data = {
    //   start_time: start_date,
    //   end_time: end_date
    // };
    var reqHeaders = new Headers();
    reqHeaders.append("Content-Type", "application/json");
    reqHeaders.append("App-Key", config.APP_KEY);
    reqHeaders.append("App-Id", config.APP_ID);
    reqHeaders.append("Auth-Token", authToken);
    reqHeaders.append("Cache-Control", "no-cache");


    const response = await fetch(url, {
      method: "PUT",
      headers: reqHeaders,
      //body: JSON.stringify(data)
    });

    if (!response.ok) {
      throw new Error(
        `ResourcesService blockResource failed, HTTP status ${response.status}`
      );
    }

    const result = await response.json();

    return result;
  }

  /**Engage bookingbug api start */

}

export default new ResourceService();
