import React, { Component } from "react";
import { connect } from "react-redux";
import HeaderNavigation from "../../components/HeaderNavigation/HeaderNavigation";
import FooterNavigation from "../../components/FooterNavigation/FooterNavigation";
import Button from "../../components/Button/Button";
import { createBrowserHistory } from 'history';
import * as authActions from "../../store/auth/actions";
import "../../sass/containers/ErrorScreen/index.css";


const browserHistory = createBrowserHistory();
const location = browserHistory.location;
class ErrorScreen401 extends Component {
    constructor(props) {
        super(props);


    }

    componentDidMount() {
      
         setTimeout(() => {
          
            this.props.dispatch(authActions.logout());
            window.location.reload();
           
        }, 100);

       
    }
handleClick(e){
    browserHistory.push({"pathname" : "/"});

    setTimeout(() =>{
        window.location.reload();
    }, 1000);
   

}
    render() {

        return (
            <div>


                <div className="container transparent type-plain-header-block">
                    <div style={{ width: 100 + "%", height: 272 + "px" }}>

                    </div>
                    <h1 className="align-center enlarged">#401</h1>

                </div>

                <div className="white-background">
                    <div className="container">

                        <div className="content-wrapper">

                            <h2>UnAuthorized</h2>

                            <h3 className="brownish-grey align-center">Sorry, but it looks like your account is <br />un-authosized to process this page.</h3>

                        </div>

                        <div className="align-center">
                            <Button
                                handleClick={e => this.handleClick(e)}
                                text="Back To Home"
                                size="medium" />
                        </div>

                        <FooterNavigation />
                    </div>
                </div>

            </div>


        );
    }
}

function mapStateToProps(state) {
    return {};
  }
  export default connect(mapStateToProps)(ErrorScreen401);
