import React, { Component } from "react";
import { connect } from "react-redux";
import Autosuggest from "react-autosuggest";
import InputComponent from "../../components/Input/Input";

import * as addrLookupActions from "../../store/AddressLookUp/actions";
import * as addrLookupSelectors from "../../store/AddressLookUp/reducer";
import _ from "lodash";
import autoBind from "react-autobind";
import * as $ from "jquery";
var PropTypes = require('prop-types');

class AddrLookUpAutoSuggest extends Component {
  constructor(props) {
    super(props);

    this.defaultState = {
      value: "",
      suggestions: [],
      valid: false
    };

    this.state = this.defaultState;
    this.props.dispatch(addrLookupActions.loadAddresses());
    this.emptyResponse = {
      addresslookupId: "",
      address: "",
      valid: false
    };

    this.selectedAddressFromDefault = false;

    autoBind(this);
  }

 
  componentDidUpdate(prevProps, pervState){
	/** the will reset back to default on subsequet defaultTenantId modified */
  if (this.props.defaultAddressId !== prevProps.defaultAddressId && !_.isUndefined(this.props.defaultAddressId)) {
    //this.selectedTenantFromDefault = true;
    const startAddress = _.find(this.props.addresses, (address) => {
      return address.id === Number(this.props.defaultAddressId);
    });
    if (!_.isUndefined(startAddress)) {
      this.handleAddressSelected(startAddress);
      this.setState({
        value:
          startAddress.addr1FlatNumber + `${startAddress.addr2BuildingName ? " " + startAddress.addr2BuildingName : ""}` + `${startAddress.addr3StreetNumber ? " " + startAddress.addr3StreetNumber : ""}` + `${startAddress.addr4Town ? " " + startAddress.addr4Town : ""}`,
        valid: true
      });

    
    }
  }

  if (
    this.props.addresses.length > 0 &&
    !_.isUndefined(this.props.defaultAddressId) &&
    this.selectedAddressFromDefault === false
  ) {
    this.selectedAddressFromDefault = true;

    const startAddress = this.props.addresses.find(address => {
      return address.id === Number(this.props.defaultAddressId);
    });

    if (!_.isUndefined(startAddress)) {
      this.handleAddressSelected(startAddress);

      this.setState({
        value:
          startAddress.addr1FlatNumber + `${startAddress.addr2BuildingName ? " " + startAddress.addr2BuildingName : "" }` + `${startAddress.addr3StreetNumber ? " " + startAddress.addr3StreetNumber : ""}` + `${startAddress.addr4Town ? " " + startAddress.addr4Town : ""}`,
        valid: true
      });
    }
  }
}


  reset() {
    this.setState(this.defaultState);
  }

  // Autosuggest functions ++++++++++++++++++++++++++++++++++++++++++
  getSuggestionValue(suggestion) {
    this.handleAddressSelected(suggestion);
    if (suggestion) this.setState({ disabled: "" });

    let address = "";
    if(suggestion.addr1FlatNumber)
    address = suggestion.addr1FlatNumber;
    if(suggestion.addr2BuildingName)
    address += `${" "}${suggestion.addr2BuildingName}`
    if(suggestion.addr3StreetNumber)
    address += `${" "}${suggestion.addr3StreetNumber}`
    if(suggestion.addr4Town)
    address += `${" "}${suggestion.addr4Town}`

    return address;
  }

  getSuggestions(val) {
    const { addresses } = this.props;
    if (!addresses) return [];

    const value = val.trim().toLowerCase();
		const parts = value.split(' ').filter((v) => {
			return v !== '';
    });
    
 
    const match = _.filter(addresses, o => {
       return  (o.addr1FlatNumber && _.includes(o.addr1FlatNumber.toLowerCase(), value.trim().toLowerCase())  ||
                o.addr2BuildingName && _.includes(o.addr2BuildingName.toLowerCase(), value.trim().toLowerCase()) || 
                o.addr3StreetNumber && _.includes(o.addr3StreetNumber.toLowerCase(), value.trim().toLowerCase()) ||
                o.addr4Town && _.includes(o.addr4Town.toLowerCase(), value.trim().toLowerCase()) || 
                o.addr5County && _.includes(o.addr5County.toLowerCase(), value.trim().toLowerCase()) ||
                o.postCode && _.includes(o.postCode.toLowerCase(), value.trim().toLowerCase())
                );
      // let numSearch = _.startsWith(
      //   o.addr1FlatNumber.toLowerCase(),
      //   val.toLowerCase()
      // );

      // if (numSearch === false) {
      //   let buildingSearch = _.includes(
      //     o.addr2BuildingName.toLowerCase(),
      //     val.toLowerCase()
      //   );

      //   if (buildingSearch === true) {
      //     return buildingSearch;
      //   }
      // }
      // return numSearch;
    });

    return match;
  }

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({ suggestions: this.getSuggestions(value) });
  };

  onSuggestionsClearRequested = () => {};

  onChange = (event, { newValue }) => {
    if (newValue.length === 0) this.setState({ address: undefined });
    if (newValue.length >= 1) {
      this.handleAddressChange(newValue);
    }

    this.setState({
      value: newValue
    });

    setTimeout(() => {
      // Delayed because parseley not ready if user selected a suggestion

      this.setState({
        valid: $(this.refs.autosuggest.input)
          .parsley()
          .isValid()
      });
    }, 0);

    //this.checkMatch(newValue);
  }

  handleAddressSelected(addr, setValue = false) {
    const stateChanges = {
      addresslookupId: addr.id,

      address: addr.addr1FlatNumber + `${addr.addr2BuildingName ? " " + addr.addr2BuildingName : ""}` + `${addr.addr3StreetNumber ? " " + addr.addr3StreetNumber : ""}` + " " + addr.addr4Town,
      valid: !_.isUndefined(addr) ? true : false,
      ...(setValue
        ? {
            value: addr.addr1FlatNumber + `${addr.addr2BuildingName ? " " + addr.addr2BuildingName : ""}` + `${addr.addr3StreetNumber ? " " + addr.addr3StreetNumber : ""}` + " " + addr.addr4Town,
            valid: true
          }
        : {})
    };

    this.setState(stateChanges);

    this.props.onSelected && this.props.onSelected(stateChanges);
  }

  handleAddressChange(value) {
    this.setState({
      address: value
    });
  }
  renderSuggestion(suggestion) {

    return (
      <div className="Select-option">
        {suggestion.addr1FlatNumber}
        {suggestion.addr2BuildingName ? " " + suggestion.addr2BuildingName : ""}
        {suggestion.addr3StreetNumber ? " " + suggestion.addr3StreetNumber : ""}{" "}
        {suggestion.addr4Town}
      </div>
    );
  }

  handleSuggestionSelected(suggestion, suggestionValue, suggestionIndex, sectionIndex, method) {
    
		this.handleAddressSelected(suggestion);
	}

  checkMatch(currentValue) {
    
    const value = currentValue.toLowerCase();
    this.props.defaultText(currentValue);
    const parts = value.split(" ").filter(value => {
      return value !== "";
    });

    const matchedAddress = _.find(this.props.addresses, address => {
     
      if ( parts.length >= 1  &&  
        ( _.startsWith(address.addr1FlatNumber.toLowerCase(), value.trim().toLowerCase()) ||  
          _.startsWith(address.addr3StreetNumber.toLowerCase(),  value.trim().toLowerCase()) 
          )
              //  ( address.addr2BuildingName && _.includes(address.addr2BuildingName.toLowerCase(), value.trim().toLowerCase())) || 
              //   (address.addr3StreetNumber && _.includes(address.addr3StreetNumber.toLowerCase(), value.trim().toLowerCase())) ||
              //   (address.addr4Town && _.includes(address.addr4Town.toLowerCase(), value.trim().toLowerCase())) || 
              //   (address.addr5County && _.includes(address.addr5County.toLowerCase(), value.trim().toLowerCase())) ||
              //   (address.postCode && _.includes(address.postCode.toLowerCase(), value.trim().toLowerCase()))
                
      ) {
        return true;
      }
      // else if (
      //   parts.length === 2 &&
      //   _.includes(address.addr2BuildingName, parts[1].toLowerCase())
      // ) {
      //   return true;
      // }

      return false;
    });

    //console.log(matchedAddress);
   
    if (_.isUndefined(matchedAddress) === false) {
      this.handleAddressSelected(matchedAddress, true);
    } else {
      this.setState({
        valid: false
      });

      this.props.onSelected && this.props.onSelected(this.emptyResponse);
    }
  }

  inputClick() {
    if (
      this.state.valid === true &&
      !_.isUndefined(this.props.clearableIfValid) &&
      this.props.clearableIfValid === true
    ) {
      this.reset();

      this.props.onSelected && this.props.onSelected(this.emptyResponse);
    }
  }

  render() {
    const { value, suggestions, valid } = this.state;
    const { label, placeHolder } = this.props;
    const disabled =
      _.isUndefined(this.props.disabled) || this.props.disabled === false
        ? false
        : true;

    const inputProps = {
      placeholder: "Address",
      value,
      onChange: this.onChange,
      onClick: this.inputClick
    };

  
    return (
      <Autosuggest
        ref="autosuggest"
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={this.getSuggestionValue}
        onSuggestionSelected={() => {}}
        onSuggestionSelected={(event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) =>
					this.handleSuggestionSelected(suggestion, suggestionValue, suggestionIndex, sectionIndex, method)}
        renderSuggestion={this.renderSuggestion}
        inputProps={inputProps}
        onBlur={(event, { highlightedSuggestion }) => {
					console.log(highlightedSuggestion);
				}}
        theme={{
          // maybe make global for reuse at some point
          container:
            "auto-suggest Select " +
            (!_.isUndefined(this.props.clearableIfValid) &&
            this.props.clearableIfValid === true
              ? "is-clearable"
              : ""),
          suggestionsList: "Select-menu-outer",
          suggestion: "Select-menu"
        }}
        renderInputComponent={inputProps => {
          const iconProp =
            !_.isUndefined(this.props.clearableIfValid) &&
            this.props.clearableIfValid === true &&
            valid === true
              ? { icon: "ion-close-round" }
              : {};

          return (
            <InputComponent
              autoSuggestProps={inputProps}
              label={!_.isUndefined(label) ? label : "Address"}
              //name="tenant"
              placeHolder={
                !_.isUndefined(placeHolder)
                  ? placeHolder
                  : "Enter address details"
              }
              name="address"
              disabled={disabled}
              value={this.state.value}
              onChange={inputProps.onChange}
              onClick={inputProps.onClick}
              onBlur={e => {
                /* Ensures that member ID fetched even if not selected via autosuggest */

              // this.checkMatch(e.target.value);
              }}
              parsley={{
                "data-parsley-matches-one-of": JSON.stringify({
                  allSuggestions: this.props.addresses.map(address => {
                    return {
                      value:
                        address.addr1FlatNumber +
                        
                        `${address.addr2BuildingName ? " " + address.addr2BuildingName : ""}` +
                        `${address.addr3StreetNumber ? " " + address.addr3StreetNumber : ""}` + " " + address.addr4Town

                    };
                  })
                }),
                //"data-parsley-type": "alphanum",
                "data-parsley-minwords": 1,
                "data-parsley-required": true,
                "data-parsley-error-message":
                  "Please enter a valid tenant address"
              }}
              {...iconProp}
            />
          );
        }}
      />
    );
  }
}

AddrLookUpAutoSuggest.propTypes = {
  /* Optional initial Address id to start with, (used when navigating back etc...) */
  defaultAddressId: PropTypes.number,
  /* Callback function for when item is selected */
  onSelected: PropTypes.func,
  /* Optional disable the input field */
  disabled: PropTypes.bool,
  /* Specifies if a X icon shows and the input field becomes clickable if the autosuggest is valid */
  clearableIfValid: PropTypes.bool,
  /** Optional label text*/
  label: PropTypes.string,
  /** Optional PlaceHolder Text */
  placeHolder: PropTypes.string
};

function mapStateToProps(state) {
  return {
    addresses: addrLookupSelectors.getAddresses(state)
  };
}

//export default connect(mapStateToProps)(AddrLookUpAutoSuggest);
export default connect(mapStateToProps, null, null, { forwardRef: true })(
  AddrLookUpAutoSuggest
);
