import React, { Component } from "react";

import { connect } from "react-redux";
import autoBind from "react-autobind";
import dateFormat from "dateformat";
import _ from "lodash";

import BigCalendar from "react-big-calendar";
import moment from "moment";
import {withRouter} from 'react-router-dom';
import * as CyptoHelper from "../../utility/cryptoUtility";

import CustomDayWrapper from "../CalendarCustomComponents/CustomDayWrapper";

import Week from "react-big-calendar/lib/Week";
//import WorkWeek from "react-big-calendar/lib/WorkWeek";
import TimeGrid from "react-big-calendar/lib/TimeGrid";
import dates from "react-big-calendar/lib/utils/dates";
import localizer from "react-big-calendar/lib/localizer";
import EventCell from "react-big-calendar/lib/EventCell";

import EventModal from "../ariaModals/EventModal";
import CustomEventWrapper from "../CalendarCustomComponents/CustomEventWrapper";

import * as resourceSelector from "../../store/Resources/reducer";
import * as resourceActions from "../../store/Resources/actions";
//import * as serviceModuleActions from "../../store/ServiceModules/actions";
import * as serviceModuleSelectors from "../../store/ServiceModules/reducer";

import * as resourceMonthlyActions from "../../store/ResourcesMonthly/actions";
import * as resourceMonthlySelectors from "../../store/ResourcesMonthly/reducer";
import * as tenantSelectors from "../../store/Tenants/reducer";
import * as checkoutSelectors from "../../store/Checkout/reducer";

import "../../sass/components/BigCalendar/react-big-calendarMonthly.css";

var PropTypes = require('prop-types');
BigCalendar.setLocalizer(BigCalendar.momentLocalizer(moment));



function dayWrapperClicked() { }

const calendarViews = {
  Day: "day",
  Week: "week",
  WorkWeek: "work_week",
  Month: "month"
};
const ServiceUnitType = {
  Day: "day",
  Minute: "minute"
};

const ORDER_KEY = "order_key";

//test template for cards, some settings can be moved into css styling for each cards, see sample class included
const custom_cards = [
  { card: 0, color: "#ffffff", border: "#009cc4", borderPast: "#7fcee2" },
  { card: 1, color: "#0ea610", border: "#0ea610", borderPast: "#87d388" },
  { card: 2, color: "#fa4b2e", border: "#fa4b2e", borderPast: "#fda597" },
  { card: 3, color: "#009cc4", border: "#009cc4", borderPast: "#7fcee2" }];

const CURRENT_DATE = moment().toDate();
const MAX_DATE_BOOKINGS = 7;
const customDateCellWrapper = ({ range, value, children }) => {
  var isToday = moment(value).format("YYYY-MM-DD") === moment(CURRENT_DATE).format("YYYY-MM-DD");

  if (moment(value).toDate() === CURRENT_DATE) { }
  return (
    <div className={isToday === true ? "rbc-now" : ""}>
      {children}
    </div>
  )
  // return {
  //   style: {
  //     ...children.style
  //   }
  // }
  // return React.cloneElement(Children.only(children), {
  //   style: {
  //     ...children.style,
  //     color: '#009cc4'
  //   },
  // });
}


const myCustomDayWrapper = () => (
  <CustomDayWrapper handleClick={dayWrapperClicked} />
);
function eventPropsGetter(event, start, end, isSelected) {

  if (!event.isAvailable && !event.inBasket && _.isUndefined(event.booked) && _.isUndefined(event.blocked)) {
    return {
      style: {
        backgroundColor: "rgba(255,255,255,0)",
        border: 0,
        borderRadius: 0,
        zIndex: 0,

      }
    };
  } else if (event.inBasket && !event.isAvailable) {
    return {
      style: {
        backgroundColor: "#AEDBBC ",
        border: 0,
        borderRadius: 0
      }
    };
  } else if (event.isAvailable) {
    return {
      style: {
        backgroundColor: "#FFFFFF ",
        borderBottom: "0.9px solid #d9d9d9",
        borderTop: 0,
        borderLeft: 0,
        borderRight: 0,
        borderRadius: 0,
        opacity: 0,
        color: "#ffffff"
      }
    };
  } else if (
    !event.isAvailable &&
    !_.isUndefined(event.booked) &&
    event.booked
  ) {
    let current_card = _.find(custom_cards, { card: event.card })
    return {
      className: !_.isUndefined(event.card) ? event.past === true ? "card--" + event.card + " event past" : "card--" + event.card + " event" : "event", //provide special class styling attribute based on card no ie, inner text, headers etc
      style: {
        border: "0px solid #ffffff",
        borderLeft: !_.isUndefined(current_card) ? "solid " + 2 + "px " + (!_.isUndefined(event.card) && event.past === true ? current_card.borderPast : current_card.border) : ""

      }
    };
  } else if (!event.isAvailable
    && !_.isUndefined(event.blocked) && event.blocked) {
    let current_card = _.find(custom_cards, { card: event.blocked_card });

    return {
      className: event.past === true ? "card--blocked past" : "card--blocked",
      style: {
        border: "0px solid #ffffff",
        borderLeft: !_.isUndefined(current_card) ? "solid " + 2 + "px " + current_card.border : ""
      }
    }
  }
}

// function slotPropGetter(date) {
//   return {
//     style: {
//       backgroundColor: "#AEDBBC",
//       border: 0,
//       borderRadius: 0
//     }
//   };
// }


const CustomEventCell = (event) => {
  return (
    <EventCell
      eventWrapperComponent={CustomEventWrapper}
      event={event}
    />
  );
}

const customDayPropGetter = (date) => {
 
  if (moment(date).toDate() === CURRENT_DATE)
    return {
      className: 'special-day',
      style: {
        color: '#009cc4'
      }
    };
  else return {};
}

class MyWeek extends Week {
  handleNav(date, action) { }

  render() {
    let { date } = this.props;
    let { start, end } = MyWeek.range(date, this.props);

    return (
      <TimeGrid {...this.props} start={start} end={end} eventOffset={18} />
    );
  }
}

MyWeek.range = (date, { culture }) => {
  let firstOfWeek = localizer.startOfWeek(culture);
  firstOfWeek = new Date().getDay() - 1;
  let start = dates.startOf(date, "week", firstOfWeek);
  let end = dates.endOf(date, "week", firstOfWeek);

  if (firstOfWeek === 1) {
    end = dates.subtract(end, 2, "day");
  } else {
    start = dates.add(start, 1, "day");
    end = dates.add(end, 1, "day");
  }

  return {
    start,
    end
  };
};
class BookingCalendarCustom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timeSlot: 1,
      modal: "",
      bookingId: 0,
      date: "" /*date string xxxx-xx-xx */,
      time: "" /*time specified in 24hr A  (11:00 AM) */,
      duration: "" /* duration calculated in minutes */,
      selectedBookedSlot: undefined,
      error: "",
      booking_action_valid: false,
      min_cancel_time: moment(),
      slots: [],
      cancelledBooking: undefined,
      bookingUpdated: undefined,
      type2: "",
      current_nav_view: "Today",
      switchActive: "",
      open: false,
      slotSelected: undefined,
      eventsResourceSelected: this.props.selectedEventResources,
      selectedSartDate: undefined,
      selectedEndDate: undefined,
      selectedSlotTimes: [],
      slotDataRefreshed: false,
      selectedTenant: undefined,
      basketQue: [],
      paymentStatus: undefined,
      max_day_bookings: MAX_DATE_BOOKINGS

    };

    autoBind(this);

    this.props.dispatch(resourceActions.getBookingsCancelReasons());
    this.props.dispatch(resourceActions.getBookingErrors());
  }

  handleCloseClick(event, modalActive) {
    this.setState({ modal: "", modalActive: false });

    if (this.props.fromRedirect === true) {
      this.props.modalActionEvent("payment", this.props.paymentAction);
    }
  }

  getApplicationNode = () => {
    return document.getElementById("bookingApp");
  };


  modalActionCompleted(action, valid) {

    this.props.modalActionEvent(action, valid);

    if (valid === true)
      setTimeout(() => {
        if (
          action === "delete" &&
          !_.isUndefined(this.state.cancelledBooking)
        ) {
          this.props.dispatch(
            resourceActions.nofifyCancelBooking(
              this.state.cancelledBooking.booking
            )
          );
        } else if (
          action === "update" &&
          !_.isUndefined(this.state.bookingUpdated)
        ) {

          if (this.state.bookingId === this.state.bookingUpdated.id)
            this.props.dispatch(
              resourceActions.notifyEditBooking(this.state.bookingUpdated.id)
            );
        } else if (action === "block") {
          this.setState({ modal: "", modalActive: false });
        } else if (action === "create") {

        } else if (action === "payment") {


          this.setState({
            actionTriggered: action,
            modal: "",
            modalActive: false
          });

          let data = {
            itemQue: this.props.basketQueue,
            tenant: this.props.selectedTenant,
            selectedEvents: this.props.selectedEvents,
            selectedResource: _.find(this.props.selectedEvents, { eventId: this.props.eventId }),
            selectedService: this.props.service,
            carlendarView: this.props.current_view,
            basket: this.props.basket,
            chargeType: this.props.chargeType
          };


          var crypted = CyptoHelper.encryptAES(data);

          // var crypt = "?data=" + encodeURIComponent(crypted);
          localStorage.setItem(ORDER_KEY, crypted);

          setTimeout(() => {
            this.props.history.push({
              pathname: "/concierge/bookings/payment"

            });
            window.scrollTo(0, 0);
          }, 0);
        }

      }, 0);


  }
  componentDidMount() {


  }
  UNSAFE_componentWillMount() {
    const { serviceId, date } = this.props;


    if (this.props.service) {
      this.setState({

        max_day_bookings: !_.isUndefined(this.props.service.max_bookings) &&
          this.props.service.max_bookings > MAX_DATE_BOOKINGS ? this.props.service.max_bookings : MAX_DATE_BOOKINGS
      });
    }
    this.serviceBookings(serviceId, dateFormat(date._d, "isoDate"));


    if (this.props.fromRedirect && this.props.fromRedirect === true) {
      let status_screen = "";
      if (this.props.paymentAction === true) {
        status_screen = "booking_success"

        // this.props.dispatch(resourceActions.notifyCreateBooking(this.props.booking));
      } else if (this.props.paymentAction === false) {
        status_screen = "booking_error"
      }

      this.setState({
        modal: this.showModal(true),
        modalActive: true,
        modalScreenType: status_screen,
        paymentStatus: this.props.paymentAction
      });
    }

  }
  componentWillUnmount() {
    this.props.dispatch(resourceActions.setActionRedirect(undefined));
    this.props.dispatch(resourceActions.setPaymentAction(undefined));

  }
  UNSAFE_componentWillReceiveProps(nextProps, nextState) {
    if (this.props.selectedBookingDetail !== nextProps.selectedBookingDetail) {
      if (
        !_.isUndefined(nextProps.selectedBookingDetail.min_cancellation_time)
      ) {
        this.setState({
          min_cancel_time: moment(
            nextProps.selectedBookingDetail.min_cancellation_time
          )
        });
      }
    }
    if (this.props.bookingsCancelled !== nextProps.bookingsCancelled) {
      this.setState({
        cancelledBooking:
          !_.isUndefined(nextProps.bookingsCancelled) &&
            nextProps.bookingsCancelled.length > 0
            ? nextProps.bookingsCancelled[0]
            : undefined
      });
    }
    if (this.props.bookingUpdated !== nextProps.bookingUpdated) {
      this.setState({
        bookingUpdated:
          !_.isUndefined(nextProps.bookingUpdated.booking) &&
            nextProps.bookingUpdated.booking.length > 0
            ? nextProps.bookingUpdated.booking[0]
            : undefined
      });
    }

    if (this.state.slots !== nextState.slots) {
      this.setState({
        slots: nextProps.slots
      });
    }

    if (this.props.selectedEventResources !== nextProps.selectedEventResources) {
      this.setState({
        eventsResourceSelected: nextProps.selectedEventResources
      });
    }

    if (this.props.bookingCreated !== nextProps.bookingCreated) {
      this.setState({
        bookingCreated: !_.isUndefined(nextProps.bookingCreated) && nextProps.bookingCreated ? nextProps.bookingCreated : undefined
      });
    }

    // if (this.state.paymentStatus !== nextState.paymentStatus && !_.isUndefined(nextState.paymentStatus)) {
    //   console.log(this.props);
    //   //this.props.dispatch(resourceActions.notifyCreateBooking(this.props.booking));
    // }
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {


    // if (this.state.actionTriggered !== nextState.actionTriggered) {
    //   if (nextState.actionTriggered === "payment") {

    //     browserHistory.push({
    //       pathname: "/concierge/bookings/payment",
    //       state: {
    //         itemQue: basketQue,
    //         tenant: selectedTenant
    //       }
    //     });
    //   }

    // }


  }

  //keep for carlendar anvigation
  // handleNavigation(date, action) {
  //   switch (action) {
  //     case "day":
  //       console.log(date);
  //       if (date >= new Date().setHours(0)) {
  //         this.props.dispatch(
  //           resourceActions.fetchServicesSlots(this.props.serviceId, date._d)
  //         );
  //       }
  //       return date;
  //     default:
  //       return date;
  //   }
  // }
  componentDidUpdate(prevProps, prevState) {
    const { selectedTenant, basketQue } = this.props;

    if (
      prevProps.serviceId !== this.props.serviceId ||
      prevProps.date !== this.props.date
    ) {

      this.serviceBookings(
        this.props.serviceId,
        dateFormat(this.props.date._d, "isoDate")
      );
    }

    if (prevState.selectedBookedSlot !== this.state.selectedBookedSlot) {
      this.setState({
        selectedBookedSlot: this.state.selectedBookedSlot
      });
    }



    if (prevProps.selectedTenant !== selectedTenant) {
      this.setState({
        selectedTenant: selectedTenant
      });
    }
    if (prevProps.basketQue !== basketQue) {
      this.setState({
        basketQue: basketQue
      });
    }
  }

  //handle slot selection
  slotSelected(slotInfo) {



    //const { serviceId } = this.props;
    const { slots, serviceId } = this.state;
    let startDate = moment(slotInfo.start);
    let endDate = moment(slotInfo.end);


    this.setState({
      selectedStartDate: startDate,
      selectedEndDate: endDate
    });


    //Get slot data for selected slot
    let selectedSlotTimes = _.filter(slots, o => {
      return moment(o.start) >= startDate && moment(o.start) < endDate;
    });


    var isAvailable = true;
    let price = 0;

    var slot_obj = _.map(selectedSlotTimes, slot => {

      if (moment(slot.start) < moment(new Date()))
        isAvailable = false;

      //price += slot.price
      return slot;
    });

    let slot = {};
    if (slotInfo && slot_obj.length > 0 && isAvailable === true) {

      slot = {
        isAvailable: true,
        inBasket: false,
        id: slot_obj[0].id,
        start: slotInfo.start,
        end: slotInfo.end,
        //price: price,
        duration: endDate.diff(startDate, 'days'), // moment.duration(endDate.diff(startDate)).asDays(),
        //start_min: min,
        //end_min: end_min,
        type: slotInfo.action,
        active: true
      };

      this.props.dispatch(resourceActions.setSelectedSlot(slot, serviceId));
    }


    if (slotInfo.action === "select") {
      let now = new Date();
      if (startDate.toDate() >= now && this.state.eventsResourceSelected && this.state.eventsResourceSelected.length > 0) {
        this.setState({
          modalActive: true,
          slotSelected: slot,
          modalScreenType: "create",
          selectedSlotTimes: selectedSlotTimes
        });
      }


    }

    this.getBookedBooking(slot_obj);
  }


  showModal(active) {
    const { eventsResourceSelected,
      modalScreenType,
      switchActive,
      slotSelected,
      selectedStartDate,
      selectedEndDate,
      max_day_bookings
    } = this.state;
    const { serviceDetail, bookingQuestions, step, serviceId, portalSettings } = this.props;
    const events = _.map(eventsResourceSelected, o => {
      return { name: o.eventName, eventId: o.eventId };
    })


    return (
      <EventModal
        handleCloseClick={this.handleCloseClick}
        focus={active}
        type={modalScreenType}
        switchActive={switchActive}
        getApplicationNode={this.getApplicationNode}
        activateModal={""}
        deactivateModal={""}
        selectedSlot={slotSelected}
        //slots={slots}
        resources={events}
        startDate={selectedStartDate}
        endDate={selectedEndDate}
        service={serviceDetail}
        bookingQuestions={bookingQuestions}
        timeStep={step}
        modalActionCompleted={this.modalActionCompleted}
        cancellationPeriod={!_.isUndefined(portalSettings) && !_.isUndefined(portalSettings.cancellation_period) ? portalSettings.cancellation_period : 0}
        view={this.props.current_view}
        serviceId={serviceId}
        max_day_bookings={max_day_bookings}
      />
    );
  }


  //helper method to get selected booked slot
  getBookedBooking(slot) {
    const { bookings, selectedEvents } = this.props;


    let selected_booking = undefined;
    if (
      slot &&
      bookings &&
      !_.isUndefined(slot.booked) &&
      slot.booked === true
    ) {
      const event = _.find(selectedEvents, { eventId: slot.eventId });


      let stime = new Date(slot.start);
      let etime = new Date(slot.end);

      let smin = stime.getHours() * 60 + stime.getMinutes();
      let emin = etime.getHours() * 60 + etime.getMinutes();

      selected_booking = _.find(bookings, b => {
        //convert each booking time from to minutes
        let booking_start_time = moment(b.timeFrom, "HH:mm A");
        let booking_start_mins =
          booking_start_time.get("hour") * 60 +
          booking_start_time.get("minutes");

        //convert each booking end time to minutes
        let booking_end_time = moment(b.timeTo, "HH:mm A");
        let booking_end_min =
          booking_end_time.get("hour") * 60 + booking_end_time.get("minutes");

        //return booking that falls within a booked slot range
        return booking_start_mins === smin && booking_end_min === emin && event.eventName === b.resourceName;
      });


    } else {

    }


  }


  //handle event selected
  eventSelected(slot, e) {
    const { serviceId } = this.props;
    const { slots } = this.state;

    if (slot.booked || slot.blocked) {


      //swap selected active property 
      //in position cards based on package calculaions. 
      let new_slots = _.map(slots, (data, index) => {
        if (data.id === slot.id) {
          return {
            ...data,
            active: true,
          };
        }

        return {
          ...data,
          active: false,
        };
      });


      this.setState({
        selectedBookedSlot: slot,
        slotSelected: slot,
        slots: new_slots
      });

      if (!_.isUndefined(slot.active) && slot.active === true) {
        this.setState({
          modal: this.showModal(slot, true),
          modalActive: true,
          modalScreenType: "edit"
        });
      }
    }

    if (slot.inBasket) {
      this.props.dispatch(resourceActions.deSelectSlot(slot));
    } else {
      if (slot.isAvailable) {

        let selected_slot = {
          isAvailable: true,
          inBasket: false,
          id: slot.id,
          start: slot.start,
          end: slot.end,
          price: slot.price,
          start_min: slot.start_min,
          end_min: slot.end_min,
          type: "click"
        };
        this.props.dispatch(
          resourceActions.setSelectedSlot(selected_slot, serviceId)
        );
      }
    }

    this.setState({
      open: !this.state.open
    });


  }

  serviceBookings(serviceid, date) {

    const { current_view } = this.props;

    var date_ = moment(date).toDate(), y = date_.getFullYear(), m = date_.getMonth();

    var startDay = new Date(y, m, 1);
    var endDay = new Date(y, m + 1, 0);

    startDay = moment(startDay).subtract(7, 'd');
    endDay = moment(endDay).add(7, 'd');

    this.props.dispatch(resourceMonthlyActions.getServiceBookings(serviceid, startDay.format("YYYY-MM-DD"), endDay.format("YYYY-MM-DD"), success => {
      if (success === true) {
        this.setState({
          request_fail: undefined
        });
      } else {
        this.setState({
          request_fail: true
        });
      }
    }));


  }

  //helper to get overlapping related time event slot from slots list
  getOverlappingSlot(slots, selected_slot) {
    const current_slots = _.filter(slots, slot => {
      return moment(selected_slot.start).format("YYYY-MM-DD") === moment(slot.start).format("YYYY-MM-DD") &&

        !_.isUndefined(slot.booked) && slot.booked === true &&
        ((selected_slot.start_min < slot.start_min && slot.start_min < selected_slot.end_min) ||
          (selected_slot.start_min === slot.start_min && slot.end_min <= selected_slot.end_min) ||
          (selected_slot.start_min >= slot.start_min && slot.end_min >= selected_slot.end_min)
        );

    });
    const sort_slot = _.orderBy(current_slots, ['start_min'], ['asc']);


    return sort_slot;
  }

  clearBookingSelection() {
    this.setState({
      selectedBookedSlot: undefined,
      bookingId: "",
      date: "",
      time: "",
      duration: "",
      error: ""
    });
  }

  handleUpdateDateChange(val) {
    this.setState({
      date: val
    });
  }

  switchDayNext(dayType) {
    const { current_nav_view } = this.state;
    const { date, current_view, endDate } = this.props;

    let now = moment();

    if (current_view === calendarViews.Week || current_view === calendarViews.WorkWeek) {

      if (dayType === "Today") {

        this.setState({
          current_nav_view: "Next"
        });
      }
      if (dayType === "Next") {

        this.setState({
          current_nav_view: "Next"
        });
      }

      if (dayType === "Back") {

        this.setState({
          current_nav_view: "Next"
        });
      }

    }


    // if (dayType === "Today") {

    //     this.setState({
    //         current_nav_view: "Next"
    //     })
    // }
    // if (dayType === "Back") {
    //     this.setState({
    //         current_nav_view: "Today"
    //     })

    // }

  }

  switchDayPrevious(dayType) {
    const { current_nav_view } = this.state;
    const { date, current_view, endDate } = this.props;


    if (current_view === calendarViews.Week) {

      if (dayType === "Next") {
        this.setState({
          current_nav_view: "Back"
        });

      }

      if (dayType === "Back") {

        this.setState({
          current_nav_view: "Back"
        });
      }
    }



    // if (dayType === "Today") {

    //     this.setState({
    //         current_nav_view: "Back"
    //     })
    // }
    // if (dayType === "Next") {
    //     this.setState({
    //         current_nav_view: "Today"
    //     })

    // }


  }

  switchDayTest() {


  }

  getCustomToolbar(toolbar) {
    //toolbar parameter exposes, view, date
    this.toolbarDate = toolbar.date;

    const goToBack = () => {
      toolbar.onNavigate("PREV");
    };
    const goToNext = () => {
      toolbar.onNavigate("NEXT");
    };
    const goToCurrent = () => {
      toolbar.onNavigate("TODAY");
    };
    var monthNames = ["JANUARY", "FEBRUARY", "MARCH", "APRIL", "MAY", "JUNE", "JULY", "AUGUST", "SEPTEMBER", "OCTOBER", "NOVEMBER", "DECEMBER"];
    var weekDays = this.props.current_view === calendarViews.Week || this.props.current_view === calendarViews.WorkWeek ?
      ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"] : ["SUNDAY", "MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SAT"]



    var n = new Date(this.props.date);
    // var curruntDate = new Date();

    var ed = new Date(this.props.endDate);
    var currentMonth = monthNames[n.getMonth()];
    var currentDay = weekDays[n.getDay()];
    var currentYear = n.getFullYear();
    var ws = new Date(this.props.endDate);
    ws.setDate(ws.getDate() - 6);

    let dayCount = 0;

    if (this.props.current_view === calendarViews.WorkWeek)
      dayCount = 5;
    if (this.props.current_view === calendarViews.Week)
      dayCount = 7;
    if (this.props.current_view === calendarViews.Day)
      dayCount = 1;

    let isCurrentMonth = monthNames[ws.getMonth()] === monthNames[new Date().getMonth()];


    let viewSwitcher = this.props.serviceUnit && this.props.serviceUnit === ServiceUnitType.Minute ?
      <div className="week_select" style={{ display: "flex", flexDirection: "row" }} >
        <button className={"Day" + (this.props.current_view === 'work_week' || this.props.current_view === "week" ? ' active' : '')} onClick={() => this.props.switchTypeHandler('week')}  >Week</button>
        <button className={"Week" + (this.props.current_view === 'day' ? ' active' : '')} onClick={() => this.props.switchTypeHandler('day')} >Day</button>
      </div>
      : "";


    return <div className="toolbar-container">
      <div style={{ display: "flex", flexDirection: "row", marginBottom: "15px" }}>
        {viewSwitcher}
        <div className="day_select" style={{ display: "flex", flexDirection: "row", paddingRight: 10 + "px", marginTop: "1em" }}>
          <button className="nav-btn" onClick={() => {
            this.switchDayPrevious(this.state.current_nav_view);
            goToBack();

          }}><i style={{ fontWeight: "bolder", fontSize: 22 + "px", color: "#6b6b6b" }} className="fa fa-angle-left fa-2" aria-hidden="true"></i></button>
          <div onClick={() => {

            goToCurrent();

          }} className="Text-Field">
            <label className="today" key={this.state.current_nav_view} value={this.state.current_nav_view}>{this.state.current_nav_view}</label>

          </div>
          <button className="nav-btn" onClick={() => {
            // this.switchDayNext(this.state.current_nav_view);
            goToNext();
          }}><i style={{ fontWeight: "bolder", fontSize: 22 + "px", color: "#6b6b6b" }} className="fa fa-angle-right fa-2" aria-hidden="true"></i></button>

        </div>

        <div style={{ display: "flex", flexDirection: "column", width: 100 + "%" }}>
          {this.props.servicesOptions}


        </div>
      </div>

      {this.props.requestStatus && this.props.requestStatus === true && <div>
        <p className="message type-error">Network error, Please try again later.</p>
      </div>}
      {
        // this.props.eventsSlotStatus && <div> <div className="progress-spinner"> <i className={"fa fa-spinner fa-spin resource-arrow "} style={{ fontSize: 24 + "px" }}></i></div> </div>
      }
      <div className="currentMonthYear">
        <div className={`${!isCurrentMonth ? "set set-month" : "set"}`}>
          <label className="lblMonthYear">{this.props.current_view === 'day' ? n.getDate() : ''}  {currentMonth}  {currentYear} </label>
          <label className="lblCurrentDay">{this.props.current_view === 'day' ? currentDay : ''}   </label>
        </div>
        {this.props.current_view === calendarViews.WorkWeek || this.props.current_view === calendarViews.Week ? _.times(dayCount, i =>

          <div key={i} className={this.props.current_view === 'week' ? "currentWeek" : 'currentDay'} >
            <label className="lblDateDay" style={ws < new Date() ? { color: '#6b6b6b' } : (ws.getDate() == new Date().getDate() ? { color: '#6d20ab' } : { color: '#221f20' })}>{ws.getDate()} </label>
            <label className="lblCurrentWeekDay" style={ws < new Date() ? { color: '#6b6b6b' } : (ws.getDate() == new Date().getDate() ? { color: '#6d20ab' } : { color: '#221f20' })}>{weekDays[ws.getDay()]}</label>
            <label style={{ display: 'none' }} >{ws.setDate(ws.getDate() + 1)}  </label>
          </div>
        ) : ''}
      </div>

    </div>;
  }

  getDatesRange(startDate, endDate) {
    var dateRange = [];
    var currentDate = new Date(moment(startDate).format("YYYY-MM-DD"));
    var end = new Date(moment(endDate).format("YYYY-MM-DD"));

    let current = currentDate;
    for (var d = current; d <= end; d.setDate(d.getDate() + 1)) {
      dateRange.push(new Date(d));
    }

    return dateRange;
  }

  render() {
    MyWeek.navigate = this.props.handleNavigation;

    const { current_view, date, endDate, step, slots, defaultDate } = this.props;
    const { modalActive } = this.state;

    let formats = {
      dayFormat: (date, culture, localizer) =>
        localizer.format(date, 'D ddd', culture),
      timeGutterFormat: "HH:mm",
      dateFormat: "D"
    }

    // const formats = {
    //   dayFormat: (date, culture, localizer) =>
    //     localizer.format(date, 'ddd MM/dd', culture),
    // }
    let today

    if (!this.state.slots) {
      return this.renderLoading();
    }


    return (

      <div id="bookingApp">
        {_.isUndefined(this.props.eventsSlotStatus) ?
          <BigCalendar
            className={"calendar " + this.props.current_view}
            events={_.filter(this.state.slots, o =>
              o.booked === true || o.blocked === true
            )}

            defaultDate={new Date()}
            formats={formats}
            now={new Date()}
            date={new Date(date._d)}
            startAccessor={({ start }) => new Date(start)}
            endAccessor={({ end }) => new Date(end.getTime() - 1)} //subtract one millisecond from the end date putting midnight dates at 11:59:999 https://github.com/intljusticemission/react-big-calendar/issues/363
            defaultView={this.props.current_view}
            step={step * this.state.timeSlot}
            timeslots={this.state.timeSlot}
            view={this.props.current_view}
            views={['day', 'week', 'work_week', 'month']}
            onView={() => { }}
            selectable={'ignoreEvents'}
            toolbar={true}
            showMultiDayTimes={true}
            onSelectEvent={(event, target) => {
              this.eventSelected(event, target);
            }}
            onSelectSlot={slotInfo => {
              this.slotSelected(slotInfo);
            }}
            elementProps={{}}
            min={moment("09:00am", "h:mma").toDate()}
            max={moment("18:00pm", "h:mma").toDate()}
            components={{
              event: CustomEventCell,
              dayWrapper: myCustomDayWrapper,
              toolbar: this.getCustomToolbar,
              //dateCellWrapper: customDateCellWrapper,
              month: {
                header: (props) => (
                  <div className={moment(CURRENT_DATE).format("YYYY-MM") === moment(props.date).format("YYYY-MM") &&
                    props.date.getDay() === CURRENT_DATE.getDay() && moment(props.date).format("YYYY-MM") === date.format("YYYY-MM") ?
                    "rbc-header current-day" : "rbc-header"}>
                    <span> {props.label} </span>
                  </div>
                )
              }
            }}
            eventPropGetter={eventPropsGetter}
            //slotPropGetter={slotPropGetter}
            onNavigate={(date, action) => this.props.handleNavigation(date, action)}
            dayPropGetter={customDayPropGetter}

          />
          :
          <div> <div className="progress-spinner"> <i className={"fa fa-spinner fa-spin resource-arrow "} style={{ fontSize: 24 + "px" }}></i></div> </div>
        }

        {modalActive === true && this.showModal(this.state.slotSelected, true)}

      </div>
    );
  }
  renderLoading() {
    return <p> Loading... </p>;
  }
}
BookingCalendarCustom.propTypes = {
  bookedEventSelected: PropTypes.func,
  serviceUnit: PropTypes.string
};
function mapStateToProps(state, ownProps) {
  return {
    basketQueue: resourceSelector.getBasketQueue(state),
    // serviceId: resourceSelector.getServiceId(state),
    eventId: resourceSelector.getEventId(state),
    bookings: resourceSelector.getBookings(state),
    bookingErrors: resourceSelector.getBookingErrors(state),
    timeAvailable: resourceSelector.getTimeAvailable(state),
    selectedBookingDetail: resourceSelector.getSelectedBookingDetail(state),
    bookingsCancelled: resourceSelector.getBookingsCancelled(state),
    bookingUpdated: resourceSelector.getBookingUpdated(state),
    selectedEvents: resourceSelector.getSelectedEventResources(state),
    portalSettings: serviceModuleSelectors.loadPortalSttings(state),
    bookingCreated: resourceSelector.getBookingCreated(state),
    serviceUnit: resourceSelector.getServiceUnit(state),
    selectedTenant: tenantSelectors.getSelectedTenant(state),
    service: resourceSelector.getService(state),
    fromRedirect: resourceSelector.getActionRedirect(state),
    paymentAction: resourceSelector.getPaymentAction(state),
    booking: resourceSelector.getSetBooking(state),
    basket: checkoutSelectors.getBasket(state),
    carlendarSetting: resourceSelector.getCalendarSettings(state),
    chargeType: checkoutSelectors.getChargeType(state)
  };
}
export default withRouter(connect(mapStateToProps)(BookingCalendarCustom));
