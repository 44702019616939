import * as types from "./actionTypes";
import _ from "lodash";
import moduleServices from "../../services/ModulesServices";
import * as config from "../../services/config";

export function getModules(callback = () => { }) {
  return async (dispatch, getState) => {
    try {
      const modules = await moduleServices.getModules();

      dispatch({
        type: types.MODULES_LOADED_SUCCESSFULLY,
        modules: _.get(modules, "modules")
      });
      callback(true);
    } catch (error) {
      callback(false);

      dispatch({ type: types.MODULES_LOAD_FAIL, error });
      //console.error(error);
    }
  };
}

export function loadModules(callback = () => { }) {
  return (dispatch, getState) => {
    try {
      const mod = moduleServices.getServiceModules();

      dispatch({
        type: types.MODULES_LOADED_SUCCESSFULLY,
        modules: JSON.parse(mod)
      });
    } catch (error) {
      callback(false);
    }
  };
}

export function getPortalSetting(callback = () => { }) {
  return async (dispatch, getState) => {
    try {
      const setting = await moduleServices.getPortalSettings();
      dispatch({
        type: types.PORTAL_SETTINGS_LOAD_SUCCESSFULLY,
        settings: setting
      });

      callback(true);
    } catch (error) {
      callback(false);

      dispatch({ type: types.PORTAL_SETTINGS_LOAD_FAIL, error });
      //console.error(error);
    }
  };
}


export function getPortalTypes() {
  return config.portalTypes;
}