import * as config from "./config";
import authService from "./loginService";

class AddressLookUpServices {
  async getAddresses() {
    const url = `${config.ADDRESSES_ENDPOINT}`;
    const authToken = authService.getToken();

    var requestHeader = new Headers();
    requestHeader.append("Authorization", "Bearer " + authToken);

    const response = await fetch(url, {
      method: "GET",
      headers: requestHeader
    });
    if (!response.ok) {
      // config.errorPageRedirect(response);
      throw new Error(
        `get modules service failed, HTTP status ${response.status}`
      );
    }

    const result = await response.json();

    return result;
  }
}

export default new AddressLookUpServices();
