
export const MONTHLY_SERVICES_SLOTS_FETCHED = "MONTHLY_SERVICES_SLOTS_FETCHED";
export const MONTHLY_RESOURCE_BOOKINGS_FETCHED = "MONTHLY_RESOURCE_BOOKINGS_FETCHED";
export const RESOURCES_FETCHED_SUCCESSFULLY = "RESOURCES_FETCHED_SUCCESSFULLY";
export const SERVICE_DAYDATA_FETCHED_SCCESSFULLY = "SERVICE_DAYDATA_FETCHED_SCCESSFULLY";




export const RESOURCES_FETCHED_FAIL = "RESOURCES_FETCHED_FAIL";
export const SERVICE_DAYDATA_FETCHED_FAIL = "SERVICE_DAYDATA_FETCHED_FAIL";
export const RESOURCE_DAYDATA_FETCH_SUCCESS = " RESOURCE_DAYDATA_FETCH_SUCCESS";
export const RESOURCE_DAYDATA_FETCH_FAIL = " RESOURCE_DAYDATA_FETCH_FAIL";