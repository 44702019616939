import React, { Component } from "react";
// import { connect } from "react-redux";
// import * as resourceActions from "../../store/Resources/actions";
// import * as resourceSelector from "../../store/Resources/reducer";
import autoBind from "react-autobind";
import _ from 'lodash';

var PropTypes = require('prop-types');

class ListItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      // maxLimit: false
    }

    autoBind(this);
  }

  render() {

    return (
      <a
        onClick={() => {
          this.setState(prevState => {
            let newState = !prevState.active;
            let eventIDs = this.props.event_ids;

            if (eventIDs.length === 3 && !prevState.active) {
              return;
            }
            else {
              eventIDs = [...eventIDs, this.props.event_id];
            }
            if (prevState.active) {
              eventIDs = eventIDs.filter(e => e !== this.props.event_id);
            }



            this.props.handleClick(newState, this.props.value, this.props.event_id);
            this.props.onClick(eventIDs)
            return { active: newState }
          })
        }}

        className={!this.state.active && !this.props.active ? '' : 'selected'}
      >
        {this.props.value}</a>
    )
  }
}



class ServiceListMulti extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showList: false,
      value: this.props.selectedOption ? _.map(this.props.selectedOption, val => {
        return val.eventName
      }) : [],
      event_id: this.props.selectedOption ? _.map(this.props.selectedOption, val => {
        return val.eventId
      }) : [],
      // showSpinner: this.props.processing,
      showDownList: _.isUndefined(this.props.processing) ? true : undefined,
      iconClass: "fa-angle-down"

    }

    this.handleItemClick = this.handleItemClick.bind(this)
  }

  renderValue() {
    let { value } = this.state;
    if (!value.length) return "Select..."
    else return value.length + " selected";
    // return value.join(', ')
  }
  toggleList() {
    let { showList } = this.state;
    if (!showList) {
      this.setState({
        iconClass: "fa-angle-up"
      });
    }
    else {
      this.setState({
        iconClass: "fa-angle-down"
      });
    }
    this.setState(prevState => ({ showList: !prevState.showList }));
  }

  toggleSpiiner() {
    let { showList } = this.state;
    if (showList) {
      //var millisecondsToWait = 500;
      var CurruntContext = this;
      setTimeout(function () {
        // Whatever you want to do after the wait
        CurruntContext.setState(prevState => ({ showUparrow: prevState.showList }))
        CurruntContext.setState(prevState => ({ showSpinner: false }))
      }, 500);
    }
  }

  handleItemClick(active, val, evntid) {

    let { value, event_id } = this.state;
    if (active && value.length < 3) {
      value = [...value, val];
      if (!_.includes(event_id, (evntid))) {
        event_id = [...event_id, evntid];
      }
    }
    else {
      value = value.filter(e => e !== val);
      event_id = event_id.filter(e => e !== evntid);
    }
    this.setState({ value })
    this.setState({ event_id })


  }


  render() {
    const { handleSelectionChange, selectedOption } = this.props;

    const options = this.props.services.map((item, index) => {

      return (
        { label: item.name, value: item.eventid, key: index, _handleItemClick: this.handleItemClick, _event_ids: this.state.event_id });
    });


    return (

      <div
        ref={node => this.node = node}
        className="select">

        <button onMouseUp={() => { this.toggleList() }} onClick={() => { /*this.toggleSpiiner()*/ }} className="multiSelectButton">
          <span className="select_value">
            {this.renderValue()}
          </span>

          {_.isUndefined(this.props.processing) ?
            <i className={`${"fa "}${this.state.iconClass}${" fa-2 resource-arrow"}`}></i>
            :
            <i className={"fa fa-spinner fa-spin resource-arrow " + (this.props.processing !== true && 'hide')} style={{ fontSize: 24 + "px" }}></i>
            // <i className={"fa fa-angle-down fa-2 resource-arrow " + (this.state.showDownList && 'hide')}></i>
            // <i className={"fa fa-angle-up fa-2 resource-arrow " + (!this.state.showUparrow && 'hide')}></i>
          }
          {
            //<i className={"fa fa-spinner fa-spin resource-arrow " + (this.props.processing !== true && 'hide')} style={{ fontSize: 24 + "px" }}></i>
            // <i className={"fa fa-spinner fa-spin resource-arrow " + (!this.state.showSpinner && 'hide')} style={{ fontSize: 24 + "px" }}></i>
          }
        </button>

        <div
          className={"select_list " + (!this.state.showList && 'hide')}>

          {options && options.length > 0 && options.map(function (item, index) {

            return <ListItem value={item.label} key={index}
              event_id={item.value}
              handleClick={item._handleItemClick}
              onClick={handleSelectionChange}
              event_ids={item._event_ids}
              active={!_.isUndefined(_.find(selectedOption, { eventId: item.value }))} />
          })}


        </div>


      </div>
    )
  }
}

ServiceListMulti.propTypes = {
  serviceId: PropTypes.string,
  date: PropTypes.string,
  services: PropTypes.array,
  handleSelectionChange: PropTypes.func
};

// function mapStateToProps(state, ownProps) {
//   return {
//     // services: resourceSelector.getServices(state)
//   };
// }
export default ServiceListMulti;