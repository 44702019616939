import React, { Component } from 'react';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';
import {withRouter} from 'react-router-dom';
import * as $ from 'jquery';

import 'react-datepicker/dist/react-datepicker.css';
import 'rc-time-picker/assets/index.css';
import _ from 'lodash';
import Datepicker from 'react-datepicker';

import moment from 'moment';
import dateFormat from 'dateformat';

import FeatureArea from '../../../components/FeatureArea/FeatureArea';
import FooterNavigation from '../../../components/FooterNavigation/FooterNavigation';

import TenantAddrTypeAutoSuggest from '../../../components/TenantAutoSuggest/TenantAddrTypeAutoSuggest';
import TenantCompanyTypeAutoSuggest from '../../../components/TenantAutoSuggest/TenantCompanyTypeAutoSuggest';

import Button from '../../../components/Button/Button';
import ButtonGroup from '../../../components/Button/ButtonGroup';
import InputNewComponent from '../../../components/Input/InputNew';
import DetailListNew from '../../../components/DetailList/DetailListNew';

import * as serviceModulesActions from '../../../store/ServiceModules/actions';
import * as settingSelector from '../../../store/ConciergeSetting/reducer';

import * as moduleSelectors from '../../../store/ServiceModules/reducer';
import * as tenantActions from '../../../store/Tenants/actions';
import * as tenantSelector from '../../../store/Tenants/reducer';
import * as meetingAction from '../../../store/Meetings/actions';
import * as meetingSelector from '../../../store/Meetings/reducer';

import * as visitorSelector from '../../../store/Visitors/reducer';

import '../../../sass/containers/visitors/index.css';

function generateOptions(length, excludedOptions) {
	const arr = [];
	for (let value = 0; value < length; value++) {
		if (excludedOptions.indexOf(value) < 0) {
			arr.push(value);
		}
	}
	return arr;
}
function disabledMinutes(h) {
	var min_arr = [ 0 ];
	for (let value = 0; value < 60; value++) {
		let curr_min = value + 5;
		if (curr_min < 60 && curr_min % 5 === 0) {
			min_arr.push(curr_min);
		}
	}
	switch (h) {
		default:
			return generateOptions(60, min_arr);
	}
}

const PortalTypes = serviceModulesActions.getPortalTypes();
const StatusMessages = {
	Booked: 'Visitors successfully booked',
	Updated: 'Visitors successfully updated',
	UpdatedList: 'Visitor updated'
};


class VisitorBooking extends Component {
	constructor(props) {
		super(props);
		this.state = {
			value: '',
			name: '',
			suggestions: [],
			tenantId: '',
			tenantCompany: '',
			tenantName: '',
			tenantAddress: '',
			tenantType: '',
			startDate: moment(),
			timeFrom: moment(),
			timeTo: moment().add(1, 'hours'),
			now: moment().format('LLLL'),
			addVisitorFormUsed: false,
			visitorName: !_.isUndefined(this.props.location.state)
				? this.props.location.state.visitorData.visitorName
				: '',
			visitorCompany: !_.isUndefined(this.props.location.state)
				? this.props.location.state.visitorData.visitorCompany
				: '',
			visitorEmail: '',
			rows: [ 0 ],
			type: 'Visitors',
			specialInstruction: !_.isUndefined(this.props.location.state)
				? this.props.location.state.visitorData.specialInstruction
				: '',
			commercial: false,
			portalType:
				this.props.setting && !_.isUndefined(this.props.setting.portalType)
					? this.props.setting.portalType
					: '',
			isSucessfull: true,
			isVistorSave: false,
			isMeetindAdded: false,
			message: ''
		};

		this.addVisitorValidation = null;
		// this.addDatepickerValidation = null;
		autoBind(this);
	}

	componentDidMount() {
		const { specialInstruction } = this.state;

		/** Just to get it all working */

		this.addValidation();
		this.props.dispatch(tenantActions.getAllTenants());

		if (specialInstruction === '' && this.props.instruction) {
			this.setState({
				specialInstruction: this.props.instruction
			});
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.setting !== this.props.setting && !_.isUndefined(this.props.setting)) {
			this.setState({
				portalType: this.props.setting.portalType
			});
		}
	}

	componentWillUnmount() {
		this.addVisitorValidation.destroy();
		this.props.dispatch(meetingAction.clearMeetings());
		this.props.dispatch(meetingAction.setMettingsQueue([]));
		// this.addDatepickerValidation.destroy();
	}
	addValidation() {
		this.addVisitorValidation = $(this.refs.validate).parsley({
			classHandler: function(el) {
				return el.$element.closest('div:not(.input-wrapper):not(.Select)');
			}
		});

		// this.addDatepickerValidation = $(this.refs.validate).parsley({
		//   classHandler: function(el) {
		//     return el.$element.closest("input");
		//   }
		// });

		// this.addVisitorValidation = $("pickeryDate").parsley({
		//   classHandler: function(el) {
		//     return el.$element.closest("input");
		//   }
		// });
	}
	validate(fromSubmit = false) {
		this.addVisitorValidation.validate({ force: true });
		// this.addDatepickerValidation.validate({ force: true });
		const valid = this.addVisitorValidation.isValid();
		// this.addVisitorValidation.isValid() &&
		// this.addDatepickerValidation.isValid();

		this.setState({
			addVisitorFormValid: valid,
			addVisitorFormUsed: true
		});
		if (valid === true && fromSubmit === true) {
			this.formReset();
			const {
				timeFrom,
				startDate,
				timeTo,
				tenantId,
				visitorName,
				visitorCompany,
				tenantCompany,
				tenantName,
				tenantType,
				visitorEmail,
				rows,
				specialInstruction
			} = this.state;

			const timeFromExpected = dateFormat(timeFrom, 'isoTime');
			const timeToExpected = dateFormat(timeTo, 'isoTime');
			const dateExpected = dateFormat(startDate._d, 'isoDate');

			let dueDT = moment(dateExpected + ' ' + timeFromExpected);
			let toDT = moment(dateExpected + ' ' + timeToExpected);

			if (this.state.selectedStatus === true) {
				const { meetings } = this.props;

				var data = {
					tenantId: tenantId,
					tenantName: tenantName,
					tenantCompany: tenantCompany,
					tenantType: tenantType,
					name: visitorName,
					companyName: visitorCompany,
					dueDate: dueDT.format('YYYY-MM-DD HH:mm:ss'),
					endDate: toDT.format('YYYY-MM-DD HH:mm:ss'),
					status: 'notified',
					email: visitorEmail,
					specialInstruction: specialInstruction
				};

				let setMeetings = _.map(meetings, (meeting, index) => {
					if (meeting.index === this.state.selected) {
						return {
							...data,
							index: meeting.index
						};
					}
					return meeting;
				});

				this.props.dispatch(meetingAction.setMettingsQueue(setMeetings));
				this.setState({
					isSucessfull: true,
					message: StatusMessages.UpdatedList
				});
			} else {
				this.setState({
					rows: this.state.rows.concat([ this.state.rows.length ])
				});

				var data_ = {
					tenantId: tenantId,
					tenantName: tenantName,
					tenantCompany: tenantCompany,
					tenantType: tenantType,
					name: visitorName,
					companyName: visitorCompany,
					dueDate: dueDT.format('YYYY-MM-DD HH:mm:ss'),
					endDate: toDT.format('YYYY-MM-DD HH:mm:ss'),
					status: 'notified',
					email: visitorEmail,
					specialInstruction: specialInstruction,
					index: rows[rows.length - 1]
				};

				this.props.dispatch(meetingAction.addMeetingToQueue([ data_ ]));

				this.setState({
					isMeetindAdded: true,
					isSucessfull: false
				});
			}
		}
	}

	resetValidation() {
		$(this.refs.validate).get(0).reset();
		this.addVisitorValidation.destroy();
	}
	formReset() {
		// Doesnt seem to be working....
		// this.addParcelValidation.reset();

		this.setState({
			visitorName: '',
			visitorEmail: '',
			visitorCompany: '',
			specialInstruction: '',
			selectedStatus: undefined,
			selected: undefined,
			message: ''
		});
		// this.refs["tenant-auto-suggest"].getWrappedInstance().reset();

		$(this.refs.validate).get(0).reset();

		this.addVisitorValidation.destroy();
		//this.addDatePickerValidation.destroy();

		// Reset all the select input components to the placeholder value
		for (let key in this.refs) {
			if (key.indexOf('formSelect') === 0) {
				this.refs[key].reset();
			}
		}

		setTimeout(() => {
			this.addValidation();
		}, 0);
	}

	resetAutoSelect() {
		//deprecated
		//this.refs['tenant-auto-suggest'].getWrappedInstance().reset();

		setTimeout(() => {
			this.addValidation();
		}, 0);
	}

	handleInputChange(e) {
		const field = e.target.name;
		const value = e.target.value;
			this.setState({
				[field]: value,
				message: '',
				isSuccessfull: undefined
			});
	}


	handleSubmit(e) {
		const { specialInstruction } = this.state;
		const { meetings } = this.props;

		this.props.dispatch(
			meetingAction.createNewMeetings(meetings, (success) => {
				if (success === true) {
					this.props.dispatch(meetingAction.setMettingsQueue([]));
					this.formReset();
					this.resetAutoSelect();
					this.setState({
						isSucessfull: true,
						isVistorSave: true,
						isMeetindAdded: true,
						message: StatusMessages.Booked,
						tenantId: '',
						tenantCompany: '',
						tenantName: '',
						tenantAddress: '',
						tenantType: '',
						startDate: moment(),
						timeFrom: moment(),
						timeTo: moment().add(1, 'hours'),
						selectedStatus: false
					});
				}
			})
		);

		// if (specialInstruction) {
		//   this.props.dispatch(
		//     meetingAction.addSpectialInstruction(specialInstruction)
		//   );
		// }

		// // browserHistory.push("/concierge/visitor-management-review");
		//  browserHistory.push("/concierge/visitor-booking");
		//  this.setState({isSucessfull:true});
		// this.setState({isVistorSave:true});
	}

	handleDateChange(date) {
		this.setState({
			startDate: date
		});
	}
	handleTimeFromChange(val) {
		let checkMins = 23 * 60 + 59;

		let toTime = moment(val).add(1, 'hours');

		if (toTime.hours() === 0) {
			let today = moment(val).toDate();
			let maxEnd = new Date(today.getFullYear(), today.getMonth(), today.getDate());

			maxEnd.setMinutes(checkMins);

			toTime = moment(maxEnd);
		}

		this.setState({
			timeFrom: val,
			timeTo: toTime
		});
	}
	handleTimeToChange(val) {
		this.setState({
			timeTo: val
		});
	}
	removeFromQueue(index) {
		
		this.props.dispatch(meetingAction.removeMeetingFromQueue(index));
	}

	disabledHours() {
		const { timeFrom } = this.state;
		let time = [];
		for (let i = 0; i < 24; i++) {
			time.push(i);
		}
		if (!_.isUndefined(timeFrom) || timeFrom !== null)
			return _.filter(time, (t) => {
				if (timeFrom !== null) {
					return t < timeFrom.hour();
				} else {
					return t;
				}
			});
	}

	handleEdit(val) {
		const { meetings } = this.props;

		this.setState({ message: '' });
		if (!_.isUndefined(val)) {
			let selectedItem = _.find(meetings, { index: val });
			let data = {
				tenantId: selectedItem.tenantId,
				status: selectedItem.status,
				visitorName: selectedItem.name,
				visitorEmail: selectedItem.email,
				specialInstruction: selectedItem.specialInstruction,
				visitorCompany: selectedItem.companyName,
				timeFrom: moment(selectedItem.dueDate),
				timeTo: moment(selectedItem.endDate)
			};
			this.setState({ ...data, selectedStatus: true, selected: val });
		} else {
			this.formReset();
		}
	}

	render() {
		const {
			tenantCompany,
			startDate,
			specialInstruction,
			timeFrom,
			timeTo,
			visitorName,
			visitorEmail,
			visitorCompany,
			tenantId
		} = this.state;
		const currentModule = _.find(this.props.modules, (o) => o.title === 'Visitors');

		const overlayColor = currentModule && currentModule.imageOverlayTextColour;
		const visitor_style = {
			color: overlayColor,
			fontSize: '30px',
			textTransform: 'capitalize'
		};

		return (
			<div>
				<div className="container type-concierge-desktop management">
					<FeatureArea
						type={this.state.type}
						areaTitle="Visitors"
						areaTagline="Please enter information below"
						pageHeader="Visitor Booking"
						overlayColor={currentModule && currentModule.imageOverlayTextColour}
						landing={false}
						activeMenu={true}
					/>

					<form
						id="form-content"
						className="visitor-wrapper visitor-booking"
						ref="validate"
						onSubmit={(e) => {
							e.preventDefault();
						}}
						data-parsley-errors-container="#validation-messages"
						data-parsley-focus="none"
						data-parsley-validate-if-empty
						//data-parsley-inputs
					>
						{this.state.portalType === PortalTypes.Commercial ? (
							<div className="names">
								<div className="tenant_elements">
									<TenantCompanyTypeAutoSuggest
										ref="tenant-auto-suggest"
										label="Tenant Name*"
										labelText="Tenant"
										disabled={this.props.meetings.length > 0 && this.state.tenantId !== ''}
										value={this.state.tenant}
										clearableIfValid={false}
										onSelected={(data) => {
											this.setState({
												tenantId: data.memberId,
												tenantName: data.tenant,
												tenantCompany: data.companyName,
												tenantType: data.memberType
											});

											/* Timeout added otherwise validation not fired because autosuggest still updating DOM */
											// setTimeout(() => {
											// 	this.state.addVisitorFormUsed && this.validate();
											// }, 0);
										}}
									/>
								</div>
								<br />
								<br />
								<div className="company_elements">
									<div className="company-div">
										<InputNewComponent
											type="text"
											name="tenantCompany"
											label="Company"
											placeHolder="Company"
											handleChange={this.handleInputChange}
											value={tenantCompany}
											parsley={{
												//"data-parsley-pattern": "/^[A-Za-z ]+$/", // alpha chars only https://stackoverflow.com/questions/23415795/
												//"data-parsley-minlength": 2,
												'data-parsley-minwords': '1',
												'data-parsley-required': true,
												'data-parsley-error-message': 'Please enter a valid company name'
											}}
											disabled={true}
										/>
									</div>
								</div>
							</div>
						) : (
							<div className="talent">
								<div className="tenant_elements">
									<TenantAddrTypeAutoSuggest
										ref="tenant-auto-suggest"
										label="Tenant Name*"
										labelText="Tenant"
										placeHolder="Select Tenant"
										clearableIfValid={false}
										onSelected={(data) => {
											this.setState({
												tenantId: data.memberId,
												tenantAddress: data.address,
												tenantName: data.tenant
											});

											// setTimeout(() => {
											// 	this.state.addVisitorFormUsed && this.validate();
											// }, 0);
										}}
										defaultText={(dataVal) => {}}
										disabled={this.props.meetings.length > 0 && this.state.tenantId !== ''}
									/>
								</div>
							</div>
						)}
						<div className="date_elements">
							<div className="dates">
								<label className="dateLabel visitor-label">Date*</label>

								<Datepicker
									icon="ion-chevron-down"
									type="datePicker"
									ref="datePickery"
									fixedHeight={true}
									selected={startDate}
									minDate={moment()}
									onChange={this.handleDateChange}
									dateFormat="DD/MM/YYYY"
									placeholder="Date "
									dateValue={
										_.isUndefined(startDate) || startDate === null ? (
											moment().format('DD/MM/YYYY')
										) : (
											startDate.format('DD/MM/YYYY')
										)
									}
									name="datePick"
									// {...this.props.parsley}
								/>
							</div>
							<div className="times">
								<div>
									{
										<InputNewComponent
											icon="ion-chevron-down"
											id="timeFrom"
											type="timePicker"
											defaultValue={startDate}
											handleTimeChange={this.handleTimeFromChange}
											name="timeFrom"
											placeholder="From"
											disabledMinutes={disabledMinutes}
											label="From*"
											dateValue={
												_.isUndefined(timeFrom) || timeFrom === null ? moment() : timeFrom
											}
											parsley={{
												'data-parsley-pattern': '/^[A-Za-z ]+$/',
												'data-parsley-minlength': 5,
												'data-parsley-required': true,
												'data-parsley-minwords': '1',
												'data-parsley-error-message': 'Please enter a valid time'
											}}
										/>
									}
								</div>
								<div>
									<InputNewComponent
										type="timePicker"
										icon="ion-chevron-down"
										defaultValue={startDate}
										handleTimeChange={this.handleTimeToChange}
										name="timeTo"
										placeholder="To"
										disabledMinutes={disabledMinutes}
										label="To"
										disabledHours={this.disabledHours}
										dateValue={
											_.isUndefined(timeTo) || timeTo === null || timeTo < timeFrom ? (
												timeFrom
											) : (
												timeTo
											)
										}
										parsley={{
											'data-parsley-pattern': '/^[A-Za-z ]+$/',
											'data-parsley-minlength': 1,
											'data-parsley-required': true,
											'data-parsley-minwords': '1',
											'data-parsley-gte': 100000000000,
											'data-parsley-error-message': 'Please enter a valid time'
										}}
									/>
								</div>
							</div>
						</div>
						<div className="visitor_elements">
							<label className="visitor-label">Visitor Name*</label>
							<InputNewComponent
								type="text"
								name="visitorName"
								placeHolder="Enter visitor name"
								handleChange={this.handleInputChange}
								value={visitorName}
								parsley={{
									'data-parsley-pattern': "/^[a-z ,.'-]+$/i", // alpha chars only https://stackoverflow.com/questions/23415795/
									'data-parsley-minlength': 2,
									'data-parsley-required': true,
									'data-parsley-minwords': '2',
									'data-parsley-error-message': 'Please enter visitor first and the last name'
								}}
							/>
						</div>

						<div className="company_elements">
							<label className="visitor-label">Visitor Company</label>
							<InputNewComponent
								type="text"
								name="visitorCompany"
								placeHolder="Enter visitor company name if applicable"
								value={visitorCompany}
								handleChange={this.handleInputChange}
								// parsley={{
								//   "data-parsley-pattern": "/^[A-Za-z'!&(), ]+$/", // alpha chars only https://stackoverflow.com/questions/23415795/
								//   "data-parsley-minwords": "1",
								//   "data-parsley-required": true,
								//   "data-parsley-error-message":
								//     "Please enter a valid visitor company name"
								// }}
							/>
						</div>

						<div className="visitorEmail">
							<label className="visitor-label"> Visitor Email</label>
							<InputNewComponent
								type="text"
								name="visitorEmail"
								placeHolder="Enter visitor email if known"
								handleChange={this.handleInputChange}
								value={visitorEmail}
								parsley={{
									'data-parsley-type': 'email',
									'data-parsley-validate-if-empty': false,
									'data-parsley-error-message': 'Please enter a valid email'
								}}
							/>
						</div>
						<div className="InstructionText">
							<label className="visitor-label">Special Instructions</label>
							<InputNewComponent
								type="text"
								name="specialInstruction"
								placeHolder="Enter special instructions for visitor on arrival"
								value={specialInstruction}
								handleChange={this.handleInputChange}
							    maxLength="68"
							/>
						</div>

						<div
							className={'validation-messages' + (this.state.addVisitorFormValid ? ' valid' : ' invalid')}
							id="validation-messages"
						>
							{this.state.addVisitorFormValid && (
								<div>{/* <p className="message type-success">Sending data To Server...</p> */}</div>
							)}
						</div>

						<br />
						<div className="align-center middleBtn">
							{this.state.selectedStatus === true ? (
								<Button
									className="hidden"
									size="cta-smedium add"
									text="Update Visitor"
									handleClick={() => {
										this.validate(true);
									}}
								/>
							) : (
								<Button
									className="hidden"
									size="cta-smedium add"
									text="Add to List"
									handleClick={() => this.validate(true)}
									isDisabled={
										this.state.tenantId !== '' ? this.state.visitorName !== '' ? false : true : true
									}
								/>
							)}
						</div>
					</form>

					<div className="visitor-wrapper">
						<DetailListNew
							listType="visitor-add-meeting"
							data={this.props.meetings}
							headingsAlone={true}
							emptyMessage={this.state.message !== '' ? '' : 'You have not added any visitors yet'}
							actionClick={(index) => {
								this.formReset();
								this.removeFromQueue(index);
							}}
							visitorStyle={visitor_style}
							selectedDetail={(val) => {
								this.resetValidation();
								this.handleEdit(val);
							}}
							selectDetailReset={this.state.selectedStatus}
							resetForm={(e) => {
								if (e === true) {
									this.formReset();
								}
							}}
						/>
					</div>

					{this.state.message && (
						<div className="visitor-wrapper visitor-added-successful-txt">
							<label> {this.state.message} </label>
						</div>
					)}
					{
						// this.state.isVistorSave ?
						// <div className="visitor-wrapper visitor-added-successful-txt" >
						//   <label className="label-success-msg"> Visitors Successfully Booked </label>
						// </div> : ""
					}

					<div className="footer-btn-group visitor-wrapper">
						<ButtonGroup
							rightText="Save Visitors"
							leftText="Cancel"
							rightDisabled={this.props.meetings.length === 0}
							leftClick={() => {
								this.props.history.push({
									pathname: '/',
									state: { module: 'Visitors' }
								});
								window.scrollTo(0, 0);
							}}
							rightClick={this.handleSubmit}
							isDisabled={this.state.isSucessfull}
							icon_l=""
							icon_r=""
						/>
					</div>
					<FooterNavigation />
				</div>
			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		modules: moduleSelectors.loadModules(state),
		tenants: tenantSelector.getAllTenants(state),
		meetings: meetingSelector.getMeetingsQueue(state),
		visitors: visitorSelector.getVisitors(state),
		instruction: meetingSelector.getSpecialInstruction(state),
		setting: settingSelector.loadGlobalSetting(state)
	};
}
export default withRouter(connect(mapStateToProps)(VisitorBooking));
