//https://www.npmjs.com/package/react-textarea-autosize
import React, { Component } from "react";

import Textarea from "react-textarea-autosize";

var PropTypes = require('prop-types');

class TextAreaInput extends Component {

  render() {
    const { value, name, handleChange, placeHolder, maxLength } = this.props;
    return (
      <Textarea
        name={name}
        value={value}
        onChange={handleChange}
        placeholder={placeHolder}
        maxLength={maxLength}
      />
    );
  }
}

TextAreaInput.propTypes = {
  /** text area input value*/
  value: PropTypes.string,
  /**text area property name */
  name: PropTypes.string,
  /** Handlechange function passed in and called in parent  */
  handleChange: PropTypes.func,
  /** Placeholder text  */
  placeHolder: PropTypes.string,
  /** Maximum Length of the text area */
  maxLength: PropTypes.string
};
export default TextAreaInput;
