import * as types from "./actionTypes";
import Immutable from "seamless-immutable";



const initialState = Immutable({
    resources: [],
    monthServicesSlots: [],
    bookings: [],
    serviceDayData: [],
    resourceDayData: []

});

export default function resourcesMonthlyReducer(state = initialState, action = {}) {

    switch (action.type) {

        case types.MONTHLY_SERVICES_SLOTS_FETCHED:
            return state.merge({
                monthServicesSlots: action.servicesSlots
            });
        case types.MONTHLY_RESOURCE_BOOKINGS_FETCHED:
            return state.merge({
                bookings: action.bookings
            });
        case types.RESOURCES_FETCHED_SUCCESSFULLY:
            return state.merge({
                resources: action.resources
            });
        case types.SERVICE_DAYDATA_FETCHED_SCCESSFULLY:
            return state.merge({
                serviceDayData: action.dayData
            });
        case types.RESOURCE_DAYDATA_FETCH_SUCCESS:
            return state.merge({
                resourceDayData: action.data
            })
        default:
            return state;
    }
}

export function getServicesSlots(state) {
    return state.resourcesMonthly.monthServicesSlots;
}

export function getBookings(state) {
    return state.resourcesMonthly.bookings;
}

export function getResources(state) {
    return state.resourcesMonthly.resources;
}

export function getServiceDayData(state) {
    return state.resourcesMonthly.serviceDayData;
}

export function getResourceDayData(state) {
    return state.resourcesMonthly.resourceDayData;
}