import * as types from "./actionTypes";
import Immutable from "seamless-immutable";

const initialState = Immutable({
  resident: {},
  residents: [],
  residentsSearchQueue: [],
  newResident: {},
  residentCreated: false,
  residentUpdated: false,
  residentGroups: {},
  registerNotificationSent: {}
});

export default function residentRecord(state = initialState, action = {}) {
  switch (action.type) {
    case types.RESIDENTS_LOADED_SUCCESS:
      return state.merge({
        residents: action.residents
      });

      case types.RESIDENTS_GROUPS_LOADED_SUCCESS:
        return state.merge({
          residentGroups: action.residentGroups
        });

    case types.SEARCH_RESIDENTS_LOAD_SUCCESS:
      return state.merge({
        residentsSearchQueue: action.residentsResponse
      });
    case types.CREATE_RESIDENT_SUCCESS:
      return state.merge({
        newResident: action.resident,
        residentCreated: action.resident
          ? { status: true }
          : { status: false, error: "Problems adding new member" }
      });
    case types.CREATE_RESIDENT_FAIL:
      return state.merge({
        residentCreated: action.response, error : action.msg ? `, ${action.msg}` : ""
      });
    case types.UPDATE_RESIDENT_SUCCESS:
      return state.merge({
        resident: action.resident,
        residentUpdated: action.resident
          ? { status: true }
          : { status: false, error: "Problems updating member" }
      });
    case types.UPDATE_RESIDENT_FAIL:
      return state.merge({
        residentUpdated: action.response
      });
    case types.UPDATE_RESIDENT_APPROVAL_SUCCESS:
      return state.merge({
        resident: action.resident,
        residentApprovalUpdated: action.resident
          ? { status: true }
          : { status: false, error: "Problems updating member" }
      });
    case types.UPDATE_RESIDENT_APPROVAL_FAIL:
      return state.merge({
        residentApprovalUpdated: action.response
      });
    case types.SEND_REGISTRATION_ACTIVATION_SUCCESS:
      return state.merge({
        registerNotificationSent: action.result
      });
    default:
      return state;
  }
}

export function getResidents(state) {
  return state.residents.residents;
}

export function GetConciergeGroups(state) {
  return state.residents.residentGroups;
}



export function getSearchedResidents(state) {
  return state.residents.residentsSearchQueue;
}

export function getNewResident(state) {
  return state.residents.newResident;
}

export function residentCreatedStatus(state) {
  return state.residents.residentCreated;
}

export function residentUpdatedStatus(state) {
  return state.residents.residentUpdated;
}

export function residentUpdatedApprovalStatus(state) {
  return state.residents.residentApprovalUpdated;
}

export function registerNotificationSent(state) {
  return state.residents.registerNotificationSent;
}

export function residentError(state){
  return state.residents.error;
}