import * as types from "./actionTypes";
import Immutable from "seamless-immutable";

const initialState = Immutable({
  modules: [],
  statusCodes: [],
  portal: undefined
});

export default function serviceModuleReducer(
  state = initialState,
  action = {}
) {
  switch (action.type) {
    case types.MODULES_LOADED_SUCCESSFULLY:
      return state.merge({
        modules: action.modules
      });
    case types.PORTAL_SETTINGS_LOAD_SUCCESSFULLY:
      return state.merge({
        portal: action.settings
      });
    default:
      return state;
  }
}

export function loadModules(state) {
  return state.serviceModules.modules;
}

export function loadPortalSttings(state) {
  return state.serviceModules.portal;
}
