import _ from 'lodash';

export function formatString(str) {
	if (str.length > 20) {
		return str.substr(0, 10) + '...' + str.substr(str.length - 10, str.length);
	}
	return str;
}

/**
 * Concat array of string data and append  
 * @param {*} data 
 * @param {*} substringsize 
 */
export function formatStringList(data, substringsize = 0) {
	let result = '';
	if (data && data.length > 0) {
		_.map(data, (val, i) => {
			if (val) {
				result += `${data[data.length - 1] != val ? `${val}${', '}` : `${val}`}`;
			}
		});
	}
	if (substringsize > 0 && result.length > substringsize) {
		result = result.substring(0, substringsize) + '...';
	}

	return result;
}
