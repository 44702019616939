const config = {
	apiDomain: '#{API.Domain}',
	// bookingbugApi: "#{BookingBugAPI.Domain}",
	// bookingbugPwd: "#{BookingBugAPI.PWD}"
	userTimeOutInMinutes : 20,
	bookingbugApi: '',
	bookingbugPwd: ''
};

module.exports = config;
