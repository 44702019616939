import _ from "lodash";
import * as types from "./actionTypes";
import Immutable from "seamless-immutable";
import moment from "moment";


const initialState = Immutable({
  resources: undefined,
  services: [],
  query: {},
  servicesSlots: [],
  eventId: undefined,
  selectedServiceSlot: [],
  service: {},
  basketQueue: [],
  selectedSlots: [],
  serviceId: "",
  eventDate: undefined,
  selectedSlot: undefined,
  bookings: [],
  bookingsSelectionQueue: [],
  cancelReasons: [],
  bookingsCancelled: [],
  blockSlotSelected: [],
  timeAvailable: [],
  bookingUpdated: {},
  bookingErrors: [],
  selectedBookingDetail: {},
  notify: {},
  selectedEventsSlots: [],
  selectedEventResources: [],
  bookedEventSelected: undefined,
  bookingQuestions: [],
  resourceBlocked: undefined,
  bookingCreated: {},
  serviceUnit: undefined,
  resourceEvents: [],
  selectedTenant: undefined,
  actionRedirect: undefined,
  paymentAction: undefined,
  booking: undefined,
  calendarSettings: undefined,
  processingResourceRequest: undefined,
  itemQueue: []
});

export default function reducer(state = initialState, action = {}) {

  switch (action.type) {
    case types.SERVICES_FETCHED:
      return state.merge({
        services: action.services
      });
    case types.RESOURCES_QUERY:
      return state.merge({
        query: action.query
      });
    case types.SERVICES_SLOTS_FETCHED:
      return state.merge({
        servicesSlots: action.servicesSlots
      });
    case types.SLOTS_FETCHED:
      return state.merge({
        eventId: action.slotsData.eventId,
        selectedServiceSlot: action.slotsData.slots
      });
    case types.RESOURCE_EVENT_SET:
      return state.merge({
        eventId: action.eventId
      });
    case types.SERVICE_DETAIL_FETCHED:
      return state.merge({
        service: action.service
      });
    case types.ADD_TO_BASKET_QUEUE:
      //handling multiple selection
      // let current_basket = state.basketQueue;
      // let newBasket = _.compact(
      //   _.map(current_basket, o => {
      //     if (
      //       o.start_min >= action.slot.start_min &&
      //       o.end_min <= action.slot.end_min
      //     )
      //       return null;
      //     else return o;
      //   })
      // );

      // return state.merge({
      //   basketQueue: newBasket.concat(action.slot)
      // });
      return state.merge({
        basketQueue: [action.slot]
      });
    case types.REMOVE_FROM_BASKET_QUEUE:
      return state.merge({
        basketQueue: state.basketQueue.filter(slot => {
          return slot.id !== action.slot.id;
        })
      });
    case types.SLOT_SELECTED:
      return state.merge({
        selectedSlots: state.selectedSlots.concat(action.slot),
        selectedServiceSlot: state.selectedServiceSlot.map((s, i) => {
          if (s.id === action.slot.id) {
            return {
              isAvailable: false,
              inBasket: true,
              id: s.id,
              start: s.start,
              end: s.end,
              price: s.price,
              type: action.slot.type,
              start_min: action.slot.start_min,
              end_min: action.slot.end_min,
              duration: !_.isUndefined(action.stlot.duration) ? action.stlot.duration : 0
            };
          } else return s;
        })
      });

    case types.SLOT_DESELECTED:
      return state.merge({
        selectedServiceSlot: state.selectedServiceSlot.map((s, i) => {
          if (s.id === action.slot.id) {
            return {
              isAvailable: true,
              inBasket: false,
              id: s.id,
              start: s.start,
              end: s.end,
              price: s.price,
              start_min: s.start_min,
              end_min: s.end_min
            };
          } else return s;
        }),
        selectedSlots: state.selectedSlots.filter(s => s.id !== action.slot.id)
      });
    case types.SERVICE_ID_SELECTED:
      return state.merge({
        serviceId: action.serviceId
      });
    case types.EVENT_DATE_SELECTED:
      return state.merge({
        eventDate: action.eventDate
      });

    case types.SELECTED_SERVICE_SLOT:
      return state.merge({
        selectedServiceSlot: action.selectedServiceSlot
      });
    case types.CLEAR_BASKET_QUEUE:
      return state.merge({
        basketQueue: action.clear,
        selectedSlots: action.clear,
        selectedServiceSlot: action.clear
      });
    case types.RESOURCE_BOOKINGS_FETCHED:
      return state.merge({
        bookings: action.bookings
      });
    case types.ADD_TO_BOOKINGS_SELECTION_QUEUE:
      return state.merge({
        bookingsSelectionQueue: action.booking
      });
    case types.CANCEL_BOOKINGS_REASONS:
      return state.merge({
        cancelReasons: action.cancelReasons
      });
    case types.BOOKINGS_CANCELED_SUCCESS:
      return state.merge({
        bookingsCancelled: action.bookingsCancelled
      });
    case types.BLOCK_SLOT_SELECTED:
      let current_block = state.blockSlotSelected;

      let new_block = _.compact(
        _.map(current_block, o => {
          if (
            o.start_min >= action.slot.start_min &&
            o.end_min <= action.slot.end_min
          )
            return null;
          else return o;
        })
      );

      return state.merge({
        blockSlotSelected: new_block.concat(action.slot),

        selectedEventsSlot: _.map(state.selectedEventsSlot, (s, i) => {
          let blk_start_min = action.slot.start_min;
          let blk_end_min = action.slot.end_min;

          if (s.start_min >= blk_start_min && s.end_min <= blk_end_min) {
            return {
              isAvailable: false,
              inBasket: true,
              id: s.id,
              start: s.start,
              end: s.end,
              price: s.price,
              start_min: s.start_min,
              type: action.slot.type,
              end_min: s.end_min
            };
          } else return s;
        }),
        selectedSlots: _.compact(
          _.map(state.selectedSlots, s => {
            if (
              s.start_min >= action.slot.start_min &&
              s.end_min <= action.slot.end_min
            )
              return null;
            else return s;
          })
        )
      });
    case types.BLOCK_SLOT_DESELECTED:
      let slot_deselected = action.slot;
      let curr_basket = state.basketQueue;
      let basket = _.compact(
        _.map(curr_basket, o => {
          if (
            o.start_min >= slot_deselected.start_min &&
            o.end_min <= slot_deselected.end_min
          )
            return null;
          else return o;
        })
      );
      return state.merge({
        selectedEventsSlots: _.map(state.selectedEventsSlots, o => {
          if (
            o.start_min >= slot_deselected.start_min &&
            o.end_min <= slot_deselected.end_min
          ) {
            return {
              isAvailable: true,
              inBasket: false,
              id: o.id,
              start: o.start,
              end: o.end,
              price: o.price,
              start_min: o.start_min,
              end_min: o.end_min
            };
          } else return o;
        }),
        blockSlotSelected: _.filter(
          state.blockSlotSelected,
          blockItem => blockItem.id !== slot_deselected.id
        ),
        basketQueue: basket
      });
    case types.RESOURCE_TIME_AVAILABLE_FETCHED:
      return state.merge({
        timeAvailable: action.timesAvail
      });
    case types.UPDATE_BOOKING_SUCCESS:
      return state.merge({
        bookingUpdated: action.bookingStatus
      });

    case types.BOOKING_ERRORS_LOAD_SUCCESS:
      return state.merge({
        bookingErrors: action.errors
      });
    case types.BOOKING_DETAIL_LOAD_SUCCESS:
      return state.merge({
        selectedBookingDetail: action.detail
      });
    case types.BOOKING_CANCELLED_NOTIFY_SUCCESS:
      return state.merge({
        notify: action.status
      });
    case types.EVENTS_SLOTS_FETCHED:
      return state.merge({
        selectedEventResources: action.slotsData.events,
        selectedEventsSlots: action.slotsData.slots
      });
    case types.BOOKED_EVENT_SLOT_IS_SELECTED:
      let selected_event = action.event;
      let temp_slot = _.findLast(state.selectedEventsSlots, o => {
        let date = moment(o.start).format("YYYY-MM-DD");
        let sdate = moment(selected_event.start).format("YYYY-MM-DD");
        return date === sdate && o.booked === true
      });
      let new_slots = _.map(state.selectedEventsSlots, data => {
        if (data.id === selected_event.id) {
          return temp_slot;
        }
        if (data.id === temp_slot.id) {
          return selected_event;
        }
        return data;
      });

      return state.merge({
        selectedEventsSlots: new_slots
      });

    case types.BOOKING_QUESTIONS_LOAD_SUCCESS:
      return state.merge({
        bookingQuestions: action.questions
      });
    case types.RESOURCE_BLOCKED_SUCCESS:
      return state.merge({
        resourceBlocked: action.resource
      });
    case types.RESOURCE_BOOKED_SUCCESS:
      return state.merge({
        bookingCreated: action.booking
      });
    case types.RESOURCE_EVENTS_CLEARED:
      return state.merge({
        selectedEventResources: action.slotsData.events,
        selectedEventsSlots: action.slotsData.slots,
        bookings: action.slotsData.bookings
      });
    case types.SERVICE_UNIT_CHANGED:
      return state.merge({
        serviceUnit: action.unit
      });
    case types.RESOURCE_EVENT_SELECTED:
      return state.merge({
        resourceEvents: action.events
      });
    case types.SELECTED_TENANT_SET_SUCCESS:
      return state.merge({
        selectedTenant: action.tenant
      });
    case types.ACTION_REDIRCECT_SUCCESS:
      return state.merge({
        actionRedirect: action.status
      });
    case types.PAYMENT_ACTION_SUCCESS:
      return state.merge({
        paymentAction: action.paymentStatus
      });
    case types.SET_BBOOKING_SUCCESS:
      return state.merge({
        booking: action.booking
      });
    case types.CALENDAR_SETTINGS_SET:
      return state.merge({
        calendarSettings: action.settings
      });
    case types.PROCESSING_RESOURCE_REQUEST:
      return state.merge({
        processingResourceRequest: action.status
      });
    case types.BASKET_QUEUE_UPDATED_SUCCESSFULLY:
      // let updateQueue = state.basketQueue;
      // console.log("before que update", updateQueue);
      // let blockSlot = state.blockSlotSelected
      // if (action.queueData) {
      //   updateQueue = _.map(state.basketQueue, o => {

      //     if (o.id === action.queueData.id) {
      //       return action.queueData;
      //     }

      //     return o;
      //   });

      //   blockSlot = _.map(state.blockSlotSelected, bl => {

      //     if (bl.id === action.queueData.id) {
      //       return action.queueData;
      //     }

      //     return bl;
      //   });

      //   console.log("after que update", updateQueue);
      // }
      return state.merge({
        itemQueue: [action.data],
        //blockSlotSelected: blockSlot
      });
    default:
      return state;
  }
}

export function getServices(state) {
  return state.resources.services;
}

export function getResourceQuery(state) {
  return state.resources.query;
}
export function getServicesSlots(state) {
  return state.resources.servicesSlots;
}

export function getSelectedServiceSlot(state) {
  return state.resources.selectedServiceSlot;
}
export function getService(state) {
  return state.resources.service;
}

export function getSelectedSlots(state) {
  return state.resources.selectedSlots;
}

export function getBasketQueue(state) {
  return state.resources.basketQueue;
}

export function getEventId(state) {
  return state.resources.eventId;
}

export function getServiceId(state) {
  return state.resources.serviceId;
}

export function getEventDate(state) {
  return state.resources.eventDate;
}

export function getBookings(state) {
  return state.resources.bookings;
}

export function getBookingsSelectionQueue(state) {
  return state.resources.bookingsSelectionQueue;
}

export function getBookingsCancelReasons(state) {
  return state.resources.cancelReasons;
}

export function getBookingsCancelled(state) {
  return state.resources.bookingsCancelled;
}

export function getBlockSlotSelected(state) {
  return state.resources.blockSlotSelected;
}

export function getBookingErrors(state) {
  return state.resources.bookingErrors;
}

export function getTimeAvailable(state) {
  return state.resources.timeAvailable;
}

export function getSelectedBookingDetail(state) {
  return state.resources.selectedBookingDetail;
}

export function getBookingUpdated(state) {
  return state.resources.bookingUpdated;
}
export function vision_type(state) {
  return state.resources.vision_type;
}
export function current_view(state) {
  return state.resources.current_view;
}


export function getSelectedEventsSlots(state) {
  return state.resources.selectedEventsSlots;
}

export function getSelectedEventResources(state) {
  return state.resources.selectedEventResources;
}

export function getBookingQuestions(state) {
  return state.resources.bookingQuestions;
}

export function getResourceBlocked(state) {
  return state.resources.resourceBlocked;
}

export function getBookingCreated(state) {
  return state.resources.bookingCreated;
}

export function getServiceUnit(state) {
  return state.resources.serviceUnit;
}

export function getResourceEvents(state) {
  return state.resources.resourceEvents;
}

export function getSelectedTenant(state) {
  return state.resources.selectedTenant;
}

export function getActionRedirect(state) {
  return state.resources.actionRedirect;
}

export function getPaymentAction(state) {
  return state.resources.paymentAction;
}

export function getSetBooking(state) {
  return state.resources.booking;
}


export function getCalendarSettings(state) {
  return state.resources.calendarSettings;
}

export function getResourceProcessingStatus(state) {
  return state.resources.processingResourceRequest;
}
export function getItemQueue(state){
  return state.resources.itemQueue;
}