import * as config from "./config";
import authService from "./loginService";
import _ from "lodash";

const CONC_MODULE = "conc_module";
class ServiceModules {
  async getModules() {
    const url = `${config.MODULES_ENDPOINT}`;
    const authToken = authService.getToken();

    var requestHeader = new Headers();
    requestHeader.append("Authorization", "Bearer " + authToken);

    const response = await fetch(url, {
      method: "GET",
      headers: requestHeader
    });
    if (!response.ok) {
      // config.errorPageRedirect(response);
      throw new Error(
        `get modules service failed, HTTP status ${response.status}`
      );
    }

    const result = await response.json();

    this.setServiceModules(_.get(result, "modules"));

    return {
      modules: _.get(result, "modules")
    };
  }

  async getPortalSettings() {
    const url = `${config.PORTAL_ENPOINT}`;
    const authToken = authService.getToken();

    var requestHeader = new Headers();
    requestHeader.append("Authorization", "Bearer " + authToken);

    const response = await fetch(url, {
      method: "GET",
      headers: requestHeader
    });

    if (!response.ok) {
      // config.errorPageRedirect(response);
      throw new Error(
        `get modules service failed, HTTP status ${response.status}`
      );
    }

    const result = await response.json();

    return result;
  }
  setServiceModules(module) {
    localStorage.setItem(CONC_MODULE, JSON.stringify(module));
  }

  getServiceModules() {
    return localStorage.getItem(CONC_MODULE);
  }

  clearServiceModule() {
    localStorage.removeItem(CONC_MODULE);
  }
}

export default new ServiceModules();
