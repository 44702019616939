import React, { Component } from "react";
import { connect } from "react-redux";
import autobind from "react-autobind";
import _ from "lodash";
import moment from "moment";
import * as $ from "jquery";
import {withRouter} from 'react-router-dom';
import FooterNavigation from "../../../components/FooterNavigation/FooterNavigation";
import FeatureArea from "../../../components/FeatureArea/FeatureArea";

import ButtonGroup from "../../../components/Button/ButtonGroup";
import InputComponent from "../../../components/Input/Input";
import AddressLookUp from "../../../components/AddrLookUpAutoSuggest/AddrLookUpAutoSuggest";

import ResidentVisitorAutoSelect from "../../../components/TenantAutoSuggest/ResidentVisitorAutoSuggest";
import * as keysActions from "../../../store/Keys/actions";
import * as keySelectors from "../../../store/Keys/reducer";
import * as addressLookupActions from "../../../store/AddressLookUp/actions";
import * as addressLookupSelectors from "../../../store/AddressLookUp/reducer";
import * as residentActions from "../../../store/Residents/actions";
import * as residentSelectors from "../../../store/Residents/reducer";
import * as moduleSelectors from "../../../store/ServiceModules/reducer";
import * as visitorSelectors from "../../../store/Visitors/reducer";

import "../../../sass/containers/Keys/index.css";
import "../../../sass/components/DateTimePicker/index.css";

class KeyAssignScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "keys",
      validSearch: false,
      keyList: [],
      overlayColor: "",
      keyId: !_.isUndefined(this.props.location.state)
        ? this.props.location.state.keyId
        : "",
      phoneNumber: "",
      email: "",
      currentDate: moment(new Date()).format("DD/MM/YYYY"),
      currentTime: moment(new Date()).format("HH:mm "),
      expectedReturnDate: "",
      name: "",
      addresslookupId: "",
      address: "",
      selectedKey: undefined,
      tenant: "",
      tenantId: "",
      selectedTenant: undefined,
      selectedVisitor: undefined,
      selectedDate: moment(),
      status: ""
    };

    this.props.dispatch(keysActions.getAllKeys());
    this.props.dispatch(keysActions.getKeyStatuses());
    this.props.dispatch(residentActions.getAllResidents());
    this.props.dispatch(addressLookupActions.loadAddresses());
    this.assignKeyValidation = null;
    autobind(this);
  }

  componentWillMount() {
    
  }

  componentDidMount() {
    this.addValidation();

    let overlayColor;
    let currentModule = _.find(this.props.modules, o => o.title === "Keys");
    overlayColor = currentModule && currentModule.imageOverlayTextColour;

    this.setState({
      overlayColor: overlayColor
    });
  }

  componentWillUnmount() {
    this.assignKeyValidation.destroy();
  }
  componentWillUpdate(nextProps, nextState) {
   

    // if(this.state.selectedVisitor !== nextState.selectedVisitor && _.isUndefined(nextState.selectedVisitor)){
    //   this.setState({
    //     email: ""
    //   });
    // }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.keys !== this.props.keys && this.props.keys.length > 0) {
      let currentKey = _.find(this.props.keys, { id: this.state.keyId });
      this.setState({
        selectedKey: currentKey
      });
    }
    if (
      this.state.selectedVisitor !== prevState.selectedVisitor &&
      !_.isUndefined(this.state.selectedVisitor)
    ) {
      this.setState({
        email: this.state.selectedVisitor.email
      });
    }

    if (
      this.state.selectedTenant !== prevState.selectedTenant &&
      !_.isUndefined(this.state.selectedTenant)
    ) {
      this.setState({
        phoneNumber: this.state.selectedTenant.mobileNumber,
        email: this.state.selectedTenant.email
      });
    }

    if (
      (this.state.selectedTenant !== prevState.selectedTenant &&
        _.isUndefined(this.state.selectedTenant)) ||
      (this.state.selectedVisitor !== prevState.selectedVisitor &&
        _.isUndefined(this.state.selectedVisitor))
    ) {
      this.setState({
        email: "",
        phoneNumber: ""
      });
    }
  }

  handleChange(e) {
    var field = e.target.name;
    var value = e.target.value;
    this.setState({
      [field]: value
    });
  }

  handleDateChange(val) {
    this.setState({
      selectedDate: val
    });
  }

  addValidation() {
    this.assignKeyValidation = $(this.refs.validate).parsley({
      classHandler: function (el) {
        return el.$element.closest("div:not(.input-wrapper):not(.Select)");
      }
    });
  }
  validate(fromSubmit = false) {
    this.assignKeyValidation.validate({ force: true });
    const valid = this.assignKeyValidation.isValid();

    this.setState({
      assignKeyFormValid: valid,
      assignKeyFormUsed: true
    });

    if (valid === true && fromSubmit === true) {
      this.formReset();

      this.handleAssign();
      this.setState({
        assignKeyFormUsed: false
      });
    }
  }
  formReset() {
    this.setState({
      email: "",
      addresslookupId: "",
      phoneNumber: "",
      address: "",
      tenant: "",
      tenantId: "",
      selectedTenant: undefined,
      selectedVisitor: undefined,
      selectedDate: moment()
    });

    //deprecated 
    //this.refs["tenant-auto-suggest"].getWrappedInstance().reset();
    //this.refs["addr-auto-suggest"].getWrappedInstance().reset();

    $(this.refs.validate)
      .get(0)
      .reset();

    this.assignKeyValidation.destroy();

    // Reset all the select input components to the placeholder value
    for (let key in this.refs) {
      if (key.indexOf("formSelect") === 0) {
        this.refs[key].reset();
      }
    }

    setTimeout(() => {
      this.addValidation();
    }, 0);
  }
  handleAssign() {
    const { selectedKey, selectedTenant } = this.state;

    let data;
    if (selectedKey) {
      data = {
        keyId: selectedKey.id,
        memberId: this.state.tenantId,
        expectedDateReturn: this.state.selectedDate,
        recipientType: !_.isUndefined(selectedTenant)
          ? this.state.selectedTenant.memberType
          : !_.isUndefined(this.state.selectedVisitor) ? "Visitor" : "",
        visitorId: !_.isUndefined(this.state.selectedVisitor)
          ? this.state.selectedVisitor.id
          : ""
      };

      this.props.dispatch(
        keysActions.assignKey(data, success => {
          if (success === true) {

            let notifyData = {
              keyId: data.keyId,
              memberId: data.memberId,
              dateAssigned: moment().format("YYYY-MM-DD HH:mm"),
              visitorId: data.visitorId,
              recipientType: data.recipientType
            };
            this.props.dispatch(keysActions.sendKeyAssignedNotification(notifyData, status => {
              if (status === true) {
                setTimeout(() => {
                  this.props.history.push({
                    pathname: "/",
                    state: { module: "Keys" }
                  });
                }, 1001);
                window.scrollTo(0, 0);
              } else {
                this.setState({
                  error: "unable to send email"
                })
              }
            }))

          } else {
            this.setState({
              error: "Sorry! unable to assign key! "
            });
          }
        })
      );
    }
  }
  render() {
    const {
      phoneNumber,
      email,
      tenant,
      selectedTenant,
      currentDate,
      currentTime,
      selectedDate
    } = this.state;

    const currentModule = _.find(this.props.modules, o => o.title === "Keys");

    // console.log(this.props);
    // console.log(this.state);
    return (
      <div>
        <div className={"container key_assign"}>
          <FeatureArea
            type={this.state.type}
            areaTagline="Please enter information below"
            pageHeader="Assign Key"
            areaTitle="Keys"
            overlayColor={currentModule && currentModule.imageOverlayTextColour}
            landing={false}
						activeMenu={true}
          />

          {
            <form
              className="content-wrapper double-margin-top"
              ref="validate"
              onSubmit={e => {
                e.preventDefault();
              }}
              data-parsley-errors-container="#validation-messages"
              data-parsley-focus="none"
            >
              <div className="inputs" style={{ marginTop: 4 }}>
                {/**Uncoment to replace, component includes visitor on lookup */}
                <ResidentVisitorAutoSelect
                  ref="tenant-auto-suggest"
                  label="Name"
                  placeHolder="Look up"
                  clearableIfValid={true}
                  value={tenant}
                  onSelected={data => {
                    this.setState({
                      tenantId: data.memberId,
                      tenant: data.tenant,
                      selectedTenant:
                        data.type !== "Visitor"
                          ? _.find(
                            this.props.residents,
                            o => o.id === data.memberId
                          )
                          : undefined,
                      selectedVisitor:
                        data.type === "Visitor"
                          ? _.find(
                            this.props.visitors,
                            x => x.id === data.memberId
                          )
                          : undefined
                    });
                    setTimeout(() => {
                      this.state.assignKeyFormUsed && this.validate();
                    }, 0);
                  }}
                  defaultText={dataVal => { }}
                />
              </div>
              <div className="inputs two-column marginKeys" style={{ marginTop: 4 }}>
                <InputComponent
                  label="Phone Number"
                  name="phoneNumber"
                  placeHolder="phone number"
                  handleChange={e => {
                    this.handleChange(e);
                  }}
                  value={
                    !_.isUndefined(selectedTenant)
                      ? !selectedTenant.mobileNumber ? "" : selectedTenant.mobileNumber
                      : !phoneNumber ? "" : phoneNumber
                  }
                  parsley={{
                   // "data-parsley-type": "digits",
                    "data-parsley-minlength": 11,
                    "data-parsley-error-message":
                      "Please enter a valid phone number"
                  }}
                />
                <InputComponent
                  label="Email"
                  name="email"
                  placeHolder="email"
                  handleChange={e => {
                    this.handleChange(e);
                  }}
                  value={
                    !_.isUndefined(selectedTenant)
                      ? selectedTenant.email
                      : email
                  }
                  parsley={{
                    "data-parsley-type": "email",
                    "data-parsley-validate-if-empty": false,
                    "data-parsley-error-message": "Please enter a valid email"
                  }}
                />
              </div>
              <div className="inputs" >
                <AddressLookUp
                  ref="addr-auto-suggest"
                  label="Key Address"
                  placeHolder="Search Address"
                  clearableIfValid={false}
                  //value={address}
                  defaultAddressId={
                    !_.isUndefined(this.state.selectedKey)
                      ? this.state.selectedKey.lookupAddressId
                      : undefined
                  }
                  onSelected={data => {
                    this.setState({
                      addresslookupId: data.addresslookupId,
                      address: data.address
                    });
                    setTimeout(() => {
                      this.state.assignKeyFormUsed && this.validate();
                    }, 0);
                  }}
                  defaultText={dataVal => { }}
                />
              </div>
              <div className="inputs two-column" style={{ marginTop: 4 }}>
                <div className="inputs two-column">
                  <InputComponent
                    label="Date"
                    value={currentDate}
                    handleChange={e => this.handleChange(e)}
                  />
                  <InputComponent
                    label="Time"
                    value={currentTime}
                    handleChange={e => this.handleChange(e)}
                  />
                </div>

                <InputComponent
                  type="datePicker"
                  label="Expected Return"
                  name="selectedDate"
                  defaultMin={moment()}
                  defaultSelect={selectedDate}
                  handleDateChange={this.handleDateChange}
                />
              </div>
              <div
                className={
                  "validation-messages" +
                  (this.state.assignKeyFormValid ? " valid" : " invalid")
                }
                id="validation-messages"
              >
                <div>
                  {this.props.keyActionStatus &&
                    !_.isUndefined(this.props.keyActionStatus.action) &&
                    (this.props.keyActionStatus.action === true ? (
                      <div> <p className="message type-success">Key Assigned.</p> {!_.isUndefined(this.props.notifyStatus) && this.props.notifyStatus === true ? <p className="message type-success">Notification sent... </p> :
                        <p className="message error">{_.isUndefined(this.props.notifyStatus) ? "" : "Notification unsuccessfull..."}</p>}</div>
                    ) : (
                        this.state.error && (
                          <p className="message error"> {this.state.error}</p>
                        )
                      ))}
                </div>
              </div>
              <div className="align-center double-margin-top">
                <ButtonGroup
                  sizes={["smedium", "medium"]}
                  leftText={this.props.keyActionStatus && !_.isUndefined(this.props.keyActionStatus.action) &&
                    this.props.keyActionStatus.action === true ? "Back to home" : "Cancel"}
                  rightText="Assign Key"
                  leftClick={() => {
                    this.props.history.push({
                      pathname: "/",
                      state: { module: "Keys" }
                    });
                  }}
                  rightClick={e => {
                    this.validate(true);
                  }}
                />
              </div>
            </form>
          }

          {/**End of key assign section */}
        </div>
        <FooterNavigation />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    keys: keySelectors.loadAllKeys(state),
    addressLookup: addressLookupSelectors.getAddresses(state),
    residents: residentSelectors.getResidents(state),
    keyActionStatus: keySelectors.getKeyActionStatus(state),
    visitors: visitorSelectors.getVisitors(state),
    modules: moduleSelectors.loadModules(state),
    notifyStatus: keySelectors.getNotificationSentsuccess(state)
  };
}

export default withRouter(connect(mapStateToProps, null, null, { forwardRef: true })(
  KeyAssignScreen
));
