import React, { Component } from 'react';

import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import autobind from 'react-autobind';
import InputComponent from '../../../components/Input/Input';
//import ServicesListMulti from "../../../components/ServiceList/ServiceListMulti";
import ServicesListPager from '../../../components/ServiceList/ServiceListPager';
import MonthScreen from './MonthScreen';
import DailyScreen from './DailyScreen';
import * as authSelectors from '../../../store/auth/reducer';
import * as resourceActions from '../../../store/Resources/actions';
import * as resourceSelectors from '../../../store/Resources/reducer';
import * as resourceMonthlyActions from '../../../store/ResourcesMonthly/actions';
import * as resourceMonthlySelectors from '../../../store/ResourcesMonthly/reducer';
import * as tenantActions from '../../../store/Tenants/actions';
import * as pageEventActions from '../../../store/PageEvents/actions';
import * as checkoutActions from '../../../store/Checkout/actions';
import {withRouter} from "react-router-dom";
import '../../../sass/containers/Bookings/index.css';

const ServiceUnitType = {
	Day: 'day',
	Minute: 'minute'
};

const ResourcePager = {
	page: 1,
	per_page: 387
};
const MAX_DATE_BOOKINGS = 7;
class BookingsScreen extends Component {
	constructor(props) {
		super(props);

		this.state = {
			type: 'bookings',
			now: moment(),
			unitType: '',
			view: undefined,
			serviceId: '',
			selectedService: undefined,
			resourceOptions: [],
			fromRedirect: undefined,
			resourceProcessing: undefined,
			events: [],
			lastscrollTop: 0,
			max_day_bookings: MAX_DATE_BOOKINGS,
			minCalTime: '09:00 am',
			maxCalTime: '18:00 pm'
		};

		this.props.dispatch(resourceActions.getServices());
		this.props.dispatch(tenantActions.getAllTenants());
		autobind(this);
	}

	componentDidMount() {
		const { serviceId, fromRedirect, paymentStatus, selectedResourceId, paymentSetDate } = this.props;
		const { type } = this.state;

		this.props.dispatch(pageEventActions.setPageEvent(type));
		this.props.dispatch(checkoutActions.loadBasketFromCache());

		this.props.dispatch(resourceMonthlyActions.fetchResources(ResourcePager.page, ResourcePager.per_page));
	
		if (fromRedirect && fromRedirect === true && !_.isUndefined(paymentStatus)) {
			this.setState({
				serviceId: serviceId,
				fromRedirect: fromRedirect,
				paymentStatus: paymentStatus,
				eventId: selectedResourceId,
				paymentEventSetDate: paymentSetDate
			});

			let data = {
				event_date: paymentSetDate
			};
			this.props.dispatch(resourceActions.setCarlendarSettings(data));
		} else {
		}

		// window.addEventListener("scroll", this.handleScroll);

		this.props.dispatch(resourceActions.clearBasketQueue());
	}
	// componentWillMount() {
	// 	const { type } = this.state;
	// 	this.props.dispatch(pageEventActions.setPageEvent(type));
	// 	this.props.dispatch(checkoutActions.loadBasketFromCache());

	// 	this.props.dispatch(resourceMonthlyActions.fetchResources(ResourcePager.page, ResourcePager.per_page));
	// }
	componentWillUnmount() {
		this.props.dispatch(resourceActions.setActionRedirect(undefined));
		this.props.dispatch(resourceActions.setPaymentAction(undefined));
		// window.removeEventListener('scroll', this.updateDimensions);
	}
	componentDidUpdate(prevProps, prevState) {
		//set bookings state comming from payment or other redirect actions
		//step 1
		if (
			prevProps.services !== this.props.services &&
			!_.isUndefined(this.props.services) &&
			this.props.services.length > 0 &&
			this.state.fromRedirect === true
		) {
			
			let setSelectedService = _.find(this.props.services, { id: this.state.serviceId });
			let unit = !_.isUndefined(setSelectedService) ? setSelectedService.duration_unit : '';

			this.setState({
				selectedService: setSelectedService,
				unitType: !_.isUndefined(setSelectedService) ? setSelectedService.duration_unit : '',
				services: this.props.services
			});

			this.setServiceDetail(this.state.serviceId);
			if (unit === ServiceUnitType.Day) {
				var date_ = this.state.now.toDate(),
					y = date_.getFullYear(),
					m = date_.getMonth();
				var startDay = new Date(y, m, 1);
				var endDay = new Date(y, m + 1, 0);

				startDay = moment(startDay).subtract(this.state.max_day_bookings, 'd');
				endDay = moment(endDay).add(7, 'd');

				this.servicesDayData(
					this.props.services[0].id,
					startDay.format('YYYY-MM-DD'),
					endDay.format('YYYY-MM-DD')
				);
			} 
			if (setSelectedService) {

				if(unit === ServiceUnitType.Minute){
						
						if (setSelectedService.extra && setSelectedService.extra.mincal && setSelectedService.extra.maxcal) {
							var minTime = moment(this.time_convert(Number(setSelectedService.extra.mincal)), 'LT').format('HH:mm a');
							var maxTime = moment(this.time_convert(Number(setSelectedService.extra.maxcal)), 'LT').format('HH:mm a');
							this.setState({
								minCalTime: minTime,
								maxCalTime: maxTime
							});
						} else {
							this.setState({
								minCalTime: '09:00 am',
								maxCalTime: '18:00 pm'
							});
						}
				}

				let detailGrpId = !_.isUndefined(setSelectedService.detail_group_id)
					? setSelectedService.detail_group_id
					: undefined;
				this.loadBookingQuestions(detailGrpId);
			}

			this.props.dispatch(resourceActions.setActionRedirect(this.state.fromRedirect));
			this.props.dispatch(resourceActions.setPaymentAction(this.state.paymentStatus));
		}

		//refetch slots service data from redirect action triggered
		//step 2
		if (prevProps.monthlyServicesSlots !== this.props.monthlyServicesSlots &&
				this.props.monthlyServicesSlots &&
				this.props.monthlyServicesSlots.length > 0 &&
			this.state.fromRedirect === true
		) {
			let options = [];

			 if (this.state.unitType === ServiceUnitType.Day) {
				options = _.map(this.props.monthlyServicesSlots, (item) => {
					return { eventName: item.name, eventId: item.event_id };
				});
			
			let selected_event_resource = [];
			//reform selected resource to usable object values
			_.map(this.props.selectedResourcesId, (o) => {
				let curre_event = _.find(options, { eventId: o });
				if (!_.isUndefined(curre_event)) {
					selected_event_resource.push(curre_event);
				}
				return o;
			});

			this.setState({
				events: selected_event_resource
				// events: _.filter(selected_event_resource, ev => ev.eventId === this.props.selectedResourceId), //for single resource load, to do creat reducer for selected event
			});

				this.props.dispatch(
					resourceActions.setResourceEventsSelected(selected_event_resource, (success) => {
						if (success === true) {
							this.setState({
								fromRedirect: undefined
							});
						} else {
						}
					})
				);

			}
		}

		if (prevState.resourceOptions !== this.state.resourceOptions && this.state.resourceOptions && this.state.resourceOptions.length > 0) {
			
			if (this.state.events.length > 0) {
				let current_events = _.map(this.state.events, (event) => {
					let item = _.find(this.state.resourceOptions, { name: event.eventName });
					return { eventName: item.name, eventId: item.eventid };
				});
				this.setState({
					resourceProcessing: undefined,
					events: current_events
				});

				this.props.dispatch(
					resourceActions.setResourceEventsSelected(current_events, (success) => {
						if (success === true) {
						} else {
						}
					})
				);
			}
		}

		//initial load of bookings, not from redirect
		if (
			this.props.services !== prevProps.services &&
			!_.isUndefined(this.props.services) &&
			this.props.services.length > 0 &&
			(_.isUndefined(this.props.fromRedirect) || this.props.fromRedirect === false)
		) {
			if (this.props.services.length === 1) {
				var service = this.props.services[0];
				let unit = service.duration_unit;

				let service_id = service.id;
				this.setState({
					serviceId: service_id,
					selectedService: service,
					unitType: unit,
					resourceProcessing: true
				});
				if (unit === ServiceUnitType.Minute) {
					if (service.extra && service.extra.mincal && service.extra.maxcal) {
						var minTime = moment(this.time_convert(Number(service.extra.mincal)), 'LT').format('HH:mm a');
						var maxTime = moment(this.time_convert(Number(service.extra.maxcal)), 'LT').format('HH:mm a');
						this.setState({
							minCalTime: minTime,
							maxCalTime: maxTime
						});
					} else {
						this.setState({
							minCalTime: '09:00 am',
							maxCalTime: '18:00 pm'
						});
					}
				} else if (unit === ServiceUnitType.Day) {
					var date_ = this.state.now.toDate(),
						y = date_.getFullYear(),
						m = date_.getMonth();
					var startDay = new Date(y, m, 1);
					var endDay = new Date(y, m + 1, 0);

					startDay = moment(startDay).subtract(this.state.max_day_bookings, 'd');
					endDay = moment(endDay).add(7, 'd');

					this.servicesDayData(
						this.props.services[0].id,
						startDay.format('YYYY-MM-DD'),
						endDay.format('YYYY-MM-DD')
					);
				}

				this.loadBookingQuestions(this.props.services[0].detail_group_id);
			}
		}
		if (this.state.serviceId !== prevState.serviceId && this.state.serviceId) {
			this.setServiceDetail(this.state.serviceId);
		}

		if (this.state.unitType !== prevState.unitType && this.state.unitType) {
			this.setServiceUnit(this.state.unitType);
		}


		if (
			this.props.servicesSlots !== prevProps.servicesSlots &&
			this.props.servicesSlots &&
			this.props.servicesSlots.length > 0 &&
			this.state.unitType === ServiceUnitType.Minute
		) {
			let data = _.uniqBy(this.props.servicesSlots, function(e) {
				return e.event_id;
			});

			let service_options = _.map(data, (item) => {
				return { name: item.name, eventid: item.event_id };
			});

			this.setState({
				resourceOptions: service_options,
				
			});
		}

		//resources state data, prefetched
		//this.props.resources removed (previous implementation)
		if (
			this.props.monthlyServicesSlots !== prevProps.monthlyServicesSlots &&
			!_.isUndefined(this.props.monthlyServicesSlots) &&
			this.props.monthlyServicesSlots.length > 0 &&
			this.state.unitType === ServiceUnitType.Day
		) {
			let service_options = _.map(this.props.monthlyServicesSlots, (item) => {
				return { name: item.name, eventid: item.event_id };
			});
			let defaultEvents = [];

			//trigger this on initial bookings module landing and excluding payment journey / redirect
			if (
				this.props.conciergeMember &&
				this.props.conciergeMember.home_development &&
				this.props.conciergeMember.home_development.length > 0 &&
				_.isUndefined(this.props.fromRedirect)
			) {
				defaultEvents = _.filter(
					_.map(this.props.conciergeMember.home_development, (x) => {
						let res = _.find(this.props.monthlyServicesSlots, { resource_id: x });
						if (res) {
							return { eventName: res.name, eventId: res.event_id };
						}
						return undefined;
					}),
					(o) => !_.isUndefined(o)
				);

				this.setState({
					events: defaultEvents
				});
			}
		
			this.setState({
				resourceOptions: _.filter(service_options, item => item.name !== ""),
				fromRedirect : undefined
			});
		}

	
		if (this.state.now !== prevState.now && !_.isUndefined(this.state.serviceId)) {
			if (this.state.unitType === ServiceUnitType.Day) {
				//start date of month change was triggered, update month daydata and resource options accordinly
				var dateVal = this.state.now.toDate(),
					yr = dateVal.getFullYear(),
					mth = dateVal.getMonth();
				var startDay_ = new Date(yr, mth, 1);
				var endDay_ = new Date(yr, mth + 1, 0);

				startDay_ = moment(startDay_).subtract(this.state.max_day_bookings, 'd');
				endDay_ = moment(endDay_).add(7, 'd');

				this.servicesDayData(
					this.state.serviceId,
					startDay_.format('YYYY-MM-DD'),
					endDay_.format('YYYY-MM-DD')
				);
			}
		}

		if (prevProps.servicesSlots !== this.props.servicesSlots &&
			this.props.servicesSlots && this.props.servicesSlots.length > 0 &&
			this.state.fromRedirect === true
		) {
			let options = [];

			if (this.state.unitType === ServiceUnitType.Minute) {
				options = _.map(this.props.servicesSlots, (item) => {
					return { eventName: item.name, eventId: item.event_id };
				});
			
			let selected_event_resource = [];
			//reform selected resource to usable object values
			_.map(this.props.selectedResourcesId, (o) => {
				let curre_event = _.find(options, { eventId: o });
				if (!_.isUndefined(curre_event)) {
					selected_event_resource.push(curre_event);
				}
				return o;
			});

			this.setState({
				events: selected_event_resource
			});

				this.props.dispatch(
					resourceActions.setResourceEventsSelected(selected_event_resource, (success) => {
						if (success === true) {
							this.setState({
								fromRedirect: undefined
							});
						} else {
						}
					})
				);
			}
		}
		
	}

	//method to handle servcies selection change
	handleServiceSelected(option) {
		let service_id = option.target.value;

		let serv = _.find(this.props.services, { id: service_id });
		let unitType = !_.isUndefined(serv) ? serv.duration_unit : '';

		var date_ = this.state.now.toDate(),
			y = date_.getFullYear(),
			m = date_.getMonth();
		var startDay = new Date(y, m, 1);
		var endDay = new Date(y, m + 1, 0);

		let max_booking =
			!_.isUndefined(serv) && !_.isUndefined(serv.max_bookings) && serv.max_bookings > MAX_DATE_BOOKINGS
				? serv.max_bookings
				: MAX_DATE_BOOKINGS;
		startDay = moment(startDay).subtract(max_booking, 'd');
		endDay = moment(endDay).add(7, 'd');

		this.setState({
			selectedService: serv,
			serviceId: service_id,
			unitType: unitType,
			resourceProcessing: true,
			max_day_bookings: max_booking
		});

		this.setServiceDetail(service_id);
		this.setServiceUnit(serv.duration_unit);
		this.props.dispatch(resourceActions.setServiceId(service_id));
		if (unitType === ServiceUnitType.Minute) {
			if (serv.extra && serv.extra.mincal && serv.extra.maxcal) {
				var minTime = moment(this.time_convert(Number(serv.extra.mincal)), 'LT').format('HH:mm a');
				var maxTime = moment(this.time_convert(Number(serv.extra.maxcal)), 'LT').format('HH:mm a');
				this.setState({
					minCalTime: minTime,
					maxCalTime: maxTime
				});
			} else {
				this.setState({
					minCalTime: '09:00 am',
					maxCalTime: '18:00 pm'
				});
			}
		} else if (unitType === ServiceUnitType.Day) {
			this.servicesDayData(service_id, startDay.format('YYYY-MM-DD'), endDay.format('YYYY-MM-DD'));
		}

		let detailGrpId = !_.isUndefined(serv.detail_group_id) ? serv.detail_group_id : undefined;
		this.loadBookingQuestions(detailGrpId);
		this.props.dispatch(
			resourceActions.setResourceEventsSelected([], (success) => {
				if (success === true) {
				} else {
				}
			})
		);
		this.setState({
			resourceOptions: [],
			events: []
		});
	}

	loadBookingQuestions(detail_group_id) {
		this.props.dispatch(resourceMonthlyActions.fetchBookingQuestions(detail_group_id));
	}
	setServiceDetail(serviceId) {
		this.props.dispatch(resourceActions.fetchServiceDetail(serviceId));
	}
	setServiceUnit(unit) {
		this.props.dispatch(resourceActions.setServiceUnit(unit));
	}

	servicesDayData(serviceId, startDate, endDate) {
		this.props.dispatch(
			resourceMonthlyActions.fetchServiceDayData(serviceId, startDate, endDate, (success) => {
				if (success === true) {
					this.fetchMonthlyServicesSlots(serviceId, moment(startDate), endDate);
				}
			})
		);
	}

	fetchMonthlyServicesSlots(serviceId, startDate, endDate) {

		this.props.dispatch(
			resourceMonthlyActions.fetchServicesSlots(
				serviceId,
				moment(startDate).format('YYYY-MM-DD'),
				moment(endDate).format('YYYY-MM-DD'),
				(success) => {
					if (success === true) {
						this.setState({
							resourceProcessing: undefined
						});
					} else {
					}
				}
			)
		);
	}

	trigerdDateChanged(date) {
		this.setState({
			now: date
		});
	}

	//screen selectors for monthly or daily type
	showView(type) {
		let properties = {
			sericeUnit: type,
			setDate: this.state.paymentEventSetDate,
			minCalTime: this.state.minCalTime,
			maxCalTime: this.state.maxCalTime
		};
		
		switch (type) {
			case ServiceUnitType.Day:
				return <MonthScreen {...properties} dateChanged={(date) => this.trigerdDateChanged(date)} />;
			case ServiceUnitType.Minute:
				return <DailyScreen {...properties} />;
			default:
				return this.showBlank();
		}
	}

	/**default blank section used on fallback on screen shown */
	showBlank() {
		return <div>No services availaible..</div>;
	}

	handleResourceChange(val) {
		const { servicesSlots, monthlyServicesSlots } = this.props;
		const { unitType } = this.state;

		let options = [];

		if (unitType === ServiceUnitType.Minute) {
			options = _.map(servicesSlots, (item) => {
				return { eventName: item.name, eventId: item.event_id };
			});
		} else if (unitType === ServiceUnitType.Day) {
			options = _.map(monthlyServicesSlots, (item) => {
				return { eventName: item.name, eventId: item.event_id };
			});
		}

		let selected_event_resource = [];

		//reform selected resource to usable object values
		_.map(val, (o) => {
			let curre_event = _.find(options, { eventId: o });
			if (!_.isUndefined(curre_event)) {
				selected_event_resource.push(curre_event);
			}
			return o;
		});

		this.props.dispatch(
			resourceActions.setResourceEventsSelected(selected_event_resource, (success) => {
				if (success === true) {
				} else {
				}
			})
		);

		this.setState({
			events: selected_event_resource
		});
	}

	time_convert(num) {
		var hours = Math.floor(num / 60);
		var minutes = num % 60;
		return `${hours}${':'}${minutes}`;
	}

	render() {
		const { services } = this.props;

		let services_options = [];

		//format services option for dropdown
		if (services && services.length > 0) {
			services_options = _.map(services, (serv) => {
				return { label: serv.name, value: serv.id };
			});
		}
		
		return (
			<div>
				<div className="row-content">
					{/**
                         Services dropdown option and resources dropdown option placement
                         * 
                         */}
					<div className="row-item">
						<InputComponent
							type="select"
							name="service"
							ref="formSelect-Services"
							label="Service"
							options={services_options}
							placeHolder="Pick Service"
							value={!_.isUndefined(this.state.serviceId) ? this.state.serviceId.toString() : undefined}
							handleChange={(e) => this.handleServiceSelected(e)}
						/>
					</div>
					<div className="row-item">
						<label>Resources</label>
						<div className="options_select">
							<div className="field-options">
								{
									<ServicesListPager
										services={this.state.resourceOptions}
										handleSelectionChange={(e) => this.handleResourceChange(e)}
										selectedOption={
											this.state.resourceOptions.length > 0 ? this.state.events : undefined
										}
										processing={this.state.resourceProcessing}
										eventSelectionprocessing={this.props.resourceProcessingStatus}
										placeHolder={'Select Resource'}
									/>
								}
							</div>
						</div>
					</div>
				</div>

				<div className="booking-app">{this.props.service && this.showView(this.state.unitType)}</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		services: resourceSelectors.getServices(state),
		service: resourceSelectors.getService(state),
		servicesSlots: resourceSelectors.getServicesSlots(state),
		dayData: resourceMonthlySelectors.getServiceDayData(state),
		resources: resourceMonthlySelectors.getResources(state),
		monthlyServicesSlots: resourceMonthlySelectors.getServicesSlots(state),
		conciergeMember: authSelectors.getConciergeDetails(state),
		resourceProcessingStatus: resourceSelectors.getResourceProcessingStatus(state)
	};
}

export default withRouter(connect(mapStateToProps)(BookingsScreen));
