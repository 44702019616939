import _ from "lodash";
import * as config from "../config";
import authService from "../EngageBookingBugServices/LoginService";


class CheckoutService {
  async addToBasket(serviceId, date, eventId, time, memberId) {
    const companyId = `${config.COMPANY_ID}`;
    const authToken = authService.getToken();
    const url = `${config.BOOKING_BUG_API}/${
      companyId
      }/basket/add_item?service_id=${serviceId}&date=${date}&event_id=${
      eventId
      }&time=${time}&member_id=${memberId}`;

    var reqHeaders = new Headers();
    reqHeaders.append("App-Key", config.APP_KEY);
    reqHeaders.append("App-Id", config.APP_ID);
    reqHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    reqHeaders.append("Auth-Token", authToken);
    const response = await fetch(url, {
      method: "POST",
      headers: reqHeaders
    });

    if (!response.ok) {
      config.errorPageRedirect(response);
      throw new Error(
        `ResourcesService addToBasket failed, HTTP status ${response.status}`
      );
    }

    const data = await response.json();

    return _.get(data, "_embedded.items");
  }

  // async addToBasketGroup(eventId, memberId, serviceId, bookingData) {
  //   const companyId = `${config.COMPANY_ID}`;
  //   const authToken = authService.getToken();
  //   const url = `${config.BOOKING_BUG_API}/${companyId}/basket/add_item`;

  //   //?service_id=${serviceId}&date=${date}&event_id=${eventId}&time=${time}&member_id=${memberId}
  //   var reqHeaders = new Headers();
  //   reqHeaders.append("App-Key", config.APP_KEY);
  //   reqHeaders.append("App-Id", config.APP_ID);
  //   reqHeaders.append("Content-Type", "application/json");
  //   reqHeaders.append("Auth-Token", authToken);
  //   const response = await fetch(url, {
  //     method: "POST",
  //     headers: reqHeaders,
  //     body: JSON.stringify(bookingData)
  //   });

  //   if (!response.ok) {
  //     config.errorPageRedirect(response);
  //     throw new Error(
  //       `ResourcesService addToBasket failed, HTTP status ${response.status}`
  //     );
  //   }

  //   const data = await response.json();

  //   return _.get(data, "_embedded.items");
  // }
  // async removeFromBasket(itemBasketId) {
  //   const companyId = `${config.COMPANY_ID}`;
  //   const authToken = authService.getToken();
  //   const url = `${config.BOOKING_BUG_API}/${companyId}/basket/${itemBasketId}`;

  //   var reqHeaders = new Headers();
  //   reqHeaders.append("Content-Type", "application/json");
  //   reqHeaders.append("App-Key", config.APP_KEY);
  //   reqHeaders.append("App-Id", config.APP_ID);
  //   reqHeaders.append("Cache-Control", "no-cache");
  //   reqHeaders.append("Auth-Token", authToken);

  //   const response = await fetch(url, {
  //     method: "DELETE",
  //     headers: reqHeaders
  //   });

  //   if (!response.ok) {
  //     config.errorPageRedirect(response);
  //     throw new Error(
  //       `CheckoutService removeFromBasket failed, HTTP status ${
  //       response.status
  //       }`
  //     );
  //   }

  //   const data = await response.json();
  //   return _.find(data, "items");
  // }

  // async getBasket() {
  //   const companyId = `${config.COMPANY_ID}`;
  //   const authToken = authService.getToken();

  //   const url = `${config.BOOKING_BUG_API}/${companyId}/basket`;

  //   var reqHeaders = new Headers();
  //   reqHeaders.append("Content-Type", "application/json");
  //   reqHeaders.append("App-Key", config.APP_KEY);
  //   reqHeaders.append("App-Id", config.APP_ID);
  //   //reqHeaders.append("Cache-Control", "no-cache");
  //   reqHeaders.append("Auth-Token", authToken);

  //   const response = await fetch(url, {
  //     method: "GET",
  //     headers: reqHeaders
  //   });

  //   if (!response.ok) {
  //     //config.errorPageRedirect(response);
  //     throw new Error(
  //       `CheckoutService getBasket failed, HTTP status ${response.status}`
  //     );
  //   }

  //   const data = await response.json();
  //   //return data;
  //   return _.get(data, "_embedded.items");
  // }

  // async checkout(memberId) {
  //   const companyId = `${config.COMPANY_ID}`;
  //   const authToken = authService.getToken();
  //   const url = `${config.BOOKING_BUG_API}/${companyId}/basket/checkout`;

  //   const reqBody = {
  //     member_id: memberId,
  //     take_from_wallet: false
  //   };

  //   var reqHeaders = new Headers();
  //   reqHeaders.append("Content-Type", "application/json");
  //   reqHeaders.append("App-Key", config.APP_KEY);
  //   reqHeaders.append("App-Id", config.APP_ID);
  //   reqHeaders.append("Cache-Control", "no-cache");
  //   reqHeaders.append("Auth-Token", authToken);

  //   const response = await fetch(url, {
  //     method: "POST",
  //     headers: reqHeaders,
  //     body: JSON.stringify(reqBody)
  //   });

  //   if (!response.ok) {
  //     config.errorPageRedirect(response);
  //     throw new Error(
  //       `CheckoutService checkout failed, HTTP status ${response.status}`
  //     );
  //   }

  //   const data = await response.json();

  //   return _.get(data, "_embedded");
  // }


  async addToBasketGroup(eventId, memberId, serviceId, bookingData) {


    const url = `${config.ENGAGE_BOOKINGBUG_ADD_TO_BASKET_ENDPOINT}`;
    const authToken = authService.getToken();

    var reqHeaders = new Headers();

    reqHeaders.append("Content-Type", "application/json");
    reqHeaders.append("Authorization", authToken);

    const response = await fetch(url, {
      method: "POST",
      headers: reqHeaders,
      body: JSON.stringify(bookingData)
    });

    if (!response.ok) {
      config.errorPageRedirect(response);
      throw new Error(
        `CheckoutService addToBasketGroup failed, HTTP status ${response.status}`
      );
    }

    const data = await response.json();

    return _.get(data, "_embedded.items");
  }

  async checkout(memberId) {


    const url = `${config.ENGAGE_BOOKINGBUG_CHECKOUT_ENDPOINT}${"?bbmemberId="}${memberId}`;
    const authToken = authService.getToken();

    var reqHeaders = new Headers();

    reqHeaders.append("Content-Type", "application/json");
    reqHeaders.append("Authorization", authToken);

    const response = await fetch(url, {
      method: "POST",
      headers: reqHeaders,

    });

    if (!response.ok) {
      config.errorPageRedirect(response);
      throw new Error(
        `CheckoutService checkout failed, HTTP status ${response.status}`
      );
    }

    const data = await response.json();

    return _.get(data, "_embedded");
  }
  async getBasket() {


    const url = `${config.ENGAGE_BOOKINGBUG_BASKET_ENDPOINT}`;
    const authToken = authService.getToken();

    var reqHeaders = new Headers();

    reqHeaders.append("Content-Type", "application/json");
    reqHeaders.append("Authorization", authToken);

    const response = await fetch(url, {
      method: "GET",
      headers: reqHeaders,

    });

    if (!response.ok) {
      config.errorPageRedirect(response);
      throw new Error(
        `CheckoutService getBasket failed, HTTP status ${response.status}`
      );
    }

    const data = await response.json();

    return _.get(data, "_embedded.items");
  }


  async removeFromBasket(itemBasketId) {
    const url = `${config.ENGAGE_BOOKINGBUG_DELETE_ITEM_FROM_BASKET_ENDPOINT}${"?id="}${itemBasketId}`;
    const authToken = authService.getToken();

    var reqHeaders = new Headers();

    reqHeaders.append("Content-Type", "application/json");
    reqHeaders.append("Authorization", authToken);

    const response = await fetch(url, {
      method: "DELETE",
      headers: reqHeaders,
    });

    if (!response.ok) {
      config.errorPageRedirect(response);
      throw new Error(
        `CheckoutService removeFromBasket failed, HTTP status ${response.status}`
      );
    }

    const data = await response.json();

    return _.get(data, "_embedded.items");

  }
  // async makePayment(data) {

  //   //https://www.npmjs.com/package/js2xmlparser used 
  //   const url = config.WorldPayURL;
  //   const instId = config.WorldPayInstID;

  //   var contentObj = {
  //     "@": { version: "1.4", "merchantCode": "TESTING_MERCHANT_CODE" },
  //     "submit": {
  //       "order": {
  //         "@": { "orderCode": "XPPDJDHDDGDGDGDGDG_OUR_ORDER_CODE", "installationId": instId },

  //         "description": "Testing payment on concierge",
  //         "amount": {
  //           "@": { "currencyCode": "GBP" },
  //           "@": { "exponent": "2" },
  //           "@": { "value": "5000" }
  //         },
  //         "orderContent": "",
  //         "paymentMethodMask": {
  //           "include": { "@": { "code": "ALL" } }
  //         },
  //         "shopper": { "shopperEmailAddress": "resident@engageproptech" },
  //         "shippingAddress": {
  //           "address": {
  //             "address1": "47A",
  //             "address2": "Queensbridge Road",
  //             "address3": "Suburbia",
  //             "postalCode": "CB94BQ",
  //             "city": "Cambridge",
  //             "state": "Cambridgeshire",
  //             "countryCode": "GB"
  //           }
  //         },
  //         "billingAddress": {
  //           "address": {
  //             "address1": "47A",
  //             "address2": "Queensbridge Road",
  //             "address3": "Suburbia",
  //             "postalCode": "CB94BQ",
  //             "city": "Cambridge",
  //             "state": "Cambridgeshire",
  //             "countryCode": "GB"
  //           }
  //         }
  //       }
  //     }
  //   };



  //   var content = js2xmlparser.parse("paymentService", contentObj);

  //   // var response;
  //   // var xhr = new XMLHttpRequest();
  //   // xhr.open("POST", url, true);
  //   // xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest')
  //   // // xhr.setRequestHeader("Content-Type", "application/xml");

  //   // xhr.onreadystatechange = function () { // Call a function when the state changes.
  //   //   if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {

  //   //     response = xhr.responseText;
  //   //     console.log(xhr.responseText);
  //   //   }
  //   // }

  //   // xhr.send(content);

  //   var reqHeaders = new Headers();
  //   reqHeaders.append("Access-Control-Allow-Origin", "*");
  //   //reqHeaders.append("Access-Control-Allow-Headers", "X-Requested-With");
  //   //reqHeaders.append("Content-Type", "application/xml");

  //   // reqHeaders.append("Accept", "*");
  //   // reqHeaders.append("Accept-Language", "en-GB");
  //   // reqHeaders.append("Accept-Encoding", "gzip, deflate");

  //   const response = await fetch(url, {
  //     method: "POST",
  //     mode: "cors",
  //     headers: reqHeaders,
  //     body: content,

  //     // cache: 'default'
  //   });


  //   if (!response.ok) {
  //     //config.errorPageRedirect(response);
  //     throw new Error(
  //       `makepayment checkout failed, HTTP status ${response.status}`
  //     );
  //   }

  //   return response;

  // }


}

export default new CheckoutService();
