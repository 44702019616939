import React, { Component } from "react";
import { connect } from "react-redux";

import HeaderNavigation from "../components/HeaderNavigation/HeaderNavigation";
import FooterNavigation from "../components/FooterNavigation/FooterNavigation";
import Button from "../components/Button/Button";
import autoBind from "react-autobind";

import * as authSelectors from "../store/auth/reducer";
import * as authActions from "../store/auth/actions";
import * as settingActions from "../store/ConciergeSetting/actions";
import * as settingSelector from "../store/ConciergeSetting/reducer";
import "../sass/static/logout.css";

import "../sass/static/passwordRecoverySent.css"

class PassRecoverySent extends Component {
  constructor(props) {
    super(props);

    this.props.dispatch(settingActions.getGlobalSetting());
    autoBind(this);
  }

  handleResend(e) {
    const { email } = this.props;

    if (email) this.props.dispatch(authActions.ResetPasswordEmail(email));
  }

  render() {
    const { settings } = this.props;
    let bgImage = "";
    if (settings) {
      bgImage = settings.mainBackgroundImage;
    }
    const bgStyle = {
      backgroundImage: "url('" + bgImage + "')",
      backgroundRepeatY: "no-repeat",
      backgroundSize: "cover"
    };
    return (
      <div className="loginScreen passwordRecoverySent">
        <HeaderNavigation isLoggedIn={false} setting={settings} />

        <div className="loginWrap" style={bgStyle}>
          <h2 className="loginScreen" >Sent!</h2>
        </div>

        <div className="main-wrp-login">
          <div className="login-form-wrapper">
            <div className="validation-messages">
              <ul>
                <li className="type-success">
                  Check your inbox and follow the instructions
                  </li>
              </ul>
            </div>
            <div className="btnHolder">
              <Button
                handleClick={this.handleResend}
                text="Resend Email"
                size="medium"
              />
            </div>
          </div>
          <FooterNavigation />
        </div>
      </div>

    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    isLoggedIn: authSelectors.getIsLoggedIn(state),
    email: authSelectors.getEmail(state),
    settings: settingSelector.loadGlobalSetting(state)
  };
}
export default connect(mapStateToProps)(PassRecoverySent);
