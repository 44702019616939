import * as types from './actionTypes';
import Immutable from 'seamless-immutable';
import _ from 'lodash';
//import PageEvent from "../../services/PegeEvent";

const initialState = Immutable({
	visitor: [],
	visitors: [],
	selectedVisitor: undefined,
	meeting: undefined,
	meetings: [],
	visitorCheckedIn: [],
	visitorSearchInput: undefined,
	visitorCheckedOut: [],
	visitorEvent: '',
	carReg: '',
	specialInstructions: '',
	searchResult: undefined,
	searchFromRedirect: undefined
});

export default function visitorReducer(state = initialState, action = {}) {
	switch (action.type) {
		case types.VISITORS_RECORDS_LOADED_SUCCESS:
			return state.merge({
				visitors: action.visitors
			});
		case types.VISITOR_RECORD_LOADED_SUCCESS:
			return state.merge({
				visitor: action.visitor
			});

		case types.VISITOR_MEETING_LOADED_SUCCESS:
			return state.merge({
				meeting: action.meeting
			});
		case types.VISITOR_SELF_CHECKIN_SUCCESS:
			return state.merge({
				specialInstructions:
					action.visitorCheckedIn && action.visitorCheckedIn.length > 0
						? action.visitorCheckedIn[0].specialInstructions
						: '',
				visitorCheckedIn: action.visitorCheckedIn
			});
		case types.UPDATE_CHECKOUT_STATUS_SUCCESS:
			return state.merge({
				visitorCheckedOut: action.visitorCheckedOut
			});
		case types.VISITOR_SELECTED:
			return state.merge({
				selectedVisitor: _.find(action.visitors, { id: action.id })
			});
		case types.VISITOR_SEARCH_DATA:
			return state.merge({
				visitorSearchInput: action.visitorSearchInput
			});
		case types.VISITOR_EVENT:
			return state.merge({
				visitorEvent: action.visitorEvent
			});
		case types.CANCEL_MEETING_LOAD:
			return state.merge({
				meeting: action.meeting
			});
		case types.VISITOR_CAR_REG_SET:
			return state.merge({
				carReg: action.carReg
			});
		case types.VISITOR_SPECIAL_INSTRUCTIONS:
			return state.merge({
				specialInstructions: action.specialInstructions
			});
		case types.VISITOR_SEARCH_STORED_SUCCESSFULLY:
			return state.merge({
				searchResult: action.data
			});
		case types.VISITOR_SEARCH_FROM_REDIRECT:
			return state.merge({
				searchFromRedirect: action.status
			});
		case types.VISITOR_CHECKEDIN_RESET_SUCCESSFULLY:
			return state.merge({
				visitorCheckedIn: []
			});
		default:
			return state;
	}
}

export function getVisitors(state) {
	return state.visitors.visitors;
}

export function getVisitor(state) {
	return state.visitors.visitor;
}

export function getSelectedVisitor(state) {
	return state.visitors.selectedVisitor;
}

export function getVisitorMeeting(state) {
	return state.visitors.meeting;
}

export function visitorCheckedIn(state) {
	return state.visitors.visitorCheckedIn;
}

export function getVisitorSearchInput(state) {
	return state.visitors.visitorSearchInput;
}
export function visitorCheckedOut(state) {
	return state.visitors.visitorCheckedOut;
}

export function getVisitorEvent(state) {
	return state.visitors.visitorEvent;
}

export function getCarReg(state) {
	return state.visitors.carReg;
}

export function getSpecialInstructions(state) {
	return state.visitors.specialInstructions;
}

export function getSearchResult(state) {
	return state.visitors.searchResult;
}
export function getSearchFromRedirect(state) {
	return state.visitors.searchFromRedirect;
}
