import * as types from "./actionTypes";
import Immutable from "seamless-immutable";


const initialState = Immutable({
    setting: undefined,
    settings: []
});


export default function settingsReducer(state = initialState, action = {}) {

    switch (action.type) {
        case types.CONCIERGE_SETTINGS_LOAD_SUCCESS:
            return state.merge({
                setting: action.response
            })
        default:
            return state;
    }
}



export function loadGlobalSetting(state) {
    return state.settings.setting;
}