//import _ from "lodash";
import * as types from "./actionTypes";
//import conc_loginService from "../../services/loginService";
//import loginService from "../../services/BookingBugServices/LoginService";
import loginService from "../../services/EngageBookingBugServices/LoginService";
import * as config from "../../services/config";

export function login() {
  return async (dispatch, getState) => {
    try {
      let authToken = loginService.getToken();
      let companyId = config.COMPANY_ID; //loginService.getProfile();


      if (authToken && companyId) {

        const loginData = {
          bbAuthToken: loginService.getToken(),
          isLoggedIn: true
        };

        console.log("user is was already lgoged in");
        dispatch({ type: types.LOGIN_COMPLETED, loginData });
      } else {
        companyId = await loginService.getCompanyId();

        dispatch({ type: types.FETCH_COMPANY_ID_COMPLETED, companyId });

        const loginData = await loginService.login(
          config.BOOKING_BUG_USER_LOGIN,
          config.BOOKING_BUG_USER_PWD,
          config.BOOKING_BUG_USER_ID
        );

        dispatch({ type: types.LOGIN_COMPLETED, loginData });
      }
    } catch (error) {
      console.error(error);
    }
  };
}
