import _ from 'lodash';
import * as config from './config';
//import { browserHistory } from 'react-router';
import {createBrowserHistory} from 'history';

let history = createBrowserHistory();
const AUTH_TOKEN = 'conc_authToken';
const TOKEN_TYPE = 'conc_tokenType';
const TOKEN_EXPIRE = 'conc_tokenExpireIn';

class LoginService {
	async login(username, password) {
		const auth_url = `${config.CONCIERGE_AUTH_ENDPOINT}`;

		var headers = new Headers();
		headers.append('Content-Type', 'application/x-www-form-urlencoded;charset=UTF-8');
		//headers.append('Access-Control-Allow-Origin', '*');
		const response = await fetch(auth_url, {
			method: 'POST',
			body: `grant_type=password&password=${password}&username=${username}`.toString(),
			headers: headers
		});

		if (!response.ok) {
			throw new Error(`Authentication Error, status ${response.status}`);
		}
		const result = await response.json();
		this.setToken(_.get(result, 'access_token'));
		this.setTokenType(_.get(result, 'token_type'));
		this.setTokenExpire(_.get(result, 'expires_in'));

		return {
			authToken: _.get(result, 'access_token')
		};
	}

	async requestResetPass(resetData) {
		const url = `${config.REQUEST_PASSWORD_RESET_ENDPOINT}`;
		// const param = "?email=" + resetData.email + "&type=" + resetData.type;
		var reqHeaders = new Headers();
		reqHeaders.append('Content-Type', 'application/json');

		const response = await fetch(url, {
			method: 'POST',
			headers: reqHeaders,
			body: JSON.stringify(resetData)
		});

		if (!response.ok) {
			throw new Error(`LoginService requestResetPass failed, HTTP status ${response.status}`);
		}

		const data = await response.json();

		return data;
	}

	async verifyResetRequest(requestData) {
		const url = `${config.VERIFY_REQUEST_RESET_ENDPOINT}`;

		var reqHeaders = new Headers();
		reqHeaders.append('Content-Type', 'application/json');

		const response = await fetch(url, {
			method: 'POST',
			headers: reqHeaders,
			body: JSON.stringify(requestData)
		});

		if (!response.ok) {
			throw new Error(`LoginService verifyResetRequest failed, HTTP status ${response.status}`);
		}

		const data = await response.json();

		return data;
	}

	async changePassword(requestData) {
		const url = `${config.PASSWORD_RESET_ENDPOINT}`;

		var reqHeaders = new Headers();
		reqHeaders.append('Content-Type', 'application/json');

		const response = await fetch(url, {
			method: 'POST',
			headers: reqHeaders,
			body: JSON.stringify(requestData)
		});

		if (!response.ok) {
			throw new Error(`LoginService changePassword failed, HTTP status ${response.status}`);
		}

		const data = await response.json();

		return data;
	}

	async getConciergeDetails() {
		const url = `${config.MEMBERS_ENDPOINT}${'/conciergedetails'}`;

		const authToken = this.getToken();

		var requestHeader = new Headers();
		requestHeader.append('Authorization', `${'Bearer '}${authToken}`);
		requestHeader.append('Content-Type', 'application/x-www-form-urlencoded;charset=UTF-8');
		requestHeader.append("Cache-Control", "no-cache");
		//   reqHeaders.append("App-Key", config.APP_KEY);
		//   reqHeaders.append("App-Id", config.APP_ID);
		//   reqHeaders.append("Cache-Control", "no-cache");

		const response = await fetch(url, {
			method: 'GET',
			headers: requestHeader
		});

		if (!response.ok) {
			//config.errorPageRedirect(response);
			throw new Error(`LoginService getConciergeDetails failed, HTTP status ${response.status}`);
		}

		const data = await response.json();

		return data;
	}

	setToken(token) {
		localStorage.setItem(AUTH_TOKEN, token);
	}

	getToken() {
		return localStorage.getItem(AUTH_TOKEN);
	}

	setTokenType(type) {
		localStorage.setItem(TOKEN_TYPE, type);
	}

	getTokenType() {
		return localStorage.getItem(TOKEN_TYPE);
	}

	setTokenExpire(min) {
		localStorage.setItem(TOKEN_EXPIRE, min);
	}

	getTokenExpire() {
		return localStorage.getItem(TOKEN_EXPIRE);
	}

	logout() {
		
		window.localStorage.removeItem(AUTH_TOKEN);
		window.localStorage.removeItem(TOKEN_EXPIRE);
		history.replace('/');
	}
}

export default new LoginService();
