import React, { Component } from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line
import Parsley from 'parsleyjs';
import Select from 'react-select';
import TimePicker from 'rc-time-picker';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import _ from 'lodash';
// eslint-disable-next-line
import * as $ from 'jquery';
import '../../sass/components/Input/react-select.css';
import '../../sass/components/Input/index.css';
import autoBind from "react-autobind";
/**
 * Renders a input text element or select form element with standardised label format
 * value will be bound internally to state by default or optionally to something passed in from the parent component
 * accepts autoSuggestProps when this component is rendered inside of react-autosuggest renderInputComponent method
 */

class InputComponent extends Component {
	constructor(props) {
		super(props);

		this.state = {
			render: true,
			value: _.isUndefined(this.props.value) ? '' : this.props.value,
			icon_change: 'ion-chevron-down'
		};

		autoBind(this);
	}

	componentDidMount() {
		this.parselyToReactSelect();
	}

	parselyToReactSelect() {
		// For react-select, parsely properties need adding manually to the source of truth
		// <input type="hidden" it generates.
		if (this.props.type === 'select' && this.props.parsley) {
			for (var key in this.props.parsley) {
				if (!_.isUndefined(this.refs.select.refs.value))
					this.refs.select.refs.value.setAttribute(key, this.props.parsley[key]);
			}
			if (!_.isUndefined(this.refs.select.refs.value)) this.refs.select.refs.value.removeAttribute('type');
		}
	}

	// Intented for use with <select
	setVal(value) {
		this.setState({ value: value });
	}

	reset() {
		// Used for resetting select input to default option
		// This could alrenatively be done with dom element selectedIndex = 0
		if (this.props.type === 'select') {
			this.setState(
				{
					render: false,
					value: _.isUndefined(this.props.value) ? '' : this.props.value
				},
				() => {
					this.setState({ render: true }, () => {
						this.parselyToReactSelect();
					});
				}
			);
		}
	}

	handlePaste(e){
		this.props.handleChange(e);
	}
	render() {
		const {
			label,
			name,
			placeHolder,
			//value,
			handleChange,
			type,
			options,
			maxLength,
			handleTimeChange,
			defaultValue,
			dateValue,
			defaultSelect,
			handleDateChange,
			defaultMin
		} = this.props;

		let { autoSuggestProps } = this.props;

		let disabled;

		if (_.isUndefined(autoSuggestProps)) {
			autoSuggestProps = {};
		}

		if (this.state.render === true) {
			return (
				<div
					className={
						(this.props.disabled ? 'disabled' : '') + ' ' + (this.props.uneditable ? 'uneditable' : '')
					}
				>
					<label htmlFor={'form-input-' + name} className="like-h4">
						{label}
					</label>

					<div className={'input-wrapper ' + (!_.isUndefined(this.props.icon) ? this.props.icon : '')}>
						{((type) => {
							// Bind the value if it is present
							const valueBind = !_.isUndefined(this.props.value) ? { value: this.props.value } : {};

							switch (type) {
								case 'select':
									disabled =
										_.isUndefined(this.props.disabled) || this.props.disabled === false
											? false
											: true;
									return (
										<Select
											ref="select"
											className={this.state.icon_change}
											name={name}
											id={'form-input-' + name}
											placeholder={placeHolder}
											searchable={false}
											options={this.props.options}
											clearable={false}
											value={
												_.isUndefined(this.props.value) ? (
													this.state.value
												) : !_.isUndefined(
													this.props.options.find((option) => {
														return option.value.toString() === this.props.value.toString();
													})
												) ? (
													this.props.value
												) : (
													this.state.value
												)
											}
											disabled={disabled}
											onChange={(value) => {
												this.setState({ value: value.value }, () => {
													const label_ = _.find(options, (option) => {
														return option.value === value.value;
													});
													const label = _.isUndefined(label_) ? undefined : label_.label;

													handleChange({
														target: { name: name, value: value.value, label: label }
													});
												});
											}}
											onClose={() => {
												this.setState({
													icon_change: 'ion-chevron-down'
												});
											}}
											onOpen={() => {
												this.setState({
													icon_change: 'ion-chevron-up'
												});
											}}
										/>
									);

								case 'timePicker':
									return (
										<TimePicker
											defaultValue={defaultValue}
											showSecond={false}
											onChange={handleTimeChange}
											name={name}
											placeholder={placeHolder}
											value={dateValue}
											disabledMinutes={this.props.disabledMinutes}
											disabledHours={this.props.disabledHours}
											allowEmpty={false}
											hideDisabledOptions
											{...this.props.parsley}
										/>
									);

								case 'datePicker':
									return (
										<DatePicker
											selected={defaultSelect}
											minDate={defaultMin}
											onChange={handleDateChange}
											dateFormat="DD/MM/YYYY"
											placeholder={placeHolder}
											value={moment(dateValue)}
											name={name}
											{...this.props.parsley}
										/>
									);

								case 'password':
								case 'email':
								case 'text':
								default:
									const blurBind = _.isUndefined(this.props.onBlur)
										? {}
										: { onBlur: this.props.onBlur };

									disabled =
										_.isUndefined(this.props.disabled) || this.props.disabled === false
											? false
											: 'disabled';

									return (
										<input
											ref="input"
											type={_.isUndefined(type) || type === '' ? 'text' : type}
											spellCheck="false"
											name={name}
											id={'form-input-' + name}
											onChange={handleChange}
											onPaste={handleChange}
											onKeyPress={(e) => {
												if (this.props.handleKeyPress) this.props.handleKeyPress(e);
											}}
											autoComplete="off"
											maxLength={maxLength}
											disabled={disabled}
											{...this.props.parsley}
											{...valueBind}
											{...autoSuggestProps}
											{...blurBind}
											placeholder={placeHolder} // has priority over one added in autoSuggestProps so comes after
										/>
									);
							}
						})(type)}
					</div>
				</div>
			);
		} else {
			return <span />;
		}
	}
}
InputComponent.propTypes = {
	/* Optional function to run on blur of the input field */
	onBlur: PropTypes.func,
	/** If the input is disabled */
	disabled: PropTypes.bool,
	/** If the input is uneditable and for display only */
	uneditable: PropTypes.bool,
	/** Optional value so the input can be bound to an item in the parents state */
	value: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
	/** Optional autosuggest properties when this component is rendered inside of react-autosuggest renderInputComponent method  */
	autoSuggestProps: PropTypes.object,
	/** Label displayed above input <h4 like-h4  */
	label: PropTypes.string,
	/** Name displayed within form  */
	name: PropTypes.string,
	/** Placeholder text  */
	placeHolder: PropTypes.string,
	/** Handlechange function passed in and called in parent  */
	handleChange: PropTypes.func,
	/** handleTimeChange function passed in and called in parent  */
	handleTimeChange: PropTypes.func,
	/** Used to switch between input type="text" (default) or 'select' */
	type: PropTypes.string,
	/** Optional obhext of data-paseley-**** validation properties */
	parsley: PropTypes.object,
	/** Optional ion classs */
	icon: PropTypes.string,
	/** Optional maximum character length */
	maxLength: PropTypes.string,

	dateValue: PropTypes.string
};

export default InputComponent;
