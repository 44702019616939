import * as types from "./actionTypes";
import Immutable from "seamless-immutable";


const initialState = Immutable({
  addresses: []
});

export default function addressReducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.ADDRESSES_LOAD_SUCCESS:
      return state.merge({
        addresses: action.addresses
      });
    default:
      return state;
  }
}

export function getAddresses(state) {
  return state.addresses.addresses;
}
