import { Component } from "react";
import * as authActions from "../store/auth/actions";
import { connect } from "react-redux";

class Logout extends Component {
constructor(props){
  super(props);

  this.props.dispatch(authActions.logout());
}



  render() {
    
    return null;
  }
}

function mapStateToProps(state) {

  return {};
}

export default connect(mapStateToProps)(Logout);
