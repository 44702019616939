import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import DropDownMenu from './DropDownMenu';
import { connect } from 'react-redux';
import { createBrowserHistory } from 'history';
import * as authActions from "../../store/auth/actions";

import autobind from 'react-autobind';
import {withRouter} from 'react-router-dom';
import '../../sass/components/HeaderNavigation/HeaderNavigation.css';

const StickyStatus = {
	Stuck: 'stuck',
	UnStuck: 'unstuck'
};

let history = createBrowserHistory();
/**
 * The header component for the pages. It comes in two flavours, closed: which just shows the logo linking to home, and full which is the full display of links
 */
class HeaderNavigation extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isMenuOn: true,
			lastscrollTop: 0,
			isStuck: false,
			hambugerStatus: 'close'
		};

		autobind(this);
	}

	componentDidMount() {
		if (this.props.isLoggedIn === true) {
			window.addEventListener('scroll', this.handleScroll);
		} else {
			window.removeEventListener('scroll', this.handleScrol);
		}
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScroll);
	}

	handleClick(e) {
		e.preventDefault();

		if (this.state.hambugerStatus === 'open') {
			this.setState({
				hambugerStatus: 'close'
			});
		} else {
			this.setState({
				hambugerStatus: 'open'
			});
		}
	}

	handleScroll(e) {
		var path = e.path || (e.composedPath && e.composedPath());
		let page_window_offsetY = path && path[1].pageYOffset;
		let page_document_scrollTop = path && path[0].scrollingElement.scrollTop;
		let currenScrollTop = page_window_offsetY || page_document_scrollTop;
		this.setState({
			lastscrollTop: currenScrollTop,
			isStuck: currenScrollTop > this.state.lastscrollTop ? true : false
		});
	}

	handleReset(val) {
		if (val) {
			this.setState({
				hambugerStatus: 'close'
			});
		}
	}

	handleReturnHome() {
		const { modules } = this.props;
			
		if (modules && modules.length > 0) {
			this.props.history.push({
				pathname: '/',
				state: { setModule: modules[0].title }
			});
		} else {
			this.props.history.push('/');
		}
	}

	handleLogout(){
		this.props.dispatch(authActions.logout());
		history.push('/');
	}
	render() {
		const { modules, isLoggedIn, setting } = this.props;

		const logo = setting && setting.logo;

		const header_style = {
			backgroundImage: logo ? "url('" + logo + "') " : '',
			backgroundSize: '70px'
		};
		//'this.mainBackgroundImage = "url('" + this.setting.mainBackgroundImage + "')";
		
		return (
			<div>
				<header
					id="stickyishHeader"
					className={this.state.isStuck === true ? StickyStatus.UnStuck : StickyStatus.Stuck}
				>
					{isLoggedIn === false ? (
						<nav className="closed">
							<Link className="homeLogo" to="/" onClick={this.handleReturnHome} style={header_style} />
						</nav>
					) : (
						<nav>
							<ul className="navList">
								<li className="deadspace" />
								<li className="menuButton">
									<div
										className={`${'menu-button'} ${this.state.hambugerStatus === 'open'
											? ' cross--large'
											: ''}`}
										id="menuButton"
									>
										<span
											className="hamburger"
											onClick={(e) => this.handleClick(e)}
											id="hamburger"
										/>
									</div>
								</li>
								<li className="home" style={header_style}>
									<span className="homeLogo"  onClick={this.handleReturnHome} />
								</li>
								<li className="logout">
									{' '}
									<Link className="logout" onClick={this.handleLogout} to="/">
										Logout
									</Link>
								</li>
								<li className="deadspace" />
							</ul>
						</nav>
					)}
				</header>
				<ul id="spacer" className={this.state.isStuck === true ? 'spacer' : ''}>
					<li />
				</ul>
				<DropDownMenu
					moduleList={modules}
					sticky={this.state.isStuck === true ? StickyStatus.UnStuck : StickyStatus.Stuck}
					visible={this.state.hambugerStatus === 'open' ? 'visible' : ''}
					reset={(val) => this.handleReset(val)}
				/>
			</div>
		);
	}
}

HeaderNavigation.propTypes = {
	/** The module items for navigatin */
	modules: PropTypes.array,

	/** User login status */
	isLoggedIn: PropTypes.bool.isRequired,

	/** The concierge settings object */
	setting: PropTypes.object
};

function mapStateToProps(state) {
	return {};
  }
  
export default withRouter(connect(mapStateToProps)(HeaderNavigation));
