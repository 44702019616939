import React, { Component } from "react";
import { connect } from "react-redux";
import autobind from "react-autobind";
import _ from "lodash";
import * as serviceModuleActions from "./store/ServiceModules/actions";
import * as serviceModuleSelectors from "./store/ServiceModules/reducer";
class App extends Component {
  constructor(props) {
    super(props);

    this.props.dispatch(serviceModuleActions.getPortalSetting());
    autobind(this);
  }
  componentDidUpdate(prevProps, prevState){
    if (
      prevProps.portalSettings !== this.props.portalSettings &&
      !_.isUndefined(this.props.portalSettings)
    ) {
      document.title = this.props.portalSettings.title;
    }
  }
  

  // moduleMenu() {
  //   const { modules } = this.state;

  //   return (
  //     <ModuleMenu
  //       modules={modules}
  //       handleModuleMenuSelected={e => this.handleModuleMenuSelected(e)}
  //     />
  //   );
  // }

  render() {
    
    return (<div>{this.props.children}</div>);
  }
}
function mapStateToProps(state) {
  return {
    portalSettings: serviceModuleSelectors.loadPortalSttings(state)
  };
}
export default connect(mapStateToProps)(App);
