import React, { Component } from 'react';
import { connect } from 'react-redux';
import Autosuggest from 'react-autosuggest';
import InputComponent from '../../components/Input/Input';

import PropTypes from 'prop-types';

import * as residentActions from '../../store/Residents/actions';
import * as residentSelector from '../../store/Residents/reducer';
import _ from 'lodash';
import * as $ from 'jquery';
import autoBind from 'react-autobind';

class ResidentAddrAutoSuggest extends Component {
	constructor(props) {
		super(props);

		this.defaultState = {
			value: '',
			suggestions: [],
			valid: false,
			otherText: ''
		};

		this.state = this.defaultState;

		this.props.dispatch(residentActions.getAllResidents());
		this.emptyResponse = {
			memberId: '',
			address: '',
			tenant: ''
		};

		this.selectedTenantFromDefault = false;

		autoBind(this);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		// Set the autosuggest to an initial value if it is provided
		if (
			nextProps.residents.length > 0 &&
			!_.isUndefined(nextProps.defaultTenantId) &&
			_.isNumber(nextProps.defaultTenantId) &&
			this.selectedTenantFromDefault === false
		) {
			this.selectedTenantFromDefault = true;

			const startTenant = _.find(nextProps.residents, (resident) => {
				return resident.id === Number(nextProps.defaultTenantId);
			});

			if (!_.isUndefined(startTenant)) {
				this.handleTenantSelected(startTenant);

				this.setState({
					value: startTenant.firstName + ' ' + startTenant.lastName,
					valid: true
				});
			}
		}
	}
	reset() {
		this.setState(this.defaultState);
	}
	// Autosuggest functions ++++++++++++++++++++++++++++++++++++++++++
	getSuggestionValue(suggestion) {
		// const { name } = this.state;

		this.handleTenantSelected(suggestion);
		if (suggestion) this.setState({ disabled: '' });

		return `${suggestion.firstName} ${suggestion.lastName}`;
	}

	getSuggestions(val) {
		const { residents } = this.props;
		if (!residents) return [];

		//safe guard residents data return null value in some records
		var data_ = _.filter(residents, (obj) => {
			return obj.firstName !== null || obj.lastName !== null;
		});

		const match = _.filter(data_, (o) => {
			let nameSearch = _.startsWith(o.firstName.toLowerCase(), val.toLowerCase());

			if (nameSearch === false) {
				let addrSearch = _.includes(o.addressLine1, val);

				if (addrSearch === true) {
					return addrSearch;
				}
			}
			return nameSearch;
		});

		return match;
	}

	onSuggestionsFetchRequested = ({ value }) => {
		this.setState({ suggestions: this.getSuggestions(value) });
	};

	onSuggestionsClearRequested = () => {};
	handlePasteChange(e) {
		this.setState({
			otherText: e.target.value,
			value: e.target.value
		});
		this.props.defaultText(e.target.value);
	}
	onChange = (event, { newValue }) => {
		if (newValue.length === 0) this.setState({ tenant: undefined });
		if (newValue.length > 2) {
			this.handleTenantChange(newValue);
		}

		this.setState({
			value: newValue
		});

		setTimeout(() => {
			// Delayed because parseley not ready if user selected a suggestion

			this.setState({
				valid: $(this.refs.autosuggest.input).parsley().isValid()
			});
		}, 0);

		this.checkMatch(newValue);
	};

	handleTenantSelected(ten, setValue = false) {
		const stateChanges = {
			memberId: ten.id,
			address: ten.addressLine1 + ' ' + ten.addressLine2 + ' ' + ten.postCode,
			tenant: ten.firstName + ' ' + ten.lastName,

			...(setValue ? { value: ten.firstName + ' ' + ten.lastName, valid: true } : {})
		};

		this.setState(stateChanges);

		this.props.onSelected && this.props.onSelected(stateChanges);
	}

	handleTenantChange(value) {
		// const { memberId } = this.state;
		this.setState({
			name: value,
			otherText: value
		});

		//used as a container if resident value is null
		this.props.defaultText(value);
	}

	renderSuggestion(suggestion) {
		return (
			<div className="Select-option">
				{suggestion.firstName} {suggestion.lastName} {' - '} {suggestion.addressLine1} {suggestion.addressLine2}{' '}
				{suggestion.postCode}
			</div>
		);
	}

	checkMatch(currentValue) {
		this.setState({
			otherText: currentValue
		});
		const matchedTenant = _.find(this.props.residents, (resident) => {
			const value = currentValue.toLowerCase();
			const parts = value.split(' ').filter((value) => {
				return value !== '';
			});

			if (
				parts.length === 2 &&
				resident.firstName.toLowerCase() === parts[0].trim() &&
				resident.lastName.toLowerCase() === parts[1].trim()
			) {
				return true;
			}

			return false;
		});

		if (_.isUndefined(matchedTenant) === false) {
			this.handleTenantSelected(matchedTenant);
		} else {
			this.props.onSelected && this.props.onSelected(this.emptyResponse);
		}
	}

	inputClick() {
		if (
			this.state.valid === true &&
			!_.isUndefined(this.props.clearableIfValid) &&
			this.props.clearableIfValid === true
		) {
			this.reset();

			this.props.onSelected && this.props.onSelected(this.emptyResponse);
		}
	}

	render() {
		const { value, suggestions, valid } = this.state;

		const disabled = _.isUndefined(this.props.disabled) || this.props.disabled === false ? false : true;

		const inputProps = {
			placeholder: 'Tenant',
			value,
			onChange: this.onChange,
			onClick: this.inputClick
		};

		return (
			<Autosuggest
				ref="autosuggest"
				suggestions={suggestions}
				onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
				onSuggestionsClearRequested={this.onSuggestionsClearRequested}
				getSuggestionValue={this.getSuggestionValue}
				onSuggestionSelected={() => {}}
				renderSuggestion={this.renderSuggestion}
				inputProps={inputProps}
				theme={{
					// maybe make global for reuse at some point
					container:
						'auto-suggest Select ' +
						(!_.isUndefined(this.props.clearableIfValid) && this.props.clearableIfValid === true
							? 'is-clearable'
							: ''),
					suggestionsList: 'Select-menu-outer',
					suggestion: 'Select-menu'
				}}
				renderInputComponent={(inputProps) => {
					const iconProp =
						!_.isUndefined(this.props.clearableIfValid) &&
						this.props.clearableIfValid === true &&
						valid === true
							? { icon: 'ion-close-round' }
							: {};

					return (
						<InputComponent
							autoSuggestProps={inputProps}
							label={this.props.labelText}
							name="tenant"
							placeHolder={this.props.placeHolder}
							disabled={disabled}
							value={this.state.value}
							onChange={inputProps.onChange}
							onClick={inputProps.onClick}
							handleChange={this.handlePasteChange}
							onBlur={(e) => {
								/* Ensures that member ID fetched even if not selected via autosuggest */
								this.checkMatch(e.target.value);
							}}
							parsley={{
								'data-parsley-matches-one-of': JSON.stringify({
									allSuggestions: this.props.residents.map((resident) => {
										return {
											value: resident.firstName + ' ' + resident.lastName
										};
									})
								}),
								'data-parsley-pattern': "/^[A-Za-z ,.'-]+$/i", // alpha chars only https://stackoverflow.com/questions/8059370/
								'data-parsley-minlength': 4,
								'data-parsley-required': false,
								'data-parsley-error-message': 'Please enter an existing tenant name'
							}}
							{...iconProp}
						/>
					);
				}}
			/>
		);
	}
}

ResidentAddrAutoSuggest.propTypes = {
	/* Optional initial tenant id to start with, (used when navigating back etc...) */
	defaultTenantId: PropTypes.number,
	/* Callback function for when item is selected */
	onSelected: PropTypes.func,
	/* Optional disable the input field */
	disabled: PropTypes.bool,
	/* Specifies if a X icon shows and the input field becomes clickable if the autosuggest is valid */
	clearableIfValid: PropTypes.bool,
	/* Label text */
	labelText: PropTypes.string,
	/* PlaceHolder Text */
	placeHolder: PropTypes.string,

	defaultText: PropTypes.func
};

function mapStateToProps(state) {
	return {
		residents: residentSelector.getResidents(state)
	};
}

export default connect(mapStateToProps, null, null, { forwardRef: true })(ResidentAddrAutoSuggest);
