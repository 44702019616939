import * as config from "../config";
import authService from "../loginService";
import bbauthService from "../BookingBugServices/LoginService";
import _ from "lodash";

class EngageBookingServices {

    async getServices() {
        const url = `${config.ENGAGE_BOOKINGBUG_SERVICES_ENDPOINT}`;
        const authToken = bbauthService.getToken();

        var requestHeader = new Headers();
        requestHeader.append("Authorization", authToken);

        const response = await fetch(url, {
            method: "GET",
            headers: requestHeader
        });

        if (!response.ok) {
            //config.errorPageRedirect(response);
            throw new Error(
                `getServices service failed, HTTP status ${response.status}`
            );
        }

        const result = await response.json();

        return _.get(result, "service");
    }

    async getResources(page, perpage) {
        const url = `${config.ENGAGE_BOOKINGBUG_RESOURCES_ENDPOINT}${"?page=" + page}${"&perpage=" + perpage}`;
        const authToken = bbauthService.getToken();

        var requestHeader = new Headers();
        requestHeader.append("Authorization", authToken);

        const response = await fetch(url, {
            method: "GET",
            headers: requestHeader
        });

        if (!response.ok) {
            //config.errorPageRedirect(response);
            throw new Error(
                `getResources service failed, HTTP status ${response.status}`
            );
        }

        const result = await response.json();

        return _.get(result, "resources");
    }

    async getBookings(start_date, end_date) {

        const authToken = bbauthService.getToken();

        const url = config.ENGAGE_BOOKINGBUG_BOOKINGS_ENDPOINT;

        var requestHeader = new Headers();
        requestHeader.append("Authorization", authToken);


        const response = await fetch(
            url + "?startDate=" + start_date + "&endDate=" + end_date,
            {
                method: "GET",
                headers: requestHeader
            }
        );

        if (!response.ok) {
            //config.errorPageRedirect(response);
            throw new Error(
                `ResourcesService getBookings failed, HTTP status ${response.status}`
            );
        }

        const data = await response.json();

        return _.get(data, "_embedded.bookings");
    }

    async getServiceDayData(service_id, start_date, end_date) {

        const url = config.ENGAGE_BOOKINGBUG_SERVICE_DAYDATA_ENDPOINT;
        const authToken = bbauthService.getToken();



        var requestHeader = new Headers();
        requestHeader.append("Authorization", authToken);


        const response = await fetch(
            url + "?serviceId=" + service_id + "&startDate=" + start_date + "&endDate=" + end_date,
            {
                method: "GET",
                headers: requestHeader
            }
        );

        if (!response.ok) {
            //config.errorPageRedirect(response);
            throw new Error(
                `getServiceDayData daydata failed, HTTP status ${response.status}`
            );
        }

        const data = await response.json();

        return _.get(data, "days");
    }

    async getResourceDayData(events, service_id, start_date, end_date, page, perpage) {

        const url = config.ENGAGE_BOOKINGBUG_RESOURCE_DAYDATA_ENDPOINT;
        const authToken = bbauthService.getToken();



        var requestHeader = new Headers();
        requestHeader.append("Authorization", authToken);
        requestHeader.append("Content-Type", "application/json");

        const response = await fetch(
            url + "?serviceId=" + service_id + "&startDate=" + start_date + "&endDate=" + end_date + "&page=" + page + "&perpage=" + perpage,
            {
                method: "POST",
                headers: requestHeader,
                body: JSON.stringify(events)

            }
        );

        if (!response.ok) {
            //config.errorPageRedirect(response);
            throw new Error(
                `getResourceDayData daydata failed, HTTP status ${response.status}`
            );
        }

        const data = await response.json();

        return _.get(data, "days");
    }


    async getBookingQuestions(group_id) {

        const url = config.ENGAGE_BOOKINGBUG_BOOKING_QUESTIONS;
        const authToken = bbauthService.getToken();



        var requestHeader = new Headers();
        requestHeader.append("Authorization", authToken);


        const response = await fetch(
            url + "?detailGroupId=" + group_id,
            {
                method: "GET",
                headers: requestHeader
            }
        );

        if (!response.ok) {
            //config.errorPageRedirect(response);
            throw new Error(
                `getBookingQuestions questions failed, HTTP status ${response.status}`
            );
        }

        const data = await response.json();

        return _.get(data, "questions");


    }

    async getBlockedBookings(resourcesIds, startDate, endDate, page, perpage) {
        const authToken = bbauthService.getToken();

        const url = config.ENGAGE_BOOKINGBUG_BLOCKED_BOOKINGS_ENDPOINT + `${"?startDate="}${startDate}${"&endDate="}${endDate}${"&page="}${page}${"&perPage="}${perpage}`;


        var requestHeader = new Headers();
        requestHeader.append("Authorization", authToken);
        requestHeader.append("Content-Type", "application/json");

        const response = await fetch(
            url,
            {
                method: "POST",
                headers: requestHeader,
                body: JSON.stringify(resourcesIds)
            }
        );

        if (!response.ok) {
            //config.errorPageRedirect(response);
            throw new Error(
                `EngageBookingService getBlockedBookings failed, HTTP status ${response.status}`
            );
        }

        const data = await response.json();

        return _.get(data, "_embedded.bookings");
    }
    async getBookedBookings(resourcesIds, startDate, endDate, page, perpage) {
        const authToken = bbauthService.getToken();

        const url = config.ENGAGE_BOOKINGBUG_BOOKED_BOOKINGS_ENDPOINT + `${"?startDate="}${startDate}${"&endDate="}${endDate}${"&page="}${page}${"&perPage="}${perpage}`;


        var requestHeader = new Headers();
        requestHeader.append("Authorization", authToken);
        requestHeader.append("Content-Type", "application/json");

        const response = await fetch(
            url,
            {
                method: "POST",
                headers: requestHeader,
                body: JSON.stringify(resourcesIds)
            }
        );

        if (!response.ok) {
            //config.errorPageRedirect(response);
            throw new Error(
                `EngageBookingService getBookedBookings failed, HTTP status ${response.status}`
            );
        }

        const data = await response.json();

        return _.get(data, "_embedded.bookings");
    }
    async getResourceBookings(resourcesIds, startDate, endDate, page, perpage) {

        const authToken = bbauthService.getToken();

        const url = config.ENGAGE_BOOKINGBUG_RESOURCE_BOOKINGS_ENDPOINT + `${"?startDate="}${startDate}${"&endDate="}${endDate}${"&page="}${page}${"&perPage="}${perpage}`;

        var requestHeader = new Headers();
        requestHeader.append("Authorization", authToken);
        requestHeader.append("Content-Type", "application/json");

        const response = await fetch(
            url,
            {
                method: "POST",
                headers: requestHeader,
                body: JSON.stringify(resourcesIds)
            }
        );

        if (!response.ok) {
            //config.errorPageRedirect(response);
            throw new Error(
                `EngageBookingService getBookedBookings failed, HTTP status ${response.status}`
            );
        }

        const data = await response.json();

        return data;
    }


    async getResourceTimeData(serviceId, startDate, endDate) {
        const url = config.ENGAGE_BOOKINGBUG_RESOURCE_TIMEDATA_ENDPOINT;
        const authToken = bbauthService.getToken();



        var requestHeader = new Headers();
        requestHeader.append("Authorization", authToken);
        requestHeader.append("Content-Type", "application/json");


        const response = await fetch(url + `${"?serviceId="}${serviceId}${"&date="}${startDate}${"&endDate="}${endDate}`, {
            method: "GET",
            headers: requestHeader
        });

        if (!response.ok) {
            //config.errorPageRedirect(response);
            throw new Error(
                `EngageBookingService getResourceServices failed, HTTP status ${
                response.status
                }`
            );
        }

        const data = await response.json();

        return _.get(data, "_embedded.events");
    }

    async updateBookingResource(id, data) {
        const url = `${config.ENGAGE_BOOKINGBUG_UPDATE_BOOKING_RESOURCE_ENDPOINT}${"?id="}${id}`;
        const authToken = bbauthService.getToken();

        var reqHeaders = new Headers();

        reqHeaders.append("Content-Type", "application/json");
        reqHeaders.append("Authorization", authToken);

        const response = await fetch(url, {
            method: "PUT",
            headers: reqHeaders,
            body: JSON.stringify(data)
        });

        if (!response.ok) {
            config.errorPageRedirect(response);
            throw new Error(
                `ResourceService updateBookingResource failed, HTTP status ${response.status}`
            );
        }

        const result = await response.json();

        return result;
    }

    async cancelBooking(id, reason) {
        const url = `${config.ENGAGE_BOOKINGBUG_CANCEL_BOOKING_ENDPOINT}${"?id="}${id}${"&reason="}${reason}`;
        const authToken = bbauthService.getToken();

        var reqHeaders = new Headers();

        reqHeaders.append("Content-Type", "application/json");
        reqHeaders.append("Authorization", authToken);

        const response = await fetch(url, {
            method: "POST",
            headers: reqHeaders,
        });

        if (!response.ok) {
            //config.errorPageRedirect(response);
            throw new Error(
                `ResourceService cancelbooking failed, HTTP status ${response.status}`
            );
        }

        const result = await response.json();

        return result;
    }

    async blockResource(resourceId, start_date, end_date) {

        const url = `${config.ENGAGE_BOOKINGBUG_BLOCK_RESOURCE_ENDPOINT}${"?resourceid="}${resourceId}${"&startdate="}${start_date}${"&enddate="}${end_date}`
        const authToken = bbauthService.getToken();

        var reqHeaders = new Headers();

        reqHeaders.append("Content-Type", "application/json");
        reqHeaders.append("Authorization", authToken);

        const response = await fetch(url, {
            method: "PUT",
            headers: reqHeaders,
        });

        if (!response.ok) {
            //config.errorPageRedirect(response);
            throw new Error(
                `EngageBookingService blockResource failed, HTTP status ${response.status}`
            );
        }

        const result = await response.json();

        return result;
    }

}





export default new EngageBookingServices();