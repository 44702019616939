import React, { Component } from "react";
import { connect } from "react-redux";
import autoBind from "react-autobind";


import * as parcelsAction from "../../../store/Parcels/actions";
import * as parcelsSelector from "../../../store/Parcels/reducer";
import * as residentActions from "../../../store/Residents/actions";
import * as residentSelector from "../../../store/Residents/reducer";
import * as moduleSelectors from "../../../store/ServiceModules/reducer";
import moment from "moment";


import Button from "../../../components/Button/Button";

import DetailList from "../../../components/DetailList/DetailList";
import CompactCard from "../../../components/CompactCard/CompactCard";
import ResidentAddrAutoSuggest from "../../../components/TenantAutoSuggest/ResidentAddrAutoSuggest";
//import Pagination from "../../../components/Pagination/Pagination";
import { createBrowserHistory } from 'history';

import _ from "lodash";
import * as $ from "jquery";
let history = createBrowserHistory();
class CheckInOutScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      type: "parcel",
      searchFormUsed: false,
      barcode: "",
      maxReturnsToShow: 6,
      maxLastCheckedInToShow: 5,
      maxLastCheckedOutToShow: 5,
      memberId: "",
      tenant: "",
      modules: [],
      overlayColor: "",
      currentModule: []
    };

    this.searchFormValidation = null;

    autoBind(this);

    this.props.dispatch(parcelsAction.getStatusCodes());
    this.props.dispatch(parcelsAction.getAllParcels());
    this.props.dispatch(residentActions.getAllResidents());
  }

  componentWillMount() {
    let overlayColor;
    let currentModule = _.find(this.props.modules, o => o.title === "Parcels");
    overlayColor = currentModule.imageOverlayTextColour;

    this.setState({
      overlayColor: overlayColor
    });
  }

  componentDidMount() {
    this.addValidation();
  }

  componentWillUnmount() {
    this.searchFormValidation.destroy();
  }

  handleInputChange(e) {
    var field = e.target.name;
    var value = e.target.value;

    this.setState({
      [field]: value
    });
  }

  addValidation() {
    this.searchFormValidation = $(this.refs.validate).parsley({
      classHandler: function (el) {
        return el.$element.closest("div:not(.input-wrapper):not(.Select)");
      }
    });
  }

  validate(fromSubmit = false) {
    this.searchFormValidation.validate({ force: true });

    const valid = this.searchFormValidation.isValid();

    const { barcode, memberId } = this.state;

    this.setState({
      addParcelFormValid: valid,
      searchFormUsed: true
    });

    var searchValue = barcode.split(' ');
    // Determine where to redirect the user, based on if barcode exists and other tests
    const matchingParcel =
      this.props.parcels.length >= 0 &&
      _.find(this.props.parcels, parcel => {
        return parcel.barcode === barcode;
      });

    const statusText =
      _.isUndefined(matchingParcel) || !matchingParcel
        ? ""
        : _.find(this.props.statusCodes, code => {
          return code.id === matchingParcel.statusId;
        }).status;

    // Parcel exists and is not already checked out
    if (
      _.isUndefined(matchingParcel) === false &&
      statusText !== "collected"
    ) {
      const matchingTenant = _.find(this.props.residents, tenant => {
        return tenant.id === matchingParcel.memberId;
      });

      history.push({
        pathname: "/concierge/parcel/checkout",
        state: {
          tenant: matchingTenant,
          parcel: matchingParcel
        }
      });
    } else if (_.isUndefined(matchingParcel) && searchValue.length === 1) {
      history.push({
        pathname: "/concierge/parcel/checkin",
        state: {
          barcode: barcode,
        }
      });
    }

    //Check if form use valid, tenant was found or selected.
    if (valid === true && fromSubmit === true) {
      if (!_.isEmpty(memberId) && memberId) {
        const matchingTenant = _.find(this.props.residents, tenant => {
          return tenant.id === memberId;
        });

        history.push({
          pathname: "/concierge/parcel/checkout",
          state: {
            tenant: matchingTenant,
            memberId: memberId
          }
        });
      } else {
        // Parcel doesnt exist or barcode matches a checked out / collected parcel
        // If parcel code doesnt exist, start check in page prefilled with barcode

        history.push({
          pathname: "/concierge/parcel/checkin",
          state: {
            barcode: searchValue.length === 1 ? barcode : "",
            tenant: searchValue.length > 1 ? `${searchValue[0]} ${searchValue[1]}` : "",
            memberId: this.state.memberId
          }
        });
      }
      this.setState({
        searchFormUsed: false
      });
    }
  }

  sortByLatest(a, b) {
    // Sort by dateArrived by comparing epoch MS value
    const msA = moment(a.dateArrived).valueOf();
    const msB = moment(b.dateArrived).valueOf();

    if (msA > msB) {
      return -1;
    }

    if (msA < msB) {
      return 1;
    }

    return 0;
  }

  addFullName(parcel) {
    // Add in 'fullName' property to parcel object

    parcel.fullName =
      parcel.resident.firstName + " " + parcel.resident.lastName;

    return parcel;
  }

  select(parcel, tenant) {
    // Add a minor delay to show highlight

    setTimeout(() => {
      history.push({
        pathname: "/concierge/parcel/return",
        state: { parcel: parcel, tenant: tenant }
      });
    }, 400);
  }

  render() {
    const displayedCheckedInParcels =
      this.props.checkedInParcels.length > 0
        ? // Clone imuutableJS object (array) so it can be sorted in place
        this.props.checkedInParcels
          .asMutable({ deep: true })
          .sort(this.sortByLatest)
          .slice(0, this.state.maxLastCheckedInToShow)
          .map(this.addFullName)
        : [];

    const displayedCheckedOutParcels =
      this.props.checkedOutParcels.length > 0
        ? // Clone imuutableJS object (array) so it can be sorted in place
        this.props.checkedOutParcels
          .asMutable({ deep: true })
          .sort(this.sortByLatest)
          .slice(0, this.state.maxLastCheckedInToShow)
          .map(this.addFullName)
        : [];

    const displayDueParcels =
      this.props.dueParcels.length > 0
        ? // Clone imuutableJS object (array) so it can be sorted in place
        this.props.dueParcels //.asMutable({deep: true})
          .slice(0, this.state.maxReturnsToShow)
        : [];

    return (
      <div>
        <div className={"container type-" + this.props.type}>
          {}
          <div className="content-wrapper">
            <form
              ref="validate"
              onSubmit={e => {
                e.preventDefault();
              }}
              data-parsley-errors-container="#validation-messages"
              data-parsley-focus="none"
            >
              <div className="inputs">
                <ResidentAddrAutoSuggest
                  labelText="Scan first parcel or search by name / address"
                  placeHolder="Barcode"
                  clearableIfValid={true}
                  value={this.state.tenant}
                  onSelected={data => {
                    this.setState({
                      memberId: data.memberId,
                      tenant: data.tenant,
                      address: data.address
                    });

                    setTimeout(() => {
                      this.state.searchFormValidation && this.validate();
                    }, 0);
                  }}
                  defaultText={dataVal => {
                    this.setState({
                      barcode: dataVal
                    });
                  }}
                />
              </div>

              <div
                className={
                  "validation-messages" +
                  (this.state.returnFormUsed ? " valid" : " invalid")
                }
                id="validation-messages"
              >
                {this.state.searchFormUsed && (
                  <div>
                    {/* <p className="message type-success">Sending Parcel To Server...</p> */}
                  </div>
                )}
              </div>

              <div className="align-center">
                <Button
                  size="cta-medium"
                  text=""
                  icon="arrow-right-c"
                  handleClick={() => {
                    this.validate(true);
                  }}
                />
              </div>
            </form>

            <h1 style={{ color: this.state.overlayColor }}>Recent</h1>

            <h4>Checked-in</h4>

            <DetailList
              listType="parcel-overview-check-in"
              data={displayedCheckedInParcels}
              headingsAlone={true}
              emptyMessage="Checked-in parcels will appear here"
              actionClick={index => {
                //this.removeFromQueue(index);
              }}
            />

            <h4 className="double-margin-top">Check-out</h4>

            <DetailList
              listType="parcel-overview-check-out"
              data={displayedCheckedOutParcels}
              headingsAlone={true}
              emptyMessage="Checked-out parcels will appear here"
              actionClick={index => {
                //this.removeFromQueue(index);
              }}
            />

            <h1
              className="double-margin-top"
              style={{ color: this.state.overlayColor }}
            >
              Return
            </h1>

            <h4 className="double-margin-bottom">Due to be returned</h4>

            <div className="card-list">
              {_.map(displayDueParcels, parcel => {
                const tenant = _.find(this.props.residents, tenant => {
                  return tenant.id === parcel.memberId;
                });

                return (
                  <CompactCard
                    key={parcel.id}
                    type={"parcel"}
                    highlighted={true}
                    onClick={() => {
                      this.select(parcel, tenant);
                    }}
                    data={{
                      id: parcel.id,
                      parcel: parcel,
                      tenant: tenant
                    }}
                  />
                );
              })}
            </div>

            <div className="align-center double-margin-top">
              <Button
                size="cta-medium"
                text="See all returns"
                handleClick={() => {
                  history.push({
                    pathname: "/concierge/parcel/returnlog",
                    state: { dueParcels: this.props.dueParcels }
                  });
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    statusCodes: parcelsSelector.getStatusCodes(state),
    checkedInParcels: parcelsSelector.getCheckedInParcels(state),
    checkedOutParcels: parcelsSelector.getCheckedOutParcels(state),
    parcels: parcelsSelector.getParcels(state),
    returnedParcels: parcelsSelector.getReturnedParcels(state),
    modules: moduleSelectors.loadModules(state),
    dueParcels: parcelsSelector.getDueParcels(state),
    residents: residentSelector.getResidents(state)
  };
}

export default connect(mapStateToProps)(CheckInOutScreen);
