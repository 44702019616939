import React, { Component } from "react";

import Autosuggest from "react-autosuggest";
import InputComponent from "../Input/Input";



import _ from "lodash";
import autoBind from "react-autobind";

import "../../sass/components/Input/index.css";
import "../../sass/components/VisitorAutoSuggestComponent/index.css";
import { stringify } from "querystring";

class VisitorAutoSuggestComponent extends Component {
    constructor(props) {
        super(props);

        this.defaultState = {
            value: "",
            suggestions: [],
            valid: false
        };

        this.state = this.defaultState;
        this.emptyResponse = {
            visitorId: "",
            meetingId: "",
            companyName: "",
            visitor: "",
            dataMatch: undefined
        };

        this.selectedVisitorFromDefault = false;
        autoBind(this);
    }


    UNSAFE_componentWillReceiveProps(nextProps) {

        if (
            nextProps.meetings.length > 0 &&
            !_.isUndefined(nextProps.defaultVisitorId) &&
            _.isNumber(nextProps.defaultVisitorId) &&
            this.selectedVisitorFromDefault === false
          ) {
            this.selectedVisitorFromDefault = true;

            const startVisitor = nextProps.meetings.find(meeting => {
                return meeting.id === nextProps.defaultVisitorId;
            });

            if(!_.isUndefined(startVisitor)){

                
                this.setState({
                    value: `${startVisitor.visitor.firstName} ${startVisitor.visitor.surname} ${'-'} ${startVisitor.visitor.companyName}`,
                    valid: true
                });

                this.handleVisitorSelected(startVisitor);
            }

          }

          if(nextProps.meetings.length > 0 && !_.isUndefined(nextProps.defaultValue) && this.selectedVisitorFromDefault === false){
              this.setState({
                  value :nextProps.defaultValue ,
                  valid : true
              });
          }

         
    }

    reset() {
        this.setState(this.defaultState);
    }

    UNSAFE_componentWillUpdate(nextProps, nextState){
        if(this.state.value !== nextState.value && nextState.value === ""){
            this.setState({
                valid : false
            });
        }
    }
    // Autosuggest functions ++++++++++++++++++++++++++++++++++++++++++
    getSuggestionValue(suggestion) {
        // const { name } = this.state;

        this.handleVisitorSelected(suggestion);
        if (suggestion) this.setState({ disabled: "" });
        this.props.matchfound && this.props.matchfound(undefined);

        return `${suggestion.visitor.firstName} ${suggestion.visitor.surname} - ${(!_.isUndefined(suggestion.visitor.companyName) && suggestion.visitor.companyName !== '') ? suggestion.visitor.companyName :" N/A "}`;
        // ${suggestion.visitor.companyName}`;
    }

    handleClear(e) {
        this.reset();
        this.props.matchfound && this.props.matchfound(undefined);
        this.props.fieldManualCleared && this.props.fieldManualCleared(true);
        this.props.fieldCleared && this.props.fieldCleared(undefined);
        this.props.handleKeyPress && this.props.handleKeyPress({ key: "Reset" }, []);
    }
    getSuggestions(val) {
        const { meetings } = this.props;


        if (!meetings) return [];


        const inputValue = val.trim().toLowerCase();

        const inputParts = inputValue.split(" ").filter(value => {
            return value !== "";
        });


        const match = _.filter(_.filter(meetings, o => {

            if (inputParts.length === 1) {

                return (_.startsWith(o.visitor.firstName.toLowerCase(), inputParts[0].toLowerCase()) ||
                    _.startsWith(o.visitor.surname.toLowerCase(), inputParts[0].toLowerCase()) ||
                    _.startsWith(o.visitor.companyName.toLowerCase(), inputParts[0].toLowerCase()));
            } else if (inputParts.length >= 3) {

                return (_.startsWith(o.visitor.firstName.toLowerCase(), inputParts[0].toLowerCase()) &&
                    _.startsWith(o.visitor.surname.toLowerCase(), inputParts[1].toLowerCase()) &&
                    _.startsWith(o.visitor.companyName.toLowerCase(), inputParts[inputParts.length - 1].toLowerCase()));
            } else if (inputParts.length >= 2 && inputParts.length < 3) {

                return (_.startsWith(o.visitor.firstName.toLowerCase(), inputParts[0].toLowerCase()) &&
                    _.startsWith(o.visitor.surname.toLowerCase(), inputParts[1].toLowerCase()));

            } else {

                return undefined
            }

        }), data => !_.isUndefined(data));



        // if (inputParts < 3)
        this.props.matchfound && this.props.matchfound(match.length > 0);


        this.setState({
            dataMatch: match
        });

        return match;
    }

    onSuggestionsFetchRequested = ({ value }) => {

        this.setState({ suggestions: this.getSuggestions(value) });
    };

    onSuggestionsClearRequested = () => {


        //this.props.matchfound && this.props.matchfound(undefined);
        // if (this.state.dataMatch && this.state.dataMatch.length > 0)
        //   this.props.matchfound && this.props.matchfound(undefined);
        // this.setState({
        //   suggestions: []
        // });
    };

    onChange = (event, { newValue }) => {

        let inputVal = newValue.split(" ").filter(value => {
            return value !== "";
        });



        if (inputVal.length === 0) {

            this.setState({ visitor: undefined, dataMatch: [] });
            this.reset();
            this.props.matchfound && this.props.matchfound(undefined);
            this.props.fieldManualCleared && this.props.fieldManualCleared(true);

        } else {
            this.props.fieldCleared && this.props.fieldCleared(undefined)
        }
        if (newValue.length > 2) {
            this.handleVisitorChange(newValue);
        }
        this.props.handleInputValue && this.props.handleInputValue(newValue);

        this.setState({
            value: newValue,
            valid: inputVal.length > 0
        });

        // setTimeout(() => {
        //   // Delayed because parseley not ready if user selected a suggestion

        //   this.setState({
        //     valid: $(this.refs.autosuggest.input)
        //       .parsley()
        //       .isValid()
        //   });
        // }, 0);

        //this.checkMatch(newValue);
    };
    handleSuggestionSelected(suggestion, suggestionValue, suggestionIndex, sectionIndex, method) {

    }
    handleVisitorSelected(vis, setValue = false) {

        const stateChanges = {
            meetingId: vis.id,
            visitorId: vis.visitor.id,
            companyName: vis.visitor.companyName,
            visitor: vis.visitor.firstName + " " + vis.visitor.surname,
            matchfound: true,
            ...(setValue
                ? { value: vis.visitor.firstName + " " + vis.visitor.surname, valid: true }
                : {})
        };


        this.setState(stateChanges);
        this.props.matchfound && this.props.matchfound(true);
        this.props.onSelected && this.props.onSelected(stateChanges);
    }

    handleVisitorChange(value) {
        // const { memberId } = this.state;
        this.setState({
            name: value
        });
    }

    renderSuggestion(suggestion) {
        return (
            <div className="Select-option">

{suggestion.visitor.firstName} {suggestion.visitor.surname} {"-"} {(!_.isUndefined(suggestion.visitor.companyName) && suggestion.visitor.companyName !== '') ? suggestion.visitor.companyName :" N/A "}
                {/* {suggestion.visitor.companyName} */}

            </div>
        );
    }

    checkMatch(currentValue) {


        const matchedVisitor = this.props.meetings.find(meeting => {
            if (currentValue) {
                const value = currentValue.toLowerCase();
                const parts = value.split(" ").filter(value => {
                    return value !== "";
                });

                if (parts.length > 3) {
                    if (
                        _.startsWith(meeting.visitor.firstName.toLowerCase(), parts[0].toLowerCase()) &&
                        _.startsWith(meeting.visitor.surname.toLowerCase(), parts[1].toLowerCase()) &&
                        _.startsWith(meeting.visitor.companyName.toLowerCase(), parts[3].toLowerCase())) {


                        return true;

                    }
                }

            }


            return false;
        });


        // const matchedVisitor_ = _.filter(this.props.meetings, meeting => {
        //   if (currentValue) {
        //     const value = currentValue.toLowerCase();
        //     const parts = value.split(" ").filter(value => {
        //       return value !== "";
        //     });

        //     if (parts.length > 0) {
        //       if (
        //         _.startsWith(meeting.visitor.firstName.toLowerCase(), parts[0].toLowerCase()) ||
        //         _.startsWith(meeting.visitor.surname.toLowerCase(), parts[0].toLowerCase()) ||
        //         _.startsWith(meeting.visitor.companyName.toLowerCase(), parts[0].toLowerCase())) {


        //         return meeting;

        //       }
        //     }

        //   }

        //   return null
        // });



        if (_.isUndefined(matchedVisitor) === false) {
            // if (matchedVisitor_.length === 1) {


            this.handleVisitorSelected(matchedVisitor);

        } else {

            if (currentValue === "") {
                //this.props.matchfound && this.props.matchfound(undefined);
            }


            //this.props.matchfound && this.props.matchfound(false);

            this.props.onSelected && this.props.onSelected(this.emptyResponse);
        }
    }

    inputClick() {
        if (
            this.state.valid === true &&
            !_.isUndefined(this.props.clearableIfValid) &&
            this.props.clearableIfValid === true
        ) {
            this.reset();
            //this.props.matchfound && this.props.matchfound(undefined);
            this.props.onSelected && this.props.onSelected(this.emptyResponse);
            this.props.handleKeyPress && this.props.handleKeyPress({ key: "Clear" }, []);
        }
    }

    render() {
        const { value, suggestions, valid } = this.state;

        const disabled =
            _.isUndefined(this.props.disabled) || this.props.disabled === false
                ? false
                : true;

        const inputProps = {
            placeholder: this.props.placeHolder ? this.props.placeHolder : "Visitor",
            value,
            onChange: this.onChange,
            onClick: this.inputClick
        };

       
        return (

            <Autosuggest
                ref="autosuggest"
                suggestions={suggestions}
                focusInputOnSuggestionClick={false}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                getSuggestionValue={this.getSuggestionValue}
                onSuggestionSelected={(event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) =>
                    this.handleSuggestionSelected(suggestion, suggestionValue, suggestionIndex, sectionIndex, method)}
                renderSuggestion={this.renderSuggestion}
                inputProps={inputProps}
                theme={{
                    // maybe make global for reuse at some point
                    container:
                        "auto-suggest Select " +
                        (!_.isUndefined(this.props.clearableIfValid) &&
                            this.props.clearableIfValid === true
                            ? "is-clearable"
                            : ""),
                    suggestionsList: "Select-menu-outer",
                    suggestion: "Select-menu"
                }}
                renderInputComponent={inputProps => {
                    // const iconProp =
                    //   !_.isUndefined(this.props.clearableIfValid) &&
                    //     this.props.clearableIfValid === true &&
                    //     valid === true
                    //     ? { icon: "ion-close-round" }
                    //     : {};

                    const iconProp =
                        valid === true
                            ? { icon: "ion-close-round" }
                            : {};

                    return (
                        <div>
                            <label htmlFor={"form-input-visitor"} className="like-h4">
                                {this.props.label ? this.props.label : "Visitor Name"}
                            </label>
                            <div className="Select-input">
                                <input
                                    // autoSuggestProps={inputProps}
                                    //label={this.props.label ? this.props.label : "Visitor"}
                                    name="visitor"
                                    autoComplete="off"
                                    //placeholder={
                                    //     this.props.placeHolder ? this.props.placeHolder : "Visitor Name"
                                    // }
                                    disabled={disabled}
                                    value={this.state.value}
                                    onChange={inputProps.onChange}
                                    //onClick={inputProps.onClick}
                                    // handleKeyPress={e => {

                                    //     if (e.key === "Enter" && !_.isUndefined(this.props.handleKeyPress)) {

                                    //         this.props.handleKeyPress(e, this.state.dataMatch);
                                    //     }



                                    // }}
                                    onKeyPress={e => {


                                        if (e.key === "Enter" && !_.isUndefined(this.props.handleKeyPress)) {

                                            if (this.state.value || this.state.value !== "") {

                                                this.props.handleKeyPress(e, this.state.dataMatch);
                                            }
                                            else {
                                                if (this.props.listDefault)
                                                    this.props.handleKeyPress(e, this.props.meetings);
                                                else {
                                                    this.props.handleKeyPress(e, []);

                                                }

                                            }

                                        }

                                    
                                    }
                                    }
                                    onBlur={e => {
                                        /* Ensures that member ID fetched even if not selected via autosuggest */

                                       // this.checkMatch(e.target.value);

                                    }

                                    }
                                    {...inputProps}
                                // parsley={{
                                //   "data-parsley-matches-one-of": JSON.stringify({
                                //     allSuggestions: this.props.meetings.map(meeting => {
                                //       return { value: meeting.visitor.firstName + " " + meeting.visitor.surname };
                                //     })
                                //   }),
                                //   "data-parsley-pattern": "/^[a-z ,.'-]+$/i",
                                //   "data-parsley-minlength": 4,
                                //   "data-parsley-required": true,
                                //   "data-parsley-error-message":
                                //     "Please enter an existing visitor name"
                                // }}
                                //{...iconProp}
                                />
                                {valid === true && <div className={"icon ion-close-round"} onClick={e => this.handleClear(e)}> </div>}
                            </div>
                        </div>
                    );
                }
                }
            />



        );
    }
}

export default VisitorAutoSuggestComponent;
