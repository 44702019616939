export const MEETING_RECORD_LOADED_SUCCESS = "MEETING_RECORD_LOADED_SUCCESS";
export const MEETING_RECORD_LOADED_FAIL = "MEETING_RECORD_LOADED_FAIL";
export const NEW_MEETING_CREATED_SUCCESS = "NEW_MEETING_CREATED_SUCCESS";
export const NEW_MEETING_CREATED_FAIL = "NEW_MEETING_CREATED_FAIL";
export const NEW_METTING_ADD_TO_QUEUE = "NEW_METTING_ADD_TO_QUEUE";
export const NEW_METTING_REMOVE_FROM_QUEUE = "NEW_METTING_REMOVE_FROM_QUEUE";
export const MEETINGS_LOADED_SCCESS = "MEETINGS_LOADED_SCCESS";
export const MEETINGS_LOADED_FAIL = "MEETINGS_LOADED_FAIL";
export const UPDATE_MEETING_SUCCESS = "UPDATE_MEETING_SUCCESS";
export const UPDATE_MEETING_FAIL = "UPDATE_MEETING_FAIL";
export const MEETING_UPDATED = "MEETING_UPDATED";

export const FILTER_CHECKED_IN = "FILTER_CHECKED_IN";
export const FILTER_CHECKED_OUT = "FILTER_CHECKED_OUT";
export const ADD_TO_FILTER_CHECKED_IN = "ADD_TO_FILTER_CHECKED_IN";
export const REMOVE_FROM_FILTER_CHECKED_IN = "REMOVE_FROM_FILTER_CHECKED_IN";
export const ADD_TO_FILTER_CHECKED_OUT = "ADD_TO_FILTER_CHECKED_OUT";
export const REMOVE_FROM_FILTER_CHECKED_OUT = "REMOVE_FROM_FILTER_CHECKED_OUT";
export const MEETING_SELECTED = "MEETING_SELECTED";
export const MEETINGS_FILTER_LOADED_SUCCESSFULLY = "MEETING_FILTER_LOADED_SUCCESSFULLY";
export const MEETINGS_FILTER_LOADED_FAIL = "MEETING_FILTER_LOADED_FAIL";
export const ADD_TO_CHECKED_IN_SELECT_QUEUE = "ADD_TO_CHECKED_IN_SELECT_QUEUE";
export const REMOVE_FROM_CHECKED_IN_SELECT_QUEUE =
  "REMOVE_FROM_CHECKED_IN_SELECT_QUEUE";
export const ADD_TO_CHECKED_OUT_SELECT_QUEUE =
  "ADD_TO_CHECKED_OUT_SELECT_QUEUE";
export const REMOVE_FROM_CHECKED_OUT_SELECT_QUEUE =
  "REMOVE_FROM_CHECKED_OUT_SELECT_QUEUE";

export const ADD_TO_UNCOLLECTED_SELECT_QUEUE =
  "ADD_TO_UNCOLLECTED_SELECT_QUEUE";
export const REMOVE_FROM_UNCOLLECTED_SELECT_QUEUE =
  "REMOVE_FROM_UNCOLLECTED_SELECT_QUEUE";
export const ADD_TO_COLLECTED_SELECT_QUEUE = "ADD_TO_COLLECTED_SELECT_QUEUE";
export const REMOVE_FROM_COLLECTED_SELECT_QUEUE =
  "REMOVE_FROM_COLLECTED_SELECT_QUEUE";

export const MEETINGS_LOG_FETCHED = "MEETINGS_LOG_FETCHED";
export const SET_MEETINGS_QUEUE = "SET_MEETINGS_QUEUE";
export const SPECIAL_INSTRUCTIONS_APPENDED_SUCCESS =
  "SPECIAL_INSTRUCTIONS_APPENDED_SUCCESS";

export const SEND_CHECKIN_NOTIFICATION_SUCCESS =
  "SEND_CHECKIN_NOTIFICATION_SUCCESS";

export const SEND_CHECKIN_NOTIFICATION_FAIL = "SEND_CHECKIN_NOTIFICATION_FAIL";

export const CLEAR_MEETINGS = "CLEAR_MEETINGS";
