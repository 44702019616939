import React, { Component } from 'react';
import { connect } from 'react-redux';
import Autosuggest from 'react-autosuggest';

import PropTypes from 'prop-types';
import _ from 'lodash';
import autoBind from 'react-autobind';
import * as $ from 'jquery';
import moment from 'moment';

import InputComponent from '../../components/Input/Input';

import * as residentActions from '../../store/Residents/actions';
import * as residentSelectors from '../../store/Residents/reducer';
import * as visitorActions from '../../store/Visitors/actions';
import * as visitorSelectors from '../../store/Visitors/reducer';

/**
 * Pairs up an <Autosuggest and <InputComponent together with the relevant state and events,
 * optionally clearable if clearableIfValid property specified
 */
class ResidentVisitorAutoSuggest extends Component {
	constructor(props) {
		super(props);

		this.defaultState = {
			value: '',
			suggestions: [],
			valid: false,
			lookupMembers: []
		};

		this.state = this.defaultState;

		this.props.dispatch(residentActions.getAllResidents());

		let date = moment(new Date()).format('YYYY-MM-DD');
		this.props.dispatch(visitorActions.getNotifiledVisitorsByDate(date));

		this.emptyResponse = {
			memberId: '',
			tenant: '',
			type: ''
		};

		this.selectedTenantFromDefault = false;

		autoBind(this);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		// Set the autosuggest to an initial value if it is provided
		if (
			nextProps.residents.length > 0 &&
			!_.isUndefined(nextProps.defaultTenantId) &&
			this.selectedTenantFromDefault === false
		) {
			this.selectedTenantFromDefault = true;

			const startTenant = nextProps.residents.find((tenant) => {
				return tenant.id === nextProps.defaultTenantId;
			});

			this.handleTenantSelected(startTenant);

			this.setState({
				value: startTenant.firstName + ' ' + startTenant.lastName,
				valid: true
			});
		}

		if (nextProps.visitors && nextProps.visitors.length > 0) {
			let lookupVisitor = _.map(nextProps.visitors, (x) => {
				return {
					id: x.id,
					firstName: x.firstName,
					lastName: x.surname,
					email: x.email,
					type: x.memberType,
					companyName: x.companyName
				};
			});
			let lookupMember =
				nextProps.residents && nextProps.residents.length > 0
					? _.map(nextProps.residents, (o) => {
							return {
								id: o.id,
								email: o.email,
								firstName: o.firstName,
								lastName: o.lastName,
								type: o.memberType,
								companyName: ''
							};
						})
					: [];
			let lookupArray = lookupMember;

			if (lookupVisitor && lookupVisitor.length > 0) {
				for (var i = 0; i < lookupVisitor.length; i++) {
					lookupArray.push(lookupVisitor[i]);
				}
			}

			this.setState({
				lookupMembers: lookupArray
			});
		}

		if (nextProps.visitors.length === 0) {
			let lookupMember =
				nextProps.residents && nextProps.residents.length > 0
					? _.map(nextProps.residents, (o) => {
							return {
								id: o.id,
								email: o.email,
								firstName: o.firstName,
								lastName: o.lastName,
								type: o.memberType,
								companyName: ''
							};
						})
					: [];

			this.setState({
				lookupMembers: lookupMember
			});
		}
	}

	reset() {
		this.setState(this.defaultState);
	}

	// Autosuggest functions ++++++++++++++++++++++++++++++++++++++++++
	getSuggestionValue(suggestion) {
		// const { name } = this.state;

		this.handleTenantSelected(suggestion);
		if (suggestion) this.setState({ disabled: '' });

		/**Handle what is displayed on the auto-suggest Text field */
		return `${suggestion.firstName} ${suggestion.lastName}`;
	}

	getSuggestions(val) {
		const { lookupMembers } = this.state;
		if (!lookupMembers) return [];

		//safe guard residents data return null value in some records
		var data_ = _.filter(lookupMembers, (obj) => {
			return obj.firstName !== null || obj.lastName !== null;
		});
		let suggestionsAvailable = false;
		const match = _.filter(data_, (o) => {
			suggestionsAvailable = o.firstName && _.startsWith(o.firstName.toLowerCase().trim(), val.toLowerCase());
			if (suggestionsAvailable === false) {
				suggestionsAvailable = o.lastName && _.startsWith(o.lastName.toLowerCase().trim(), val.toLowerCase());
				if (suggestionsAvailable === false) {
					suggestionsAvailable =
						o.firstName &&
						o.lastName &&
						_.startsWith(
							o.firstName.toLowerCase().trim() + ' ' + o.lastName.toLowerCase().trim(),
							val.toLowerCase()
						);
				}
			}
			return suggestionsAvailable;
		});

		return match;
	}

	onSuggestionsFetchRequested = ({ value }) => {
		this.setState({ suggestions: this.getSuggestions(value) });
	};

	onSuggestionsClearRequested = () => {};

	onChange = (event, { newValue }) => {
		if (newValue.length === 0) this.setState({ tenant: undefined });
		if (newValue.length > 2) {
			this.handleTenantChange(newValue);
		}

		this.setState({
			value: newValue
		});

		setTimeout(() => {
			// Delayed because parseley not ready if user selected a suggestion

			this.setState({
				valid: $(this.refs.autosuggest.input).parsley().isValid()
			});
		}, 0);

		//	this.checkMatch(newValue);
	};

	handleTenantSelected(ten, setValue = false) {
		const stateChanges = {
			memberId: ten.id,
			companyName: ten.companyName,
			tenant: ten.firstName + ' ' + ten.lastName,
			type: ten.type,
			...(setValue ? { value: ten.firstName + ' ' + ten.lastName, valid: true } : {})
		};

		this.setState(stateChanges);

		this.props.onSelected && this.props.onSelected(stateChanges);
	}

	handleTenantChange(value) {
		// const { memberId } = this.state;
		this.setState({
			name: value
		});
	}

	/**Handles what fields are displayed in the drop down List, styling can be applied to list*/
	renderSuggestion(suggestion, { query, isHighlighted }) {
		return (
			<div className={`${'Select-option'} ${isHighlighted === true ? 'highlighted' : ''}`}>
				{suggestion.firstName} {suggestion.lastName} {'['} {suggestion.type}
				{' ]  '}
				{suggestion.companyName}
			</div>
		);
	}

	handleSuggestionSelected(suggestion, suggestionValue, suggestionIndex, sectionIndex, method) {
		this.handleTenantSelected(suggestion);
	}

	checkMatch(currentValue) {
		const matchedTenant = this.state.lookupMembers.find((tenant) => {
			const value = currentValue.toLowerCase();
			const parts = value.split(' ').filter((value) => {
				return value !== '';
			});

			if (
				parts.length === 2 &&
				tenant.firstName.toLowerCase() === parts[0].trim() &&
				tenant.lastName.toLowerCase() === parts[1].trim()
			) {
				return true;
			}

			return false;
		});

		if (_.isUndefined(matchedTenant) === false) {
			this.handleTenantSelected(matchedTenant);
		} else {
			this.props.onSelected && this.props.onSelected(this.emptyResponse);
		}
	}

	inputClick() {
		if (
			this.state.valid === true &&
			!_.isUndefined(this.props.clearableIfValid) &&
			this.props.clearableIfValid === true
		) {
			this.reset();

			this.props.onSelected && this.props.onSelected(this.emptyResponse);
		}
	}

	render() {
		const { value, suggestions, valid, lookupMembers } = this.state;
		const { label, placeHolder } = this.props;
		const disabled = _.isUndefined(this.props.disabled) || this.props.disabled === false ? false : true;

		const inputProps = {
			placeholder: 'Tenant',
			value,
			onChange: this.onChange,
			onClick: this.inputClick
		};

		return (
			<Autosuggest
				ref="autosuggest"
				suggestions={suggestions}
				onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
				onSuggestionsClearRequested={this.onSuggestionsClearRequested}
				getSuggestionValue={this.getSuggestionValue}
				onSuggestionSelected={(event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) =>
					this.handleSuggestionSelected(suggestion, suggestionValue, suggestionIndex, sectionIndex, method)}
				renderSuggestion={this.renderSuggestion}
				inputProps={inputProps}
				theme={{
					// maybe make global for reuse at some point
					container:
						'auto-suggest Select ' +
						(!_.isUndefined(this.props.clearableIfValid) && this.props.clearableIfValid === true
							? 'is-clearable'
							: ''),
					suggestionsList: 'Select-menu-outer',
					suggestion: 'Select-menu'
				}}
				renderInputComponent={(inputProps) => {
					const iconProp =
						!_.isUndefined(this.props.clearableIfValid) &&
						this.props.clearableIfValid === true &&
						valid === true
							? { icon: 'ion-close-round' }
							: { icon: 'ion-chevron-down' };

					return (
						<InputComponent
							autoSuggestProps={inputProps}
							label={!_.isUndefined(label) ? label : 'Tenant'}
							name="tenant"
							placeHolder={!_.isUndefined(placeHolder) ? placeHolder : 'Tenant Name'}
							disabled={disabled}
							value={this.state.value}
							onChange={inputProps.onChange}
							onClick={inputProps.onClick}
							onBlur={(e) => {
								/* Ensures that member ID fetched even if not selected via autosuggest */
								//this.checkMatch(e.target.value);
							}}
							parsley={{
								'data-parsley-matches-one-of': JSON.stringify({
									allSuggestions: lookupMembers.map((tenant) => {
										return { value: tenant.firstName + ' ' + tenant.lastName };
									})
								}),
								'data-parsley-pattern': "/^[a-z ,.'-]+$/i", // alpha chars only https://stackoverflow.com/questions/8059370/
								'data-parsley-minlength': 4,
								'data-parsley-required': false,
								'data-parsley-error-message': 'Please enter an existing tenant name'
							}}
							{...iconProp}
						/>
					);
				}}
			/>
		);
	}
}

function mapStateToProps(state) {
	return {
		residents: residentSelectors.getResidents(state),
		visitors: visitorSelectors.getVisitors(state)
	};
}

ResidentVisitorAutoSuggest.propTypes = {
	/* Optional initial tenant id to start with, (used when navigating back etc...) */
	defaultTenantId: PropTypes.number,
	/* Callback function for when item is selected */
	onSelected: PropTypes.func,
	/* Optional disable the input field */
	disabled: PropTypes.bool,
	/* Specifies if a X icon shows and the input field becomes clickable if the autosuggest is valid */
	clearableIfValid: PropTypes.bool,
	/** Optional label text*/
	label: PropTypes.string,
	/** Optional PlaceHolder Text */
	placeHolder: PropTypes.string
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(ResidentVisitorAutoSuggest);
