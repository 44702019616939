import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import autobind from 'react-autobind';
import InputComponent from '../Input/Input';
import Button from '../Button/Button';
import moment from 'moment';
import AriaModal from 'react-aria-modal';
import _ from 'lodash';
import * as $ from 'jquery';
import { createBrowserHistory } from 'history';
import * as parcelActions from '../../store/Parcels/actions';
import * as parcelSelectors from '../../store/Parcels/reducer';

import '../../sass/components/ParcelModal/index.css';
import '../../sass/iescss.css';

import * as configHelper from '../../helpers/configHelper';
import * as helpers from '../../helpers/stringHelper';
/**
 * Main Modal event, diferent modal states loaded as type is specified
 */

const PortalTypes = configHelper.PortalTypes;
const ParcelScreens = {
	Register: 'register',
	Result: 'result'
};

const browserHistory = createBrowserHistory();
class ParcelModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			switchActive: '',
			specialInstruction_chk: false,
			specialInstructions: '',
			modalActive: true,
			portalType:
				this.props.portalSettings && !_.isUndefined(this.props.portalSettings.portalType)
					? this.props.portalSettings.portalType
					: '',
			currentIndex: this.props.currentIndex,
			selectedParcels: [],
			buttontextNext: 'Next',
			buttontextBack: 'Cancel',
			isLocationEmpty: true,
			selectedOptions: [],
			locationSelect: false,
			isParcelRegistered: false,
			buttonFinish: 'Finish',
			screen: ParcelScreens.Register,
			statusFailure: false
		};

		this.registerParcelValidation = null;
		autobind(this);
	}

	componentDidMount() {
		this.addValidation();
		/**
     * exclude control + J on input response, this is needed as scanner append Ctrl + J at end of value scanned
     * https://stackoverflow.com/questions/27960841/usb-barcode-scanner-opens-browsers-downloads-page/27961410
     */

		window.addEventListener('keydown', function(event) {
			if (event.keyCode === 17 || event.keyCode === 74) {
				if (event.ctrlKey) event.preventDefault();
			}
		});
	
		let tempArrayForOptions = [];
		let tempArray = [];

		if (!_.isUndefined(this.props.selectedParcels)) {
			for (var i = 0; i < this.props.selectedParcels.length; i++) {
				var tempItem = {};
				tempItem.name = this.props.selectedParcels[i].name;
				tempItem.id = this.props.selectedParcels[i].id;
				tempItem.location = this.props.selectedParcels[i].location;
				tempItem.barcode = this.props.selectedParcels[i].barcode;
				tempArray.push(tempItem);

				var tempItemForOptions = { value: undefined };

				if (this.props.parcelLocations && this.props.parcelLocations.length === 1) {
					if (i === this.state.currentIndex) {
						tempItemForOptions = { value: this.props.parcelLocations[0].id };
						tempArray[i].location = this.props.parcelLocations[0].id;
					}
				}

				tempArrayForOptions.push(tempItemForOptions);
			}

			this.setState(
				{
					selectedParcels: [ ...tempArray ],
					selectedOptions: [ ...tempArrayForOptions ]
				},
				() => {
					if (this.state.selectedParcels.length === 1) {
						this.setState({
							buttontextNext: 'Register'
						});
					}
				}
			);
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state.isParcelRegistered !== prevState.isParcelRegistered) {
			if (this.state.isParcelRegistered === true) {
				let itemList = _.filter(
					_.map(this.state.selectedParcels, (selectedParcel, rowIndex) => {
						if (!_.find(this.props.registeredParcels, { id: selectedParcel.id })) return undefined;

						return selectedParcel;
					}),
					(item) => _.isUndefined(item)
				);

				if (itemList.length > 0) {
					this.setState({
						statusFailure: true,
						buttonFinish: 'I Understand'
					});
				}
				/**send parcel checkin/arrived/registerd notification */
				this.sendParcelArrivedNotification(this.props.registeredParcels);
			}

			this.setState({
				isParcelRegistered: false
			});
		}

		//pre update parcel location when just one parcel location is available
		if (this.state.currentIndex !== prevState.currentIndex) {
			let currentOption = this.state.selectedOptions;

			var currentArray = _.map(this.state.selectedParcels, (parcel, i) => {
				if (this.props.parcelLocations && this.props.parcelLocations.length === 1) {
					if (i === this.state.currentIndex && currentOption && currentOption.length > 0) {
						currentOption[i].value = this.props.parcelLocations[0].id;
						parcel.location = this.props.parcelLocations[0].id;
					}
				}

				return parcel;
			});

			this.setState({
				selectedOptions: currentOption,
				selectedParcels: currentArray
			});
		}
	}

	sendParcelArrivedNotification(parcels) {
		this.props.dispatch(
			parcelActions.notifyCheckinParcels(parcels, (success) => {
				if (success === true) {
				}
			})
		);
	}
	addValidation() {
		this.registerParcelValidation = $(this.refs.validate).parsley({
			classHandler: function(el) {
				return el.$element.closest('div:not(.input-wrapper):not(.Select)');
			}
		});
	}

	handleChange(e) {
		let field = e.target.name;
		let value = e.target.value;
		this.setState({
			[field]: value
		});

		var tempArray = [];
		tempArray = this.state.selectedParcels.slice();
		tempArray[this.state.currentIndex].location = e.target.value;

		this.setState({
			selectedParcels: tempArray
		});
	}

	handleLocationChange(e) {
		let data = _.map(this.state.selectedOptions, (item, index) => {
			if (index === this.state.currentIndex) {
				return { value: e.target.value };
			}

			return item;
		});

		this.setState({ selectedOptions: data });

		let field = e.target.name;
		let value = e.target.value;
		this.setState({
			[field]: value
		});

		var tempArray = [];
		tempArray = this.state.selectedParcels.slice();
		tempArray[this.state.currentIndex].location = e.target.value;

		this.setState({
			selectedParcels: tempArray
		});
	}

	handleBarcodeChange(e) {
		let field = e.target.name;
		let value = e.target.value;
		this.setState({
			[field]: value
		});

		var tempArray = [];
		tempArray = this.state.selectedParcels.slice();
		tempArray[this.state.currentIndex].barcode = e.target.value;

		this.setState({
			selectedParcels: tempArray
		});
	}

	/**modal screens */
	handleNextClick() {
		const { selectedParcels } = this.state;

		if (this.state.buttontextNext === 'Register') {
			let parcelsData = _.map(selectedParcels, (parcel) => {
				return {
					id: parcel.id,
					status: configHelper.ParcelStatuses.CheckedIn,
					dateArrived: moment().format(),
					locationId: parcel.location,
					barcode: parcel.barcode
				};
			});

			this.props.dispatch(
				parcelActions.registerExpected(parcelsData, (success) => {
					if (success === true) {
						this.setState({
							isParcelRegistered: true,
							screen: ParcelScreens.Result
						});
					} else {
						this.setState({
							error: true,
							message: 'Unexected error occured!'
						});
					}
				})
			);
		} else {
			if (
				!_.isUndefined(this.state.selectedParcels) &&
				this.state.currentIndex < this.state.selectedParcels.length - 1
			) {
				this.setState(
					{
						currentIndex: this.state.currentIndex + 1
					},
					function() {
						if (this.state.currentIndex === this.state.selectedParcels.length - 1) {
							this.setState({
								buttontextNext: 'Register'
							});
						}
						this.setState({
							buttontextBack: 'Back'
						});

						if (_.isUndefined(this.state.selectedOptions[this.state.currentIndex].value)) {
							for (let key in this.refs) {
								if (key.indexOf('locationRef') === 0) {
									this.setState({
										locationSelect: false
									});

									this.refs[key].reset();
								}
							}
						} else {
							this.setState({
								locationSelect: true
							});
						}
					}
				);
			}
		}
	}

	handleBackClick() {
		if (!_.isUndefined(this.state.selectedParcels) && this.state.currentIndex >= 0) {
			this.setState(
				{
					currentIndex: this.state.currentIndex - 1
				},
				function() {
					if (this.state.currentIndex === 0) {
						this.setState({
							buttontextBack: 'Cancel'
						});
					}
				}
			);
		}

		this.setState({
			buttontextNext: 'Next'
		});

		this.setState({
			locationSelect: true
		});
	}

	showRegiterScreen() {
		const { parcelLocations, moduleColor, handleCloseClick, expectedParcel } = this.props;
		let senderName = '';
		let totalParcels = 1;
		if (!_.isUndefined(this.state.selectedParcels) && this.state.selectedParcels.length > 0) {
			senderName = this.state.selectedParcels[this.state.currentIndex].name;
			totalParcels = this.state.selectedParcels.length;
		}

		let location_option = [];

		const selectedLocation = parcelLocations.length === 1 ? parcelLocations[0].id : '';

		_.map(parcelLocations, (location, rowIndex) => {
			let curruntLocation = { name: location.locationName, label: location.locationName, value: location.id };
			location_option.push(curruntLocation);
		});

		return (
			<div>
				<div className="tenant-name">
					<div className="text-box mb24">
						<InputComponent
							type="text"
							name={'tname'}
							label={'Tenant Name'}
							value={expectedParcel.tenant.firstName + ' ' + expectedParcel.tenant.lastName}
							disabled={true}
						/>
					</div>
					<div className="text-box">
						<InputComponent
							type="text"
							name={'addr'}
							value={
								this.state.portalType === PortalTypes.Commercial ? expectedParcel.tenant.companyName !=
								null ? expectedParcel.tenant.companyName.length > 30 ? (
									expectedParcel.tenant.companyName.substring(0, 30) + '...'
								) : (
									expectedParcel.tenant.companyName
								) : (
									''
								) : expectedParcel.resident.addressLine1 != null ? (
									helpers
										.formatStringList(
											[
												expectedParcel.resident.addressLine1,
												expectedParcel.resident.addressLine2
											],
											50
										)
										.toString()
								) : (
									''
								)
							}
							disabled={true}
						/>
					</div>
				</div>

				<div className="modal-sub-heading" style={{ color: moduleColor }}>
					{' '}
					Parcel {this.state.currentIndex + 1}/{totalParcels} -{' '}
					{(senderName != null ? senderName : '').substring(0, 17)}
				</div>
				<div className="content-section">
					<InputComponent
						type="text"
						label={'Parcel Barcode'}
						name="ParcelBarcode"
						placeHolder="Scan or enter barcode"
						className="text-box"
						value={ !_.isUndefined(this.state.selectedParcels[this.state.currentIndex]) &&
							this.state.selectedParcels[this.state.currentIndex].barcode !== null &&
							!_.isUndefined(this.state.selectedParcels[this.state.currentIndex].barcode) ? (
								this.state.selectedParcels[this.state.currentIndex].barcode
							) : (
								''
							)
						}
						handleChange={this.handleBarcodeChange}
					/>

					<InputComponent
						ref="locationRef"
						label={'Parcel Location*'}
						type="select"
						name="locationSelect"
						placeHolder="Select parcel location"
						className="text-box"
						options={location_option}
						handleChange={this.handleLocationChange}
						value={
							selectedLocation === '' ? !_.isUndefined(this.state.selectedOptions[this.state.currentIndex]) && !_.isUndefined(
								this.state.selectedOptions[this.state.currentIndex].value
							) ? (
								this.state.selectedOptions[this.state.currentIndex].value.toString()
							) : (
								undefined
							) : (
								selectedLocation
							)
						}
					/>
				</div>
			</div>
		);
	}

	showResultScreen() {
		return (
			<div className="checkedIn-parcel">
				<div className="modalParcelList">
					{_.map(this.state.selectedParcels, (selectedParcels, rowIndex) => {
						return _.find(this.props.registeredParcels, { id: selectedParcels.id }) ? (
							<div className="list-outer-box" key={rowIndex}>
								<span className="modal-checkedin-parcelList">
									{' '}
									Parcel {rowIndex + 1}/{this.state.selectedParcels.length} -{' '}
									{selectedParcels.name !== null ? selectedParcels.name : ''}
								</span>
								<span className="listCheckIcons ion-checkmark" />
							</div>
						) : (
							<div className="list-outer-box" key={rowIndex}>
								<span className="modal-unregistered-parcel">
									{' '}
									Parcel {rowIndex + 1}/{this.state.selectedParcels.length} -{' '}
									{selectedParcels.name !== null ? selectedParcels.name : ''}
								</span>
								<span className="listCheckIcons listCheckIcons-redbg ion-close-round" />
							</div>
						);
					})}
				</div>
			</div>
		);
	}

	render() {
		const {
			handleCloseClick,
			getApplicationNode,
			focus,
			moduleColor,
			// expectedParcel,

			parcelLocations
			// portalSettings
		} = this.props;

		let location_option = [];

		const selectedLocation = parcelLocations.length === 1 ? parcelLocations[0].id : '';
		_.map(parcelLocations, (location, rowIndex) => {
			let curruntLocation = { name: location.locationName, label: location.locationName, value: location.id };
			location_option.push(curruntLocation);
		});
	
		return (
			<form id="modalForm" className="modal-wrapper">
				<AriaModal
					titleText="Booking Details"
					onExit={handleCloseClick}
					underlayStyle={{ paddingTop: '8em' }}
					underlayClickExits={true}
					focusDialog={focus}
					mounted={this.state.modalActive}
					getApplicationNode={getApplicationNode}
					escapeExits={true}
				>
					<form
						id="form-content"
						ref="validate"
						onSubmit={(e) => {
							e.preventDefault();
						}}
						data-parsley-errors-container="#validation-messages"
						data-parsley-focus="none"
						data-parsley-validate-if-empty
					>
						<div className="modal-wrapper parcel-registration">
							<div className="modal-container">
								<div className="modal-top">
									<div className="icons">
										<span
											className="ion-close-round close_btn"
											onClick={() => handleCloseClick(true)}
										/>
									</div>
								</div>

								<div>
									<div className="modal-body">
										<div
											className={
												this.state.screen === ParcelScreens.Result ? (
													'modal-heading  model-heading-list'
												) : (
													'modal-heading'
												)
											}
											style={{ color: moduleColor }}
										>
											Parcel Registration
										</div>

										{this.state.screen === ParcelScreens.Register && this.showRegiterScreen()}

										{this.state.screen === ParcelScreens.Result && this.showResultScreen()}
									</div>
									{this.state.screen === ParcelScreens.Register && (
										<div className="modal-footer ">
											<Button
												text={this.state.buttontextBack}
												otherClass="cancel group"
												handleClick={() =>
													this.state.currentIndex === 0
														? handleCloseClick(true)
														: this.handleBackClick()}
											/>
											<Button
												text={this.state.buttontextNext}
												otherClass="next group"
												isDisabled={
													!this.state.locationSelect && selectedLocation === '' ? true : false
												}
												handleClick={this.handleNextClick}
											/>
										</div>
									)}
									{this.state.screen === ParcelScreens.Result && (
										<div className="modal-footer">
											<div className="unregistered">
												{this.state.statusFailure ? (
													<div className="unregisteredParcel unregisteredParcelMsg">
														It was not possible to register{' '}
														{this.state.selectedParcels.length -
															this.props.registeredParcels.length}{' '}
														{this.state.selectedParcels.length -
															this.props.registeredParcels.length >
														1 ? (
															'parcels.'
														) : (
															'parcel.'
														)}
														<br />{' '}
														<span
															className="link-style"
															onClick={() => {
																browserHistory.push('/concierge/parcel/register');
																window.scrollTo(0, 0);
															}}
														>
															Click here
														</span>{' '}
														to register it as an <span>Unexpected Parcel.</span>
													</div>
												) : (
													''
												)}
												<Button
													text={this.state.buttonFinish}
													otherClass="understandButton"
													size="xsmall"
													handleClick={() => {
														handleCloseClick(true);
														this.props.resetParcelsList &&
															this.props.resetParcelsList(this.props.registeredParcels);
													}}
												/>
											</div>
										</div>
									)}
								</div>

								{
									//     !this.state.isParcelRegistered ?
									//     ""
									// :
									// <div className="checkedIn-parcel">
									//     <div className="modal-body">
									//         <div className="model-heading" style={{ color: moduleColor }}>Parcel Registration</div>
									//     </div>
									// </div>
								}
							</div>
						</div>
					</form>
				</AriaModal>
			</form>
		);
	}
}

ParcelModal.propTypes = {
	/**Screen type value to specify current screen loaded */
	type: PropTypes.string,
	/**getting the module color */
	moduleColor: PropTypes.string
};

function mapStateToProps(state) {
	return {
		registeredParcels: parcelSelectors.getRegisteredParcels(state)
	};
}
export default connect(mapStateToProps, null, null, { forwardRef: true })(ParcelModal);
