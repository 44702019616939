
import React, { Component } from "react";
import _ from "lodash";

import "../../fonts/font-awesome/css/font-awesome.css";
import "../../sass/components/DotNav/index.css";

var PropTypes = require('prop-types');

class DotNav extends Component {



    render() {

        const { items, currentItem } = this.props;

        return (
            <div className="dot-navigation">
                <div className="navigation">
                    <ul className="dot-container">
                        {

                            _.map(items, (item, index) => {
                                let tag = item === currentItem ? "fa fa-circle" : "fa fa-circle-o";

                                return (<li className="dot-item" key={index}>
                                    <span className={tag}></span>
                                </li>);
                            })
                        }

                    </ul>

                </div>

            </div>
        );
    }

}


DotNav.propTypes = {

    /** Collection of strings in an array  */
    items: PropTypes.array,

    /** Indicates the active item in list */
    currentItem: PropTypes.string
};

export default DotNav;