//import _ from "lodash";
import * as types from "./actionTypes";
import Immutable from "seamless-immutable";

const initialState = Immutable({
  bbAuthToken: undefined,
  bbIsLoggedIn: false,
  companyId: undefined,
  companyName: undefined
});

export default function bbauthreduce(state = initialState, action = {}) {
  switch (action.type) {
    case types.LOGIN_COMPLETED:
      return state.merge({
        bbAuthToken: action.loginData.bbAuthToken,
        bbIsLoggedIn: true
      });
    case types.FETCH_COMPANY_ID_COMPLETED:
      return state.merge({
        companyId: action.companyId
      });
    default:
      return state;
  }
}

export function getAuthToken(state) {
  return state.bbauth.bbAuthToken;
}

export function getIsLoggedIn(state) {
  return state.bbauth.bbIsLoggedIn;
}

export function getCompanyId(state) {
  return state.bbauth.companyId;
}
