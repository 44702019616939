import React, { Component } from "react";
import { connect } from "react-redux";

//helper apps
import moment from "moment";
import _ from "lodash";
import autoBind from "react-autobind";
import dateFormat from "dateformat";
import {withRouter} from 'react-router-dom';
//component
import ServicesListMulti from "../../../components/ServiceList/ServiceListMulti";
import BookingCalendarCustom from "../../../components/BookingCalendar/BookingCalendarCustom";

//store
import * as resourceSelector from "../../../store/Resources/reducer";
import * as resourceActions from "../../../store/Resources/actions";
import * as pageEventActions from "../../../store/PageEvents/actions";
import * as checkoutSelector from "../../../store/Checkout/reducer";
import * as checkoutActions from "../../../store/Checkout/actions";

import * as tenantActions from "../../../store/Tenants/actions";

import "../../../sass/containers/rooms/index.css";

//const showSecond = false;


const calendarViews = {
    Day: "day",
    Week: "week",
    WorkWeek: "work_week",
    Month: "month"
};

function disabledMinutes(h) {
    switch (h) {
        default:
            return generateOptions(60, [0, 30]);
    }
}

function generateOptions(length, excludedOptions) {
    const arr = [];
    for (let value = 0; value < length; value++) {
        if (excludedOptions.indexOf(value) < 0) {
            arr.push(value);
        }
    }
    return arr;
}


class RoomsScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: "rooms",
            now: new Date(),
            startDate: moment(),
            endDate: moment().endOf("week"),
            serviceId: "",
            eventId: "",
            slots: [],
            options: [],
            timeSlot: 1,
            eventName: "",
            time_step: 1, //default value
            selectedServiceSlot: [],
            selectedEventsSlots: [],
            vision_type: "week",
            monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
            current_view: calendarViews.Week,
            selectedEventsSlot: [],
            selected_event_resources: []

        };

       

        this.props.dispatch(tenantActions.getAllTenants());
        this.props.dispatch(resourceActions.setRoomsServiceHr());
        this.props.dispatch(resourceActions.getServices());

        autoBind(this);
    }

    componentDidMount() {

        const { type } = this.state;

        this.props.dispatch(resourceActions.clearBasketQueue());

        this.props.dispatch(pageEventActions.setPageEvent(type));

        this.props.dispatch(checkoutActions.loadBasketFromCache());


    }

    componentDidUpdate(prevProps, prevState) {

        if (prevProps.serviceId !== this.props.serviceId && !_.isUndefined(this.props.serviceId)) {

            this.setState({
                serviceId: this.props.serviceId
            });
        }

        if (prevProps.selectedEventsSlots !== this.props.selectedEventsSlots) {
            this.setState({
                selectedEventsSlots: this.props.selectedEventsSlots
            });
        }
    }

    componentWillMount() {

        if (!_.isUndefined(this.props.service.bookingTimeStep)) {
            this.setState({
                time_step: this.props.service.bookingTimeStep
            });
        }

        if (!_.isUndefined(this.props.serviceId)) {
            this.servicesSlots(this.props.serviceId, this.state.startDate, this.state.current_view);
        }
    }
    componentWillUnmount() {
        this.props.dispatch(resourceActions.emptyServicesSlots());
    }
    componentWillUpdate(nextProps, nextState) {

        if (this.props.services != nextProps.services && nextProps.services.length > 0) {

            this.props.dispatch(
                resourceActions.fetchServiceDetail(this.state.serviceId),
            );

            this.servicesSlots(this.state.serviceId, this.state.startDate, this.state.current_view);

        }

        // if (
        //     this.state.serviceId !== nextState.serviceId &&
        //     !_.isUndefined(nextState.serviceId)
        // ) {
        //     this.props.dispatch(
        //         resourceActions.fetchServiceDetail(nextState.serviceId),
        //     );

        //     console.log(nextState.serviceId);

        //     this.servicesSlots(nextState.serviceId, nextState.startDate, this.state.current_view);

        // }

        //old daily slot list
        if (this.props.selectedServiceSlot !== nextProps.selectedServiceSlot) {
            this.setState({
                selectedServiceSlot: nextProps.selectedServiceSlot
            });
        }

        if (this.props.selectedEventsSlots !== nextProps.selectedEventsSlots) {
            this.setState({
                selectedEventsSlots: nextProps.selectedEventsSlots
            });
        }

        if (this.state.startDate !== nextState.startDate) {

            this.setState({
                startDate: nextState.startDate,
                endDate: nextState.endDate
            });


            this.servicesSlots(this.state.serviceId, nextState.startDate, this.state.current_view);

            this.loadEventSlots(this.state.selected_event_resources, nextState.startDate, nextState.endDate);
        }

        if (this.state.current_view !== nextState.current_view) {
            this.setState({
                current_view: nextState.current_view
            });
            this.servicesSlots(this.state.serviceId, this.state.startDate, nextState.current_view);
            this.loadEventSlots(this.state.selected_event_resources, this.state.startDate, this.state.endDate);
        }



    }

    componentDidUpdate(prevProps, prevState) {

        if (!_.isUndefined(this.props.service) &&
            !_.isUndefined(this.props.service.bookingTimeStep) &&
            this.props.service.bookingTimeStep !== prevProps.service.bookingTimeStep
        ) {
            this.setState({ time_step: this.props.service.bookingTimeStep });
        }

        if (prevProps.service !== this.props.service && !_.isUndefined(this.props.service)) {
            if (!_.isUndefined(this.props.service.detailGroupId))
                this.loadBookingQuestions(this.props.service.detailGroupId);

            this.props.dispatch(resourceActions.setServiceUnit(this.props.service.durationUnit));
        }

    }
    loadBookingQuestions(detail_group_id) {
        this.props.dispatch(resourceActions.fetchBookingQuestions(detail_group_id));
    }

    servicesSlots(serviceId, date, view) {


        if (view === calendarViews.Week) {
            let start_date = dateFormat(moment(date.startOf("week")._d, "isoDate"));
            let end_date = dateFormat(moment(date.endOf("week")._d, "isoDate"));

            this.props.dispatch(
                resourceActions.fetchServicesSlots(
                    serviceId,
                    start_date, end_date, success => {
                        if (success === true) {
                            this.setState({
                                request_fail: undefined
                            });
                        } else {

                            this.setState({
                                request_fail: true
                            });
                        }

                    }
                )
            );
        } else if (view === calendarViews.Day) {
            this.props.dispatch(
                resourceActions.fetchServicesSlots(
                    serviceId,
                    date._d, date._d, success => {
                        if (success === true) {
                            this.setState({
                                request_fail: undefined
                            });
                        } else {

                            this.setState({
                                request_fail: true
                            });
                        }

                    }
                )
            );
        }

    }

    selectedServiceSlots(eventId, date, serviceId, eventName) {

        // this.props.dispatch(
        //     resourceActions.getSelectedEventSlot(
        //         eventId,
        //         new Date(date._d),
        //         serviceId,
        //         eventName, success => {
        //             if (success === true) {
        //                 this.setState({
        //                     request_fail: undefined
        //                 });
        //             } else {

        //                 this.setState({
        //                     request_fail: true
        //                 });
        //             }
        //         }
        //     )
        // );


    }

    availaibleTimeData(serviceId, date, eventId) {

        this.props.dispatch(
            resourceActions.getServiceAvailability(serviceId, date, eventId)
        );
    }
    handleDateChange(date) {

        const { eventId, serviceId, startDate, eventName, current_view } = this.state;

        this.setState({
            startDate: date
        });

        this.servicesSlots(this.props.serviceId, date, current_view);
        if (eventId) {
            this.selectedServiceSlots(eventId, date, this.props.serviceId, eventName);
        }
    }

    handleSelectChange(val) {


        const { servicesSlots } = this.props;
        const { serviceId, startDate, endDate } = this.state;

        let options = _.map(servicesSlots, item => {
            return { eventName: item.name, eventId: item.event_id };
        });
        let selected_event_resource = [];

        //reform selected resource to usable object values
        const temp = _.map(val, o => {
            let curre_event = _.find(options, { eventId: o });
            if (!_.isUndefined(curre_event)) {
                selected_event_resource.push(curre_event);
            }
            return o;
        });


        this.setState({
            selected_event_resources: selected_event_resource
        });

        //fetch resources based on selected event resource
        this.loadEventSlots(selected_event_resource, startDate, endDate);


        // if (!_.isUndefined(evtName)) {

        //     this.setState({
        //         eventId: val.target.value,
        //         eventName: evtName.name
        //     });

        //     this.selectedServiceSlots(
        //         val.target.value,
        //         startDate,
        //         this.props.serviceId,
        //         evtName.name
        //     );

        //     this.availaibleTimeData(
        //         this.props.serviceId,
        //         dateFormat(startDate._d, "isoDate"),
        //         val.target.value
        //     );
        // }

       

    }

    handleRightClick(e) {

        this.props.history.push("/reception/tenant");
    }
    handleBackClick(e) {

        this.props.history.push("/");
    }
    onLogin() {

        this.props.navigationController.pushView(
            <div>Today</div>
        );
    }

    modalActionTrigered(action, valid) {


        if (!_.isUndefined(this.props.serviceId)) {

            this.serviceBookings(this.props.serviceId, dateFormat(this.state.startDate._d, "isoDate"));
            this.servicesSlots(this.props.serviceId, this.state.startDate, this.state.current_view);

            if (this.state.selected_event_resources && this.state.selected_event_resources.length > 0)
                this.loadEventSlots(this.state.selected_event_resources, this.state.startDate, this.state.endDate);
        }
        // setTimeout(() => {
        //     if (valid === true) {
        //          this.serviceBookings(
        //             this.props.serviceId,
        //             dateFormat(this.state.startDate._d, "isoDate")
        //         );
        //         this.servicesSlots(this.props.serviceId, this.state.startDate, this.state.current_view);

        //         setTimeout(() => { }, 0);
        //         // if (this.state.eventId) {
        //         //     this.selectedServiceSlots(
        //         //         this.state.eventId,
        //         //         this.state.startDate,
        //         //         this.props.serviceId,
        //         //         this.state.eventName
        //         //     );
        //         // }

        //         if (this.state.selected_event_resources && this.state.selected_event_resources.length > 0)
        //             this.loadEventSlots(this.state.selected_event_resources, this.state.startDate, this.state.endDate);
        //     }
        // }, 0);
    }
    serviceBookings(serviceid, date) {

        const { current_view } = this.state;

        let startDate = moment(date);
        let endDate = moment(date);


        if (current_view === calendarViews.Day) {
            startDate = moment(date).startOf("day");
            endDate = moment(date).endOf("day");
        } else if (current_view === calendarViews.Week) {
            startDate = moment(date).startOf("week");
            endDate = moment(date).endOf("week");

        }

        this.props.dispatch(resourceActions.getServiceBookings_range(serviceid, startDate.format("YYYY-MM-DD"), endDate.format("YYYY-MM-DD"), success => {
            if (success === true) {
                this.setState({
                    request_fail: undefined
                });
            } else {
                this.setState({
                    request_fail: true
                });
            }
        }));

    }
    switchTypeHandler = (type) => {

        switch (type) {
            case "week":
                this.setState({
                    startDate: moment().startOf("week"),
                    endDate: moment().endOf("week"),
                    // current_view: type
                });

                break;
            case "work_week":
                this.setState({
                    startDate: moment().startOf("week"),
                    endDate: moment().endOf("week"),
                    // current_view: type
                });

                break;
            case "day":
                this.setState({
                    startDate: moment(),
                    endDate: moment(),
                    // current_view: type
                });
                break;
            default:
                break;
        }
        this.state.current_view = type;

        // this.setState({
        //   current_view: type
        // })

    }

    loadServicesOption(options) {
        const { eventId } = this.state;

        return (
            <div className="options_select">
                <div className="time_options">
                    {options && (
                        <ServicesListMulti
                            services={options}
                            handleSelectionChange={e => this.handleSelectChange(e)}
                            selectedOption={eventId}
                        />
                    )}
                </div>
            </div>
        );
    }

    //helper method to call slot data on selected events
    loadEventSlots(events, startDate, endDate) {

        const { current_view,
        } = this.state;

        const { serviceId } = this.props;

        if (current_view === calendarViews.Week) {

            this.props.dispatch(resourceActions.getSelectedEventsSlots(events, new Date(startDate.startOf("week")._d),
                new Date(endDate.endOf("week")._d), serviceId, success => {
                    if (success === true) {
                        this.setState({
                            request_fail: undefined
                        });
                    } else {

                        this.setState({
                            request_fail: true
                        });
                    }
                }));

        } else if (current_view === calendarViews.Day) {

            this.props.dispatch(resourceActions.getSelectedEventsSlots(events, new Date(startDate._d),
                new Date(startDate._d), serviceId, success => {
                    if (success === true) {
                        this.setState({
                            request_fail: undefined
                        });
                    } else {
                        this.setState({
                            request_fail: true
                        });
                    }
                }));
        }

    }


    //keep for carlendar navigation
    handleNavigation(date, view) {

        switch (view) {
            case "day":
                this.setState({
                    startDate: moment(date).startOf("day"),
                    endDate: moment(date).endOf("day")
                });

                //this.servicesSlots(this.state.serviceId, moment(date), view);

                // this.loadEventSlots(this.state.selected_event_resources,
                //     moment(date),
                //     moment(date));
                return date;
            case "work_week":

                this.setState({
                    startDate: moment(date).startOf("week"),
                    endDate: moment(date).endOf("week")
                });

                // this.selectedServiceSlots(this.state.serviceId, moment(date), view);
                // this.loadEventSlots(this.state.selected_event_resources,
                //     new Date(moment(date).startOf("week")._d),
                //     new Date(moment(date).endOf("week"))._d);
                return date;
            case "week":

                this.setState({
                    startDate: moment(date).startOf("week"),
                    endDate: moment(date).endOf("week")
                });

                // this.loadEventSlots(this.state.selected_event_resources,
                //   new Date(moment(date).startOf("week")._d),
                //   new Date(moment(date).endOf("week"))._d);
                return date;
            default:
                return date;
        }
    }


    render() {
        const {
            query,
            servicesSlots,
            service,
            basketQueue,
            bookingQuestions
        } = this.props;

        const {
            startDate,
            eventId,
            eventName,
            type,
            time_step,
            endDate
        } = this.state;

        let service_options = []
        let options = _.filter(_.map(servicesSlots, item => {

            if (_.isUndefined(_.find(service_options, { eventid: item.event_id }))) {
                service_options.push({ name: item.name, eventid: item.event_id });
                return { name: item.name, eventid: item.event_id };
            } else {
                return undefined;
            }

        }), o => !_.isUndefined(o));


        return (
            <div className={"container content-wrapper type-" + this.state.type}>
                <div className="BookingCalendar">

                    {
                        service &&
                            this.state.selectedEventsSlots && this.state.current_view && !_.isUndefined(service.bookingTimeStep) ?
                            <BookingCalendarCustom
                                slots={this.props.selectedEventsSlots}
                                serviceDetail={service}
                                serviceId={query.roomsServiceHrId}
                                eventId={eventId}
                                eventName={eventName}
                                step={time_step}
                                date={startDate}
                                endDate={endDate}
                                current_view={this.state.current_view}
                                blockSelected={() => this.props.blockSlotSelected}
                                modalActionEvent={this.modalActionTrigered}
                                switchTypeHandler={this.switchTypeHandler}
                                servicesOptions={this.loadServicesOption(options)}
                                handleNavigation={this.handleNavigation}
                                requestStatus={this.state.request_fail}
                                selectedEventResources={this.state.selected_event_resources}
                                bookingQuestions={bookingQuestions}
                            /> : <div> <div className="progress-spinner"> <i className={"fa fa-spinner fa-spin resource-arrow "} style={{ fontSize: 24 + "px" }}></i></div> </div>
                    }
                </div>
                <div className="align-center double-margin-top">

                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        query: resourceSelector.getResourceQuery(state),
        servicesSlots: resourceSelector.getServicesSlots(state),
        selectedServiceSlot: resourceSelector.getSelectedServiceSlot(state),
        service: resourceSelector.getService(state),
        basket: checkoutSelector.getBasket(state),
        basketQueue: resourceSelector.getBasketQueue(state),
        basket: checkoutSelector.getBasket(state),
        blockSlotSelected: resourceSelector.getBlockSlotSelected(state),
        serviceId: resourceSelector.getServiceId(state),
        selectedEventsSlots: resourceSelector.getSelectedEventsSlots(state),
        bookingQuestions: resourceSelector.getBookingQuestions(state),
        services: resourceSelector.getServices(state)

    };
}

export default withRouter(connect(mapStateToProps)(RoomsScreen));