import React, { Component } from 'react';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';
import moment from 'moment';
import dateFormat from 'dateformat';
import Datepicker from 'react-datepicker';
import {withRouter} from 'react-router-dom';
//import CheckinVisitorForm from "../../component/CheckinVisitorForm/CheckinVisitorForm";
import * as meetingSelector from '../../../store/Meetings/reducer';
import * as visitorsAction from '../../../store/Visitors/actions';
import * as moduleSelectors from '../../../store/ServiceModules/reducer';
import * as meetingsActions from '../../../store/Meetings/actions';
import * as meetingActions from '../../../store/Meetings/actions';
import * as visitorActions from '../../../store/Visitors/actions';
import * as residentAction from '../../../store/Residents/actions';
import * as residentSelectors from '../../../store/Residents/reducer';
import Button from '../../../components/Button/Button';
import InputComponent from '../../../components/Input/Input';
import VisitorAutoSuggest from '../../../components/VisitorAutoSuggest/VisitorAutoSuggestComponent';
import _ from 'lodash';
import '../../../sass/containers/visitors/index.css';
// import VisitorLogCard from "../../../components/visitorLogCard/visitorLogCard";
import VisitorSearchResult from '../../../components/visitorLogCard/visitorSearchResult';
import * as $ from 'jquery';
import ResponseMessage from '../../../components/ResponseMessage/ResponseMessage';
import VisitorsExpected from '../../../components/VisitorsExpected/VisitorsExpected';
import FeatureArea from '../../../components/FeatureArea/FeatureArea';
import PastVisitors from '../../../components/PastVisitors/PastVisitors';

//Log time Hrs
const TimeLogHelper = {
	MorningLogTime: 11,
	NoonLogTime: 12,
	NoonLogTimeTo: 13,
	EveningLogTime: 23
};
const VisitorStatuses = {
	CheckedIn: 'checkedin',
	CheckedOut: 'checkedout',
	Notified: 'notified'
};

class CheckinVisitorScreen extends Component {
	constructor(props) {
		super(props);

		this.state = {
			value: '',
			name: '',
			visitorName: '',
			startDate: moment(),
			visitorCompany: '',
			disabled: 'disabled',
			rows: [ 0 ],
			type: 'visitors',
			formSubmitted: false,
			now: moment(),
			status: visitorActions.getVisitorStauses(),
			found: true,
			morningLog: [],
			noonLog: [],
			afterNoonLog: [],
			isPastVisitorEnable: false
		};
		this.addVisitorValidation = null;
		autoBind(this);
	}

	componentDidMount() {
		// const { now } = this.state;
		// const { startDate } = this.state;

		// const { startDate } = this.state;

		this.addValidation();
		this.props.dispatch(visitorsAction.cancelMeetingLoad());
	}
	UNSAFE_componentWillMount() {
		const { now } = this.state;

		this.props.dispatch(meetingActions.getMeetingsByDate(moment(now).format('YYYY-MM-DD')));
		this.props.dispatch(meetingsActions.getFilterMeetingsByDate(dateFormat(now, 'isoDate')));

		this.props.dispatch(residentAction.getAllResidents());

		// this.props.dispatch(
		//   meetingsActions.getMeetingsByDate(dateFormat(startDate, "isoDate"))
		// );
	}

	componentWillUnmount() {
		this.addVisitorValidation.destroy();
		this.props.dispatch(visitorActions.resetVisitorCheckedIn());
		this.formReset();
	}

	UNSAFE_componentWillReceiveProps(nextProps, nextState) {
		if (this.props.meetings !== nextProps.meetings) {
			//visitor status changed on action, set new data on action completed
			if (!_.isUndefined(this.state.selectedMeeting) && nextProps.meetings.length > 0) {
				var selectedMeetingUpdated = _.find(nextProps.meetings, { id: this.state.selectedMeeting.id });

				if (selectedMeetingUpdated) {
					this.setState({
						selectedMeeting: selectedMeetingUpdated
					});
				}
			}

			//visitor list haschange due to action on visitor result cards, update with ne data
			if (
				!_.isUndefined(this.state.visitorMeetingOptions) &&
				this.state.visitorMeetingOptions.length > 0 &&
				nextProps.meetings.length > 0
			) {
				var visMeetingsUpdated = _.map(this.state.visitorMeetingOptions, (val) => {
					let newObj = _.find(nextProps.meetings, { id: val.id });
					if (newObj && newObj.id === val.id) {
						return newObj;
					}

					return val;
				});

				this.setState({
					visitorMeetingOptions: _.filter(visMeetingsUpdated, (o) => !_.isUndefined(o))
				});
			}
		}
	}
	UNSAFE_componentWillUpdate(nextProps, nextState) {
		if (this.props.meetingsFilter !== nextProps.meetingsFilter) {
			this.setMeetingLog(nextProps.meetingsFilter);
		}

		//fix for visitor found result return from visitor autosuggest
		//this is due to input field holding more values than expected for match condition in th component implementation
		if (this.state.visitorFound !== nextState.visitorFound && _.isUndefined(nextState.visitorFound)) {
			//if (this.state.selectedMeeting || (this.state.visitorMeetingOptions && this.state.visitorMeetingOptions.length > 0)) { }
			// this.setState({
			//   selectedMeeting: undefined,
			//   visitorMeetingOptions: []
			// })
		}
	}

	addValidation() {
		this.addVisitorValidation = $(this.refs.validate).parsley({
			classHandler: function(el) {
				return el.$element.closest('div:not(.input-wrapper):not(.Select)');
			}
		});
	}

	validate(fromSubmit = false) {
		this.addVisitorValidation.validate({ force: true });

		const valid = this.addVisitorValidation.isValid();

		this.setState({
			addVisitorFormValid: valid,
			addVisitorFormUsed: true,
			rows: this.state.rows.concat([ this.state.rows.length ])
		});

		if (valid === true && fromSubmit === true) {
			//this.handleVisitorSearch();
			this.formReset();

			this.setState({
				addVisitorFormUsed: false,
				formSubmitted: true
			});
		}
	}

	formReset() {
		this.setState({
			name: '',
			visitorCompany: '',
			selecetMeetingId: '',
			selectedMeeting: undefined
		});
	}

	handleBookVisitorsClick(e) {
	
		this.props.history.push('/concierge/visitor-booking');
		window.scrollTo(0, 0);
	}

	handleVisitorSearch(e) {
		const { visitorCompany, name } = this.state;
		var now = new Date();

		//if (e.charCode == 13) {}
		const data = {
			status: 'notified',
			companyName: visitorCompany,
			dueDate: dateFormat(now, 'isoDate'),
			name: name
		};

		this.props.dispatch(
			visitorActions.searchVisitor(data, (success) => {
				if (success === true) {
					this.props.dispatch(visitorActions.getVisitorSearchInput(data));
					this.props.history.push({
						pathname: '/concierge/visitor-checkin-review',
						state: { visitorData: data }
					});
				} else {
					this.setState({
						found: false
					});
					setTimeout(() => {
						this.props.history.push({
							pathname: '/concierge/visitor-management',
							state: { visitorData: data }
						});
					}, 2000);
				}
			})
		);
	}
	handleInputChange(e) {
		const field = e.target.name;
		const value = e.target.value;
		this.setState({
			[field]: value
		});
	}

	//handle selected visitor / meeting from auto suggest
	handleSelectedVisitor(data) {
		this.setState({
			selecetMeetingId: data.meetingId,
			visitorId: data.visitorId,
			visitorCompany: data.companyName,
			name: data.visitor,
			selectedMeeting: _.find(this.props.meetings, { id: data.meetingId }),
			//visitorFound: data ? true : false,
			visitorMeetingOptions: undefined
		});

		setTimeout(() => document.activeElement.blur());
	}

	getAddress(memberId) {
		const { residents } = this.props;
		var resident = _.find(residents, { id: memberId });
		if (_.isUndefined(resident)) return '';

		
		return `${resident.addressLine1 == null ? '':resident.addressLine1}${resident.addressLine2 == null ? '': (resident.addressLine1 == null ? '':', ') + resident.addressLine2}${resident.postCode == null ? '': ( resident.addressLine1 == null && resident.addressLine2 == null ? '':', ') + resident.postCode}`;
	}

	handleSumbit(val) {
		const { meetings } = this.props;
		let selectedMeeting = _.filter(meetings, (meeting) => meeting.id === val);
		this.props.dispatch(
			visitorActions.checkinVisitor(selectedMeeting, (success) => {
				if (success === true) {
					this.props.dispatch(meetingsActions.getMeetingsByDate(dateFormat(this.state.startDate, 'isoDate')));
					this.props.dispatch(
						meetingActions.notifyCheckedIn(selectedMeeting, (status) => {
							if (status === true) {
							}
						})
					);
				}
			})
		);
	}

	handleDateChange(date) {
		// this.setState({
		//   startDate: date,
		//   isPastVisitorEnable:true
		// });
		// if (moment(date).format("YYYY-MM-DD") !== moment(new Date()).format("YYYY-MM-DD")) {

		if (moment(date).format('YYYY-MM-DD') < moment(new Date()).format('YYYY-MM-DD')) {
			this.setState({
				startDate: date,
				isPastVisitorEnable: true
			});
		} else {
			this.setState({
				startDate: date,
				isPastVisitorEnable: false
			});
		}

		this.props.dispatch(meetingsActions.getFilterMeetingsByDate(dateFormat(date, 'isoDate')));

		setTimeout(() => document.activeElement.blur());
		// this.props.dispatch(
		//   meetingsActions.getMeetingsByDate(dateFormat(date, "isoDate"))
		// );
	}

	//handle enter key used on visitor auto suggest
	handleAutoKeyPress(e, data) {
		if (e.Key === 'Enter') {
			console.log('enter key used: ', data);
		}
	}

	setMeetingLog(meetings) {
		var morningLog = [];
		var noonLog = [];
		var afterNoonLog = [];
		var eveningLog = [];

		// if (meetings && meetings.length > 0) {}

		morningLog = _.filter(meetings, (meeting) => {
			let mins = new Date(meeting.start).getUTCHours() * 60 + new Date(meeting.start).getUTCMinutes();

			//convert to mins
			let mrn = TimeLogHelper.MorningLogTime * 60 + 59;
			return mins <= mrn;
		});

		noonLog = _.filter(meetings, (meeting) => {
			let mins = new Date(meeting.start).getUTCHours() * 60 + new Date(meeting.start).getUTCMinutes();

			//convert to mins
			let mrn = TimeLogHelper.MorningLogTime * 60 + 59;
			let noon = TimeLogHelper.NoonLogTime * 60 + 59;
			let noonTo = TimeLogHelper.NoonLogTimeTo * 60 + 59;
			return mins > mrn && mins <= noonTo;
		});

		afterNoonLog = _.filter(meetings, (meeting) => {
			let mins = new Date(meeting.start).getUTCHours() * 60 + new Date(meeting.start).getUTCMinutes();

			//convert to mins
			let noon = TimeLogHelper.NoonLogTimeTo * 60 + 59;
			let aftnoon = TimeLogHelper.EveningLogTime * 60 + 59;

			return mins > noon && mins <= aftnoon;
		});

		this.setState({
			morningLog: morningLog,
			noonLog: noonLog,
			afterNoonLog: afterNoonLog
		});
	}
	formatStatusString(status) {
		let val;
		switch (status) {
			case VisitorStatuses.CheckedIn:
				val = 'Checked-in';
				break;
			case VisitorStatuses.CheckedOut:
				val = 'Checked-out';
				break;
			case VisitorStatuses.Notified:
				val = 'Expected';
				break;
			default:
				val = '';
				break;
		}

		return val;
	}

	NavigateToVisitorManagement() {
		this.props.dispatch(visitorActions.visitorSearchRedirected(undefined));
		
		this.props.history.push({
			pathname: '/concierge/visitor-management',
			state: { startDate: this.state.startDate.toDate() }
		});

		window.scrollTo(0, 0);
	}

	GotoProfile(meetingId, data) {
		this.props.history.push({
			pathname: '/concierge/visitor-profile',
			state: {
				meetingId: meetingId,
				meeting: data
			}
		});
		window.scrollTo(0, 0);
	}
	render() {
		const { disabled, formSubmitted, visitorCompany, status, visitorName, startDate } = this.state;
		const { meetings } = this.props;

		var renderDate = new Date();
		let visitors = [];
		let meeting = [];
		if (this.props.meetings && this.props.meetings !== null && this.props.meetings.length > 0) {
			meeting = _.filter(this.props.meetings, (o) => o.visitor && o.visitor.status === status.notified);
			visitors = _.map(meeting, (m) => {
				return m.visitor;
			});
		}
		const { tempLog } = this.state;

		const early = _.sortBy(meeting, [ 'meeting', 'time' ], [ 'asc', 'asc' ]);

		const { modules } = this.props;
		const currentModule = _.find(modules, (o) => o.title === 'Visitors');

		const overlayColor = currentModule && currentModule.imageOverlayTextColour;
		const h1_style = {
			color: overlayColor
		};

		//to be removed on later confirmation ERP-2982
		let searchMeetings = [];
		
		if (meetings && meetings.length > 0) {
			let cleanMeetingsList  = _.filter(
				meetings,
				(meeting) => meeting.visitor !== null
			);

			
			searchMeetings = _.filter(
				cleanMeetingsList,
				(meeting) => meeting && meeting.visitor.status === 'notified' || meeting.visitor.status === 'checkedin'
			);
		}

		let expectedVisitorsMorning = _.filter(this.state.morningLog, (log) => log.visitor !== null && log.visitor.status === 'notified');
		let expectedVisitorsNoon = _.filter(this.state.noonLog, (log) => log.visitor !== null && log.visitor.status === 'notified');
		let expectedVisitorsAfterNoon = _.filter(this.state.afterNoonLog, (log) =>log.visitor !== null && log.visitor.status === 'notified');
		let checkinVisitorsMorning = _.filter(this.state.morningLog, (log) =>log.visitor !== null && log.visitor.status === 'checkedin');
		let checkinVisitorsNoon = _.filter(this.state.noonLog, (log) => log.visitor !== null &&log.visitor.status === 'checkedin');
		let checkinVisitorsAfterNoon = _.filter(this.state.afterNoonLog, (log) =>log.visitor !== null && log.visitor.status === 'checkedin');
		let checkoutVisitorsMorning = _.filter(this.state.morningLog, (log) =>log.visitor !== null && log.visitor.status === 'checkedout');
		let checkoutVisitorsNoon = _.filter(this.state.noonLog, (log) =>log.visitor !== null && log.visitor.status === 'checkedout');
		let checkoutVisitorsAfterNoon = _.filter(this.state.afterNoonLog, (log) =>log.visitor !== null && log.visitor.status === 'checkedout');
		let noShowVisitors = _.filter(
			this.props.meetingsFilter,
			(val) => val.visitor !== null && val.visitor.status === VisitorStatuses.Notified
		);

		return (
			<div>
				<div className="container">
					<form
						id="checkin"
						className="contentwrapper"
						ref="validate"
						onSubmit={(e) => {
							e.preventDefault();
						}}
						data-parsley-errors-container="#validation-messages"
					>
						<div className="checkin visitor-search-section">
							<div className="inputs">
								<VisitorAutoSuggest
									ref="visitor-auto-suggest"
									onSelected={this.handleSelectedVisitor}
									visitors={visitors}
									clearableIfValid={false}
									value={_.isEmpty(visitorName)}
									label="Search for Visitor Name or Company" //"Search for Visitor Name or Company" //"Enter Visitor Full Name"
									placeHolder="Enter visitor name or company name"
									meetings={_.orderBy(searchMeetings, [ 'date', 'visitor.firstName' ], [ 'asc' ])}
									matchfound={(val) => {
										this.setState({
											visitorFound: this.state.selectedMeeting == undefined ? val : true
										});
									}}
									handleKeyPress={(e, data) => {
										if (e.key === 'Enter') {
											if (!_.isUndefined(this.state.selectedMeeting)) {
												this.setState({
													visitorMeetingOptions: [ this.state.selectedMeeting ],
													selectedMeeting: undefined
												});
											} else {
												this.setState({
													visitorMeetingOptions: data
												});
											}

											setTimeout(() => document.activeElement.blur());
										}
										if (e.key === 'Clear') {
											this.setState({
												visitorMeetingOptions: data,
												visitorFound: undefined
											});
										}
									}}
									fieldManualCleared={(o) => {
										if (o === true) {
											this.setState({
												selectedMeeting: undefined,
												visitorMeetingOptions: []
											});
										}
									}}
								/>

								{!_.isUndefined(this.state.selectedMeeting) &&
								(this.state.visitorFound === true || _.isUndefined(this.state.visitorFound)) &&
								(_.isUndefined(this.state.visitorMeetingOptions) ||
									this.state.visitorMeetingOptions.length === 0) ? (
									<VisitorSearchResult
										id={this.state.selectedMeeting.id}
										name={`${this.state.selectedMeeting.visitor.firstName} ${this.state
											.selectedMeeting.visitor.surname}`}
										company={this.state.selectedMeeting.visitor.companyName}
										meetingId={this.state.selectedMeeting.visitor.meetingId}
										dateExpected={dateFormat(this.state.selectedMeeting.date, 'dd/mm/yyyy')}
										status={this.state.selectedMeeting.visitor.status}
										handleSubmit={(val) => this.handleSumbit(val)}
										tenant={
											this.state.selectedMeeting.tenant.firstName +
											' ' +
											this.state.selectedMeeting.tenant.lastName
										}
										tenantAddress={this.getAddress(this.state.selectedMeeting.tenant.id)}
										companyName={this.state.selectedMeeting.tenant.companyName}
										specialInstructions={this.state.selectedMeeting.specialInstructions}
										dueDate={moment
											.utc(this.state.selectedMeeting.visitor.dueDate)
											.format('HH:mm A')}
										isDisabled={
											moment(this.state.selectedMeeting.date).utc().format('YYYY-MM-DD') !==
											moment(this.state.today).utc().format('YYYY-MM-DD')
										}
										statusLabel={this.formatStatusString(this.state.selectedMeeting.visitor.status)}
										timeExpected={
											this.state.selectedMeeting.visitor.status === VisitorStatuses.Notified ? (
												moment.utc(this.state.selectedMeeting.start).format('HH:mm')
											) : this.state.selectedMeeting.visitor.status ===
											VisitorStatuses.CheckedIn ? (
												moment.utc(this.state.selectedMeeting.visitor.dueDate).format('HH:mm')
											) : this.state.selectedMeeting.visitor.status ===
											VisitorStatuses.CheckedOut ? (
												moment
													.utc(this.state.selectedMeeting.visitor.checkedOutDate)
													.format('HH:mm')
											) : (
												moment.utc(this.state.selectedMeeting.date).format('HH:mm')
											)
										}
										data={this.state.selectedMeeting}
										gotoProfile={this.GotoProfile}
									/>
								) : (
									(_.isUndefined(this.state.selectedMeeting) ||
										!_.isUndefined(this.state.selectedMeeting)) &&
									(this.state.visitorFound || _.isUndefined(this.state.visitorFound)) &&
									!_.isUndefined(this.state.visitorMeetingOptions) &&
									this.state.visitorMeetingOptions.length > 0 &&
									_.map(this.state.visitorMeetingOptions, (meeting, index) => {
										return (
											<VisitorSearchResult
												key={index}
												id={meeting.id}
												name={`${meeting.visitor.firstName} ${meeting.visitor.surname}`}
												company={meeting.visitor.companyName}
												meetingId={meeting.id}
												dateExpected={dateFormat(meeting.date, 'dd/mm/yyyy')}
												status={meeting.visitor.status}
												handleSubmit={(val) => this.handleSumbit(val)}
												tenant={meeting.tenant.firstName + ' ' + meeting.tenant.lastName}
												tenantAddress={this.getAddress(meeting.tenant.id)}
												companyName={meeting.tenant.companyName}
												specialInstructions={meeting.specialInstructions}
												dueDate={moment.utc(meeting.visitor.dueDate).format('HH:mm A')}
												isDisabled={
													dateFormat(meeting.date, 'isoDate') !==
													dateFormat(this.state.today, 'isoDate')
												}
												statusLabel={this.formatStatusString(meeting.visitor.status)}
												timeExpected={
													meeting.visitor.status === VisitorStatuses.Notified ? (
														moment.utc(meeting.start).format('HH:mm')
													) : meeting.visitor.status === VisitorStatuses.CheckedIn ? (
														moment.utc(meeting.visitor.dueDate).format('HH:mm')
													) : meeting.visitor.status === VisitorStatuses.CheckedOut ? (
														moment.utc(meeting.visitor.checkedOutDate).format('HH:mm')
													) : (
														moment.utc(meeting.date).format('HH:mm')
													)
												}
												data={meeting}
												gotoProfile={this.GotoProfile}
											/>
										);
									})
								)}
							</div>
							<div
								className={
									'validation-messages' + (this.state.addVisitorFormValid ? ' valid' : ' invalid')
								}
								id="validation-messages"
							>
								{this.state.addVisitorFormValid && (
									<div>
										{/* <p className="message type-success">Sending Parcel To Server...</p> */}
									</div>
								)}
								{
									//this.state.found === true ? "" :
									//<p className="message error">This visitor has not been found, you will now be redirected to visitor check-in</p>
								}
							</div>
						</div>
					</form>
					<div className="vmContain ">
						{
							<ResponseMessage
								text={
									this.state.visitorFound === false ? (
										'No results found. You can book future visitors or check-in unexpected ones by clicking below'
									) : (
										'You can book future visitors or check-in unexpected ones by clicking below'
									)
								}
								id="no_results"
								className={this.state.visitorFound === false ? 'No-results' : 'future-text'}
								type={this.state.visitorFound === false ? 'error' : ''}
							/>
						}
						{
							// _.isUndefined(this.state.visitorFound) &&
							// <div className="future-text"><strong>You can book future visitors or check-in unexpected ones by clicking below</strong></div>
						}

						{/* <h1 style={h1_style}>Visitor Management</h1> */}
						<div className="btnGroup">
							<Button
								size="small"
								text="Book Visitors"
								handleClick={this.handleBookVisitorsClick}
								icon=""
								disabled={disabled}
							/>
							<Button
								size="small"
								text="Unexpected Visitor"
								handleClick={() => {
									this.props.history.push('/concierge/visitor-express-checkin');
									window.scrollTo(0, 0);
								}}
								icon=""
							/>
						</div>
					</div>

					<div className="visitor-expected-heading">
						<h2 style={{ color: overlayColor }}> Visitors Expected</h2>
					</div>

					<div className="container type-visitors log">
						<div className="date_select">
							<label>Select Date</label>
							<Datepicker
								selected={startDate}
								dateFormat="DD/MM/YYYY"
								locale="en-gb"
								onChange={this.handleDateChange}
								fixedHeight={true}
								// minDate={moment()}
							/>
						</div>
					</div>

					{this.state.isPastVisitorEnable ? (
						<div className="past-visitor-section">
							<PastVisitors
								textDataColor={overlayColor}
								dayPeriodName={'Morning'.toUpperCase()}
								expectedVisitors={expectedVisitorsMorning.length}
								checkedinVisitors={checkinVisitorsMorning.length}
								notshowvisitors={expectedVisitorsMorning.length}
								timePeriod="7am-Midday"
								handleLinkSelected={this.NavigateToVisitorManagement}
							/>
							<PastVisitors
								textDataColor={overlayColor}
								dayPeriodName={'Noon'.toUpperCase()}
								expectedVisitors={expectedVisitorsNoon.length}
								checkedinVisitors={checkinVisitorsNoon.length}
								notshowvisitors={expectedVisitorsNoon.length}
								timePeriod="7am-Midday"
								handleLinkSelected={this.NavigateToVisitorManagement}
							/>
							<PastVisitors
								textDataColor={overlayColor}
								dayPeriodName={'AfterNoon'.toUpperCase()}
								expectedVisitors={expectedVisitorsAfterNoon.length}
								checkedinVisitors={checkinVisitorsAfterNoon.length}
								notshowvisitors={expectedVisitorsAfterNoon.length}
								timePeriod="7am-Midday"
								handleLinkSelected={this.NavigateToVisitorManagement}
							/>
						</div>
					) : (
						<div className="Visitor-Expected">
							<VisitorsExpected
								dayPeriodName="Morning"
								numberVisitors={expectedVisitorsMorning.length}
								timePeriod="Morning - Midday"
								moduleColor={overlayColor}
								handleLinkSelected={this.NavigateToVisitorManagement}
							/>
							<VisitorsExpected
								dayPeriodName="Noon"
								numberVisitors={expectedVisitorsNoon.length}
								timePeriod="Midday - 2pm"
								moduleColor={overlayColor}
								handleLinkSelected={this.NavigateToVisitorManagement}
							/>
							<VisitorsExpected
								dayPeriodName="Afternoon"
								numberVisitors={expectedVisitorsAfterNoon.length}
								timePeriod="2pm - Midnight"
								moduleColor={overlayColor}
								handleLinkSelected={this.NavigateToVisitorManagement}
							/>
						</div>
					)}

					<div className="single-btn manage-visitor-btn">
						<Button text="Manage Visitors" size="medium" handleClick={this.NavigateToVisitorManagement} />
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		modules: moduleSelectors.loadModules(state),
		meetings: meetingSelector.getMeetings(state),
		residents: residentSelectors.getResidents(state),
		meetingsFilter: meetingSelector.getMeetingsFilter(state)
	};
}
export default withRouter(connect(mapStateToProps)(CheckinVisitorScreen));
