import * as types from "./actionTypes";
import TenantServices from "../../services/TenantServices";
import _ from "lodash";

import * as tenantSelectors from "../Tenants/reducer";

export function getAllTenants() {
  return async (dispatch, getState) => {
    try {
      const tenants = await TenantServices.getTenants();

      dispatch({ type: types.TENANTS_LOADED_SUCCESS, tenants: tenants });
    } catch (error) {
      console.error(error);
    }
  };
}

export function getSelectedTenant(memberId) {
  return async (dispatch, getState) => {
    const tenants = tenantSelectors.getAllTenants(getState());
    const selectedTenant = _.find(tenants, { id: memberId });
    dispatch({ type: types.TENANT_SELECTED, selectedTenant });
  };
}
