import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import autobind from 'react-autobind';
import InputComponent from '../Input/Input';
import Button from '../Button/Button';
import moment from 'moment';
import AriaModal from 'react-aria-modal';
import ButtonGroup from '../../components/Button/ButtonGroup';
import _ from 'lodash';
import * as $ from 'jquery';
import { browserHistory } from 'react-router';
import * as parcelActions from '../../store/Parcels/actions';
import * as parcelSelectors from '../../store/Parcels/reducer';
import TextAreaInput from '../../components/Input/TextAreaInput';
import '../../sass/components/ParcelModal/index.css';
import '../../sass/iescss.css';
//import parcelStatuses  from '../../../services/config';
import * as configHelper from '../../helpers/configHelper';

/**
 * Main Modal event, diferent modal states loaded as type is specified
 */

const PortalTypes = configHelper.PortalTypes;
const ParcelStatuses = configHelper.ParcelStatuses;
class ParcelCancelModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			modalActive: true,
			portalType:
				this.props.portalSettings && !_.isUndefined(this.props.portalSettings.portalType)
					? this.props.portalSettings.portalType
					: ''
		};
		autobind(this);
	}

	componentDidMount() {
		//	this.addValidation();
		/**
     * exclude control + J on input response, this is needed as scanner append Ctrl + J at end of value scanned
     * https://stackoverflow.com/questions/27960841/usb-barcode-scanner-opens-browsers-downloads-page/27961410
     */
	}
	UNSAFE_componentWillMount() {
		//	this.props.dispatch(parcelActions.getStatusCodes());
	}

//	handleContinueClick() {}

//	handleStayOnPageClick() {}
	render() {
		const {
			handleCloseClick,
			handleContinueClick,
			handleStayOnPageClick,
			getApplicationNode,
			focus,
			moduleColor,
			confirmationMessage
		} = this.props;

		return (
			<form id="modalForm" className="modal-wrapper">
				<AriaModal
					titleText="Concel Confirmation"
					onExit={handleCloseClick}
					underlayStyle={{ paddingTop: '8em' }}
					underlayClickExits={true}
					focusDialog={focus}
					mounted={this.state.modalActive}
					getApplicationNode={getApplicationNode}
					escapeExits={true}
				>
					<form
						id="form-content"
						ref="validate"
						onSubmit={(e) => {
							e.preventDefault();
						}}
						data-parsley-errors-container="#validation-messages"
						data-parsley-focus="none"
						data-parsley-validate-if-empty
					>
						<div className="modal-wrapper parcel-cancel-confirmation">
							<div className="modal-body">
								<h1 className="cancel-heading" style={{ color: moduleColor }}>
									Before you go...
								</h1>

								<p className="confirmation-message">{confirmationMessage}</p>

								<div className="btnGroup-cancel-modal">
									<Button
										text="Continue"
										size="xxsmall"
										handleClick={handleContinueClick}
									/>
									<Button
										text="Stay on the Page"
										size="xxsmall"
										handleClick={handleStayOnPageClick}
									/>
									{/* <ButtonGroup
													sizes={[ 'xxsmall', 'xxsmall' ]}
														leftText={'Continue'}
														rightText={'Stay on the page'}
														leftClick={() =>this.setState({ modalCancelShow: true })}
														rightClick={() => {
															this.setState({ IsEditable: false });
													}} 
												
													/>*/}
								</div>
							</div>
							<div />
						</div>
					</form>
				</AriaModal>
			</form>
		);
	}
}

ParcelCancelModal.propTypes = {
	/**Screen type value to specify current screen loaded */
	type: PropTypes.string,
	/**getting the module color */
	moduleColor: PropTypes.string
};

export default ParcelCancelModal;
