export const INITIAL_STATE = "INITIAL_STATE";
export const ITEM_ADDED_TO_BASKET = "ITEM_ADDED_TO_BASKET";
export const BASKET_LOADED_FROM_CACHE = "BASKET_LOADED_FROM_CACHE";
export const ITEM_REMOVED_FROM_BASKET = "ITEM_REMOVED_FROM_BASKET";
export const CHECKOUT_COMPLETED = "CHECKOUT_COMPLETED";
export const BOOKING_COMPLETED = "BOOKING_COMPLETED";
export const NOTIFICATION_SENT_SUCCESS = "NOTIFICATION_SENT_SUCCESS";
export const NOTIFICATION_SENT_FAIL = "NOTIFICATION_SENT_FAIL";
export const ITEM_SELECTED = "ITEM_SELECTED";
export const CHECKOUT_FAIL = "CHECKOUT_FAIL";
export const CHARGE_TYPE_SET = "CHARGE_TYPE_SET";
export const ACTION_SUCCESS = "ACTION_SUCCESS";
export const ACTION_TYPE = "ACTION_TYPE";
export const ACTION_MESSAGE_SET = "ACTION_MESSAGE_SET";
export const HM_NOTIFICATION_SENT_SUCCESS = "HM_NOTIFICATION_SENT_SUCCESS";
export const FM_NOTIFICATION_SENT_SUCCESS = "FM_NOTIFICATION_SENT_SUCCESS";
export const HM_NOTIFICATION_SENT_FAIL = "HM_NOTIFICATION_SENT_FAIL";
export const FM_NOTIFICATION_SENT_FAIL = "FM_NOTIFICATION_SENT_FAIL";
