import React, { Component } from "react";
import "../../sass/components/ResponseMessage/responseMessage.css";

var PropTypes = require('prop-types');
/**
 * A general purpose ResponseMessage Component for use throughout the project, where message and style is dynamic
 */

class ResponseMessage extends Component {
  render() {
    const { text, id, className, type } = this.props;


    return (
      <div
        id={id}
        className={className
        }

      >
        {type && type === "error" ?
          <p> {text}</p>
          :
          <strong>{text}</strong>
        }
      </div>
    );
  }
}

ResponseMessage.propTypes = {

  /** Type of Response for the component*/
  type: PropTypes.string,

  /** Text displayed on the Component*/
  text: PropTypes.string,

  /** If the component needs an id */
  id: PropTypes.string
};

export default ResponseMessage;
