import React, { Component } from "react";
import "../../sass/components/ProfileCard/index.css";

var PropTypes = require('prop-types');

/**
 * This is a card component for the parcel lite section
 *
 */

class ProfileCard extends Component {
  render() {
    const { name, address, picture, txtColor } = this.props;

    let profileStyle = {
      backgroundImage: "url(" + this.props.picture + ")",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      borderRadius: "50%",
      marginLeft: "64px",
      marginTop: "50px"
    };
    //  let profileStyle = {
    //       backgroundImage:"url(" + picture +}
    //   }
    //console.log(dateArrived);
    return (
      <div>
        <div className="ProfileCard">
          <div className="ProfilePicture">
            <div className="profileImg" style={profileStyle} />
             {/**<img src={picture} alt="profile image" />**/}
          </div>

          <div className="cardDetails">
            <div>
              <h2 className="name" style={{ color: txtColor }}>
                {name}
              </h2>
              <h3 className="address">{address}</h3>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ProfileCard.propType = {
  name: PropTypes.string,
  address: PropTypes.string,
  picture: PropTypes.string
};

export default ProfileCard;
