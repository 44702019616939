import React, { Component } from 'react';
import Popover from 'react-tiny-popover';
import autobind from 'react-autobind';
import moment from 'moment';
import _ from 'lodash';

const custom_card_colours = [
	{ card: 0, color: '#abadb0', border: '#abadb0' },
	{ card: 1, color: '#0ea610', border: '#0ea610' },
	{ card: 2, color: '#fa4b2e', border: '#fa4b2e' },
	{ card: 3, color: '#009cc4', border: '#009cc4' }
];

const custom_cards = [
	{ card: 0, color: '#ffffff', border: '#009cc4', borderPast: '#7fcee2' },
	{ card: 1, color: '#0ea610', border: '#0ea610', borderPast: '#87d388' },
	{ card: 2, color: '#fa4b2e', border: '#fa4b2e', borderPast: '#fda597' },
	{ card: 3, color: '#009cc4', border: '#009cc4', borderPast: '#7fcee2' }
];

const ServiceUnitType = {
	Day: 'day',
	Minute: 'minute'
};

class CustomEventWrapper extends Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false
		};

		autobind(this);
	}

	handleClick(e) {
		this.setState({ open: !this.state.open });
	}
	handleClose(e) {
		this.setState({ open: false });
	}

	dateDisplayHelper(event) {
		var dateRange = [];
		var currentDate = new Date(moment(event.start).format('YYYY-MM-DD'));
		var end = new Date(moment(event.end).format('YYYY-MM-DD'));
		var unit_type = event.type;
		let current = currentDate;
		for (var d = current; d <= end; d.setDate(d.getDate() + 1)) {
			dateRange.push(new Date(d));
		}

		if (unit_type === 'day') {
			if (dateRange.length > 1) {
				return `${moment(dateRange[0]).format('DD/MM/YYYY')}${' to ' +
					moment(dateRange[dateRange.length - 1]).format('DD/MM/YYYY')} ${event.duration && event.duration > 1
					? '(' + event.duration + ' days)'
					: '(' + event.duration + ' day)'}`;
			} else if (dateRange.length === 1) {
				return moment(dateRange[0]).format('YYYY-MM-DD');
			}
		} else if (unit_type === 'minute') {
			return moment(event.start).format('HH:mm') + ' to ' + moment(event.end).format('HH:mm');
		}
	}
	dayNameDisplayHelper(event) {
		var dateRange = [];
		var currentDate = new Date(moment(event.start).format('YYYY-MM-DD'));
		var end = new Date(moment(event.end).format('YYYY-MM-DD'));

		let current = currentDate;
		for (var d = current; d <= end; d.setDate(d.getDate() + 1)) {
			dateRange.push(new Date(d));
		}

		if (dateRange.length > 1) {
			return (
				moment(dateRange[0]).format('dddd') + ' to ' + moment(dateRange[dateRange.length - 1]).format('dddd')
			);
		} else if (dateRange.length === 1) {
			return moment(dateRange[0]).format('dddd');
		}
	}

	render() {
		const { event } = this.props;
		let card_color = _.find(custom_card_colours, { card: event.event.card });

		//this can be removed
		// let blocked_card_color = _.find(custom_cards, (cardItem) => {
		// 	return cardItem.card === card_color.card;
		// });

		//used fo the blocked bar color
		let current_card = _.find(custom_cards, { card: event.event.blocked_card });

		return (
			<Popover
				isOpen={this.state.open}
				position={'top'} // preferred position
				onClickOutside={() => this.setState({ open: false })}
				padding={10}
				disableReposition
				content={
					//   <div className={!_.isUndefined(card_color) ? "popover--card card--" + card_color.card : ""}>

					//     <div className="popover--content">
					//          <span className={event.event.booked || event.event.blocked ? "calendar-event booked" : "calendar-event"}>
					//              <strong>{event.event.eventName ? event.event.eventName : ""}</strong>  {" "}
					//              <p className={!_.isUndefined(card_color) ? "card-desc--" + card_color.card : event.event.blocked ? "card-desc" : "0"}> {event.event.booked || event.event.blocked ? (event.event.desc !== "" ? event.event.desc : "testing is in progress") : ""}</p>
					//         </span>
					//                        </div>
					//     </div>

					<div className="pop-up-box">
						<div
							className={
								card_color.card !== 0 ? 'Combined-Shape' : 'Combined-Shape Combined-Shape-blocked'
							}
						>
							<div
								className={
									!_.isUndefined(current_card) ? (
										'color-bar-' + current_card.card
									) : (
										'color-bar-' + event.event.card
									)
								}
							/>
							{
								//to be removed
								//<div className={!_.isUndefined(card_color) ? ((card_color.card != 0) ? "color-bar-" + card_color.card : (!_.isUndefined(blocked_card_color) ? "color-bar-" + blocked_card_color.card : "")) : ""}></div>
							}
							<strong
								className={
									!_.isUndefined(card_color) ? (
										'Booking-Name-Copy Booking-Name-Copy-color-' + card_color.card
									) : (
										''
									)
								}
							>
								{event.event.eventName ? event.event.eventName : ''}
							</strong>
							<p
								className={
									!_.isUndefined(card_color) ? (
										'Booking-information-Copy Booking-Name-Copy-color-' + card_color.card
									) : (
										''
									)
								}
							>
								{' '}
								{event.event.booked || event.event.blocked ? event.event.desc !== '' ? (
									event.event.desc
								) : (
									'testing is in progress'
								) : (
									''
								)}
							</p>
							{/* <input type="text" value={event.event.eventName ? event.event.eventName : ""} className={!_.isUndefined(card_color) ? "Booking-Name-Copy Booking-Name-Copy-color-" + card_color.card : ""} /> */}
							{/* <input type="text" value={event.event.booked || event.event.blocked ? (event.event.desc !== "" ? event.event.desc : "testing is in progress") : ""} className={!_.isUndefined(card_color) ? "Booking-information-Copy Booking-Name-Copy-color-" + card_color.card : ""}  /> */}
							{/* <input type="text" value="" className="Saturday-to-Sunday" />
                   <input type="text" value="24/11/2018 to 25/11/2018 " className="date-display" /> */}
							<div className="separater-line row-content" />
							<div className="triangle-support">
								<p className="Saturday-to-Sunday">{this.dayNameDisplayHelper(event.event)}</p>
								<p className="date-display">{this.dateDisplayHelper(event.event)}</p>
							</div>
							<div className="triangle-down" />
						</div>
					</div>
				}
			>
				<div className="ceventwrapper" ref={'target_' + event.event.id} onClick={() => this.handleClick()}>
					<span
						className={
							event.event.booked ? (
								'calendar-event booked'
							) : event.event.blocked ? (
								'calendar-event blocked'
							) : (
								'calendar-event'
							)
						}
					>
						<strong>{event.event.booked || event.event.blocked ? event.event.eventName : ''}</strong> {' '}
						{event.event.type && event.event.type === ServiceUnitType.Minute ? (
							<p className={!_.isUndefined(card_color) ? 'card-desc--' + card_color.card : '0'}>
								{' '}
								{event.event.desc !== '' ? event.event.desc : 'testing is in progress'}
							</p>
						) : (
							''
						)}
					</span>
				</div>
			</Popover>
		);
	}
}

export default CustomEventWrapper;
