import React, { Component } from 'react';

import _ from 'lodash';
import autoBind from 'react-autobind';
import * as configHelper from '../../helpers/configHelper';
import * as stringHelper from '../../helpers/stringHelper';

import Autosuggest from 'react-autosuggest';

import '../../sass/components/ParcelAutosuggest/index.css';

class ParcelAutoSuggest extends Component {
	constructor(props) {
		super(props);
		this.textInput = null;
		this.defaultState = {
			value: '',
			suggestions: [],
			valid: false,
			selected: undefined
		};

		this.state = this.defaultState;
		this.emptyResponse = {
			parcelId: '',
			companyName: '',
			parcel: undefined,
			dataMatch: undefined
		};

		autoBind(this);
	}

	componentDidMount() {
		// Set the autosuggest to an initial value if it is provided
		if (!_.isUndefined(this.props.defaultparcelId)) {
			const startParcel = this.props.parcels.find((meeting) => {
				return meeting.parcel.id === this.props.defaultparcelId;
			});

			this.handleParcelSelected(startParcel);

			this.setState({
				value: startParcel.resident.firstName + ' ' + startParcel.resident.lastName,
				valid: true
			});
		}

		/**
     * exclude control + J on input response, this is needed as scanner append Ctrl + J at end of value scanned
     * https://stackoverflow.com/questions/27960841/usb-barcode-scanner-opens-browsers-downloads-page/27961410
     */

		window.addEventListener('keydown', function(event) {
			if (event.keyCode === 17 || event.keyCode === 74) {
				if (event.ctrlKey) event.preventDefault();
			}
		});
	}

	reset() {
		this.setState(this.defaultState);
	}

	//null to empty string
	formatText(data) {
		return data ? data : '';
	}

	formatDataText(v1, v2) {
		let data = '';
		if (v1) {
			data = `${' - '}${v1}${v2 ? `${', '}${v2}` : ''}`;
		}
		return data;
	}

	// Autosuggest functions ++++++++++++++++++++++++++++++++++++++++++
	getSuggestionValue(suggestion) {
		// const { name } = this.state;
		//.replace(/,/g, '')
		// return `${suggestion.resident.firstName} ${suggestion.resident.lastName} ${this.formatDataText(suggestion.resident.addressLine1, suggestion.resident.addressLine2)}`;
		this.handleParcelSelected(suggestion);
		if (suggestion) this.setState({ disabled: '' });
		this.props.matchfound && this.props.matchfound(undefined);
		if (this.props.type === configHelper.PortalTypes.Residential)
			return `${suggestion.resident.firstName} ${suggestion.resident.lastName} ${this.formatDataText(
				suggestion.resident.addressLine1 && suggestion.resident.addressLine1,
				suggestion.resident.addressLine2
			)}`;
		else
			return `${suggestion.tenant.firstName} ${suggestion.tenant.lastName} ${suggestion.tenant.companyName
				? '- ' + this.formatText(suggestion.tenant.companyName)
				: ''}`;
	}

	handleClear(e) {
		this.reset();
		this.props.matchfound && this.props.matchfound(undefined);
		this.props.fieldManualCleared && this.props.fieldManualCleared(true);
		this.props.fieldCleared && this.props.fieldCleared(undefined);
		this.props.handleKeyPress && this.props.handleKeyPress({ key: 'Reset' }, []);
	}

	getSuggestions(val) {
		const { parcels, type } = this.props;

		if (!parcels) return [];

		var parcels_ = _.filter(parcels, (item) => {
			if (!_.isUndefined(type) && type === configHelper.PortalTypes.Residential) {
				return item.resident.firstName !== null || item.resident.lastName !== null;
			} else if (!_.isUndefined(type) && type === configHelper.PortalTypes.Commercial) {
				return item.tenant.firstName !== null || item.tenant.lastName !== null;
			}
		});
		const inputValue = val.trim().toLowerCase();
		// const inputLength = inputValue.length;
		const inputParts = inputValue.split(' ').filter((value) => {
			return value !== '';
		});

		let match = [];

		//input parts use to filter out blanck or empty text used
		if (inputParts.length === 1) {
			if (!_.isUndefined(type) && type === configHelper.PortalTypes.Residential) {
				match = _.filter(parcels_, (o) => {
					return (
						_.startsWith(o.resident.firstName.toLowerCase(), inputParts[0].toLowerCase()) ||
						_.startsWith(o.resident.lastName.toLowerCase(), inputParts[0].toLowerCase()) ||
						(o.barcode && _.startsWith(o.barcode.toLowerCase(), inputParts[0].toLowerCase())) ||
						(o.resident.addressLine1 &&
							_.includes(o.resident.addressLine1.toLowerCase(), inputParts[0].toLowerCase())) ||
						(o.resident.addressLine2 &&
							_.includes(o.resident.addressLine2.toLowerCase(), inputParts[0].toLowerCase()))
					);
				});
			} else if (!_.isUndefined(type) && type === configHelper.PortalTypes.Commercial) {
				match = _.filter(parcels_, (o) => {
					return (
						_.startsWith(o.tenant.firstName.toLowerCase(), inputParts[0].toLowerCase()) ||
						_.startsWith(o.tenant.lastName.toLowerCase(), inputParts[0].toLowerCase()) ||
						(o.barcode && _.startsWith(o.barcode.toLowerCase(), inputParts[0].toLowerCase())) ||
						(o.tenant.companyName &&
							_.startsWith(o.tenant.companyName.toLowerCase(), inputParts[0].toLowerCase()))
					);
				});
			}
		} else if (inputParts.length === 2) {
			let searchData = _.trimEnd(_.trimStart(val));
			if (!_.isUndefined(type) && type === configHelper.PortalTypes.Residential) {
				match = _.filter(parcels_, (o) => {
					return (
						(_.startsWith(o.resident.firstName.toLowerCase(), inputParts[0].toLowerCase()) &&
							_.startsWith(o.resident.lastName.toLowerCase(), inputParts[1].toLowerCase())) ||
						(o.resident.addressLine1 &&
							_.includes(o.resident.addressLine1.toLowerCase(), searchData.toLowerCase())) ||
						(o.resident.addressLine2 &&
							_.includes(o.resident.addressLine2.toLowerCase(), searchData.toLowerCase())) ||
						_.startsWith(o.barcode, searchData)
					);
				});
			} else if (!_.isUndefined(type) && type === configHelper.PortalTypes.Commercial) {
				match = _.filter(parcels_, (o) => {
					return (
						(o.tenant &&
							o.tenant.companyName &&
							_.startsWith(o.tenant.companyName.toLowerCase(), searchData.toLowerCase())) ||
						(_.startsWith(o.tenant.firstName.toLowerCase(), inputParts[0].toLowerCase()) &&
							_.startsWith(o.tenant.lastName.toLowerCase(), inputParts[1].toLowerCase())) ||
						_.startsWith(o.barcode, searchData)
					);
				});
			}
		} else if (inputParts.length > 3) {
			let search = '';
			for (let i = 3; i < inputParts.length; i++) {
				search += inputParts[i] + ' ';
			}
			search = _.trimEnd(search);

			match = _.filter(parcels_, (o) => {
				if (!_.isUndefined(type) && type === configHelper.PortalTypes.Residential) {
					return (
						(_.startsWith(o.resident.firstName.toLowerCase(), inputParts[0].toLowerCase()) &&
							_.startsWith(o.resident.lastName.toLowerCase(), inputParts[1].toLowerCase()) &&
							(o.resident.addressLine1 &&
								_.includes(o.resident.addressLine1.toLowerCase(), inputParts[3].toLowerCase()))) ||
						(o.barcode && _.startsWith(o.barcode.toLowerCase(), val.toLowerCase())) ||
						(o.tenant &&
							o.tenant.companyName &&
							_.startsWith(o.tenant.companyName.toLowerCase(), val.toLowerCase())) ||
						(o.resident.addressLine1 &&
							_.includes(o.resident.addressLine1.toLowerCase(), val.toLowerCase())) ||
						(o.resident.addressLine2 &&
							_.includes(o.resident.addressLine2.toLowerCase(), val.toLowerCase()))
					);
				} else if (!_.isUndefined(type) && type === configHelper.PortalTypes.Commercial) {
					return (
						(o.barcode && _.startsWith(o.barcode.toLowerCase(), val.toLowerCase())) ||
						(o.tenant &&
							o.tenant.companyName &&
							_.startsWith(o.tenant.companyName.toLowerCase(), val.toLowerCase())) ||
						(_.startsWith(o.tenant.firstName.toLowerCase(), inputParts[0].toLowerCase()) &&
							_.startsWith(o.tenant.lastName.toLowerCase(), inputParts[1].toLowerCase()) &&
							(o.tenant &&
								o.tenant.companyName &&
								_.startsWith(o.tenant.companyName.toLowerCase(), inputParts[3].toLowerCase())))
					);
				}
			});
		}

		this.props.matchfound && this.props.matchfound(match.length > 0);

		this.setState({
			dataMatch: match
		});

		return match;
	}

	onSuggestionsFetchRequested = ({ value }) => {
		this.setState({ suggestions: this.getSuggestions(value) });
	};

	onSuggestionsClearRequested = () => {
		//this.props.matchfound && this.props.matchfound(undefined);
		// if (this.state.dataMatch && this.state.dataMatch.length > 0)
		//   this.props.matchfound && this.props.matchfound(undefined);
		// this.setState({
		//   suggestions: []
		// });
	};

	onChange = (event, { newValue }) => {
		if (newValue.length === 0) {
			this.setState({ parcel: undefined });
			this.props.matchfound && this.props.matchfound(undefined);
			this.props.fieldManualCleared && this.props.fieldManualCleared(true);
		} else {
			this.props.fieldCleared && this.props.fieldCleared(undefined);
		}
		if (newValue.length > 2) {
			this.handleParcelChange(newValue);
		}

		this.setState({
			value: newValue,
			valid: newValue.length > 0,
			selected: undefined
		});
	};

	//to be revisited on up / down used for selection
	handleSuggestionSelected(suggestion, suggestionValue, suggestionIndex, sectionIndex, method) {
		this.handleParcelSelected(suggestion);
	}
	handleParcelSelected(val, setValue = false) {
		const stateChanges = {
			// meetingId: vis.id,
			parcelId: val.id,
			address:
				this.props.type === configHelper.PortalTypes.Residential
					? val.resident.addressLine1 + ' ' + val.resident.addressLine2
					: '',
			parcel: val,
			companyName: this.props.type === configHelper.PortalTypes.Commercial ? val.tenant.companyName : '',
			selected: true,
			matchfound: true,
			...(setValue ? { value: val.resident.firstName + ' ' + val.resident.lastName, valid: true } : {})
		};

		this.setState(stateChanges);
		this.props.matchfound && this.props.matchfound(true);
		this.props.onSelected && this.props.onSelected(stateChanges);
	}

	handleParcelChange(value) {
		this.setState({
			name: value
		});
	}

	renderSuggestion(suggestion, { query, isHighlighted }) {
		let addr = !suggestion.resident.addressLine1 ? '' : suggestion.resident.addressLine1;
		return (
			<div className={`${'Select-option'} ${isHighlighted === true ? 'highlighted' : ''}`}>
				{this.props.type === configHelper.PortalTypes.Residential ? (
					`${suggestion.resident.firstName} ${suggestion.resident
						.lastName} ${' - '}${stringHelper.formatStringList(
						[
							addr,
							suggestion.resident.addressLine2,
							suggestion.resident.addressLine3,
							suggestion.resident.addressLine4,
							suggestion.resident.addressLine5,
							suggestion.resident.addressLine6,
							suggestion.resident.postCode
						],
						0
					)}`
				) : (
					`${suggestion.tenant.firstName} ${suggestion.tenant.lastName} ${suggestion.tenant.companyName
						? '- ' + this.formatText(suggestion.tenant.companyName)
						: ''}`
				)}
				<div className="barCode">
					<span>{'Barcode:'}</span> {suggestion.barcode ? suggestion.barcode : 'Not Available'}
				</div>
			</div>
		);
	}

	checkMatch(currentValue) {
		const matchedParcel = this.props.parcels.find((parcel) => {
			if (currentValue) {
				const value = currentValue.toLowerCase();
				const parts = value.split(' ').filter((value) => {
					return value !== '';
				});

				if (parts.length > 0) {
					if (
						_.startsWith(parcel.resident.firstName.toLowerCase(), parts[0].toLowerCase()) ||
						_.startsWith(parcel.resident.lastName.toLowerCase(), parts[0].toLowerCase()) ||
						_.startsWith(parcel.resident.addressLine1.toLowerCase(), parts[0].toLowerCase()) ||
						_.startsWith(parcel.resident.addressLine2.toLowerCase(), parts[0].toLowerCase())
					) {
						return true;
					}
				}
			}

			return false;
		});

		if (_.isUndefined(matchedParcel) === false) {
			// if (matchedParcel_.length === 1) {

			this.handleParcelSelected(matchedParcel);
		} else {
			if (currentValue === '') {
				//this.props.matchfound && this.props.matchfound(undefined);
			}

			//this.props.matchfound && this.props.matchfound(false);

			this.props.onSelected && this.props.onSelected(this.emptyResponse);
		}
	}

	inputClick() {
		if (
			this.state.valid === true &&
			!_.isUndefined(this.props.clearableIfValid) &&
			this.props.clearableIfValid === true
		) {
			this.reset();
			//this.props.matchfound && this.props.matchfound(undefined);
			this.props.onSelected && this.props.onSelected(this.emptyResponse);
			this.props.handleKeyPress && this.props.handleKeyPress({ key: 'Clear' }, []);
		}
	}

	render() {
		const { value, suggestions, valid } = this.state;

		const disabled = _.isUndefined(this.props.disabled) || this.props.disabled === false ? false : true;

		const inputProps = {
			placeholder: this.props.placeHolder ? this.props.placeHolder : '',
			value,
			onChange: this.onChange,
			onClick: this.inputClick
		};

		//alway set the parcel search  input when this parcel module loads
		if (this.textInput) {
			this.textInput.focus();
		}

		return (
			<Autosuggest
				ref="autosuggest"
				suggestions={suggestions}
				focusInputOnSuggestionClick={false}
				onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
				onSuggestionsClearRequested={this.onSuggestionsClearRequested}
				getSuggestionValue={this.getSuggestionValue}
				onSuggestionSelected={(event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) =>
					this.handleSuggestionSelected(suggestion, suggestionValue, suggestionIndex, sectionIndex, method)}
				renderSuggestion={this.renderSuggestion}
				inputProps={inputProps}
				theme={{
					// maybe make global for reuse at some point
					container:
						'auto-suggest Select ' +
						(!_.isUndefined(this.props.clearableIfValid) && this.props.clearableIfValid === true
							? 'is-clearable'
							: ''),
					suggestionsList: 'Select-menu-outer-parcel',
					suggestion: 'Select-menu',
					suggestionsContainer: 'select--suggestions'
				}}
				renderInputComponent={(inputProps) => {
					return (
						<div>
							<label htmlFor={'form-input-parcel'} className="parcelSearchLable">
								{this.props.label ? this.props.label : 'Search'}
							</label>
							<div className="Select-input">
								<input
									name="parcel"
									autoComplete="off"
									disabled={disabled}
									value={this.state.value}
									onChange={inputProps.onChange}
									onKeyPress={(e) => {
										if (_.isUndefined(this.state.selected)) {
											if (e.key === 'Enter' && !_.isUndefined(this.props.handleKeyPress)) {
												if (!_.isEmpty(_.trim(this.state.value))) {
													this.props.handleKeyPress(e, this.state.dataMatch);
												} else {
													this.props.handleKeyPress(e, []);
												}
											}
										} else if (this.state.selected === true) {
											if (e.key === 'Enter' && !_.isUndefined(this.props.handleKeyPress)) {
												this.setState({ selected: undefined });
											}
										}
									}}
									onBlur={(e) => {
										/* Ensures that member ID fetched even if not selected via autosuggest */

										this.checkMatch(e.target.value);
									}}
									{...inputProps}
									//ref={e => (this.textInput = e)} //to be looked at, affecting display of data in auto suggest
									//ref={(e) => { this.nameInput = e; }}
									autoFocus
								/>
								{valid === true && (
									<div className={'icon ion-close-round'} onClick={(e) => this.handleClear(e)}>
										{' '}
									</div>
								)}
							</div>
						</div>
					);
				}}
			/>
		);
	}
}
export default ParcelAutoSuggest;
