import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import autobind from 'react-autobind';
import InputComponent from '../Input/Input';
import Button from '../Button/Button';
import moment from 'moment';
import AriaModal from 'react-aria-modal';
import _ from 'lodash';
import * as $ from 'jquery';
import { browserHistory } from 'react-router';
import * as parcelActions from '../../store/Parcels/actions';
import * as parcelSelectors from '../../store/Parcels/reducer';
import TextAreaInput from '../../components/Input/TextAreaInput';
import '../../sass/components/ParcelModal/index.css';
import '../../sass/iescss.css';

import { PortalTypes, ParcelStatuses, ParcelReasons } from '../../helpers/configHelper';

/**
 * Main Modal event, diferent modal states loaded as type is specified
 */

const ParcelScreens = {
	Register: 'register',
	Result: 'result'
};

class ParcelStatusModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			modalActive: true,
			portalType:
				this.props.portalSettings && !_.isUndefined(this.props.portalSettings.portalType)
					? this.props.portalSettings.portalType
					: '',
			currentIndex: this.props.currentIndex,
			selectedParcels: [],
			buttontextNext: 'Next',
			buttontextBack: 'Cancel',
			isLocationEmpty: true,
			selectedOptions: [],
			locationSelect: false,
			isParcelRegistered: false,
			buttonFinish: 'Finish',
			screen: ParcelScreens.Register,
			statusFailure: false,

			statusId: '',
			reasonId: '',
			additionalInfo: '',
			isAdditionalInformationFieldVisible: false,
			isReasonFieldVisible: false,
			isValid: false,
			success: undefined,
			message: ''
		};

		this.registerParcelValidation = null;
		autobind(this);
	}

	componentDidMount() {
		this.addValidation();
		/**
     * exclude control + J on input response, this is needed as scanner append Ctrl + J at end of value scanned
     * https://stackoverflow.com/questions/27960841/usb-barcode-scanner-opens-browsers-downloads-page/27961410
     */

		window.addEventListener('keydown', function(event) {
			if (event.keyCode === 17 || event.keyCode === 74) {
				if (event.ctrlKey) event.preventDefault();
			}
		});

		this.props.dispatch(parcelActions.getStatusCodes());
		this.props.dispatch(parcelActions.getReturnReasonCodes());
	}
	

	addValidation() {
		this.registerParcelValidation = $(this.refs.validate).parsley({
			classHandler: function(el) {
				return el.$element.closest('div:not(.input-wrapper):not(.Select)');
			}
		});
	}
	handleStatusChange(e) {
		let field = e.target.name;
		let value = e.target.value;
		this.setState({
			[field]: value
		});

		if (e.target.label === 'Collected') {
			this.setState({
				isValid: true,
				isReasonFieldVisible: false,
				isAdditionalInformationFieldVisible: false,
				reasonId: ''
			});
		} else {
			this.setState({
				isValid: false,
				isReasonFieldVisible: true
			});

			let reasonObj = _.find(this.props.parcelReturnReason, { id: this.state.reasonId });

			if (reasonObj && reasonObj.reason === 'Other') {
				if (this.state.additionalInfo === '') {
					this.setState({
						isValid: false
					});
				} else {
					this.setState({
						isValid: true
					});
				}
			} else {
				if (this.state.reasonId === '') {
					this.setState({
						isValid: false
					});
				} else {
					this.setState({
						isValid: true
					});
				}
			}
		}
	}

	handleReasonChange(e) {
		let field = e.target.name;
		let value = e.target.value;
		this.setState({
			[field]: value
		});

		if (e.target.label === 'Other') {
			this.setState({
				isAdditionalInformationFieldVisible: true
			});

			if (this.state.additionalInfo === '') {
				this.setState({
					isValid: false
				});
			} else {
				this.setState({
					isValid: true
				});
			}
		} else {
			this.setState({
				isAdditionalInformationFieldVisible: false,
				isValid: true
			});
		}
	}

	handleInputChange(e) {
		const field = e.target.name;
		const value = e.target.value;
		this.setState({
			[field]: value
		});

		if (e.target.value === '') {
			this.setState({
				isValid: false
			});
		} else {
			this.setState({
				isValid: true
			});
		}
	}

	handleSubmit() {
		const { data, parcelStatus, parcelReturnReason } = this.props;
		const { statusId, reasonId, additionalInfo } = this.state;

		let statusObj = _.find(parcelStatus, { id: statusId });
		let reasonObj = _.find(parcelReturnReason, { id: reasonId });

		if (!_.isUndefined(statusObj)) {
			let updateData = {
				id: data.id,
				barcode: data.barcode,
				memberId: data.memberId,
				locationId: data.locationId,
				status: {status : statusObj.status}
			};

			if (statusObj.status === ParcelStatuses.Collected) {
				this.props.dispatch(
					parcelActions.checkoutParcel(data, (success) => {
						if (success === true) {
							this.setState({
								success: success,
								message: '',
								isValid: false
							});

							this.props.dispatch(
								parcelActions.notifyParcels([ updateData ], (complete) => {
									if (complete === true) {
									}
								})
							);
							this.props.handleCloseClick(true);
						} else {
							this.setState({
								error: true,
								errorMessage: 'Your changes could not be saved. Please try again.'
							});
						}
					})
				);
			} else {
				let updateData_ = {
					...updateData,
					statusReasonId: reasonObj ? reasonObj.id : '',
					comment: additionalInfo,
					status : statusObj.status
				};
				
				this.setState({
					error: undefined,
					errorMessage: ''
				});

				this.props.dispatch(
					parcelActions.updateParcel(updateData_, (success) => {
						if (success === true) {
							this.setState({
								success: success,
								message: '',
								isValid: false
							});
							this.props.dispatch(
								parcelActions.notifyParcels([ updateData ], updateData.status, (complete) => {
									if (complete === true) {
									}
								})
							);
							this.props.handleCloseClick(true);
						} else {
							this.setState({
								error: true,
								errorMessage: 'Your changes could not be saved. Please try again.'
							});
						}
					})
				);
			}

			this.setState({
				success: undefined,
				message: ''
			});
		}
	}
	render() {
		const {
			handleCloseClick,
			getApplicationNode,
			focus,
			moduleColor,
			parcelStatus,
			parcelReturnReason
		} = this.props;

		let status_options = [];

		_.map(parcelStatus, (status, rowIndex) => {
			let curruntStatus = {
				name: status.status,
				label: status.status.charAt(0).toUpperCase() + status.status.slice(1),
				value: status.id
			};
			if (
				status.status === ParcelStatuses.Collected ||
				status.status === ParcelStatuses.Discarded ||
				status.status === ParcelStatuses.Returned
			) {
				status_options.push(curruntStatus);
			}
		});

		let reasons_options = [];

		_.map(parcelReturnReason, (reason, rowIndex) => {
			let curruntReason = {
				name: reason.reason,
				label: reason.reason.charAt(0).toUpperCase() + reason.reason.slice(1),
				value: reason.id
			};
			if (
				reason.reason === ParcelReasons.Damaged ||
				reason.reason === ParcelReasons.ForwardedToNewAddress ||
				reason.reason === ParcelReasons.RecipientMovedOut ||
				reason.reason === ParcelReasons.Uncollected ||
				reason.reason === ParcelReasons.Other
			) {
				reasons_options.push(curruntReason);
			}
		});

		return (
			<form id="modalForm" className="modal-wrapper">
				<AriaModal
					titleText="Booking Details"
					onExit={handleCloseClick}
					underlayStyle={{ paddingTop: '8em' }}
					underlayClickExits={true}
					focusDialog={focus}
					mounted={this.state.modalActive}
					getApplicationNode={getApplicationNode}
					escapeExits={true}
				>
					<form
						id="form-content"
						ref="validate"
						onSubmit={(e) => {
							e.preventDefault();
						}}
						data-parsley-errors-container="#validation-messages"
						data-parsley-focus="none"
						data-parsley-validate-if-empty
					>
						<div className="modal-wrapper parcel-change-status">
							<div className="modal-body">
								<h1 className="status-heading" style={{ color: moduleColor }}>
									Parcel Status
								</h1>
								<div className="status-field">
									<InputComponent
										ref="statusRef"
										label={'Change Status to*'}
										type="select"
										name="statusId"
										placeHolder="Select status"
										className="text-box"
										options={status_options}
										handleChange={(e) => this.handleStatusChange(e)}
										// value={
										// 	selectedStatus === '' ? !_.isUndefined(
										// 		this.state.selectedOptions[this.state.currentIndex].value
										// 	) ? (
										// 		this.state.selectedOptions[this.state.currentIndex].value.toString()
										// 	) : (
										// 		undefined
										// 	) : (
										// 		selectedLocation
										// 	)
										// }
									/>

									{this.state.isReasonFieldVisible ? (
										<InputComponent
											ref="reasonRef"
											label={'Reason*'}
											type="select"
											name="reasonId"
											placeHolder="Select reason"
											className="select-status-box"
											options={reasons_options}
											handleChange={this.handleReasonChange}
											// value={
											// 	selectedLocation === '' ? !_.isUndefined(
											// 		this.state.selectedOptions[this.state.currentIndex].value
											// 	) ? (
											// 		this.state.selectedOptions[this.state.currentIndex].value.toString()
											// 	) : (
											// 		undefined
											// 	) : (
											// 		selectedLocation
											// 	)
											// }
										/>
									) : (
										''
									)}

									{this.state.isAdditionalInformationFieldVisible ? (
										<div className="input-wrapper additional-info">
											<label className="dateLabel visitor-label">Additional Information*</label>
											<div className="addtionl-textarea">
												<TextAreaInput
													type="text"
													name="additionalInfo"
													value={this.state.additionalInfo}
													placeHolder="Please enter additional information"
													handleChange={this.handleInputChange}
													maxLength="391"
												/>
											</div>
										</div>
									) : (
										''
									)}
									<div
										className={
											'validation-messages' +
											(this.state.addParcelFormValid ? ' valid' : ' invalid')
										}
										id="validation-messages"
									>
										{this.state.error && (
											<div>
												<span className="error">{this.state.errorMessage}</span>
											</div>
										)}

										{this.state.success && (
											<div>
												<span className="success">{this.state.message}</span>
											</div>
										)}
									</div>
									<div className="footer-section">
										<Button text="Cancel" handleClick={() => handleCloseClick(true)} />
										<Button
											text="Change Status"
											isDisabled={!this.state.isValid}
											// isDisabled={
											// 	!this.state.locationSelect && selectedLocation === '' ? true : false
											// }
											handleClick={() => this.handleSubmit()}
										/>
									</div>
								</div>
							</div>
							<div />
						</div>
					</form>
				</AriaModal>
			</form>
		);
	}
}

ParcelStatusModal.propTypes = {
	/**Screen type value to specify current screen loaded */
	type: PropTypes.string,
	/**getting the module color */
	moduleColor: PropTypes.string,
	/** Portal type value  specifies current portal type*/
	portalType: PropTypes.string
};

function mapStateToProps(state) {
	return {
		registeredParcels: parcelSelectors.getRegisteredParcels(state),
		parcelStatus: parcelSelectors.getStatusCodes(state),
		parcelReturnReason: parcelSelectors.getReturnResonCodes(state),
		parcel: parcelSelectors.getParcel(state)
	};
}
export default connect(mapStateToProps)(ParcelStatusModal);
