import React, { Component } from "react";

import scriptLoader from 'react-async-script-loader';

var PropTypes = require('prop-types');


class WorldPayComponent extends Component {
    constructor(props) {
        super(props);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const {
            isScriptLoaded,
            isScriptLoadSucceed,
        } = nextProps;

        if (isScriptLoaded && !this.props.isScriptLoaded) { // load finished
            if (isScriptLoadSucceed) {

                console.log(isScriptLoaded);
                console.log(nextProps);
                this.initEditor()
            }
            else this.props.onError()
        }
    }

    initEditor() {
        console.log(this.props);
        this.props.handlePaymentLoad(true);

    }
    componentDidMount() {
        const { isScriptLoaded, isScriptLoadSucceed } = this.props
        if (isScriptLoaded && isScriptLoadSucceed) {
            this.initEditor()
        }
    }

    render() {
        //var libraryObject = new WPCL.Library();
        //libraryObject.setup(customOptions);
        return (
            <div id='paymentSection'>

            </div>
        );
    }

}

export default scriptLoader('https://cdn.worldpay.com/v1/worldpay.js')(WorldPayComponent);
//export default scriptLoader('https://payments.worldpay.com/resources/hpp/integrations/embedded/js/hpp-embedded-integration-library.js')(WorldPayComponent);