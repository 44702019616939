import React, { Component } from "react";
import { Link } from 'react-router-dom';
import "../../sass/components/FooterNavigation/FooterNavigation.css";

/**
 * This is the footer component initially designed for use on the concierge desktop.
 * Eventually it'd be nice to have the links for this brought in through an array. The links are hardcoded for now
 * 
 * 
 * 
 */
class FooterNavigation extends Component {
  render() {
    return (
      <footer>
        <div>
          <Link to="">Privacy</Link>
          <Link to="/termsandconditions">Terms & Conditions</Link>
          <Link to="">Cookies</Link>
          <Link to="">Feedback</Link><br></br>
          <Link to="">Designed and licensed by MRI Software</Link>
        </div>
      </footer>
    );
  }
}

export default FooterNavigation;
