import React, { Component } from 'react';
import { connect } from 'react-redux';
import Autosuggest from 'react-autosuggest';

import PropTypes from 'prop-types';
import { PortalTypes } from '../../helpers/configHelper';

import _ from 'lodash';
import * as $ from 'jquery';
import * as stringHelper from '../../helpers/stringHelper';

import autoBind from 'react-autobind';

import '../../sass/components/MemberAutoSuggest/index.css';

const Suggestion = ({ isHighlighted, suggestion, portalType }) => {
	return (
		<div className={`${'Select-option'} ${isHighlighted === true ? 'highlighted' : ''}`}>
			{suggestion.firstName} {suggestion.lastName}{' '}
			{portalType === PortalTypes.Residential ? (
				`${' - '}${stringHelper.formatStringList([
					suggestion.addressLine1,
					suggestion.addressLine2,
					suggestion.addressLine3,
					suggestion.addressLine4,
					suggestion.addressLine5,
					suggestion.addressLine6,
					suggestion.postCode
				])}`
			) : (
				`${!suggestion.companyName ? '' : ' - ' + suggestion.companyName}`
			)}
		</div>
	);
};
class MemberAutoSuggest extends Component {
	constructor(props) {
		super(props);

		this.defaultState = {
			value: '',
			suggestions: [],
			valid: false,
			otherText: '',
			portalType: ''
		};

		this.state = this.defaultState;

		//this.props.dispatch(residentActions.getAllResidents());
		this.emptyResponse = {
			memberId: '',
			address: '',
			tenant: ''
		};

		this.selectedTenantFromDefault = false;

		autoBind(this);
	}

	componentDidUpdate(prevProps, prevState) {
		// Set the autosuggest to an initial value if it is provided
		if (
			this.props.data.length > 0 &&
			!_.isUndefined(this.props.defaultTenantId) &&
			_.isNumber(this.props.defaultTenantId) &&
			prevProps.portalType &&
			this.selectedTenantFromDefault === false
		) {
			this.selectedTenantFromDefault = true;

			const startTenant = _.find(this.props.data, (resident) => {
				return resident.id === Number(this.props.defaultTenantId);
			});
			if (!_.isUndefined(startTenant)) {
				this.handleTenantSelected(startTenant);

				this.setState({
					value:
						startTenant.firstName +
						' ' +
						startTenant.lastName +
						`${this.props.portalType === PortalTypes.Residential
							? `${' - '}${stringHelper.formatStringList(
									[
										startTenant.addressLine1,
										startTenant.addressLine2,
										startTenant.addressLine3,
										startTenant.addressLine4,
										startTenant.addressLine5,
										startTenant.addressLine6,
										startTenant.postCode
									],
									0
								)}`
							: !startTenant.companyName || _.isUndefined(startTenant.companyName)
								? ''
								: ' - ' + startTenant.companyName}`,
					valid: true
				});
			}
		}

		if (this.props.defaultTenantId !== prevProps.defaultTenantId && !_.isUndefined(this.props.defaultTenantId)) {
			//this.selectedTenantFromDefault = true;
			const startTenant = _.find(this.props.data, (resident) => {
				return resident.id === Number(this.props.defaultTenantId);
			});
			if (!_.isUndefined(startTenant)) {
				this.handleTenantSelected(startTenant);
				this.setState({
					value:
						startTenant.firstName +
						' ' +
						startTenant.lastName +
						`${this.props.portalType === PortalTypes.Residential
							? `${' - '}${stringHelper.formatStringList(
									[
										startTenant.addressLine1,
										startTenant.addressLine2,
										startTenant.addressLine3,
										startTenant.addressLine4,
										startTenant.addressLine5,
										startTenant.addressLine6,
										startTenant.postCode
									],
									0
								)}`
							: !startTenant.companyName || _.isUndefined(startTenant.companyName)
								? ''
								: ' - ' + startTenant.companyName}`,
					valid: true
				});
			}
		}
			
		
		if(this.props.reset !== prevProps.reset && this.props.reset === true){
			
			this.handleClear(null);
		}
	}
	
	reset() {
		this.setState(this.defaultState);
	}

	handleClear(e) {
		this.reset();
		this.props.matchfound && this.props.matchfound(undefined);
		this.props.fieldManualCleared && this.props.fieldManualCleared(true);
		this.props.fieldCleared && this.props.fieldCleared(undefined);
		this.props.handleKeyPress && this.props.handleKeyPress({ key: 'Reset' }, []);
	}
	// Autosuggest functions ++++++++++++++++++++++++++++++++++++++++++
	getSuggestionValue(suggestion) {
		const { portalType } = this.props;

		this.handleTenantSelected(suggestion);
		if (suggestion) this.setState({ disabled: '' });

		return `${suggestion.firstName} ${suggestion.lastName} ${portalType === PortalTypes.Residential
			? !suggestion.addressLine1
				? ''
				: ' - ' +
					suggestion.addressLine1 +
					`${suggestion.addressLine2 ? `${' , '}${suggestion.addressLine2}` : ''}`
			: !suggestion.companyName || _.isUndefined(suggestion.companyName) ? '' : ' - ' + suggestion.companyName}`;
	}

	getSuggestions(val) {
		const { data, portalType } = this.props;

		if (!data) return [];

		//safe guard residents data return null value in some records
		var data_ = _.filter(data, (obj) => {
			return obj.firstName !== null || obj.lastName !== null;
		});

		const value = val.trim().toLowerCase();
		const parts = value.split(' ').filter((value) => {
			return value !== '';
		});

		const match = _.filter(data_, (item) => {
			if (parts.length === 1) {
				return (
					(item.firstName &&_.startsWith(item.firstName.toLowerCase(), parts[0].toLowerCase())) ||
					(item.lastName &&_.startsWith(item.lastName.toLowerCase(), parts[0].toLowerCase())) ||
					((portalType === PortalTypes.Residential &&
						item.addressLine1 &&
						_.includes(item.addressLine1.toLowerCase(), parts[0].toLowerCase())) ||
						(item.addressLine2 && _.includes(item.addressLine2.toLowerCase(), parts[0].toLowerCase()))) ||
					(portalType === PortalTypes.Commercial &&
						item.companyName &&
						_.startsWith(item.companyName.toLowerCase(), parts[0].toLowerCase()))
				);
			} else if (parts.length === 2) {
				return (
					((item.firstName && _.startsWith(item.firstName.toLowerCase(), parts[0].toLowerCase())) &&
						(item.lastName && _.startsWith(item.lastName.toLowerCase(), parts[1].toLowerCase()))) ||
					(portalType === PortalTypes.Commercial &&
						item.companyName &&
						_.startsWith(item.companyName.toLowerCase(), val.trim().toLowerCase())) ||
					(portalType === PortalTypes.Residential &&
						item.addressLine1 &&
						_.includes(item.addressLine1.toLowerCase(), val.trim().toLowerCase()))
				);
			} else if (parts.length === 3) {
				return (
					(item.firstName && _.startsWith(item.firstName.toLowerCase(), parts[0].toLowerCase())) &&
					(item.lastName && _.startsWith(item.lastName.toLowerCase(), parts[1].toLowerCase())) &&
					((portalType === PortalTypes.Residential &&
						item.addressLine1 &&
						_.includes(item.addressLine1.toLowerCase(), parts[2].toLowerCase())) ||
						(portalType === PortalTypes.Commercial &&
							item.companyName &&
							_.startsWith(item.companyName.toLowerCase(), parts[2].toLowerCase())))
				);
			} else if (parts.length > 3) {
				let searchData = '';
				for (var i = 3; i < parts.length; i++) {
					searchData += parts[i] + ' ';
				}

				return (
					(item.firstName && _.startsWith(item.firstName.toLowerCase(), parts[0].toLowerCase()) ) &&
					(item.firstName && _.startsWith(item.lastName.toLowerCase(), parts[1].toLowerCase()) )&&
					((portalType === PortalTypes.Residential &&
						item.addressLine1 &&
						_.includes(item.addressLine1.toLowerCase(), searchData.trim().toLowerCase())) ||
						(portalType === PortalTypes.Commercial &&
							item.companyName &&
							_.startsWith(item.companyName.toLowerCase(), searchData.trim().toLowerCase())))
				);
			}
		});

		return match;
	}

	onSuggestionsFetchRequested = ({ value }) => {
		this.setState({ suggestions: this.getSuggestions(value) });
	};

	onSuggestionsClearRequested = () => {};
	handlePasteChange(e) {
		this.setState({
			otherText: e.target.value,
			value: e.target.value
		});
		this.props.defaultText(e.target.value);
	}
	onChange = (event, { newValue, method }) => {
		if (newValue.length === 0) {
			this.setState({ tenant: undefined });
			this.props.fieldManualCleared && this.props.fieldManualCleared(true);
		}

		//this.handleClear(event);

		if (newValue.length > 2) {
			this.handleTenantChange(newValue);
		}

		this.setState({
			value: newValue
		});

		setTimeout(() => {
			// Delayed because parseley not ready if user selected a suggestion

			this.setState({
				valid: $(this.refs.autosuggest.input).parsley().isValid()
			});
		}, 0);

		//this.checkMatch(newValue);
	};

	handleTenantSelected(ten, setValue = false) {
		let tenantType = '';
		let address = '';

		if (this.props.portalType === PortalTypes.Residential) {
			tenantType = `${' - '}${stringHelper.formatStringList(
				[
					ten.addressLine1,
					ten.addressLine2,
					ten.addressLine3,
					ten.addressLine4,
					ten.addressLine5,
					ten.addressLine6,
					ten.postCode
				],
				0
			)}`;
			address = stringHelper.formatStringList(
				[
					ten.addressLine1,
					ten.addressLine2,
					ten.addressLine3,
					ten.addressLine4,
					ten.addressLine5,
					ten.addressLine6,
					ten.postCode
				],
				30
			);
		} else {
			address = !ten.companyName || _.isUndefined(ten.companyName) ? '' : ' - ' + ten.companyName;
		}

		const stateChanges = {
			memberId: ten.id,
			address: this.props.portalType === PortalTypes.Residential ? address : ten.companyName,
			tenant: ten.firstName + ' ' + ten.lastName,

			...(setValue ? { value: ten.firstName + ' ' + ten.lastName + tenantType, valid: true } : {})
		};

		this.setState(stateChanges);

		this.props.onSelected && this.props.onSelected(stateChanges);
	}

	handleTenantChange(value) {
		// const { memberId } = this.state;
		this.setState({
			name: value,
			otherText: value
		});

		//used as a container if resident value is null
		this.props.defaultText(value);
	}

	renderSuggestion(suggestion, { query, isHighlighted }) {
		return <Suggestion isHighlighted={isHighlighted} suggestion={suggestion} portalType={this.props.portalType} />;
	}
	handleSuggestionSelected(suggestion, suggestionValue, suggestionIndex, sectionIndex, method) {
		this.handleTenantSelected(suggestion);
	}

	checkMatch(currentValue) {
		this.setState({
			otherText: currentValue
		});

		const matchedTenant = _.find(this.props.data, (resident) => {
			const value = currentValue.toLowerCase();
			const parts = value.split(' ').filter((value) => {
				return value !== '';
			});

			if (
				parts.length === 2 &&
				resident.firstName.toLowerCase() === parts[0].trim() &&
				resident.lastName.toLowerCase() === parts[1].trim()
			) {
				return true;
			}

			return false;
		});

		if (_.isUndefined(matchedTenant) === false) {
			this.handleTenantSelected(matchedTenant);
		} else {
			this.props.onSelected && this.props.onSelected(this.emptyResponse);
		}
	}

	inputClick() {
		if (
			this.state.valid === true &&
			!_.isUndefined(this.props.clearableIfValid) &&
			this.props.clearableIfValid === true
		) {
			this.reset();

			this.props.onSelected && this.props.onSelected(this.emptyResponse);
		}
	}

	render() {
		const { value, suggestions, valid } = this.state;

		const disabled = _.isUndefined(this.props.disabled) || this.props.disabled === false ? false : true;

		const inputProps = {
			placeholder: this.props.placeHolder ? this.props.placeHolder : 'Tenant',
			value,
			onChange: this.onChange,
			onClick: this.inputClick
		};
		
		return (
			<Autosuggest
				ref="autosuggest"
				scrollBar={true}
				suggestions={suggestions}
				onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
				onSuggestionsClearRequested={this.onSuggestionsClearRequested}
				getSuggestionValue={this.getSuggestionValue}
				onSuggestionSelected={(event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) =>
					this.handleSuggestionSelected(suggestion, suggestionValue, suggestionIndex, sectionIndex, method)}
				renderSuggestion={this.renderSuggestion}
				inputProps={inputProps}
				onBlur={(event, { highlightedSuggestion }) => {
					//console.log(highlightedSuggestion);
				}}
				theme={{
					// maybe make global for reuse at some point
					container:
						'auto-suggest Select ' +
						(!_.isUndefined(this.props.clearableIfValid) && this.props.clearableIfValid === true
							? 'is-clearable'
							: ''),
					suggestionsContainerOpen: 'container--is--open',
					suggestionsList: 'Select-list',
					suggestion: 'Select-menu',
					suggestionsContainer: 'select--suggestions',
					suggestions: 'Select-menu-outer'
				}}
				renderInputComponent={(inputProps) => {
					const iconProp =
						!_.isUndefined(this.props.clearableIfValid) &&
						this.props.clearableIfValid === true &&
						valid === true &&
						!disabled
							? { icon: 'ion-close-round' }
							: {};

					return (
						<div
							className={
								this.props.validation && this.props.validation.validate === true ? (
									this.props.validation.className
								) : (
									''
								)
							}
						>
							<label htmlFor={'form-input-visitor'} className="like-h4">
								{this.props.label ? this.props.label : 'Member'}
							</label>
							<div>
								<div className={'Select-input' + (disabled ? ' disabled' : '')}>
									<input
										// autoSuggestProps={inputProps}
										//label={this.props.label ? this.props.label : "Visitor"}
										name="tenant"
										autoComplete="off"
										disabled={disabled}
										value={this.state.value}
										onChange={inputProps.onChange}
										//onClick={inputProps.onClick}
										// handleKeyPress={e => {

										//     if (e.key === "Enter" && !_.isUndefined(this.props.handleKeyPress)) {

										//         this.props.handleKeyPress(e, this.state.dataMatch);
										//     }

										// }}
										onKeyPress={() => {}}
										onBlur={(e) => {
											/* Ensures that member ID fetched even if not selected via autosuggest */

											this.checkMatch(e.target.value);
										}}
										{...inputProps}
										// {...{
										// 	'data-parsley-matches-one-of': JSON.stringify({
										// 		allSuggestions: this.props.data.map((resident) => {
										// 			return {
										// 				value: resident.firstName + ' ' + resident.lastName
										// 			};
										// 		})
										// 	}),
										// 	'data-parsley-pattern': "/^[A-Za-z ,.'-]+$/i", // alpha chars only https://stackoverflow.com/questions/8059370/
										// 	'data-parsley-minlength': 4,
										// 	'data-parsley-required': false,
										// 	'data-parsley-error-message': 'Please enter an existing tenant name'
										// }}
									/>
									{valid === true &&
									value &&
									!disabled && (
										<div className={'icon ion-close-round'} onClick={(e) => this.handleClear(e)}>
											{' '}
										</div>
									)}
								</div>
							</div>
						</div>
						// <InputComponent
						// 	autoSuggestProps={inputProps}
						// 	label={this.props.labelText}
						// 	name="tenant"
						// 	placeHolder={this.props.placeHolder}
						// 	disabled={disabled}
						// 	value={this.state.value}
						// 	onChange={inputProps.onChange}
						// 	onClick={inputProps.onClick}
						// 	handleChange={this.handlePasteChange}
						// 	onBlur={(e) => {
						// 		/* Ensures that member ID fetched even if not selected via autosuggest */
						// 		this.checkMatch(e.target.value);
						// 	}}
						// 	parsley={{
						// 		'data-parsley-matches-one-of': JSON.stringify({
						// 			allSuggestions: this.props.data.map((resident) => {
						// 				return {
						// 					value: resident.firstName + ' ' + resident.lastName
						// 				};
						// 			})
						// 		}),
						// 		'data-parsley-pattern': "/^[A-Za-z ,.'-]+$/i", // alpha chars only https://stackoverflow.com/questions/8059370/
						// 		'data-parsley-minlength': 4,
						// 		'data-parsley-required': false,
						// 		'data-parsley-error-message': 'Please enter an existing tenant name'
						// 	}}
						// 	{...iconProp}
						// />
					);
				}}
			/>
		);
	}
}

MemberAutoSuggest.propTypes = {
	/* Optional initial tenant id to start with, (used when navigating back etc...) */
	defaultTenantId: PropTypes.number,
	/* Callback function for when item is selected */
	onSelected: PropTypes.func,
	/* Optional disable the input field */
	disabled: PropTypes.bool,
	/* Specifies if a X icon shows and the input field becomes clickable if the autosuggest is valid */
	clearableIfValid: PropTypes.bool,
	/* Label text */
	labelText: PropTypes.string,
	/* PlaceHolder Text */
	placeHolder: PropTypes.string,
	/**Default text */
	defaultText: PropTypes.func,
	/*data list */
	data: PropTypes.array
};

function mapStateToProps(state) {
	return {
		//portalSettings: settingSelector.loadGlobalSetting(state)
	};
}

export default connect(mapStateToProps, null, null, { forwardRef: true })(MemberAutoSuggest);
