import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import autobind from 'react-autobind';
import InputComponent from '../../../components/Input/CustomInput';
import { createBrowserHistory } from 'history';
import FeatureArea from '../../../components/FeatureArea/FeatureArea';
import FooterNavigation from '../../../components/FooterNavigation/FooterNavigation';
import Button from '../../../components/Button/Button';
import * as moduleSelectors from '../../../store/ServiceModules/reducer';
import * as parcelActions from '../../../store/Parcels/actions';
import * as portalSettingsActions from '../../../store/ConciergeSetting/actions';
import * as portalSettingsSelectors from '../../../store/ConciergeSetting/reducer';
import * as configHelper from '../../../helpers/configHelper';
import ParcelsListComponent from '../../../components/Parcel/ParcelsListComponent';
import * as parcelSelectors from '../../../store/Parcels/reducer';

import '../../../sass/components/Parcel/index.css';
import ParcelModal from '../../../components/ariaModals/ParcelModal';

/**
 * Parcel Expected screen
 */

let history = createBrowserHistory();
const PortalTypes = configHelper.PortalTypes;
class ParcelExpectedScreen extends Component {
	constructor(props) {
		super(props);
		this.setSelectedParcel = this.setSelectedParcel.bind(this);
		this.state = {
			type: 'parcels',
			today: moment(),
			parcels: [],
			parcelList: [],
			search: '',
			btnText: 'Unexpected Parcel',
			todayFilterSelected: true,
			maxFilterSelected: false,
			minFilterSelected: false,
			dayFilter: 15,
			portalType:
				this.props.portalSettings && this.props.portalSettings.portalType
					? this.props.portalSettings.portalType
					: '',
			showFilterOptions: false,
			modal: '',
			modalShow: false,
			IsAnyParcelSelected: false,
			IsParcelSearched: false,
			selectedParcels: [],
			currentIndex: 0,
			IsParcelsUpdated: false,
			noParcelFoundMsg: ''
		};

		this.addParcelValidation = null;

		autobind(this);
	}

	componentWillMount() {
		this.props.dispatch(parcelActions.getAllLocations());
		this.props.dispatch(portalSettingsActions.getGlobalSetting());
		this.props.dispatch(parcelActions.getParcelSettigs());
		this.props.dispatch(parcelActions.getParcelsByStatus(configHelper.ParcelStatuses.Expected));
	}

	componentWillUpdate(nextProps, nextState) {
		if (this.props.portalSettings !== nextProps.portalSettings && !_.isUndefined(nextProps.portalSettings)) {
			if (nextProps.portalSettings.portalType) {
				this.setState({
					portalType: nextProps.portalSettings.portalType
				});
			}
		}

		if (this.props.parcelSettings !== nextProps.parcelSettings && !_.isUndefined(nextProps.parcelSettings)) {
			let filterOptions = nextProps.parcelSettings.filterSettings;
			if (filterOptions) {
				this.setState({
					dayFilter: filterOptions.dayFilter ? filterOptions.dayFilter : this.state.dayFilter
				});
			}
		}

		//load initial parcel list on page load
		// && nextProps.parcels.length > 0 i remove this condition so that when no parcel found after updation it should also updated with blank list
		if (this.props.parcels !== nextProps.parcels) {
			let parcelList = _.filter(
				nextProps.parcels,
				(parcel) => moment(parcel.expectedDate).format('YYYY-MM-DD') === this.state.today.format('YYYY-MM-DD')
			);
			this.setState({
				parcels: parcelList
			});

			this.updateSearchList(this.state.search, nextProps.parcels);
		}

		if (this.state.selectedParcels !== nextState.selectedParcels) {
			let listItemSelected = nextState.selectedParcels && nextState.selectedParcels.length > 0 ? true : false;

			this.setState({
				IsAnyParcelSelected: listItemSelected
			});
		}
	}

	resetParcelsList(registeredParcelsList) {
		this.props.dispatch(parcelActions.getParcelsByStatus(configHelper.ParcelStatuses.Expected));
	}

	setSelectedParcel(expectedParcel, setSelectedParcel) {
		this.setState({
			expectedParcel: expectedParcel,
			selectedParcels: setSelectedParcel
		});
	}

	setParcelsUpdated(status) {
		this.setState({
			IsParcelsUpdated: status
		});
	}

	/**
 * Handle filter option seltion, 
 * @param {*} min 
 * @param {*} today 
 * @param {*} max 
 */
	handleFilterOptionsClick(min, today, max) {
		let min_sel = false,
			today_sel = false,
			max_sel = false;

		if (today === true) {
			if (!this.state.minFilterSelected && !this.state.maxFilterSelected) {
				this.setState({
					minFilterSelected: !min,
					maxFilterSelected: !max,
					todayFilterSelected: today
				});

				min_sel = !min;
				max_sel = !max;
				today_sel = today;
			} else {
				this.setState({
					todayFilterSelected: !this.state.todayFilterSelected ? today : !today,
					minFilterSelected: this.state.minFilterSelected,
					maxFilterSelected: this.state.maxFilterSelected
				});
				min_sel = this.state.minFilterSelected;
				max_sel = this.state.maxFilterSelected;
				today_sel = !this.state.todayFilterSelected ? today : !today;
			}
		}
		if (max === true) {
			if (!this.state.todayFilterSelected && !this.state.minFilterSelected) {
				this.setState({
					maxFilterSelected: max,
					minFilterSelected: !min,
					todayFilterSelected: !today
				});

				max_sel = max;
				min_sel = !min;
				today_sel = !today;
			} else {
				this.setState({
					maxFilterSelected: !this.state.maxFilterSelected ? max : !max,
					minFilterSelected: this.state.minFilterSelected,
					todayFilterSelected: this.state.todayFilterSelected
				});
				max_sel = !this.state.maxFilterSelected ? max : !max;
				min_sel = this.state.minFilterSelected;
				today_sel = this.state.todayFilterSelected;
			}
		}

		if (min === true) {
			if (!this.state.maxFilterSelected && !this.state.todayFilterSelected) {
				this.setState({
					maxFilterSelected: !max,
					minFilterSelected: min,
					todayFilterSelected: !today
				});

				max_sel = !max;
				min_sel = min;
				today_sel = !today;
			} else {
				this.setState({
					maxFilterSelected: this.state.maxFilterSelected,
					minFilterSelected: !this.state.minFilterSelected ? min : !min,
					todayFilterSelected: this.state.todayFilterSelected
				});
				max_sel = this.state.maxFilterSelected;
				min_sel = !this.state.minFilterSelected ? min : !min;
				today_sel = this.state.todayFilterSelected;
			}
		}

		this.handleFilterSelected(min_sel, max_sel, today_sel, this.state.parcelList);
	}
	handleInputChange(e) {
		const field = e.target.name;
		const value = e.target.value;
		this.setState({
			[field]: value
		});

		if (value === '') {
			let defaultParcels = _.filter(
				this.props.parcels,
				(parcel) => moment(parcel.expectedDate).format('YYYY-MM-DD') === this.state.today.format('YYYY-MM-DD')
			);
			this.setState({
				search: '',
				parcels: defaultParcels,
				showFilterOptions: false,
				IsParcelSearched: true
			});

			this.handleFilterSelected(
				this.state.minFilterSelected,
				this.state.maxFilterSelected,
				this.state.todayFilterSelected,
				this.props.parcels
			);
		}
	}

	handleCloseClick() {
		this.setState({ modalShow: false });
	}
	/**
     * 
     * @param {*} e handle return key search trigger 
     */
	handleSearchPress(e) {
		const { parcels } = this.props;
		const { search, portalType, today, dayFilter } = this.state;

		let type = portalType;

		// this.setState({
		//     IsParcelSearched:true,
		// });

		if (e.key === 'Enter') {
			this.updateSearchList(search, parcels);
			// this.setState(
			// 	{
			// 		minFilterSelected: false,
			// 		maxFilterSelected: false,
			// 		todayFilterSelected: true
			// 	},
			// 	function() {
			// 		this.updateSearchList(search, parcels);
			// 	}
			// );
		} else if (e.key === 'Clear') {
			this.setState({
				IsParcelSearched: true
			});
		} else if (e.key === 'Reset') {
			let defaultParcels = _.filter(
				this.props.parcels,
				(parcel) => moment(parcel.expectedDate).format('YYYY-MM-DD') === this.state.today.format('YYYY-MM-DD')
			);

			this.setState({
				IsParcelSearched: true,
				search: '',
				parcels: defaultParcels,
				showFilterOptions: false
			});
		}
	}

	updateSearchList(search, parcels) {
		const { portalType, today, dayFilter } = this.state;

		let type = portalType;
		const inputValue = search.trim().toLowerCase();
		// const inputLength = inputValue.length;
		const inputParts = inputValue.split(' ').filter((value) => {
			return value !== '';
		});

		let searchData = '';

		let match = [];
		if (inputParts.length === 1) {
			if (!_.isUndefined(type) && type === configHelper.PortalTypes.Residential) {
				match = _.filter(parcels, (o) => {
					return (
						_.startsWith(o.resident.firstName.toLowerCase(), inputParts[0].toLowerCase()) ||
						_.startsWith(o.resident.lastName.toLowerCase(), inputParts[0].toLowerCase()) ||
						(o.resident.addressLine1 &&
							_.includes(o.resident.addressLine1.toLowerCase(), inputParts[0].toLowerCase())) ||
						(o.resident.addressLine2 &&
							_.includes(o.resident.addressLine2.toLowerCase(), inputParts[0].toLowerCase()))
					);
				});
			} else if (!_.isUndefined(type) && type === configHelper.PortalTypes.Commercial) {
				match = _.filter(parcels, (o) => {
					return (
						_.startsWith(o.tenant.firstName.toLowerCase(), inputParts[0].toLowerCase()) ||
						_.startsWith(o.tenant.lastName.toLowerCase(), inputParts[0].toLowerCase()) ||
						(o.tenant.companyName &&
							_.startsWith(o.tenant.companyName.toLowerCase(), inputParts[0].toLowerCase()))
					);
				});
			}
		} else if (inputParts.length === 2) {
			searchData = _.trimEnd(_.trimStart(search));

			match = _.filter(parcels, (o) => {
				return (
					(_.startsWith(o.resident.firstName.toLowerCase(), inputParts[0].toLowerCase()) &&
						_.startsWith(o.resident.lastName.toLowerCase(), inputParts[1].toLowerCase())) ||
					(o.tenant &&
						o.tenant.companyName &&
						_.startsWith(o.tenant.companyName.toLowerCase(), searchData.toLowerCase())) ||
					(o.resident.addressLine1 &&
						_.includes(o.resident.addressLine1.toLowerCase(), searchData.toLowerCase())) ||
					(_.startsWith(o.tenant.firstName.toLowerCase(), inputParts[0].toLowerCase()) &&
						_.startsWith(o.tenant.lastName.toLowerCase(), inputParts[1].toLowerCase()))
				);
			});
		} else if (inputParts.length > 2) {
			searchData = _.trimEnd(_.trimStart(search));

			match = _.filter(parcels, (o) => {
				if (!_.isUndefined(type) && type === configHelper.PortalTypes.Residential) {
					return (
						(_.startsWith(o.resident.firstName.toLowerCase(), inputParts[0].toLowerCase()) &&
							_.startsWith(o.resident.lastName.toLowerCase(), inputParts[1].toLowerCase()) &&
							(o.resident.addressLine1 &&
								_.includes(o.resident.addressLine1.toLowerCase(), inputParts[2].toLowerCase()))) ||
						(o.tenant &&
							o.tenant.companyName &&
							_.startsWith(o.tenant.companyName.toLowerCase(), searchData.toLowerCase())) ||
						(o.resident.addressLine1 &&
							_.includes(o.resident.addressLine1.toLowerCase(), searchData.toLowerCase()))
					);
				} else if (!_.isUndefined(type) && type === configHelper.PortalTypes.Commercial) {
					return (
						(o.tenant &&
							o.tenant.companyName &&
							_.startsWith(o.tenant.companyName.toLowerCase(), searchData.toLowerCase())) ||
						(_.startsWith(o.tenant.firstName.toLowerCase(), inputParts[0].toLowerCase()) &&
							_.startsWith(o.tenant.lastName.toLowerCase(), inputParts[1].toLowerCase()) &&
							(o.tenant &&
								o.tenant.companyName &&
								_.startsWith(o.tenant.companyName.toLowerCase(), inputParts[2].toLowerCase())))
					);
				}
			});
		} else if (inputParts.length === 0) {
			match = _.filter(parcels);
		}

		var duration = moment.duration({ days: dayFilter });
		let maxDate = moment(today).add(duration, 'days');
		let minDate = moment(today).subtract(duration, 'days');

		//get parcels list within dayfilter range, this would set the showfilteroptios value, if condition is met
		let filterList = _.filter(match, (parcel) => {
			return (
				(moment(parcel.expectedDate).format('L') === minDate.format('L') ||
					moment(parcel.expectedDate).isAfter(minDate)) &&
				(moment(parcel.expectedDate).format('L') === maxDate.format('L') ||
					moment(parcel.expectedDate).isBefore(maxDate))
				// && moment(parcel.expectedDate).format('L') !== today.format('L')
			);
		});

		let filteredList = _.filter(filterList, (parcel) => {
			return moment(parcel.expectedDate).format('L') !== today.format('L');
		});

		let expectedToday = _.filter(
			match,
			(o) => moment(o.expectedDate).format('YYYY-MM-DD') === today.format('YYYY-MM-DD')
		);

		this.setState({
			showFilterOptions: expectedToday.length === 0 && filterList.length > 0,
			parcelList: filterList,
			IsParcelSearched: true,
			expectedTodayList: expectedToday.length
		});

		//this was used to allow component state to update,
		//fresh search triggered default settings is set

		this.handleFilterSelected(
			this.state.minFilterSelected,
			this.state.maxFilterSelected,
			this.state.todayFilterSelected,
			match
		);
	}

	/**
     * handle filter options selected, filter parcels data based on expecteddate value
     * @param {*} minSelected 
     * @param {*} maxSelected 
     */
	handleFilterSelected(minSelected, maxSelected, todaySelected, parcels) {
		const { today, dayFilter } = this.state;
		let parcelList = [];
		let parcelListAll = [];

		var duration = moment.duration({ days: dayFilter });
		let maxDate = moment(today).add(duration, 'days');
		let minDate = moment(today).subtract(duration, 'days');

		parcelListAll = _.filter(parcels, (parcel) => {
			return (
				(moment(parcel.expectedDate).format('L') === minDate.format('L') ||
					moment(parcel.expectedDate).isAfter(minDate)) &&
				(moment(parcel.expectedDate).format('L') === maxDate.format('L') ||
					moment(parcel.expectedDate).isBefore(maxDate))
			);
		});

		let noParcelFoundMsg =
			parcelListAll && parcelListAll.length > 0
				? 'No matching results. Try looking for past and future parcels expected. '
				: ' No matching results.';
		this.setState({
			noParcelFoundMsg: noParcelFoundMsg
		});
		if (todaySelected === true && !minSelected && !maxSelected) {
			parcelList = _.filter(parcels, (parcel) => {
				return moment(parcel.expectedDate).format('L') === today.format('L');
			});
		} else if (minSelected === true && todaySelected === true && !maxSelected) {
			parcelList = _.filter(parcels, (parcel) => {
				return (
					((moment(parcel.expectedDate).format('L') === minDate.format('L') ||
						moment(parcel.expectedDate).isAfter(minDate)) &&
						moment(parcel.expectedDate).isBefore(moment(today))) ||
					moment(parcel.expectedDate).format('L') === today.format('L')
				);
			});
		} else if (minSelected === true && !todaySelected && !maxSelected) {
			parcelList = _.filter(
				parcels,
				(parcel) =>
					(moment(parcel.expectedDate).format('L') === minDate.format('L') ||
						moment(parcel.expectedDate).isAfter(minDate)) &&
					moment(parcel.expectedDate).isBefore(moment(today)) &&
					moment(parcel.expectedDate).format('L') !== today.format('L')
			);
		} else if (minSelected === true && maxSelected === true && todaySelected === true) {
			parcelList = _.filter(parcels, (parcel) => {
				return (
					(moment(parcel.expectedDate).format('L') === minDate.format('L') ||
						moment(parcel.expectedDate).isAfter(minDate)) &&
					(moment(parcel.expectedDate).format('L') === maxDate.format('L') ||
						moment(parcel.expectedDate).isBefore(maxDate))
				);
			});
		} else if (minSelected === true && maxSelected === true && !todaySelected) {
			parcelList = _.filter(
				_.filter(parcels, (parcel) => {
					return (
						(moment(parcel.expectedDate).format('L') === minDate.format('L') ||
							moment(parcel.expectedDate).isAfter(minDate)) &&
						(moment(parcel.expectedDate).format('L') === maxDate.format('L') ||
							moment(parcel.expectedDate).isBefore(maxDate))
					);
				}),
				(o) => moment(o.expectedDate).format('L') !== today.format('L')
			);
		} else if (!minSelected && todaySelected === true && maxSelected === true) {
			parcelList = _.filter(parcels, (parcel) => {
				return (
					(moment(parcel.expectedDate).format('L') === today.format('L') ||
						moment(parcel.expectedDate).isAfter(today)) &&
					(moment(parcel.expectedDate).isBefore(maxDate) ||
						moment(parcel.expectedDate).format('L') === maxDate.format('L'))
				);
			});
		} else if (!minSelected && !todaySelected && maxSelected === true) {
			parcelList = _.filter(
				parcels,
				(parcel) =>
					(moment(parcel.expectedDate).format('L') === maxDate.format('L') ||
						moment(parcel.expectedDate).isBefore(maxDate)) &&
					moment(parcel.expectedDate).isAfter(today) &&
					moment(parcel.expectedDate).format('L') !== today.format('L')
			);
		}

		this.setState({
			parcels: parcelList
		});

		let filteredSelectedParcelList = _.filter(
			_.map(this.state.selectedParcels, (item) => {
				let parcel = _.find(parcelList, { id: item.id });
				if (!_.isUndefined(parcel)) {
					return item;
				}

				return undefined;
			}),
			(o) => !_.isUndefined(o)
		);

		this.setState({
			selectedParcels: filteredSelectedParcelList
		});
	}

	getApplicationNode = () => {
		return document.getElementById('parcelRoot');
	};

	setParcelSearched(status) {
		this.setState({
			IsParcelSearched: status
		});
	}

	render() {
		const { search, showFilterOptions, portalType, modules, noParcelFoundMsg } = this.state;
		const { parcelLocations } = this.props;
		const currentModule = _.find(this.props.modules, (o) => o.title === 'Parcels');

		const overlayColor = currentModule && currentModule.imageOverlayTextColour;

		const { parcels } = this.state;

		const dayFilter = this.state.dayFilter > 1 ? this.state.dayFilter + ' days' : this.state.dayFilter + ' day';

		let currentParcels = [];

		if (parcels && parcels.length > 0) {
			currentParcels = _.orderBy(parcels, [ 'expectedDate' ], [ 'desc' ]);

			if (portalType === PortalTypes.Commercial) {
				currentParcels = _.orderBy(currentParcels, [ 'tenant.firstName', 'tenant.companyName' ], [ 'asc' ]);
			} else if (portalType === PortalTypes.Residential) {
				currentParcels = _.orderBy(
					currentParcels,
					[ 'resident.firstName', 'resident.addressLine1' ],
					[ 'asc' ]
				);
			}
		}
		
		return (
			<div>
				<div className="container type-concierge-desktop parcel-expe-header">
					<FeatureArea
						type={this.state.type}
						areaTitle="Parcels"
						areaTagline="Welcome, select a service"
						pageHeader="Parcels Expected"
						overlayColor={currentModule && currentModule.imageOverlayTextColour}
						landing={false}
						activeMenu={true}
					/>

					<div id="parcelRoot" className="main-container parcel-expe-root">
						<div className="search-text">
							{portalType === PortalTypes.Commercial ? (
								'Search for Tenant Name or Company'
							) : (
								'Search for Tenant Name or Address'
							)}
						</div>
						<div className="search-section">
							<InputComponent
								type="text"
								name="search"
								placeHolder={
									portalType === PortalTypes.Commercial ? (
										'Enter tenant name or company name'
									) : (
										'Enter tenant name or address line'
									)
								}
								value={search}
								handleChange={(e) => this.handleInputChange(e)}
								handleKeyPress={(e) => this.handleSearchPress(e)}
								clearable={true}
								icon={'ion-close-round'}
								resetField={(val) => {
									// let defaultParcels = _.filter(
									// 	this.props.parcels,
									// 	(parcel) =>
									// 		moment(parcel.expectedDate).format('YYYY-MM-DD') ===
									// 		this.state.today.format('YYYY-MM-DD')
									// );
									this.updateSearchList('', this.props.parcels);

									if (val === true)
										this.setState({
											search: '',
											//	parcels: defaultParcels,
											showFilterOptions: false,
											IsParcelSearched: true
										});
								}}
							/>

							<Button
								size="xsmall"
								text={this.state.btnText}
								handleClick={() => {
									if (this.state.IsAnyParcelSelected === true) {
										this.setState({ modalShow: true });
									} else {
										this.setState({ modalShow: false });
										this.props.history.push('/concierge/parcel/register');
										window.scrollTo(0, 0);
									}
								}}
								icon=""
							/>
						</div>

						{/* {showFilterOptions === true && ( */}
						<div className="filter-section">
							<div className="fliter-resultstext">Filter results:</div>
							<div
								className={this.state.minFilterSelected ? 'filter-selected' : 'fliter-unselected'}
								onClick={() => {
									this.handleFilterOptionsClick(true, false, false);
								}}
							>
								Last {dayFilter}{' '}
							</div>
							<span className="filter-margin" />
							<div
								className={this.state.todayFilterSelected ? 'filter-selected' : 'fliter-unselected'}
								onClick={() => {
									this.handleFilterOptionsClick(false, true, false);
								}}
							>
								Today
							</div>
							<span className="filter-margin" />
							<div
								className={this.state.maxFilterSelected ? 'filter-selected' : 'fliter-unselected'}
								onClick={() => {
									this.handleFilterOptionsClick(false, false, true);
								}}
							>
								Next {dayFilter}{' '}
							</div>
						</div>
						{/* )} */}

						<ParcelsListComponent
							data={currentParcels}
							portalType={portalType}
							setSelectedParcel={this.setSelectedParcel}
							actionClick={(IsParcelSelected) => {
								if (IsParcelSelected === true) {
									this.setState({
										btnText: 'Register Parcel'
									});
								} else {
									this.setState({
										btnText: 'Unexpected Parcel'
									});
								}
							}}
							isParcelExpectedSearched={this.state.IsParcelSearched}
							setParcelSearched={this.setParcelSearched}
							isParcelListChanged={this.state.IsParcelsUpdated}
							setParcelsUpdated={this.setParcelsUpdated}
							selectedParcels={this.state.selectedParcels}
							noParcelFoundMsg={noParcelFoundMsg}
						/>

						{this.state.modalShow ? (
							<ParcelModal
								handleCloseClick={() => this.handleCloseClick()}
								focus={true}
								getApplicationNode={this.getApplicationNode}
								moduleColor={overlayColor}
								expectedParcel={this.state.expectedParcel}
								portalSettings={this.props.portalSettings}
								selectedParcels={this.state.selectedParcels}
								currentIndex={this.state.currentIndex}
								parcelLocations={parcelLocations}
								resetParcelsList={this.resetParcelsList}
							/>
						) : (
							''
						)}
					</div>

					<FooterNavigation />
				</div>
			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		modules: moduleSelectors.loadModules(state),
		parcels: parcelSelectors.getParcels(state),
		parcelSettings: parcelSelectors.getParcelSettings(state),
		portalSettings: portalSettingsSelectors.loadGlobalSetting(state),
		parcelLocations: parcelSelectors.getParcelLocations(state)
	};
}

export default connect(mapStateToProps, null, null, { forwardRef: true })(ParcelExpectedScreen);
