import React, { Component } from "react";
import { connect } from "react-redux";

import moment from "moment";
import _ from "lodash";
import autoBind from "react-autobind";
import dateFormat from "dateformat";
import {withRouter} from 'react-router-dom';

import * as resourceSelector from "../../../store/Resources/reducer";
import * as resourceActions from "../../../store/Resources/actions";
import * as checkoutSelector from "../../../store/Checkout/reducer";
import * as checkoutActions from "../../../store/Checkout/actions";

import * as resourceMonthlyActions from "../../../store/ResourcesMonthly/actions";
//import * as resourceMonthlySelectors from "../../../store/ResourcesMonthly/reducer";

import BookingCalendarCustom from "../../../components/BookingCalendar/BookingCalendarCustomMonthly";

const calendarViews = {
    Day: "day",
    Week: "week",
    WorkWeek: "work_week",
    Month: "month"
};

const ResourcePager = {
    page: 1,
    per_page: 387
};
const MAX_DATE_BOOKINGS = 7;
class MonthScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            type: "rooms",
            now: new Date(),
            startDate: moment(),
            endDate: moment(),
            serviceId: 0,
            eventId: "",
            slots: [],
            options: [],
            timeSlot: 1,
            eventName: "",
            time_step: 1, //default value
            selectedServiceSlot: [],
            selectedEventsSlots: [],
            vision_type: "week",
            monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
            current_view: calendarViews.Month,
            selectedEventsSlot: [],
            selected_event_resources: [],
            max_day_bookings: MAX_DATE_BOOKINGS

        };

        autoBind(this);

    }


    componentDidMount() {


        this.props.dispatch(resourceActions.clearBasketQueue());

        this.props.dispatch(checkoutActions.loadBasketFromCache());


    }
    componentWillMount() {


        if (this.props.service) {
            this.setState({
                serviceId: this.props.service.id,
                time_step: this.props.service.booking_time_step,
                max_day_bookings: !_.isUndefined(this.props.service.max_bookings) &&
                    this.props.service.max_bookings > MAX_DATE_BOOKINGS ? this.props.service.max_bookings : MAX_DATE_BOOKINGS
            });

            this.servicesSlots(this.props.service.id, this.state.startDate, this.state.current_view);

        }

        //coming from payment redirect, reset date to preload date 
        if (this.props.setDate) {
            this.setState({
                startDate: moment(this.props.setDate),
                endDate: moment(this.props.setDate)
            });


        }

    }
    componentWillUnmount() {
        this.props.dispatch(resourceActions.emptyServicesSlots());
    }

    componentDidUpdate(prevProps, prevState) {

        if (prevProps.selectedEventsSlots !== this.props.selectedEventsSlots) {

            this.setState({
                selectedEventsSlots: this.props.selectedEventsSlots
            });
        }

        if (prevProps.service !== this.props.service) {
            if (this.props.service) {
                this.setState({
                    serviceId: this.props.service.id,
                    time_step: this.props.service.booking_time_step,
                    max_day_bookings: !_.isUndefined(this.props.service.max_bookings) &&
                    this.props.service.max_bookings > MAX_DATE_BOOKINGS ? this.props.service.max_bookings : MAX_DATE_BOOKINGS
                });

                this.servicesSlots(this.props.service.id, this.state.startDate, this.state.current_view);
            }
        }

    }


    componentWillUpdate(nextProps, nextState) {


        //old daily slot list
        if (this.props.selectedServiceSlot !== nextProps.selectedServiceSlot) {
            this.setState({
                selectedServiceSlot: nextProps.selectedServiceSlot
            });
        }
        if (this.props.resourceEvents !== nextProps.resourceEvents) {


            this.setState({
                selected_event_resources: nextProps.resourceEvents
            });

            var events = _.map(nextProps.resourceEvents, eventItem => {
                return { id: eventItem.eventId }
            });

            var eventsData = {
                events: events
            };


            var date_ = this.state.startDate.toDate(), y = date_.getFullYear(), m = date_.getMonth();
            var startDay = new Date(y, m, 1);
            var endDay = new Date(y, m + 1, 0);

            startDay = moment(startDay).subtract(this.state.max_day_bookings, 'd');
            endDay = moment(endDay).add(7, 'd');

            this.props.dateChanged(nextState.startDate);
            //this.servicesDayData(this.state.serviceId, moment(startDay).format("YYYY-MM-DD"), moment(endDay).format("YYYY-MM-DD"));

            this.props.dispatch(resourceMonthlyActions.fetchResourceDayData(eventsData, this.state.serviceId,
                moment(startDay._d).format("YYYY-MM-DD"),
                moment(endDay._d).format("YYYY-MM-DD"), ResourcePager.page, ResourcePager.per_page, success => {
                    if (success === true) {
                        this.loadEventSlots(nextProps.resourceEvents, this.state.startDate, this.state.endDate);
                    } else {

                    }
                }))


        }

        if (this.state.startDate !== nextState.startDate && !_.isUndefined(nextState.startDate)) {



            this.setState({
                startDate: nextState.startDate,
                endDate: nextState.endDate
            });

            this.props.dateChanged(nextState.startDate);

            var date_val = nextState.startDate.toDate(), yr = date_val.getFullYear(), mth = date_val.getMonth();
            var startDay_val = new Date(yr, mth, 1);
            var endDay_val = new Date(yr, mth + 1, 0);

            startDay_val = moment(startDay_val).subtract(this.state.max_day_bookings, 'd');
            endDay_val = moment(endDay_val).add(7, 'd');


            //construct for resourceDaydata request
            let events = {
                events: _.map(this.state.selected_event_resources, event => {
                    return { id: event.eventId }
                })
            }

            //this.servicesDayData(this.state.serviceId, moment(startDay).format("YYYY-MM-DD"), moment(endDay).format("YYYY-MM-DD"));

            this.props.dispatch(resourceMonthlyActions.fetchResourceDayData(events, this.state.serviceId,
                moment(startDay_val).format("YYYY-MM-DD"), moment(endDay_val).format("YYYY-MM-DD"), ResourcePager.page, ResourcePager.per_page, success => {
                    if (success === true) {
                        this.loadEventSlots(this.state.selected_event_resources, nextState.startDate, nextState.endDate);
                    } else {

                    }
                }))


            //this.servicesSlots(this.state.serviceId, nextState.startDate, this.state.current_view);
            //this.loadEventSlots(this.state.selected_event_resources, nextState.startDate, nextState.endDate);

        }

        // if (this.props.selectedEventsSlots !== nextProps.selectedEventsSlots) {
        //     this.setState({
        //         selectedEventsSlots: nextProps.selectedEventsSlots
        //     });
        // }



        if (this.props.service !== nextProps.service) {

            this.setState({
                serviceId: nextProps.service.id,
                time_step: nextProps.booking_time_step
            });


            this.servicesSlots(nextProps.service.id, this.state.startDate, this.state.current_view);



        }


    }

    loadBookingQuestions(detail_group_id) {
        //this.props.dispatch(resourceActions.fetchBookingQuestions(detail_group_id));
    }

    servicesSlots(serviceId, date, view) {

        var date_ = this.state.startDate.toDate(), y = date_.getFullYear(), m = date_.getMonth();
        var firstDay = new Date(y, m, 1);
        var lastDay = new Date(y, m + 1, 0);

        firstDay = moment(firstDay);
        lastDay = moment(lastDay);

        let start_date = dateFormat(firstDay._d, "isoDate");
        let end_date = dateFormat(lastDay._d, "isoDate");


        this.props.dispatch(
            resourceMonthlyActions.fetchServicesSlots(
                serviceId,
                start_date, end_date, success => {
                    if (success === true) {
                        this.setState({
                            request_fail: undefined
                        });
                    } else {

                        this.setState({
                            request_fail: true
                        });
                    }

                }
            )
        );


    }

    servicesDayData(serviceId, startDate, endDate) {

        this.props.dispatch(resourceMonthlyActions.fetchServiceDayData(serviceId, startDate, endDate, success => {
            if (success === true) {
                this.servicesSlots(this.state.serviceId, startDate, this.state.current_view);
            }
        }));
    }

    selectedServiceSlots(eventId, date, serviceId, eventName) {


    }

    availaibleTimeData(serviceId, date, eventId) {

        this.props.dispatch(
            resourceActions.getServiceAvailability(serviceId, date, eventId)
        );
    }
    handleDateChange(date) {

        const { eventId, eventName, current_view } = this.state;

        this.setState({
            startDate: date
        });


        this.servicesSlots(this.props.serviceId, date, current_view);
        if (eventId) {
            this.selectedServiceSlots(eventId, date, this.props.serviceId, eventName);
        }
    }

    handleRightClick(e) {

        this.props.history.push("/reception/tenant");
    }
    handleBackClick(e) {

        this.props.history.push("/");
    }
    onLogin() {

        this.props.navigationController.pushView(
            <div>Today</div>
        );
    }

    modalActionTrigered(action, valid) {

        if (action === "payment") {
            this.props.history.push({
                pathname: "/",
                state: {
                    setModule: "Bookings"
                }
            });
        } else {
            if (!_.isUndefined(this.state.serviceId)) {

                // this.serviceBookings(this.state.serviceId, dateFormat(this.state.startDate._d, "isoDate"));
                this.servicesSlots(this.state.serviceId, this.state.startDate, this.state.current_view);

                if (this.state.selected_event_resources && this.state.selected_event_resources.length > 0)
                    this.loadEventSlots(this.state.selected_event_resources, this.state.startDate, this.state.endDate);

            }

        }
    }

    serviceBookings(serviceid, date) {

        const { current_view } = this.state;

        var date_ = moment(date).toDate(), y = date_.getFullYear(), m = date_.getMonth();
        var startDay = new Date(y, m, 1);
        var endDay = new Date(y, m + 1, 0);

        startDay = moment(startDay).subtract(this.state.max_day_bookings, 'd');
        endDay = moment(endDay).add(7, 'd');



        if (current_view === calendarViews.Month)
            this.props.dispatch(resourceMonthlyActions.getServiceBookings(serviceid, startDay.format("YYYY-MM-DD"), endDay.format("YYYY-MM-DD"), success => {
                if (success === true) {
                    this.setState({
                        request_fail: undefined
                    });
                } else {
                    this.setState({
                        request_fail: true
                    });
                }
            }));

    }
    switchTypeHandler = (type) => {

        console.log(" switchTypeHandler here now", this.state.startDate.format("YYYY-MM-DD"));
        switch (type) {
            case "week":
                this.setState({
                    startDate: moment().startOf("week"),
                    endDate: moment().endOf("week"),
                    current_view: type
                });

                break;
            case "work_week":
                this.setState({
                    startDate: moment().startOf("week"),
                    endDate: moment().endOf("week"),
                    current_view: type
                });

                break;
            case "day":
                this.setState({
                    startDate: this.state.startDate,
                    endDate: this.state.endDate,
                    current_view: type
                });
                break;
            default:
                break;
        }
        // this.state.current_view = type;

        // this.setState({
        //     current_view: type
        // });

    }

    loadServicesOption(options) {

        return (
            <div></div>
        );
    }

    //helper method to call slot data on selected events
    loadEventSlots(events, startDate, endDate) {

        const { current_view,
        } = this.state;

        const { serviceId } = this.state;

        if (current_view === calendarViews.Month) {

            var date_ = startDate.toDate(), y = date_.getFullYear(), m = date_.getMonth();
            var startDay = new Date(y, m, 1);
            var endDay = new Date(y, m + 1, 0);

            startDay = moment(startDay).subtract(this.state.max_day_bookings, 'd');
            endDay = moment(endDay).add(7, 'd');

            this.setState({
                eventslot_processing: true
            });

            this.props.dispatch(resourceActions.resourceProcessingStatus(true));
            this.props.dispatch(resourceMonthlyActions.getSelectedEventsSlots(events, new Date(startDay._d),
                new Date(endDay._d), serviceId, success => {
                    if (success === true) {
                        this.setState({
                            request_fail: undefined,
                            eventslot_processing: undefined
                        });

                    } else {

                        this.setState({
                            request_fail: true,
                            eventslot_processing: undefined
                        });

                    }
                    this.props.dispatch(resourceActions.resourceProcessingStatus(undefined));
                }));

        }

    }


    //keep for carlendar navigation
    handleNavigation(date, view) {

        switch (view) {
            case "day":
                this.setState({
                    startDate: moment(date).startOf("day"),
                    endDate: moment(date).endOf("day")
                });


                return date;
            case "work_week":

                this.setState({
                    startDate: moment(date).startOf("week"),
                    endDate: moment(date).endOf("week")
                });

                return date;
            case "week":

                this.setState({
                    startDate: moment(date).startOf("week"),
                    endDate: moment(date).endOf("week")
                });
                return date;
            case "month":
                this.setState({
                    startDate: moment(date).startOf("month"),
                    endDate: moment(date).endOf("month")
                });
                return date;
            default:
                return date;
        }
    }

    testSlotData() {

        let data = [
            {
                id: 820,
                isAvailable: false,
                booked: true,
                desc: 'Demo   Resident',
                end: '2018-11-23',
                start: '2018-11-21',
                allDay: true,
                type: 'day',
                // start_min: 630,
                // end_min: 690,
                eventId: 992,
                eventName: 'DeLacy',
                tenant: {
                    id: 1261,
                    firstName: 'Demo',
                    lastName: 'Resident',
                    companyName: 'GSM International',
                    bookingBugMemberId: '9',
                    email: 'resident@engageproptech.com',
                    ccAddress: null,
                    sendCcNotification: false
                },
                tag: 1,
                past: true,
                card: 1,
                title: 'DeLacy',
                questions: {},
                answers: [],
                price: 0,
                service_name: 'Meeting Rooms - Hour',
                duration: 60
            },

            {
                id: 821,
                isAvailable: false,
                booked: true,
                desc: 'Demo   Resident',
                end: '2018-11-21',
                start: '2018-11-21',
                allDay: true,
                type: 'day',
                // start_min: 720,
                // end_min: 780,
                eventId: 992,
                eventName: 'DeLacy',
                tenant: {
                    id: 1261,
                    firstName: 'Demo',
                    lastName: 'Resident',
                    companyName: 'GSM International',
                    bookingBugMemberId: '9',
                    email: 'resident@engageproptech.com',
                    ccAddress: null,
                    sendCcNotification: false
                },
                tag: 2,
                past: true,
                card: 2,
                title: 'DeLacy',
                questions: {},
                answers: [],
                price: 0,
                service_name: 'Meeting Rooms - Hour',
                duration: 60
            }
        ];

        return data;
    }


    render() {
        const {
            servicesSlots,
            service,
            bookingQuestions,
        } = this.props;

        const {
            startDate,
            eventId,
            eventName,
            type,
            time_step,
            endDate,
            now
        } = this.state;

        let service_options = []
        let options = _.filter(_.map(servicesSlots, item => {

            if (_.isUndefined(_.find(service_options, { eventid: item.event_id }))) {
                service_options.push({ name: item.name, eventid: item.event_id });
                return { name: item.name, eventid: item.event_id };
            } else {
                return undefined;
            }

        }), o => !_.isUndefined(o));



        return (
            <div className="container content-wrapper">
                <div className="BookingCalendar">

                    {

                        this.props.service &&
                            this.state.selectedEventsSlots && this.state.current_view ?

                            <BookingCalendarCustom
                                slots={this.state.selectedEventsSlots}
                                //slots={this.testSlotData()}
                                serviceDetail={service}
                                serviceId={this.state.serviceId}
                                eventId={eventId}
                                eventName={eventName}
                                step={time_step}
                                defaultDate={now}
                                date={startDate}
                                endDate={endDate}
                                current_view={this.state.current_view}
                                blockSelected={() => this.props.blockSlotSelected}
                                modalActionEvent={this.modalActionTrigered}
                                switchTypeHandler={this.switchTypeHandler}
                                servicesOptions={this.loadServicesOption(options)}
                                handleNavigation={this.handleNavigation}
                                requestStatus={this.state.request_fail}
                                selectedEventResources={this.state.selected_event_resources}
                                eventsSlotStatus={this.state.eventslot_processing}
                                bookingQuestions={bookingQuestions}
                            /> : <div> <div className="progress-spinner"> <i className={"fa fa-spinner fa-spin resource-arrow "} style={{ fontSize: 24 + "px" }}></i></div> </div>
                    }
                </div>

            </div>
        );
    }

}

function mapStateToProps(state) {

    return {
        servicesSlots: resourceSelector.getServicesSlots(state),
        selectedServiceSlot: resourceSelector.getSelectedServiceSlot(state),
        service: resourceSelector.getService(state),
        basket: checkoutSelector.getBasket(state),
        basketQueue: resourceSelector.getBasketQueue(state),
        blockSlotSelected: resourceSelector.getBlockSlotSelected(state),
        selectedEventsSlots: resourceSelector.getSelectedEventsSlots(state),
        bookingQuestions: resourceSelector.getBookingQuestions(state),
        resourceEvents: resourceSelector.getResourceEvents(state),
        fromRedirect: resourceSelector.getActionRedirect(state)
    };
}
export default withRouter(connect(mapStateToProps)(MonthScreen));