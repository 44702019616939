//import _ from "lodash";
import * as types from "./actionTypes";
import Immutable from "seamless-immutable";

const initialState = Immutable({
  basket: { items: [] },
  bookingCompleted: false,
  bookingDetails: [],
  sendNotification: "",
  itemSelected: {},
  chargeType: "",
  financeManagerNotification: "",
  houseManagerNotification: "",
  actionStatus: "",
  actionType: "",
  actionMessage: ""
});

export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case types.INITIAL_STATE:
      return state.merge({
        basket: undefined,
        bookingCompleted: false,
        bookingDetails: []
      });
    case types.ITEM_ADDED_TO_BASKET:
      return state.merge({
        basket: action.basket
      });
    case types.BASKET_LOADED_FROM_CACHE:
      return state.merge({
        basket: action.basket
      });
    case types.ITEM_REMOVED_FROM_BASKET:
      return state.merge({
        basket: action.basket
      });
    case types.BOOKING_COMPLETED:
      return state.merge({
        bookingDetails: action.details,
        bookingCompleted: true
      });
    case types.NOTIFICATION_SENT_SUCCESS:
      return state.merge({
        sendNotification: action.sendNotification
      });
    case types.FM_NOTIFICATION_SENT_SUCCESS:
      return state.merge({
        financeManagerNotification: action.sendNotification
      });
    case types.HM_NOTIFICATION_SENT_SUCCESS:
      return state.merge({
        houseManagerNotification: action.sendNotification
      });
    case types.ITEM_SELECTED:
      return state.merge({
        itemSelected: action.item
      });
    case types.CHARGE_TYPE_SET:
      return state.merge({
        chargeType: action.chargetype
      });
    case types.ACTION_SUCCESS:
      return state.merge({
        actionStatus: action.status
      });
    case types.ACTION_TYPE:
      return state.merge({
        actionType: action.transType
      });
    case types.ACTION_MESSAGE_SET:
      return state.merge({
        actionMessage: action.message
      });
    default:
      return state;
  }
}

export function getBasket(state) {
  return state.checkout.basket;
}

export function getBookingStatus(state) {
  return state.checkout.bookingCompleted;
}

export function getBookingDetails(state) {
  return state.checkout.bookingDetails;
}
export function getChargeType(state) {
  return state.checkout.chargeType;
}

export function getActionType(state) {
  return state.checkout.actionType;
}
export function getActionStatus(state) {
  return state.checkout.actionStatus;
}
export function getActionMessage(state) {
  return state.checkout.actionMessage;
}