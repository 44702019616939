import React, { Component } from "react";
import { connect } from "react-redux";
import * as authActions from "../store/auth/actions";
import * as authSelectors from "../store/auth/reducer";
import * as settingActions from "../store/ConciergeSetting/actions";
import * as settingSelector from "../store/ConciergeSetting/reducer";
import HeaderNavigation from "../components/HeaderNavigation/HeaderNavigation";
import FooterNavigation from "../components/FooterNavigation/FooterNavigation";
import Button from "../components/Button/Button";
import autoBind from "react-autobind";
import LoginForm from "../components/LoginForm/LoginForm";
import {withRouter} from 'react-router-dom';

import * as cryptoUtil from "../utility/cryptoUtility";

import "../sass/static/logout.css";
import "../sass/static/newPassword.css";


class PasswordRecoveryNewPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      guid: "",
      valid: false,
      error: false,

      resetFormSuccesssfull: false
    };

    autoBind(this);
    this.props.dispatch(settingActions.getGlobalSetting());
  }

  componentDidMount() {
    if (this.props.isLoggedIn === true) {
      this.props.history.push("/");
    }
    this.setState({
      email: this.props.location.query.email,
      guid: this.props.location.query.guid
    });

    let data = {
      email: this.props.location.query.email,
      tokenid: this.props.location.query.guid
    };
    this.props.dispatch(authActions.verifyRequest(data), success => { });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.passwordReset !== nextProps.passwordReset) {
      this.setState({
        resetFormSuccesssfull: nextProps.passwordReset
      });
    }
    if (this.props.validRequest !== nextProps.validRequest) {
      this.setState({
        valid: nextProps.validRequest
      });
    }
  }
  handleEmailChange(e) {
    // this.props.dispatch(authActions.setEmail(e.target.value));
  }

  handlePasswordChange(e) {
    e.target.value.length < 8
      ? this.setState({ error: true })
      : this.setState({ error: false });

    this.props.dispatch(authActions.setPassword(e.target.value));
  }

  handleSubmit(e) {
    e.preventDefault();

    const { email, guid } = this.state;

    const data = {
      email: cryptoUtil.encryptAES(email),
      tokenid: cryptoUtil.encryptAES(guid),
      password: cryptoUtil.encryptAES(this.props.password)
    };

    this.props.dispatch(authActions.resetpassword(data), success => {
      if (success) {
        this.setState({
          resetFormSuccesssfull: success
        });
      }
    });
  }
  render() {
    const { resetFormSuccesssfull, valid } = this.state;
    const { settings } = this.props;
    let bgImage = "";
    if (settings) {
      bgImage = settings.mainBackgroundImage;
    }
    const bgStyle = {
      backgroundImage: "url('" + bgImage + "')",
      backgroundRepeatY: "no-repeat",
      backgroundSize: "cover"
    };

    return (
      <div className="loginScreen newPassword">
        <HeaderNavigation closed={true} isLoggedIn={false} setting={settings} />

        <div className="loginWrap" style={bgStyle}>
          <h2 className="loginScreen">Password Recovery</h2>
        </div>

        <div className="white-background">
          <div className="content-wrapper">
            {valid === false ? (
              <div className={"validation-messages"}>
                <p className="message type-error">
                  Oops, unable to verify your request
                </p>
                <div className="align-center double-margin-top">
                  <Button
                    handleClick={() => {
                      this.props.history.push("/forgotpassword");
                    }}
                    text="Resend Email"
                    size="medium"
                  />
                </div>
              </div>
            ) : (
                <div>
                  {resetFormSuccesssfull === false && (
                    <LoginForm
                      handleEmailChange={this.handleEmailChange}
                      handlePasswordChange={this.handlePasswordChange}
                      password={this.props.password}
                      username={this.props.password2}
                      handleSubmit={this.handleSubmit}
                      actionSuccess={this.state.resetFormSuccesssfull}
                      mode={"newPwd"}
                    />
                  )}

                  {resetFormSuccesssfull === true && (
                    <div>
                      <div className="validation-messages">
                        <ul>
                          <li className="type-success">
                            Your password has been reset successfully
                        </li>
                        </ul>
                      </div>
                      <div className="align-center double-margin-top">
                        <Button
                          handleClick={() => {
                            this.props.history.push("/");
                          }}
                          text="Go to Login"
                          size="medium"
                        />
                      </div>
                    </div>
                  )}
                </div>
              )}
          </div>

          <FooterNavigation />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isLoggedIn: authSelectors.getIsLoggedIn(state),
    password: authSelectors.getPassword(state),
    passwordReset: authSelectors.passwordReset(state),
    validRequest: authSelectors.emailVerifyStatus(state),
    settings: settingSelector.loadGlobalSetting(state)
  };
}

export default withRouter(connect(mapStateToProps)(PasswordRecoveryNewPassword));
