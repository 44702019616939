import React from "react";
//import { Link } from "react-router";
import { Link } from 'react-router-dom';

import _ from "lodash";
import { createBrowserHistory } from 'history';
import "../../sass/components/HeaderNavigation/HeaderNavigation.css";

let history = createBrowserHistory();
function DropDownMenu(props) {
  /**Preload module locations, this would be loaded as a store/config component */
  // const menuLocations = [
  //   { title: "Parcels", url: "/concierge/parcel/checkin" },
  //   { title: "Keys", url: "" },
  //   { title: "Residents", url: "/concierge/residents/addnew" },
  //   { title: "Visitors", url: "" }
  // ];

  function menuClick(menu) {
    props.reset(true);
   
    history.push({
      pathname: "/",
      state: { module: menu }
    });
  }

  function menuTo(menu){
    props.reset(true);

    return{
      pathname : "/",
      state : {module : menu}
    
    };
  }
  return (
    <div className="nav-wrapper">
      <nav id="subNav" className={`${props.sticky} ${props.visible}`}>
        <ul>
          {_.map(props.moduleList, (module, index) => {

            return (
              <li key={index}>
                <Link
                  to={{
                    pathname : "/",
                    state : {module : module.title}
                  }}
                  onClick={e => menuClick(module.title)}
                  style={{ cursor: "pointer" }}
                >
                  {module.title}
                </Link>
              </li>
            );
          })}
        </ul>
      </nav>
    </div>
  );
}

export default DropDownMenu;
