import * as types from './actionTypes';
import KeyServices from '../../services/KeyServices';

export function getAllKeys(callback = () => {}) {
	return async (dispatch, getState) => {
		try {
			const keys = await KeyServices.getKeys();
			dispatch({ type: types.KEYS_LOADED_SUCCESS, keys });
			callback(true);
		} catch (error) {
			callback(false);
			dispatch({ type: types.KEYS_LOADED_FAIL, error });
			console.error(error);
		}
	};
}

export function getKeyStatuses(callback = () => {}) {
	return async (dispatch, getState) => {
		try {
			const statuses = await KeyServices.getKeyStatuses();
			dispatch({ type: types.KEYS_STATUSES_LOADED_SUCCESS, statuses });
			callback(true);
		} catch (error) {
			callback(false);
			dispatch({ type: types.KEYS_STATUSES_LOADED_FAIL, error });
			console.error(error);
		}
	};
}

export function assignKey(data, callback = () => {}) {
	return async (dispatch, getState) => {
		try {
			const result = await KeyServices.logKey(data);
			dispatch({ type: types.ASSIGN_KEY_SUCCESS, response: result });
			callback(true);
		} catch (error) {
			callback(false);
			dispatch({ type: types.ASSIGN_KEY_FAIL });
			console.error(error);
		}
	};
}

export function getKeyLogByKeyStatus(data, callback = () => {}) {
	return async (dispatch, getState) => {
		try {
			const log = await KeyServices.getKeylogKeyStatus(data);

			dispatch({ type: types.KEYS_LOG_LOADED_SUCCESS, keysLog: log });
			callback(true);
		} catch (error) {
			dispatch({ type: types.KEYS_LOG_LOADED_FAIL, status: false });
		}
	};
}

// export function getOverduKeyLogs(data, callback = () => {}) {
//   return async (dispatch, getState) => {
//     try {
//       let keyLogs = [];
//       if (data && data.length > 0) {
//         for (let i = 0; i < data.length; i++) {
//           var response = await KeyServices.getDueKeyLogByKeyId(data[i].keyid);
//           keyLogs.push(response);
//         }

//         dispatch({ type: types.OVERDUE_KEY_LOG_LOAD_SUCCESS, logs: keyLogs });
//         callback(true);
//       }
//     } catch (error) {
//       console.error(error);
//     }
//   };
// }

export function returnKey(data, callback = () => {}) {
	return async (dispatch, getState) => {
		try {
			const result = await KeyServices.returnKey(data);
			dispatch({ type: types.RETURN_KEY_SUCCESS, response: result });
			callback(true);
		} catch (error) {
			callback(false);
			dispatch({ type: types.RETURN_KEY_FAIL, data });
			console.error(error);
		}
	};
}

export function loadOverdueKeyLogs(data, callback = () => {}) {
	return async (dispatch, getState) => {
		try {
			const logs = await KeyServices.getKyLogsbyKeyIds(data);
			dispatch({ type: types.OVERDUE_KEY_LOG_LOAD_SUCCESS, logs });
			callback(true);
		} catch (error) {
			callback(false);
			console.error(error);
		}
	};
}

export function loadKeyLogList(lastRecord, keyId, callback = () => {}) {
	return async (dispatch, getState) => {
		try {
			const loglist = await KeyServices.getKeyLogList(lastRecord, keyId);
			dispatch({ type: types.KEY_LOG_LIST_LOAD_SUCCESS, loglist });
			callback(true);
		} catch (error) {
			callback(false);
			console.error(error);
		}
	};
}

export function setSelectedKey(id, callback = () => {}) {
	return async (dispatch, getState) => {
		try {
			dispatch({ type: types.SET_KEY_SUCCESS, key: id });
			callback(true);
		} catch (error) {
			callback(false);
			console.error(error);
		}
	};
}

export function updateKeyStatus(data, callback = () => {}) {
	return async (dispatch, getState) => {
		try {
			const response = await KeyServices.updateKey(data);
			dispatch({ type: types.KEY_STATUS_UPDATE_SUCCESS, response });
			callback(true);
		} catch (error) {
			dispatch({ type: types.KEY_STATUS_UPDATE_FAIL });
			callback(false);
			console.error(error);
		}
	};
}

export function resetAction(reset) {
	return async (dispatch, getState) => {
		try {
			dispatch({ type: types.RESET_ACTION_STATUS });
		} catch (error) {
			console.error(error);
		}
	};
}

export function sendKeyAssignedNotification(data, callback = () => {}) {
	return async (dispatch, getState) => {
		try {
			const response = await KeyServices.notifyKeyassigned(data);

			dispatch({ type: types.NOTIFY_KEY_ASSIGNED_SUCCESS, response });
			callback(true);
		} catch (error) {
			callback(false);
			console.error(error);
		}
	};
}

export function sendKeyReturnNotification(data, callback = () => {}) {
	return async (dispatch, getState) => {
		try {
			const response = await KeyServices.notifyKeyReturned(data);
			if (response === true) {
				dispatch({ type: types.NOTIFY_KEY_RETURNED_SUCCESS, response });
				callback(true);
			} else {
				dispatch({ type: types.NOTIFY_KEY_RETURNED_FAIL, response });
				callback(false);
			}
		} catch (error) {
			callback(false);
			console.error(error);
		}
	};
}
