import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

import autoBind from 'react-autobind';
import moment from 'moment';
import dateFormat from 'dateformat';
import * as configHelper from '../../helpers/configHelper';
import * as helpers from '../../helpers/stringHelper';
/**
 * Display list of Parcels with properties Tenant Name, Address, Sender and Expected Arrival
 * 
 */
const PortalTypes = configHelper.PortalTypes;
class ParcelsListComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			IsParcelSelected: false,
			selectedItem: undefined,
			portalType:
				this.props.portalSettings && !_.isUndefined(this.props.portalSettings.portalType)
					? this.props.portalSettings.portalType
					: '',
			disabledItem: false,
			expectedParcelsIndexInState: [],
			memberId: undefined,
			isParcelExpectedSearched: this.props.isParcelExpectedSearched,
			selectedParcels: []
		};

		autoBind(this);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		//Change trigere fro selected row, clear selection on deselct trigger recieved
		if (this.props.isParcelExpectedSearched !== nextProps.isParcelExpectedSearched) {
			if (this.props.isParcelExpectedSearched) {
				this.setState({
					expectedParcelsIndexInState: [],
					memberId: undefined,
					selectedParcels: []
				});
			}
			this.props.setParcelSearched(false);
			this.props.actionClick(false);
		}

		if (this.props.selectedParcels !== nextProps.selectedParcels) {
			if (nextProps.selectedParcels.length > 0) {
				this.setState({
					selectedParcels: nextProps.selectedParcels
				});
			} else {
				this.setState({
					expectedParcelsIndexInState: [],
					memberId: undefined,
					selectedParcels: []
				});
				this.props.actionClick(false);
			}
		}
	}

	UNSAFE_componentWillMount() {}

	UNSAFE_componentWillUpdate(nextProps, nextState) {
		if (this.props.isParcelListChanged !== nextProps.isParcelListChanged) {
			if (this.props.isParcelListChanged) {
				this.setState({
					expectedParcelsIndexInState: [],
					memberId: undefined,
					selectedParcels: []
				});
			}
			this.props.setParcelsUpdated && this.props.setParcelsUpdated(false);
		}
	}

	//not sure this is needed in here, component would react to data passed into it
	//condition already specified in page using it
	getExpectedParcelCount() {
		const { data } = this.props;
		let expectedCount = 0;
		!_.isUndefined(data) &&
			data &&
			data.length > 0 &&
			_.map(data.slice(0, this.state.limit), (expectedParcel, index) => {
				if (
					expectedParcel.status.status &&
					expectedParcel.status.status.toLowerCase() === configHelper.ParcelStatuses.Expected.toLowerCase() &&
					dateFormat(expectedParcel.expecteDate, 'dd/mm/yyyy') === dateFormat(moment().format(), 'dd/mm/yyyy')
				) {
					expectedCount++;
				}
			});

		return expectedCount;
	}

	handleParcelSelected(rowIndex, expectedParcel) {
		const { selectedParcels } = this.state;

		let selectedList = selectedParcels;

		//check current selected list, if selected row item is present
		let parcelItem = _.find(selectedParcels, { id: expectedParcel.id });

		//selected item is present
		if (!_.isUndefined(parcelItem)) {
			//remove selected item from list (deselection mode)
			selectedList = _.filter(selectedParcels, (item) => item.id !== parcelItem.id);

			/**after deselection is any item remaining, if not reset memberId tag to default  */
			if (selectedList.length === 0) {
				this.setState({
					memberId: undefined
				});
			}
		} else if (_.isUndefined(parcelItem)) {
			/**current selected is not in main selection list and if its the first set as the unique member */
			if (selectedParcels.length === 0) {
				selectedList.push(expectedParcel);
				this.setState({
					memberId: expectedParcel.recipient.memberId
				});
			} else {
				/**current selected not in list befor adding to the selection list, make sure it belongs to set member */
				if (
					selectedParcels &&
					selectedParcels.length > 0 &&
					selectedParcels[0].recipient.memberId === expectedParcel.recipient.memberId
				) {
					selectedList.push(expectedParcel);
				}
			}
		}

		/**compile and set the reformed selected list  */
		this.setState({
			selectedParcels: selectedList
		});

		this.props.actionClick(selectedList.length > 0);
		this.props.setSelectedParcel(expectedParcel, selectedList);

		// if (this.state.expectedParcelsIndexInState[rowIndex] === rowIndex) {
		// 	const matchedParcels = _.filter(this.state.selectedParcels, (parcel) => {
		// 		return parcel.id !== expectedParcel.id;
		// 	});

		// 	this.setState({ selectedParcels: matchedParcels }, function() {
		// 		this.props.setSelectedParcel(expectedParcel, this.state.selectedParcels);
		// 	});

		// 	let tempArray = this.state.expectedParcelsIndexInState.slice();
		// 	tempArray[rowIndex] = 'random element';
		// 	this.setState({
		// 		expectedParcelsIndexInState: tempArray
		// 	});
		// 	var count = tempArray.reduce(function(n, val) {
		// 		return n + (val !== 'random element');
		// 	}, 0);

		// 	if (count === 0) {
		// 		this.setState({
		// 			memberId: undefined
		// 		});

		// 		this.props.actionClick(false);
		// 	}
		// } else {
		// 	if (this.state.memberId === expectedParcel.recipient.memberId || this.state.memberId === undefined) {
		// 		let tempArray = this.state.expectedParcelsIndexInState.slice();
		// 		tempArray[rowIndex] = rowIndex;
		// 		this.setState({ expectedParcelsIndexInState: tempArray });

		// 		let selectedParcels = this.state.selectedParcels;
		// 		selectedParcels.push(expectedParcel);
		// 		this.setState({
		// 			memberId: expectedParcel.recipient.memberId,
		// 			selectedParcels: selectedParcels
		// 		});

		// 		this.props.actionClick(true);
		// 		this.props.setSelectedParcel(expectedParcel, this.state.selectedParcels);
		// 	}
		// }
	}

	render() {
		const { data, portalType, noParcelFoundMsg } = this.props;

		if (data && data.length > 0) {
			return (
				<div>
					<div className="list-text">
						{' '}
						Select items from the list then press ‘Register Parcel’ or press ‘Unexpected Parcel’
					</div>
					<div className="list-display-hedaing-row">
						<div className="tenant-name">Tenant Name</div>
						<div className="address">{portalType === PortalTypes.Commercial ? 'Company' : 'Address'}</div>
						<div className="sender">Sender</div>
						<div className="expected-arrival">Expected Arrival</div>
					</div>
					{data.length > 0 &&
						_.map(data, (expectedParcel, rowIndex) => {
							if (
								dateFormat(expectedParcel.expecteDate, 'dd/mm/yyyy') ===
								dateFormat(moment().format(), 'dd/mm/yyyy')
							) {
								return (
									<div
										className={`${'list-display-detail-row '}${!_.isUndefined(
											_.find(this.state.selectedParcels, { id: expectedParcel.id })
										)
											? 'selected-with-border'
											: ''} ${this.state.memberId !== expectedParcel.recipient.memberId &&
										!_.isUndefined(this.state.memberId)
											? 'custom-disable'
											: ''} `}
										key={rowIndex}
										onClick={() => this.handleParcelSelected(rowIndex, expectedParcel)}
									>
										<div className="tenant-name">
											{portalType === PortalTypes.Commercial ? expectedParcel.tenant.firstName +
												' ' +
												expectedParcel.tenant.lastName !=
											null ? (expectedParcel.tenant.firstName +
												' ' +
												expectedParcel.tenant.lastName).length > 24 ? (
												(expectedParcel.tenant.firstName +
													' ' +
													expectedParcel.tenant.lastName).substring(0, 24) + '...'
											) : (
												expectedParcel.tenant.firstName + ' ' + expectedParcel.tenant.lastName
											) : (
												''
											) : expectedParcel.resident.firstName +
												' ' +
												expectedParcel.resident.lastName !=
											null ? (expectedParcel.resident.firstName +
												' ' +
												expectedParcel.resident.lastName).length > 24 ? (
												(expectedParcel.resident.firstName +
													' ' +
													expectedParcel.resident.lastName).substring(0, 24) + '...'
											) : (
												expectedParcel.resident.firstName +
												' ' +
												expectedParcel.resident.lastName
											) : (
												''
											)}
										</div>
										<div className="address">
											{portalType === PortalTypes.Commercial ? expectedParcel.tenant
												.companyName != null ? expectedParcel.tenant.companyName.length > 30 ? (
												expectedParcel.tenant.companyName.substring(0, 30) + '...'
											) : (
												expectedParcel.tenant.companyName
											) : (
												''
											) : (
												helpers.formatStringList(
													[
														expectedParcel.resident.addressLine1,
														expectedParcel.resident.addressLine2,
														expectedParcel.resident.addressLine3,
														expectedParcel.resident.addressLine4,
														expectedParcel.resident.addressLine5,
														expectedParcel.resident.addressLine6,
														expectedParcel.resident.postCode
													],
													23
												)
											)}
										</div>
										<div className="sender">
											{expectedParcel.name != null ? expectedParcel.name.length > 28 ? (
												expectedParcel.name.substring(0, 28) + '...'
											) : (
												expectedParcel.name
											) : (
												''
											)}
										</div>
										<div className="expected-arrival">
											{expectedParcel.expectedDate !== null ? (
												moment(expectedParcel.expectedDate).format('DD/MM/YYYY')
											) : (
												''
											)}
										</div>
									</div>
								);
							}
						})}
				</div>
			);
		} else {
			return (
				<div className="list-text">
					<span className="no-result-found">
						{/* No matching results found. Try looking for past and future parcels expected. */}
						{noParcelFoundMsg}
					</span>
				</div>
			);
		}
	}
}

ParcelsListComponent.propTypes = {
	/**The list of Parcels to be displayed */
	data: PropTypes.array,
	/** Function that is called for clicking on the icon */
	actionClick: PropTypes.func,

	/**Sets Portal type  */
	portalType: PropTypes.string,

	/**bool for If the Parcel is searched  */
	IsParcelExpectedSearched: PropTypes.bool,
	/**bool for If the Parcel list is changed  */
	isParcelListChanged: PropTypes.bool,

	/** list of selected parcels */
	selectedParcels: PropTypes.array
};

export default ParcelsListComponent;
