import React, { Component } from 'react';
import PropTypes from 'prop-types';

import autoBind from 'react-autobind';
import _ from 'lodash';
import ReactPaginate from 'react-paginate';

import '../../sass/components/MultiSelectOptions/index.css';

class ListItem extends Component {
	constructor(props) {
		super(props);
		this.state = {
			active: this.props.active,
			eventSelectionprocessing: undefined
			// maxLimit: false
		};

		autoBind(this);
	}

	render() {
		return (
			<a
				onClick={() => {
					this.setState((prevState) => {
						let newState = !prevState.active;
						let eventIDs = this.props.event_ids;

						eventIDs = [ ...eventIDs, this.props.event_id ];

						if (prevState.active) {
							eventIDs = eventIDs.filter((e) => e !== this.props.event_id);
						}

						this.props.handleClick(newState, this.props.value, this.props.event_id);
						this.props.onClick(eventIDs);

						return { active: newState };
					});
				}}
				className={`${this.props.status === true ? 'disabled ' : ''} ${!this.state.active && !this.props.active
					? ''
					: 'selected'}`}
			>
				{this.props.value}
			</a>
		);
	}
}

class MultiSelectComponent extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showList: false,
			value: [],
			event_id: [],
			showSpinner: this.props.data.length === 0 ? true : false,
			showDownList: _.isUndefined(this.props.processing) ? true : undefined,
			iconClass: 'fa-angle-down',
			currentPage: 0,
			dataList: [],
			currentList: []
		};

		autoBind(this);
	}

	UNSAFE_componentWillUpdate(nextProps, nextState) {
		if (this.props.data !== nextProps.data) {
			let options = _.map(nextProps.data, (item, index) => {
				return {
					label: item.label,
					value: item.id,
					key: index,
					_handleItemClick: this.handleItemClick,
					_event_ids: this.state.event_id
				};
			});

			let n = 13;
			let data = _.groupBy(options, (dataItem) => {
				return Math.floor(dataItem.key / n);
			});
			let dataList = _.toArray(data);
			this.setState({
				dataList: dataList
				//currentList: dataList.length > 0 ? dataList[0] : []
			});

			// if (nextProps.selectedOption && nextProps.selectedOption.length > 0) {
			// 	this.setState({
			// 		value : _.filter(_.map(nextProps.selectedOption, o => {
			// 			let obj = _.find(options, {value : o.id});
			// 				if(!_.isUndefined(obj))
			// 				{
			// 					return obj.label
			// 				}
			// 				return undefined
			// 		}), s => !_.isUndefined(s)),

			// 		event_id: _.map(nextProps.selectedOption, (val) => {
			// 			return val.id;
			// 		})
			// 	});
			// }
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			this.props.selectedOption !== prevProps.selectedOption &&
			this.props.selectedOption &&
			this.props.selectedOption.length > 0
		) {
			this.setState({
				value: _.filter(
					_.map(this.props.selectedOption, (o) => {
						let obj = _.find(this.props.data, { id: o.id });
						if (!_.isUndefined(obj)) {
							return obj.label;
						}
						return undefined;
					}),
					(s) => !_.isUndefined(s)
				),

				event_id: _.map(this.props.selectedOption, (val) => {
					return val.id;
				})
			});
		}
	}
	renderValue() {
		let { value } = this.state;
		if (!value.length) return this.props.placeHolder ? this.props.placeHolder : 'Select...';
		else return Array.from(new Set(value)).length + ' selected';
		// return value.join(', ')
	}
	toggleList() {
		let { showList } = this.state;
		if (!showList) {
			this.setState({
				iconClass: 'fa-angle-up'
			});
		} else {
			this.setState({
				iconClass: 'fa-angle-down'
			});
		}
		this.setState((prevState) => ({ showList: !prevState.showList }));
	}

	toggleSpiiner() {
		let { showList } = this.state;
		if (showList) {
			//var millisecondsToWait = 500;
			var CurruntContext = this;
			setTimeout(function() {
				// Whatever you want to do after the wait
				CurruntContext.setState((prevState) => ({ showUparrow: prevState.showList }));
				// CurruntContext.setState(prevState => ({ showSpinner: false }))
			}, 500);
		}
	}

	handleItemClick(active, val, evntid) {
		let { value, event_id } = this.state;

		let currentIdList = event_id;
		let currentValueList = value;

		if (active === true) {
			if (!_.includes(event_id, evntid)) {
				currentIdList = [ ...currentIdList, evntid ];
				currentValueList = [ ...currentValueList, val ];
			}
		} else {
			currentIdList = _.filter(event_id, (e) => e !== evntid);
			currentValueList = _.filter(currentValueList, (e) => e !== val);
		}

		this.setState({ event_id: currentIdList, value: currentValueList });
	}
	handlePagerChanged(page) {
		this.setState({
			currentPage: page.selected
		});
	}

	//helper method to get current page list item
	getCurrentPageList(index) {
		const { dataList } = this.state;

		return dataList[index];
	}
	isOnline(){
		
			return navigator.onLine ? true : false;
	}

	render() {
		const { handleSelectionChange, selectedOption, processing, eventSelectionprocessing } = this.props;
		const { value } = this.state;

		return (
			<div ref={(node) => (this.node = node)} className="select">
				<button
					onMouseUp={() => {
						this.toggleList();
					}}
					onClick={() => {
						/*this.toggleSpiiner()*/
					}}
					className="multiSelectButton"
				>
					<span className={`${'select_value'} ${value && value.length > 0 ? '' : ' Select-placeholder'}`}>
						{this.renderValue()}
					</span>

					{_.isUndefined(processing) ? (
						<i className={`${'fa '}${this.state.iconClass}${' fa-2 resource-arrow'}`} />
					) : this.props.data.length === 0 ? (
						<i
							className={
								'fa fa-spinner fa-spin resource-arrow ' + (this.props.processing !== true && 'hide')
							}
							style={{ fontSize: 24 + 'px' }}
						/>
					) : (
						<i className={`${'fa '}${this.state.iconClass}${' fa-2 resource-arrow'}`} />
					)}
				</button>
		
				<div className={'list'}>
					
					<div className={ this.isOnline() ? 'select_list ' +( !this.state.showList === false ? '' : 'hide'):'hide'}>
						{this.state.dataList &&
							_.map(this.state.dataList, (optionList, indx) => {
								
								return (
								
									<div key={indx} className={this.state.currentPage !== indx  ? 'hide' : ''}>
										{ _.isUndefined(eventSelectionprocessing) && _.map(optionList, (item, index) => {
											return (
												<ListItem
													value={item.label}
													key={index}
													event_id={item.value}
													handleClick={item._handleItemClick}
													onClick={(e) => {
														handleSelectionChange(e);
													}}
													event_ids={this.state.event_id}
													active={!_.isUndefined(_.find(selectedOption, { id: item.value }))}
													selectedOption={selectedOption}
													status={eventSelectionprocessing}
												/>
											);
										})}
									</div>
								);
							})}
					</div>

					<div id="react-paginate" className={!this.state.showList === false ? '' : 'hide'}>
						{this.state.dataList.length > 1 && (
							<ReactPaginate
								pageCount={this.state.dataList.length}
								marginPagesDisplayed={2}
								pageRangeDisplayed={5}
								onPageChange={(val) => this.handlePagerChanged(val)}
							/>
						)}
					</div>
				</div>
			</div>
		);
	}
}

MultiSelectComponent.propTypes = {
	serviceId: PropTypes.string,
	date: PropTypes.string,
	data: PropTypes.array,
	handleSelectionChange: PropTypes.func
};

export default MultiSelectComponent;
