import React, { Component } from "react";
import { connect } from "react-redux";
import autoBind from "react-autobind";

import {withRouter} from 'react-router-dom';
import * as $ from "jquery";

import "react-datepicker/dist/react-datepicker.css";
import "rc-time-picker/assets/index.css";
import _ from "lodash";
import Datepicker from "react-datepicker";

import moment from "moment";
import dateFormat from "dateformat";

import FeatureArea from "../../../components/FeatureArea/FeatureArea";
import FooterNavigation from "../../../components/FooterNavigation/FooterNavigation";

import TenantAddrTypeAutoSuggest from "../../../components/TenantAutoSuggest/TenantAddrTypeAutoSuggest";
import TenantCompanyTypeAutoSuggest from "../../../components/TenantAutoSuggest/TenantCompanyTypeAutoSuggest";

import Button from "../../../components/Button/Button";
import ButtonGroup from "../../../components/Button/ButtonGroup";
import InputComponent from "../../../components/Input/Input";
import DetailList from "../../../components/DetailList/DetailList";

import * as serviceModulesActions from "../../../store/ServiceModules/actions";
import * as settingSelector from "../../../store/ConciergeSetting/reducer";

import * as moduleSelectors from "../../../store/ServiceModules/reducer";
import * as tenantActions from "../../../store/Tenants/actions";
import * as tenantSelector from "../../../store/Tenants/reducer";
import * as meetingAction from "../../../store/Meetings/actions";
import * as meetingSelector from "../../../store/Meetings/reducer";

import * as visitorSelector from "../../../store/Visitors/reducer";

import "../../../sass/containers/visitors/index.css";

function generateOptions(length, excludedOptions) {
  const arr = [];
  for (let value = 0; value < length; value++) {
    if (excludedOptions.indexOf(value) < 0) {
      arr.push(value);
    }
  }
  return arr;
}
function disabledMinutes(h) {
  var min_arr = [0];
  for (let value = 0; value < 60; value++) {
    let curr_min = value + 5;
    if (curr_min < 60 && (curr_min % 5 === 0)) {
      min_arr.push(curr_min);
    }
  }
  switch (h) {
    default:
      return generateOptions(60, min_arr);
  }
}

const PortalTypes = serviceModulesActions.getPortalTypes();

class ManagementScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      name: "",
      suggestions: [],
      tenantId: "",
      tenantCompany: "",
      tenantName: "",
      tenantAddress: "",
      tenantType: "",
      startDate: moment(),
      timeFrom: moment(),
      timeTo: moment().add(1, 'hours'),
      now: moment().format("LLLL"),
      addVisitorFormUsed: false,
      visitorName: !_.isUndefined(this.props.location.state) ? this.props.location.state.visitorData.visitorName : "",
      visitorCompany: !_.isUndefined(this.props.location.state) ? this.props.location.state.visitorData.visitorCompany : "",
      visitorEmail: "",
      rows: [0],
      type: "Visitors",
      specialInstruction: "",
      commercial: false,
      portalType: this.props.setting && !_.isUndefined(this.props.setting.portalType) ? this.props.setting.portalType : ""
    };

    this.addVisitorValidation = null;
    // this.addDatepickerValidation = null;
    autoBind(this);
  }

  componentDidMount() {
    const { specialInstruction } = this.state;

    /** Just to get it all working */

    this.addValidation();
    this.props.dispatch(tenantActions.getAllTenants());

    if (specialInstruction === "" && this.props.instruction) {
      this.setState({
        specialInstruction: this.props.instruction
      });
    }

  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.setting !== this.props.setting && !_.isUndefined(this.props.setting)) {
      this.setState({
        portalType: this.props.setting.portalType
      });
    }
  }

  componentWillUnmount() {
    this.addVisitorValidation.destroy();
    // this.addDatepickerValidation.destroy();
  }
  addValidation() {
    this.addVisitorValidation = $(this.refs.validate).parsley({
      classHandler: function (el) {
        return el.$element.closest("div:not(.input-wrapper):not(.Select)");
      }
    });

    // this.addDatepickerValidation = $(this.refs.validate).parsley({
    //   classHandler: function(el) {
    //     return el.$element.closest("input");
    //   }
    // });

    // this.addVisitorValidation = $("pickeryDate").parsley({
    //   classHandler: function(el) {
    //     return el.$element.closest("input");
    //   }
    // });
  }
  validate(fromSubmit = false) {
    this.addVisitorValidation.validate({ force: true });
    // this.addDatepickerValidation.validate({ force: true });
    const valid = this.addVisitorValidation.isValid();
    // this.addVisitorValidation.isValid() &&
    // this.addDatepickerValidation.isValid();

    this.setState({
      addVisitorFormValid: valid,
      addVisitorFormUsed: true,
      rows: this.state.rows.concat([this.state.rows.length])
    });

    if (valid === true && fromSubmit === true) {
      this.formReset();
      const {

        timeFrom,
        startDate,
        timeTo,
        tenantId,
        visitorName,
        visitorCompany,
        tenantCompany,
        tenantName,
        tenantType,
        visitorEmail,
        rows,
        specialInstruction
      } = this.state;

      const timeFromExpected = dateFormat(timeFrom, "isoTime");
      const timeToExpected = dateFormat(timeTo, "isoTime");
      const dateExpected = dateFormat(startDate._d, "isoDate");

      let dueDT = moment(dateExpected + " " + timeFromExpected);
      let toDT = moment(dateExpected + " " + timeToExpected);

      var data = {
        tenantId: tenantId,
        tenantName: tenantName,
        tenantCompany: tenantCompany,
        tenantType: tenantType,
        name: visitorName,
        companyName: visitorCompany,
        dueDate: dueDT.format("YYYY-MM-DD HH:mm:ss"),
        endDate: toDT.format("YYYY-MM-DD HH:mm:ss"),
        status: "notified",
        email: visitorEmail,
        specialInstruction: specialInstruction,
        index: rows[rows.length - 1]
      };

      this.props.dispatch(meetingAction.addMeetingToQueue([data]));
    }
  }
  formReset() {
    // Doesnt seem to be working....
    // this.addParcelValidation.reset();

    this.setState({
      tenantAddress: ""
    });

    this.refs["tenant-auto-suggest"].getWrappedInstance().reset();
    $(this.refs.validate)
      .get(0)
      .reset();

    this.addVisitorValidation.destroy();
    //this.addDatePickerValidation.destroy();

    // Reset all the select input components to the placeholder value
    for (let key in this.refs) {
      if (key.indexOf("formSelect") === 0) {
        this.refs[key].reset();
      }
    }

    setTimeout(() => {
      this.addValidation();
    }, 0);
  }

  handleInputChange(e) {
    const field = e.target.name;
    const value = e.target.value;
    this.setState({
      [field]: value
    });
  }

  handleSubmit(e) {
    const { specialInstruction } = this.state;

    if (specialInstruction) {
      this.props.dispatch(
        meetingAction.addSpectialInstruction(specialInstruction)
      );
    }

    this.props.history.push("/concierge/visitor-management-review");
  }

  handleDateChange(date) {
    this.setState({
      startDate: date
    });
  }
  handleTimeFromChange(val) {
    this.setState({
      timeFrom: val,
      timeTo: moment(val).add(1, 'hours')
    });
  }
  handleTimeToChange(val) {
    this.setState({
      timeTo: val
    });
  }
  removeFromQueue(index) {
    //index
    this.props.dispatch(meetingAction.removeMeetingFromQueue(index));
  }

  disabledHours() {
    const { timeFrom } = this.state;
    let time = [];
    for (let i = 0; i < 24; i++) {
      time.push(i);
    }
    if (!_.isUndefined(timeFrom) || timeFrom !== null)
      return _.filter(time, t => {
        if (timeFrom !== null) {
          return t < timeFrom.hour();
        } else {
          return t;
        }
      });
  }


  render() {

    const {
      tenantCompany,
      startDate,
      specialInstruction,
      timeFrom,
      timeTo,
      // tenantAddress
    } = this.state;
    const currentModule = _.find(
      this.props.modules,
      o => o.title === "Visitors"
    );


    const overlayColor = currentModule && currentModule.imageOverlayTextColour;
    const visitor_style = {
      color: overlayColor,
      fontSize: "30px",
      textTransform: "capitalize"
    }

    return (
      <div>
        <div className="container type-concierge-desktop management">
          <FeatureArea
            type={this.state.type}
            areaTitle="Visitors"
            areaTagline="Please enter information below"
            pageHeader="Visitor Management"
            overlayColor={currentModule && currentModule.imageOverlayTextColour}
            landing={false}

          />

          <form
            id="form-content"
            className="content-wrapper"
            ref="validate"
            onSubmit={e => {
              e.preventDefault();
            }}
            data-parsley-errors-container="#validation-messages"
            data-parsley-focus="none"
            data-parsley-validate-if-empty
          //data-parsley-inputs
          >

            {this.state.portalType === PortalTypes.Commercial ?
              <div className="names">
                <div className="tenant_elements">
                  <TenantCompanyTypeAutoSuggest
                    ref="tenant-auto-suggest"
                    disabled={
                      this.props.meetings.length > 0 && this.state.tenantId !== ""
                    }
                    value={this.state.tenant}
                    clearableIfValid={false}
                    onSelected={data => {
                      this.setState({
                        tenantId: data.memberId,
                        tenantName: data.tenant,
                        tenantCompany: data.companyName,
                        tenantType: data.memberType
                      });

                      /* Timeout added otherwise validation not fired because autosuggest still updating DOM */
                      setTimeout(() => {
                        this.state.addVisitorFormUsed && this.validate();
                      }, 0);
                    }}
                  />
                </div>
                <br />
                <br />
                <div className="company_elements">
                  <InputComponent
                    type="text"
                    name="tenantCompany"
                    label="Company"
                    placeHolder="Company"
                    handleChange={this.handleInputChange}
                    value={tenantCompany}
                    parsley={{
                      //"data-parsley-pattern": "/^[A-Za-z ]+$/", // alpha chars only https://stackoverflow.com/questions/23415795/
                      //"data-parsley-minlength": 2,
                      "data-parsley-minwords": "1",
                      "data-parsley-required": true,
                      "data-parsley-error-message":
                        "Please enter a valid company name"
                    }}
                    disabled={
                      this.props.meetings.length > 0 && this.state.tenantId !== ""
                    }
                  />
                </div>
              </div>
              :
              <div className="name">
                <div className="tenant_elements">
                  <TenantAddrTypeAutoSuggest
                    ref="tenant-auto-suggest"
                    labelText="Tenant"
                    placeHolder="Full Name"
                    clearableIfValid={false}
                    onSelected={data => {

                      this.setState({
                        tenantId: data.memberId,
                        tenantAddress: data.address,
                        tenantName: data.tenant,

                      });

                      setTimeout(() => {
                        this.state.addVisitorFormUsed && this.validate();
                      }, 0);
                    }}
                    defaultText={dataVal => { }}
                    disabled={
                      this.props.meetings.length > 0 && this.state.tenantId !== ""
                    }
                  />
                </div>
                {
                  /** <div className="company_elements">
                   <InputComponent
                     type="text"
                     name="tenantAddress"
                     label="Address"
                     placeHolder="Address"
                     handleChange={this.handleInputChange}
                     value={tenantAddress}
                     parsley={{
                       //"data-parsley-pattern": "/^[A-Za-z ]+$/", // alpha chars only https://stackoverflow.com/questions/23415795/
                       //"data-parsley-minlength": 2,
                       // "data-parsley-minwords": "1",
                       // "data-parsley-required": true,
                       // "data-parsley-error-message":
                       //   "Please enter a valid company name"
                     }}
                     disabled={
                       this.props.meetings.length > 0 && this.state.tenantId !== ""
                     }
                   />
                 </div>
                   */
                }
              </div>
            }
            <div className="date_elements">
              <div className="dates">
                <label className="dateLabel">Date</label>
                <Datepicker
                  ref="datePickery"
                  selected={startDate}
                  minDate={moment()}
                  onChange={this.handleDateChange}
                  dateFormat="DD/MM/YYYY"
                  placeholder="Date"
                  value={
                    _.isUndefined(startDate) || startDate === null
                      ? moment().format("DD/MM/YYYY")
                      : startDate
                  }
                  name="datePick"
                // {...this.props.parsley}
                />
              </div>
              <div className="times">
                <div>
                  {
                    <InputComponent
                      id="timeFrom"
                      type="timePicker"
                      defaultValue={startDate}
                      handleTimeChange={this.handleTimeFromChange}
                      name="timeFrom"
                      placeholder="From"
                      disabledMinutes={disabledMinutes}
                      label="From"
                      dateValue={
                        _.isUndefined(timeFrom) || timeFrom === null
                          ? moment()
                          : timeFrom
                      }
                      parsley={{
                        "data-parsley-pattern": "/^[A-Za-z ]+$/",
                        "data-parsley-minlength": 5,
                        "data-parsley-required": true,
                        "data-parsley-minwords": "1",
                        "data-parsley-error-message": "Please enter a valid time"
                      }}
                    />

                  }
                </div>
                <div>
                  <InputComponent
                    type="timePicker"
                    defaultValue={startDate}
                    handleTimeChange={this.handleTimeToChange}
                    name="timeTo"
                    placeholder="To"
                    disabledMinutes={disabledMinutes}
                    label="To"
                    disabledHours={this.disabledHours}
                    dateValue={
                      _.isUndefined(timeTo) ||
                        timeTo === null ||
                        timeTo < timeFrom
                        ? timeFrom
                        : timeTo
                    }
                    parsley={{
                      "data-parsley-pattern": "/^[A-Za-z ]+$/",
                      "data-parsley-minlength": 1,
                      "data-parsley-required": true,
                      "data-parsley-minwords": "1",
                      "data-parsley-gte": 100000000000,
                      "data-parsley-error-message": "Please enter a valid time"
                    }}
                  />

                </div>
              </div>
            </div>
            <div className="visitor_elements">
              <label>Visitor Name</label>
              <InputComponent
                type="text"
                name="visitorName"
                placeHolder="Visitor's Name"
                handleChange={this.handleInputChange}
                parsley={{
                  "data-parsley-pattern": "/^[a-z ,.'-]+$/i", // alpha chars only https://stackoverflow.com/questions/23415795/
                  "data-parsley-minlength": 2,
                  "data-parsley-required": true,
                  "data-parsley-minwords": "2",
                  "data-parsley-error-message":
                    "Please enter a valid Visitor's name"
                }}
              />
            </div>



            <div className="company_elements">

              <label>Company From</label>
              <InputComponent
                type="text"
                name="visitorCompany"
                placeHolder="Visitor's Company"
                value={this.state.visitorCompany}
                handleChange={this.handleInputChange}
                parsley={{
                  "data-parsley-pattern": "/^[A-Za-z'!&(), ]+$/", // alpha chars only https://stackoverflow.com/questions/23415795/
                  "data-parsley-minwords": "1",
                  "data-parsley-required": true,
                  "data-parsley-error-message":
                    "Please enter a valid visitor company name"
                }}
              />
            </div>

            <div className="visitorEmail">
              <label>Visitor Email</label>
              <InputComponent
                type="text"
                name="visitorEmail"
                placeHolder="Visitor's Email"
                handleChange={this.handleInputChange}
              // parsley={{
              //   "data-parsley-type": "email",
              //   "data-parsley-validate-if-empty": false,
              //   "data-parsley-error-message": "Please enter a valid email"
              // }}
              />
            </div>
            <br />

            <div
              className={
                "validation-messages" +
                (this.state.addVisitorFormValid ? " valid" : " invalid")
              }
              id="validation-messages"
            >
              {this.state.addVisitorFormValid && (
                <div>
                  {/* <p className="message type-success">Sending data To Server...</p> */}
                </div>
              )}
            </div>

            <br />
            <div className="align-center middleBtn">
              <Button
                size="cta-smedium add"
                text="Add Visitor"
                handleClick={() => this.validate(true)}
                isDisabled={this.props.meetings.length < 0}
              />
            </div>
          </form>

          <div className="content-wrapper">
            <DetailList
              listType="visitor-add-meeting"
              data={this.props.meetings}
              headingsAlone={true}
              emptyMessage="Visitors will appear here"
              actionClick={index => {
                this.removeFromQueue(index);
              }}
            />
          </div>

          <div className="content-wrapper special">
            <h1 style={visitor_style}>Instructions</h1>
            <label>Describe Instruction</label>
            <InputComponent
              type="text"
              name="specialInstruction"
              placeHolder="Please insert any special instructions for the visitors"
              handleChange={this.handleInputChange}
              maxLength="68"
              value={specialInstruction}
            />
          </div>
          <ButtonGroup
            rightDisabled={this.props.meetings.length === 0}
            leftClick={() => {
              this.props.history.push({
                pathname: "/",
                state: { setModule: "Visitors" }
              });
            }}
            rightClick={this.handleSubmit}
            icon_l="arrow-left-c"
            icon_r="arrow-right-c"
          />

          <FooterNavigation />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    modules: moduleSelectors.loadModules(state),
    tenants: tenantSelector.getAllTenants(state),
    meetings: meetingSelector.getMeetingsQueue(state),
    visitors: visitorSelector.getVisitors(state),
    instruction: meetingSelector.getSpecialInstruction(state),
    setting: settingSelector.loadGlobalSetting(state)

  };
}
export default withRouter(connect(mapStateToProps)(ManagementScreen));
