import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import * as $ from 'jquery';
import FeatureArea from '../../../components/FeatureArea/FeatureArea';
import FooterNavigation from '../../../components/FooterNavigation/FooterNavigation';
import InputComponent from '../../../components/Input/InputNew';
import TextAreaInput from '../../../components/Input/TextAreaInput';
import Button from '../../../components/Button/Button';
import {withRouter} from 'react-router-dom';
import autoBind from 'react-autobind';
import * as configHelper from '../../../helpers/configHelper';
import * as moduleSelectors from '../../../store/ServiceModules/reducer';
import * as parcelActions from '../../../store/Parcels/actions';
import { portalTypes, parcelStatuses } from '../../../services/config';
import * as parcelSelectors from '../../../store/Parcels/reducer';
import ButtonGroup from '../../../components/Button/ButtonGroup';

import * as portalSettingsActions from '../../../store/ConciergeSetting/actions';
import moment from 'moment';
import ParcelStatusModal from '../../../components/ariaModals/ParcelStatusModal';
import * as residentActions from '../../../store/Residents/actions';
import * as residentSelectors from '../../../store/Residents/reducer';
import * as tenantActions from '../../../store/Tenants/actions';
import * as tenantSelectors from '../../../store/Tenants/reducer';
import * as settingSelector from '../../../store/ConciergeSetting/reducer';
import MemberAutoSuggest from '../../../components/TenantAutoSuggest/MemberAutoSuggest';
import ParcelCancelModal from '../../../components/ariaModals/ParcelCancelModal';

/**
 * Parcel registration screen
 */

const PortalTypes = configHelper.PortalTypes;
const ParcelStatuses = configHelper.ParcelStatuses;
const ParcelReasons = configHelper.ParcelReasons;
class ParcelInfoScreen extends Component {
	constructor(props) {
		super(props);
		this.state = {
			type: 'parcels',
			parcel: undefined,
			barcode: '',
			memberId: undefined,
			parcelEmail: '',
			phone: '',
			IsEditable: true,
			locationId: undefined,
			portalType: '',
			modalShow: false,
			addParcelFormUsed: false,
			modalCancelShow: false,
			status: ''
		};

		this.addParcelValidation = null;
		// this.addDatepickerValidation = null;
		autoBind(this);
	}

	// componentDidMount() {
		
	// }
	componentDidMount() {
		this.addValidation();
		let parcel = this.props.history.location && this.props.history.location.state && this.props.history.location.state.parcel;

		if (parcel) {
			this.setState({
				parcel: parcel,
				barcode: parcel.barcode,
				locationId: parcel.locationId,
				memberId: parcel.memberId,
				parcelEmail: parcel.resident ? parcel.resident.email : '',
				phone: parcel.resident && parcel.resident.mobileNumber ? parcel.resident.mobileNumber : '',
				status: parcel.status.status,
				statusReasonId: parcel.statusReasonId
			});
		} else {
			this.props.history.push({
				pathname: '/'
			});
		}

		this.props.dispatch(parcelActions.getAllLocations());
		this.props.dispatch(portalSettingsActions.getGlobalSetting());
		this.props.dispatch(residentActions.getAllResidents());
		this.props.dispatch(tenantActions.getAllTenants());
		this.props.dispatch(parcelActions.getReturnReasonCodes());
	}

	componentDidUpdate(prevProps, prevState) {
		// if (this.state.parcel !== nextState.parcel && !_.isUndefined(nextState.parcel)) {
		// 	let parcel = nextState.parcel;
		// 	this.setState({
		// 		parcelEmail: parcel && parcel.resident ? parcel.resident.email : '',
		// 		phone:
		// 			parcel && parcel.resident && parcel.resident.mobileNumber
		// 				? parcel.resident.mobileNumber
		// 				: ''
		// 	});
		// }
	}
	componentWillUpdate(nextProps, nextState) {
		if (this.props.portalSettings !== nextProps.portalSettings) {
			this.setState({
				portalType: nextProps.portalSettings ? nextProps.portalSettings.portalType : ''
			});
		}

		if (this.props.parcel !== nextProps.parcel && !_.isUndefined(nextProps.parcel)) {
			let updateRecord = {
				parcel: nextProps.parcel,
				memberId: nextProps.parcel.memberId,
				locationId: nextProps.parcel.location.id,
				barcode: nextProps.parcel.barcode,
				status: nextProps.parcel.status.status,
				statusReasonId: nextProps.parcel.statusReasonId
			};

			if (
				nextProps.parcel.status.status === ParcelStatuses.CheckedIn &&
				Number(this.state.parcel.memberId) !== Number(nextProps.parcel.memberId)
			) {
				this.props.dispatch(
					parcelActions.notifyCheckinParcels([ nextProps.parcel ], (success) => {
						if (success === true) {
						} else {
							this.setState({
								error: true,
								errorMessage: 'Unable to send email notification!'
							});
						}
					})
				);
			}

			this.setState({ ...updateRecord });
			setTimeout(() => {
				this.setState({
					success: undefined,
					message: ''
				});
			}, 5000);
			this.props.history.push({
				pathname: '/concierge/parcel/info',
				state: { parcel: nextProps.parcel }
			});
		}
	}

	componentWillUnmount() {
		//this.addParcelValidation.destroy();
	}
	addValidation() {
		this.addParcelValidation = $(this.refs.validate).parsley({
			classHandler: function(el) {
				return el.$element.closest('div:not(.input-wrapper):not(.Select)');
			}
		});
	}

	validate(fromSubmit = false) {
		this.addParcelValidation.validate({ force: true });

		let validationError = !_.isUndefined(this.state.memberId) ? false : true;
		const valid = this.addParcelValidation.isValid();

		this.setState({
			addParcelFormValid: valid,
			addParcelFormUsed: true,
			error: !validationError ? undefined : true,
			memberValidationStatus: _.isUndefined(this.state.memberId) ? 'invalid' : '',
			memberValidationMessage: _.isUndefined(this.state.memberId) ? 'Please select a valid Recipient name' : '',
			locationValidationStatus: _.isUndefined(this.state.locationId) ? 'invalid' : '',
			locatinValidationMessage: _.isUndefined(this.state.locationId) ? 'Please select a location' : ''
		});

		if (valid === true && fromSubmit === true && validationError === false) {
			const { parcel, barcode, memberId, locationId, IsEditable } = this.state;

			if (!IsEditable) {
				let updateData = {
					id: parcel.id,
					barcode: barcode,
					memberId: memberId,
					locationId: locationId,
					comment: null,
					status: parcel.status.status
				};

				this.props.dispatch(
					parcelActions.updateParcel(updateData, (success) => {
						if (success === true) {
							this.setState({
								IsEditable: true,
								error: undefined,
								success: success,
								message: 'Your changes have been successfully saved'
							});
						} else {
							updateData = {
								id: this.state.parcel.id,
								barcode: this.state.parcel.barcode,
								memberId: this.state.parcel.memberId,
								locationId: this.state.parcel.locationId,
								comment: null,
								status: this.state.parcel.status.status
							};

							this.setState({
								...updateData,
								IsEditable: true,
								error: true,
								errorMessage: 'Your changes could not be saved. Please try again.'
							});
						}
					})
				);
			}
		}
	}

	formReset() {
		this.refs['tenant-auto-suggest'].getWrappedInstance().reset();
		//this.refs["formSelect-resources"].setVal("");
		//this.refs["formSelect-resources"].getWrappedInstance().reset();

		$(this.refs.validate).get(0).reset();

		this.addParcelValidation.destroy();

		// Reset all the select input components to the placeholder value
		for (let key in this.refs) {
			if (key.indexOf('formSelect') === 0) {
				this.refs[key].reset();
			}
		}

		setTimeout(() => {
			this.addValidation();
		}, 0);
	}

	resetDefaults() {
		this.setState({
			memberValidationStatus: '',
			memberValidationMessage: '',
			memberValidationStatus: '',
			error: undefined,
			success: undefined,
			message: '',
			memberId: this.state.parcel.memberId,
			locationId: this.state.parcel.locationId,
			errorMessage: ''
		});
	}

	resetEditableDefaults() {
		this.setState({
			locationId: this.state.parcel.locationId,
			memberId: this.state.parcel.memberId,
			barcode: this.state.parcel.barcode
		});
	}

	resetValidation() {
		this.setState({
			memberValidationStatus: '',
			memberValidationMessage: '',
			memberValidationStatus: '',
			error: undefined,
			success: undefined,
			message: '',
			errorMessage: ''
		});
	}
	getstatus(status) {
		// if(!_.isUndefined(status)){
		switch (status.toLowerCase()) {
			case ParcelStatuses.CheckedIn:
				return 'In Storage';

			case ParcelStatuses.Due:
				return 'Due to be Returned';

			case ParcelStatuses.Collected:
				return 'Collected';

			case ParcelStatuses.Returned:
				return 'Returned';

			case ParcelStatuses.Discarded:
				return 'Discarded';

			default:
				break;
		}
		//  }
	}

	handleInputChange(e) {
		let field = e.target.name;
		let value = e.target.value;
		this.setState({
			[field]: value,
			success: undefined,
			message: '',
			errorMessage: '',
			error: undefined
		});
	}

	handleCloseClick() {
		this.setState({
			modalShow: false,
			modalCancelShow: false
		});
	}

	handleContinueClick() {
		this.setState({
			modalCancelShow: false,
			IsEditable: true
		});
		this.resetEditableDefaults();
		//this.validate(true);
	}

	handleStayOnPageClick() {
		this.setState({
			modalCancelShow: false
		});
	}

	handleCancel(e) {
		if (this.state.IsEditable) {
			this.setState({ modalShow: false });
			this.props.history.push('/concierge/parcel/management');
			window.scrollTo(0, 0);
		} else {
			this.resetDefaults();
			this.setState({ IsEditable: true });
		}
	}

	handleTenantSelected(tenant) {
		this.setState({
			memberId: tenant.memberId && tenant.memberId !== '' ? tenant.memberId : undefined,
			memberValidationStatus: '',
			memberValidationMessage: ''
		});
	}

	handleAmend() {
		const { parcel, barcode, memberId, locationId, IsEditable } = this.state;
		this.setState({ IsEditable: false });
		if (!IsEditable) {
			this.validate(true);
			// setTimeout(() => {
			// 	this.state.addParcelFormUsed && this.validate(true);
			// }, 0);
		}
	}
	render() {
		const { parcelLocations, residents, tenants, parcelReturnReason } = this.props;
		const { parcel, barcode, parcelEmail, phone, portalType, memberId, status, statusReasonId } = this.state;

		let { locationSelect } = this.state;
		const currentModule = _.find(this.props.modules, (o) => o.title === 'Parcels');

		let residentsList = _.orderBy(residents, [ 'firstName', 'addressLine1' ], [ 'asc' ]);
		let tenantsList = _.orderBy(tenants, [ 'firstName', 'companyName' ], [ 'asc' ]);

		let location_option = _.map(parcelLocations, (location, rowIndex) => {
			return { name: location.locationName, label: location.locationName, value: location.id };
		});

		const selectedLocationById = _.find(location_option, { value: this.state.locationId });
		const selectedLocation =
			selectedLocationById == undefined
				? location_option.length === 1 ? location_option[0] : ''
				: selectedLocationById;
		locationSelect = locationSelect == undefined ? selectedLocation.value : '';

		let defaultTenantIdProperty = {};

		if (memberId && memberId !== null) {
			defaultTenantIdProperty = {
				defaultTenantId: memberId
			};
		}
		let statusReason = '';
		if (parcelReturnReason && parcelReturnReason.length > 0 && statusReasonId !== 0) {
			let status = _.find(parcelReturnReason, { id: statusReasonId });
			statusReason = !_.isUndefined(status) ? status.reason : '';
		}

		return (
			<div>
				<div className="container type-concierge-desktop parcel-info-container">
					<FeatureArea
						type={this.state.type}
						areaTitle="Parcels"
						areaTagline="Welcome, select a service"
						pageHeader="Parcel Info"
						overlayColor={currentModule && currentModule.imageOverlayTextColour}
						landing={false}
						activeMenu={true}
					/>
					<form
						// className="modal-wrapper"
						ref="validate"
						onSubmit={(e) => {
							e.preventDefault();
						}}
						data-parsley-errors-container="#validation-messages"
						data-parsley-focus="none"
						data-parsley-validate-if-empty
					>
						<div className="main-container parcel-info-root">
							<div className="visitor-management-header parcel-status">
								<div className="status-link-div">
									{(status && status.toLowerCase() === ParcelStatuses.CheckedIn) ||
									(status && status.toLowerCase() === ParcelStatuses.Due) ? (
										<span
											className={
												!this.state.IsEditable ? (
													'change-status-link disabled-link'
												) : (
													'change-status-link'
												)
											}
											onClick={() =>
												this.state.IsEditable ? this.setState({ modalShow: true }) : ''}
										>
											Change Status
										</span>
									) : (
										''
									)}
								</div>
								<div className="">
									<label className=" parcel-status">{this.getstatus(status)}</label>
								</div>
							</div>

							<div className="visitor-management-header">
								<div className="input-section">
									{this.state.IsEditable ? (
										<InputComponent
											label="Parcel Barcode"
											type="text"
											value={
												barcode != null ? barcode.length > 57 ? (
													barcode.substring(0, 57) + '...'
												) : (
													barcode
												) : (
													''
												)
											}
											name="barcode"
											handleChange={(e) => this.handleInputChange(e)}
											disabled={true}
										/>
									) : (
										<InputComponent
											label="Parcel Barcode"
											type="text"
											value={barcode}
											name="barcode"
											placeHolder="Scan or enter reference code"
											handleChange={(e) => this.handleInputChange(e)}
											disabled={false}
										/>
									)}
								</div>
								<div className={'date_select'}>
									{this.state.IsEditable ? (
										<InputComponent
											type={'text'}
											label={'Location*'}
											className={'text-box'}
											value={
												selectedLocationById ? selectedLocationById.label.length > 23 ? (
													selectedLocationById.label.substring(0, 23) + '...'
												) : (
													selectedLocationById.label
												) : (
													''
												)
											}
											disabled={true}
										/>
									) : (
										<InputComponent
											ref="locationRef"
											label={'Location*'}
											type={'select'}
											name="locationId"
											placeHolder="Select location"
											className="text-box"
											options={location_option}
											value={locationSelect && locationSelect.toString()}
											//value={selectedLocation.value}
											handleChange={(e) => {
												this.handleInputChange(e);
											}}
											disabled={this.state.IsEditable}
										/>
									)}
								</div>
							</div>

							{
								// <div className="input-wrapper">
								// 	<InputComponent
								// 		type="text"
								// 		label="Recipient"
								// 		name="recipient"
								// 		value={recipient} //"Paul Smith - Flat 2, Long Name Court Road"
								// 		placeHolder={
								// 			portalTypes === PortalTypes.Commercial ? (
								// 				'Search for tenant name or company'
								// 			) : (
								// 				'Search for tenant name or address'
								// 			)
								// 		}
								// 		disabled={this.state.IsEditable}
								// 		handleChange={(e) => this.handleBarcodeChange(e)}
								// 	/>
								// </div>
							}
							<div className="input-wrapper">
								{portalType && (
									<MemberAutoSuggest
										ref="tenant-auto-suggest"
										{...defaultTenantIdProperty}
										label="Recipient*"
										placeHolder={
											this.state.IsEditable ? (
												''
											) : portalType === PortalTypes.Commercial ? (
												'Search for tenant name or company'
											) : (
												'Search for tenant name or address'
											)
										}
										defaultTenantId={memberId && memberId}
										clearableIfValid={false}
										onSelected={(data) => {
											this.handleTenantSelected(data);
										}}
										defaultText={() => {}}
										disabled={this.state.IsEditable}
										showRecordsField={true}
										portalType={this.state.portalType}
										data={portalType === PortalTypes.Commercial ? tenantsList : residentsList}
										fieldCleared={(val) => {
											this.setState({
												memberId: undefined
											});
											this.resetValidation();
										}}
										validation={{
											validate: true,
											className: this.state.memberValidationStatus
										}}
									/>
								)}
							</div>
							<div className="input-wrapper info-two-section">
								<div>
									<InputComponent
										type="text"
										label="Email Address"
										name="parcelEmail"
										value={parcelEmail}
										disabled={true}
									/>
								</div>
								<div>
									<InputComponent
										type="text"
										label="Contact Number"
										name="contactNumber"
										value={phone} //"0121 496 0120"
										disabled={true}
									/>
								</div>
							</div>

							<div className="input-wrapper info-two-section">
								<div>
									<InputComponent
										type="text"
										label="Check-in Date"
										name="checkinDate"
										value={parcel && moment(parcel.dateArrived).utc().format('DD/MM/YYYY - HH:mm')} //"01/03/2018 - 13:46"
										disabled={true}
									/>
								</div>
								<div>
									<InputComponent
										type="text"
										label="Collection Date"
										name="collectionDate"
										value={
											status && status.toLowerCase() === ParcelStatuses.Collected ? (
												moment(parcel.dateCollected).format('DD/MM/YYYY - HH:mm')
											) : (
												'N/A'
											)
										} //"N/A"
										disabled={true}
									/>
								</div>
							</div>

							{(status && status.toLowerCase() === ParcelStatuses.Returned) ||
							status.toLowerCase() === ParcelStatuses.Discarded ? (
								<div>
									<div className="input-wrapper info-two-section">
										<div>
											<InputComponent
												type="text"
												label={
													status.toLowerCase() === ParcelStatuses.Discarded ? (
														'Discard Date'
													) : (
														'Return Date'
													)
												}
												name="returnDate"
												value={
													parcel && status.toLowerCase() === ParcelStatuses.Discarded ? (
														moment(parcel.dateDiscarded).format('DD/MM/YYYY - HH:mm')
													) : (
														parcel &&
														moment(parcel.dateReturned).format('DD/MM/YYYY - HH:mm')
													)
												} //"16/06/2018 - 10:03"
												disabled={true}
											/>
										</div>
										<div>
											<InputComponent
												type="text"
												label={
													status.toLowerCase() === ParcelStatuses.Discarded ? (
														'Discard Reason'
													) : (
														'Return Reason'
													)
												}
												name="retrunReason"
												value={statusReason}
												disabled={true}
											/>
										</div>
									</div>

									{statusReason === ParcelReasons.Other && (
										<div className="input-wrapper additional-info">
											<label className="dateLabel visitor-label">Additional Information</label>
											<div className="addtionl-textarea">
												<TextAreaInput
													type="text"
													name="addtionalInfo"
													value={parcel.comment} //"Phasellus quis imperdiet nibh. Vivamus ac mattis nulla. Phasellus tortor est, fermentum ac volutpat et, pellentesque eu nulla. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Integer efficitur dolor erat, non placerat enim rhoncus in. Cras in lorem id libero semper facilisis posuere eu purus."
												/>
											</div>
										</div>
									)}
								</div>
							) : (
								''
							)}

							<div
								className={
									'validation-messages' + (this.state.addParcelFormValid ? ' valid' : ' invalid')
								}
								id="validation-messages"
							>
								{this.state.error && (
									<div>
										<span className="error">{this.state.memberValidationMessage}</span>
										<span className="error">{this.state.errorMessage}</span>
									</div>
								)}

								{this.state.success && (
									<div>
										<span className="success">{this.state.message}</span>
									</div>
								)}
							</div>

							<div className="input-wrapper button-container">
								{status === 'collected' || status === 'returned' || status === 'discarded' ? (
									<div className="btnGroup">
										<Button
											size="large"
											text="Cancel"
											handleClick={(e) => this.handleCancel(e)}
											icon=""
										/>
									</div>
								) : (
									<ButtonGroup
										sizes={[ 'smedium', 'smedium' ]}
										leftText={this.state.IsEditable ? 'Cancel' : 'Cancel Changes'}
										rightText={this.state.IsEditable ? 'Amend Record' : 'Save Changes'}
										leftClick={
											this.state.IsEditable ? (
												(e) => this.handleCancel(e)
											) : (
												() => {
													if (
														barcode !== parcel.barcode ||
														locationSelect !== parcel.locationId ||
														memberId !== parcel.memberId
													) {
														this.setState({ modalCancelShow: true });
													} else {
														this.setState({ IsEditable: true });
													}
												}
											)
										}
										rightClick={() => {
											this.handleAmend();
										}}
									/>
								)}
							</div>
						</div>
					</form>

					{this.state.modalCancelShow ? (
						<ParcelCancelModal
							handleCloseClick={() => this.handleCloseClick()}
							handleContinueClick={() => this.handleContinueClick()}
							handleStayOnPageClick={() => this.handleStayOnPageClick()}
							focus={true}
							//	getApplicationNode={this.getApplicationNode}
							moduleColor={currentModule && currentModule.imageOverlayTextColour}
							confirmationMessage={
								'Please notice that by leaving this page all changes to this parcel records will be discard. Do you wish to continue?'
							}
						/>
					) : (
						''
					)}
					{this.state.modalShow ? (
						<ParcelStatusModal
							handleCloseClick={() => this.handleCloseClick()}
							focus={true}
							data={parcel}
							moduleColor={currentModule && currentModule.imageOverlayTextColour}
							portalType={portalType}
						/>
					) : (
						''
					)}

					<FooterNavigation />
				</div>
			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		modules: moduleSelectors.loadModules(state),
		parcelLocations: parcelSelectors.getParcelLocations(state),
		residents: residentSelectors.getResidents(state),
		tenants: tenantSelectors.getAllTenants(state),
		portalSettings: settingSelector.loadGlobalSetting(state),
		parcel: parcelSelectors.getParcel(state),
		parcelReturnReason: parcelSelectors.getReturnResonCodes(state)
	};
}

export default withRouter(connect(mapStateToProps, null, null, { forwardRef: true })(ParcelInfoScreen));
