import * as types from "./actionTypes";
import settingServices from "../../services/ConciergeSettingService";

export function getGlobalSetting(callback = () => { }) {
    return async (dispatch, getState) => {
        try {
            const response = await settingServices.getGlobalSettings();
            dispatch({ type: types.CONCIERGE_SETTINGS_LOAD_SUCCESS, response });
            callback(true);
        } catch (error) {
            callback(false);
            console.error(error);
        }
    };
} 