import * as config from './config';
import authService from './loginService';

class ParcelServices {
	async getParcel(id) {
		const url = `${config.PARCEL_RECORD_ENDPOINT}`;
		const authToken = authService.getToken();

		var requestHeader = new Headers();
		requestHeader.append('Authorization', 'Bearer ' + authToken);

		const response = await fetch(url + '?id=' + id, {
			method: 'GET',
			headers: requestHeader
		});

		if (!response.ok) {
			config.errorPageRedirect(response);
			throw new Error(`getMeetingById service failed, HTTP status ${response.status}`);
		}

		const result = await response.json();

		return result;
	}
	async getLocations() {
		const url = `${config.PARCEL_LOCATIONS_ENDPOINT}`;
		//const authToken = authService.getToken();
		//console.log(authToken);
		var requestHeader = new Headers();
		//requestHeader.append("Authorization", "Bearer " + authToken);

		const response = await fetch(url, {
			method: 'GET',
			headers: requestHeader
		});

		if (!response.ok) {
			config.errorPageRedirect(response);

			throw new Error(`getParcel service failed, HTTP status ${response.status}`);
		}

		const result = await response.json();

		return result;
	}
	async getStatusReasons() {
		// Uncomment when reasons endpoint ready

		/*const url = `${config.PARCEL_STATUS_REASONS_ENDPOINT}`;
    //const authToken = authService.getToken();
    //console.log(authToken);
    var requestHeader = new Headers();
    //requestHeader.append("Authorization", "Bearer " + authToken);

    const response = await fetch(url, {
      method: "GET",
      headers: requestHeader
    });

    if (!response.ok) {    
      config.errorPageRedirect(response);
      throw new Error(
        `getParcel service failed, HTTP status ${response.status}`
      );
    }

    const result = await response.json();*/

		const result = [
			{ value: 1, label: 'Dont want' },
			{ value: 2, label: 'Wrong Item' },
			{ value: 3, label: 'Wrong Address' }
		];

		return result;
	}
	async getParcels() {
		const url = `${config.ALL_PARCELS_ENDPOINT}`;
		const authToken = authService.getToken();
		//console.log(authToken);
		var requestHeader = new Headers();
		requestHeader.append("Authorization", "Bearer " + authToken);

		const response = await fetch(url, {
			method: 'GET',
			headers: requestHeader
		});

		if (!response.ok) {
			config.errorPageRedirect(response);
			throw new Error(`getParcel service failed, HTTP status ${response.status}`);
		}

		const result = await response.json();

		return result;
	}

	async getStatusCodes() {
		const url = `${config.PARCELS_STATUS_CODES_SUCCESS_ENDPOINT}`;
		//const authToken = authService.getToken();
		//console.log(authToken);
		var requestHeader = new Headers();
		//requestHeader.append("Authorization", "Bearer " + authToken);

		const response = await fetch(url, {
			method: 'GET',
			headers: requestHeader
		});

		if (!response.ok) {
			config.errorPageRedirect(response);
			throw new Error(`getParcel service failed, HTTP status ${response.status}`);
		}

		const result = await response.json();

		return result;
	}

	async getReturnReasonCodes() {
		const url = `${config.PARCEL_RETURN_REASON_CODES_ENDPOINT}`;
		//const authToken = authService.getToken();
		//console.log(authToken);
		var requestHeader = new Headers();
		//requestHeader.append("Authorization", "Bearer " + authToken);

		const response = await fetch(url, {
			method: 'GET',
			headers: requestHeader
		});

		if (!response.ok) {
			config.errorPageRedirect(response);
			throw new Error(`getParcel service failed, HTTP status ${response.status}`);
		}

		const result = await response.json();

		return result;
	}

	async addParcels(parcelData) {
		const url = `${config.PARCEL_ADD_ENDPOINT}`;

		var requestHeader = new Headers();
		requestHeader.append('Accept', 'application/json');
		requestHeader.append('Content-Type', 'application/json');

		const response = await fetch(url, {
			method: 'POST',
			headers: requestHeader,
			body: JSON.stringify(parcelData)
		});

		if (!response.ok) {
			config.errorPageRedirect(response);
			throw new Error(`addParcels service failed, HTTP status ${response.status}`);
		}

		const result = await response.json();

		return result;
	}

	async returnParcel(parcelData) {
		const url = `${config.PARCEL_RETURN_ENDPOINT}`;

		var requestHeader = new Headers();
		requestHeader.append('Accept', 'application/json');
		requestHeader.append('Content-Type', 'application/json');

		const response = await fetch(url + '?id=' + parcelData.id + '&statusReasonId=' + parcelData.statusReasonId, {
			method: 'POST',
			headers: requestHeader,
			body: JSON.stringify(parcelData)
		});

		if (!response.ok) {
			config.errorPageRedirect(response);
			throw new Error(`return service failed, HTTP status ${response.status}`);
		}

		const result = await response.json();

		return result;
	}

	async checkoutParcels(parcelData) {
		const url = `${config.PARCEL_CHECKOUT_ENDPOINT}`;

		var requestHeader = new Headers();
		requestHeader.append('Accept', 'application/json');
		requestHeader.append('Content-Type', 'application/json');

		const response = await fetch(url, {
			method: 'POST',
			headers: requestHeader,
			body: JSON.stringify(parcelData)
		});

		if (!response.ok) {
			config.errorPageRedirect(response);
			throw new Error(`checkoutParcels service failed, HTTP status ${response.status}`);
		}

		const result = await response.json();

		return result;
	}

	async checkoutParcel(parcelData) {
		const url = `${config.PARCEL_CHECKOUT_ENDPOINT}`;

		var requestHeader = new Headers();
		requestHeader.append('Accept', 'application/json');
		requestHeader.append('Content-Type', 'application/json');

		const response = await fetch(url, {
			method: 'PUT',
			headers: requestHeader,
			body: JSON.stringify(parcelData)
		});

		if (!response.ok) {
			//config.errorPageRedirect(response);
			throw new Error(`checkoutParcel service failed, HTTP status ${response.status}`);
		}

		const result = await response.json();

		return result;
	}
	//send notification on parcel checked in by concierge
	async notifyCheckin(parcelData) {
		const url = `${config.NOTIFY_PARCEL_CHECKED_IN}`;
		var requestHeader = new Headers();
		requestHeader.append('Accept', 'application/json');
		requestHeader.append('Content-Type', 'application/json');

		const response = await fetch(url, {
			method: 'POST',
			headers: requestHeader,
			body: JSON.stringify(parcelData)
		});
		if (!response.ok) {
			//config.errorPageRedirect(response);
			throw new Error(`notifyCheckin service failed, HTTP status ${response.status}`);
		}

		const result = await response.json();

		return result;
	}

	//send notification on parcel collected by resident
	async notifyCollected(parcelData) {
		const url = `${config.NOTIFY_PARCEL_COLLECTED}`;
		var requestHeader = new Headers();
		requestHeader.append('Accept', 'application/json');
		requestHeader.append('Content-Type', 'application/json');

		const response = await fetch(url, {
			method: 'POST',
			headers: requestHeader,
			body: JSON.stringify(parcelData)
		});
		if (!response.ok) {
			//config.errorPageRedirect(response);
			throw new Error(`checkoutParcels service failed, HTTP status ${response.status}`);
		}

		const result = await response.json();

		return result;
	}

	async notifyCheckinParcels(parcelsData) {
		const url = `${config.NOTIFY_PARCELS_CHECKED_IN}`;
		var requestHeader = new Headers();
		requestHeader.append('Accept', 'application/json');
		requestHeader.append('Content-Type', 'application/json');

		const response = await fetch(url, {
			method: 'POST',
			headers: requestHeader,
			body: JSON.stringify(parcelsData)
		});
		if (!response.ok) {
			//config.errorPageRedirect(response);
			throw new Error(`notifyCheckinParcels service failed, HTTP status ${response.status}`);
		}

		const result = await response.json();

		return result;
	}

	async notifyCollectedParcels(parcelsData) {
		const url = `${config.NOTIFY_PARCELS_COLLECTED}`;
		var requestHeader = new Headers();
		requestHeader.append('Accept', 'application/json');
		requestHeader.append('Content-Type', 'application/json');

		const response = await fetch(url, {
			method: 'POST',
			headers: requestHeader,
			body: JSON.stringify(parcelsData)
		});
		if (!response.ok) {
			//config.errorPageRedirect(response);
			throw new Error(`notifyCollectedParcels service failed, HTTP status ${response.status}`);
		}

		const result = await response.json();

		return result;
	}

	async getExpectedArrivedByDateInterval(dateFrom, dateTo) {
		const url = `${config.PARCEL_EXPECTEDARRIVEDBYDATE}`;

		// var requestHeader = new Headers();

		const response = await fetch(url, {
			method: 'GET'
			//headers: requestHeader,
		});

		if (!response.ok) {
			//config.errorPageRedirect(response);
			throw new Error(`getExpectedArrivedByDateInterval service failed, HTTP status ${response.status}`);
		}

		const result = await response.json();

		return result;
	}

	async registerExpectedParcelsList(parcelList) {
		const url = `${config.PARCEL_REGISTEREXPECTEDLIST}`;

		var requestHeader = new Headers();
		requestHeader.append('Accept', 'application/json');
		requestHeader.append('Content-Type', 'application/json');

		const response = await fetch(url, {
			method: 'POST',
			headers: requestHeader,
			body: JSON.stringify(parcelList)
		});
		if (!response.ok) {
			//config.errorPageRedirect(response);
			throw new Error(`registerExpectedParcelsList service failed, HTTP status ${response.status}`);
		}

		const result = await response.json();

		return result;
	}

	async getParcelSettings() {
		const url = `${config.PARCEL_SETTINGS}`;

		// var requestHeader = new Headers();

		const response = await fetch(url, {
			method: 'GET'
			// headers: requestHeader,
		});
		if (!response.ok) {
			//config.errorPageRedirect(response);
			throw new Error(`getParcelSettings service failed, HTTP status ${response.status}`);
		}

		const result = await response.json();

		return result;
	}

	async getPacrcelsByStatus(status) {
		const url = `${config.PARCELS_BY_STATUS}${'?status='}${status}`;
		const authToken = authService.getToken();

		var requestHeader = new Headers();
		requestHeader.append('Authorization', 'Bearer ' + authToken);

		const response = await fetch(url, {
			method: 'GET',
			headers: requestHeader
		});
		if (!response.ok) {
			//config.errorPageRedirect(response);
			throw new Error(`getPacrcelsByStatus service failed, HTTP status ${response.status}`);
		}

		const result = await response.json();

		return result;
	}

	async updateParcelRecord(record) {
		const url = `${config.UPDATE_PARCEL}`;
		var requestHeader = new Headers();
		requestHeader.append('Accept', 'application/json');
		requestHeader.append('Content-Type', 'application/json');
		const response = await fetch(url, {
			method: 'PUT',
			body: JSON.stringify(record),
			headers: requestHeader
		});
		if (!response.ok) {
			//config.errorPageRedirect(response);
			throw new Error(`updateParcelRecord service failed, HTTP status ${response.status}`);
		}

		const result = await response.json();

		return result;
	}

	async notifyByStatus(parcelList) {
		const url = `${config.PARCEL_NOTIFY}`;
		var requestHeader = new Headers();
		requestHeader.append('Accept', 'application/json');
		requestHeader.append('Content-Type', 'application/json');

		const response = await fetch(url, {
			method: 'POST',
			headers: requestHeader,
			body: JSON.stringify(parcelList)
		});
		if (!response.ok) {
			//config.errorPageRedirect(response);
			throw new Error(`notifyByStatus service failed, HTTP status ${response.status}`);
		}

		const result = await response.json();

		return result;
	}
}

export default new ParcelServices();
