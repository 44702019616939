import * as types from './actionTypes';
import ParcelServices from '../../services/ParcelServices';
import * as parcelSelector from './reducer';
import * as configHelper from '../../helpers/configHelper';
import _ from 'lodash';
export function getAllParcels(callback = () => {}) {
	return async (dispatch, getState) => {
		try {
			const parcels = await ParcelServices.getParcels();

			dispatch({ type: types.PARCELS_LOADED_SUCCESS, parcels });
			callback(true);
		} catch (error) {
			callback(false);
			//console.error(error);
		}
	};
}

export function getAllParcelsonManage(callback = () => {}) {
	return async (dispatch, getState) => {
		try {
			const parcels = await ParcelServices.getParcels();

			dispatch({ type: types.PARCELS_LOADED_SUCCESS_MANAGE, parcels });
			callback(true);
		} catch (error) {
			callback(false);
			//console.error(error);
		}
	};
}

export function getParcelRecord(id) {
	return async (dispatch, getState) => {
		try {
			const parcel = await ParcelServices.getParcel(id);
			dispatch({ type: types.PARCEL_RECORD_LOADED_SUCCESS, parcel });
		} catch (error) {
			console.error(error);
		}
	};
}

export function getAllLocations() {
	return async (dispatch, getState) => {
		try {
			const locations = await ParcelServices.getLocations();

			dispatch({
				type: types.PARCEL_LOCATIONS_LOADED_SUCCESS,
				locations
			});
		} catch (error) {
			console.error(error);
		}
	};
}

export function getAllStatusReasons() {
	return async (dispatch, getState) => {
		try {
			const reasons = await ParcelServices.getStatusReasons();
			dispatch({
				type: types.PARCEL_STATUS_REASONS_LOADED_SUCCESS,
				reasons
			});
		} catch (error) {
			console.error(error);
		}
	};
}

export function addParcelsToQueue(parcelList) {
	return {
		type: types.PARCEL_ADD_TO_CHECKIN_QUEUE,
		parcels: parcelList
	};
}

export function setParcelsCheckoutQueue(parcelList) {
	return {
		type: types.PARCEL_SET_CHECKOUT_QUEUE,
		parcels: parcelList
	};
}

export function removeParcelFromQueue(index) {
	return {
		type: types.PARCEL_REMOVE_FROM_CHECKIN_QUEUE,
		index: index
	};
}

export function editParcelInQueue(parcel) {
	return {
		type: types.PARCEL_EDIT_IN_CHECKIN_QUEUE,
		parcel: parcel
	};
}

export function getStatusCodes(callback = () => {}) {
	return async (dispatch, getState) => {
		try {
			const statusCodesResponse = await ParcelServices.getStatusCodes();

			dispatch({
				type: types.PARCELS_STATUS_CODES_SUCCESS,
				statusCodesResponse
			});

			callback(true);
		} catch (error) {
			callback(error);
		}
	};
}

export function getReturnReasonCodes(callback = () => {}) {
	return async (dispatch, getState) => {
		try {
			const reasonCodesResponse = await ParcelServices.getReturnReasonCodes();

			dispatch({
				type: types.PARCEL_RETURN_REASON_CODES_LOAD_SUCCESS,
				reasonCodesResponse
			});

			callback(true);
		} catch (error) {
			callback(error);
		}
	};
}

export function addParcels(parcelsData, callback = () => {}) {
	return async (dispatch, getState) => {
		try {
			const addParcelResponse = await ParcelServices.addParcels(parcelsData);

			dispatch({
				type: types.PARCELS_ADDED_SUCCESS,
				addParcelResponse: addParcelResponse
			});

			callback(true);
		} catch (error) {
			callback(false);
			console.error(error);
		}
	};
}

export function returnParcel(parcelData, callback = () => {}) {
	return async (dispatch, getState) => {
		try {
			const returnParcelResponse = await ParcelServices.returnParcel(parcelData);

			dispatch({
				type: types.PARCELS_RETURNED_SUCCESS,
				returnParcelResponse
			});

			callback(true);
		} catch (error) {
			callback(error);
		}
	};
}

export function addParcelsToCheckout(idList, callback = () => {}) {
	return async (dispatch, getState) => {
		try {
			const addCheckoutResponse = await ParcelServices.checkoutParcels(idList);

			dispatch({
				type: types.PARCELS_CHECKOUT_SUCCESS,
				addCheckoutResponse
			});

			callback(true);
		} catch (error) {
			callback(false);
		}
	};
}
export function checkoutParcel(parcel, callback = () => {}) {
	return async (dispatch, getState) => {
		try {
			const result = await ParcelServices.checkoutParcel(parcel);

			dispatch({
				type: types.PARCEL_CHECKOUT_SUCCESS,
				response: result
			});

			callback(true);
		} catch (error) {
			callback(false);
		}
	};
}

export function notifyCheckin() {
	return async (dispatch, getState) => {
		try {
			const parcelData = parcelSelector.getParcelsCheckedIn(getState());

			if (!_.isUndefined(parcelData)) {
				for (let i = 0; i < parcelData.length; i++) {
					const response = await ParcelServices.notifyCheckin(parcelData[i]);
					dispatch({ type: types.NOTIFY_PARCEL_CHECKED_IN_SUCCESS, response });
				}
			}
		} catch (error) {
			dispatch({ type: types.NOTIFY_PARCEL_CHECKED_IN_FAIL, error });
		}
	};
}

export function notifyCollected() {
	return async (dispatch, getState) => {
		try {
			const parcelData = parcelSelector.getCheckoutQueue(getState());

			if (!_.isUndefined(parcelData)) {
				for (let i = 0; i < parcelData.length; i++) {
					const response = await ParcelServices.notifyCollected(parcelData[i]);
					dispatch({ type: types.NOTIFY_PARCEL_COLLECTED_SUCCESS, response });
				}
			}
		} catch (error) {
			dispatch({ type: types.NOTIFY_PARCEL_COLLECTED_FAIL, error });

			console.error(error);
		}
	};
}

export function notifyCheckinParcels(parcels, callback = () => {}) {
	return async (dispatch, getState) => {
		try {
			const response = await ParcelServices.notifyCheckinParcels(parcels);
			dispatch({
				type: types.NOTIFY_PARCELS_CHECKED_IN_SUCCESS,
				notified: response
			});
			callback(true);
		} catch (error) {
			dispatch({ type: types.NOTIFY_PARCELS_CHECKED_IN_FAIL, error });
		}
	};
}

export function notifyCollectedParcels(parcelData, callback = () => {}) {
	return async (dispatch, getState) => {
		try {
			const response = await ParcelServices.notifyCollectedParcels(parcelData);
			dispatch({
				type: types.NOTIFY_PARCELS_COLLECTED_SUCCESS,
				notified: response
			});
			callback(true);
		} catch (error) {
			callback(false);
			dispatch({ type: types.NOTIFY_PARCEL_COLLECTED_FAIL, error });

			console.error(error);
		}
	};
}

export function notifyParcels(parcelData, status, callback = () => {}) {
	return async (dispatch, getState) => {
		try {
			var response = await ParcelServices.notifyByStatus(parcelData, status);
			callback(true);
		} catch (error) {
			callback(false);
		}
	};
}
export function getParcelExpectedArrivedByDate(dateFrom, dateTo, callback = () => {}) {
	return async (dispatch, getState) => {
		try {
			var parcels = await ParcelServices.getExpectedArrivedByDateInterval(dateFrom, dateTo);

			dispatch({ type: types.PARCELS_EXPECTED_ARRIVED_BY_DATE_LOADED_SUCCESSFULL, list: parcels });
		} catch (error) {
			callback(false);
		}
	};
}

export function getParcelsByStatus(status, callback = () => {}) {
	return async (dispatch, getState) => {
		try {
			var parcels = await ParcelServices.getPacrcelsByStatus(status);

			dispatch({ type: types.PARCELS_LOADED_SUCCESS, parcels });

			callback(true);
		} catch (error) {
			callback(false);
		}
	};
}

export function getParcelSettigs(callback = () => {}) {
	return async (dispatch, getState) => {
		try {
			var settings = await ParcelServices.getParcelSettings();
			dispatch({ type: types.PARCEL_SETTINGS_LOAD_SUCCESSFULL, settings });
			callback(true);
		} catch (error) {
			callback(false);
		}
	};
}

export function getParcelsDue(callback = () => {}) {
	return async (dispatch, getState) => {
		try {
			var parcels = await ParcelServices.getPacrcelsByStatus(configHelper.ParcelStatuses.Due);
			dispatch({ type: types.PARCEL_DUE_LOADED_SUCCESSFULLY, data: parcels });

			callback(true);
		} catch (error) {
			callback(false);
		}
	};
}

export function getParcelsExpected(callback = () => {}) {
	return async (dispatch, getState) => {
		try {
			var parcels = await ParcelServices.getPacrcelsByStatus(configHelper.ParcelStatuses.Expected);
			dispatch({ type: types.PARCEL_EXPECTED_LOAD_SUCCESSFULLY, data: parcels });

			callback(true);
		} catch (error) {
			callback(false);
		}
	};
}

export function clearParcel() {
	return (dispatch) => {
		let parcels = [];
		dispatch({ type: types.PARCELS_LOADED_SUCCESS, parcels });
	};
}

export function registerParcelQueue(list, callback = () => {}) {
	return async (dispatch, getState) => {
		try {
		} catch (error) {}
	};
}
export function registerExpected(listData, callback = () => {}) {
	return async (dispatch, getState) => {
		try {
			const data = await ParcelServices.registerExpectedParcelsList(listData);
			dispatch({ type: types.REGISTER_EXPECTED_PARCEL_LIST_SUCCESSFULL, parcels: data });
			callback(true);
		} catch (error) {
			callback(false);
		}
	};
}

/**update parcel record action */
export function updateParcel(record, callback = () => {}) {
	return async (dispatch, getState) => {
		try {
			const result = await ParcelServices.updateParcelRecord(record);
			dispatch({ type: types.PARCEL_RECORD_UPDATED_SUCCESSFULLY, data: result });
			callback(true);
		} catch (error) {
			callback(false);
		}
	};
}

export function updateParcelStatus(id, status, callback = () => {}) {
	return async (dispatch, getState) => {
		try {
		} catch (error) {
			callback(false);
		}
	};
}
export function getParcelsTestData() {
	const parcelData = [
		{
			id: 30,
			barcode: '464646456',
			dateArrived: '2018-01-30T10:33:57.207Z',
			locationId: 3,
			numberOfParcel: 1,
			recipientId: 30,
			residentStatus: null,
			statusId: 1,
			dateCollected: '0001-01-01T00:00:00',
			signatureTaken: false,
			signatureId: 0,
			collectByDate: '2018-02-06T10:33:57.207Z',
			collectByDateExtension: '0001-01-01T00:00:00',
			dateReturned: '0001-01-01T00:00:00',
			comment: null,
			statusReasonId: 0,
			memberId: 1261,
			resident: {
				id: 1261,
				firstName: 'Edward',
				lastName: 'Gage',
				email: 'resident@engageproptech.com',
				addressLine1: 'Flat 6,',
				addressLine2: '224 Iverson Road',
				postCode: 'NW6 2HL',
				status: ''
			},
			location: {
				id: 3,
				locationName: 'Storage Area B'
			},
			status: {
				id: 1,
				status: 'checkedin'
			},
			recipient: {
				recipientId: 30,
				memberId: 1261,
				firstName: null,
				lastName: null,
				recipientType: null,
				serviceType: 'Parcels',
				serviceId: 30
			}
		},
		{
			id: 29,
			barcode: '435435435',
			dateArrived: '2018-01-30T10:14:25.427Z',
			locationId: 2,
			numberOfParcel: 1,
			recipientId: 29,
			residentStatus: null,
			statusId: 1,
			dateCollected: '0001-01-01T00:00:00',
			signatureTaken: false,
			signatureId: 0,
			collectByDate: '2018-02-06T10:14:25.427Z',
			collectByDateExtension: '0001-01-01T00:00:00',
			dateReturned: '0001-01-01T00:00:00',
			comment: null,
			statusReasonId: 0,
			memberId: 1606,
			resident: {
				id: 1606,
				firstName: 'Dave',
				lastName: 'Pearce',
				email: 'dave.pearce@mearsgroup.co.uk',
				addressLine1: '1390 Montpellier Court',
				addressLine2: 'Gloucester Business Park',
				postCode: 'GL3 4AH',
				status: ''
			},
			location: {
				id: 2,
				locationName: 'Storage Area Large'
			},
			status: {
				id: 1,
				status: 'checkedin'
			},
			recipient: {
				recipientId: 29,
				memberId: 1606,
				firstName: null,
				lastName: null,
				recipientType: null,
				serviceType: 'Parcels',
				serviceId: 29
			}
		},
		{
			id: 28,
			barcode: 'gdrhgfdhjfghfhfh',
			dateArrived: '2018-01-30T10:13:29.49Z',
			locationId: 2,
			numberOfParcel: 1,
			recipientId: 28,
			residentStatus: null,
			statusId: 1,
			dateCollected: '0001-01-01T00:00:00',
			signatureTaken: false,
			signatureId: 0,
			collectByDate: '2018-02-06T10:13:29.49Z',
			collectByDateExtension: '0001-01-01T00:00:00',
			dateReturned: '0001-01-01T00:00:00',
			comment: null,
			statusReasonId: 0,
			memberId: 1606,
			resident: {
				id: 1606,
				firstName: 'Dave',
				lastName: 'Pearce',
				email: 'dave.pearce@mearsgroup.co.uk',
				addressLine1: '1390 Montpellier Court',
				addressLine2: 'Gloucester Business Park',
				postCode: 'GL3 4AH',
				status: ''
			},
			location: {
				id: 2,
				locationName: 'Storage Area Large'
			},
			status: {
				id: 1,
				status: 'checkedin'
			},
			recipient: {
				recipientId: 28,
				memberId: 1606,
				firstName: null,
				lastName: null,
				recipientType: null,
				serviceType: 'Parcels',
				serviceId: 28
			}
		},
		{
			id: 27,
			barcode: 'fsdfsfs',
			dateArrived: '2018-01-30T10:13:12.307Z',
			locationId: 2,
			numberOfParcel: 1,
			recipientId: 27,
			residentStatus: null,
			statusId: 1,
			dateCollected: '0001-01-01T00:00:00',
			signatureTaken: false,
			signatureId: 0,
			collectByDate: '2018-02-06T10:13:12.307Z',
			collectByDateExtension: '0001-01-01T00:00:00',
			dateReturned: '0001-01-01T00:00:00',
			comment: null,
			statusReasonId: 0,
			memberId: 1606,
			resident: {
				id: 1606,
				firstName: 'Dave',
				lastName: 'Pearce',
				email: 'dave.pearce@mearsgroup.co.uk',
				addressLine1: '1390 Montpellier Court',
				addressLine2: 'Gloucester Business Park',
				postCode: 'GL3 4AH',
				status: ''
			},
			location: {
				id: 2,
				locationName: 'Storage Area Large'
			},
			status: {
				id: 1,
				status: 'checkedin'
			},
			recipient: {
				recipientId: 27,
				memberId: 1606,
				firstName: null,
				lastName: null,
				recipientType: null,
				serviceType: 'Parcels',
				serviceId: 27
			}
		}
	];

	return parcelData;
}
