import * as types from "./actionTypes";
import Immutable from "seamless-immutable";
import _ from "lodash";




const initialState = Immutable({
    settings: {},
    errors: [],
    signature: undefined
});


export default function paymentGatewayReducer(state = initialState, action = {}) {

    switch (action.type) {

        case types.PAYMENT_GATEWAY_SETTINGS_LOAD_SUCCESS:
            return state.merge({
                settings: action.settings
            });
        case types.PAYMENT_GATEWAY_SETTINGS_LOAD_FAIL:

            return state.merge({
                errors: _.concat(state.errors, action.errors)
            });
        case types.PAYMENT_SIGNATURE_LOAD_SUCCESS:
            return state.merge({
                signature: action.signature
            });

        default:
            return state;
    }

}




export function getGatewaySettings(state) {
    return state.paymentGateway.settings;
}

export function getGatewayErrors(state) {
    return state.paymentGateway.errors;
}

export function getPaymentSignature(state) {
    return state.paymentGateway.signature;
}
