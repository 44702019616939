import React, { Component } from "react";

import "../../sass/components/customMessage/index.css";

var PropTypes = require('prop-types');
/*
*Custom Underline Message
*/
class CustomMessages extends Component {


    render() {
        const { text } = this.props
        return (
            <div className="custom-messgaes background">
                <span>{text}</span>
            </div>
        );
    }
}

CustomMessages.propTypes = {

    /**Text string to display */
    text: PropTypes.string

};

export default CustomMessages;