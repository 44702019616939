import * as types from "./actionTypes";
import Immutable from "seamless-immutable";


const initialState = Immutable({
  event: ""
});

export default function pageEventReducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.PAGE_EVENT_CHANGED:
      return state.merge({
        event: action.event
      });
    default:
      return state;
  }
}

export function getPageEvent(state) {
  return state.events.event;
}
