import * as config from "./config";
import authService from './loginService';

class TenantServices {
  async getTenants() {
    const url = `${config.TENANTS_ENDPOINT}`;
    const memberType = "Residents";
    const authToken = authService.getToken();
    var requestHeader = new Headers();
    requestHeader.append('Authorization', 'Bearer ' + authToken);

    const response = await fetch(url + "?type=" + memberType, {
      method: "GET",
      headers: requestHeader
    });

    if (!response.ok) {     
      config.errorPageRedirect(response);
      throw new Error(
        `getTenants service failed, HTTP status ${response.status}`
      );
    }

    const result = await response.json();

    return result;
  }
}

export default new TenantServices();
