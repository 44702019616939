import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import '../../sass/components/DetailList/index.css';
import DeleteBtn from '../../images/delete-btn/bin.png';
import DeleteWhiteBtn from '../../images/delete-bin-white/bin-white.png';
import InstructionBtn from '../../images/special-instructions/special-instructions.png';
import Popover from 'react-tiny-popover';

/**
 * Display list of up to two properties with an con in the third column. Data is passed in as an array and mapped to appropriate
 * column with the listsConfig.
 */

class DetailListNew extends Component {
	constructor(props) {
		super(props);
		this.state = {
			IsDeletePopoverOpen: true,
			activeRow: '',
			isPopoverOpen: ''
		};

		this.listsConfig = {
			types: [
				{
					name: 'visitor-add-meeting',
					headings: [ 'Visitor Name', 'Company', 'Special Instruction' ],
					fields: [ 'name', 'companyName', 'specialInstruction', 'index' ],
					iconClass: 'ion-close-round'
				},
				{
					name: 'visitor',
					headings: [ 'Visitor Name', 'Visitor Company' ],
					fields: [ 'fullName', 'company', 'index' ],
					iconClass: 'ion-checkmark-round'
				},
				{
					name: 'tenant',
					headings: [ 'Tenant Name', 'Company' ],
					fields: [ 'fullName', 'company', 'specialInstruction', 'index' ],
					iconClass: 'ion-checkmark-round'
				},
				{
					name: 'resident',
					headings: [ 'Tenant Name', 'Address' ],
					fields: [ 'fullName', 'address', 'specialInstruction', 'index' ],
					link: 'ion-checkmark-round'
				}
			]
		};
	}

	componentDidUpdate(prevProps, prevState) {
		//Change trigere fro selected row, clear selection on deselct trigger recieved
		if (this.props.selectDetailReset !== prevProps.selectDetailReset) {
			if (_.isUndefined(this.props.selectDetailReset))
				this.setState({
					selectedItem: undefined
				});
		}
	}

	// Utility function so fields: [] options above can optionally specifiy a path to a nested value
	// e.g. for a parcel location.locationName
	// https://stackoverflow.com/questions/6491463
	accessObjectByString(o, s) {
		s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
		s = s.replace(/^\./, ''); // strip a leading dot
		var a = s.split('.');
		for (var i = 0, n = a.length; i < n; ++i) {
			var k = a[i];
			if (k in o) {
				o = o[k];
			} else {
				return;
			}
		}
		return o;
	}
	


	handleClick = (e, idx) => {
		// const { target: { value } } = event;
		
		const selectedRow = idx;

		// And do whatever you need with it's value, for example change state
		this.setState({
			IsDeletePopoverOpen: false,
			isPopoverOpen: '',
			activeRow: selectedRow
		});
	};

	handleClickInstrunction = (idx) => {
		const selectedRow = idx;
		this.setState({
			IsDeletePopoverOpen: false,
			isPopoverOpen: selectedRow
		});
	};

	render() {
		const { listType, data, pageClass } = this.props;

		let { headingsAlone } = this.props;

		// const config = this.listsConfig.types.find(type => {
		//   return type.name === listType;
		// });
		const config = _.find(this.listsConfig.types, (type) => {
			return type.name === listType;
		});

		if (_.isUndefined(headingsAlone)) {
			headingsAlone = false;
		}

		if ((_.isUndefined(data) || data.length === 0) && headingsAlone === false) {
			return false;
		}
		
		return (
			<div className="list-section">
				<div className="heading">
					<div>{config.headings[0]}</div>
					<div className={this.props.classAppend && this.props.classAppend}>{config.headings[1]}</div>
					<div />
				</div>

				{data.length > 0 &&
					data.map((row, rowIndex) => {
						return (
							<div
								className={`${'detail '}${this.state.selectedItem === rowIndex ? 'selected' : ''}`}
								key={rowIndex}
							>
								<div
									className="info"
									onClick={() => {
										if (this.state.selectedItem === rowIndex) {
											this.setState({
												selectedItem: undefined
											});
											
											this.props.selectedDetail(undefined);
										} else {
											this.setState({
												selectedItem: rowIndex
											});
											this.props.selectedDetail(
												this.accessObjectByString(data[rowIndex], config.fields[3])
											);
										}
									}}
								>
									<div className="visitorName">
										{' '}
										<span> {this.accessObjectByString(data[rowIndex], config.fields[0])}</span>
									</div>
									<div className={'company'}>
										{' '}
										{this.accessObjectByString(data[rowIndex], config.fields[1])}
									</div>
								</div>
								{
									<div className="actions">
										{this.state.activeRow !== rowIndex ? data[rowIndex].specialInstruction !==
										'' ? (
											<div className="actions">
												<Popover
													isOpen={this.state.isPopoverOpen !== rowIndex ? false : true}
													onClickOutside={() => this.setState({ isPopoverOpen: '' })}
													position={[ 'top' ]} // preferred position
													disableReposition={true}
													content={
														<div className="pop-up-box popover-special-instruction">
															<div className="Combined-Shape">
																<div
																	className={'color-bar-special-instruction '}
																	style={
																		this.props.visitorStyle && {
																			backgroundColor: this.props.visitorStyle
																				.color
																		}
																	}
																/>
																<strong className="special-instruction-heading">
																	Special Instructions
																</strong>
																<p className="special-instruction-text">
																	{this.accessObjectByString(
																		data[rowIndex],
																		config.fields[2]
																	).slice(0, 160)}
																</p>
																<div className="triangle-support" />
																<div className="triangle-down" />
															</div>
														</div>
													}
												>
													{
														<span
														href=""
															className="instructions-icons"
															onClick={(e) => {
																this.handleClickInstrunction(rowIndex);
															}}
														>
															<img src={InstructionBtn} alt="" />
														</span>
													}
												</Popover>

												<span
													className="bin"
													onClick={(e) => {
														this.handleClick(e,rowIndex);
														if (this.props.resetForm === true) {
															this.props.resetForm(true);
														}
													}}
												>
													<img src={DeleteBtn} alt="" />
												</span>
											</div>
										) : (
											<div className="actions">
												<span
													className="bin"
													onClick={(e) => {
														this.handleClick(e,rowIndex);
														if (this.props.resetForm) {
															this.props.resetForm(true);
														}
													}}
												>
													<img src={DeleteBtn} alt="" />
												</span>
											</div>
										) : (
											<div className="actions">
												<span
													className="back"
													onClick={(e) => {
														this.setState({
															activeRow: '',
															isPopoverOpen: ''
														});
													}}
												>
													Back
												</span>
												<span
													className="bin del-bin"
													onClick={(e) => {
														
														this.props.actionClick(
															this.accessObjectByString(data[rowIndex], config.fields[3])
														);
														this.setState({
															activeRow: '',
															selectedItem: undefined
														});
													}}
												>
													<img src={DeleteWhiteBtn} alt="" />
												</span>
											</div>
										)}
									</div>
								}
							</div>
						);
					})}

				{data.length === 0 && (
					<div>
						<span className="list-messages">{this.props.emptyMessage}</span>
					</div>
				)}
			</div>
		);
	}
}

DetailListNew.propTypes = {
	/** First Heading Text for the coloumn */
	heading1: PropTypes.string,
	/** Second Heading Text for the coloumn */
	heading2: PropTypes.string,
	/** Third Heading Text for the coloumn */
	heading3: PropTypes.string,
	/** Message to display if the table is empty */
	emptyMessage: PropTypes.string,
	/** Function that is called for clicking on the icon */
	actionClick: PropTypes.func,
	/** If list data is empty can headings be displayed on their own? */
	headingsAlone: PropTypes.bool,
	/** The configuration to use from listsConfig in the component constrctor. (specifies icon and data to column mappings) */
	listType: PropTypes.string,
	/** An array of objects, fields are variable as long as configuration is available for mappings in listsConfig */
	data: PropTypes.array,
	/**The class name for the detail list */
	pageClass: PropTypes.string
};

export default DetailListNew;
