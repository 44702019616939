import * as types from "./actionTypes";
import Immutable from "seamless-immutable";
import _ from "lodash";

const initialState = Immutable({
  key: undefined,
  keys: [],
  statuses: [],
  keysLog: [],
  keyActionStatus: undefined,
  overdueKeyLog: [],
  logList: [],
  selectedKey: undefined,
  notificationSent: undefined
});

export default function keyReducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.KEYS_LOADED_SUCCESS:
      return state.merge({
        keys: action.keys
      });
    case types.KEYS_LOG_LOADED_SUCCESS:
      return state.merge({
        keysLog: _.filter(action.keysLog, o => o !== null)
      });
    case types.KEYS_STATUSES_LOADED_SUCCESS:
      return state.merge({
        statuses: action.statuses
      });
    case types.ASSIGN_KEY_SUCCESS:
      return state.merge({
        key: action.response,
        keyActionStatus: !_.isUndefined(action.response)
          ? { status: "assigned", action: true }
          : { action: false }
      });
    case types.OVERDUE_KEY_LOG_LOAD_SUCCESS:
      return state.merge({
        overdueKeyLog: action.logs
      });
    case types.RETURN_KEY_SUCCESS:
      return state.merge({
        key: action.response,
        keyActionStatus: !_.isUndefined(action.response)
          ? { status: "returned", action: true }
          : { action: false }
      });
    case types.ASSIGN_KEY_FAIL:
      return state.merge({
        keyActionStatus: { status: "fail", action: false }
      });
    case types.KEY_LOG_LIST_LOAD_SUCCESS:
      let list = _.isUndefined(state.selectedKey)
        ? action.loglist
        : _.filter(action.loglist, o => o.keyId === state.selectedKey.id);

      return state.merge({
        logList: list
      });

    case types.SET_KEY_SUCCESS:
      return state.merge({
        selectedKey: _.find(state.keys, o => o.id === action.key)
      });
    case types.KEY_STATUS_UPDATE_SUCCESS:
      return state.merge({
        keyActionStatus: !_.isUndefined(action.response)
          ? { status: "returned", action: true }
          : { action: false }
      });
    case types.KEY_STATUS_UPDATE_FAIL:
      return state.merge({
        keyActionStatus: { status: "fail", action: false }
      });
    case types.RESET_ACTION_STATUS:
      return state.merge({
        keyActionStatus: { action: false }
      })
    case types.NOTIFY_KEY_ASSIGNED_SUCCESS:
      return state.merge({
        notificationSent: !_.isUndefined(action.response) ? true : false
      });
    default:
      return state;
  }
}

export function loadAllKeys(state) {
  return state.keys.keys;
}
export function loadkeyStatuses(state) {
  return state.keys.statuses;
}
export function loadKeysLog(state) {
  return state.keys.keysLog;
}

export function loadKey(state) {
  return state.keys.key;
}

/**Used only in key test screen, to be removed */
export function assignedKey(state) {
  return state.keys.key;
}

export function getKeyActionStatus(state) {
  return state.keys.keyActionStatus;
}

export function getOverdueKeyLog(state) {
  return state.keys.overdueKeyLog;
}

export function getKeyLogList(state) {
  return state.keys.logList;
}

export function getSelectedKey(state) {
  return state.keys.selectedKey;
}

export function getNotificationSentsuccess(state) {
  return state.keys.notificationSent;
}