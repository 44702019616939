import * as types from "./actionTypes";
import PageEvent from "../../services/PegeEvent";

export function setPageEvent(event) {
  return async (dispatch, getState) => {
    try {
      setTimeout(() => {
        PageEvent.setPageEvent(event);
      }, 0);

      let pg_event = PageEvent.getPageEvent();

      dispatch({
        type: types.PAGE_EVENT_CHANGED,
        event: pg_event
      });
    } catch (error) {
      console.error(error);
    }
  };
}

export function getPageEvent() {
  return async (dispatch, getSatate) => {
    let pg_event = PageEvent.getPageEvent();

    dispatch({
      type: types.PAGE_EVENT_CHANGED,
      event: pg_event
    });
  };
}
