import React, { Component } from "react";

import _ from "lodash";

import "../../sass/components/Input/index.css";
import "../../sass/components/Input/customInput.css";

var PropTypes = require('prop-types');
/**
 * Render Input element with icon to auto clear field if required
 */
class CustomInput extends Component {
    constructor(props) {
        super(props);

        this.state = {
            render: true,
            value: _.isUndefined(this.props.value) ? "" : this.props.value,
            icon: "ion-close-round"
        };


    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.value !== nextProps.value) {
            this.setState({
                value: nextProps.value
            });
        }
    }

    handleClear(e) {
        this.setState({
            value: ""
        });

        this.props.resetField && this.props.resetField(true);
    }
    render() {
        const {
            label,
            name,
            placeHolder,
            handleChange,
            maxLength,
            type,
            clearable,
            icon
        } = this.props;
        const { value } = this.state;

        const blurBind = _.isUndefined(this.props.onBlur)
            ? {}
            : { onBlur: this.props.onBlur };

        let disabled =
            _.isUndefined(this.props.disabled) ||
                this.props.disabled === false
                ? false
                : "disabled";
        const valueBind = !_.isUndefined(this.props.value)
            ? { value: this.props.value }
            : {};


        return (
            <div
                className={"custom-input " +
                    (this.props.disabled ? "disabled" : "") +
                    " " +
                    (this.props.uneditable ? "uneditable" : "")
                }
            >
                <label htmlFor={"form-input-" + name} className="like-h4">
                    {label}
                </label>
                <div className={"input-wrapper"}>
                    <input
                        ref="input"
                        type={_.isUndefined(type) || type === "" ? "text" : type}
                        spellCheck="false"
                        name={name}
                        id={"form-input-" + name}
                        onChange={handleChange}
                        onPaste={e => {

                            e.persist();
                            setTimeout(() => {
                                handleChange(e);
                            }, 0);
                        }}
                        onKeyPress={e => {
                            if (this.props.handleKeyPress) {
                                if (e.key === "Enter") {
                                    if (!_.isEmpty(this.state.value))
                                        this.props.handleKeyPress(e)
                                    else
                                        this.props.handleKeyPress({ key: "Reset" })
                                }

                            }

                        }}
                        autoComplete="off"
                        maxLength={maxLength}
                        disabled={disabled}
                        {...this.props.parsley}
                        {...valueBind}
                        {...blurBind}
                        placeholder={placeHolder} // has priority over one added in autoSuggestProps so comes after
                    />
                    {clearable === true && value && <div className={`${"icon"} ${icon ? icon : this.state.icon}`} onClick={e => this.handleClear(e)}> </div>}
                </div>

            </div>
        );
    }
}

CustomInput.propTypes = {
    /** Optional function to run on blur of the input field */
    onBlur: PropTypes.func,
    /** If the input is disabled */
    disabled: PropTypes.bool,
    /** If the input is uneditable and for display only */
    uneditable: PropTypes.bool,
    /** Optional value so the input can be bound to an item in the parents state */
    value: PropTypes.string,
    /** Optional autosuggest properties when this component is rendered inside of react-autosuggest renderInputComponent method  */
    label: PropTypes.string,
    /** Name displayed within form  */
    name: PropTypes.string,
    /** Placeholder text  */
    placeHolder: PropTypes.string,
    /** Handlechange function passed in and called in parent  */
    handleChange: PropTypes.func,
    /** Used to switch between input type="text" (default) or 'select' */
    type: PropTypes.string,
    /** Optional obhext of data-paseley-**** validation properties */
    parsley: PropTypes.object,
    /** Optional ion classs */
    icon: PropTypes.string,
    /** Optional maximum character length */
    maxLength: PropTypes.string,

};

export default CustomInput;