import React, { Component } from "react";

class CustomDayWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = { event: undefined };
  }

  componentDidMount() {}

  render() {
    return (
      <div className="cdaywrapper" onClick={() => this.props.handleClick()} />
    );
  }
}

export default CustomDayWrapper;
