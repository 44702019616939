import React, { Component } from "react";

import { Link } from 'react-router-dom';

import "../../sass/components/VisitorsExpected/VisitorsExpected.css";

var PropTypes = require('prop-types');
/**
 * A general purpose Visitors Expected Component to display the day and time details of the Visitors and their Numbers
 */

class VisitorsExpected extends Component {
  render() {

    const { dayPeriodName, numberVisitors, timePeriod, linkValue, handleLinkSelected, moduleColor } = this.props;

    return (
      <div className="visitor-expected-block" onClick={() => {
        handleLinkSelected && handleLinkSelected();
      }}>
        <div className="Card-title">{dayPeriodName}</div>
        <div className="Number">
          <p style={{ color: moduleColor }}>{numberVisitors}</p>
        </div>
        <div className="Wording">

          {(numberVisitors > 1) ? <p style={{ color: moduleColor }}>visitors</p> : <p style={{ color: moduleColor }}>visitor</p>}

        </div>
        <div className="Wording-2">
          <p>are expected to arrive between</p>
        </div>
        <div className="Time-frame">
          <p style={{ color: moduleColor }}>{timePeriod}</p>
        </div>
        <div className="CTA-button">
          <a href="" onClick={e => handleLinkSelected(linkValue)}> Find out more</a>
          {    /**<a href="">Find out more</a>  */}
        </div>
      </div>


    );
  }
}

VisitorsExpected.propTypes = {

  /** Text displayed on the Component to denote the Day Period*/
  dayPeriodName: PropTypes.string,

  /** Text to display the Number of visitors Expected */
  numberVisitors: PropTypes.number,

  /** Time Period to denote the time when the visitor is Expected*/
  timePeriod: PropTypes.string,

  /** The link to redirect for more information */
  linkValue: PropTypes.string,

  /** The variable to display module color */
  moduleColor: PropTypes.string
};

export default VisitorsExpected;
