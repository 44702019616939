import React, { Component } from "react";
// import { connect } from "react-redux";

import _ from "lodash";
import "../../sass/components/DetailList/index.css";
var PropTypes = require('prop-types');
/**
 * Display list of up to two properties with an con in the third column. Data is passed in as an array and mapped to appropriate
 * column with the listsConfig.
 */

class DetailList extends Component {
  constructor(props) {
    super(props);

    this.listsConfig = {
      types: [
        {
          name: "visitor-add-meeting",
          headings: ["Visitor", "Company"],
          fields: ["name", "companyName", "index"],
          iconClass: "ion-close-round"
        },
        {
          name: "visitor-confirm-meeting",
          headings: ["Visitor", "Company"],
          fields: ["firstName", "companyName", "index"],
          iconClass: "ion-close-round"
        },
        {
          name: "parcel-check-in",
          headings: ["Parcel Barcode", "Location"],
          fields: ["barcode", "locationFullName", "index"],
          iconClass: "ion-close-round"
        },
        {
          name: "parcel-review",
          headings: ["Parcel Barcode", "Location"],
          fields: ["barcode", "locationFullName", "index"],
          iconClass: "ion-edit"
        },
        {
          name: "parcel-confirm",
          headings: ["Parcel Barcode", "Location"],
          fields: ["barcode", "location.locationName", "id"],
          iconClass: "ion-checkmark-round"
        },
        {
          name: "car",
          headings: ["Name", "Reg. Number"],
          fields: ["name", "reg"],
          iconClass: "ion-close-round"
        },
        {
          name: "parcel-overview-check-in",
          headings: ["Parcel Barcode", "Tenant"],
          fields: ["barcode", "fullName"],
          iconClass: "ion-arrow-up-c"
        },
        {
          name: "parcel-overview-check-out",
          headings: ["Parcel Barcode", "Tenant"],
          fields: ["barcode", "fullName"],
          iconClass: "ion-arrow-down-c"
        },
        {
          name: "visitor",
          headings: ["Visitor Name", "Visitor Company"],
          fields: ["fullName", "company", "id"],
          iconClass: "ion-checkmark-round"
        },
        {
          name: "tenant",
          headings: ["Tenant", "Company"],
          fields: ["fullName", "company", "id"],
          iconClass: "ion-checkmark-round"
        },
        {
          name: "resident-view-list",
          headings: ["Resident", "Address"],
          fields: ["fullName", "address", "id"],
          link: "view"
        },
        {
          name: "key-view-list",
          headings: ["Status", "Address"],
          fields: ["status", "address", "id"],
          link: "view"
        },
        {
          name: "related-key-list",
          headings: ["Key ID Code", "Key"],
          fields: ["barcode", "address", "id"],
          iconClass: "ion-forward"
        }
      ]
    };
  }

  // Utility function so fields: [] options above can optionally specifiy a path to a nested value
  // e.g. for a parcel location.locationName
  // https://stackoverflow.com/questions/6491463
  accessObjectByString(o, s) {
    s = s.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
    s = s.replace(/^\./, ""); // strip a leading dot
    var a = s.split(".");
    for (var i = 0, n = a.length; i < n; ++i) {
      var k = a[i];
      if (k in o) {
        o = o[k];
      } else {
        return;
      }
    }
    return o;
  }

  render() {
    const { listType, data, pageClass } = this.props;

    let { headingsAlone } = this.props;

    // const config = this.listsConfig.types.find(type => {
    //   return type.name === listType;
    // });
    const config = _.find(this.listsConfig.types, type => {
      return type.name === listType;
    });

    if (_.isUndefined(headingsAlone)) {
      headingsAlone = false;
    }

    if ((_.isUndefined(data) || data.length === 0) && headingsAlone === false) {
      return false;
    }

    return (
      <table className={"detail-list type-" + listType + " " + pageClass}>
        <colgroup>
          <col className={"column-" + config.fields[0]} />
          <col className={"column-" + config.fields[1]} />
          <col className="column-icon" />
        </colgroup>
        <thead>
          <tr>
            <th className="small-copy brownish-grey">{config.headings[0]}</th>
            <th className="small-copy brownish-grey">{config.headings[1]}</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {data.length > 0 &&
            data.map((row, rowIndex) => {
              return (
                <tr key={rowIndex} className="rows">
                  <td className="strong">
                    {this.accessObjectByString(
                      data[rowIndex],
                      config.fields[0]
                    )}
                  </td>
                  <td className="light-copy">
                    {this.accessObjectByString(
                      data[rowIndex],
                      config.fields[1]
                    )}
                  </td>
                  <td
                    className={
                      "light-copy " +
                      (_.isUndefined(this.props.actionClick)
                        ? " no-action"
                        : "")
                    }
                    onClick={() => {
                      this.props.actionClick(
                        this.accessObjectByString(
                          data[rowIndex],
                          config.fields[2]
                        )
                      );
                    }}
                  >
                    {!_.isUndefined(config.iconClass) ? (
                      <span className={config.iconClass} />
                    ) : !_.isUndefined(config.link) ? (
                      <span>{config.link}</span>
                    ) : (
                      ""
                    )}
                  </td>
                </tr>
              );
            })}
        </tbody>
        <tfoot>
          {data.length === 0 && (
            <tr>
              <td colSpan="3">
                <p className="small-copy brownish-grey">
                  {this.props.emptyMessage}
                </p>
              </td>
            </tr>
          )}
        </tfoot>
      </table>
    );
  }
}

DetailList.propTypes = {
  /** Message to display if the table is empty */
  emptyMessage: PropTypes.string,
  /** Function that is called for clicking on the icon */
  actionClick: PropTypes.func,
  /** If list data is empty can headings be displayed on their own? */
  headingsAlone: PropTypes.bool,
  /** The configuration to use from listsConfig in the component constrctor. (specifies icon and data to column mappings) */
  listType: PropTypes.string,
  /** An array of objects, fields are variable as long as configuration is available for mappings in listsConfig */
  data: PropTypes.array,
  /**The class name for the detail list */
  pageClass: PropTypes.string
};

export default DetailList;
