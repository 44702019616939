import * as types from "./actionTypes";
import Immutable from "seamless-immutable";

const initialState = Immutable({
  tenant: undefined,
  tenants: [],
  selectedTenant: undefined
});

export default function tenantReducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.TENANT_LOADED_SUCCESS:
      return state.merge({
        tenant: action.tenant
      });
    case types.TENANTS_LOADED_SUCCESS:
      return state.merge({
        tenants: action.tenants
      });
    case types.TENANT_SELECTED:
      return state.merge({
        selectedTenant: action.selectedTenant
      });
    default:
      return state;
  }
}

export function getTenant(state) {
  return state.tenants.tenant;
}

export function getAllTenants(state) {
  return state.tenants.tenants;
}

export function getSelectedTenant(state) {
  return state.tenants.selectedTenant;
}
