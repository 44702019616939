import React, { Component } from "react";
import autobind from "react-autobind";
import { connect } from "react-redux";
import _ from "lodash";
import {withRouter} from 'react-router-dom';
import FeatureArea from "../../../components/FeatureArea/FeatureArea";
import ButtonGroup from "../../../components/Button/ButtonGroup";
import InputComponent from "../../../components/Input/Input";
import FooterNavigation from "../../../components/FooterNavigation/FooterNavigation";
import AddrLookUpAutoSuggest from "../../../components/AddrLookUpAutoSuggest/AddrLookUpAutoSuggest";

import * as residentActions from "../../../store/Residents/actions";
import * as residentSelectors from "../../../store/Residents/reducer";
import * as moduleSelectors from "../../../store/ServiceModules/reducer";
import * as conciergeSelectors from "../../../store/auth/reducer";

import "../../../sass/containers/residents.css";

import * as $ from "jquery";

class AddResidentScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      type: "residents",
      fname: "",
      lname: "",
      email: "",
      phone: "",
      addresslookupId: "",
      address: "",
      addressValid: false,
      residentCreated: false,
      resident: undefined,
      msgError: "",
      residentGroups: [],
      groupId: undefined,
    };

    this.props.dispatch(residentActions.getAllResidents());
    this.props.dispatch(residentActions.GetConciergeGroups());
    this.addResidentValidation = null;

    autobind(this);
  }

  componentDidMount() {
    this.addValidation();

    if(this.props.conciergeDetails && this.props.conciergeDetails.roles){
      this.setState({
        residentGroups  : _.map(this.props.conciergeDetails.roles, (o, index) => {
          
          return {name : o, label : o, value : index};
        })
      });
    }
   
    
  }
  // componentWillUnmount() {
  //   this.addResidentValidation.destroy();
  // }


 componentDidUpdate(prevProps, prevState) {
    if (
      this.props.residentCreatedstatus !== prevProps.residentCreatedstatus &&
      !_.isUndefined(this.props.residentCreatedstatus.status)
    ) {
      if (this.props.residentCreatedstatus.status === true) {
        this.setState({
          residentCreated: this.props.residentCreatedstatus.status,
          resident: this.props.newResident
        });

      } else {
        this.setState({
          residentCreated: this.props.residentCreatedstatus.status,
          error: this.props.residentCreatedstatus.error
        });
      }
    }

    if(this.props.conciergeDetails !== prevProps.conciergeDetails && this.props.conciergeDetails){
     this.setState({   
      residentGroups :this.props.conciergeDetails.roles &&  _.map( this.props.conciergeDetails.roles, (o, index) => {      
               return {name : o, label : o, value : index};
         })
      });
    }
   
   
  }


  sendRegistrationActivation(status, resi) {
    if (status === true && !_.isUndefined(resi)) {
      let data = {
        id: resi.id,
        email: resi.email
      };

      this.props.dispatch(
        residentActions.sendRegistrationActivation(data, success => {
          if (success === true) {
            this.setState({
              messageSent: true,
              msgError: ""
            })

            this.props.history.push({
              pathname: "/concierge/residents/profile",
              state: {
                residentId: resi.id
              }
            });
          } else {
            this.setState({
              messageSent: false,
              msgError: "Unable to send message"
            });
          }
        })
      );
    }
  }
  addValidation() {
    this.addResidentValidation = $(this.refs.validate).parsley({
      classHandler: function (el) {
        return el.$element.closest("div:not(.input-wrapper):not(.Select)");
      }
    });
  }

  validate(fromSubmit = false) {
    this.addResidentValidation.validate({ force: true });

    let valid = this.addResidentValidation.isValid() && this.state.addressValid;

    // if (!this.state.addressValid) {
    //   this.setState({
    //     error: "Please enter an existing tenant address"
    //   });
    // }
    this.setState({
      addResidentFormValid: valid,
      addResidentFormUsed: true
    });

    if (valid === true && fromSubmit === true) {
      //this.formReset();

      let groupObj = _.find(this.state.residentGroups, { value : this.state.groupId}); 
      let residentData = {
        phone: this.state.phone,
        firstName: this.state.fname,
        lastName: this.state.lname,
        email: this.state.email,
        address: this.state.address,
        addressId: this.state.addresslookupId,
        groupName : !_.isUndefined(groupObj) ? groupObj.name : ""
      };

      this.props.dispatch(
        residentActions.createNewResident(residentData, success => {
          if (success === true) {
            this.returnToNewProfile();
          }else{
         
            this.setState({
              error: "Unable to create member",
              //addResidentFormValid : false,
              residentCreated : false
            });
          }
        })
      );

      this.setState({
       // addResidentFormUsed: false
      });
    }
  }

  returnToNewProfile() {
    const { newResident } = this.props;
    const { residentCreated } = this.state;
    if (!_.isUndefined(newResident)) {
      this.sendRegistrationActivation(residentCreated, newResident);
    }
  }

  formReset() {
    this.setState({
      fname: "",
      lname: "",
      email: "",
      addresslookupId: "",
      phone: "",
      address: ""
    });

    //this.refs["addr-auto-suggest"].getWrappedInstance().reset();

    $(this.refs.validate)
      .get(0)
      .reset();

    this.addResidentValidation.destroy();

    // Reset all the select input components to the placeholder value
    for (let key in this.refs) {
      if (key.indexOf("formSelect") === 0) {
        this.refs[key].reset();
      }
    }

    setTimeout(() => {
      this.addValidation();
    }, 0);
  }

  handleInputChange(e) {
    let field = e.target.name;
    let value = e.target.value;

    this.setState({
      [field]: value
    });
  }
  handleGroupChange(val){
    
    let field = val.target.name;
		let value = val.target.value;
		this.setState({
			[field]: value,
			groupId: value,
		});
  }
  changeSubNav() {
    //navBut = document.getElementsByClassName('visitors-button').attr(order,1;;
  }
  render() {

    const {
      fname,
      lname,
      email,
      phone,
      addressDefault,
      residentGroups

    } = this.state;
   
    const currentModule = _.find(
      this.props.modules,
      o => o.title === "Residents"
    );
   
    return (
      <div>
        <div className={"container type-" + this.state.type + " edit_profile"}>
          <FeatureArea
            type={this.state.type}
            pageHeader="New Resident"
            areaTagline="Please enter information below"
            areaTitle="Residents"
            overlayColor={currentModule && currentModule.imageOverlayTextColour}
            activeMenu={true}
          />
          <form
            className="content-wrapper"
            ref="validate"
            onSubmit={e => {
              e.preventDefault();
            }}
            data-parsley-errors-container="#validation-messages"
            data-parsley-focus="none"
          >
            <div className="inputs two-column">
              <InputComponent
                label="First Name"
                name="fname"
                placeHolder="First Name"
                handleChange={e => {
                  this.handleInputChange(e);
                }}
                value={fname}
                parsley={{
                  "data-parsley-pattern": "/^[a-z ,.'-]+$/i", // alpha chars only https://stackoverflow.com/questions/23415795/
                  "data-parsley-minlength": 2,
                  "data-parsley-required": true,
                  "data-parsley-error-message":
                    "Please enter a valid first name"
                }}
              />
              <InputComponent
                label="Last Name"
                name="lname"
                placeHolder="Last Name"
                handleChange={e => {
                  this.handleInputChange(e);
                }}
                value={lname}
                parsley={{
                  "data-parsley-pattern": "/^[a-z ,.'-]+$/i", // alpha chars only https://stackoverflow.com/questions/23415795/
                  "data-parsley-minlength": 2,
                  "data-parsley-required": true,
                  "data-parsley-error-message": "Please enter a valid last name"
                }}
              />
            </div>
            <div className="inputs">
              <AddrLookUpAutoSuggest
                ref="addr-auto-suggest"
                labelText="Address"
                placeholder="Search Address"
                clearableIfValid={true}
                //value={this.state.address}
                onSelected={data => {
                  this.setState({
                    addresslookupId: data.addresslookupId,
                    address: data.address,
                    addressValid: data.valid
                  });

                  setTimeout(() => {
                    this.state.addResidentFormUsed && this.validate();
                  }, 0);
                }}
                defaultText={dataVal => {
                  this.setState({
                    addressDefault: dataVal
                  });
                }}
              />
            </div>

            <div className="inputs">
              <InputComponent
                label="Email"
                name="email"
                placeHolder="Email"
                handleChange={e => {
                  this.handleInputChange(e);
                  //this.validate();
                }}
                value={email}
                parsley={{
                  "data-parsley-required": true,
                  "data-parsley-type": "email",
                  //"data-parsley-validate-if-empty": true,
                  "data-parsley-error-message": "Please enter a valid email"
                }}
              />
              </div>
              <div className="inputs">
              <InputComponent
                label="Phone"
                name="phone"
                placeHolder="Phone"
                handleChange={e => {
                  this.handleInputChange(e);
                }}
                value={phone}
                parsley={{
                  //"data-parsley-type": "digits",
                  "data-parsley-validate-if-empty": false,
                  "data-parsley-minlength": 11,
                  "data-parsley-error-message":
                    "Please enter a valid phone number"
                }}
              />
            </div>


            <div className={'date_select ' + this.state.locationValidationStatus}>
            <InputComponent
              ref="groupRef"
              label={'Member Group'}
              type={'select'}
              name="groupSelect"
              placeHolder="Select member group"
              className="text-box"
              //value={memberGroup}
               handleChange={(e) => this.handleGroupChange(e)}
              options={_.map(this.props.conciergeDetails.roles, (o, index) => {
          
                return {name : o, label : o, value : index};
              })}
            />
          </div>

            <div
              className={
                "validation-messages" +
                (this.state.addResidentFormValid ? " valid" : " invalid")
              }
              id="validation-messages"
            >
              {this.state.addResidentFormUsed &&
                this.state.addResidentFormUsed === true &&
                addressDefault &&
                this.state.addressValid === false && (
                  <p className="message error"> {this.state.error}</p>
                )}
              <div>
                {!_.isUndefined(this.props.residentCreatedstatus) &&
                  (this.state.residentCreated === true ? (
                    <p className="message type-success">Member created</p>
                  ) : (
                      this.state.error && (
                        <p className="message error"> {this.state.error}{this.props.residentError ? this.props.residentError : "" }</p>
                      )
                    ))}
                {this.props.registerNotificationSent && this.state.messageSent === true ?
                  (<p className="message type-success">Message sent</p>) : (<p className="message error"> {this.state.msgError} </p>)
                }
              </div>
            </div>

            <ButtonGroup
              sizes={["smedium", "medium"]}
              leftText={"Cancel"}
              rightText="Save Resident"
              //rightDisabled={this.state.addResidentFormValid === false}
              leftClick={() => {
                this.props.history.push({
                  pathname: "/",
                  state: { setModule: "Residents" }
                });
              }}
              rightClick={e => {
                this.validate(true);
              }}
            />
          </form>
        </div>
        <FooterNavigation />
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    modules: moduleSelectors.loadModules(state),
    residentCreatedstatus: residentSelectors.residentCreatedStatus(state),
    newResident: residentSelectors.getNewResident(state),
    registerNotificationSent: residentSelectors.registerNotificationSent(state),
    residentGroups: residentSelectors.GetConciergeGroups(state),
    conciergeDetails : conciergeSelectors.getConciergeDetails(state), 
    residentError : residentSelectors.residentError(state)
  };
}
export default withRouter(connect(mapStateToProps)(AddResidentScreen));
