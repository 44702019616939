import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as configHelper from '../../helpers/configHelper';
import Button from '../../components/Button/Button';
import autoBind from 'react-autobind';
import _ from 'lodash';

import * as serviceModulesActions from '../../store/ServiceModules/actions';
import * as settingSelector from '../../store/ConciergeSetting/reducer';
import '../../sass/components/visitorLogCard/visitorSearchResult.css';
import { browserHistory } from 'react-router';

const PortalTypes = configHelper.PortalTypes;
class VisitorSearchResult extends Component {
	constructor(props) {
		super(props);
		this.state = {
			visitors: [],
			btnStatus: 'unchecked hidden',
			btnText: 'Check-in',
			more: 'hidden',
			extra: '',
			moreBtnText: 'More',
			checked: 'false',
			checkInTimeText: 'Expected',
			portalType:
				this.props.setting && !_.isUndefined(this.props.setting.portalType) ? this.props.setting.portalType : ''
		};
		autoBind(this);
	}

	UNSAFE_componentWillReceiveProps(nextProps, nextState) {
		if (this.props.setting !== nextProps.setting && !_.isUndefined(nextProps.setting)) {
			this.setState({
				portalType: nextProps.setting.portalType
			});
		}
	}

	handleClick(e) {
		this.setState({
			btnStatus: 'checked unhide'
		});

		// if(this.state.btnText =="View")
		// {}
	}

	handleNo(e) {
		this.setState({
			btnStatus: 'unchecked hidden'
		});
	}

	handleYes(e) {
		this.setState({
			btnStatus: 'unchecked hidden checked-in',
			btnText: 'View',
			checkInTimeText: 'Check-in',
			moreBtnText: 'More'
		});
	}

	getTimeNow() {
		// const clock = moment();
	}

	handleMore(e) {
		if (this.state.checked === 'false') {
			this.setState({
				checked: 'true',
				extra: 'extra',
				more: 'unhide',
				moreBtnText: 'Less'
			});
		} else {
			this.setState({
				extra: '',
				more: 'hidden',
				moreBtnText: 'More',
				checked: 'false'
			});
		}
	}

	handleView(val) {
		this.props.gotoProfile(this.props.meetingId, this.props.data);
	}

	render() {
		const {
			company,
			timeExpected,
			dueDate,
			name,
			specialInstructions,
			tenant,
			companyName,
			status,
			isDisabled,
			id,
			tenantAddress,
			meetingId,
			hasMargin,
			textcolor,
			statusLabel
		} = this.props;

		
		if (status === 'notified') {
			return (
				<div className="visitor-section">
					<div className={'visitorLogCard ' + this.state.btnStatus}>
						<div className="infoRow">
							<span className="lighten">Visitor</span>

							<div className="infoColumn">
								<span className="visitorsName">{name}</span>
								<span className="thirdDetail">{company}</span>
							</div>
							<div className="expectedTime">
								<span className="lighten">{statusLabel}</span>
								<span className="visitorsName">{timeExpected}</span>
							</div>
						</div>
						<div className="infoRow">
							<span className="lighten">Tenant</span>

							<div className="infoColumn">
								<span className="tenantsName">{tenant}</span>
								{this.state.portalType === PortalTypes.Commercial ? (
									<span className="thirdDetail">{companyName}</span>
								) : (
									<span className="thirdDetail">{tenantAddress}</span>
								)}
							</div>

							<Button
								// className="checkin"
								handleClick={this.handleClick}
								size="customCheckIn"
								text={this.state.btnText}
								isDisabled={isDisabled}
							/>
						</div>
						<div className={'firstSlide ' + this.state.btnStatus}>
							<div className="visitor">
								<span className="title">Do you wish to check-in this visitor?</span>
								<span className="header">{name}</span>
							</div>
							
							<div className="specials">
								{!_.isUndefined(specialInstructions) && specialInstructions !='' && specialInstructions != null ?
								 (<span className="title">Special Instruction</span>) : ""}

								{!_.isUndefined(specialInstructions) && specialInstructions !='' && specialInstructions != null ?
								(<span className="details">{specialInstructions}</span> ) : ""}
								
              				</div>
							  

							<div className="btnGroup">
								<Button
									size="custom"
									text="Yes"
									handleClick={() => {
										// this.handleYes();
										this.props.handleSubmit(id);
									}}
								/>
								<Button size="custom" text="No" handleClick={this.handleNo} />
							</div>
						</div>
					</div>
				</div>
			);
		} else if (status === 'checkedin') {
			return (
				<div className={'visitorLogCard checked-in ' + this.state.extra}>
					<div className="infoRow">
						<span className="lighten">visitor</span>

						<div className="infoColumn">
							<span className="visitorName">{name}</span>
							<span className="thirdDetail">{company}</span>
						</div>
						<div className="expectedTime">
							<span className="lighten">{statusLabel}</span>
							<span className="visitorName">{timeExpected}</span>
						</div>
					</div>
					<div className="infoRow">
						<span className="lighten">Tenant</span>

						<div className="infoColumn">
							<span className="tenantName">{tenant}</span>
							{this.state.portalType === PortalTypes.Commercial ? (
								<span className="thirdDetail">{companyName}</span>
							) : (
								<span className="thirdDetail">{tenantAddress}</span>
							)}
						</div>
						<Button
							handleClick={this.handleView}
							size="customCheckIn"
							text="View" //{this.state.moreBtnText}
						/>
					</div>
				</div>
			);
		} else if (_.isUndefined(status) || status === '') {
			return (
				<div className={'visitor-section ' + this.props.view}>
					<div className={'visitorLogCard ' + this.state.btnStatus}>
						<div className="infoRow">
							<span className="lighten">Visitor</span>

							<div className="infoColumn">
								<span className="visitorName">{name}</span>
								<span className="thirdDetail">{company}</span>
							</div>
							<div className="expectedTime">
								<span className="lighten">{statusLabel}</span>
								<span className="visitorName">{timeExpected}</span>
							</div>
						</div>
						<div className="infoRow">
							<span className="lighten">Tenant</span>

							<div className="infoColumn">
								<span className="tenantName">{tenant}</span>
								{this.state.portalType === PortalTypes.Commercial ? (
									<span className="thirdDetail">{companyName}</span>
								) : (
									<span className="thirdDetail">{tenantAddress}</span>
								)}
							</div>
							<Button
								handleClick={this.handleView}
								size="customCheckIn"
								text="View" //{this.state.moreBtnText}
							/>
						</div>
					</div>
				</div>
			);
		} else {
			return <span />;
		}
	}
}

VisitorSearchResult.propType = {
	name: PropTypes.string,
	status: PropTypes.string,
	sender: PropTypes.string,
	dueDate: PropTypes.string,
	deliveryDate: PropTypes.string,
	company: PropTypes.string,
	parcelDetail: PropTypes.string,
	specialInstructions: PropTypes.string,
	tenantAddress: PropTypes.string
};

function mapStateToProps(state, ownProps) {
	return {
		setting: settingSelector.loadGlobalSetting(state)
	};
}

export default connect(mapStateToProps)(VisitorSearchResult);
