// import _ from "lodash";
// import * as config from "../config";

class ServicesService {
  // async getServices(serviceId) {
  //   const companyId = `${config.COMPANY_ID}`;

  //   const url = `${
  //     config.BOOKING_BUG_API
  //     }/${companyId}/services?id=${serviceId}`;

  //   var reqHeaders = new Headers();
  //   reqHeaders.append("Content-Type", "application/x-www-form-urlencoded");
  //   reqHeaders.append("App-Key", config.APP_KEY);
  //   reqHeaders.append("App-Id", config.APP_ID);

  //   const response = await fetch(url, {
  //     method: "GET",
  //     headers: reqHeaders
  //   });

  //   if (!response.ok) {
  //     config.errorPageRedirect(response);
  //     throw new Error(
  //       `ServicesService getServices failed, HTTP status ${response.status}`
  //     );
  //   }

  //   const data = await response.json();
  //   return _.get(data, "_embedded.services");
  // }

  _validateUrl(url = "") {
    return url.startsWith("http") ? url : undefined;
  }
}

export default new ServicesService();
