import React, { Component } from "react";
import { Link } from 'react-router-dom';
import "../../sass/components/PastVisitors/PastVisitors.css";

var PropTypes = require('prop-types');
/**
 * A general purpose Visitors Expected Component to display the day and time details of the Visitors and their Numbers
 */

class PastVisitors extends Component {
  render() {

    const { dayPeriodName, expectedVisitors, checkedinVisitors, notshowvisitors, linkValue, handleLinkSelected } = this.props;


    return (
      <div className="past-visitor-block" onClick={() => {
        handleLinkSelected && handleLinkSelected();
      }}>
        <div className="Card-title">{dayPeriodName}</div>
        <div className="Number" style={{ color: this.props.textDataColor }}>{expectedVisitors}</div>
        <div className="Wording expected">visitors were expected</div>
        <div className="Number" style={{ color: this.props.textDataColor }}>{checkedinVisitors}</div>
        <div className="Wording cheched-in">visitors were checked-in</div>
        <div className="Number show-up-heading" style={{ color: this.props.textDataColor }}>{notshowvisitors}</div>
        <div className="Wording show-up">visitors that did not arrive</div>
        <div className="CTA-button">
          <Link style={{ 'cursor': 'pointer' }} onClick={e => handleLinkSelected(linkValue)}> Find out more</Link>
          {    /**<a href="">Find out more</a>  */}
        </div>
      </div>


    );
  }
}

PastVisitors.propTypes = {

  /** Text displayed on the Component to denote the Day Period*/
  dayPeriodName: PropTypes.string,

  /** Text to display the Number of visitors Expected */
  numberVisitors: PropTypes.string,

  /** Time Period to denote the time when the visitor is Expected*/
  timePeriod: PropTypes.string,

  /** The link to redirect for more information */
  linkValue: PropTypes.string
};

export default PastVisitors;
