import * as types from "./actionTypes";
import loginService from "../../services/loginService";
import bbugLoginService from "../../services/BookingBugServices/LoginService";
//import engaebbugLoginServices from "../../services/EngageBookingBugServices/LoginService";
import * as bookingAuthActions from "../BookingAuth/actions";

import moduleService from "../../services/ModulesServices";

export function login(userName, password, callback = () => { }) {
  return async (dispatch, getState) => {
    try {
      let authToken = loginService.getToken();

      if (authToken) {
        const loginData = {
          authToken: loginService.getToken(),
          isLoggedIn: true
        };
        //console.log("token still valid, user is logged in");
        dispatch({ type: types.LOGIN_SUCESSFUL, loginData });
        //  dispatch(bookingAuthActions.login());
        dispatch(fetchConciergeDetails());

      } else {
        const loginData = await loginService.login(userName, password);
        dispatch({ type: types.LOGIN_SUCESSFUL, loginData });

        dispatch(bookingAuthActions.login());
        dispatch(fetchConciergeDetails());
      }
      callback(true);
    } catch (error) {
      callback && callback(false);
      dispatch({ type: types.LOGIN_FAIL, response: false });
      //console.error(error);
    }
  };
}

export function fetchConciergeDetails(callback = () => { }) {
  return async (dispatch, getState) => {
    try {

      let details = await loginService.getConciergeDetails();
      dispatch({ type: types.CONCIERGE_AUTHORIZED_SUCCESSFULL, detail: details });

      callback(true);
    } catch (error) {
      callback(false);
      dispatch({ type: types.CONCIERGE_AUTHORIZED_FAIL, status: false });

    }
  }
}
export function logout(callback = () => { }) {
  return async (dispatch, getState) => {
    try {
      loginService.logout();
      bbugLoginService.logout();
      moduleService.clearServiceModule();
      const loginData = {
        isLoggedIn: false
      };
      console.log("logging out....");
      dispatch({ type: types.LOGGED_OUT, loginData });
      console.log("logged out....");
      callback(true);
    } catch (error) {
      callback(false);
      console.error(error);
    }
  };
}
export function setEmail(value) {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: types.EMAIL_CHANGED, value });
    } catch (error) {
      console.error(error);
    }
  };
}

export function verifyRequest(value, success = () => { }) {
  return async (dispatch, getState) => {
    try {
      const valid = await loginService.verifyResetRequest(value);
      if (valid)
        dispatch({
          type: types.PASWORD_REQUEST_VERIFY_SUCCESS,
          validRequest: valid
        });
      else if (!valid)
        dispatch({
          type: types.PASWORD_REQUEST_VERIFY_FAIL,
          validRequest: false
        });

      success(true);
    } catch (error) {
      success(false);
      console.error(error);
    }
  };
}

export function resetpassword(data, success = () => { }) {
  return async (dispatch, getState) => {
    try {
      const result = await loginService.changePassword(data);
      dispatch({
        type: types.PASSWORD_RESET_SUCCESSFULL,
        passwordReset: result
      });
      success(true);
    } catch (error) {
      success(false);
      console.error(error);
    }
  };
}

export function setPassword(value) {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: types.PASSWORD_CHANGED, value });
    } catch (error) {
      console.error(error);
    }
  };
}

export function ResetPasswordEmail(value, success = () => { }) {
  return async (dispatch, getState) => {
    try {
      const data = {
        email: value,
        type: "password-reset",
        isforgotpassword: true
      };
      const resetPass = await loginService.requestResetPass(data);

      if (resetPass)
        dispatch({
          type: types.PASSWORD_RESET_REQUEST_SENT_SUCCESS,
          resetPass
        });
      else
        dispatch({
          type: types.PASWORD_REQUEST_VERIFY_SUCCESS,
          validRequest: false
        });

      success(true);
    } catch (error) {
      success(false);
      console.error(error);
    }
  };
}

export function setRequestStatus(valid) {
  return { type: types.PASWORD_REQUEST_VERIFY_FAIL, validRequest: valid };
}
