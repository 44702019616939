//API
import { createBrowserHistory } from 'history';
import Config from '../config';
var apiDomain = Config.apiDomain;
var bookingBugApi = Config.bookingbugApi;
var bookingBugPwd = Config.bookingbugPwd;
//const currentHostname = document.location.hostname;
// if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
//    apiDomain = "http://demo.engage.local";

//   if (currentHostname.indexOf("ngrok") !== -1) {
//     apiDomain = "http://ba05bae1.ngrok.io:80"; //....
//   }
// } else if (currentHostname.indexOf("ept-dev.co.uk") !== -1) {
//   apiDomain = "http://engageresi.ept-dev.co.uk";

// } else if (currentHostname.indexOf("ept-stage.co.uk") !== -1) {
//   apiDomain = "http://engageresi.ept-stage.co.uk/";

// } else {
//   apiDomain = window.location.origin;
// }

const browserHistory = createBrowserHistory();
export const visitorStatuses = {
	checkedIn: 'checkedin',
	checkedOut: 'checkedout',
	dueForArrival: 'dueforarrival',
	notified: 'notified'
};

export const CONCIERGE_API = apiDomain + '/api';
export const BOOKING_BUG_API = bookingBugApi;
export const LOGIN_ENDPOINT = BOOKING_BUG_API + '/login';
export const BOOKINGBUG_ADMIN_ENDPOINT = BOOKING_BUG_API + '/admin';

//ENDPOINTS

//Auth Endpoint
export const CONCIERGE_AUTH_ENDPOINT = apiDomain + '/oauth/concierge/token';
export const PARCELS_ENDPOINT = CONCIERGE_API + '/parcels';
export const MEMBERS_ENDPOINT = CONCIERGE_API + '/members';
export const SERVICE_MODULES_ENDPOINT = CONCIERGE_API + '/conciergemodules';
export const ADDRESS_LOOKUP_ENDPOINT = CONCIERGE_API + '/addresslookup';
export const KEYS_ENDPOINT = CONCIERGE_API + '/keys';
export const VISITORS_ENDPOINT = CONCIERGE_API + '/visitors';
export const ENGAGE_BOOKINGBUG_API = CONCIERGE_API + '/bookingbug';
//export const VISITORS_ENDPOINT = apiDomain + "/api/visitors";
export const MEETINGS_ENDPOINT = CONCIERGE_API + '/meetings';
//export const MEMBERS_ENDPOINT = apiDomain + "/api/members";
export const CONCIERGE_SETTINGS_ENDPOINT = CONCIERGE_API + '/conciergesettings';
//Parcels API
export const PARCELS_STATUS_CODES_SUCCESS_ENDPOINT = PARCELS_ENDPOINT + '/allstatus';

export const PARCEL_RETURN_REASON_CODES_ENDPOINT = PARCELS_ENDPOINT + '/returnreasons';

export const PARCEL_RECORD_ENDPOINT = PARCELS_ENDPOINT + '/record';
export const ALL_PARCELS_ENDPOINT = PARCELS_ENDPOINT + '/all';
export const PARCEL_LOCATIONS_ENDPOINT = PARCELS_ENDPOINT + '/locations';
export const PARCEL_STATUS_REASONS_ENDPOINT = PARCELS_ENDPOINT + '/statusreason';
export const PARCEL_RETURN_ENDPOINT = PARCELS_ENDPOINT + '/return';

export const PARCEL_ADD_ENDPOINT = PARCELS_ENDPOINT + '/checkin';
export const PARCEL_CHECKOUT_ENDPOINT = PARCELS_ENDPOINT + '/collected';
export const NOTIFY_PARCEL_CHECKED_IN = PARCELS_ENDPOINT + '/notifycheckin';
export const NOTIFY_PARCEL_COLLECTED = PARCELS_ENDPOINT + '/notifycollected';
export const NOTIFY_PARCELS_CHECKED_IN = PARCELS_ENDPOINT + '/notifycheckinparcels';
export const NOTIFY_PARCELS_COLLECTED = PARCELS_ENDPOINT + '/notifycollectedparcels';
export const PARCEL_EXPECTEDARRIVEDBYDATE = `${PARCELS_ENDPOINT}${'/expectedArrivedByDate'}`;
export const PARCEL_REGISTEREXPECTEDLIST = `${PARCELS_ENDPOINT}${'/registerexpectedlist'}`;
export const PARCEL_SETTINGS = `${PARCELS_ENDPOINT}${'/settings'}`;
export const PARCELS_BY_STATUS = `${PARCELS_ENDPOINT}${'/byStatus'}`;
export const UPDATE_PARCEL = `${PARCELS_ENDPOINT}${'/update'}`;
export const PARCEL_NOTIFY = `${PARCELS_ENDPOINT}${'/notify'}`;
//Tenants Endpoint
export const TENANTS_ENDPOINT = MEMBERS_ENDPOINT + '/tenants';
export const RESIDENTS_ENDPOINT = MEMBERS_ENDPOINT + '/residents';
export const SEARCH_RESIDENTS_ENDPOINT = MEMBERS_ENDPOINT + '/searchby';
export const CREATE_RESIDENT_ENDPOINT = MEMBERS_ENDPOINT + '/create';
export const UPDATE_MEMBER_ENDPOINT = MEMBERS_ENDPOINT + '/update';
export const UPDATEAPPROVAL_MEMBER_ENDPOINT = MEMBERS_ENDPOINT + '/updateapproval';
export const SEND_REGISTERATION_ACTIVATION_ENDPOINT = MEMBERS_ENDPOINT + '/notifyregister';
export const RESIDENTS_GROUPS_ENDPOINT = MEMBERS_ENDPOINT + '/groups';
/**AddressLookup */
export const ADDRESSES_ENDPOINT = ADDRESS_LOOKUP_ENDPOINT + '/addresses';

//Member Reset EndPoint
export const REQUEST_PASSWORD_RESET_ENDPOINT = MEMBERS_ENDPOINT + '/requestresetpassword';
export const PASSWORD_RESET_ENDPOINT = MEMBERS_ENDPOINT + '/resetpassword';
export const VERIFY_REQUEST_RESET_ENDPOINT = MEMBERS_ENDPOINT + '/verifyresetrequest';

//Meeting API Endpoint
export const UPDATE_MEETING_RECORD_ENDPOINT = MEETINGS_ENDPOINT + '/update';
export const MEETING_RECORD_ENDPOINT = MEETINGS_ENDPOINT + '/record';
export const VISITOR_MEETING_ENDPOINT = MEETINGS_ENDPOINT + '/visitor';
export const CHECKIN_VISITOR_ENPOINT = MEETINGS_ENDPOINT + '/checkin';
export const CHECKOUT_VISITOR_ENPOINT = MEETINGS_ENDPOINT + '/checkout';
export const CREATE_NEW_MEETING_ENDPOINT = MEETINGS_ENDPOINT + '/add';
export const CREATE_NEW_MEETINGS_ENDPOINT = MEETINGS_ENDPOINT + '/addmultiple';
export const RESOURCE_BOOKING_NOTIFY_ENDPOINT = MEETINGS_ENDPOINT + '/notify';
export const NOTIFY_CHECKING_ENPOINT = MEETINGS_ENDPOINT + '/notifyCheckin';
export const RESOURCE_BOOKING_CANCELLED_NOTIFY_ENDPOINT = MEETINGS_ENDPOINT + '/notify_resource_cancelled';
export const RESOURCE_BOOKING_UPDATED_NOTIFY_ENDPOINT = MEETINGS_ENDPOINT + '/notify_resource_amended';
export const NOTIFY_TENANT_RESOURCE_BOOKED = MEETINGS_ENDPOINT + '/notifyResourcebooked';
export const NOTIFY_HOUSE_MANAGER_RESOURCE_BOOKED = MEETINGS_ENDPOINT + '/notifyHMResourcebooked';
export const NOTIFY_RAISECHARGE_FINANCE_MANAGER_RESOURCE_BOOKED =
	MEETINGS_ENDPOINT + '/notifyRaisechargeFMResourcebooked';

/**Keys Endpoint */
export const KEYS_RECORD_ENDPOINT = KEYS_ENDPOINT + '/records';
export const KEY_STATUSES_ENDPOINT = KEYS_ENDPOINT + '/statuses';
export const KEYS_LOG_ENDPOINT = KEYS_ENDPOINT + '/log';
export const KEYS_LOG_BY_KEY_STATUS = KEYS_ENDPOINT + '/keybystatus';
export const KEY_LOG_BY_KEYID = KEYS_ENDPOINT + '/overduekeylog';
export const RETURN_KEY_ENDPOINT = KEYS_ENDPOINT + '/return';
export const KEY_LOG_LIST_ENDPOINT = KEYS_ENDPOINT + '/loglist';
export const KEY_STATUS_UPDATE = KEYS_ENDPOINT + '/updatekey';
export const NOTIFY_KEY_ASSIGNED = KEYS_ENDPOINT + '/notifykeyassigned';
export const NOTIFY_KEY_RETURNED = KEYS_ENDPOINT + '/notifykeyreturned';
/**Visitors Endpoint */
export const SEARCH_VISITOR_ENDPOINT = VISITORS_ENDPOINT + '/search';
export const SEARCH_VISITOR_BY_NAME_ENDPOINT = VISITORS_ENDPOINT + '/record';
export const VISITORS_EXPECTED_ENDPOINT = VISITORS_ENDPOINT + '/notifiedcheckedbydate';
export const VISITOR_CHECKIN_ENDPOINT = VISITORS_ENDPOINT + '/checkin';
export const VISITOR_CHECKOUT_ENPOINT = VISITORS_ENDPOINT + '/checkout';
export const VISITOR_CAR_REG_ENDPOINT = VISITORS_ENDPOINT + '/addreg';
export const VISITOR_PASS_COLLECTED = MEETINGS_ENDPOINT + '/pass';

/**Settings Endpoint */
export const GLOBAL_SEETINGS_ENDPOINT = SERVICE_MODULES_ENDPOINT + '/globalSetting';
export const PAYMENT_GATEWAY_SETTINGS_ENDPOINT = CONCIERGE_SETTINGS_ENDPOINT + '/paymentgateway';
export const PAYMENT_SIGNATURE_ENDPOINT = CONCIERGE_SETTINGS_ENDPOINT + '/paymentmd5signature';
export const PAYMENT_CRYPT_ENDPOINT = CONCIERGE_SETTINGS_ENDPOINT + '/paymentsignature';
/**Engage Bookingbug Endpoint */
export const ENGAGE__BOOKINGBUG_LOGIN_ENDPOINT = ENGAGE_BOOKINGBUG_API + '/bbadminlogin';
export const ENGAGE_BOOKINGBUG_SERVICES_ENDPOINT = ENGAGE_BOOKINGBUG_API + '/services';
export const ENGAGE_BOOKINGBUG_BOOKINGS_ENDPOINT = ENGAGE_BOOKINGBUG_API + '/bookings';
export const ENGAGE_BOOKINGBUG_SERVICE_DAYDATA_ENDPOINT = ENGAGE_BOOKINGBUG_API + '/servicedaydata';
export const ENGAGE_BOOKINGBUG_RESOURCE_DAYDATA_ENDPOINT = ENGAGE_BOOKINGBUG_API + '/resourcedaydata';
export const ENGAGE_BOOKINGBUG_RESOURCE_TIMEDATA_ENDPOINT = ENGAGE_BOOKINGBUG_API + '/servicetimedata';

export const ENGAGE_BOOKINGBUG_RESOURCE_ENDPOINT = ENGAGE_BOOKINGBUG_API + '/resource';
export const ENGAGE_BOOKINGBUG_RESOURCES_ENDPOINT = ENGAGE_BOOKINGBUG_API + '/resources';
export const ENGAGE_BOOKINGBUG_BOOKING_QUESTIONS = ENGAGE_BOOKINGBUG_API + '/bookingquestions';
export const ENGAGE_BOOKINGBUG_BOOKED_BOOKINGS_ENDPOINT = ENGAGE_BOOKINGBUG_API + '/bookedbookings';
export const ENGAGE_BOOKINGBUG_BLOCKED_BOOKINGS_ENDPOINT = ENGAGE_BOOKINGBUG_API + '/blockedbookings';
export const ENGAGE_BOOKINGBUG_RESOURCE_BOOKINGS_ENDPOINT = ENGAGE_BOOKINGBUG_API + '/resourcebookings';
export const ENGAGE_BOOKINGBUG_ADD_TO_BASKET_ENDPOINT = ENGAGE_BOOKINGBUG_API + '/additemtobasket';
export const ENGAGE_BOOKINGBUG_CHECKOUT_ENDPOINT = ENGAGE_BOOKINGBUG_API + '/checkout';
export const ENGAGE_BOOKINGBUG_UPDATE_BOOKING_RESOURCE_ENDPOINT = ENGAGE_BOOKINGBUG_API + '/updatebookingresource';
export const ENGAGE_BOOKINGBUG_CANCEL_BOOKING_ENDPOINT = ENGAGE_BOOKINGBUG_API + '/cancelbookings';
export const ENGAGE_BOOKINGBUG_BASKET_ENDPOINT = ENGAGE_BOOKINGBUG_API + '/basket';
export const ENGAGE_BOOKINGBUG_BLOCK_RESOURCE_ENDPOINT = ENGAGE_BOOKINGBUG_API + '/blockresource';
export const ENGAGE_BOOKINGBUG_DELETE_ITEM_FROM_BASKET_ENDPOINT = ENGAGE_BOOKINGBUG_API + '/removefrombasket';
/***Payment Endpoint ******/

export const WorldPayURL = 'https://secure-test.worldpay.com/wcc/purchase';
export const WorldPayInstID = '1091094';

export const errorPageRedirect = (response) => {
	if (response.status === 500) {
		browserHistory.push('/500');
	} else if (response.status === 403) {
		browserHistory.push('/403');
	}else if(response.status === 401){
		browserHistory.push('/401');
	}else if(response.status === 404){
		browserHistory.push('/404');
	}
};

//Modules API
export const MODULES_ENDPOINT = SERVICE_MODULES_ENDPOINT + '/modules';
export const PORTAL_ENPOINT = SERVICE_MODULES_ENDPOINT + '/portalsettings';
//parcel constants
export const parcelStatuses = {
	checkedIn: 'checked-in',
	returned: 'returned',
	collected: 'collected'
};

export const portalTypes = {
	Residential: 'residential',
	Commercial: 'commercial'
};

//Booking Bug Config
export const APP_KEY = 'xxxxxx';

export const APP_ID = 'xxxxxx';
export const COMPANY_ID = null;
export const ADMIN_COMPANY_ID = 'xxxxxx';
export const MEETING_ROOM_SERVICE_HR_ID = 'xxxxxx';
export const PARKING_SERVICE_HR_ID = 'xxxxxx';

//logIn
export const BOOKING_BUG_USER_LOGIN = 'technical@engageproptech.com';
export const BOOKING_BUG_USER_PWD = bookingBugPwd;
export const BOOKING_BUG_USER_ID = null;

//Encryption Utility
export const EngageCryptoKey = '1547896544623248';
export const EngageCryptoKeyIV = '7871564623240457';
