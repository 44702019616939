import React, { Component } from 'react';
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom';
import _ from 'lodash';
import * as $ from 'jquery';
import FeatureArea from '../../../components/FeatureArea/FeatureArea';
import FooterNavigation from '../../../components/FooterNavigation/FooterNavigation';
import InputComponent from '../../../components/Input/InputNew';
import ButtonGroup from '../../../components/Button/ButtonGroup';
import * as moduleSelectors from '../../../store/ServiceModules/reducer';
import MemberAutoSuggest from '../../../components/TenantAutoSuggest/MemberAutoSuggest';

import { PortalTypes } from '../../../helpers/configHelper';
import * as settingsActions from '../../../store/ConciergeSetting/actions';
import * as settingSelector from '../../../store/ConciergeSetting/reducer';

import DetailListNew from '../../../components/DetailList/DetailListNew';
import * as parcelSelectors from '../../../store/Parcels/reducer';
import * as parcelActions from '../../../store/Parcels/actions';
import * as residentActions from '../../../store/Residents/actions';
import * as residentSelectors from '../../../store/Residents/reducer';
import * as tenantActions from '../../../store/Tenants/actions';
import * as tenantSelectors from '../../../store/Tenants/reducer';
import ParcelCancelModal from '../../../components/ariaModals/ParcelCancelModal';
import '../../../sass/containers/parcels.css';
import Button from '../../../components/Button/Button';

/**
 * Parcel registration screen
 */

class RegisterScreen extends Component {
	constructor(props) {
		super(props);
		this.state = {
			type: 'parcels',
			portalType: '',
			memberId: undefined,
			member: undefined,
			modalCancelShow: false,
			barcode: '',
			locationId: undefined,
			count: 1,
			enabled: false,
			parcelList: [],
			isSelected: false,
			parcelQueue: [],
			index: -1,
			errorMessage: '',
			isOnlyOneLocationAvailable: false,
			parcelLocations: []
		};
		this.addParcelValidation = null;
	}

	componentDidMount() {
		this.addValidation();

		window.addEventListener('keydown', function(event) {
			if (event.keyCode === 17 || event.keyCode === 74) {
				if (event.ctrlKey) event.preventDefault();
			}
		});
	}

	addValidation() {
		this.addParcelValidation = $(this.refs.validate).parsley({
			classHandler: function(el) {
				return el.$element.closest('div:not(.input-wrapper):not(.Select)');
			}
		});
	}

	componentWillMount() {
		if (this.props.portalSettings && this.props.portalSettings.portalType) {
			this.setState({
				portalType: this.props.portalSettings.portalType
			});
		}

		this.props.dispatch(parcelActions.getAllLocations());
		this.props.dispatch(tenantActions.getAllTenants());
		this.props.dispatch(residentActions.getAllResidents());
		this.props.dispatch(settingsActions.getGlobalSetting());
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.portalSettings !== prevProps.portalSettings && !_.isUndefined(this.props.portalSettings)) {
			if (this.props.portalSettings.portalType) {
				this.setState({
					portalType: this.props.portalSettings.portalType
				});
			}
		}
		if (prevProps.parcelLocations !== this.props.parcelLocations && this.props.parcelLocations.length > 0) {
			let locations = _.map(this.props.parcelLocations, (location, rowIndex) => {
				return { name: location.locationName, label: location.locationName, value: location.id };
			});
			//test condition
			//locations = _.filter(locations, (item) => item.value === 1);

			this.setState({
				parcelLocations: locations
			});
			if (locations.length === 1) {
				this.setState({
					isOnlyOneLocationAvailable: true
				});
			}
		}

		if (
			prevProps.parcelsCheckedIn !== this.props.parcelsCheckedIn &&
			!_.isUndefined(this.props.parcelsCheckedIn) &&
			this.props.parcelsCheckedIn.length > 0
		) {
			this.sendRegitrationNotification(this.props.parcelsCheckedIn);
		}

		if (this.state.memberId !== prevState.memberId) {
			if (!_.isUndefined(this.state.memberId)) {
				if (this.state.parcelLocations && this.state.parcelLocations.length === 1) {
					this.setState({
						locationId: this.state.parcelLocations[0].value.toString()
					});
				}
			} else {
				if (this.state.parcelLocations && this.state.parcelLocations.length === 1) {
					//this.refs['locationRef'].state.value = undefined;
					this.setState({
						locationId: undefined
					});
				}
			}
		}
	}

	componentWillUpdate(nextProps, nextState) {
		
		
	}
	sendRegitrationNotification(data) {
		this.props.dispatch(
			parcelActions.notifyCheckinParcels(data, (success) => {
				if (success === true) {
				}
			})
		);
	}
	handleLocationChange(e) {
		let field = e.target.name;
		let value = e.target.value;
		this.setState({
			[field]: value,
			locationId: value,
			locationValidationStatus: '',
			locatinValidationMessage: ''
		});
	}

	handleTenantSelected(tenant) {
		this.setState({
			memberId: tenant.memberId ? tenant.memberId : undefined,
			member: tenant.memberId ? tenant : undefined,
			memberValidationStatus: '',
			memberValidationMessage: ''
		});
	}

	handleCloseClick() {
		this.setState({
			modalCancelShow: false
		});
	}

	handleContinueClick() {
		this.setState({
			modalCancelShow: false
		});
		this.props.history.push({
			pathname: '/',
			state: { module: 'Parcels' }
		});
		window.scrollTo(0, 0);
	}

	handleStayOnPageClick() {
		this.setState({
			modalCancelShow: false
		});
	}

	isSelectedTest() {
		this.setState({ isSelected: false });
	}

	handleInputChange(e) {
		const field = e.target.name;
		const value = e.target.value;
		this.setState({
			[field]: value,
			barcode: value
		});
	}
	// handleRightClick() {
	// 	this.setState({
	// 		enabled: true
	// 	});
	// }

	validate(fromSubmit = false) {
		const { portalType } = this.state;
		this.addParcelValidation.validate({ force: true });
		let validationError =
			!_.isUndefined(this.state.memberId) && !_.isUndefined(this.state.locationId) ? false : true;
		const valid = this.addParcelValidation.isValid();
		this.setState({
			addParcelFormValid: valid,
			addParcelFormUsed: true,
			error: !validationError ? undefined : true,
			memberValidationStatus: _.isUndefined(this.state.memberId) ? 'invalid' : '',
			memberValidationMessage: _.isUndefined(this.state.memberId)
				? `${'Please select a valid Tenant '}${portalType === PortalTypes.Residential
						? 'or Address'
						: 'or Company'}`
				: '',
			locationValidationStatus: _.isUndefined(this.state.locationId) ? 'invalid' : '',
			locatinValidationMessage: _.isUndefined(this.state.locationId) ? 'Please select a location' : '',
			success: false
		});
		return validationError;
	}

	handleAddClick(index) {
		var newParcel = {};

		if (!this.state.isSelected) {
			if (!this.validate(true)) {
				let splitTenant = [];
				splitTenant = this.state.member.tenant.split('-');
				let address = this.state.member.address;
				let fullNametemp = this.state.member.tenant.replace('- ' + address, '');
				splitTenant = fullNametemp.split('-');
				let fullName = splitTenant[0];
				for (let i = 1; i <= splitTenant.length - 1; i++) {
					fullName += '-' + splitTenant[i];
				}
				newParcel.barcode = this.state.barcode;
				newParcel.locationId = this.state.locationId;
				newParcel.fullName = fullName.length > 24 ? fullName.substring(0, 24) + '...' : fullName;
				if (this.state.portalType === PortalTypes.Commercial) {
					newParcel.company =
						address != null ? (address.length > 30 ? address.substring(0, 30) + '...' : address) : '';
				} else {
					newParcel.address =
						address != null ? (address.length > 30 ? address.substring(0, 30) + '...' : address) : '';
				}

				newParcel.memberId = this.state.member.memberId;
				newParcel.specialInstruction = '';

				let parcelList = this.state.parcelQueue;
				parcelList.unshift(newParcel);
				parcelList = _.map(parcelList, (item, indx) => {
					return { ...item, index: indx };
				});

			//	this.refs['TenantAddressRef'].getWrappedInstance().reset();
				
			//	this.refs['locationRef'].state.value = undefined;

				this.setState({
					parcelQueue: parcelList,
					barcode: '',
					locationId: this.state.isOnlyOneLocationAvailable ? this.state.locationId : undefined,
					memberId: undefined,
					member: undefined,
					resetTenantAddress : true
				});
			}
		} else {
			if (!this.validate(true)) {
				let updatedQueue = this.state.parcelQueue;
				let queue = {};
				let splitTenant = [];
				splitTenant = this.state.member.tenant.split('-');

				let address = this.state.member.address;
				let fullNametemp = this.state.member.tenant.replace('- ' + address, '');
				splitTenant = fullNametemp.split('-');
				let fullName = splitTenant[0];
				for (let i = 1; i <= splitTenant.length - 1; i++) {
					fullName += '-' + splitTenant[i];
				}

				queue.barcode = this.state.barcode;
				queue.locationId = this.state.locationId;

				queue.fullName = fullName.length > 24 ? fullName.substring(0, 24) + '...' : fullName;
				if (this.state.portalType === PortalTypes.Commercial) {
					queue.company =
						address != null ? (address.length > 30 ? address.substring(0, 30) + '...' : address) : '';
				} else {
					queue.address =
						address != null ? (address.length > 30 ? address.substring(0, 30) + '...' : address) : '';
				}
				queue.memberId = this.state.member.memberId;
				queue.specialInstruction = '';
				//this.refs['TenantAddressRef'].getWrappedInstance().reset();
				//this.refs['locationRef'].state.value = undefined;

				updatedQueue = _.map(updatedQueue, (item) => {
					if (item.index === index) {
						return { ...queue, index: item.index };
					}
					return item;
				});

				this.setState({
					parcelQueue: updatedQueue,
					barcode: '',
					locationId: this.state.isOnlyOneLocationAvailable ? this.state.locationId : undefined,
					memberId: undefined,
					member: undefined,
					isSelected: undefined,
					resetTenantAddress : true
				});
			}
		}
	}

	removeFromQueue(index) {
		const { parcelQueue } = this.state;

		let queue = _.filter(parcelQueue, (item) => item.index !== index);

		this.setState({
			parcelQueue: queue
		});
	}
	formReset() {
		//this.refs['TenantAddressRef'].getWrappedInstance().reset();

		this.setState({
			barcode: '',
			locationId: this.state.isOnlyOneLocationAvailable ? this.state.locationId : undefined,
			memberId: undefined,
			isSelected: undefined,
			locationValidationStatus: '',
			memberValidationStatus: '',
			locatinValidationMessage: '',
			memberValidationMessage: '',
			resetTenantAddress : true
		});
		// this.refs['locationRef'].reset();
		//this.refs['locationRef'].state.value = undefined;
	}

	handleEdit(val) {
		const { parcelQueue } = this.state;

		this.setState({ message: '' });
		if (!_.isUndefined(val)) {
			let selectedItem = _.find(parcelQueue, { index: val });
			this.setState({
				barcode: selectedItem.barcode,
				locationId: selectedItem.locationId,
				memberId: selectedItem.memberId,
				isSelected: true,
				index: val,
				locationValidationStatus: '',
				locatinValidationMessage: '',
				resetTenantAddress : undefined
			});
		} else {
			this.formReset();
		}
	}

	handleRightClick() {
		// this.setState({
		// 	enabled: true
		// });
		if (this.state.parcelQueue.length === 0) {
			if (!this.validate(true)) this.parcelsRegistertionProcess(true);
		} else {
			//	if ( (this.state.location == undefined || this.state.isOnlyOneLocationAvailable) && this.state.memberId == undefined && this.state.barcode == ''  ) {

			//if ((this.state.location === undefined || this.state.isOnlyOneLocationAvailable) && this.state.memberId === undefined && this.state.barcode === '' && this.refs['TenantAddressRef'].wrappedInstance.state.value === "") {

			if (
				(this.state.locationId === undefined || this.state.isOnlyOneLocationAvailable) &&
				this.state.memberId === undefined &&
				this.state.barcode === '' 
			) {
				this.parcelsRegistertionProcess();
			} else {
				if (!this.validate(true)) {
					if (this.state.isSelected) this.parcelsRegistertionProcess();
					else this.parcelsRegistertionProcess(true);
				}
			}
		}
	}

	parcelsRegistertionProcess(isDataAddedFromFields) {
		let parcelsData = _.map(this.state.parcelQueue, (parcel) => {
			return {
				// id: parcel.id,
				// status: configHelper.ParcelStatuses.CheckedIn,
				// dateArrived: moment().format(),
				memberId: parcel.memberId,
				locationId: parcel.locationId,
				barcode: parcel.barcode
			};
		});

		if (isDataAddedFromFields) {
			var objFromField = {};
			objFromField.memberId = this.state.memberId;
			objFromField.locationId = this.state.locationId;
			objFromField.barcode = this.state.barcode;
			parcelsData.push(objFromField);
		}

		this.props.dispatch(
			parcelActions.addParcels(parcelsData, (success) => {
				if (success === true) {
					this.setState({
						parcelQueue: [],
						barcode: '',
						locationId: this.state.isOnlyOneLocationAvailable ? this.state.locationId : undefined,
						memberId: undefined,
						member: undefined,
						success: true,
						message: 'Parcels Successfully Registered',
						resetTenantAddress : true
					});

					//this.refs['TenantAddressRef'].getWrappedInstance().reset();
				
					//this.refs['locationRef'].state.value = undefined;
				} else {
					this.setState({
						errorMessage: 'Registration failed due to issues connecting the network',
						error: true
					});
				}
			})
		);
		this.resetValidation();
		setTimeout(() => {
			this.setState({
				success: false
			});
		}, 5000);
	}

	resetValidation() {
		this.setState({
			memberValidationStatus: '',
			memberValidationMessage: '',
			locationValidationStatus: '',
			locatinValidationMessage: '',
			error: undefined,
			success: undefined,
			message: '',
			errorMessage: ''
		});
	}

	render() {
		const currentModule = _.find(this.props.modules, (o) => o.title === 'Parcels');
		const { portalType, parcelQueue, parcelLocations, locationId } = this.state;

		const { tenants, residents } = this.props;

		let residentsList = _.orderBy(residents, [ 'firstName', 'addressLine1' ], [ 'asc' ]);
		let tenantsList = _.orderBy(tenants, [ 'firstName', 'companyName' ], [ 'asc' ]);

		// let selectedLocation =
		// 	parcelLocations.length === 1 ? parcelLocations[0].id : this.state.isSelected ? this.state.locationId : '';

		let defaultTenantIdProperty = {};

		if (!_.isUndefined(this.state.memberId)) {
			defaultTenantIdProperty = {
				defaultTenantId: this.state.memberId
			};
		}
		
		return (
			<div>
				<div className="container type-concierge-desktop parcel-info-container">
					<FeatureArea
						type={this.state.type}
						areaTitle="Parcels"
						areaTagline="Welcome, select a service"
						pageHeader="Parcel Registration"
						overlayColor={currentModule && currentModule.imageOverlayTextColour}
						landing={false}
						activeMenu={true}
					/>
                    
					<form
						// className="modal-wrapper"
						ref="validate"
						onSubmit={(e) => {
							e.preventDefault();
						}}
						data-parsley-errors-container="#validation-messages"
						data-parsley-focus="none"
						data-parsley-validate-if-empty
					>
						<div className="main-container  parcel-registration-root">
							<div className="input-wrapper info-section">
								{portalType && (
									<MemberAutoSuggest
										ref="TenantAddressRef"
										reset={this.state.resetTenantAddress}
										{...defaultTenantIdProperty}
										label={
											portalType === PortalTypes.Commercial ? (
												'Tenant or Company*'
											) : (
												'Tenant or Address*'
											)
										}
										placeHolder={
											portalType === PortalTypes.Commercial ? (
												'Search for tenant name or company'
											) : (
												'Search for tenant name or address'
											)
										}
										clearableIfValid={false}
										onSelected={(data) => {
											this.handleTenantSelected(data);

											//console.log('selected tenant: ', data);
										}}
										defaultText={() => {}}
										showRecordsField={true}
										portalType={this.state.portalType}
										data={portalType === PortalTypes.Commercial ? tenantsList : residentsList}
										// fieldCleared={(val) => {
										// 	this.setState({
										// 		memberId: undefined
										// 	});
										// 	this.resetValidation();
										// }}
										fieldCleared={(val) => {
											this.setState({
												memberId: undefined
											});
											//this.resetValidation();
										}}
										fieldManualCleared={(val) => {
											this.setState({
												memberId: undefined
											});
											//this.resetValidation();
										}}
										validation={{
											validate: true,
											className: this.state.memberValidationStatus
										}}
										reset={_.isUndefined(this.state.memberId)}
									/>
								)}
							</div>

							<div className="visitor-management-header info-section">
								<div className="input-section">
									<InputComponent
										label="Parcel Barcode"
										type="text"
										name="barcode"
										value={this.state.barcode}
										handleChange={(e) => this.handleInputChange(e)}
										placeHolder="Scan or enter reference code"
										disabled={false}
									/>
								</div>

								<div className={'date_select ' + this.state.locationValidationStatus}>
									<InputComponent
										ref="locationRef"
										label={'Location*'}
										type={'select'}
										name="locationSelect"
										placeHolder="Select location"
										className="text-box"
										value={locationId ? locationId.toString() : undefined}
										handleChange={(e) => this.handleLocationChange(e)}
										options={parcelLocations}
									/>
								</div>

								{/* <div className="">
									<label
										className=" add-button"
										style={{ opacity: this.state.memberId !== undefined ? 1 : 0.5 }}
										onClick={() => this.handleAddClick()}
									>
										+
									</label>
								</div> */}

								<div style={{ marginTop: 29 }}>
									<Button
										otherClass={'icon icon-medium'}
										//style={{ display: this.state.isSelected === false ? 'block' : 'none' }}

										// isDisabled={this.state.isSelected === true  ? false :  (((
										// 	this.state.location !== undefined &&
										// 	this.state.memberId !== undefined) || this.state.parcelQueue.length > 0
										// ) ? false : true)}

										handleClick={() => this.handleAddClick(this.state.index)}
										//isDisabled={this.state.isSelected === true ? false : (this.state.memberId !== undefined ? false : true)}
										// isDisabled={((
										// 	this.state.location !== undefined &&
										// 	this.state.memberId !== undefined) || this.state.parcelQueue.length > 0
										// ) ? false : true}

										icon={this.state.isSelected === true ? 'loop' : 'android-add'}
									/>
								</div>
							</div>
							<div>
								{//<RegisterParcelsListComponent portalType={portalType} />
								parcelQueue.length > 0 ? (
									<div>
										<div className="list-text">
											You have added {parcelQueue.length}{' '}
											{parcelQueue.length > 1 ? 'parcels' : 'parcel'} to the list
										</div>
										<DetailListNew
											//data={this.sampleParcelQueue()}
											data={parcelQueue}
											headingsAlone={true}
											emptyMessage={''}
											actionClick={(index) => {
												this.formReset();
												this.removeFromQueue(index);
											}}
											//visitorStyle={visitor_style}
											selectedDetail={(val) => {
												this.handleEdit(val);
											}}
											selectDetailReset={this.state.isSelected}
											resetForm={(e) => {
												
												if (e === true) {
													//	this.formReset();
												}
											}}
											listType={portalType === PortalTypes.Commercial ? 'tenant' : 'resident'}
											classAppend={'content-mrg'}
										/>
									</div>
								) : (
									''
								)}
							</div>

							<div>
								<div
									className={
										'validation-messages' + (this.state.addParcelFormValid ? ' valid' : ' invalid')
									}
									id="validation-messages"
								>
									{this.state.error && (
										<div>
											{this.state.memberValidationMessage !== '' ? (
												<span className="error">{this.state.memberValidationMessage}</span>
											) : (
												''
											)}
											{this.state.locatinValidationMessage !== '' ? (
												<span className="error">{this.state.locatinValidationMessage}</span>
											) : (
												''
											)}
											{this.state.errorMessage !== '' ? (
												<span className="error">{this.state.errorMessage}</span>
											) : (
												''
											)}
										</div>
									)}

									{this.state.success && (
										<div>
											<span className="success">{this.state.message}</span>
										</div>
									)}
								</div>

								<ButtonGroup
									rightText="Register Parcels"
									leftText="Cancel"
									rightDisabled={
										this.state.locationId !== undefined ||
										this.state.memberId !== undefined ||
										this.state.parcelQueue.length > 0 ? (
											false
										) : (
											true
										)
									}
									leftClick={//this.state.count > 0 ? (
									() => {
										if (
											this.state.locationId !== undefined ||
											this.state.memberId !== undefined ||
											this.state.barcode !== '' ||
											this.state.parcelQueue.length > 0
										) {
											this.setState({ modalCancelShow: true });
										} else {
											this.props.history.push({
												pathname: '/',
												state: { module: 'Parcels' }
											});
											window.scrollTo(0, 0);
										}
									}
									
									}
									rightClick={() => this.handleRightClick()}
									// rightClick={this.handleRightClick()}
									//  isDisabled={this.state.isSucessfull}
									icon_l=""
									icon_r=""
									sizes={[ 'small', 'small' ]}
									otherClass={'btn-margin'}
								/>
							</div>
						</div>
					</form>
					{this.state.modalCancelShow ? (
						<ParcelCancelModal
							handleCloseClick={() => this.handleCloseClick()}
							handleContinueClick={() => this.handleContinueClick()}
							handleStayOnPageClick={() => this.handleStayOnPageClick()}
							focus={true}
							//	getApplicationNode={this.getApplicationNode}
							moduleColor={currentModule && currentModule.imageOverlayTextColour}
							confirmationMessage={
								'Please notice that by leaving this page all parcel added to the list will be discard and not registered. Do you wish to continue?'
							}
						/>
					) : (
						''
					)}
					<FooterNavigation />
				</div>
			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		modules: moduleSelectors.loadModules(state),
		portalSettings: settingSelector.loadGlobalSetting(state),
		parcelLocations: parcelSelectors.getParcelLocations(state),
		residents: residentSelectors.getResidents(state),
		tenants: tenantSelectors.getAllTenants(state),
		parcelsCheckedIn: parcelSelectors.getCheckedInParcels(state)
	};
}

export default withRouter(connect(mapStateToProps, null, null, { forwardRef: true })(RegisterScreen));
