import * as config from "./config";

class VisitorServices {
  async getVisitorsExpectedByDate(date) {
    const url = `${config.VISITORS_EXPECTED_ENDPOINT}`;

    var requestHeader = new Headers();

    const response = await fetch(url + "?date=" + date, {
      method: "GET",
      headers: requestHeader
    });

    if (!response.ok) {
      config.errorPageRedirect(response);
      throw new Error(
        `getVisitorsExpectedByDate service failed, HTTP status ${
        response.status
        }`
      );
    }

    const result = await response.json();

    return result;
  }

  async searchVisitorByEmail(email) {
    const url = `${config.SEARCH_VISITOR_ENDPOINT}`;

    var requestHeader = new Headers();
    const response = await fetch(url + "?email=" + email, {
      method: "GET",
      headers: requestHeader
    });

    if (!response.ok) {
      config.errorPageRedirect(response);
      throw new Error(
        `searchVisitorByEmail service failed, HTTP status ${response.status}`
      );
    }

    const result = await response.json();

    return result;
  }

  async searchVisitorByName(visitor) {
    const url = `${config.SEARCH_VISITOR_BY_NAME_ENDPOINT}`;

    var requestHeader = new Headers();
    requestHeader.append("Accept", "application/json");
    requestHeader.append("Content-Type", "application/json");

    const response = await fetch(url, {
      method: "POST",
      headers: requestHeader,
      body: JSON.stringify(visitor)
    });

    if (!response.ok) {
      config.errorPageRedirect(response);
      throw new Error(
        `searchVisitorByName service failed, HTTP status ${response.status}`
      );
    }

    const result = await response.json();

    return result;
  }

  async searchByVisitor(visitor) {
    const url = `${config.SEARCH_VISITOR_ENDPOINT}`;

    var requestHeader = new Headers();
    requestHeader.append("Accept", "application/json");
    requestHeader.append("Content-Type", "application/json");

    const response = await fetch(url, {
      method: "POST",
      headers: requestHeader,
      body: JSON.stringify(visitor)
    });

    if (!response.ok) {
      config.errorPageRedirect(response);
      throw new Error(
        `searchVisitorByName service failed, HTTP status ${response.status}`
      );
    }

    const result = await response.json();

    return result;
  }

  async getAllVisitors() {
    const url = `${config.VISITORS_ENDPOINT}`;

    var requestHeader = new Headers();

    const response = await fetch(url + "/notified", {
      method: "GET",
      headers: requestHeader
    });

    if (!response.ok) {
      config.errorPageRedirect(response);
      throw new Error(
        `getAllVisitors service failed, HTTP status ${response.status}`
      );
    }

    const result = await response.json();

    return result;
  }

  async checkinVisitorById(id) {
    const url = `${config.VISITOR_CHECKIN_ENDPOINT}`;

    var requestHeader = new Headers();

    const response = await fetch(url + "?id=" + id, {
      method: "POST",
      headers: requestHeader
    });

    if (!response.ok) {
      config.errorPageRedirect(response);
      throw new Error(
        `checkinVisitorById service failed, HTTP status ${response.status}`
      );
    }

    const result = await response.json();

    return result;
  }

  async checkOutVisitorById(id) {
    const url = `${config.VISITOR_CHECKOUT_ENPOINT}`;

    var requestHeader = new Headers();

    const response = await fetch(url + "?id=" + id, {
      method: "POST",
      headers: requestHeader
    });

    if (!response.ok) {
      config.errorPageRedirect(response);
      throw new Error(
        `checkOutVisitorById service failed, HTTP status ${response.status}`
      );
    }

    const result = await response.json();

    return result;
  }

  async addCarRegNumber(data) {
    const url = `${config.VISITOR_CAR_REG_ENDPOINT}`;

    var requestHeader = new Headers();
    requestHeader.append("Accept", "application/json");
    requestHeader.append("Content-Type", "application/json");

    const response = await fetch(url, {
      method: "POST",
      headers: requestHeader,
      body: JSON.stringify(data)
    });

    if (!response.ok) {
      throw new Error(
        `addCarRegNumber service failed, HTTP status ${response.status}`
      );
    }

    const result = await response.json();

    return result;
  }

  async searchVisitorById(id) {
    const url = `${config.SEARCH_VISITOR_ENDPOINT}`;

    var requestHeader = new Headers();
    const response = await fetch(url + "?id=" + Number(id), {
      method: "GET",
      headers: requestHeader
    });

    if (!response.ok) {
      config.errorPageRedirect(response);
      throw new Error(
        `searchVisitorBId service failed, HTTP status ${response.status}`
      );
    }

    const result = await response.json();

    return result;
  }

}

export default new VisitorServices();
