import React, { Component } from "react";

import { Link } from "react-router-dom";

var PropTypes = require('prop-types');
/**
 * A general purpose Visitors Expected Component to display the day and time details of the Visitors and their Numbers
 */

class ParcelsExpected extends Component {
  render() {

    const { taskTitle, numberOfParcels, parcelMessage, linkMessage, timeFrame, moduleColor, handleClick } = this.props;

    return (

      <div className="task-detail">
        <div className="sections-box" onClick={handleClick}>
          <span className="card-title" >{taskTitle}</span>
          <span className="Number" style={{ color: moduleColor }}>{numberOfParcels}</span>
          <span className="Wording ">{parcelMessage}</span>
          <span className="Time-frame" style={{ color: moduleColor }}>{timeFrame}</span>
          <span className="find-link">{linkMessage}</span>
        </div>
      </div>


    );
  }
}

ParcelsExpected.propTypes = {
  /** Text displayed on the Component to denote the title*/
  taskTitle: PropTypes.string,

  /** Text to display the Number of parcels Expected */
  numberOfParcels: PropTypes.string,

  /** Text displayed on the Component to denote the message */
  parcelMessage: PropTypes.string,

  /** The link to redirect for more information */
  linkMessage: PropTypes.string,

  /** Time Period to denote the time when the parcels is Expected*/
  timeFrame: PropTypes.string,

  /** The variable to display module color */
  moduleColor: PropTypes.string
};
export default ParcelsExpected;
