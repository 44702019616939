import React, { Component } from 'react';
import { connect } from 'react-redux';
import Autosuggest from 'react-autosuggest';
import InputComponent from '../../components/Input/Input';
// import Parsley from "parsleyjs";
import PropTypes from 'prop-types';
import * as tenantActions from '../../store/Tenants/actions';
import * as tenantSelector from '../../store/Tenants/reducer';

import * as residentActions from '../../store/Residents/actions';
import * as residentSelectors from '../../store/Residents/reducer';

import _ from 'lodash';
import autoBind from 'react-autobind';
import * as $ from 'jquery';

/**
 * Pairs up an <Autosuggest and <InputComponent together with the relevant state and events,
 * optionally clearable if clearableIfValid property specified
 */
class TenantAddrTypeAutoSuggest extends Component {
	constructor(props) {
		super(props);

		this.defaultState = {
			value: '',
			suggestions: [],
			valid: false,
			lookupMembers: [],
			name: '',
			address: '',
			type: ''
		};

		this.state = this.defaultState;

		this.props.dispatch(tenantActions.getAllTenants());
		this.props.dispatch(residentActions.getAllResidents());

		this.emptyResponse = {
			memberId: '',
			companyName: '',
			tenant: '',
			address: '',
			type: ''
		};

		this.selectedTenantFromDefault = false;

		autoBind(this);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		// Set the autosuggest to an initial value if it is provided
		if (
			nextProps.residents.length > 0 &&
			!_.isUndefined(nextProps.defaultTenantId) &&
			this.selectedTenantFromDefault === false
		) {
			this.selectedTenantFromDefault = true;

			const startTenant = nextProps.residents.find((tenant) => {
				return tenant.id === nextProps.defaultTenantId;
			});

			this.handleTenantSelected(startTenant);

			this.setState({
				value: startTenant.firstName + ' ' + startTenant.lastName,
				valid: true
			});
		}

		if (nextProps.residents && nextProps.residents.length > 0) {
			let lookupMember =
				nextProps.tenants && nextProps.tenants.length > 0
					? _.map(nextProps.tenants, (o) => {
							let resident = _.find(nextProps.residents, { id: o.id });

							return {
								id: o.id,
								email: o.email,
								firstName: o.firstName,
								lastName: o.lastName,
								type: resident.memberType,
								address: resident.addressLine1 + ' ' + resident.addressLine2 + ', ' + resident.postCode,
								companyName: o.companyName
							};
						})
					: [];
			let lookupArray = lookupMember;

			this.setState({
				lookupMembers: lookupArray
			});
		}
	}

	reset() {
		this.setState(this.defaultState);
	}

	// Autosuggest functions ++++++++++++++++++++++++++++++++++++++++++
	getSuggestionValue(suggestion) {
		// const { name } = this.state;

		this.handleTenantSelected(suggestion);
		if (suggestion) this.setState({ disabled: '' });

		return `${suggestion.firstName} ${suggestion.lastName}`;
	}

	getSuggestions(val) {
		//const { tenants } = this.props;
		const { lookupMembers } = this.state;

		if (!lookupMembers) return [];
		//if (!tenants) return [];

		//safe guard residents data return null value in some records
		var data_ = _.reject(lookupMembers, obj => {
			return obj.firstName == null || _.isUndefined(obj.firstName);
		});

		var data_l = _.reject(lookupMembers, obj => {
			return obj.lastName == null || _.isUndefined(obj.lastName);
		});

		var match = _.filter(data_, (o) => {
			
			return _.startsWith(o.firstName.toLowerCase(), val.toLowerCase());
		});

		if(match.length == 0){

			match = _.filter(data_l, (o) => {
			
				return _.startsWith(o.lastName.toLowerCase(), val.toLowerCase());
			});
		}
		
		return match;
	}

	onSuggestionsFetchRequested = ({ value }) => {
		this.setState({ suggestions: this.getSuggestions(value) });
	};

	onSuggestionsClearRequested = () => {};

	onChange = (event, { newValue }) => {
		if (newValue.length === 0) this.setState({ tenant: undefined });
		if (newValue.length > 2) {
			this.handleTenantChange(newValue);
		}

		this.setState({
			value: newValue
		});

		setTimeout(() => {
			// Delayed because parseley not ready if user selected a suggestion

			this.setState({
				valid: $(this.refs.autosuggest.input).parsley().isValid()
			});
		}, 0);

		this.checkMatch(newValue);
	};

	handleTenantSelected(ten, setValue = false) {
		const stateChanges = {
			memberId: ten.id,
			companyName: ten.companyName,
			tenant: ten.firstName + ' ' + ten.lastName,
			type: ten.memberType,
			address: ten.address,

			...(setValue ? { value: ten.firstName + ' ' + ten.lastName, valid: true } : {})
		};

		this.setState(stateChanges);

		this.props.onSelected && this.props.onSelected(stateChanges);
	}

	handleTenantChange(value) {
		// const { memberId } = this.state;
		this.setState({
			name: value
		});
	}

	renderSuggestion(suggestion) {
		return (
			<div className="Select-option">
				{suggestion.firstName} {suggestion.lastName} {'['} {suggestion.type} {']'} {suggestion.address}
			</div>
		);
	}

	checkMatch(currentValue) {
		const matchedTenant = _.find(this.state.lookupMembers, (tenant) => {
			const value = currentValue.toLowerCase();
			const parts = value.split(' ').filter((value) => {
				return value !== '';
			});

			if (
				parts.length === 2 &&
				tenant.firstName.toLowerCase() === parts[0].trim() &&
				tenant.lastName.toLowerCase() === parts[1].trim()
			) {
				return true;
			}

			return false;
		});

		if (_.isUndefined(matchedTenant) === false) {
			this.handleTenantSelected(matchedTenant);
		} else {
			this.props.onSelected && this.props.onSelected(this.emptyResponse);
		}
	}

	inputClick() {
		if (
			this.state.valid === true &&
			!_.isUndefined(this.props.clearableIfValid) &&
			this.props.clearableIfValid === true
		) {
			this.reset();

			this.props.onSelected && this.props.onSelected(this.emptyResponse);
		}
	}

	render() {
		const { value, suggestions, valid } = this.state;
		const { label, placeHolder } = this.props;
		const disabled = _.isUndefined(this.props.disabled) || this.props.disabled === false ? false : true;

		const inputProps = {
			placeholder: 'Tenant',
			value,
			onChange: this.onChange,
			onClick: this.inputClick
		};

		return (
			<Autosuggest
				ref="autosuggest"
				suggestions={suggestions}
				onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
				onSuggestionsClearRequested={this.onSuggestionsClearRequested}
				getSuggestionValue={this.getSuggestionValue}
				onSuggestionSelected={() => {}}
				renderSuggestion={this.renderSuggestion}
				inputProps={inputProps}
				theme={{
					// maybe make global for reuse at some point
					container:
						'auto-suggest Select ' +
						(!_.isUndefined(this.props.clearableIfValid) && this.props.clearableIfValid === true
							? 'is-clearable'
							: ''),
					suggestionsList: 'Select-menu-outer',
					suggestion: 'Select-menu'
				}}
				renderInputComponent={(inputProps) => {
					const iconProp =
						!_.isUndefined(this.props.clearableIfValid) &&
						this.props.clearableIfValid === true &&
						valid === true
							? { icon: 'ion-close-round' }
							: { icon: 'ion-chevron-down' };

					return (
						<InputComponent
							autoSuggestProps={inputProps}
							label={!_.isUndefined(label) ? label : 'Tenant'}
							name="tenant"
							placeHolder={!_.isUndefined(placeHolder) ? placeHolder : 'Tenant Name'}
							disabled={disabled}
							value={this.state.value}
							onChange={inputProps.onChange}
							onClick={inputProps.onClick}
							onBlur={(e) => {
								/* Ensures that member ID fetched even if not selected via autosuggest */

								this.checkMatch(e.target.value);
							}}
							parsley={{
								'data-parsley-matches-one-of': JSON.stringify({
									allSuggestions: this.props.tenants.map((tenant) => {
										return { value: tenant.firstName + ' ' + tenant.lastName };
									})
								}),
								'data-parsley-pattern': "/^[a-z ,.'-]+$/i", // alpha chars only https://stackoverflow.com/questions/8059370/
								'data-parsley-minlength': 4,
								'data-parsley-required': true,
								'data-parsley-error-message': 'Please enter an existing tenant name'
							}}
							{...iconProp}
						/>
					);
				}}
			/>
		);
	}
}

function mapStateToProps(state) {
	return {
		tenants: tenantSelector.getAllTenants(state),
		residents: residentSelectors.getResidents(state)
	};
}

TenantAddrTypeAutoSuggest.propTypes = {
	/* Optional initial tenant id to start with, (used when navigating back etc...) */
	defaultTenantId: PropTypes.number,
	/* Callback function for when item is selected */
	onSelected: PropTypes.func,
	/* Optional disable the input field */
	disabled: PropTypes.bool,
	/* Specifies if a X icon shows and the input field becomes clickable if the autosuggest is valid */
	clearableIfValid: PropTypes.bool,
	/** Optional label text*/
	label: PropTypes.string,
	/** Optional PlaceHolder Text */
	placeHolder: PropTypes.string
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(TenantAddrTypeAutoSuggest);
