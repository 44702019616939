import * as config from '../services/config';

export const PortalTypes = config.portalTypes;

//visitor system status types
export const VisitorStatuses = {
	CheckedIn: 'checkedin',
	CheckedOut: 'checkedout',
	Notified: 'notified'
};

//parcel system status types
export const ParcelStatuses = {
	CheckedIn: 'checkedin',
	Due: 'due',
	Collected: 'collected',
	Returned: 'returned',
	Expected: 'expected',
	Discarded: 'discarded'
};

//parcel system reason types
export const ParcelReasons = {
	Damaged: 'Damaged',
	ForwardedToNewAddress: 'Forwarded to new address',
	RecipientMovedOut: 'Recipient moved out',
	Uncollected: 'Uncollected',
	Other: 'Other'
};

//Bookings System config
export const ServicesUnit = {
	Minute: 'minute',
	Day: 'day'
};

//Component Auto suggest  config
export const SelectionMethod = {
	Down: 'down',
	Up: 'up',
	Escape: 'escape',
	Click: 'click',
	Type: 'type'
};
