import React, { Component } from "react";
import _ from "lodash";
import moment from "moment";
import autoBind from "react-autobind";

import "../../sass/components/CompactCard/index.css";

var PropTypes = require('prop-types');
/**
 * Display an assortment of labelled properties in a card format with two columns
 */

class CompactCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      highlighted: _.isUndefined(this.props.startHighlighted)
        ? false
        : this.props.startHighlighted
    };

    this.id = this.props.data.id ? this.props.data.id : null;

    autoBind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (_.isUndefined(nextProps.highlighted) === false) {
      this.setState({ highlighted: nextProps.highlighted });
    }
  }

  highlight(callback) {
    if (this.props.highlighted !== null) {
      this.setState({ highlighted: !this.state.highlighted }, callback);
    } else {
      callback();
    }
  }

  componentDidMount() {
    // https://github.com/kriasoft/react-starter-kit/issues/909
    // Pass instance to parent list so it can access it
    this.props.passInstanceToList &&
      this.props.passInstanceToList(this, this.props.data.id);
  }

  componentWillUnmount() {
    // Removes item from list of instances in parent
    this.props.passInstanceToList &&
      this.props.passInstanceToList(undefined, this.props.data.id);
  }

  // https://stackoverflow.com/questions/10073699
  padNumber(n, width, z) {
    z = z || "0";
    n = n + "";
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
  }

  formatString(str) {
    if (str.length > 20) {
      return (
        str.substr(0, 10) + "..." + str.substr(str.length - 10, str.length)
      );
    }
    return str;
  }
  render() {
    const { parcel, tenant, booking } = this.props.data ? this.props.data : {};


    return (
      <div
        onClick={e => {
          this.highlight(() => {
            this.props.onClick && this.props.onClick(e);
          });
        }}
        className={
          "compact-card" +
          (this.state.highlighted === true ? " highlighted" : "") +
          (this.props.highlighted === null ? " no-highlight" : "") +
          " type-" +
          (_.isUndefined(this.props.type) ? "default" : this.props.type)
        }
      >
        {(() => {
          switch (this.props.type) {
            case "parcel":
              let locationType = "Room";


              if (!_.isUndefined(parcel)) {
                const locationName =
                  !_.isUndefined(parcel.location) &&
                    !_.isUndefined(parcel.location) && parcel.location !== null
                    ? parcel.location.locationName
                    : parcel.locationName;

                //Try to factor in accidental double space locationName / prefix + number
                // const split = locationName
                //   .trim()
                //   .split(" ")
                //   .filter(value => {
                //     return value !== "";
                //   });

                locationType = locationName;
                //locationNumber = split[1];
              }

              return (
                <div className="compactData">
                  <h3>{tenant && tenant.firstName + " " + tenant.lastName}</h3>
                  <span className="address">
                    {!_.isUndefined(tenant) &&
                      this.formatString(
                        tenant.addressLine1 + " " + tenant.addressLine2
                      )}
                  </span>
                  <hr className="divide" />
                  <aside className="location">
                    <h4>Parcel Location:</h4>
                    <h5>{locationType}</h5>
                  </aside>
                </div>
              );

            case "booking":
              return (
                <div>
                  <h3>{tenant && tenant.firstName + " " + tenant.lastName}</h3>
                  <h4>{booking.resourceName}</h4>
                </div>
              );

            default:
              return (
                <div>
                  <h3>N/A</h3>
                  <h3>N/A</h3>
                  <h1>N/A</h1>
                </div>
              );
          }
        })()}
        <div className="betaData">
          {(() => {
            switch (this.props.type) {
              case "parcel":
                return (
                  <aside className="arrival">
                    <h4>Arrival Date</h4>
                    <h5>{moment(parcel.dateArrived).format("DD MMM YYYY")}</h5>
                  </aside>
                );

              case "booking":
                return (
                  <aside>
                    <h4>Date</h4>
                    <p>{booking.startDate}</p>
                  </aside>
                );

              default:
                return (
                  <aside>
                    <h4>Arrival Date</h4>
                    <p>26 Aug 2017</p>
                  </aside>
                );
            }
          })()}
          {(() => {
            switch (this.props.type) {
              case "parcel":
                return (
                  <aside
                    className={
                      "barcode-length " + Array(parcel.barcode.length).join("_")
                    }
                  >
                    <h4>Barcode</h4>
                    <p className="scaled">
                      {this.formatString(parcel.barcode)}
                    </p>
                  </aside>
                );


              case "booking":
                return (
                  <aside>
                    <h4>Time</h4>
                    <p>{`${booking.timeFrom} - ${booking.timeTo}`}</p>
                  </aside>
                );

              default:
                return (
                  <aside>
                    <h4>Barcode</h4>
                    <p>18273812731201</p>
                  </aside>
                );
            }
          })()}
        </div>
      </div>
    );
  }
}

CompactCard.propTypes = {
  /** Type is used to add a type- classes and used in the conditionals in render method on how the data HTML is printed out */
  type: PropTypes.string,
  /** Apart from ID, Data is flexible in what it can contain but every new type works with conditionals in the render method to access sub properties */
  data: PropTypes.object,
  /** Optional callback function to pass instance of self to parent array */
  passInstanceToList: PropTypes.func,
  /** Optional control the highlighted state, if null highlighting diabled */
  highlighted: PropTypes.bool,
  /** Optional set the initial highlighted state */
  startHighlighted: PropTypes.bool,
  /** Optional onClick method */
  onClick: PropTypes.func
};

export default CompactCard;
