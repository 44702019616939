import * as types from "./actionTypes";
import MeetingServices from "../../services/MeetingServices";

export function getMeetingById(id) {
  return async (dispatch, getState) => {
    try {
      const result = await MeetingServices.getMeetingById(id);

      if (result) {
        dispatch({ type: types.MEETING_RECORD_LOADED_SUCCESS, result });
      } else {
        dispatch({ type: types.MEETING_RECORD_LOADED_FAIL, result });
      }

    } catch (error) {
      dispatch({ type: types.MEETING_RECORD_LOADED_FAIL });
      console.error(error);
    }
  };
}

export function createNewMeeting(visitor) {
  return async (dispatch, getState) => {
    try {
      const data = visitor.name;
      const element = data.split(" ");
      const visitorData = {
        id: visitor.tenantId,
        status: visitor.status,
        dueDate: visitor.dueDate,
        endDate: visitor.endDate,
        firstName: "",
        surname: "",
        email: visitor.email,
        companyName: visitor.companyName,
        specialInstruction: visitor.specialInstruction
      };

      if (element.length > 1) {
        visitorData.firstName = element[0];
        visitorData.surname = element[1];
      }

      console.log(visitorData);
      var meeting = await MeetingServices.createMeeting(visitorData);
      console.log(meeting);
      if (meeting) {
        dispatch({ type: types.NEW_MEETING_CREATED_SUCCESS, meeting });
        dispatch({ type: types.MEETING_RECORD_LOADED_SUCCESS, meeting });
      } else dispatch({ type: types.NEW_MEETING_CREATED_FAIL, meeting });
    } catch (error) {
      console.error(error);
    }
  };
}

export function createNewMeetings(val, callback = () => { }) {
  return async (dispatch, getState) => {
    try {
      var visitors = val.map((visitor, index) => {
        const data = visitor.name;
        const element = data.split(" ");
        const visitorData = {
          id: visitor.tenantId,
          status: visitor.status,
          dueDate: visitor.dueDate,
          endDate: visitor.endDate,
          firstName: "",
          surname: "",
          email: visitor.email,
          companyName: visitor.companyName,
          specialInstruction: visitor.specialInstruction
        };

        if (element.length > 1) {
          for(var i=0; i<element.length;i++){
            if(i==0)
            visitorData.firstName = element[i];
            else 
            visitorData.surname +=element[i] + " ";
          }
        }

        return visitorData;
      });
      //console.log(visitors);
      var meetings = await MeetingServices.createMeetings(visitors);
      if (meetings) {
        dispatch({ type: types.NEW_MEETING_CREATED_SUCCESS, meetings });
        dispatch({ type: types.MEETING_RECORD_LOADED_SUCCESS, meetings });
        callback(true);
      } else {
        dispatch({ type: types.NEW_MEETING_CREATED_FAIL, meetings });
        callback(false);
      }
    } catch (error) {
      console.error(error);
    }
  };
}

export function updateMeeting(data, callback = () => { }) {
  return async (dispatch, getState) => {
    try {
      const result = await MeetingServices.updateMeetingRecord(data);

      dispatch({ type: types.UPDATE_MEETING_SUCCESS, data: result });
      callback(true);


    } catch (error) {
      callback(false);
      dispatch({ type: types.UPDATE_MEETING_FAIL });
    }

  }
}

export function getMeeting(meeting) {
  return async (dispatch, getState) => {
    try {
      if (meeting) {
        var meetings = [meeting];
        dispatch({ type: types.MEETING_RECORD_LOADED_SUCCESS, meetings });
      } else dispatch({ type: types.MEETING_RECORD_LOADED_FAIL, meeting });
    } catch (error) {
      console.error(error);
    }
  };
}

export function addMeetingToQueue(meetingList, callback = () => { }) {
  return (dispatch, getState) => {
    dispatch({
      type: types.NEW_METTING_ADD_TO_QUEUE,
      meetings: meetingList
    })
    callback(true);
  }
}

export function removeMeetingFromQueue(index) {
  return {
    type: types.NEW_METTING_REMOVE_FROM_QUEUE,
    index: index
  };
}

export function setMettingsQueue(meetingsList) {
  return {
    type: types.SET_MEETINGS_QUEUE,
    meetingQueue: meetingsList
  };
}

export function clearMeetings() {
  let clear = [];
  return { type: types.CLEAR_MEETINGS, clear };
}

export function getMeetingsByDate(date) {
  return async (dispatch, getState) => {
    try {
      var meetings = await MeetingServices.getMeetingsByDate(date);

      if (meetings && meetings.length > 0) {
        dispatch({ type: types.MEETINGS_LOADED_SCCESS, meetings });
      } else dispatch({ type: types.MEETINGS_LOADED_FAIL, meetings });
    } catch (error) {
      console.error(error);
    }
  };
}

export function getFilterMeetingsByDate(date, callback = () => { }) {
  return async (dispatch, getState) => {
    try {
      var meetings = await MeetingServices.getMeetingsByDate(date);


      dispatch({ type: types.MEETINGS_FILTER_LOADED_SUCCESSFULLY, meetings })

      callback(true);

    } catch (error) {
      dispatch({ type: types.MEETINGS_FILTER_LOADED_FAIL });
      callback(false);
    }
  };
}

export function meetingSelected(meeting) {
  return {
    type: types.MEETING_SELECTED,
    meeting
  };
}

export function addToFilterCheckIn(meeting) {
  return {
    type: types.ADD_TO_FILTER_CHECKED_IN,
    meeting
  };
}

export function addToFilterCheckOut(meeting) {
  return {
    type: types.ADD_TO_FILTER_CHECKED_OUT,
    meeting
  };
}

////////////////////////////////////////////////////////////////////////////////

export function addToCheckedInSelectQueue(list) {
  return {
    type: types.ADD_TO_CHECKED_IN_SELECT_QUEUE,
    meeting: list
  };
}

export function removeFromCheckedInSelectQueue(meeting) {
  return {
    type: types.REMOVE_FROM_CHECKED_IN_SELECT_QUEUE,
    meeting: meeting
  };
}
export function addToCheckedOutSelectQueue(list) {
  return {
    type: types.ADD_TO_CHECKED_OUT_SELECT_QUEUE,
    meeting: list
  };
}

export function removeFromCheckedOutSelectQueue(meeting) {
  return {
    type: types.REMOVE_FROM_CHECKED_OUT_SELECT_QUEUE,
    meeting: meeting
  };
}

////////////////////////////////////////////////////////////////////////////////

export function addToUncollectedSelectQueue(list) {
  return {
    type: types.ADD_TO_UNCOLLECTED_SELECT_QUEUE,
    meeting: list
  };
}

export function removeFromUncollectedSelectQueue(meeting) {
  return {
    type: types.REMOVE_FROM_UNCOLLECTED_SELECT_QUEUE,
    meeting: meeting
  };
}
export function addToCollectedSelectQueue(list) {
  return {
    type: types.ADD_TO_COLLECTED_SELECT_QUEUE,
    meeting: list
  };
}

export function removeFromCollectedSelectQueue(meeting) {
  return {
    type: types.REMOVE_FROM_COLLECTED_SELECT_QUEUE,
    meeting: meeting
  };
}

////////////////////////////////////////////////////////////////////////////////

export function fetchMeetingsLog(meetings) {
  return {
    type: types.MEETINGS_LOG_FETCHED,
    meetingsLog: meetings
  };
}

export function passCollectedById(id, collect = true) {
  return async (dispatch, getState) => {
    try {
      var meeting = await MeetingServices.passCollectedById(id, collect);

      if (meeting) {
        dispatch({
          type: types.MEETING_UPDATED,
          meeting
        });
      } /* else {
        dispatch({
          type: types.VISITOR_CHECKIN_FAIL,
          visitor
        });
      }*/
    } catch (error) {
      console.error(error);
    }
  };
}

//////////////////////////////////////////////////////////////////////////////

export function addSpectialInstruction(data) {
  return async (dispatch, getState) => {
    if (data)
      dispatch({
        type: types.SPECIAL_INSTRUCTIONS_APPENDED_SUCCESS,
        specialInstruction: data
      });
  };
}

export function notifyCheckedIn(meetings, callback = () => { }) {
  return async (dispatch, getState) => {
    try {
      const notifyResponse = await MeetingServices.notifiCheckedIn(meetings);
      dispatch({
        type: types.SEND_CHECKIN_NOTIFICATION_SUCCESS,
        notifyResponse
      });
      callback(true)
    } catch (error) {
      callback(false);
      console.error(error);

    }
  };
}
