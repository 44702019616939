import * as types from './actionTypes';
import Immutable from 'seamless-immutable';
import _ from 'lodash';

const initialState = Immutable({
	parcel: undefined,
	parcels: [],
	ManageParcels: [],
	parcelDue: [],
	parcelsExpected: [],
	newParcelQueue: [],
	checkOutQueue: [],
	registeredParcels: [],
	registeredParcelsQueue: [],
	locations: [],
	returnStatusReasons: [],
	statusCodes: [],
	returnReasonCodes: [],
	parcelsCheckedIn: [],
	checkinNotified: false,
	checkoutNotified: false,
	parcelSettings: undefined
});

export default function parcelReducer(state = initialState, action = {}) {
	switch (action.type) {
		case types.PARCELS_LOADED_SUCCESS:
			return state.merge({
				parcels: action.parcels
			});
		case types.PARCELS_LOADED_SUCCESS_MANAGE:
			return state.merge({
				ManageParcels: action.parcels
			});
		case types.PARCEL_LOCATIONS_LOADED_SUCCESS:
			return state.merge({
				locations: action.locations
			});
		case types.PARCEL_STATUS_REASONS_LOADED_SUCCESS:
			return state.merge({
				returnStatusReasons: action.reasons
			});
		case types.PARCEL_RECORD_LOADED_SUCCESS:
			return state.merge({
				parcel: action.parcel
			});

		case types.PARCEL_ADD_TO_CHECKIN_QUEUE:
			return state.merge({
				newParcelQueue: [ ...state.newParcelQueue, ...action.parcels ]
			});

		case types.PARCEL_SET_CHECKOUT_QUEUE:
			return state.merge({
				checkOutQueue: action.parcels
			});

		case types.PARCEL_REMOVE_FROM_CHECKIN_QUEUE:
			return state.merge({
				newParcelQueue: state.newParcelQueue.filter((parcel) => {
					return action.index === '*' ? false : parcel.index !== action.index;
				})
			});

		case types.PARCEL_EDIT_IN_CHECKIN_QUEUE:
			// https://stackoverflow.com/questions/43792457/
			return state.merge({
				newParcelQueue: state.newParcelQueue.map((parcel) => {
					if (parcel.index === action.parcel.index) {
						// Create new parcel
						return Object.assign({}, parcel, action.parcel);
					} else {
						return parcel;
					}
				})
			});

		case types.PARCELS_ADDED_SUCCESS:
			return state.merge({
				parcels: [ ...state.parcels, ...action.addParcelResponse ],
				parcelsCheckedIn: action.addParcelResponse
			});

		case types.PARCELS_STATUS_CODES_SUCCESS:
			return state.merge({
				statusCodes: action.statusCodesResponse
			});

		case types.PARCEL_RETURN_REASON_CODES_LOAD_SUCCESS:
			return state.merge({
				returnReasonCodes: action.reasonCodesResponse
			});

		case types.NOTIFY_PARCELS_CHECKED_IN_SUCCESS:
			return state.merge({
				checkinNotified: action.notified
			});
		case types.NOTIFY_PARCELS_COLLECTED_SUCCESS:
			return state.merge({
				checkoutNotified: action.notified
			});
		case types.PARCELS_EXPECTED_ARRIVED_BY_DATE_LOADED_SUCCESSFULL:
			let parcelList = _.map(state.parcels, (item) => {
				let parcel = _.find(action.list, { id: item.id });
				return !_.isUndefined(parcel) && parcel.id === item.id ? parcel : item;
			});
			return state.merge({
				parcels: parcelList,
				parcelsCheckedIn: action.list
			});
		case types.PARCEL_SETTINGS_LOAD_SUCCESSFULL:
			return state.merge({
				parcelSettings: action.settings
			});
		case types.PARCEL_DUE_LOADED_SUCCESSFULLY:
			return state.merge({
				parcelDue: action.data
			});
		case types.PARCEL_EXPECTED_LOAD_SUCCESSFULLY:
			return state.merge({
				parcelsExpected: action.data
			});
		case types.REGISTER_EXPECTED_PARCEL_LIST_SUCCESSFULL:
			let filteredParcelList = _.filter(
				_.map(state.parcels, (item) => {
					let parcel = _.find(action.parcels, { id: item.id });
					if (!_.isUndefined(parcel)) {
						return undefined;
					}
					return item;
				}),
				(o) => !_.isUndefined(o)
			);
			return state.merge({
				registeredParcels: action.parcels,
				parcels: filteredParcelList
			});
		case types.PARCEL_RECORD_UPDATED_SUCCESSFULLY:
			return state.merge({
				parcel: action.data
			});
		case types.PARCEL_CHECKOUT_SUCCESS:
			return state.merge({
				parcel: action.response
			});
		case types.PARCELS_CHECKOUT_SUCCESS:
			let list_ = _.filter(
				_.map(state.parcelDue, (item) => {
					let parcel = _.find(action.addCheckoutResponse, { id: item.id });
					return _.isUndefined(parcel) ? item : undefined;
				}),
				(data) => !_.isUndefined(data)
			);

			let list = _.filter(
				_.map(state.parcels, (item) => {
					let parcel_ = _.find(action.addCheckoutResponse, { id: item.id });
					return _.isUndefined(parcel_) ? item : undefined;
				}),
				(data_) => !_.isUndefined(data_)
			);

			return state.merge({
				parcelDue: list_,
				parcels: list
			});
		default:
			return state;
	}
}

export function getStatusCodes(state) {
	return state.parcels.statusCodes;
}

export function getReturnResonCodes(state) {
	return state.parcels.returnReasonCodes;
}

export function getParcels(state, status = '*') {
	// if (status === "*") {
	//   return state.parcels.parcels;
	// } else {
	//   const statusObject = state.parcels.statusCodes.find(code => {
	//     return code.status === status;
	//   });

	//   if (_.isUndefined(statusObject)) {
	//     return state.parcels.parcels;
	//   } else {
	//     return state.parcels.parcels.filter(parcel => {
	//       return parcel.statusId === statusObject.id;
	//     });
	//   }
	// }

	return state.parcels.parcels;
}

export function getManageParcels(state, status = '*') {
	return state.parcels.ManageParcels;
}

export function getCheckedInParcels(state) {
	// const statusObject = state.parcels.statusCodes.find(code => {
	//   return code.status === "checkedin";

	// });

	// const statusObject = _.find(state.parcels.statusCodes, (code) => {
	// 	return code.status === 'checkedin';
	// });

	// const statusObject_due = _.find(state.parcels.statusCodes, (o) => {
	// 	return o.status === 'due';
	// });

	// if (_.isUndefined(statusObject) || _.isUndefined(statusObject_due)) {
	// 	return state.parcels.parcels;
	// }

	// return state.parcels.parcels.filter((parcel) => {
	// 	return parcel.statusId === statusObject.id || parcel.statusId === statusObject_due.id;
	// });

	return state.parcels.parcelsCheckedIn;
}

export function getCheckedOutParcels(state) {
	// const statusObject = state.parcels.statusCodes.find(code => {
	//   return code.status === "collected";
	// });
	const statusObject = _.find(state.parcels.statusCodes, (code) => {
		return code.status === 'collected';
	});
	if (_.isUndefined(statusObject)) {
		return state.parcels.parcels;
	}

	return state.parcels.parcels.filter((parcel) => {
		return parcel.statusId === statusObject.id;
	});
}

export function getDueParcels(state) {
	// const statusObject = state.parcels.statusCodes.find(code => {
	//   return code.status === "due";
	// });
	const statusObject = _.find(state.parcels.statusCodes, (code) => {
		return code.status === 'due';
	});
	if (_.isUndefined(statusObject)) {
		return state.parcels.parcels;
	}

	return state.parcels.parcels.filter((parcel) => {
		return parcel.statusId === statusObject.id;
	});
}

export function getReturnedParcels(state) {
	// const statusObject = state.parcels.statusCodes.find(code => {
	//   return code.status === "returned";
	// });
	const statusObject = _.find(state.parcels.statusCodes, (code) => {
		return code.status === 'returned';
	});
	if (_.isUndefined(statusObject)) {
		return state.parcels.parcels;
	}

	return state.parcels.parcels.filter((parcel) => {
		return parcel.statusId === statusObject.id;
	});
}

export function getCheckInQueue(state) {
	return state.parcels.newParcelQueue;
}

export function getCheckoutQueue(state) {
	return state.parcels.checkOutQueue;
}

export function getParcel(state) {
	return state.parcels.parcel;
}
export function getParcelLocations(state) {
	return state.parcels.locations;
}
export function getReturnStatusReasons(state) {
	return state.parcels.returnStatusReasons;
}

export function getParcelsCheckedIn(state) {
	return state.parcels.parcelsCheckedIn;
}

export function getParcelCheckinNotified(state) {
	return state.parcels.checkinNotified;
}
export function getParcelCheckoutNotified(state) {
	return state.parcels.checkoutNotified;
}
export function getParcelSettings(state) {
	return state.parcels.parcelSettings;
}
export function getParcelDue(state) {
	return state.parcels.parcelDue;
}

export function getParcelExpected(state) {
	return state.parcels.parcelsExpected;
}
export function getRegisteredParcels(state) {
	return state.parcels.registeredParcels;
}
