import * as types from "./actionTypes";
import Immutable from "seamless-immutable";

const initialState = Immutable({
  authToken: undefined,
  isLoggedIn: false,
  email: "",
  password: "",
  resetPass: "",
  validRequest: false,
  passwordReset: false,
  loginSuccess: null,
  concierge: ""
});

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.LOGIN_SUCESSFUL:
      return state.merge({
        authToken: action.loginData.authToken,
        isLoggedIn: true,
        loginSuccess: action.loginData.authToken ? true : false
      });
    case types.EMAIL_CHANGED:
      return state.merge({
        email: action.value
      });
    case types.PASSWORD_CHANGED:
      return state.merge({
        password: action.value
      });
    case types.LOGGED_OUT:
      return state.merge({
        isLoggedIn: false,
        loginSuccess: null
      });
    case types.PASSWORD_RESET_REQUEST_SENT_SUCCESS:
      return state.merge({
        resetPass: action.resetPass
      });
    case types.PASWORD_REQUEST_VERIFY_SUCCESS:
      return state.merge({
        validRequest: action.validRequest
      });
    case types.PASSWORD_RESET_SUCCESSFULL:
      return state.merge({
        passwordReset: action.passwordReset
      });
    case types.LOGIN_FAIL:
      return state.merge({
        loginSuccess: action.response
      });
    case types.CONCIERGE_AUTHORIZED_SUCCESSFULL:
      return state.merge({
        concierge: action.detail
      });
    default:
      return state;
  }
}

export function getAuthToken(state) {
  return state.auth.authToken;
}

export function getIsLoggedIn(state) {
  return state.auth.isLoggedIn;
}

export function getEmail(state) {
  return state.auth.email;
}

export function getPassword(state) {
  return state.auth.password;
}

export function passwordReset(state) {
  return state.auth.passwordReset;
}

export function emailVerifyStatus(state) {
  return state.auth.validRequest;
}

export function getLoginSuccess(state) {
  return state.auth.loginSuccess;
}

export function getConciergeDetails(state) {
  return state.auth.concierge;
}