export const SERVICES_FETCHED = "SERVICES_FETCHED";
export const RESOURCES_QUERY = "RESOURCES_QUERY";
export const SERVICES_SLOTS_FETCHED = "SERVICES_SLOTS_FETCHED";
export const SLOTS_FETCHED = "SLOTS_FETCHED";
export const SERVICE_DETAIL_FETCHED = "SERVICE_DETAIL_FETCHED";
export const SLOT_SELECTED = "SLOT_SELECTED";
export const SLOT_DESELECTED = "SLOT_DESELECTED";
export const ADD_TO_BASKET_QUEUE = "ADD_TO_BASKET_QUEUE";
export const REMOVE_FROM_BASKET_QUEUE = "REMOVE_FROM_BASKET_QUEUE";
export const SERVICE_ID_SELECTED = "SERVICE_ID_SELECTED";
export const EVENT_DATE_SELECTED = "EVENT_DATE_SELECTED";
export const SELECTED_SERVICE_SLOT = "SELECTED_SERVICE_SLOT";
export const CLEAR_BASKET_QUEUE = "CLEAR_BASKET_QUEUE";
export const RESOURCE_BOOKINGS_FETCHED = "RESOURCE_BOOKINGS_FETCHED";
export const ADD_TO_BOOKINGS_SELECTION_QUEUE =
  "ADD_TO_BOOKINGS_SELECTION_QUEUE";
export const CANCEL_BOOKINGS_REASONS = "CANCEL_BOOKINGS_REASONS";
export const BOOKINGS_CANCELED_SUCCESS = "BOOKINGS_CANCELED_SUCCESS";
export const BLOCK_SLOT_SELECTED = "BLOCK_SLOT_SELECTED";
export const BLOCK_SLOT_DESELECTED = "BLOCK_SLOT_DESELECTED";
export const RESOURCE_TIME_AVAILABLE_FETCHED =
  "RESOURCE_TIME_AVAILABLE_FETCHED";
export const UPDATE_BOOKING_SUCCESS = "UPDATE_BOOKING_SUCCESS";
export const BOOKING_ERRORS_LOAD_SUCCESS = "BOOKING_ERRORS_LOAD_SUCCESS";
export const BOOKING_DETAIL_LOAD_SUCCESS = "BOOKING_DETAIL_LOAD_SUCCESS";
export const BOOKING_CANCELLED_NOTIFY_SUCCESS =
  "BOOKING_CANCELLED_NOTIFY_SUCCESS";
export const BOOKING_UPDATED_NOTIFY_SUCCESS = "BOOKING_UPDATED_NOTIFY_SUCCESS";
export const BOOKING_CREATED_NOTIFY_SUCCESS = "BOOKING_CREATED_NOTIFY_SUCCESS";
export const BOOKING_CREATED_NOTIFY_FAIL = "BOOKING_CREATED_NOTIFY_FAIL";
export const EVENTS_SLOTS_FETCHED = "EVENTS_SLOTS_FETCHED";
export const EVENTS_RESOURCE_SELECTED = "EVENTS_RESOURCE_SELECTED";
export const BOOKED_EVENT_SLOT_IS_SELECTED = "BOOKED_EVENT_SLOT_IS_SELECTED";
export const BOOKING_QUESTIONS_LOAD_SUCCESS = "BOOKING_QUESTIONS_LOAD_SUCCESS";
export const BOOKING_QUESTIONS_LOAD_FAIL = "BOOKING_QUESTIONS_LOAD_FAIL";
export const RESOURCE_BLOCKED_SUCCESS = "RESOURCE_BLOCKED_SUCCESS";
export const RESOURCE_BLOCKED_FAIL = "RESOURCE_BLOCKED_FAIL";
export const RESOURCE_EVENT_SET = "RESOURCE_EVENT_SET";
export const RESOURCE_BOOKED_SUCCESS = "RESOURCE_BOOKED_SUCCESS";
export const RESOURCE_BOOKED_FAIL = "RESOURCE_BOOKED_FAIL";
export const RESOURCE_EVENTS_CLEARED = "RESOURCE_EVENTS_CLEARED";
export const SERVICE_UNIT_CHANGED = "SERVICE_UNIT_CHANGED";
export const RESOURCE_EVENT_SELECTED = "RESOURCE_EVENT_SELECTED";
export const SELECTED_TENANT_SET_SUCCESS = "SELECTED_TENANT_SET_SUCCESS";
export const ACTION_REDIRCECT_SUCCESS = "ACTION_REDIRCECT_SUCCESS";
export const PAYMENT_ACTION_SUCCESS = "PAYMENT_ACTION_SUCCESS";
export const PAYMENT_ACTION_FAIL = "PAYMENT_ACTION_FAIL";
export const SET_BBOOKING_SUCCESS = "SET_BBOOKING_SUCCESS";
export const CALENDAR_SETTINGS_SET = "CALENDAR_SETTINGS_SET";
export const PROCESSING_RESOURCE_REQUEST = "PROCESSING_RESOURCE_REQUEST";
export const BASKET_QUEUE_UPDATED_SUCCESSFULLY = "BASKET_QUEUE_UPDATED_SUCCESSFULLY";