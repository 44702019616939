import React, { Component } from "react";
import { connect } from "react-redux";

import HeaderNavigation from "../components/HeaderNavigation/HeaderNavigation";
import FooterNavigation from "../components/FooterNavigation/FooterNavigation";
import autoBind from "react-autobind";
import * as authSelectors from "../store/auth/reducer";
import * as settingActions from "../store/ConciergeSetting/actions";
import * as settingSelector from "../store/ConciergeSetting/reducer";

import "../sass/static/logout.css";

import "../sass/static/tsandcs.css"

class TermsAndConditions extends Component {
  constructor(props) {
    super(props);

    autoBind(this);
    this.props.dispatch(settingActions.getGlobalSetting());
  }


  render() {
    const { settings } = this.props;
    let bgImage = "";
    if (settings) {
      bgImage = settings.mainBackgroundImage;
    }
    const bgStyle = {
      backgroundImage: "url('" + bgImage + "')",
      backgroundRepeatY: "no-repeat",
      backgroundSize: "cover"
    };

    return (
      <div className="loginScreen tsandcs">
        <HeaderNavigation isLoggedIn={false} setting={settings} />

        <div className="loginWrap" style={bgStyle}>
          <h2 className="loginScreen" >Terms and Conditions</h2>
        </div>

        <div className="white-container">

          <p className="content-wrapper">
            So you have your new digital camera and clicking away to glory anything and everything in sight. Now you want to print them and you need the best photo printers to print your fantastic photos. Let us talk about the various printers in the market and some tips on choosing the best photo printers.

Most of the printers could easily be purchased under $500. While all of them have similar features, it is better to be aware of certain key features that determines their price and print quality. First to look out for is the printing width. It differs among printers. Normally they are available with a width of either 8.5 inches or 13 inches. A 6 color printer is adequate but if you want good black and white images as well it is better to go for 8-color printer.

Many of the best photo printers today have the ability to print directly from your camera or flash memory card through a simple device. You must also look for the printing speed per minute. Generally it takes up to 10 minutes for one color photo so you must factor it in when choosing the model. When you want to buy multi use printers, please note that for good quality photo printing you need to buy a dedicated printer or a digital photo printer that accepts the most popular memory cards.

Now let us see the top 5 best photo printers and some of their features:

Epson Stylus Pro 4000 can take in paper wider than 11 inches thus making it very convenient. The high quality color and grayscale printing and the ability to make use of rolls of paper instead of sheets make it very economical too.

Canon Pixma IP8500 Photo Printer is another best photo printer in our top 5 list. It scores very high on speed, color and print quality. Almost all users love the 8 color cartridges. One disadvantage is the absence of media card slot – simply unacceptable in a high-end printer. The built in duplexer and good text printing however makes up for this disadvantage as it could be used for general and business use as well.

Canon Slephy DS700 is the best photo printer in the portable category. It plugs in directly into your TV so you could examine your photos in detail before printing them without having to transfer them to a PC.

The ever popular Epson Picture Mate is another best seller in its newest version – The Epson Picture Mate Deluxe. Though it has many added features and higher speeds, it no longer seem to have the cost-effectiveness per print of the older model.

Another best photo printer worth considering is the Sony PictureStation DPP-FP30. the installation is simple and the quality is simply Sony standards. However a small problem is the high running costs.

So have fun with the best photo printer that you have selected and may your photo memories come alive as good photo prints too!
</p>
        </div>
        <FooterNavigation />

      </div>

    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    isLoggedIn: authSelectors.getIsLoggedIn(state),
    email: authSelectors.getEmail(state),
    settings: settingSelector.loadGlobalSetting(state)
  };
}
export default connect(mapStateToProps)(TermsAndConditions);
