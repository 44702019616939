import _ from "lodash";
import * as types from "./actionTypes";
import * as resourcesTypes from "../Resources/actionTypes";

import moment from "moment";
import dateFormat from "dateformat";
import * as resourcesMonthlySelectors from "./reducer";
import resourcesService from "../../services/BookingBugServices/ResourceService";

import checkoutService from "../../services/BookingBugServices/CheckoutService";
import engageBookingServices from "../../services/EngageBookingBugServices/EngageBookingServices.js";
import * as tenantSelector from "../../store/Tenants/reducer";
import * as resourcesSelectors from "../Resources/reducer";




export function fetchServicesSlots(serviceId, startDate, endDate, callback = () => { }) {
    return async (dispatch, getState) => {
        try {
            
            var dayData = resourcesMonthlySelectors.getServiceDayData(getState());
            var resources = resourcesMonthlySelectors.getResources(getState());


            var servicesSlots = _.map(dayData, (data, key) => {


                let days = _.map(data, d => {
                    return {
                        spaces: d.spaces,
                        price: d.price,
                        datetime: d.date

                    };
                });
                let resource = _.find(resources, { id: Number(key) });

                let dayVal = {
                    resource_id: Number(key),
                    days: days,
                    event_id: data[0].event_id,
                    name: !_.isUndefined(resource) ? resource.name : ""
                };

                return dayVal;
            });

            dispatch({
                type: types.MONTHLY_SERVICES_SLOTS_FETCHED,
                servicesSlots
            });

            callback(true);
        } catch (error) {
            callback(false);
            console.error(error);
        }
    };
}



export function getServiceBookings(serviceId, startDate, endDate, callback = () => { }) {
    return async (dispatch, getState) => {
        try {

            // const dateRange = getDatesRange(startDate, endDate);


            // let bookings_range = []

            const bookingsList = await engageBookingServices.getBookings(startDate, endDate);

            // for (var b = 0; b < dateRange.length; b++) {

            //     const booking = await engageBookingServices.getBookings(dateFormat(dateRange[b], "isoDate"), dateFormat(dateRange[b], "isoDate"));

            //     // const booking = await engageBookingServices.getBookings(dateFormat(dateRange[b], "isoDate"), dateFormat(dateRange[b], "isoDate"));
            //     if (booking) {
            //         bookings_range.push(booking);
            //     }
            // }

            // let temp_bookings = _.map(bookings_range, booking_item => {
            //     _.map(booking_item, item => {
            //         bookingsList.push(item);
            //     })
            // });

            // const response = await resourcesService.getBookings(date, date);
            const list = _.filter(
                bookingsList,
                o => o.service_id === Number(`${serviceId}`)
            );

            const tenants = tenantSelector.getAllTenants(getState());

            let bookings = _.map(list, o => {
                return {
                    id: o.id,
                    email: o.client_email,
                    serviceId: o.service_id,
                    name: o.client_name,
                    resourceName: o.resource_name,
                    startDate: dateFormat(o.datetime, "dd/mm/yyyy"),
                    endDate: o.end_datetime,
                    duration: o.duration,
                    timeFrom: dateFormat(o.datetime, "shortTime"),
                    timeTo: dateFormat(o.end_datetime, "shortTime"),
                    tenant: _.find(tenants, {
                        email: o.client_email
                    })
                };
            });

            dispatch({
                type: types.MONTHLY_RESOURCE_BOOKINGS_FETCHED,
                bookings: bookings
            });

            callback(true);
        } catch (error) {
            callback(false);
            console.error(error);
        }
    };
}


export function fetchResources(page, perpage, callback = () => { }) {
    return async (dispatch, getState) => {
        try {
            var result = await engageBookingServices.getResources(page, perpage);

            dispatch({ type: types.RESOURCES_FETCHED_SUCCESSFULLY, resources: result });

            callback(true);
        } catch (error) {
            dispatch({ type: types.RESOURCES_FETCHED_FAIL });
            callback(false);
            console.error(error);
        }
    }
}

export function fetchServiceDayData(serviceId, startDate, endDate, callback = () => { }) {
    return async (dispatch, getState) => {
        try {


            var result = await engageBookingServices.getServiceDayData(serviceId, startDate, endDate);
            var data = [];

            if (result && result.length > 0)
                data = _.groupBy(result, val => val.resource_id);

            dispatch({ type: types.SERVICE_DAYDATA_FETCHED_SCCESSFULLY, dayData: data });

            callback(true);
        } catch (error) {
            dispatch({ type: types.SERVICE_DAYDATA_FETCHED_FAIL });
            callback(false);
            console.error(error);
        }
    }
}

export function fetchResourceDayData(events, serviceId, startDate, endDate, page, perpage, callback = () => { }) {
    return async (dispatch, getState) => {
        try {


            var result = await engageBookingServices.getResourceDayData(events, serviceId, startDate, endDate, page, perpage);
            var data = [];


            if (result && result.length > 0)
                data = _.groupBy(result, val => val.resource_id);


            dispatch({ type: types.RESOURCE_DAYDATA_FETCH_SUCCESS, data: data });

            callback(true);
        } catch (error) {
            dispatch({ type: types.RESOURCE_DAYDATA_FETCH_FAIL });
            callback(false);
            console.error(error);
        }
    }
}


export function getSelectedEventsSlots(events,
    startDate,
    endDate,
    serviceId,
    callback = () => { }) {
    return async (dispatch, getState) => {
        try {
            //var bookings = await engageBookingServices.getBookings(moment(startDate).format("YYYY-MM-DD"), moment(endDate).format("YYYY-MM-DD"));

            var bookings = []

            const basket = await checkoutService.getBasket();
            const tenants = tenantSelector.getAllTenants(getState());
            var servicesSlots = resourcesMonthlySelectors.getServicesSlots(getState());

            var resourceIds = _.filter(_.map(events, eventData => {
                let resourceData = _.find(servicesSlots, { event_id: eventData.eventId });
                if (resourceData) {
                    return { resourceId: resourceData.resource_id }
                }
                return undefined
            }), x => !_.isUndefined(x));

            const resourceObj = {
                events: resourceIds
            }

            if (events && events.length > 0) {
                bookings = await engageBookingServices.getResourceBookings(resourceObj, moment(startDate).format("YYYY-MM-DD"), moment(endDate).format("YYYY-MM-DD"), 1, 397);
            }

            var bookedBookings = _.get(bookings, "booked._embedded.bookings");
            var blockedBookings = _.get(bookings, "blocked._embedded.bookings");

            var dayData = resourcesMonthlySelectors.getResourceDayData(getState());
            var services = resourcesSelectors.getServices(getState());
            var service = resourcesSelectors.getService(getState());

            const dateRange = getDatesRange(startDate, endDate);


            if (servicesSlots) {

                var slots = [];
                var bookedSlots = [];
                let card = 0;


                const eventsSlots = _.map(events, (event, index) => {
                    card = index + 1;

                    const serviceBooking = _.filter(
                        bookedBookings,
                        booking =>
                            booking.service_id === serviceId && booking.resource_name === event.eventName
                    );

                    const blockedResourceBooking = _.filter(blockedBookings, resource => resource.resource_name === event.eventName);



                    const serviceSlot = _.filter(servicesSlots, o => o.event_id === event.eventId);


                    _.map(serviceSlot, slotService => {
                        let daySlot = slotService.days;


                        _.map(dateRange, (dteRange, index) => {

                            _.map(daySlot, (s, i) => {

                                if (moment(s.datetime).format("YYYY-MM-DD") === moment(dteRange).format("YYYY-MM-DD")) {
                                    let today = dteRange;

                                    let start = new Date(
                                        today.getFullYear(),
                                        today.getMonth(),
                                        today.getDate()
                                    );

                                    let end = new Date(
                                        today.getFullYear(),
                                        today.getMonth(),
                                        today.getDate()
                                    );

                                    let startDay = moment(start).format("YYYY-MM-YY");
                                    let endDay = moment(end).format("YYYY-MM-YY");

                                    let lastEntryId = slots.length ? slots[slots.length - 1].id + 1 : 0;


                                    //check with Bookingbug, item added tobasket still show spaces 1 on resource available
                                    //can not do this here, too heavy a load
                                    // let inBasket = false;
                                    // for (var index = 0; index < basket.length; index++) {
                                    //     var el = basket[index];

                                    //     if (Number(el.event_id) === Number(event.eventId) && el.date === moment(s.datetime).format("YYYY-MM-DD")) {
                                    //         console.log("event in basket");
                                    //         console.log(event);
                                    //         inBasket = true;
                                    //     }
                                    // }//end for loop

                                    if (s.spaces === 1) {

                                        let availSlot = {
                                            isAvailable: false,
                                            start: new Date(start),
                                            end: new Date(end),
                                            price: s.price,
                                            id: lastEntryId,
                                            eventId: event.eventId,
                                            //inBasket: inBasket,
                                        };

                                        if (
                                            today < new Date()
                                        ) {
                                            availSlot.isAvailable = false;
                                            slots.push(availSlot);
                                        } else {
                                            availSlot.isAvailable = true;

                                            // if (inBasket)
                                            //     availSlot.isAvailable = false;

                                            slots.push(availSlot);
                                        }


                                    } else {


                                        let inBasket = false;
                                        for (var index = 0; index < basket.length; index++) {
                                            var el = basket[index];
                                            if (Number(el.event_id) === Number(event.eventId) && el.date === moment(s.datetime).format("YYYY-MM-DD")) {

                                                inBasket = true;
                                            }
                                        }//end for loop

                                        let unavailSlot = {
                                            isAvailable: false,
                                            start: new Date(start),
                                            end: new Date(end),
                                            inBasket: inBasket,
                                            id: i,
                                            eventId: event.eventId
                                        };

                                        slots.push(unavailSlot);
                                    }//end else


                                }//end if 


                            }); //end daySlot              
                        }); //end dateRange
                    }); // end serviceSlot




                    const blockedSlot = _.map(blockedResourceBooking, (blocked, index) => {


                        if (blocked.resource_name === event.eventName) {
                            let last_id = slots.length ? slots[slots.length - 1].id : 0;
                            let blockedDate = new Date(blocked.datetime);
                            let blockedEndDate = new Date(blocked.end_datetime);
                            let blockedDate_start_min = blockedDate.getHours() * 60 + blockedDate.getMinutes();
                            let blockedDate_end_min = blockedEndDate.getHours() * 60 + blockedEndDate.getMinutes();
                            let today = new Date();
                            let today_min = today.getHours() * 60 + today.getMinutes();
                            let status;

                            if (blockedDate < today) {
                                status = true
                            } else if (moment(blockedDate).format("YYYY/MM/DD") === moment(today).format("YYYY/MM/DD")) {
                                status = true;
                            } else {
                                status = false;
                            }
                            let blockedAvailSlot = {
                                isAvailable: false,
                                start: new Date(blockedDate),
                                end: new Date(blockedEndDate),
                                price: blocked.price,
                                id: blocked.id, //last_id > 0 ? last_id + 1 : index,
                                desc: blocked.full_describe,
                                blocked: true,
                                eventId: event.eventId,
                                eventName: event.eventName,
                                resourceId: blocked.resource_id,
                                past: status,
                                start_min: blockedDate_start_min,
                                end_min: blockedDate_end_min,
                                title: event.eventName,
                                card: 0,
                                blocked_card: card,
                                duration: ((blocked.duration / 60) / 24),  //calculation made as duration is returned in minutes e.g (1440)
                                type: service.duration_unit
                            };

                            slots.push(blockedAvailSlot);

                            return blockedAvailSlot;
                        }
                        return blocked
                    });



                    const bookedSlot = _.map(serviceBooking, (booked, i) => {


                        let lastId = slots.length ? slots[slots.length - 1].id : 0;
                        let today = new Date();
                        let today_min = today.getHours() * 60 + today.getMinutes();
                        let status;
                        let bookedDate = new Date(booked.datetime);
                        let bookedEndDate = new Date(booked.end_datetime);
                        let bookedDate_start_min = bookedDate.getHours() * 60 + bookedDate.getMinutes();
                        let bookedDate_end_min = bookedEndDate.getHours() * 60 + bookedEndDate.getMinutes();

                        const tenant = _.find(
                            tenants,
                            member => member.email === booked.client_email
                        );
                        let slotDetail = tenant
                            ? `${tenant.firstName} ${" "} ${tenant.lastName}` : "";

                        if (bookedDate < today) {
                            status = true
                        } else if (moment(bookedDate).format("YYYY/MM/DD") === moment(today).format("YYYY/MM/DD")) {
                            status = true;
                        } else {
                            status = false;
                        }

                        let bookedAvailSlot = {
                            isAvailable: false,
                            start: new Date(bookedDate),
                            end: new Date(bookedEndDate),
                            price: booked.price,
                            id: booked.id,  // lastId > 0 ? lastId + 1 : i,
                            desc: slotDetail,
                            booked: true,
                            eventId: event.eventId,
                            eventName: event.eventName,
                            past: status,
                            start_min: bookedDate_start_min,
                            end_min: bookedDate_end_min,
                            card: card,
                            tenant: tenant,
                            title: event.eventName,
                            questions: booked.questions,
                            answers: booked.answers_summary,
                            service_name: booked.service_name,
                            duration: booked.duration,
                            type: service.duration_unit,
                            //allDay: true
                        };

                        slots.push(bookedAvailSlot);
                        return bookedAvailSlot;
                    });



                    const eventsSlotsData = {
                        slots: slots
                    };

                    return eventsSlotsData;

                }); //end eventsSlots map






                let slotsData = []


                let booked_taged = 0;
                const eventsSlot = _.map(slots, slot => {
                    if (!_.isUndefined(slot.booked) && slot.booked === true) {
                        booked_taged = booked_taged + 1;
                        let data = {
                            id: slot.id,
                            isAvailable: slot.isAvailable,
                            booked: slot.booked,
                            desc: slot.desc,
                            end: slot.end,
                            start: slot.start,
                            start_min: slot.start_min,
                            end_min: slot.end_min,
                            eventId: slot.eventId,
                            eventName: slot.eventName,
                            tenant: slot.tenant,
                            past: slot.past,
                            card: slot.card,
                            questions: slot.questions,
                            answers: slot.answers,
                            price: slot.price,
                            service_name: slot.service_name,
                            duration: slot.duration,
                            title: slot.eventName,
                            tag: booked_taged,
                            type: service.duration_unit,
                            //allDay: slot.allDay
                        };


                        slotsData.push(data);
                    } //end if
                    else {

                        //uncoment to remove duplicate from slots list
                        // let slot_dupl = _.find(slotsData, { start: slot.start, end: slot.end, start_min: slot.start_min, end_min: slot.end_min });

                        // if (_.isUndefined(slot_dupl))
                        //   slotsData.push(slot);

                        slotsData.push(slot);
                    }
                }); //end eventsSlot


                let bookingList = _.map(_.union(bookedBookings, blockedBookings), o => {
                    
                    return {
                        id: o.id,
                        email: o.client_email,
                        serviceId: o.service_id,
                        name: o.client_name,
                        resourceName: o.resource_name,
                        startDate: dateFormat(o.datetime, "dd/mm/yyyy"),
                        endDate: o.end_datetime,
                        duration: o.duration,
                        timeFrom: dateFormat(o.datetime, "shortTime"),
                        timeTo: dateFormat(o.end_datetime, "shortTime"),
                        questions: o.questions,
                        answers: o.answers_summary,
                        tenant: _.find(tenants, {
                            email: o.client_email
                        })
                    };
                });



                var slotData = {
                    events: events,
                    slots: _.sortBy(slotsData, ['start'])
                };



                dispatch({ type: resourcesTypes.EVENTS_SLOTS_FETCHED, slotsData: slotData });
                dispatch({ type: resourcesTypes.RESOURCE_BOOKINGS_FETCHED, bookings: bookingList });

                callback(true);

            }
        } catch (error) {
            callback(false);
            console.error(error);
        }

    }
}



export function fetchBookingQuestions(group_id, callback = () => { }) {
    return async (dispatch, getState) => {
        try {

            if (group_id) {
                const result = await engageBookingServices.getBookingQuestions(group_id);

                dispatch({ type: resourcesTypes.BOOKING_QUESTIONS_LOAD_SUCCESS, questions: result })

                callback(true);
            } else {
                dispatch({ type: resourcesTypes.BOOKING_QUESTIONS_LOAD_FAIL, status: false })

                callback(false);
            }

        } catch (error) {
            callback(false);
            console.error(error);
        }
    }

}

function getDatesRange(startDate, endDate) {
    var dateRange = [];
    var currentDate = new Date(moment(startDate).format("YYYY-MM-DD"));
    var end = new Date(moment(endDate).format("YYYY-MM-DD"));

    let current = currentDate;
    for (var d = current; d <= end; d.setDate(d.getDate() + 1)) {
        dateRange.push(new Date(d));
    }

    return dateRange;
}



