import * as config from "./config";
import authService from "./loginService";

class KeyServices {
  /**API endpoint to get all key records */
  async getKeys() {
    const url = `${config.KEYS_RECORD_ENDPOINT}`;
    const authToken = authService.getToken();

    var requestHeader = new Headers();
    requestHeader.append("Authorization", "Bearer " + authToken);

    const response = await fetch(url, {
      method: "GET",
      headers: requestHeader
    });

    if (!response.ok) {
      config.errorPageRedirect(response);
      throw new Error(`getKeys service failed, HTTP status ${response.status}`);
    }

    const result = await response.json();

    return result;
  }

  /**API endpoint to get all statuses */
  async getKeyStatuses() {
    const url = `${config.KEY_STATUSES_ENDPOINT}`;
    const authToken = authService.getToken();

    var requestHeader = new Headers();
    requestHeader.append("Authorization", "Bearer " + authToken);

    const response = await fetch(url, {
      method: "GET",
      headers: requestHeader
    });

    if (!response.ok) {
      //config.errorPageRedirect(response);
      throw new Error(
        `getKeyStatuses service failed, HTTP status ${response.status}`
      );
    }

    const result = await response.json();

    return result;
  }

  /**Assign / log key request */
  async logKey(data) {
    const url = `${config.KEYS_LOG_ENDPOINT}`;
    const authToken = authService.getToken();

    var requestHeader = new Headers();
    requestHeader.append("Authorization", "Bearer " + authToken);
    requestHeader.append("Accept", "application/json");
    requestHeader.append("Content-Type", "application/json");

    const response = await fetch(url, {
      method: "POST",
      headers: requestHeader,
      body: JSON.stringify(data)
    });

    if (!response.ok) {
      // config.errorPageRedirect(response);
      throw new Error(`log key service failed, HTTP status ${response.status}`);
    }

    const result = await response.json();

    return result;
  }

  async getKeylogKeyStatus(keyData) {
    const url = `${config.KEYS_LOG_BY_KEY_STATUS}`;
    const authToken = authService.getToken();

    var requestHeader = new Headers();
    requestHeader.append("Authorization", "Bearer " + authToken);
    requestHeader.append("Accept", "application/json");
    requestHeader.append("Content-Type", "application/json");

    const response = await fetch(url, {
      method: "POST",
      headers: requestHeader,
      body: JSON.stringify(keyData)
    });

    if (!response.ok) {
      //config.errorPageRedirect(response);
      throw new Error(
        `lgetKeylogKeyStatus failed, HTTP status ${response.status}`
      );
    }

    const result = await response.json();

    return result;
  }

  async getDueKeyLogByKeyId(id) {
    const url = `${config.KEY_LOG_BY_KEYID}`;
    const authToken = authService.getToken();

    var requestHeader = new Headers();
    requestHeader.append("Authorization", "Bearer " + authToken);

    const response = await fetch(url + "?keyid=" + id, {
      method: "GET",
      headers: requestHeader
    });

    if (!response.ok) {
      //config.errorPageRedirect(response);
      throw new Error(
        `getDueKeyLogByKeyId failed, HTTP status ${response.status}`
      );
    }

    const result = await response.json();

    return result;
  }

  async returnKey(data) {
    const url = `${config.RETURN_KEY_ENDPOINT}`;
    const authToken = authService.getToken();

    var requestHeader = new Headers();
    requestHeader.append("Authorization", "Bearer " + authToken);
    requestHeader.append("Accept", "application/json");
    requestHeader.append("Content-Type", "application/json");

    const response = await fetch(url, {
      method: "POST",
      headers: requestHeader,
      body: JSON.stringify(data)
    });

    if (!response.ok) {
      //config.errorPageRedirect(response);
      throw new Error(`greturnKey failed, HTTP status ${response.status}`);
    }

    const result = await response.json();

    return result;
  }

  async getKyLogsbyKeyIds(data) {
    const url = `${config.KEY_LOG_BY_KEYID}`;
    const authToken = authService.getToken();

    var requestHeader = new Headers();
    requestHeader.append("Authorization", "Bearer " + authToken);
    requestHeader.append("Accept", "application/json");
    requestHeader.append("Content-Type", "application/json");

    const response = await fetch(url, {
      method: "POST",
      headers: requestHeader,
      body: JSON.stringify(data),
     
    });

    if (!response.ok) {
      //config.errorPageRedirect(response);
      throw new Error(
        `ggetKyLogsbyKeyIds failed, HTTP status ${response.status}`
      );
    }

    const result = await response.json();

    return result;
  }

  /**Get key logs list of specified key id */
  async getKeyLogList(lastrecord, keyid) {
    const url = `${config.KEY_LOG_LIST_ENDPOINT}`;
    const authToken = authService.getToken();

    var requestHeader = new Headers();
    requestHeader.append("Authorization", "Bearer " + authToken);

    const response = await fetch(
      url + `${"?lastrecordid="}${lastrecord}${"&keyid="}${keyid}`,
      {
        method: "GET",
        headers: requestHeader
      }
    );

    if (!response.ok) {
      //config.errorPageRedirect(response);
      throw new Error(`getKeyLogList failed, HTTP status ${response.status}`);
    }

    const result = await response.json();

    return result;
  }

  async updateKey(data) {
    const url = `${config.KEY_STATUS_UPDATE}`;
    const authToken = authService.getToken();

    var requestHeader = new Headers();
    requestHeader.append("Authorization", "Bearer " + authToken);
    requestHeader.append("Accept", "application/json");
    requestHeader.append("Content-Type", "application/json");

    const response = await fetch(url, {
      method: "POST",
      headers: requestHeader,
      body: JSON.stringify(data)
    });

    if (!response.ok) {
      //config.errorPageRedirect(response);
      throw new Error(`updateKey failed, HTTP status ${response.status}`);
    }

    const result = await response.json();

    return result;
  }

  async notifyKeyassigned(data) {
    const url = `${config.NOTIFY_KEY_ASSIGNED}`;
    const authToken = authService.getToken();

    var requestHeader = new Headers();
    requestHeader.append("Authorization", "Bearer " + authToken);
    requestHeader.append("Accept", "application/json");
    requestHeader.append("Content-Type", "application/json");

    const response = await fetch(url, {
      method: "POST",
      headers: requestHeader,
      body: JSON.stringify(data)
    });

    if (!response.ok) {
      //config.errorPageRedirect(response);
      throw new Error(`notifyKeyassigned failed, HTTP status ${response.status}`);
    }

    const result = await response.json();

    return result;
  }

  //send notification on key returned
  async notifyKeyReturned(data) {
    const url = `${config.NOTIFY_KEY_RETURNED}`;
    const authToken = authService.getToken();

    var requestHeader = new Headers();
    requestHeader.append("Authorization", "Bearer " + authToken);
    requestHeader.append("Accept", "application/json");
    requestHeader.append("Content-Type", "application/json");

    const response = await fetch(url, {
      method: "POST",
      headers: requestHeader,
      body: JSON.stringify(data)
    });

    if (!response.ok) {
      //config.errorPageRedirect(response);
      throw new Error(`notifyKeyreturned failed, HTTP status ${response.status}`);
    }

    const result = await response.json();

    return result;
  }
}

export default new KeyServices();
