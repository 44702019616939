//Crypto Utility
import * as config from "../services/config";
import Crypto from "crypto-js";

export function encryptAES(data) {
  const key = Crypto.enc.Utf8.parse(config.EngageCryptoKey);
  const iv = Crypto.enc.Utf8.parse(config.EngageCryptoKeyIV);

  var encrypted = Crypto.AES.encrypt(
    Crypto.enc.Utf8.parse(JSON.stringify(data)),
    key,
    {
      keySize: 128 / 8,
      iv: iv,
      mode: Crypto.mode.CBC,
      padding: Crypto.pad.Pkcs7
    }
  );
  return encrypted.toString();
}


export function decryptAES(cypherText) {
  const key = Crypto.enc.Utf8.parse(config.EngageCryptoKey);
  const iv = Crypto.enc.Utf8.parse(config.EngageCryptoKeyIV);

  var decrypted = Crypto.AES.decrypt(cypherText, key, {
    keySize: 128 / 8,
    iv: iv,
    mode: Crypto.mode.CBC,
    padding: Crypto.pad.Pkcs7
  });
  let decryptedData = "";
  try {
    decryptedData = JSON.parse(decrypted.toString(Crypto.enc.Utf8));

  } catch (e) {
    decryptedData = ""
  }




  return decryptedData;

}
