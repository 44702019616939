import * as config from './config';
import authService from './loginService';

class ConciergSettingService {
	async getGlobalSettings() {
		const url = `${config.GLOBAL_SEETINGS_ENDPOINT}`;
		//const authToken = authService.getToken();

		var requestHeader = new Headers();
		// requestHeader.append("Authorization", "Bearer " + authToken);

		const response = await fetch(url, {
			method: 'GET',
			headers: requestHeader
		});

		if (!response.ok) {
			config.errorPageRedirect(response);
			throw new Error(`getGlobalSettings service failed, HTTP status ${response.status}`);
		}

		const result = await response.json();

		return result;
	}

	async getpaymentGatewaySettings() {
		const url = config.PAYMENT_GATEWAY_SETTINGS_ENDPOINT;
		const authToken = authService.getToken();

		var requestHeader = new Headers();
		requestHeader.append('Authorization', 'Bearer ' + authToken);

		const response = await fetch(url, {
			method: 'GET',
			headers: requestHeader
		});

		if (!response.ok) {
			//config.errorPageRedirect(response);

			throw new Error(
				`PaymentGatewaySettings service failed, ${response.statusText}, HTTP status ${response.status}`
			);
		}

		const result = await response.json();

		return result;
	}

	async getpaymentSignature(amount, currency, cartid) {
		const url = config.PAYMENT_SIGNATURE_ENDPOINT;
		const authToken = authService.getToken();

		var requestHeader = new Headers();
		requestHeader.append('Authorization', 'Bearer ' + authToken);

		const response = await fetch(url + '?amount=' + amount + '&currency=' + currency + '&cartid=' + cartid, {
			method: 'GET',
			headers: requestHeader
		});

		if (!response.ok) {
			//config.errorPageRedirect(response);

			throw new Error(`PaymentSignature service failed, ${response.statusText}, HTTP status ${response.status}`);
		}

		const result = await response.json();

		return result;
	}

	async getPaymentSignatureCrypt(gatewayType, data) {
		const url = `${config.PAYMENT_CRYPT_ENDPOINT}${'?paymentGatewayType='}${gatewayType}`;
		const authToken = authService.getToken();

		var requestHeader = new Headers();
		requestHeader.append('Authorization', 'Bearer ' + authToken);
		requestHeader.append('Accept', 'application/json');
		requestHeader.append('Content-Type', 'application/json');

		const response = await fetch(url, {
			method: 'POST',
			headers: requestHeader,
			body: JSON.stringify(data)
		});

		if (!response.ok) {
			//config.errorPageRedirect(response);

			throw new Error(
				`getPaymentSignatureCrypt service failed, ${response.statusText}, HTTP status ${response.status}`
			);
		}

		const result = await response.json();

		return result;
	}
}

export default new ConciergSettingService();
