import React, { Component } from 'react';
import { createBrowserHistory } from 'history';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import autoBind from 'react-autobind';
import Button from '../../../components/Button/Button';
import * as configHelper from '../../../helpers/configHelper';
import ParcelAutosuggest from '../../../components/ParcelAutoSuggest/ParcelAutoSuggest';
import ParcelResultComponent from '../../../components/Parcel/ParcelResultComponent';
import CustomMessages from '../../../components/CustomMessage/CustomMessages';
import { withRouter, Redirect} from 'react-router-dom';
import * as stringHelper from '../../../helpers/stringHelper';
import * as portalSettingsActions from '../../../store/ConciergeSetting/actions';
import * as portalSettingsSelectors from '../../../store/ConciergeSetting/reducer';
import * as parcelActions from '../../../store/Parcels/actions';
import * as parcelSelectors from '../../../store/Parcels/reducer';

import * as moduleSelectors from '../../../store/ServiceModules/reducer';
import ParcelsExpected from '../../../components/Parcel/ParcelsExpected';

import dateFormat from 'dateformat';

/**
 * Parcel module screen, this is the module start screen for the parcel journey loaded on the landing module home screen
 */
import history from '../../../history';
//const history = createBrowserHistory();
const location = history.location;

const PortalTypes = configHelper.PortalTypes;
const ParcelStatus = configHelper.ParcelStatuses;
class ParcelScreen extends Component {
	constructor(props) {
		super(props);

		this.state = {
			parcels: [],
			selectedParcelId: '',
			portalType:
				this.props.portalSettings && this.props.portalSettings.portalType
					? this.props.portalSettings.portalType
					: '',
			relatedParcels: [],
			//selectedParcel : undefined,
			parcel : undefined
		};

		autoBind(this);
	}

	componentWillMount() {
		this.props.dispatch(portalSettingsActions.getGlobalSetting());
		this.props.dispatch(parcelActions.getParcelsByStatus(configHelper.ParcelStatuses.CheckedIn));
		this.props.dispatch(parcelActions.getParcelsDue());
		this.props.dispatch(parcelActions.getParcelsExpected());
		this.props.dispatch(parcelActions.getParcelSettigs());
	}

	componentWillRec(nextProps, nextState) {
		// if (this.props.setting !== nextProps.setting && !_.isUndefined(nextProps.setting)) {
		// 	this.setState({
		// 		portalType: nextProps.setting.portalType
		// 	});
		// }

		// if (this.props.parcels !== nextProps.parcels) {
		// 	//PARCEL status changed on action, set new data on action completed
		// 	if (!_.isUndefined(this.state.selectedParcel) && nextProps.parcels.length > 0) {
		// 		var selectedParcelUpdated = _.find(nextProps.parcels, { id: this.state.selectedParcel.id });

		// 		this.setState({
		// 			selectedParcel: selectedParcelUpdated
		// 		});
		// 	}

		// 	//parcel list has changed due to action on parcel result cards, update with new data
		// 	if (
		// 		!_.isUndefined(this.state.parcelOptions) &&
		// 		this.state.parcelOptions.length > 0 &&
		// 		nextProps.parcels.length > 0
		// 	) {
		// 		if (!_.isUndefined(this.state.selectedParcel) && nextProps.parcels.length > 0) {
		// 			var selectedParcelUpdated_ = _.find(nextProps.parcels, { id: this.state.selectedParcel.id });
		// 			var parcelOptionsUpdated = _.filter(
		// 				_.filter(nextProps.parcels, (parcel) => {
		// 					let parcelOption = _.find(this.state.parcelOptions, { id: parcel.id });
		// 					if (!_.isUndefined(parcelOption)) {
		// 						return parcelOption;
		// 					}
		// 					return undefined;
		// 				}),
		// 				(item) => !_.isUndefined(item)
		// 			);

		// 			this.setState({
		// 				selectedParcel: selectedParcelUpdated_,
		// 				parcelOptions: parcelOptionsUpdated
		// 			});
		// 		}
		// 	}

		// 	this.setState({
		// 		parcels: nextProps.parcels
		// 	});
		// }

		// if (this.state.parcelOptions !== nextState.parcelOptions) {
		// 	this.setState({
		// 		parcelOptions: nextState.parcelOptions
		// 	});
		// }
	}

	componentWillUpdate(nextProps, nextState) {
		

		// if (this.props.parcels !== nextProps.parcels) {
		// 	//PARCEL status changed on action, set new data on action completed
		// 	if (!_.isUndefined(this.state.selectedParcel) && nextProps.parcels.length > 0) {
		// 		var selectedParcelUpdated = _.find(nextProps.parcels, { id: this.state.selectedParcel.id });

		// 		this.setState({
		// 			selectedParcel: selectedParcelUpdated
		// 		});
		// 	}

		// 	//parcel list has changed due to action on parcel result cards, update with new data
		// 	if (
		// 		!_.isUndefined(this.state.parcelOptions) &&
		// 		this.state.parcelOptions.length > 0 &&
		// 		nextProps.parcels.length > 0
		// 	) {
		// 		if (!_.isUndefined(this.state.selectedParcel) && nextProps.parcels.length > 0) {
		// 			var selectedParcelUpdated_ = _.find(nextProps.parcels, { id: this.state.selectedParcel.id });
		// 			var parcelOptionsUpdated = _.filter(
		// 				_.filter(nextProps.parcels, (parcel) => {
		// 					let parcelOption = _.find(this.state.parcelOptions, { id: parcel.id });
		// 					if (!_.isUndefined(parcelOption)) {
		// 						return parcelOption;
		// 					}
		// 					return undefined;
		// 				}),
		// 				(item) => !_.isUndefined(item)
		// 			);

		// 			this.setState({
		// 				selectedParcel: selectedParcelUpdated_,
		// 				parcelOptions: parcelOptionsUpdated
		// 			});
		// 		}
		// 	}

		// 	this.setState({
		// 		parcels: nextProps.parcels
		// 	});
		// }

	}

	componentDidUpdate(prevProps, prevState){
		if (this.props.portalSettings !== prevProps.portalSettings && !_.isUndefined(this.props.portalSettings)) {
			if (!_.isUndefined(this.props.portalSettings.portalType)) {
				this.setState({
					portalType: this.props.portalSettings.portalType
				});
			}
		}

		if (this.props.parcels !== prevProps.parcels) {
			//PARCEL status changed on action, set new data on action completed
			if (!_.isUndefined(this.state.selectedParcel) && this.props.parcels.length > 0) {
				var selectedParcelUpdated = _.find(this.props.parcels, { id: this.state.selectedParcel.id });
				console.log("selected P", this.state.selectedParcel)
				this.setState({
					selectedParcel: selectedParcelUpdated
				});
			}

			//parcel list has changed due to action on parcel result cards, update with new data
			if (
				!_.isUndefined(this.state.parcelOptions) &&
				this.state.parcelOptions.length > 0 &&
				this.props.parcels.length > 0
			) {
				if (!_.isUndefined(this.state.selectedParcel) && this.props.parcels.length > 0) {
					var selectedParcelUpdated_ = _.find(this.props.parcels, { id: this.state.selectedParcel.id });
					var parcelOptionsUpdated = _.filter(
						_.filter(this.props.parcels, (parcel) => {
							let parcelOption = _.find(this.state.parcelOptions, { id: parcel.id });
							if (!_.isUndefined(parcelOption)) {
								return parcelOption;
							}
							return undefined;
						}),
						(item) => !_.isUndefined(item)
					);

					this.setState({
						selectedParcel: selectedParcelUpdated_,
						parcelOptions: parcelOptionsUpdated
					});
				}
			}

			this.setState({
				parcels: this.props.parcels
			});
		}




	}

	componentWillUnmount() {
		//this.props.dispatch(parcelActions.clearParcel());
	}
	//reset parcel selection
	resetParcelSelection() {
		this.setState({
			selectedParcelId: '',
			relatedParcels: [],
			selectedParcel: undefined,
			parcelOptions: []
		});
	}
	//parcel autosuggest on selected
	handleParcelSelected(data) {
		let parcels = _.concat(this.state.parcels, this.props.parcelsDue);

		let parcelsRelated = _.filter(parcels, (item) => {
			if (this.props.prtalSettings && this.props.portalSettings.portalType === PortalTypes.Residential) {
				return item.resident && item.resident.id === data.parcel.resident.id;
			} else {
				return item.tenant && item.tenant.id === data.parcel.tenant.id;
			}
		});
		let parcelsRelated_ = _.filter(parcelsRelated, (o) => o.id !== data.parcelId);

		this.setState({
			selectedParcel: data.parcel,
			selectedParcelId: data.parcelId,
			parcelOptions: undefined,
			relatedParcels: parcelsRelated_,
			parcel : data.parcel
		});

		
		//setTimeout(() => document.activeElement.blur());
	}

	formReset() {
		this.setState({
			name: '',
			address: '',
			selectedParcel: undefined
		});
	}

	getParcelCount() {
		const { parcelsExpected } = this.props;
		// let parcelCount = 0;
		// let currentDate = new Date();

		let parcelsObj = _.filter(
			_.map(parcelsExpected, (o) => {
				if (
					o.status.status.toLowerCase() === configHelper.ParcelStatuses.Expected.toLowerCase() &&
					moment(o.expectedDate).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')
				) {
					return o;
				}
				return undefined;
			}),
			(item) => !_.isUndefined(item)
		);

		// !_.isUndefined(parcelsExpected) && parcelsExpected && parcelsExpected.length > 0 &&
		//   _.map(parcelsExpected.slice(0, this.state.limit),

		//     (parcel, index) => {

		//       if ((parcel.status.status && parcel.status.status.toLowerCase() === configHelper.ParcelStatuses.Expected.toLowerCase()) &&
		//         (dateFormat(parcel.expecteDate, "dd/mm/yyyy") === dateFormat(moment().format(), "dd/mm/yyyy"))) {
		//         //  moment(parcel.expectedDate).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")) {
		//         parcelCount++;
		//       }

		//     });

		return `${parcelsObj.length + ' parcels'}`;
	}
	
	handleSumbit(val) {
		let selectedItem =
			!_.isUndefined(this.state.selectedParcel) && this.state.selectedParcel.id === val
				? undefined
				: this.state.selectedParcel;

				
		this.props.dispatch(
			parcelActions.addParcelsToCheckout([ { Id: val } ], (success) => {
				if (success === true) {
					const filterData = _.filter(this.state.parcelOptions, (FilterValue) => FilterValue.id !== val);

					let updateData = {
						id: this.state.parcel.id,
						barcode: this.state.parcel.barcode,
						memberId: this.state.parcel.recipient.memberId,
						locationId: this.state.parcel.locationId,
						status: {status : ParcelStatus.Collected }
					};

					
					this.setState({
						parcelOptions: filterData,
						relatedParcels: _.filter(this.state.relatedParcels, (rel) => rel.id !== val),
						selectedParcel: selectedItem
					});
					
					
					this.props.dispatch(
						parcelActions.notifyParcels([ updateData ], (complete) => {
							if (complete === true) {
							}
						})
					);
					//this.props.dispatch(parcelActions.getParcelsByStatus(configHelper.ParcelStatuses.CheckedIn));
				}
			})
		);
	}

	render() {
		const { modules, parcels, portalSettings, parcelSettings, parcelsDue } = this.props;
		const { relatedParcels } = this.state;
		const collectByDays = !_.isUndefined(parcelSettings)
			? parcelSettings.collectByDays > 1
				? parcelSettings.collectByDays + ' days'
				: parcelSettings.collectByDays + ' day'
			: '';
		const currentModule = _.find(modules, (o) => o.title === 'Parcels');

		const overlayColor = currentModule && currentModule.imageOverlayTextColour;

		const searchPlaceHolder =
			portalSettings && portalSettings.portalType === configHelper.PortalTypes.Residential
				? 'Search by barcode, name or address'
				: 'Search by barcode, name or company';

		let currentParcels = [];
		let relatedParcelsList = [];

		if (relatedParcels && relatedParcels.length > 0) {
			relatedParcelsList = _.orderBy(relatedParcels, [ 'dateArrived' ], [ 'desc' ]);
		}

		var _portalType = '';
		if (portalSettings && portalSettings.portalType) {
			_portalType = portalSettings.portalType;
		}

		if (_portalType) {
			let parcelsObj = _.concat(parcels, parcelsDue);

			currentParcels = _.orderBy(parcelsObj, [ 'dateArrived' ], [ 'desc' ]);

			if (_portalType === PortalTypes.Commercial) {
				currentParcels = _.orderBy(currentParcels, [ 'tenant.firstName', 'tenant.companyName' ], [ 'asc' ]);
			} else if (_portalType === PortalTypes.Residential) {
				currentParcels = _.orderBy(
					currentParcels,
					[ 'resident.firstName', 'resident.addressLine1' ],
					[ 'asc' ]
				);
			}
		}
		
		return (
			<div className="container">
				<ParcelAutosuggest
					onSelected={this.handleParcelSelected}
					clearableIfValid={false}
					value={''}
					label="Search"
					placeHolder={searchPlaceHolder}
					parcels={currentParcels}
					type={portalSettings && portalSettings.portalType}
					fieldManualCleared={(o) => {
						if (o === true) {
							this.resetParcelSelection();
						}
					}}
					matchfound={(val) => {
						this.setState({
							parcelFound: val
						});
					}}
					handleKeyPress={(e, data) => {
						if (e.key === 'Enter') {
							// if (!_.isUndefined(this.state.selectedParcel)) {
							//   this.setState({
							//     parcelOptions: [this.state.selectedParcel],
							//     selectedParcel: undefined,
							//     relatedParcels: []
							//   });
							// } else {

							this.setState({
								parcelOptions: data,
								relatedParcels: []
							});
							//  }

							setTimeout(() => document.activeElement.blur());
						}
						if (e.key === 'Clear') {
							this.setState({
								parcelOptions: data,
								parcelFound: undefined,
								relatedParcels: []
							});
						}
					}}
				/>

				{/* <div className="message-result-found">{(this.state.parcelFound === true && !_.isUndefined(this.state.parcelOptions) && this.state.parcelOptions.length > 0) ? <span className="parcels-found-message">We found {this.state.parcelOptions.length} results for your search </span> : ""}</div> */}

				{
					<div className="message-result-found">
						{this.state.parcelFound === true &&
						!_.isUndefined(this.state.parcelOptions) &&
						this.state.parcelOptions.length > 0 ? (
							<span className="parcels-found-message">
								We found {this.state.parcelOptions.length} results for your search{' '}
							</span>
						) : !_.isUndefined(this.state.selectedParcel) ? (
							<span className="parcels-found-message">We found 1 results for your search </span>
						) : (
							''
						)}
					</div>
				}

				{!_.isUndefined(this.state.selectedParcel) &&
				(this.state.parcelFound === true || _.isUndefined(this.state.parcelFound)) &&
				(_.isUndefined(this.state.parcelOptions) ||
					this.state.parcelOptions.length === 0) ? this.state.selectedParcel.status.status.toLowerCase() ===
					configHelper.ParcelStatuses.CheckedIn.toLowerCase() ||
				this.state.selectedParcel.status.status.toLowerCase() ===
					configHelper.ParcelStatuses.Due.toLowerCase() ? (
					<ParcelResultComponent
						id={this.state.selectedParcel.id}
						tenantName={
							this.state.portalType === PortalTypes.Commercial ? (
								this.state.selectedParcel.tenant.firstName +
								' ' +
								this.state.selectedParcel.tenant.lastName
							) : (
								this.state.selectedParcel.resident.firstName +
								' ' +
								this.state.selectedParcel.resident.lastName
							)
						}
						tenantAddress={
							this.state.portalType === PortalTypes.Commercial ? (
								this.state.selectedParcel.tenant.companyName
							) : (
								stringHelper.formatStringList(
									[
										this.state.selectedParcel.resident.addressLine1,
										this.state.selectedParcel.resident.addressLine2,
										this.state.selectedParcel.resident.addressLine3,
										this.state.selectedParcel.resident.addressLine4,
										this.state.selectedParcel.resident.addressLine5,
										this.state.selectedParcel.resident.addressLine6,
										this.state.selectedParcel.resident.postCode
									],
									0
								)
							)
						}
						barcodeId={
							this.state.selectedParcel.barcode !== null &&
							this.state.selectedParcel.barcode != '' &&
							!_.isUndefined(this.state.selectedParcel.barcode) ? (
								this.state.selectedParcel.barcode
							) : (
								'Not Available'
							)
						}
						parcelReceivedDate={
							'Received on ' + dateFormat(this.state.selectedParcel.dateArrived, 'dd/mm/yyyy')
						} //{"Received on " + moment(this.state.selectedParcel.dateArrived).format("L")}
						location={
							this.state.selectedParcel.location !== null ? (
								this.state.selectedParcel.location.locationName
							) : (
								'Not Available'
							)
						}
						btnText="Check-out"
						moduleColor={overlayColor}
						portalType={this.state.portalType}
						handleSubmit={(val) => this.handleSumbit(val)}
						handleItemClick={(val) => this.handleParcelItemSelected(val)}
						showoverlay={true}
					/>
				) : (
					''
				) : (
					(_.isUndefined(this.state.selectedParcel) || !_.isUndefined(this.state.selectedParcel)) &&
					(this.state.parcelFound === true || _.isUndefined(this.state.parcelFound)) &&
					!_.isUndefined(this.state.parcelOptions) &&
					this.state.parcelOptions.length > 0 &&
					_.map(this.state.parcelOptions, (parcel, index) => {
						return parcel.status.status.toLowerCase() ===
							configHelper.ParcelStatuses.CheckedIn.toLowerCase() ||
						parcel.status.status.toLowerCase() === configHelper.ParcelStatuses.Due.toLowerCase() ? (
							<ParcelResultComponent
								id={parcel.id}
								key={index}
								tenantName={
									this.state.portalType === PortalTypes.Commercial ? (
										parcel.tenant.firstName + ' ' + parcel.tenant.lastName
									) : (
										parcel.resident.firstName + ' ' + parcel.resident.lastName
									)
								}
								tenantAddress={
									this.state.portalType === PortalTypes.Commercial ? (
										parcel.tenant.companyName
									) : (
										`${stringHelper.formatStringList(
											[
												parcel.resident.addressLine1,
												parcel.resident.addressLine2,
												parcel.resident.addressLine3,
												parcel.resident.addressLine4,
												parcel.resident.addressLine5,
												parcel.resident.addressLine6,
												parcel.resident.postCode
											],
											0
										)}`
									)
								}
								barcodeId={
									parcel.barcode !== null &&
									parcel.barcode != '' &&
									!_.isUndefined(parcel.barcode) ? (
										parcel.barcode
									) : (
										'Not Available'
									)
								}
								parcelReceivedDate={'Received on ' + dateFormat(parcel.dateArrived, 'dd/mm/yyyy')} // moment(parcel.dateArrived).format("L")
								location={parcel.location != null ? parcel.location.locationName : 'Not Available'}
								btnText="Check-out"
								moduleColor={overlayColor}
								portalType={this.state.portalType}
								handleSubmit={(val) => this.handleSumbit(val)}
								showoverlay={true}
							/>
						) : (
							''
						);
					})
				)}

				{relatedParcelsList &&
				relatedParcelsList.length > 0 && (
					<div>
						<CustomMessages
							text={`${'This person has'} ${relatedParcelsList.length} ${'more'} ${relatedParcelsList.length >
							1
								? 'parcels'
								: 'parcel'} ${'currently being stored'}`}
						/>
						{_.map(relatedParcelsList, (parcel, indx) => {
							return parcel.status.status.toLowerCase() ===
								configHelper.ParcelStatuses.CheckedIn.toLowerCase() ||
							parcel.status.status.toLowerCase() === configHelper.ParcelStatuses.Due.toLowerCase() ? (
								<ParcelResultComponent
									key={indx}
									id={parcel.id}
									tenantName={
										this.state.portalType === PortalTypes.Commercial ? (
											parcel.tenant.firstName + ' ' + parcel.tenant.lastName
										) : (
											parcel.resident.firstName + ' ' + parcel.resident.lastName
										)
									} //{parcel.resident.firstName + " " + parcel.resident.lastName}
									tenantAddress={
										this.state.portalType === PortalTypes.Commercial ? (
											parcel.tenant.companyName
										) : (
											`${stringHelper.formatStringList(
												[
													parcel.resident.addressLine1,
													parcel.resident.addressLine2,
													parcel.resident.addressLine3,
													parcel.resident.addressLine4,
													parcel.resident.addressLine5,
													parcel.resident.addressLine6,
													parcel.resident.postCode
												],
												0
											)}`
										)
									}
									barcodeId={
										parcel.barcode !== null &&
										parcel.barcode != '' &&
										!_.isUndefined(parcel.barcode) ? (
											parcel.barcode
										) : (
											'Not Available'
										)
									}
									parcelReceivedDate={'Received on ' + dateFormat(parcel.dateArrived, 'dd/mm/yyyy')} //{"Received on " + moment(new Date(parcel.dateArrived)).format("L")}
									location={parcel.location != null ? parcel.location.locationName : 'Not Available'}
									btnText="Check-out"
									moduleColor={overlayColor}
									portalType={this.state.portalType}
									handleSubmit={(val) => this.handleSumbit(val)}
									showoverlay={true}
								/>
							) : (
								''
							);
						})}
					</div>
				)}

				<div className="message-div">
					{this.state.parcelFound === false ? (
						<span className="parcels-found-message">
							We could not find any parcels related to your search. You may want to:{' '}
						</span>
					) : (
						<span className="parcels-found-message">
							You can also register new parcels or manage existing ones by clicking below
						</span>
					)}
				</div>

				<div className="btnGroup">
					<Button
						size="small"
						text="Manage Parcels"
						handleClick={() => {
							
							this.props.history.push('/concierge/parcel/management');
							window.scrollTo(0, 0);
							//window.location.href = window.location.href;
							//history.pushLater('/concierge/parcel/management');	
						}}
						icon=""
					/>
					<Button
						size="small"
						text="Register Parcels"
						handleClick={() => {
							this.props.history.push('/concierge/parcel/register');
							window.scrollTo(0, 0);
						}}
						icon=""
					/>
				</div>

				<h1 className="parcel-header-task" style={{ color: overlayColor }}>
					Tasks
				</h1>

				<div className="task-section">
					<ParcelsExpected
						taskTitle="Parcels Expected"
						numberOfParcels={this.getParcelCount()} //{parcels.length + " parcels"}
						parcelMessage="are expected to arrive"
						timeFrame="Today"
						linkMessage="Find out more"
						handleClick={() => {
							this.props.history.push('/concierge/parcel/expected');
							window.scrollTo(0, 0);
						}}
						moduleColor={overlayColor}
					/>

					<ParcelsExpected
						taskTitle="Due to be returned"
						numberOfParcels={this.props.parcelsDue.length + ' parcels'}
						parcelMessage="have been stored for longer than"
						timeFrame={collectByDays}
						linkMessage="See all returns"
						handleClick={() => {
							this.props.history.push({
								pathname: '/concierge/parcel/management',
								state: {
									selectedFilterOption: 4
								}
							});
							window.scrollTo(0, 0);
						}}
						moduleColor={overlayColor}
					/>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		modules: moduleSelectors.loadModules(state),
		parcels: parcelSelectors.getParcels(state),
		portalSettings: portalSettingsSelectors.loadGlobalSetting(state),
		parcelSettings: parcelSelectors.getParcelSettings(state),
		parcelsDue: parcelSelectors.getParcelDue(state),
		parcelsExpected: parcelSelectors.getParcelExpected(state)
	};
}
export default withRouter(connect(mapStateToProps)(ParcelScreen));
