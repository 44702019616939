import * as types from "./actionTypes";
import AddressLookUpServices from "../../services/AddressLookUpServices";

export function loadAddresses() {
  return async (dispatch, getState) => {
    try {
      const result = await AddressLookUpServices.getAddresses();

      dispatch({
        type: types.ADDRESSES_LOAD_SUCCESS,
        addresses: result
      });
    } catch (error) {
      console.error(error);
    }
  };
}
