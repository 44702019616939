import * as types from "./actionTypes";
import ResidentServices from "../../services/ResidentServices";
import _ from "lodash";
import * as addressLookupSelector from "../AddressLookUp/reducer";
import * as residentSelector from "../Residents/reducer";

export function getAllResidents() {
  return async function(dispatch, getState) {
    try {
      const residents = await ResidentServices.GetResidents();
      dispatch({ type: types.RESIDENTS_LOADED_SUCCESS, residents });
    } catch (error) {
      console.error(error);
    }
  };
}

export function searchResidents(data, callback = () => {}) {
  return async function(dispatch, getState) {
    try {
      const result = await ResidentServices.SearchResidentsBy(data);
      dispatch({
        type: types.SEARCH_RESIDENTS_LOAD_SUCCESS,
        residentsResponse: result
      });

      callback(true);
    } catch (error) {
      callback(false);
      console.error(error);
    }
  };
}


export function GetConciergeGroups() {
	return async (dispatch, getState) => {
		try {
			const groups = await ResidentServices.GetConciergeGroups();

			dispatch({type: types.RESIDENTS_GROUPS_LOADED_SUCCESS, groups});
		} catch (error) {
			console.error(error);
		}
	};
}




export function clearSearchQueue() {
  return async function(dispatch, getState) {
    try {
      let data = [];
      dispatch({
        type: types.SEARCH_RESIDENTS_LOAD_SUCCESS,
        residentsResponse: data
      });
    } catch (error) {
      console.error(error);
    }
  };
}

export function createNewResident(data, callback = () => {}) {
  return async function(dispatch, getState) {
    try {
      const residents = residentSelector.getResidents(getState());
      const addressList = addressLookupSelector.getAddresses(getState());
      let currentAddress = _.find(
        addressList,
        addr => addr.id === data.addressId
      );

      let residentExist = _.find(residents, res => res.email === data.email);

      if (_.isUndefined(residentExist)) {
        const newResi = {
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          mobileNumber: data.phone,
          addressLine1: currentAddress.addr1FlatNumber,
          addressLine2: currentAddress.addr2BuildingName,
          postCode: currentAddress.postCode,
          addressLookupId: currentAddress.id,
          conciergeGroup : data.groupName
        };

        const resident = await ResidentServices.CreateNewResident(newResi);
        if (resident)
          dispatch({ type: types.CREATE_RESIDENT_SUCCESS, resident });
        callback(true);
      } else {
        callback(false);
        dispatch({ type: types.CREATE_RESIDENT_FAIL, response: false, msg : "email already in use" });
      }
    } catch (error) {
      callback(false);
      dispatch({ type: types.CREATE_RESIDENT_FAIL, response: false });
      //console.error(error);
    }
  };
}

export function updateResident(data, callback = () => {}) {
  return async function(dispatch, getState) {
    try {
      const resident = await ResidentServices.UpdateResident(data);
      if (resident) {
        dispatch({ type: types.UPDATE_RESIDENT_SUCCESS, resident });
      } else {
      }
      callback(true);
    } catch (error) {
      callback(false);
      dispatch({
        type: types.UPDATE_RESIDENT_FAIL,
        response: { status: false, error: "Unable to update member" }
      });
      //console.error(error);
    }
  };
}

export function updateApprovalResident(data, callback = () => {}) {
  return async function(dispatch, getState) {
    try {
      const resident = await ResidentServices.UpdateApprovalResident(data);
      if (resident) {
        dispatch({ type: types.UPDATE_RESIDENT_APPROVAL_SUCCESS, resident });
        callback(true);
      } else {
        callback(false);
        dispatch({
          type: types.UPDATE_RESIDENT_APPROVAL_FAIL,
          response: {
            status: false,
            error: "Unable to update member approval status"
          }
        });
      }
      callback(true);
    } catch (error) {
      callback(false);
      dispatch({
        type: types.UPDATE_RESIDENT_FAIL,
        response: { status: false, error: "Unable to update member" }
      });
      //console.error(error);
    }
  };
}
export function sendRegistrationActivation(data, callback = () => {}) {
  return async function(dispatch, getState) {
    try {
      const response = await ResidentServices.sendRegisterationActivation(data);
      if (response === true) {
        let result = { status: true, message: "activation sent" };
        dispatch({ type: types.SEND_REGISTRATION_ACTIVATION_SUCCESS, result });
        callback(true);
      }
    } catch (error) {
      callback(false);
    }
  };
}
