import React, { Component } from "react";
import Button from "./Button";

import _ from "lodash";
var PropTypes = require('prop-types');
/**
 *  The ButtonGroup Component used accross the pages, displayed left and right and allows icons or text for labeling
 */
class ButtonGroup extends Component {
  render() {
    const {
      leftClick,
      rightClick,
      icon_l,
      icon_r,
      leftText,
      rightText,
    } = this.props;

    const leftDisabled =
      _.isUndefined(this.props.leftDisabled) ||
        this.props.leftDisabled === false
        ? { isDisabled: false }
        : { isDisabled: true };
    const rightDisabled =
      _.isUndefined(this.props.rightDisabled) ||
        this.props.rightDisabled === false
        ? { isDisabled: false }
        : { isDisabled: true };

    const iconLeft =
      _.isUndefined(icon_l) && (_.isUndefined(leftText) || leftText === "")
        ? "arrow-left-c"
        : icon_l;
    const iconRight =
      _.isUndefined(icon_r) && (_.isUndefined(rightText) || rightText === "")
        ? "arrow-right-c"
        : icon_r;

    const sizes = _.isUndefined(this.props.sizes)
      ? ["small", "medium"]
      : this.props.sizes;

    return (
      <div className="btnGroup">
        <Button
          size={sizes[0]}
          text={this.props.leftText ? this.props.leftText : " "}
          handleClick={leftClick}
          icon={iconLeft}
          otherClass={this.props.otherClass}
          {...leftDisabled}
          
        />
        <Button
          size={sizes[1]}
          text={this.props.rightText ? this.props.rightText : " "}
          handleClick={rightClick}
          icon={iconRight}
          {...rightDisabled}
          style={this.props.rightStyle}
        />
      </div>
    );
  }
}

ButtonGroup.propTypes = {
  /** The sizes of both buttons, ["medium" ,"large"] or in any combination order*/
  sizes: PropTypes.array,
  /** Text displayed on the left button*/
  leftText: PropTypes.string,
  /** Text displayed on the right button*/
  rightText: PropTypes.string,
  /** Click function specification of the left button */
  leftClick: PropTypes.func,
  /** Click function specification of the right button */
  rightClick: PropTypes.func,
  /** Any icons displayed on the left button*/
  icon_l: PropTypes.string,
  /** Any icons displayed on the right button*/
  icon_r: PropTypes.string
};

export default ButtonGroup;
