import React, { Component } from "react";
import autobind from "react-autobind";
import { connect } from "react-redux";
import _ from "lodash";
import {withRouter} from 'react-router-dom';
import * as residentActions from "../../../store/Residents/actions";
import * as residentSelectors from "../../../store/Residents/reducer";
import * as moduleSelectors from "../../../store/ServiceModules/reducer";


import DataList from "../../../components/DetailList/DetailList";

import Button from "../../../components/Button/Button";
const searchOption = {
  Resident: "name",
  Address: "address"
};

class ResidentScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      type: "residents",
      resident: "",
      search: "",
      residentColor: "",
      searchType:
        searchOption.Resident /** This would store the search option by (name / address)  default set as name*/,
      validSearch: false
    };

    autobind(this);
  }
  handleKeyPress(e) {
    const { search, searchType } = this.state;
    if (e.key === "Enter") {
      var split = search
        .trim()
        .split(" ")
        .filter(value => {
          return value !== "";
        });

      let data = {
        firstname: null,
        lastname: null,
        address: null
      };

      if (split.length === 2 && searchType === searchOption.Resident) {
        this.setState({
          validSearch: true
        });
        data = {
          firstname: split[0],
          lastname: split[1],
          address: null
        };
      } else if (split.length === 1 && searchType === searchOption.Resident) {
        this.setState({
          validSearch: true
        });
        data = {
          firstname: split[0],
          lastname: "",
          address: null
        };
      } else if (searchType === searchOption.Address) {
        this.setState({
          validSearch: true
        });
        data = {
          firstname: null,
          lastname: null,
          address: search
        };
      } else {
        this.props.dispatch(residentActions.clearSearchQueue());
        this.setState({
          validSearch: false
        });
      }

      if (split.length > 0)
        setTimeout(() => {
          this.props.dispatch(residentActions.searchResidents(data));
        }, 0);
    }
  }

  handleChange(e) {
    let field = e.target.name;
    let value = e.target.value;
    this.setState({
      [field]: value
    });
  }

  handleOptionSelected(e) {
    this.setState({
      searchType: e.target.value
    });
  }
  /**Method to handle resident view item clicked on listed view */
  handleViewClick(e) {
    this.props.history.push({
      pathname: "/concierge/residents/profile",
      state: { residentId: e }
    });
  }

  handleSearchOption(val) {
    this.setState({
      searchType: val
    });
  }

  render() {

    const { searchResidentsQueue } = this.props;
    let searchQueue = _.map(searchResidentsQueue, (resident, index) => {
      return {
        id: resident.id,
        fullName: `${resident.firstName} ${resident.lastName}`,
        address: `${resident.addressLine1} ${resident.addressLine2 ? resident.addressLine2 : ""}`
      };
    });
   
    return (
      <div>
       
        <div className={"landing type-" + this.state.type}>
          <label>Search</label>
          <input
            type="text"
            name="search"
            onChange={this.handleChange}
            placeholder="Search by name or address"
            onKeyPress={e => this.handleKeyPress(e)}
          />
        </div>
        <div className="options">
          <div
            id="residentOption"
            className={
              this.state.searchType === searchOption.Resident ? "underLine" : ""
            }
            onClick={e => {
              this.handleSearchOption(searchOption.Resident);
            }}
          >
            Resident
          </div>
          <div className="bar" />
          <div
            id="addressOption"
            className={
              this.state.searchType === searchOption.Address ? "underLine" : ""
            }
            onClick={e => {
              this.handleSearchOption(searchOption.Address);
            }}
          >
            Address
          </div>
        </div>
        <div>
          {/**No data result */
            this.state.validSearch === false ||
              (this.props.searchResidentsQueue &&
                this.props.searchResidentsQueue.length === 0) ? (
                <div className="searchResultContainer">
                  <span className="notFound">
                    Couldn't find a resident? Click below to create a new resident.
              </span>{" "}
                  <br /> {/** remove when css added */}
                  <Button
                    size="cta-medium"
                    text="Create Resident"
                    handleClick={() => {
                      this.props.history.push('/concierge/residents/addnew',
                       {
                          search: this.state.search,
                          firstName: this.state.fname,
                          lastName: this.state.lname
                        });
                    }}
                  />
                </div>
              ) : (
                ""
              )}

          {/**Search Result minimal data exposed, data available based on list design, will be extending the DataList component for this*/}
          {this.props.searchResidentsQueue && (
            <DataList
              data={searchQueue}
              listType="resident-view-list"
              actionClick={e => this.handleViewClick(e)}
            />
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    searchResidentsQueue: residentSelectors.getSearchedResidents(state),
    modules: moduleSelectors.loadModules(state)
  };
}
export default withRouter(connect(mapStateToProps)(ResidentScreen));
