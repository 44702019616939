import React, { Component } from 'react';
import { connect } from 'react-redux';

//import Parsley from "parsleyjs";

import * as parcelsAction from '../../../store/Parcels/actions';
import * as parcelsSelector from '../../../store/Parcels/reducer';

import * as residentActions from '../../../store/Residents/actions';
import * as residentSelectors from '../../../store/Residents/reducer';

import ModuleMenu from '../../../components/ModuleMenu/ModuleMenu';
import FooterNavigation from '../../../components/FooterNavigation/FooterNavigation';
import FeatureArea from '../../../components/FeatureArea/FeatureArea';
import InputComponent from '../../../components/Input/Input';
import Button from '../../../components/Button/Button';
import ButtonGroup from '../../../components/Button/ButtonGroup';
import DetailList from '../../../components/DetailList/DetailList';

import ResidentAddrAutoSuggest from '../../../components/TenantAutoSuggest/ResidentAddrAutoSuggest';
import * as moduleSelectors from '../../../store/ServiceModules/reducer';

import autoBind from 'react-autobind';
import { createBrowserHistory } from 'history';


//import { Link } from "react-router";
import _ from 'lodash';
import * as $ from 'jquery';
let history = createBrowserHistory();
class CheckInScreen extends Component {
	constructor(props) {
		super(props);

		this.state = {
			type: 'parcels',
			// Came here from overview page?
			barcode: '',
			memberId:
				this.props.location.state && !_.isUndefined(this.props.location.state.memberId)
					? this.props.location.state.memberId //deal with the back button used
					: undefined,
			address: '',
			tenant: '',
			locationFullName: '',
			rows: [],
			modules: []
		};

		this.props.dispatch(parcelsAction.getAllLocations());

		this.addParcelValidation = null;

		autoBind(this);
	}

	componentDidMount() {
		this.addValidation();
		this.props.dispatch(residentActions.getAllResidents());
		this.setState({
			barcode:
				this.props.location.state && this.props.location.state.barcode ? this.props.location.state.barcode : ''
		});
	}

	handleInputChange(e) {
		var field = e.target.name;
		var value = e.target.value;

		this.setState({
			[field]: value
		});
	}

	addValidation() {
		this.addParcelValidation = $(this.refs.validate).parsley({
			classHandler: function(el) {
				return el.$element.closest('div:not(.input-wrapper):not(.Select)');
			}
		});
	}

	componentWillUnmount() {
		this.addParcelValidation.destroy();
	}
	componentDidUpdate(prevProps, prevState) {
		if (prevState.memberId !== this.state.memberId && !_.isUndefined(this.state.memberId)) {
			this.setState({
				memberId: this.state.memberId
			});
		}
	}

	validate(fromSubmit = false) {
		this.addParcelValidation.validate({ force: true });

		const valid = this.addParcelValidation.isValid();

		this.setState({
			addParcelFormValid: valid,
			addParcelFormUsed: true
		});

		if (valid === true && fromSubmit === true) {
			this.setState({ rows: this.state.rows.concat([ this.state.rows.length ]) });

			this.formReset();

			this.props.dispatch(
				parcelsAction.addParcelsToQueue([
					{
						barcode: this.state.barcode,
						tenant: this.state.tenant,
						address: this.state.address,
						location: this.state.location,
						locationFullName: this.state.locationFullName,
						memberId: this.state.memberId,
						index: this.state.rows[this.state.rows.length - 1]
					}
				])
			);

			this.setState({
				addParcelFormUsed: false
			});
		}
	}

	formReset() {
		this.setState({
			barcode: ''
			//address: "",
			//tenant: ""
		});

		//this.refs["tenant-auto-suggest"].getWrappedInstance().reset();

		$(this.refs.validate).get(0).reset();

		this.addParcelValidation.destroy();

		// Reset all the select input components to the placeholder value
		for (let key in this.refs) {
			if (key.indexOf('formSelect') === 0) {
				this.refs[key].reset();
			}
		}

		setTimeout(() => {
			this.addValidation();
		}, 0);
	}

	removeFromQueue(index) {
		this.props.dispatch(parcelsAction.removeParcelFromQueue(index));
	}

	moduleMenu() {
		const { modules } = this.props;

		return <ModuleMenu modules={modules} handleModuleMenuSelected={() => {}} />;
	}

	render() {
		const locations = this.props.locations.map((location) => {
			return { value: location.id, label: location.locationName };
		});

		const { address, barcode, memberId } = this.state;

		const currentModule = _.find(this.props.modules, (o) => o.title === 'Parcels');

		return (
			<div>
				<div className={'container type-parcel'}>
					<FeatureArea
						type={this.state.type}
						areaTagline="Please enter information below"
						pageHeader="Check-in"
						areaTitle="Parcels"
						overlayColor={currentModule && currentModule.imageOverlayTextColour}
						landing={false}
						activeMenu={true}
					/>

					<form
						className="content-wrapper"
						ref="validate"
						onSubmit={(e) => {
							e.preventDefault();
						}}
						data-parsley-errors-container="#validation-messages"
						data-parsley-focus="none"
					>
						<div className="inputs">
							<InputComponent
								label="Parcel Barcode Entry"
								name="barcode"
								placeHolder="Barcode"
								value={barcode}
								handleChange={this.handleInputChange}
								parsley={{
									'data-parsley-type': 'alphanum',
									'data-parsley-minlength': '2',
									'data-parsley-maxlength': '35',
									'data-parsley-required': true,
									'data-parsley-error-message': 'Please enter a valid parcel barcode'
								}}
								//disabled={barcode !== ""}
							/>

							<ResidentAddrAutoSuggest
								ref="tenant-auto-suggest"
								labelText="Tenant"
								placeHolder="Full Name"
								clearableIfValid={true}
								defaultTenantId={Number(memberId)}
								value={this.state.tenant}
								landing={false}
								disabled={this.props.queuedParcels.length > 0 && this.state.memberId !== ''}
								onSelected={(data) => {
									this.setState({
										memberId: data.memberId,
										address: data.address,
										tenant: data.tenant
									});

									setTimeout(() => {
										this.state.addParcelFormUsed && this.validate();
									}, 0);
								}}
								defaultText={(dataVal) => {}}
							/>

							{
								//   <TenantAutoSuggest
								//   ref="tenant-auto-suggest"
								//   clearableIfValid={true}
								//   onSelected={data => {
								//     this.setState({
								//       memberId: data.memberId,
								//       companyName: data.companyName,
								//       tenant: data.tenant
								//     });
								//     /* Timeout added otherwise validation not fired because autosuggest still updating DOM */
								//     setTimeout(() => {
								//       this.state.addParcelFormUsed && this.validate();
								//     }, 0);
								//   }}
								// />
							}
						</div>

						<div className="inputs two-column">
							<InputComponent
								label="Address"
								name="address"
								placeHolder="Address"
								handleChange={this.handleInputChange}
								value={address}
								disabled={!_.isUndefined(memberId) || memberId !== ''}
								// parsley={{
								//   "data-parsley-pattern": "/^[A-Za-z ]+$/", // alpha chars only https://stackoverflow.com/questions/23415795/
								//   "data-parsley-minlength": 2,
								//   "data-parsley-required": true,
								//   "data-parsley-error-message": "Please enter a valid address"
								// }}
							/>

							<InputComponent
								type="select"
								label="Location"
								name="location"
								ref="formSelect-Location" // formSelect prefix will have .reset method called when form is resetting
								options={locations}
								placeHolder="Select Location"
								handleChange={(e) => {
									this.handleInputChange(e);

									!_.isUndefined(e.target.label) &&
										this.setState({ locationFullName: e.target.label });

									this.validate();
								}}
								parsley={{
									'data-parsley-required': true,
									'data-parsley-error-message': 'Please select a location'
								}}
							/>
						</div>

						<div
							className={'validation-messages' + (this.state.addParcelFormValid ? ' valid' : ' invalid')}
							id="validation-messages"
						>
							{this.state.addParcelFormUsed && (
								<div>{/* <p className="message type-success">Sending Parcel To Server...</p> */}</div>
							)}
						</div>

						<div className="align-center">
							<Button
								size="cta-large"
								text={this.props.queuedParcels.length > 0 ? 'Add Another Parcel' : 'Check-in Parcel'}
								handleClick={() => {
									this.validate(true);
								}}
								isDisabled={_.isUndefined(memberId) || memberId === ''}
							/>
						</div>
					</form>

					<div className="content-wrapper">
						{this.props.queuedParcels && (
							<DetailList
								listType="parcel-check-in"
								data={this.props.queuedParcels}
								headingsAlone={true}
								emptyMessage="Parcels for check-in will appear here"
								actionClick={(index) => {
									this.removeFromQueue(index);
								}}
							/>
						)}
					</div>

					<ButtonGroup
						sizes={[ 'smedium', 'medium' ]}
						rightDisabled={this.props.queuedParcels.length === 0}
						leftClick={() => {
							history.push({
								pathname: '/',
								state: { setModule: 'Parcels' }
							});
						}}
						rightClick={() => {
							history.push('/concierge/parcel/checkinreview');
						}}
					/>
					<FooterNavigation />
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		locations: parcelsSelector.getParcelLocations(state),
		queuedParcels: parcelsSelector.getCheckInQueue(state),
		residents: residentSelectors.getResidents(state),
		modules: moduleSelectors.loadModules(state)
	};
}

export default connect(mapStateToProps, null, null, { forwardRef: true })(CheckInScreen);
