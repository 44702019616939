import _ from "lodash";
import * as types from "./actionTypes";
import checkoutService from "../../services/BookingBugServices/CheckoutService";
import meetingService from "../../services/MeetingServices";
import moment from "moment";

import * as resourcesSelectors from "../Resources/reducer";
import * as resourcesActions from "../Resources/actions";
import * as visitorSelectors from "../Visitors/reducer";
import * as visitorActions from "../Visitors/actions";
import * as tenantSelector from "../Tenants/reducer";
import * as pageEventSelectors from "../PageEvents/reducer";
import * as checkoutSelectors from "./reducer";

export function addItemToBasket(tenant, serviceId, callback = () => { }) {
  return async (dispatch, getState) => {
    try {
      //const state = getState();
      const eventId = resourcesSelectors.getEventId(getState());
      const queue = resourcesSelectors.getBasketQueue(getState());

      const serviceUnit = resourcesSelectors.getServiceUnit(getState());
      let items = [];

      if (serviceUnit === "day") {
        items = _.map(queue, item => {
          return {
            service_id: serviceId,
            date: item.start,
            event_id: eventId,
            member_id: tenant.bookingBugMemberId,
            duration: item.duration,
            units: item.units,
            questions: item.questions
          };
        });


      } else if (serviceUnit === "minute") {
        items = _.map(queue, item => {
          let hours = new Date(item.start).getHours() * 60;
          let min = hours + new Date(item.start).getMinutes();
          const time = min;
          const date = moment(item.start).format("YYYY-MM-DD");

          let duration = item.end_min - item.start_min;

          return {
            service_id: serviceId,
            date: date,
            event_id: eventId,
            time: time,
            member_id: tenant.bookingBugMemberId,
            duration: duration,
            questions: item.questions
          };
        });


      }

      let bookingData = { items };

      const basket = await checkoutService.addToBasketGroup(
        eventId,
        tenant.bookingBugMemberId,
        serviceId,
        bookingData
      );

      dispatch({ type: types.ITEM_ADDED_TO_BASKET, basket });

      callback(true);


      // for (var index = 0; index < queue.length; index++) {
      //   let hrs = new Date(queue[index].start).getHours() * 60;
      //   let mins = hrs + new Date(queue[index].start).getMinutes();

      //   const time = mins;
      //   const date = dateFormat(queue[index].start, "isoDate");

      //   const data = {
      //     serviceId: serviceId,
      //     date: date,
      //     eventId: eventId,
      //     time: time,
      //     memberId: tenant.id
      //   };

      //   const basket = await checkoutService.addToBasket(
      //     serviceId,
      //     date,
      //     eventId,
      //     time,
      //     tenant.bookingBugMemberId
      //   );

      //   dispatch({ type: types.ITEM_ADDED_TO_BASKET, basket });
      // }
    } catch (error) {
      callback(false);
      console.error(error);
    }
  };
}

export function removeItemFromBasket(id, callback = () => { }) {
  return async (dispatch, getState) => {
    try {
      const currentBasket = checkoutSelectors.getBasket(getState());
      //const blockSlot = resourcesSelectors.getBlockSlotSelected(getState());
      const selectedItem = _.find(currentBasket, i => i.id === id);

      const data = await checkoutService.removeFromBasket(selectedItem.id);
      let basket = data.items;
      dispatch({ type: types.ITEM_REMOVED_FROM_BASKET, basket });
      callback(true);
      // dispatch({ type: types.ITEM_REMOVED_FROM_BASKET, basket });
    } catch (error) {
      callback(false);
      console.error(error);
    }
  };
}
export function loadBasketFromCache(id) {
  return async (dispatch, getState) => {
    try {
      const basket = await checkoutService.getBasket();

      if (basket) dispatch({ type: types.BASKET_LOADED_FROM_CACHE, basket });
    } catch (error) {
      console.error(error);
    }
  };
}
export function setChargeType(type, callback = () => { }) {
  return (dispatch, getState) => {
    try {
      dispatch({ type: types.CHARGE_TYPE_SET, chargetype: type });
      callback(true);
    } catch (error) {
      callback(false);
      console.error(error);
    }
  }
}

export function completeCheckout(id, callback = () => { }) {
  return async (dispatch, getState) => {
    try {
      const checkoutData = await checkoutService.checkout(id);
      const details = checkoutData.bookings;

      dispatch(resourcesActions.setBookingCreated(details));

      const pageEvent = pageEventSelectors.getPageEvent(getState());
      const tenant = tenantSelector.getSelectedTenant(getState());

      const vist = visitorSelectors.getVisitor(getState());
      const carReg = visitorSelectors.getCarReg(getState());
      const serviceUnit = resourcesSelectors.getServiceUnit(getState());
      const chargeType = checkoutSelectors.getChargeType(getState());
      const block_selection = resourcesSelectors.getBlockSlotSelected(
        getState()
      );

      //let status = "";
      if (pageEvent === "parking") {
        const visitorDetails = { id: vist.id, regNumber: carReg };

        dispatch(visitorActions.addParkingDetails(visitorDetails));
        dispatch(visitorActions.getVisitorById(vist.id));
      }

      //re construct visitor object from Visitor to VisitorDto
      let visitor = {};
      if (vist)
        visitor = {
          id: vist.id,
          firstName: vist.firstName,
          surname: vist.surname,
          status: vist.status,
          email: vist.email,
          dueDate: vist.dueDate,
          companyName: vist.companyName,
          regNumber: carReg,
          meetingId: vist.meetingId,
          parking: vist.parking
        };

      let bookings = [];
      let temp_booking = [];

      if (serviceUnit === "day") {

        bookings = _.map(details, booking => {
          let stime = new Date(booking.datetime);
          let etime = new Date(booking.end_datetime);
          let start_min = stime.getHours() * 60 + stime.getMinutes();
          let end_min = etime.getHours() * 60 + etime.getMinutes();
          return {
            id: booking.id,
            resourceName: booking.resource_name,
            price: booking.price,
            date: booking.datetime,
            timeFrom: booking.datetime,
            timeTo: booking.end_datetime,
            start_min: start_min,
            end_min: end_min,
            serviceType: serviceUnit,
            resourceLink: _.get(booking, "_links.resource.href")
          };
        });


        _.map(bookings, item => {
          temp_booking.push(item);
        });
      } else if (serviceUnit === "minute") {
        bookings = _.map(details, booking => {

          let stime = new Date(booking.datetime);
          let etime = new Date(booking.end_datetime);

          let start_min = stime.getHours() * 60 + stime.getMinutes();
          let end_min = etime.getHours() * 60 + etime.getMinutes();
          return {
            id: booking.id,
            resourceName: booking.resource_name,
            price: booking.price,
            date: booking.datetime,
            timeFrom: moment(stime).format("YYYY-MM-DD HH:mm"),
            timeTo: moment(etime).format("YYYY-MM-DD HH:mm"),
            start_min: start_min,
            end_min: end_min,
            serviceType: serviceUnit,
            resourceLink: _.get(booking, "_links.resource.href")
          };
        });

        _.map(bookings, (item, index) => {
          let bookedItem = _.find(block_selection, selection => {
            return (
              moment(item.date).format("YYYY-MM-DD") ===
              moment(selection.start).format("YYYY-MM-DD") &&
              _.difference([item.start_min], selection.slotsTime).length === 0
            );
          });

          if (bookedItem && !_.isUndefined(bookedItem)) {
            let tmp = {
              id: item.id,
              resourceName: item.resourceName,
              price: item.price,
              date: bookedItem.start,
              timeFrom: bookedItem.start,
              timeTo: bookedItem.end
            };

            if (!_.find(temp_booking, o => o.timeFrom === tmp.timeFrom))
              temp_booking.push(tmp);

            return tmp;
          } else {
            temp_booking.push(item);
            return item;
          }
        });


      }//end if minutes 




      //loop through and check if its a block time or event time slot

      var resourceBooked = {
        bookings: temp_booking,
        tenant: tenant,
        visitor: visitor,
        type: pageEvent,
        chargeType: chargeType
      };


      // const notify = await meetingService.resourceBookingNotification(
      //   resourceBooked
      // );

      try {
        const notify = await meetingService.tenantResourceBookingNotification(
          resourceBooked
        );
        if (notify)
          dispatch({
            type: types.NOTIFICATION_SENT_SUCCESS,
            sendNotification: notify
          });
        else
          dispatch({
            type: types.NOTIFICATION_SENT_FAIL,
            sendNotification: notify
          });


        try {
          const hm_notify = await meetingService.houseManagerResourceBookingNotification(resourceBooked);

          if (hm_notify)
            dispatch({
              type: types.HM_NOTIFICATION_SENT_SUCCESS,
              houseManagerNotification: hm_notify
            });
          else
            dispatch({
              type: types.HM_NOTIFICATION_SENT_FAIL,
              houseManagerNotification: hm_notify
            });

        } catch (error) {
          // trigger a log facility
          // console.error(error)
        }

        if (chargeType === "Account") {
          try {
            const fm_notify = await meetingService.financeManagerResourceBookingNotification(resourceBooked);

            if (notify)
              dispatch({
                type: types.FM_NOTIFICATION_SENT_SUCCESS,
                financeManagerNotification: fm_notify
              });
            else
              dispatch({
                type: types.FM_NOTIFICATION_SENT_FAIL,
                financeManagerNotification: fm_notify
              });
          } catch (error) {
            // trigger a log facility
            // console.error(error)
          }
        }


      } catch (error) {
        //log errors here for email notifications
        // console.error(error)
      }


      dispatch({ type: types.CHECKOUT_COMPLETED, checkoutData });
      dispatch({ type: types.BOOKING_COMPLETED, details });

      callback(true);
    } catch (error) {
      callback(false);
      // console.error(error);
    }
  };
}


export function getpaymentAuthorizeCode(callback = () => { }) {

  return async (dispatch, getState) => {
    try {
      const response = await checkoutService.makePayment("");


      callback(true);
    } catch (error) {
      console.error(error);
    }
  };
}

export function SetActionType(trans) {
  return (dispatch, getState) => {
    try {
      dispatch({ type: types.ACTION_TYPE, transType: trans });
    } catch (error) {
      console.error(error);
    }
  }
}

export function SetActionStatus(status) {
  return (dispatch, getState) => {
    try {
      dispatch({ type: types.ACTION_SUCCESS, status: status });
    } catch (error) {
      console.error(error);
    }
  }
}


export function SetActionMessage(message) {
  return (dispatch, getState) => {
    try {
      dispatch({ type: types.ACTION_MESSAGE_SET, message: message });
    } catch (error) {
      console.error(error);
    }
  }
}