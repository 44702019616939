export const PARCEL_RECORD_LOADED_SUCCESS = 'PARCEL_RECORD_LOADED_SUCCESS';
export const PARCELS_LOADED_SUCCESS = 'PARCELS_LOADED_SUCCESS';
export const PARCELS_LOADED_SUCCESS_MANAGE = 'PARCELS_LOADED_SUCCESS_MANAGE';
export const PARCEL_LOCATIONS_LOADED_SUCCESS = 'PARCEL_LOCATIONS_LOADED_SUCCESS';
export const PARCEL_STATUS_REASONS_LOADED_SUCCESS = 'PARCEL_STATUS_REASONS_LOADED_SUCCESS';

export const PARCEL_ADD_TO_CHECKIN_QUEUE = 'PARCEL_ADD_TO_CHECKIN_QUEUE';
export const PARCEL_REMOVE_FROM_CHECKIN_QUEUE = 'PARCEL_REMOVE_FROM_CHECKIN_QUEUE';
export const PARCEL_EDIT_IN_CHECKIN_QUEUE = 'PARCEL_EDIT_IN_CHECKIN_QUEUE';
export const PARCELS_ADDED_SUCCESS = 'PARCELS_ADDED_SUCCESS';
export const PARCELS_RETURNED_SUCCESS = 'PARCELS_RETURNED_SUCCESS';

export const PARCELS_STATUS_CODES_SUCCESS = 'PARCELS_STATUS_CODES_SUCCESS';
export const PARCEL_RETURN_REASON_CODES_LOAD_SUCCESS = 'PARCEL_RETURN_REASON_CODES_LOAD_SUCCESS';

export const PARCEL_SET_CHECKOUT_QUEUE = 'PARCEL_SET_CHECKOUT_QUEUE';
export const PARCELS_CHECKOUT_SUCCESS = 'PARCELS_CHECKOUT_SUCCESS';
export const PARCEL_CHECKOUT_SUCCESS = 'PARCEL_CHECKOUT_SUCCESS';
export const NOTIFY_PARCEL_CHECKED_IN_SUCCESS = 'NOTIFY_PARCEL_CHECKED_IN_SUCCESS';
export const NOTIFY_PARCEL_CHECKED_IN_FAIL = 'NOTIFY_PARCEL_CHECKED_IN_FAIL';
export const NOTIFY_PARCEL_COLLECTED_SUCCESS = 'NOTIFY_PARCEL_COLLECTED_SUCCESS';
export const NOTIFY_PARCEL_COLLECTED_FAIL = 'NOTIFY_PARCEL_COLLECTED_FAIL';
export const NOTIFY_PARCELS_CHECKED_IN_SUCCESS = 'NOTIFY_PARCELS_CHECKED_IN_SUCCESS';
export const NOTIFY_PARCELS_CHECKED_IN_FAIL = 'NOTIFY_PARCELS_CHECKED_IN_FAIL';
export const NOTIFY_PARCELS_COLLECTED_SUCCESS = 'NOTIFY_PARCELS_COLLECTED_SUCCESS';
export const NOTIFY_PARCELS_COLLECTED_FAIL = 'NOTIFY_PARCELS_COLLECTED_FAIL';
export const PARCELS_EXPECTED_ARRIVED_BY_DATE_LOADED_SUCCESSFULL =
	'PARCELS_EXPECTED_ARRIVED_BY_DATE_LOADED_SUCCESSFULL';
export const PARCELS_EXPECTED_ARRIVED_BY_DATE_LOADED_FAIL = 'PARCELS_EXPECTED_ARRIVED_BY_DATE_LOADED_FAIL';
export const REGISTER_EXPECTED_PARCEL_LIST_SUCCESSFULL = 'REGISTER_EXPECTED_PARCEL_LIST_SUCCESSFULL';
export const REGISTER_EXPECTED_PARCEL_LIST_FAIL = 'REGISTER_EXPECTED_PARCEL_LIST_FAIL';
export const PARCEL_SETTINGS_LOAD_SUCCESSFULL = 'PARCEL_SETTINGS_LOAD_SUCCESSFULL';
export const PARCEL_SETTINGS_LOAD_FAIL = 'PARCEL_SETTINGS_LOAD_FAIL';
export const PARCEL_DUE_LOADED_SUCCESSFULLY = 'PARCEL_DUE_LOADED_SUCCESSFULLY';
export const PARCEL_EXPECTED_LOAD_SUCCESSFULLY = 'PARCEL_EXPECTED_LOAD_SUCCESSFULLY';
export const REGISTER_PARCEL_QUEUE_SUCCESSFULLY = 'REGISTER_PARCEL_QUEUE_SUCCESSFULLY';
export const PARCEL_RECORD_UPDATED_SUCCESSFULLY = 'PARCEL_RECORD_UPDATED_SUCCESSFULLY';
export const PARCEL_RECORD_UPDATED_FAIL = 'PARCEL_RECORD_UPDATED_FAIL';
