import * as types from './actionTypes';
import conciergeServices from '../../services/ConciergeSettingService';

export function fetchgatewaySettings(callback = () => {}) {
	return async (dispatch, getState) => {
		try {
			const result = await conciergeServices.getpaymentGatewaySettings();

			if (result) {
				dispatch({ type: types.PAYMENT_GATEWAY_SETTINGS_LOAD_SUCCESS, settings: result });
				callback(true);
			} else {
				dispatch({ type: types.PAYMENT_GATEWAY_SETTINGS_LOAD_FAIL, errors: result });
				callback(true);
			}

			callback(true);
		} catch (error) {
			callback(false);

			dispatch({ type: types.PAYMENT_GATEWAY_SETTINGS_LOAD_FAIL, errors: error.message });
			//console.error(error);
		}
	};
}

export function getPaymentSignature(amount, currency, cartid, callback = () => {}) {
	return async (dispatch, getState) => {
		try {
			var result = await conciergeServices.getpaymentSignature(amount, currency, cartid);
			dispatch({ type: types.PAYMENT_SIGNATURE_LOAD_SUCCESS, signature: result });
			callback(true);
		} catch (error) {
			callback(false);

			dispatch({ type: types.PAYMENT_SIGNATURE_LOAD_FAIL, errors: error.message });
		}
	};
}

export function getPaymentCryptSignature(paymentType, data, callback = () => {}) {
	return async (dispatch, getState) => {
		try {
			var result = await conciergeServices.getPaymentSignatureCrypt(paymentType, data);
			dispatch({ type: types.PAYMENT_SIGNATURE_LOAD_SUCCESS, signature: result });
			callback(true);
		} catch (error) {
			callback(false);

			dispatch({ type: types.PAYMENT_SIGNATURE_LOAD_FAIL, errors: error.message });
		}
	};
}
