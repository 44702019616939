import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import LoginScreen from "../LoginScreen";
import HeaderNavigation from "../../components/HeaderNavigation/HeaderNavigation";
import * as authSelectors from "../../store/auth/reducer";
import * as authActions from "../../store/auth/actions";
import { withRouter} from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Config from '../../config';
//Test loading modules, this would be moved to when use signs in
import * as moduleActions from "../../store/ServiceModules/actions";
import * as moduleSelectors from "../../store/ServiceModules/reducer";
import * as settingActions from "../../store/ConciergeSetting/actions";
import * as settingSelector from "../../store/ConciergeSetting/reducer";
import autoBind from "react-autobind";
import IdleTimer from 'react-idle-timer';

var PropTypes = require('prop-types');
let history = createBrowserHistory();
const timeOutInMinutes = !_.isUndefined(Config.userTimeOutInMinutes) ? Config.userTimeOutInMinutes : 20;
class AuthArea extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modules: [],
      settings: []
    };
    this.props.dispatch(settingActions.getGlobalSetting());
    this.props.dispatch(moduleActions.loadModules());

    this.idleTimer = null
    autoBind(this);
  }
  componentDidMount() {
    this.props.dispatch(authActions.login());
    this.props.dispatch(authActions.fetchConciergeDetails());
  }

  handlelogout() {
    this.props.dispatch(authActions.logout());
  }

  handleOnAction (event) {
    // console.log('user did something', event)
  }

  handleOnActive (event) {
    // console.log('user is active', event)
    // console.log('time remaining', this.idleTimer.getRemainingTime())
  }

  handleOnIdle (event) {
    //console.log('user is idle triger')
    this.props.dispatch(authActions.logout());
		history.push('/');

    //console.log('last active', this.idleTimer.getLastActiveTime())
  }
  componentDidUpdate(prevProps, prevState){
    if (
      prevProps.modules !== this.props.modules &&
      !_.isUndefined(this.props.modules) && this.props.modules !== null
    ) {
      if (prevProps.modules && this.props.modules.length > 0)
        this.setState({
          modules: this.props.modules
        });
    }
    if (
      prevProps.setting !== this.props.setting &&
      !_.isUndefined(this.props.setting)
    ) {
      if (prevProps.setting && this.props.setting.length > 0)
        this.setState({
          settings: this.props.setting
        });
    }
  }

  render() {
    const { modules, setting } = this.props;
    const mainBackground = setting && setting.mainBackgroundImage;
    const body_style = {
      backgroundImage: "url('" + mainBackground + "')",
      "backgroundRepeatY": "no-repeat",
      "backgroundSize": "cover"
    }

    
    if (this.props.isLoggedIn==true && modules !== null){
      //console.log("in here Auth area", this.props);
      return (
        <div>
          <HeaderNavigation
            isLoggedIn={this.props.isLoggedIn}
            modules={modules}
            setting={setting}

          />
          <div className="main-wrp root" style={body_style}>
            <main className="content">{this.props.children}</main>
          </div>
          <IdleTimer 
          ref={ref => { this.idleTimer = ref }}
          timeout={1000 * 60 * timeOutInMinutes}
          onActive={this.handleOnActive}
          onIdle={this.handleOnIdle}
          onAction={this.handleOnAction}
          debounce={250}
          />
        </div>
      );
    }else{
       return <LoginScreen />
     }
  }
}

AuthArea.propTypes = {
  children: PropTypes.object.isRequired
};

function mapStateToProps(state, ownProps) {
  return {
    isLoggedIn: authSelectors.getIsLoggedIn(state),
    modules: moduleSelectors.loadModules(state),
    setting: settingSelector.loadGlobalSetting(state)
  };
}

export default connect(mapStateToProps)(AuthArea);
