export const LOGIN_SUCESSFUL = "LOGIN_SUCESSFULL";
export const PASSWORD_CHANGED = "PASSWORD_CHANGED";
export const EMAIL_CHANGED = "EMAIL_CHANGED";
export const LOGGED_OUT = "LOGGED_OUT";
export const PASSWORD_RESET_REQUEST_SENT_SUCCESS =
  "PASSWORD_RESET_REQUEST_SENT_SUCCESS";
export const PASSWORD_RESET_FAIL = "PASSWORD_RESET_FAIL";
export const PASWORD_REQUEST_VERIFY_SUCCESS = " PASWORD_REQUEST_VERIFY_SUCCESS";
export const PASWORD_REQUEST_VERIFY_FAIL = " PASWORD_REQUEST_VERIFY_FAIL";
export const PASSWORD_RESET_SUCCESSFULL = "PASSWORD_RESET_SUCCESSFULL";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const CONCIERGE_AUTHORIZED_SUCCESSFULL = "CONCIERGE_AUTHORIZED_SUCCESSFULL";
export const CONCIERGE_AUTHORIZED_FAIL = "CONCIERGE_AUTHORIZED_FAIL";
