import React, { Component } from "react";
import { connect } from "react-redux";
import autoBind from "react-autobind";
import {withRouter} from 'react-router-dom';
import { CSSTransitionGroup } from "react-transition-group";
import Button from "../../../components/Button/Button";

import VisitorAutoSuggestComponent from "../../../components/VisitorAutoSuggest/VisitorAutoSuggestComponent";
//import VisitorAutoSuggest from "../../../components/VisitorAutoSuggest/VisitorAutoSuggest";
import VisitorSearchResult from "../../../components/visitorLogCard/visitorSearchResult";
import ResponseMessage from "../../../components/ResponseMessage/ResponseMessage";
import * as visitorsAction from "../../../store/Visitors/actions";
import * as meetingsActions from "../../../store/Meetings/actions";
import * as meetingActions from "../../../store/Meetings/actions";
import * as visitorActions from "../../../store/Visitors/actions";
import * as residentAction from "../../../store/Residents/actions";
import * as residentSelectors from "../../../store/Residents/reducer";

import * as $ from "jquery";

import "react-datepicker/dist/react-datepicker.css";
import "rc-time-picker/assets/index.css";
import _ from "lodash";
import Datepicker from "react-datepicker";
import moment from "moment";
import dateFormat from "dateformat";

import FeatureArea from "../../../components/FeatureArea/FeatureArea";
import FooterNavigation from "../../../components/FooterNavigation/FooterNavigation";


import * as settingSelector from "../../../store/ConciergeSetting/reducer";

import * as moduleSelectors from "../../../store/ServiceModules/reducer";
import * as tenantSelector from "../../../store/Tenants/reducer";
import * as meetingSelector from "../../../store/Meetings/reducer";
import * as visitorSelector from "../../../store/Visitors/reducer";

import "../../../sass/containers/visitors/index.css";
import { start } from "pretty-error";


const VisitorStatuses = {
  CheckedIn: "checkedin",
  CheckedOut: "checkedout",
  Notified: "notified"
};

class VisitorBooking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      name: "",
      visitorName: "",
      searchResult: this.props.location.state && this.props.location.state.searchResult ? this.props.location.state.searchResult : undefined,
      startDate: this.props.location.state && this.props.location.state.startDate ? moment(this.props.location.state.startDate) : moment(),
      // startDate: this.props.searchRedirected ? (this.props.location.state.searchResult != undefined ? moment(this.props.location.state.searchResult.search_date.split('/')[1] + '/' + this.props.location.state.searchResult.search_date.split('/')[0] + '/' + this.props.location.state.searchResult.search_date.split('/')[2]) : moment()) : (this.props.location.state.startDate != undefined ? moment(this.props.location.state.startDate) : moment()),
      //selecetMeetingId : this.props.location.state && this.props.location.state.meetingId ? this.props.location.state.meetingId : undefined,
      visitorCompany: "",
      disabled: "disabled",
      rows: [0],
      type: "visitors",
      formSubmitted: false,
      now: moment(),
      status: visitorActions.getVisitorStauses(),
      found: true,
      meetings: [],
      limit: 7,
      defaultLimit: 7,
      dataReloaded: undefined
    };

    this.addVisitorValidation = null;
    //this.loadMoreMeetings;
    // this.addDatepickerValidation = null;    
    autoBind(this);
  }


  componentDidMount() {
    const { startDate, searchResult } = this.state;
    this.addValidation();
    this.props.dispatch(visitorsAction.cancelMeetingLoad());

    this.props.dispatch(residentAction.getAllResidents());


    this.props.dispatch(
      meetingActions.getMeetingsByDate(moment(startDate).format("YYYY-MM-DD"))
    );

    if (this.props.searchRedirected === true) {

      this.setState({

        startDate: moment(this.props.location.state.startDate)
      })


    }
    if(this.props.searchResult && this.props.searchResult.searchText){
      this.setState({
        searchData : this.props.searchResult.searchText
      })
    }
  }
 

  componentWillUnmount() {

    if (this.addVisitorValidation)
      this.addVisitorValidation.destroy();

    this.formReset();
  }

  componentDidUpdate(prevProps, prevState) {
    // if (this.props.searchResult !== nextProps.searchResult && !_.isUndefined(this.props.searchRedirected)) {
    //   this.refs["visitor-auto-suggest"].state.value  = this.refs["visitor-auto-suggest"].state.value == ""  ?  this.props.searchResult.searchText : "";
    // }
    if (this.props.meetings !== prevProps.meetings) {

      if (this.props.meetings && (_.isUndefined(prevProps.searchRedirected) || prevProps.searchRedirected === false)) {
        this.setState({
          meetings: this.props.meetings
        });
      }

      if (_.isUndefined(this.state.dataReloaded)) {

        let data = {
          search_date: this.state.startDate.format("YYYY-MM-DD"),
          data: this.state.meetings,
          searchText: this.state.searchText
        };


      }
      //visitor status changed on action, set new data on action completed
      if (!_.isUndefined(this.state.selectedMeeting) && this.props.meetings.length > 0) {
        var selectedMeetingUpdated = _.find(this.props.meetings, { id: this.state.selectedMeeting.id });

        if (selectedMeetingUpdated) {
          this.setState({
            selectedMeeting: selectedMeetingUpdated
          })
        }
      }


      if (_.isUndefined(this.props.searchRedirected) || !this.props.searchRedirected) {
        let data = {
          search_date: this.state.startDate.format("YYYY-MM-DD"),
          data: this.props.meetings,
          searchText: this.state.searchText
        };
        this.props.dispatch(visitorsAction.storeVisitorSearch(data));

      }

      if (this.props.searchRedirected === true && !_.isUndefined(this.state.searchResult)) {

          let searchMeetings = this.state.searchResult.data;
  
          if (this.state.searchResult.meetingId && searchMeetings) {
            let meetingUpdated = _.find(this.props.meetings, { id: this.state.searchResult.meetingId });
            if (!_.isUndefined(meetingUpdated)) {
              searchMeetings = _.map(this.state.searchResult.data, item => {
                if (item.id === meetingUpdated.id) {
                  return meetingUpdated;
                }
  
                return item;
              })
            }
  
  
          } else {
            searchMeetings = this.props.meetings
          }
  
          let data = {
            search_date: this.state.startDate.format("YYYY-MM-DD"),
            data: searchMeetings,
            searchText: this.state.searchResult.searchText
          };
          this.props.dispatch(visitorsAction.storeVisitorSearch(data));

          this.setState({
            meetings: searchMeetings,
            searchText: this.state.searchResult.searchText
          })
      }     
    }
  
    if (this.state.dataReLoaded !== prevState.dataReloaded) {

      if (this.state.dataReLoaded === true) {
        this.props.dispatch(visitorActions.visitorSearchRedirected(undefined))
        this.setState({
          dataReLoaded: undefined
        })
      }
    }
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
   

    // if (this.state.dataReLoaded !== nextState.dataReloaded) {

    //   if (nextState.dataReLoaded === true) {
    //     this.props.dispatch(visitorActions.visitorSearchRedirected(undefined))
    //     this.setState({
    //       dataReLoaded: undefined
    //     })
    //   }
    // }


//     if (this.props.meetings !== nextProps.meetings) {

//        //data reload triggered, re update search data 
//        if (_.isUndefined(this.state.dataReloaded)) {

//         let data = {
//           search_date: this.state.startDate.format("YYYY-MM-DD"),
//           data: nextState.meetings,
//           searchText: this.state.searchText
//         };

// //        this.props.dispatch(visitorsAction.storeVisitorSearch(data));
//       }

      //from redirected and searchdata
      // if (this.props.searchRedirected === true && !_.isUndefined(this.state.searchResult)) {

      //   let searchMeetings = this.state.searchResult.data;

      //   if (this.state.searchResult.meetingId && searchMeetings) {
      //     let meetingUpdated = _.find(nextProps.meetings, { id: this.state.searchResult.meetingId });
      //     if (!_.isUndefined(meetingUpdated)) {
      //       searchMeetings = _.map(this.state.searchResult.data, item => {
      //         if (item.id === meetingUpdated.id) {
      //           return meetingUpdated;
      //         }

      //         return item;
      //       })
      //     }


      //   } else {
      //     searchMeetings = nextProps.meetings
      //   }

      //   this.setState({
      //     meetings: searchMeetings
      //   })


      // }
    //}


    // // if(this.state.meetings !== nextState.meetings){

    // //   let data = {
    // //     search_date: this.state.startDate.format("YYYY-MM-DD"),
    // //     data: this.state.meetings,
    // //     searchText: this.state.searchData
    // //   };
    // //   this.props.dispatch(visitorsAction.storeVisitorSearch(data));
    // // }


    // // if(this.props.searchResult !== nextProps.searchResult){
    // //   this.setState({
    // //     searchData : nextProps.searchResult.searchText
    // //   });
    // // }
  }

  addValidation() {
    this.addVisitorValidation = $(this.refs.validate).parsley({
      classHandler: function (el) {
        return el.$element.closest("div:not(.input-wrapper):not(.Select)");
      }
    });
  }

  validate(fromSubmit = false) {
    this.addVisitorValidation.validate({ force: true });

    const valid = this.addVisitorValidation.isValid();

    this.setState({
      addVisitorFormValid: valid,
      addVisitorFormUsed: true,
      rows: this.state.rows.concat([this.state.rows.length])
    });

    if (valid === true && fromSubmit === true) {
      this.handleVisitorSearch();
      this.formReset();

      this.setState({
        addVisitorFormUsed: false,
        formSubmitted: true
      });
    }
  }

  formReset() {
    this.setState({
      name: "",
      visitorCompany: "",
      selecetMeetingId: undefined,
      selectedMeeting: undefined
    });
  }

  handleBookVisitorsClick(e) {
    this.props.history.push("/concierge/visitor-booking");
  }


  handleVisitorSearch(e) {
    const { visitorCompany, name } = this.state;
    var now = new Date();
    const data = {
      status: "notified",
      companyName: visitorCompany,
      dueDate: dateFormat(now, "isoDate"),
      name: name
    };
  
    this.props.dispatch(
      visitorActions.searchVisitor(data, success => {
        if (success === true) {
          this.props.dispatch(visitorActions.getVisitorSearchInput(data));
          this.props.history.push({
            pathname: "/concierge/visitor-checkin-review",
            state: { visitorData: data }
          });

        }
        else {
          this.setState({
            found: false
          })
          setTimeout(() => {
            this.props.history.push({
              pathname: "/concierge/visitor-management",
              state: { visitorData: data }
            });
          }, 2000);

        }
      })
    );
  }
  handleInputChange(e) {
    const field = e.target.name;
    const value = e.target.value;
    this.setState({
      [field]: value
    });
  }

  //handle selected visitor / meeting from auto suggest
  handleSelectedVisitor(data) {

    var selectedData = _.find(this.props.meetings, { id: data.meetingId });
   
    this.setState({
      selecetMeetingId: data.meetingId,
      visitorId: data.visitorId,
      visitorCompany: data.companyName,
      name: data.visitor,
      selectedMeeting: selectedData,
      meetings: selectedData ? [selectedData] : []
    });
    setTimeout(() => document.activeElement.blur());
    this.props.dispatch(visitorActions.visitorSearchRedirected(false));

    //update visitor search store
    let serach_data = {
      search_date: this.state.startDate.format("YYYY-MM-DD"),
      data: [selectedData],
      searchText: this.state.searchData
    };
    this.props.dispatch(visitorsAction.storeVisitorSearch(serach_data));
  }


  getAddress(memberId) {
    const { residents } = this.props;
    var resident = _.find(residents, { id: memberId })
    if (_.isUndefined(resident))
      return "";
    return `${resident.addressLine1} ${resident.addressLine2}${','} ${resident.postCode}`;
  }


  handleSumbit(val) {
    const { meetings } = this.props;
    let selectedMeeting = _.filter(meetings, meeting => meeting.id === val);
    this.props.dispatch(visitorActions.checkinVisitor(selectedMeeting, success => {
      if (success === true) {
        this.props.dispatch(
          meetingsActions.getMeetingsByDate(dateFormat(this.state.startDate, "isoDate"))
        );
      }
    }));
  }


  handleDateChange(date) {
    this.setState({
      startDate: date
    });

    this.props.dispatch(
      meetingsActions.getMeetingsByDate(dateFormat(date, "isoDate"))
    );

    this.props.dispatch(visitorActions.visitorSearchRedirected(false));

    setTimeout(() => document.activeElement.blur());
  }

  //handle enter key used on visitor auto suggest
  handleAutoKeyPress(e, data) {
    if (e.Key === "Enter") {
      //  console.log("enter key used: ", data);
    }

  }

  formatStatusString(status) {
    let val;
    switch (status) {
      case VisitorStatuses.CheckedIn:
        val = "Checked-in"
        break;
      case VisitorStatuses.CheckedOut:
        val = "Checked-out"
        break;
      case VisitorStatuses.Notified:
        val = "Expected"
        break;
      default:
        val = "";
        break;
    }

    return val;
  }
  loadMoreMeetings() {
    const postLength = this.state.meetings.length;
    const currentLimit = this.state.limit;
    const loadButton = document.getElementById("loaded");
    if (currentLimit < postLength) {
      this.setState({ limit: currentLimit + 7 });

      setTimeout(() => {
        loadButton.scrollIntoView();
      }, 0);
    }
    // if (currentLimit >= postLength) {
    //   loadButton.disabled = true;
    // }
  }

  GotoProfile(meetingId,data) {

    this.props.history.push({
      pathname: "/concierge/visitor-profile",
      state: {
        meetingId: (meetingId),
        meeting: data,
        searchText:this.refs["visitor-auto-suggest"].state.value
      }
    });
    window.scrollTo(0, 0);
  }
  
  render() {
    const { visitorName, startDate, meetings, visitorFound } = this.state;
 
    const currentModule = _.find(
      this.props.modules,
      o => o.title === "Visitors"
    );



    let orderdMeetingsByTime = _.sortBy(meetings, ["start"], ["asc"]);

    console.log(meetings)

    const overlayColor = currentModule && currentModule.imageOverlayTextColour;
    
    return (
      <div>

        <div className="container type-concierge-desktop management">
          <FeatureArea
            type={this.state.type}
            areaTitle="Visitors"
            areaTagline="Please enter information below"
            pageHeader="Visitor Management"
            overlayColor={currentModule && currentModule.imageOverlayTextColour}
            landing={false}
            activeMenu={true}
          />

          <form
            id="form-content"
            className="visitor-wrapper"
            ref="validate"
            onSubmit={e => {
              e.preventDefault();
            }}
            data-parsley-errors-container="#validation-messages"
            data-parsley-focus="none"
            data-parsley-validate-if-empty
          >

            <div className="visitor-management-header">

              <div className="input-section">
                <VisitorAutoSuggestComponent
                  ref="visitor-auto-suggest"
                  defaultVisitorId={this.state.selecetMeetingId}
                  defaultValue = {this.state.searchData}
                  onSelected={this.handleSelectedVisitor}
                  visitors={[]}
                  clearableIfValid={false}
                  value={_.isEmpty(visitorName)}
                  label="Search for Visitor Name or Company"
                  placeHolder="Enter visitor name or company name"
                  meetings={_.orderBy(this.props.meetings, ['date', 'visitor.firstName'], ['asc'])}
                  listDefault={true}
                  handleInputValue={val => {
                    this.setState({
                      searchData : val
                    });
                  }}
                  matchfound={val => {
                    
                    this.setState({
                      visitorFound: this.state.selectedMeeting == undefined ? val : true
                    });
                    // this.setState({
                    //   visitorFound: val
                    // });
                  }}
                  handleKeyPress={(e, data) => {

                    
                    this.props.dispatch(visitorsAction.storeVisitorSearch(null));                                                            
                    this.setState({
                      dataReLoaded: true,

                    });
                    if (e.key === "Enter") {

                         let serach_data = {
                        search_date: this.state.startDate.format("YYYY-MM-DD"),
                        data: data,
                        searchText: this.state.searchData
                      };
                      this.props.dispatch(visitorsAction.storeVisitorSearch(serach_data));
                     
                      if (!_.isUndefined(this.state.selectedMeeting)) {
                        this.setState({
                          visitorMeetingOptions: [this.state.selectedMeeting],
                          meetings: [this.state.selectedMeeting],
                          selectedMeeting: undefined
                        });
                      } else {


                        this.setState({
                          visitorMeetingOptions: data,
                          meetings: _.isUndefined(data) ? _.orderBy(this.props.meetings, ['date', 'visitor.firstName'], ['asc']) : data
                        });
                      }


                      setTimeout(() => document.activeElement.blur());

                    } if (e.key === "Clear") {
                    
                      this.setState({
                        visitorMeetingOptions: data,
                        meetings: this.props.meetings,
                        visitorFound: undefined,
                        selecetMeetingId : undefined,
                        searchData : "",
                        searchResult : undefined
                      })
                    }

                    if (e.key === "Reset") {
                      
                      this.setState({
                        visitorMeetingOptions: this.props.meetings,
                        meetings: this.props.meetings,
                        visitorFound: undefined,
                        searchData : "",
                        searchResult : undefined
                      });
                     this.props.history.push({
                      pathname: "/concierge/visitor-management"
                     });
                    }
                  }}
                />
              </div>
              <div className="date_select">
                <label>Select Date</label>

                <Datepicker
                  selected={startDate}
                  dateFormat="DD/MM/YYYY"
                  locale="en-gb"
                  onChange={this.handleDateChange}
                  fixedHeight={true}
                // minDate={moment()}
                />

              </div>

            </div>

            <div className="visitor-management-body">


              <ResponseMessage
                text={visitorFound === false ? "No results found." : ""}
                id="no_results"
                className={visitorFound === false ? "No-results" : "future-text"}
                type={visitorFound === false ? "error" : ""}
              />



              {orderdMeetingsByTime &&
                orderdMeetingsByTime.length > 0 &&
                (_.isUndefined(visitorFound) || visitorFound === true) &&
                <CSSTransitionGroup
                  transitionName="postman"
                  transitionEnterTimeout={500}
                  transitionLeaveTimeout={300}
                >
                  {
                    orderdMeetingsByTime && orderdMeetingsByTime.length > 0 &&
                    _.map(orderdMeetingsByTime.slice(0, this.state.limit),
                      (meeting, index) => (

                        <VisitorSearchResult
                          key={index}
                          id={meeting.id}
                          name={`${meeting.visitor.firstName} ${meeting.visitor.surname}`}
                          company={meeting.visitor.companyName}
                          meetingId={meeting.visitor.meetingId}
                          tenant={meeting.tenant.firstName + ' ' + meeting.tenant.lastName}
                          tenantAddress={this.getAddress(meeting.tenant.id)}
                          companyName={meeting.tenant.companyName}
                          statusLabel={this.formatStatusString(meeting.visitor.status)}
                          dateExpected={dateFormat(meeting.date, "dd/mm/yyyy")}
                          timeExpected={
                            meeting.visitor.status === VisitorStatuses.Notified ? moment.utc(meeting.start).format("HH:mm") :
                              meeting.visitor.status === VisitorStatuses.CheckedIn ? moment.utc(meeting.visitor.dueDate).format("HH:mm") :
                                meeting.visitor.status === VisitorStatuses.CheckedOut ? moment.utc(meeting.visitor.checkedOutDate).format("HH:mm")
                                  : moment.utc(meeting.date).format("HH:mm")
                          }
                          view={`${"view-"}${meeting.visitor.status}`}
                          data={meeting}
                          gotoProfile={this.GotoProfile}
                        />
                      )
                    )}
                </CSSTransitionGroup>
              }

            </div>
            <div className="single-btn">
              <Button
                id="loaded"
                text="Load More"
                size="medium"
                isDisabled={meetings && this.state.limit >= meetings.length}
                handleClick={this.loadMoreMeetings}
              />
            </div>

          </form>
          <FooterNavigation />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    modules: moduleSelectors.loadModules(state),
    tenants: tenantSelector.getAllTenants(state),
    visitors: visitorSelector.getVisitors(state),
    instruction: meetingSelector.getSpecialInstruction(state),
    setting: settingSelector.loadGlobalSetting(state),
    meetings: meetingSelector.getMeetings(state),
    residents: residentSelectors.getResidents(state),
    searchRedirected: visitorSelector.getSearchFromRedirect(state),
    searchResult: visitorSelector.getSearchResult(state)
  };
}
export default withRouter(connect(mapStateToProps)(VisitorBooking));
