export const KEYS_LOG_LOADED_SUCCESS = "KEYS_HISTORY_LOADED_SUCCESS";
export const KEYS_STATUSES_LOADED_SUCCESS = "KEY_STATUSES_LOADED_SUCCESS";
export const KEYS_LOADED_SUCCESS = "KEYS_LOADED_SUCCESS";
export const KEYS_LOG_LOADED_FAIL = "KEYS_HISTORY_LOADED_FAIL";
export const KEYS_STATUSES_LOADED_FAIL = "KEY_STATUSES_LOADED_FAIL";
export const KEYS_LOADED_FAIL = "KEYS_LOADED_FAIL";
export const ASSIGN_KEY_SUCCESS = "ASSIGN_KEY_SUCCESS";
export const ASSIGN_KEY_FAIL = "ASSIGN_KEY_FAIL";
export const RETURN_KEY_SUCCESS = "RETURN_KEY_SUCCESS";
export const RETURN_KEY_FAIL = "RETURN_KEY_FAIL";
export const OVERDUE_KEY_LOG_LOAD_SUCCESS = "OVERDUE_KEY_LOG_LOAD_SUCCESS";
export const KEY_LOG_LIST_LOAD_SUCCESS = "KEY_LOG_LIST_LOAD_SUCCESS";
export const KEY_LOG_LIST_LOAD_FAIL = "KEY_LOG_LIST_LOAD_FAIL";
export const SET_KEY_SUCCESS = "SET_KEY_SUCCESS";
export const KEY_STATUS_UPDATE_SUCCESS = "KEY_STATUS_UPDATE_SUCCESS";
export const KEY_STATUS_UPDATE_FAIL = "KEY_STATUS_UPDATE_FAIL";
export const RESET_ACTION_STATUS = "RESET_ACTION_STATUS";
export const NOTIFY_KEY_ASSIGNED_SUCCESS = "NOTIFY_KEY_ASSIGNED_SUCCESS";
export const NOTIFY_KEY_RETURNED_SUCCESS = "NOTIFY_KEY_RETURNED_SUCCESS";
export const NOTIFY_KEY_RETURNED_FAIL = "NOTIFY_KEY_RETURNED_FAIL";