const PAGE_EVENT = "page_event";

class PageEvent {
  setPageEvent(event) {
    localStorage.setItem(PAGE_EVENT, event);
  }

  getPageEvent() {
    return localStorage.getItem(PAGE_EVENT);
  }
}

export default new PageEvent();
