import React, { Component } from 'react';
import { connect } from 'react-redux';
import autobind from 'react-autobind';
import {withRouter} from 'react-router-dom';
import _ from 'lodash';
import FooterNavigation from '../components/FooterNavigation/FooterNavigation';
import ModuleMenu from '../components/ModuleMenu/ModuleMenu';
import GridMenu from '../components/ModuleMenu/GridMenu';
import FeatureArea from '../components/FeatureArea/FeatureArea';
import ParcelCheckInOut from '../containers/concierge/parcel/CheckInOutScreen';
import ResidentScreen from '../containers/concierge/residents/ResidentScreen';
import KeysScreen from '../containers/concierge/keys/KeysScreen';
import VisitorScreen from '../containers/concierge/visitors/CheckinVisitorScreen';
import RoomsScreen from '../containers/concierge/Rooms/RoomsScreen';
import BookingsScreen from '../containers/concierge/Bookings/BookingsScreen';
import HeaderNavigation from "../components/HeaderNavigation/HeaderNavigation";
import ParcelScreen from '../containers/concierge/parcel/ParcelScreen';
import LoginScreen from "./LoginScreen";

//Test loading modules, this would be moved to when use signs in
import * as authSelectors from "../store/auth/reducer";
import * as authActions from "../store/auth/actions";
import * as moduleActions from '../store/ServiceModules/actions';
import * as moduleSelectors from '../store/ServiceModules/reducer';
import * as settingSelector from "../store/ConciergeSetting/reducer";

import '../sass/containers/landingPage.css';
import '../sass/containers/parcels.css';
import '../sass/containers/visitors.css';
import '../sass/containers/residents.css';


class LandingScreen extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			type: 'parcel', //object to represent menu selected,
			modules: [],
			fullGrid: false,
			numOfModules: 4,
			moduleSelected: '',
			showModule: '',
			selectedImgBG: '',
			overlayColor: '',
			landing: true,
			currentModule: undefined,
			fromRedirect:
			this.props.location  && this.props.location.state  && !_.isUndefined(this.props.location.state.fromRedirect)
					? this.props.location.state.fromRedirect
					: false,
			fromRedirectSet: false,
			paymentStatus:
			this.props.location.state && this.props.location.state && !_.isUndefined(this.props.location.state.paymentStatus)
					? this.props.location.state.paymentStatus
					: undefined
		};

		autobind(this);

		this.props.dispatch(authActions.login());
	}

	componentDidMount() {
		const { modules } = this.props;

		if (this.state.fromRedirect === true) {
			let selectedModuleObj = _.find(modules, (o) => o.title === this.props.location.state.module);

			this.setState({
				type: this.props.location.state.module,
				modules: modules,
				selectedModule: this.props.location.state.module,
				currentModule: selectedModuleObj,
				selectedImgBG: !_.isUndefined(selectedModuleObj) ? selectedModuleObj.imageUrl : '',
				moduleSelected: this.props.location.state.module,
				overlayColor: !_.isUndefined(selectedModuleObj)
					? !_.isUndefined(selectedModuleObj.imageOverlayTextColour)
						? selectedModuleObj.imageOverlayTextColour
						: '#000'
					: '',
				showModule: this.renderDefault(this.props.location.state.module, selectedModuleObj)
			});
		}

			this.props.dispatch(moduleActions.loadModules());
	}
	
	componentDidUpdate(prevProps, prevState) {
		if (prevProps.modules !== this.props.modules) {
			if (this.props.modules && this.props.modules.length > 0 && this.state.fromRedirect === false) {
				let setModule = this.props.location && this.props.location.state !== null && !_.isUndefined(this.props.location.state)
					? this.props.location.state.module
					: undefined;
				let currentModule = _.find(this.props.modules, (item) => item.title === setModule);

				if (_.isUndefined(setModule)) {
					this.setState({
						modules: this.props.modules,
						currentModule: this.props.modules[0],
						type: this.props.modules[0].title,
						moduleSelected: this.props.modules[0].title,
						selectedImgBG: !_.isUndefined(this.props.modules[0].imageUrl)
							? this.props.modules[0].imageUrl
							: '',
						overlayColor: !_.isUndefined(this.props.modules[0].imageOverlayTextColour)
							? this.props.modules[0].imageOverlayTextColour
							: '',
						showModule: this.renderDefault(this.props.modules[0].title, this.props.modules[0])
					});
				} else {
					this.setState({
						modules: this.props.modules,
						currentModule: currentModule,
						type: currentModule.title,
						moduleSelected: currentModule.title,
						selectedImgBG: !_.isUndefined(currentModule.imageUrl) ? currentModule.imageUrl : '',
						overlayColor: !_.isUndefined(currentModule.imageOverlayTextColour)
							? currentModule.imageOverlayTextColour
							: '',
						showModule: this.renderDefault(currentModule.title, currentModule)
					});
				}
			}
		}
		if (prevProps.location.state !== this.props.location.state && !_.isUndefined(this.props.location.state)) {
			let setModule = !_.isUndefined(this.props.location.state) ? this.props.location.state.module : undefined;
				
			if (setModule) {
				let currentModule = _.find(prevProps.modules, (item) => item.title === setModule);

				this.setState({
					modules: this.props.modules,
					currentModule: currentModule,
					type: currentModule.title,
					moduleSelected: currentModule.title,
					selectedImgBG: !_.isUndefined(currentModule.imageUrl) ? currentModule.imageUrl : '',
					overlayColor: !_.isUndefined(currentModule.imageOverlayTextColour)
						? currentModule.imageOverlayTextColour
						: '',
					showModule: this.renderDefault(currentModule.title, currentModule)
				});
			}
		}
	}

	//Function to Render ParcelCheckin-checkout module
	renderParcelCheckinout(overlayColor) {
		return (
			<div>
				<h1 style={{ color: overlayColor }}>Check-out</h1>
				<ParcelScreen />
			</div>
		);
	}

	//Function to Render Rooms module
	//NB: would be obsolete, replaced by renderBookingsScreen
	renderRoomsView(overlayColor) {
		return (
			<div>
				<h1 style={{ color: overlayColor }}>Rooms</h1>
				<RoomsScreen />
			</div>
		);
	}

	//Function to Render Rooms module
	renderResidentsView(overlayColor) {
		return (
			<div>
				{' '}
				<h1 style={{ color: overlayColor }}>Residents</h1>
				<ResidentScreen />
			</div>
		);
	}

	// //Function to render Parking Module
	// renderParkingView(overlayColor) {
	//   return <div> Parking View Coming Soon </div>;
	// }

	//Function to render Parking Module
	renderVisitorsView(overlayColor) {
		return (
			<div className="check-in-title">
				<h1 style={{ color: overlayColor }}> Check-in</h1>
				<VisitorScreen />
			</div>
		);
	}
	renderKeysView(overlayColor) {
		return (
			<div>
				<h1 style={{ color: overlayColor }}>Keys</h1>
				<KeysScreen />
			</div>
		);
	}

	//function to Render Bookings module
	renderBookingsView(overlayColor) {
		let data = {};

		if (this.state.fromRedirect === true) {
			data = {
				serviceId: this.props.location.state.serviceId,
				fromRedirect: this.state.fromRedirect,
				selectedResourceId: this.props.location.state.selectedResourceId,
				selectedResourcesId: this.props.location.state.selectedResourcesId,
				paymentStatus: this.props.location.state.paymentStatus,
				paymentSetDate: this.props.location.state.date
			};
		}
		return (
			<div>
				<h1 style={{ color: overlayColor }}>Bookings</h1>
				<BookingsScreen {...data} />
			</div>
		);
	}
	renderBlank(overlayColor) {
		return <div> Module Not Available...</div>;
	}

	renderGrid() {
		return <GridMenu />;
	}
	renderDefault(module, currentModule) {
		if (currentModule)
			switch (module) {
				case 'Parcels':
					return this.renderParcelCheckinout(currentModule.imageOverlayTextColour);

				case 'Residents':
					return this.renderResidentsView(currentModule.imageOverlayTextColour);

				case 'Visitors':
					return this.renderVisitorsView(currentModule.imageOverlayTextColour);

				case 'Keys':
					return this.renderKeysView(currentModule.imageOverlayTextColour);

				case 'Rooms':
					return this.renderRoomsView(currentModule.imageOverlayTextColour);
				case 'Bookings':
					return this.renderBookingsView(currentModule.imageOverlayTextColour);
				default:
					break;
			}
	}
	moduleMenu() {
		const { modules } = this.state;

		return (
			<ModuleMenu
				modules={modules}
				handleModuleMenuSelected={(e) => this.handleModuleMenuSelected(e)}
				selectedModule={this.state.moduleSelected}
			/>
		);
	}

	handleModuleMenuSelected(module) {
		const { modules } = this.state;
		let selectedModuleObj = _.find(modules, (o) => o.title === module);

		switch (module) {
			case 'Parcels':
				this.setState({
					type: module,
					moduleSelected: module,
					selectedImgBG: !_.isUndefined(selectedModuleObj) ? selectedModuleObj.imageUrl : '',
					overlayColor: !_.isUndefined(selectedModuleObj)
						? !_.isUndefined(selectedModuleObj.imageOverlayTextColour)
							? selectedModuleObj.imageOverlayTextColour
							: '#000'
						: '',
					showModule: this.renderParcelCheckinout(selectedModuleObj.imageOverlayTextColour)
				});
				break;
			case 'Residents':
				this.setState({
					type: module,
					selectedImgBG: !_.isUndefined(selectedModuleObj) ? selectedModuleObj.imageUrl : '',
					showModule: this.renderResidentsView(selectedModuleObj.imageOverlayTextColour),
					moduleSelected: module,
					overlayColor: !_.isUndefined(selectedModuleObj)
						? !_.isUndefined(selectedModuleObj.imageOverlayTextColour)
							? selectedModuleObj.imageOverlayTextColour
							: '#000'
						: ''
				});
				break;
			case 'Visitors':
				this.setState({
					type: module,
					selectedImgBG: !_.isUndefined(selectedModuleObj) ? selectedModuleObj.imageUrl : '',
					showModule: this.renderVisitorsView(selectedModuleObj.imageOverlayTextColour),
					moduleSelected: module,
					overlayColor: !_.isUndefined(selectedModuleObj)
						? !_.isUndefined(selectedModuleObj.imageOverlayTextColour)
							? selectedModuleObj.imageOverlayTextColour
							: '#000'
						: ''
				});
				break;
			case 'Keys':
				this.setState({
					type: module,
					selectedImgBG: !_.isUndefined(selectedModuleObj) ? selectedModuleObj.imageUrl : '',
					showModule: this.renderKeysView(selectedModuleObj.imageOverlayTextColour),
					moduleSelected: module,
					overlayColor: !_.isUndefined(selectedModuleObj)
						? !_.isUndefined(selectedModuleObj.imageOverlayTextColour)
							? selectedModuleObj.imageOverlayTextColour
							: '#000'
						: ''
				});
				break;
			case 'Rooms':
				this.setState({
					type: module,
					selectedImgBG: !_.isUndefined(selectedModuleObj) ? selectedModuleObj.imageUrl : '',
					showModule: this.renderRoomsView(selectedModuleObj.imageOverlayTextColour),
					moduleSelected: module,
					overlayColor: !_.isUndefined(selectedModuleObj)
						? !_.isUndefined(selectedModuleObj.imageOverlayTextColour)
							? selectedModuleObj.imageOverlayTextColour
							: '#000'
						: ''
				});
				break;
			case 'Bookings':
				this.setState({
					type: module,
					selectedImgBG: !_.isUndefined(selectedModuleObj) ? selectedModuleObj.imageUrl : '',
					showModule: this.renderBookingsView(selectedModuleObj.imageOverlayTextColour),
					moduleSelected: module,
					overlayColor: !_.isUndefined(selectedModuleObj)
						? !_.isUndefined(selectedModuleObj.imageOverlayTextColour)
							? selectedModuleObj.imageOverlayTextColour
							: '#000'
						: ''
				});
				break;
			default:
				this.setState({
					type: 'Welcome',
					selectedImgBG: '',
					showModule: this.renderBlank(),
					moduleSelected: 'default',
					overlayColor: this.props.modules[0].imageOverlayTextColour
				});
		}
	}
	handleGridSelected(module) {
		// switch(module){
		//   default :
		// }
	}

	render() {
		document.body.style = 'background: #f8f8fa;';
		// Body override colour to be diff from home page
		const { numOfModules, modules } = this.state;
		const { isLoggedIn, setting } = this.props;
		const overlayColor = this.state.overlayColor;
		let moduleListSize = modules && modules.length > 0 ? modules.length : 0;
		
		if (isLoggedIn==true ){
			
		return (
			<div id="container" className={'landing content-' + this.state.type.toString()}>
			
				<FeatureArea
					type={this.state.type}
					pageHeader=""
					areaTitle={this.state.type}
					imageBG={this.state.selectedImgBG}
					landing={true}
					overlayColor={!this.state.overlayColor ? overlayColor : this.state.overlayColor}
				/>

				{
					//numOfmodules used to seperate GridView and Menu View
					//moduleListSize <= numOfModules ? (
					<div className="content-wrapper">
						<h4 id="landing"> Welcome, select a service</h4>

						{/*test stub for menu,this would be loaded based on the modules api result*/
						modules && this.moduleMenu()}
						{
							/*section here will be dynamically loaded based on module selection*/
							this.state.showModule
						}
					</div>
					//)
					// : (
					//     <div className="content-wrapper">
					//       {/*full grid content displays here,*/}
					//       <GridMenu />
					//     </div>
					//   )
				}
				<FooterNavigation />
			</div>
		);
		}else{
			return <LoginScreen />
		}
	}
}
function mapStateToProps(state) {
	return {
		modules: moduleSelectors.loadModules(state),
		isLoggedIn: authSelectors.getIsLoggedIn(state),
		setting: settingSelector.loadGlobalSetting(state)
	};
}
export default withRouter(connect(mapStateToProps)(LandingScreen));
