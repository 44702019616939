export const RESIDENTS_LOADED_SUCCESS = "RESIDENTS_LOADED_SUCCESS";
export const RESIDENTS_GROUPS_LOADED_SUCCESS = "RESIDENTS_GROUPS_LOADED_SUCCESS";
export const SEARCH_RESIDENTS_LOAD_SUCCESS = "SEARCH_RESIDENTS_LOAD_SUCCESS";
export const SEARCH_RESIDENTS_LOAD_FAIL = "SEARCH_RESIDENTS_LOAD_FAIL";
export const CREATE_RESIDENT_SUCCESS = "CREATE_RESIDENT_SUCCESS";
export const CREATE_RESIDENT_FAIL = "CREATE_RESIDENT_FAIL";
export const UPDATE_RESIDENT_SUCCESS = "UPDATE_RESIDENT_SUCCESS";
export const UPDATE_RESIDENT_FAIL = "UPDATE_RESIDENT_FAIL";
export const UPDATE_RESIDENT_APPROVAL_SUCCESS =
  "UPDATE_APPROVAL_RESIDENT_SUCCESS";
export const UPDATE_RESIDENT_APPROVAL_FAIL = "UPDATE_APPROVAL_RESIDENT_FAIL";
export const SEND_REGISTRATION_ACTIVATION_SUCCESS =
  "SEND_REGISTRATION_ACTIVATION_SUCCESS";
export const SEND_REGISTRATION_ACTIVATION_FAIL =
  "SEND_REGISTRATION_ACTIVATION_FAIL";
