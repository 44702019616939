import * as types from "./actionTypes";
import Immutable from "seamless-immutable";
import _ from "lodash";

import * as config from "../../services/config";

const initialState = Immutable({
  meeting: undefined,
  meetings: [],
  newMeetingQueue: [],
  filterCheckedIn: [],
  filterCheckedOut: [],
  checkedInSelectQueue: [],
  checkedOutSelectQueue: [],
  uncollectedSelectQueue: [],
  collectedSelectQueue: [],
  meetingsLog: [],
  logType: undefined,
  notifyCheckin: "",
  specialInstruction: "",
  meetingsFilter: [],
});

export default function meetingReducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.MEETING_RECORD_LOADED_SUCCESS:
      return state.merge({
        meeting: action.result
      });
    case types.NEW_METTING_ADD_TO_QUEUE:
      return state.merge({
        newMeetingQueue: [...state.newMeetingQueue, ...action.meetings]
      });
    case types.NEW_METTING_REMOVE_FROM_QUEUE:
      return state.merge({
        newMeetingQueue: state.newMeetingQueue.filter(meeting => {
          return meeting.index !== action.index;
        })
      });
    case types.NEW_MEETING_CREATED_SUCCESS:
      return state.merge({
        meetings: action.meetings
      });
    case types.MEETINGS_LOADED_SCCESS:
      return state.merge({
        meetings: action.meetings
      });
    case types.MEETINGS_LOADED_FAIL:
      return state.merge({
        meetings: action.meetings
      });
    case types.MEETING_UPDATED:
      // https://stackoverflow.com/questions/43792457/
      return state.merge({
        meetings: state.meetings.map(meeting => {
          if (meeting.id === action.meeting.id) {
            // Create new meeting
            return Object.assign({}, meeting, action.meeting);
          } else {
            return meeting;
          }
        })
      });

    case types.MEETING_SELECTED:
      return state.merge({
        meeting: action.meeting
      });
    case types.FILTER_CHECKED_IN:
      return state.merge({
        filterCheckedIn: action.filterCheckedIn
      });
    case types.FILTER_CHECKED_OUT:
      return state.merge({
        filterCheckedOut: action.filterCheckedOut
      });
    case types.ADD_TO_FILTER_CHECKED_IN:
      return state.merge({
        filterCheckedIn: [...state.filterCheckedIn, ...action.meeting]
      });
    case types.ADD_TO_FILTER_CHECKED_OUT:
      return state.merge({
        filterCheckedOut: [...state.filterCheckedOut, ...action.meeting]
      });

    case types.ADD_TO_CHECKED_IN_SELECT_QUEUE:
      return state.merge({
        checkedInSelectQueue: [...state.checkedInSelectQueue, action.meeting]
      });
    case types.REMOVE_FROM_CHECKED_IN_SELECT_QUEUE:
      return state.merge({
        checkedInSelectQueue:
          action.meeting === "*"
            ? []
            : state.checkedInSelectQueue.filter(meeting => {
              return meeting !== action.meeting;
            })
      });
    case types.ADD_TO_CHECKED_OUT_SELECT_QUEUE:
      return state.merge({
        checkedOutSelectQueue: [...state.checkedOutSelectQueue, action.meeting]
      });
    case types.REMOVE_FROM_CHECKED_OUT_SELECT_QUEUE:
      return state.merge({
        checkedOutSelectQueue:
          action.meeting === "*"
            ? []
            : state.checkedOutSelectQueue.filter(meeting => {
              return meeting !== action.meeting;
            })
      });

    case types.ADD_TO_UNCOLLECTED_SELECT_QUEUE:
      return state.merge({
        uncollectedSelectQueue: [
          ...state.uncollectedSelectQueue,
          action.meeting
        ]
      });
    case types.REMOVE_FROM_UNCOLLECTED_SELECT_QUEUE:
      return state.merge({
        uncollectedSelectQueue:
          action.meeting === "*"
            ? []
            : state.uncollectedSelectQueue.filter(meeting => {
              return meeting !== action.meeting;
            })
      });
    case types.ADD_TO_COLLECTED_SELECT_QUEUE:
      return state.merge({
        collectedSelectQueue: [...state.collectedSelectQueue, action.meeting]
      });
    case types.REMOVE_FROM_COLLECTED_SELECT_QUEUE:
      return state.merge({
        collectedSelectQueue:
          action.meeting === "*"
            ? []
            : state.collectedSelectQueue.filter(meeting => {
              return meeting !== action.meeting;
            })
      });

    case types.MEETINGS_LOG_FETCHED:
      return state.merge({
        meetingsLog: action.meetingsLog,
        logType: action.logType
      });
    case types.SET_MEETINGS_QUEUE:
      return state.merge({
        newMeetingQueue: action.meetingQueue
      });
    case types.SPECIAL_INSTRUCTIONS_APPENDED_SUCCESS:
      return state.merge({
        specialInstruction: action.specialInstruction,
        newMeetingQueue: _.map(state.newMeetingQueue, meeting => {
          let newMeeting = {
            tenantId: meeting.tenantId,
            tenantName: meeting.tenantName,
            tenantCompany: meeting.tenantCompany,
            name: meeting.name,
            companyName: meeting.companyName,
            dueDate: meeting.dueDate,
            endDate: meeting.endDate,
            status: meeting.status,
            email: meeting.email,
            specialInstruction: action.specialInstruction,
            index: meeting.index
          };

          return newMeeting;
        })
      });
    case types.SEND_CHECKIN_NOTIFICATION_SUCCESS:
      return state.merge({
        notifyCheckin: action.notifyResponse
      });
    case types.CLEAR_MEETINGS:
      return state.merge({
        meetings: [],
        specialInstruction: ""
      });
    case types.MEETINGS_FILTER_LOADED_SUCCESSFULLY:
      return state.merge({
        meetingsFilter: action.meetings
      });
    case types.UPDATE_MEETING_SUCCESS:
      return state.merge({
        meeting: action.data,
        meetings: action.data ? _.map(state.meetings, o => {
          if (o.id === action.data.id) {
            return action.data
          }

          return o;
        }) : state.meetings
      })
    default:
      return state;
  }
}

export function getMeeting(state) {
  return state.meetings.meeting;
}

export function getMeetingsQueue(state) {
  return state.meetings.newMeetingQueue;
}

export function getMeetings(state) {
  return state.meetings.meetings;
}
export function filterCheckedIn(state) {
  if (!state.meetings.filterCheckedIn) return state.meetings.meetings;

  return _.filter(
    state.meetings.meetings,
    m => m.visitor.status === config.visitorStatuses.checkedIn
  );
}

export function filterCheckedOut(state) {
  if (!state.meetings.filterCheckedOut) return state.meetings.meetings;

  return _.filter(
    state.meetings.meetings,
    m => m.visitor.status === config.visitorStatuses.checkedOut
  );
}

export function getCheckedInSelectQueue(state) {
  return state.meetings.checkedInSelectQueue;
}

export function getCheckedOutSelectQueue(state) {
  return state.meetings.checkedOutSelectQueue;
}

export function getUncollectedSelectQueue(state) {
  return state.meetings.uncollectedSelectQueue;
}

export function getCollectedSelectQueue(state) {
  return state.meetings.collectedSelectQueue;
}

export function getMeetingsLog(state) {
  return state.meetings.meetingsLog;
}

export function filterUncollected(state) {
  return filterCheckedIn(state).filter(meeting => {
    return meeting.passCollected === false;
  });
}

export function filterCollected(state) {
  return filterCheckedIn(state).filter(meeting => {
    return meeting.passCollected === true;
  });
}

export function getSpecialInstruction(state) {
  return state.meetings.specialInstruction;
}

export function getLogType(state) {
  return state.meetings.logType;
}

export function getMeetingsFilter(state) {
  return state.meetings.meetingsFilter;
}