import React, { Component } from 'react';
import { connect } from 'react-redux';

//helper apps
import moment from 'moment';
import _ from 'lodash';
import autoBind from 'react-autobind';
import dateFormat from 'dateformat';
import {withRouter} from 'react-router-dom';
//component

import BookingCalendarCustom from '../../../components/BookingCalendar/BookingCalendarCustom';

//store
import * as resourceSelector from '../../../store/Resources/reducer';
import * as resourceActions from '../../../store/Resources/actions';

import * as checkoutSelector from '../../../store/Checkout/reducer';
import * as checkoutActions from '../../../store/Checkout/actions';

const calendarViews = {
	Day: 'day',
	Week: 'week',
	WorkWeek: 'work_week',
	Month: 'month'
};

class DailyScreen extends Component {
	_isMounted = false;
	constructor(props) {
		super(props);
		this.state = {
			type: 'rooms',
			now: new Date(),
			startDate: this.props.setDate ? moment(this.props.setDate) : moment(),
			endDate: this.props.setDate ? moment(this.props.setDate).endOf('week') : moment().endOf('week'),
			serviceId: 0,
			eventId: '',
			slots: [],
			options: [],
			timeSlot: 1,
			eventName: '',
			time_step: 1, //default value
			selectedServiceSlot: [],
			selectedEventsSlots: [],
			vision_type: 'week',
			monthNames: [
				'January',
				'February',
				'March',
				'April',
				'May',
				'June',
				'July',
				'August',
				'September',
				'October',
				'November',
				'December'
			],
			current_view: calendarViews.Week,
			selected_event_resources: [],
			request_fail: undefined,
			eventslot_processing: undefined
		};

		autoBind(this);
	}

	componentDidMount() {
		this._isMounted = true;

		this.props.dispatch(resourceActions.clearBasketQueue());

		this.props.dispatch(checkoutActions.loadBasketFromCache());

		if (this.props.service) {
			this.setState({
				serviceId: this.props.service.id,
				time_step: this.props.service.booking_time_step
			});

			this.servicesSlots(
				this.props.service.id,
				this.state.startDate,
				this.state.endDate,
				this.state.current_view
			);
			this.loadBookingQuestions(this.props.service.detail_group_id);
		}
	}


	componentWillUnmount() {
		this._isMounted = false;
		this.props.dispatch(resourceActions.emptyServicesSlots());
	}


	componentDidUpdate(prevProps, prevState) {
		if (prevProps.resourceEvents !== this.props.resourceEvents && this.props.resourceEvents) {
			this.setState({
				selected_event_resources: this.props.resourceEvents
			});

			this.loadEventSlots(this.props.resourceEvents, this.state.startDate, this.state.endDate);
		}

		if (prevProps.service !== this.props.service) {
			if (this.props.service) {
				this.setState({
					serviceId: this.props.service.id,
					time_step: this.props.service.booking_time_step
				});

				this.servicesSlots(
					this.props.service.id,
					this.state.startDate,
					this.state.endDate,
					this.state.current_view
				);
				this.loadBookingQuestions(this.props.service.detail_group_id);
			}
		}
		if (
			this.props.selectedServiceSlot !== prevProps.selectedServiceSlot && 
			this.props.selectedServiceSlot.length > 0
		) {
			this.setState({
				selectedServiceSlot: this.props.selectedServiceSlot
			});
		}

		if (this.props.selectedEventsSlots !== prevProps.selectedEventsSlots) {
			this.setState({
				selectedEventsSlots: this.props.selectedEventsSlots
			});
		}

		if (this.state.current_view !== prevState.current_view) {
			let start_date, end_date;
			if (this.state.current_view === calendarViews.Day) {
				start_date = moment().startOf('day');
				end_date = moment().endOf('day');
				this.setState({
					startDate: start_date,
					endDate: end_date
				});
			} else if (
				this.state.current_view === calendarViews.Week ||
				this.state.current_view === calendarViews.WorkWeek
			) {
				start_date = moment().startOf('week');
				end_date = moment().endOf('week');
				this.setState({
					startDate: start_date,
					endDate: end_date
				});
			}
		}
		if (this.state.startDate !== prevState.startDate && !_.isUndefined(this.state.startDate)) {
			this.servicesSlots(this.state.serviceId, this.state.startDate, this.state.endDate, this.state.current_view);
			this.loadEventSlots(this.state.selected_event_resources, this.state.startDate, this.state.endDate);
		}
	}
	loadBookingQuestions(detail_group_id) {
		this.props.dispatch(resourceActions.fetchBookingQuestions(detail_group_id));
	}

	servicesSlots(serviceId, startDate, endDate, view) {
		let start_date = dateFormat(startDate._d, 'isoDate');
		let end_date = dateFormat(moment(endDate._d, 'isoDate'));

		this.props.dispatch(
			resourceActions.fetchServicesSlots(serviceId, start_date, end_date, (success) => {
				if (success === true) {
					if (this._isMounted)
						this.setState({
							request_fail: undefined
						});
				} else {
					this.setState({
						request_fail: true
					});
				}
			})
		);

		// if (view === calendarViews.Week) {
		//     let start_date = dateFormat(moment(date.startOf("week")._d, "isoDate"));
		//     let end_date = dateFormat(moment(date.endOf("week")._d, "isoDate"));

		//     this.props.dispatch(
		//         resourceActions.fetchServicesSlots(
		//             serviceId,
		//             start_date, end_date, success => {
		//                 if (success === true) {
		//                     this.setState({
		//                         request_fail: undefined
		//                     });
		//                 } else {

		//                     this.setState({
		//                         request_fail: true
		//                     });
		//                 }

		//             }
		//         )
		//     );
		// } else if (view === calendarViews.Day) {
		//     this.props.dispatch(
		//         resourceActions.fetchServicesSlots(
		//             serviceId,
		//             date._d, date._d, success => {
		//                 if (success === true) {
		//                     this.setState({
		//                         request_fail: undefined
		//                     });
		//                 } else {

		//                     this.setState({
		//                         request_fail: true
		//                     });
		//                 }

		//             }
		//         )
		//     );
		// }
	}

	selectedServiceSlots(eventId, date, serviceId, eventName) {
		// this.props.dispatch(
		//     resourceActions.getSelectedEventSlot(
		//         eventId,
		//         new Date(date._d),
		//         serviceId,
		//         eventName, success => {
		//             if (success === true) {
		//                 this.setState({
		//                     request_fail: undefined
		//                 });
		//             } else {
		//                 this.setState({
		//                     request_fail: true
		//                 });
		//             }
		//         }
		//     )
		// );
	}

	availaibleTimeData(serviceId, date, eventId) {
		this.props.dispatch(resourceActions.getServiceAvailability(serviceId, date, eventId));
	}

	handleSelectChange(val) {
		const { servicesSlots } = this.props;
		const { startDate, endDate } = this.state;

		let options = _.map(servicesSlots, (item) => {
			return { eventName: item.name, eventId: item.event_id };
		});
		let selected_event_resource = [];

		//reform selected resource to usable object values
		_.map(val, (o) => {
			let curre_event = _.find(options, { eventId: o });
			if (!_.isUndefined(curre_event)) {
				selected_event_resource.push(curre_event);
			}
			return o;
		});

		this.setState({
			selected_event_resources: selected_event_resource
		});

		//fetch resources based on selected event resource
		this.loadEventSlots(selected_event_resource, startDate, endDate);
	}

	handleRightClick(e) {
		this.props.history.push('/reception/tenant');
	}
	handleBackClick(e) {
		this.props.history.push('/');
	}
	onLogin() {
		this.props.navigationController.pushView(<div>Today</div>);
	}

	modalActionTrigered(action, valid) {
		if (action === 'payment') {
			this.props.history.push({
				pathname: '/',
				state: {
					setModule: 'Bookings'
				}
			});
		}

		if (!_.isUndefined(this.state.serviceId)) {
			this.serviceBookings(this.state.serviceId, dateFormat(this.state.startDate._d, 'isoDate'));
			this.servicesSlots(this.state.serviceId, this.state.startDate, this.state.endDate, this.state.current_view);

			if (this.state.selected_event_resources && this.state.selected_event_resources.length > 0)
				this.loadEventSlots(this.state.selected_event_resources, this.state.startDate, this.state.endDate);
		}
	}

	serviceBookings(serviceid, date) {
		const { current_view } = this.state;

		let startDate = moment(date);
		let endDate = moment(date);

		if (current_view) {
			if (current_view === calendarViews.Day) {
				startDate = moment(date).startOf('day');
				endDate = moment(date).endOf('day');
			} else if (current_view === calendarViews.Week) {
				startDate = moment(date).startOf('week');
				endDate = moment(date).endOf('week');
			}

			if (
				current_view === calendarViews.Day ||
				current_view === calendarViews.Week ||
				current_view === calendarViews.WorkWeek
			)
				this.props.dispatch(
					resourceActions.getServiceBookings_range(
						serviceid,
						startDate.format('YYYY-MM-DD'),
						endDate.format('YYYY-MM-DD'),
						(success) => {
							if (success === true) {
								if (this._isMounted)
									this.setState({
										request_fail: false
									});
							} else {
								this.setState({
									request_fail: true
								});
							}
						}
					)
				);
		}
	}
	switchTypeHandler(type) {
		if (type === calendarViews.Day) {
			this.setState({
				current_view: type
			});
		}
		if (type === calendarViews.Week) {
			this.setState({
				current_view: type
			});
			
		}
		if (type === calendarViews.WorkWeek) {
			this.setState({
				current_view: type
			});
		}
	}

	loadServicesOption(options) {
		return <div />;
	}

	//helper method to call slot data on selected events
	loadEventSlots(events, startDate, endDate) {
		const { current_view, serviceId } = this.state;

		this.setState({
			eventslot_processing: true
		});
		this.props.dispatch(resourceActions.resourceProcessingStatus(true));
		if (current_view === calendarViews.Week) {
			this.props.dispatch(
				resourceActions.getSelectedEventsSlots(
					events,
					new Date(startDate.startOf('week')._d),
					new Date(endDate.endOf('week')._d),
					serviceId,
					(success) => {
						if (success === true) {
							if (this._isMounted)
								this.setState({
									request_fail: undefined
								});
						} else {
							this.setState({
								request_fail: true
							});
						}
						if (this._isMounted)
							this.setState({
								eventslot_processing: undefined
							});
						this.props.dispatch(resourceActions.resourceProcessingStatus(undefined));
					}
				)
			);
		} else if (current_view === calendarViews.Day) {
			this.props.dispatch(
				resourceActions.getSelectedEventsSlots(
					events,
					new Date(startDate._d),
					new Date(startDate._d),
					serviceId,
					(success) => {
						if (success === true) {
							if (this._isMounted)
								this.setState({
									request_fail: undefined
								});
						} else {
							this.setState({
								request_fail: true
							});
						}
						this.setState({
							eventslot_processing: undefined
						});
						this.props.dispatch(resourceActions.resourceProcessingStatus(undefined));
					}
				)
			);
		}
	}

	//keep for carlendar navigation
	handleNavigation(date, view) {
		let start_date, end_date;
		switch (view) {
			case 'day':
				start_date = moment(date).startOf('day');
				end_date = moment(date).endOf('day');
				this.setState({
					startDate: start_date,
					endDate: end_date
				});

				this.servicesSlots(this.state.serviceId, start_date, end_date, this.state.current_view);

				this.loadEventSlots(this.state.selected_event_resources, start_date, end_date);
				return date;
			case 'work_week':
				start_date = moment(date).startOf('week');
				end_date = moment(date).endOf('week');
				this.setState({
					startDate: start_date,
					endDate: end_date
				});

				this.servicesSlots(this.state.serviceId, start_date, end_date, this.state.current_view);

				this.loadEventSlots(this.state.selected_event_resources, start_date, end_date);
				return date;
			case 'week':
				start_date = moment(date).startOf('week');
				end_date = moment(date).endOf('week');
				this.setState({
					startDate: start_date,
					endDate: end_date
				});

				this.servicesSlots(this.state.serviceId, start_date, end_date, this.state.current_view);

				this.loadEventSlots(this.state.selected_event_resources, start_date, end_date);
				return date;
			default:
				return date;
		}
	}

	render() {
		const { servicesSlots, service, bookingQuestions, minCalTime, maxCalTime } = this.props;

		const { startDate, eventId, eventName, type, time_step, endDate } = this.state;

		let service_options = [];
		let options = _.filter(
			_.map(servicesSlots, (item) => {
				if (_.isUndefined(_.find(service_options, { eventid: item.event_id }))) {
					service_options.push({ name: item.name, eventid: item.event_id });
					return { name: item.name, eventid: item.event_id };
				} else {
					return undefined;
				}
			}),
			(o) => !_.isUndefined(o)
		);
			
		return (
			<div className={'container content-wrapper type-' + type}>
				<div className="BookingCalendar">
					{this.props.service && this.state.current_view ? (
						<BookingCalendarCustom
							slots={this.state.selectedEventsSlots}
							serviceDetail={service}
							serviceId={this.state.serviceId}
							eventId={eventId}
							eventName={eventName}
							step={time_step}
							date={startDate}
							endDate={endDate}
							current_view={this.state.current_view}
							blockSelected={() => this.props.blockSlotSelected}
							modalActionEvent={this.modalActionTrigered}
							switchTypeHandler={(e) => this.switchTypeHandler(e)}
							servicesOptions={this.loadServicesOption(options)}
							handleNavigation={this.handleNavigation}
							requestStatus={this.state.request_fail}
							selectedEventResources={this.state.selected_event_resources}
							bookingQuestions={bookingQuestions}
							eventsSlotStatus={this.state.eventslot_processing}
							minCalTime={minCalTime}
							maxCalTime={maxCalTime}
						/>
					) : (
						<div>
							{' '}
							<div className="progress-spinner">
								{' '}
								<i
									className={'fa fa-spinner fa-spin resource-arrow '}
									style={{ fontSize: 24 + 'px' }}
								/>
							</div>{' '}
						</div>
					)}
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		servicesSlots: resourceSelector.getServicesSlots(state),
		selectedServiceSlot: resourceSelector.getSelectedServiceSlot(state),
		service: resourceSelector.getService(state),
		basket: checkoutSelector.getBasket(state),
		basketQueue: resourceSelector.getBasketQueue(state),
		blockSlotSelected: resourceSelector.getBlockSlotSelected(state),
		selectedEventsSlots: resourceSelector.getSelectedEventsSlots(state),
		bookingQuestions: resourceSelector.getBookingQuestions(state),
		resourceEvents: resourceSelector.getResourceEvents(state)
	};
}
export default withRouter(connect(mapStateToProps)(DailyScreen));
