import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import FeatureArea from '../../../components/FeatureArea/FeatureArea';
import FooterNavigation from '../../../components/FooterNavigation/FooterNavigation';
import dateFormat from 'dateformat';

import CustomInputComponent from '../../../components/Input/CustomInput';
import ParcelResultComponent from '../../../components/Parcel/ParcelResultComponent';
import HeaderNavigation from "../../../components/HeaderNavigation/HeaderNavigation";
import ResponseMessage from '../../../components/ResponseMessage/ResponseMessage';
import {withRouter} from 'react-router-dom'
import * as moduleActions from '../../../store/ServiceModules/actions';
import * as moduleSelectors from '../../../store/ServiceModules/reducer';
import * as parcelActions from '../../../store/Parcels/actions';
import * as parcelSelectors from '../../../store/Parcels/reducer';
import * as portalSettingsActions from '../../../store/ConciergeSetting/actions';
import * as portalSettingsSelectors from '../../../store/ConciergeSetting/reducer';
import * as stringHelper from '../../../helpers/stringHelper';

import MultiSelectComponent from '../../../components/MultiSelectComponent/MultiSelectComponent';
import { PortalTypes, ParcelStatuses } from '../../../helpers/configHelper';
import Button from '../../../components/Button/Button';
import { CSSTransitionGroup } from 'react-transition-group';

/**
 * Parcel registration screen
 */

class ParcelManagementScreen extends Component {
	constructor(props) {
		super(props);
		this.state = {
			type: 'parcels',
			portalType: '',
			parcels: [],
			parcelsDue: [],
			parcelsList: [],
			loadMoreLimit: 10,
			defaultLimit: 10,
			search: '',
			filterOptionsSelected: [], //populated on filter options selcted
			selectedOptions: [],
			processing: undefined,
			selectedStatus: []
		};
	}

	componentDidMount() {
		/**
     * exclude control + J on input response, this is needed as scanner append Ctrl + J at end of value scanned
     * https://stackoverflow.com/questions/27960841/usb-barcode-scanner-opens-browsers-downloads-page/27961410
     */

		window.addEventListener('keydown', function(event) {
			if (event.keyCode === 17 || event.keyCode === 74) {
				
				if (event.ctrlKey) event.preventDefault();
			}
		});
	}
  componentDidMount() {
		this.setState({
			processing: true
		});
		this.props.dispatch(moduleActions.loadModules());
		this.props.dispatch(portalSettingsActions.getGlobalSetting());
		this.props.dispatch(parcelActions.getParcelSettigs());
		this.props.dispatch(parcelActions.getStatusCodes());

		this.props.dispatch(
			parcelActions.getAllParcelsonManage((success) => {
				this.setState({
					processing: undefined
				});
			})
		);

		if (this.props.location.state && this.props.location.state.selectedFilterOption) {
			this.setState({
				filterOptionsSelected: [ this.props.location.state.selectedFilterOption ]
			});
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.ManageParcels !== this.props.ManageParcels && this.props.ManageParcels.length > 0) {
			let parcelList = _.filter(
				this.props.ManageParcels,
				(item) => item.status.status !== ParcelStatuses.Expected
			);
			this.setState({
				processing: undefined,
				parcels:
					this.state.filterOptionsSelected.length > 0
						? this.handleFilerUptions(this.state.filterOptionsSelected, parcelList)
						: parcelList,
				parcelsList: parcelList
			});
		}

		// if (this.props.parcelsDue !== nextProps.parcelsDue && nextProps.parcelsDue.length > 0) {
		// 	this.setState({
		// 		parcelsDue: nextProps.parcelsDue
		// 	});
		// }
	}

	componentWillUpdate(nextProps, nextState) {
		if (this.props.portalSettings !== nextProps.portalSettings && !_.isUndefined(nextProps.portalSettings)) {
			this.setState({
				portalType: nextProps.portalSettings.portalType
			});
		}

		if (
			this.props.parcelStatus !== nextProps.parcelStatus &&
			nextProps.parcelStatus &&
			nextProps.parcelStatus.length > 0
		) {
			this.handleResourceChange(nextState.filterOptionsSelected, nextProps.parcelStatus);
		}
	}
	handlePacrelClick(val) {
		const { parcels, parcelsDue } = this.state;
		let parcelList = _.concat(parcels, parcelsDue);
		let selectedParcel = _.find(parcelList, { id: val });

		if (!_.isUndefined(selectedParcel)) {
				this.props.history.push({
				pathname: '/concierge/parcel/info',

				state: {
					parcel: selectedParcel
				}
			});
			window.scrollTo(0, 0);
		}
	}
	// handleResourceChange(val) {

	// 	let selected = _.filter(
	// 		_.map(val, (item) => {
	// 			let s = _.find(this.props.parcelStatus, { id: item });

	// 			if (!_.isUndefined(s)) {
	// 				return { id: s.id, name: s.status };
	// 			}
	// 			return undefined;
	// 		}),
	// 		(o) => !_.isUndefined(o)
	// 	);

	// 	this.setState({
	// 		filterOptionsSelected: val,
	// 		selectedOptions: selected
	// 	});

	// 	let currentParcels =
	// 		val && val.length > 0 ? this.handleFilerUptions(val, this.state.parcelsList) : this.state.parcelsList;

	// 	if (this.state.search) {
	// 		this.updateSearchList(this.state.search, currentParcels);
	// 	} else {
	// 		this.setState({
	// 			parcels: currentParcels
	// 		});
	// 	}
	// }

	handleResourceChange(val, parcelStatusList) {
		const { portalSettings } = this.props;
		let selected = _.filter(
			_.map(val, (item) => {
				let s = _.find(parcelStatusList, { id: item });

				if (!_.isUndefined(s)) {
					return { id: s.id, name: s.status };
				}
				return undefined;
			}),
			(o) => !_.isUndefined(o)
		);

		this.setState({
			filterOptionsSelected: val,
			selectedOptions: selected
		});

		let currentParcels =
			val && val.length > 0 ? this.handleFilerUptions(val, this.state.parcelsList) : this.state.parcelsList;

		if (currentParcels && currentParcels.length > 0) {
			currentParcels = _.orderBy(currentParcels, [ 'dateArrived' ], [ 'desc' ]);
		}

		if (this.state.search) {
			this.updateSearchList(this.state.search, currentParcels);
			this.setState({
				selectedStatus: val
			});
		} else {
			this.setState({
				parcels: currentParcels,
				selectedStatus: val
			});
		}
	}

	resetParcelList() {
		let currentParcels =
			this.state.selectedStatus && this.state.selectedStatus.length > 0
				? this.handleFilerUptions(this.state.selectedStatus, this.state.parcelsList)
				: this.state.parcelsList;

		if (currentParcels && currentParcels.length > 0) {
			currentParcels = _.orderBy(currentParcels, [ 'dateArrived' ], [ 'desc' ]);
		}

		this.setState({
			parcels: currentParcels,
			search: ''
		});
	}

	handleLoadMore(parcels) {
		const parcelslength = parcels.length;
		const currentLimit = this.state.loadMoreLimit;
		if (currentLimit < parcelslength) {
			this.setState({ loadMoreLimit: currentLimit + this.state.defaultLimit });
		}
	}
	handleInputChange(e) {
		const field = e.target.name;
		const value = e.target.value;
		this.setState({
			[field]: value
		});

		if (value === '') {
			this.setState({
				parcels: this.state.parcelsList
			});
		}
	}
	handleSearchPress(e) {
		const { parcelsList, search, filterOptionsSelected } = this.state;

		if (e.key === 'Enter') {
			let parcels =
				this.state.filterOptionsSelected.length > 0
					? this.handleFilerUptions(filterOptionsSelected, parcelsList)
					: parcelsList;

			this.updateSearchList(search, parcels);
		} else if (e.key === 'Clear') {
		} else if (e.key === 'Reset') {
		}
	}

	updateSearchList(search, parcels) {
		const { portalType } = this.state;
		const { portalSettings } = this.props;
		let type = portalType;
		const inputValue = search.trim().toLowerCase();

		const inputParts = inputValue.split(' ').filter((value) => {
			return value !== '';
		});

		let searchData = '';

		let match = [];
		let parcels_ = _.filter(parcels, (item) => {
			if (!_.isUndefined(type) && type === PortalTypes.Residential) {
				return item.resident.firstName !== null || item.resident.lastName !== null;
			} else if (!_.isUndefined(type) && type === PortalTypes.Commercial) {
				return item.tenant.firstName !== null || item.tenant.lastName !== null;
			}
		});
		if (inputParts.length === 1) {
			if (!_.isUndefined(type) && type === PortalTypes.Residential) {
				match = _.filter(parcels_, (o) => {
					return (
						_.startsWith(o.resident.firstName.toLowerCase(), inputParts[0].toLowerCase()) ||
						_.startsWith(o.resident.lastName.toLowerCase(), inputParts[0].toLowerCase()) ||
						(o.resident.addressLine1 &&
							_.includes(o.resident.addressLine1.toLowerCase(), inputParts[0].toLowerCase())) ||
						(o.resident.addressLine2 &&
							_.includes(o.resident.addressLine2.toLowerCase(), inputParts[0].toLowerCase())) ||
						_.startsWith(o.barcode !== null ? o.barcode.toLowerCase() : null, inputParts[0].toLowerCase())
					);
				});
			} else if (!_.isUndefined(type) && type === PortalTypes.Commercial) {
				match = _.filter(parcels_, (o) => {
					return (
						_.startsWith(o.tenant.firstName.toLowerCase(), inputParts[0].toLowerCase()) ||
						_.startsWith(o.tenant.lastName.toLowerCase(), inputParts[0].toLowerCase()) ||
						(o.tenant.companyName &&
							_.startsWith(o.tenant.companyName.toLowerCase(), inputParts[0].toLowerCase())) ||
						_.startsWith(o.barcode !== null ? o.barcode.toLowerCase() : null, inputParts[0].toLowerCase())
					);
				});
			}
		} else if (inputParts.length === 2) {
			searchData = _.trimEnd(_.trimStart(search));

			if (!_.isUndefined(type) && type === PortalTypes.Residential) {
				match = _.filter(parcels_, (o) => {
					return (
						(_.startsWith(o.resident.firstName.toLowerCase(), inputParts[0].toLowerCase()) &&
							_.startsWith(o.resident.lastName.toLowerCase(), inputParts[1].toLowerCase())) ||
						(o.resident.addressLine1 &&
							_.includes(o.resident.addressLine1.toLowerCase(), searchData.toLowerCase())) ||
						_.startsWith(o.barcode !== null ? o.barcode.toLowerCase() : null, searchData)
					);
				});
			} else if (!_.isUndefined(type) && type === PortalTypes.Commercial) {
				match = _.filter(parcels_, (o) => {
					(o.tenant &&
						o.tenant.companyName &&
						_.startsWith(o.tenant.companyName.toLowerCase(), searchData.toLowerCase())) ||
						(_.startsWith(o.tenant.firstName.toLowerCase(), inputParts[0].toLowerCase()) &&
							_.startsWith(o.tenant.lastName.toLowerCase(), inputParts[1].toLowerCase())) ||
						_.startsWith(o.barcode !== null ? o.barcode.toLowerCase() : null, searchData);
				});
			}
		} else if (inputParts.length > 2) {
			let search_ = '';
			for (var i = 2; i < inputParts.length; i++) {
				search_ += inputParts[i] + ' ';
			}

			searchData = _.trimEnd(_.trimStart(search));

			match = _.filter(parcels_, (o) => {
				if (!_.isUndefined(type) && type === PortalTypes.Residential) {
					return (
						(_.startsWith(o.resident.firstName.toLowerCase(), inputParts[0].toLowerCase()) &&
							_.startsWith(o.resident.lastName.toLowerCase(), inputParts[1].toLowerCase()) &&
							(o.resident.addressLine1 &&
								_.includes(o.resident.addressLine1.toLowerCase(), search_.trim().toLowerCase()))) ||
						(o.resident.addressLine1 &&
							_.includes(o.resident.addressLine1.toLowerCase(), searchData.toLowerCase())) ||
						_.startsWith(o.barcode !== null ? o.barcode.toLowerCase() : null, searchData)
					);
				} else if (!_.isUndefined(type) && type === PortalTypes.Commercial) {
					return (
						(_.startsWith(o.tenant.firstName.toLowerCase(), inputParts[0].toLowerCase()) &&
							_.startsWith(o.tenant.lastName.toLowerCase(), inputParts[1].toLowerCase()) &&
							(o.tenant &&
								o.tenant.companyName &&
								_.startsWith(o.tenant.companyName.toLowerCase(), search_.trim().toLowerCase()))) ||
						(o.tenant &&
							o.tenant.companyName &&
							_.startsWith(o.tenant.companyName.toLowerCase(), searchData.toLowerCase())) ||
						_.startsWith(o.barcode !== null ? o.barcode.toLowerCase() : null, searchData)
					);
				}
			});
		}

		if (match && match.length > 0) {
			match = _.orderBy(match, [ 'dateArrived' ], [ 'desc' ]);
		}
		this.setState({
			parcels: match
		});
	}

	handleFilerUptions(options, lists) {
		let currentList = [];

		_.map(options, (statusItem) => {
			_.map(lists, (parcelItem) => {
				if (parcelItem.status.id === statusItem) {
					currentList.push(parcelItem);
				}
			});
		});

		return currentList;
	}

	getstatus(status) {
		switch (status.toLowerCase()) {
			case ParcelStatuses.CheckedIn:
				return 'In Storage';

			case ParcelStatuses.Due:
				return 'Due to be Returned';

			case ParcelStatuses.Collected:
				return 'Collected';

			case ParcelStatuses.Returned:
				return 'Returned';

			case ParcelStatuses.Discarded:
				return 'Discarded';

			default:
				break;
		}
	}

	/**
	 * reformat filter options for display
	 */
	getFilterOptions(data) {
		const statusSortOrder = [ 1, 4, 2, 3, 6 ];

		if (data && data.length > 0) {
			let options = _.filter(
				_.map(data, (item) => {
					if (item.id === 0 || item.id === 5) {
						return undefined;
					} else {
						return { id: item.id, label: this.getstatus(item.status), name: item.status };
					}
				}),
				(o) => !_.isUndefined(o)
			);

			if (options.length > 0 && options.length === statusSortOrder.length) {
				let options_status = _.map(statusSortOrder, (x) => {
					return _.find(options, { id: x });
				});

				options = options_status;
			} else {
				options = [];
			}
			return options;
		} else {
			return [];
		}
	}

	render() {
		const { portalType, parcels, search } = this.state;
		const { parcelStatus } = this.props;
		const currentModule = _.find(this.props.modules, (o) => o.title === 'Parcels');
		const overlayColor = currentModule && currentModule.imageOverlayTextColour;

		let searchPH = '';
		if (portalType) {
			searchPH = portalType === PortalTypes.Residential ? 'or address' : 'or company';
		}
		
		return (
			<div>
				<div className="container type-concierge-desktop ">
					<FeatureArea
						type={this.state.type}
						areaTitle="Parcels"
						areaTagline="Welcome, select a service"
						pageHeader="Manage Parcels"
						overlayColor={currentModule && currentModule.imageOverlayTextColour}
						landing={false}
						activeMenu={true}
					/>
					<div className="main-container parcel-wrapper">
						<div className="row-content">
							<div className="row-item">
								<CustomInputComponent
									type="text"
									name="search"
									label="Search"
									placeHolder={`${'Search by barcode, name '}${searchPH}`}
									value={search}
									handleChange={(e) => this.handleInputChange(e)}
									handleKeyPress={(e) => this.handleSearchPress(e)}
									clearable={true}
									icon={'ion-close-round'}
									resetField={(val) => {
										if (val === true) {
											this.resetParcelList();
											// this.setState({
											// 	parcels: this.state.parcelsList,
											// 	search: ''
											// });
										}
									}}
									disabled={this.state.processing}
								/>
							</div>
							<div className="row-item  manange-parcels-filter">
								<label>Filter</label>
								<MultiSelectComponent
									data={this.getFilterOptions(parcelStatus)}
									handleSelectionChange={(e) => {
										this.handleResourceChange(e, this.props.parcelStatus);
									}}
									selectedOption={
										this.state.selectedOptions.length > 0 ? this.state.selectedOptions : undefined
									}
									//processing={true}
									placeHolder={'Select an option'}
									eventSelectionprocessing={this.state.processing}
								/>
							</div>
						</div>
						<div className="row-content management-message-Container">
							{_.isUndefined(this.state.processing) && (
								<ResponseMessage
									text={
										parcels.length === 0 ? (
											'No matching results were found.'
										) : (
											`${'We found '} ${parcels.length} ${' results for your search'}`
										)
									}
									id="no_results"
									className={parcels.length === 0 ? 'No-results' : 'future-text'}
									type={''}
								/>
							)}
						</div>
						<div className="content-wrapper" />
						{!_.isUndefined(this.state.processing) && this.state.processing === true ? (
							<div>
								{' '}
								<div className="progress-spinner">
									{' '}
									<i
										className={'fa fa-spinner fa-spin resource-arrow '}
										style={{ fontSize: 24 + 'px' }}
									/>
								</div>{' '}
							</div>
						) : (
							portalType &&
							parcels.length > 0 && (
								<div>
									<CSSTransitionGroup
										transitionName="postman"
										transitionEnterTimeout={500}
										transitionLeaveTimeout={300}
									>
										{_.map(parcels.slice(0, this.state.loadMoreLimit), (parcel, index) => {
											return (
												<ParcelResultComponent
													key={index}
													id={parcel.id}
													tenantName={
														parcel.resident.firstName + ' ' + parcel.resident.lastName
													}
													tenantAddress={
														portalType === PortalTypes.Commercial ? (
															parcel.tenant.companyName
														) : (
															stringHelper.formatStringList([
																parcel.resident.addressLine1,
																parcel.resident.addressLine2,
																parcel.resident.addressLine3,
																parcel.resident.addressLine4,
																parcel.resident.addressLine5,
																parcel.resident.addressLine6,
																parcel.resident.postCode
															])
														)
													}
													barcodeId={
														parcel.barcode !== null &&
														parcel.barcode !== '' &&
														!_.isUndefined(parcel.barcode) ? (
															parcel.barcode
														) : (
															'Not Available'
														)
													}
													parcelReceivedDate={
														'Received on ' + dateFormat(parcel.dateArrived, 'dd/mm/yyyy')
													}
													location={
														parcel.location != null ? (
															parcel.location.locationName
														) : (
															'Not Available'
														)
													}
													btnText="View Parcel"
													moduleColor={overlayColor}
													portalType={portalType}
													handleSubmit={() => {}}
													handleItemClick={(item) => this.handlePacrelClick(item)}
													showoverlay={false}
												/>
											);
										})}
									</CSSTransitionGroup>
									<div className="loadbtn">
										{parcels &&
										this.state.loadMoreLimit < parcels.length && (
											<Button
												size="small"
												text="Load More"
												handleClick={() => {
													this.handleLoadMore(parcels);
												}}
												icon=""
											/>
										)}
									</div>
								</div>
							)
						)}
					</div>
					<FooterNavigation />
				</div>
			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		modules: moduleSelectors.loadModules(state),
		ManageParcels: parcelSelectors.getManageParcels(state),
		portalSettings: portalSettingsSelectors.loadGlobalSetting(state),
		parcelSettings: parcelSelectors.getParcelSettings(state),

		parcelStatus: parcelSelectors.getStatusCodes(state)

		//	parcelStatuses: parcelSelectors.getStatusCodes(state)
	};
}

export default withRouter(connect(mapStateToProps, null, null, { forwardRef: true })(ParcelManagementScreen));
