import React, { Component } from "react";
// import { connect } from "react-redux";
// import * as resourceActions from "../../store/Resources/actions";
// import * as resourceSelector from "../../store/Resources/reducer";
import autoBind from "react-autobind";
import _ from 'lodash';
import ReactPaginate from 'react-paginate';

import "../../sass/components/ServiceList/index.css";

var PropTypes = require('prop-types');

class ListItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active: this.props.active,
            eventSelectionprocessing: undefined
            // maxLimit: false
        }

        autoBind(this);
    }


    render() {

        return (
            <a
                onClick={() => {
                    this.setState(prevState => {
                        let newState = !prevState.active;
                        let eventIDs = this.props.event_ids;

                        if (eventIDs.length === 3 && !prevState.active) {
                            return;
                        }
                        else {
                            eventIDs = [...eventIDs, this.props.event_id];
                        }
                        if (prevState.active) {
                            eventIDs = eventIDs.filter(e => e !== this.props.event_id);
                        }

                        // if (this.props.status !== true) { }
                        this.props.handleClick(newState, this.props.value, this.props.event_id);

                        this.props.onClick(eventIDs)

                        return { active: newState }


                    })
                }}

                className={`${this.props.status === true ? "disabled " : ""} ${!this.state.active && !this.props.active ? '' : 'selected'}`}
            >
                {this.props.value}</a>
        )
    }
}



class ServiceListPager extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showList: false,
            value: [],
            event_id: [],
            showSpinner: this.props.services.length === 0 ? true : false,
            showDownList: _.isUndefined(this.props.processing) ? true : undefined,
            iconClass: "fa-angle-down",
            currentPage: 0,
            dataList: [],
            currentList: []
        }

        autoBind(this);

    }


    componentDidUpdate(prevProps, prevState) {
        if (prevProps.services !== this.props.services) {

            let options = _.map(this.props.services, (item, index) => {

                return (
                    { label: item.name, value: item.eventid, key: index, _handleItemClick: this.handleItemClick, _event_ids: this.state.event_id });
            });

            let n = 13;
            let data = _.groupBy(options, dataItem => {

                return Math.floor(dataItem.key / n);
            });
            let dataList = _.toArray(data);
            this.setState({
                dataList: dataList,
                currentList: dataList.length > 0 ? dataList[0] : []
            });
        }

        if (prevProps.selectedOption !== this.props.selectedOption) {
            if (this.props.selectedOption && this.props.selectedOption.length > 0) {
                this.setState({
                    value: _.map(this.props.selectedOption, val => {
                        return val.eventName
                    }),
                    event_id: _.map(this.props.selectedOption, val => {
                        return val.eventId
                    })
                });
            } else if (this.props.selectedOption && this.props.selectedOption.length === 0) {
                this.setState({
                    value: [],
                    event_id: []
                })
            }

        }


    }
    renderValue() {
        let { value } = this.state;
        if (!value.length) return this.props.placeHolder ? this.props.placeHolder : "Select..."
        else return value.length + " selected";
        // return value.join(', ')
    }
    toggleList() {
        let { showList } = this.state;
        if (!showList) {
            this.setState({
                iconClass: "fa-angle-up"
            });
        }
        else {
            this.setState({
                iconClass: "fa-angle-down"
            });
        }
        this.setState(prevState => ({ showList: !prevState.showList }));
    }


    toggleSpiiner() {
        let { showList } = this.state;
        if (showList) {
            //var millisecondsToWait = 500;
            var CurruntContext = this;
            setTimeout(function () {
                // Whatever you want to do after the wait
                CurruntContext.setState(prevState => ({ showUparrow: prevState.showList }))
                // CurruntContext.setState(prevState => ({ showSpinner: false }))
            }, 500);
        }
    }

    handleItemClick(active, val, evntid) {


        let { value, event_id } = this.state;

        if (active && value.length < 3) {
            value = [...value, val];
            if (!_.includes(event_id, (evntid))) {
                event_id = [...event_id, evntid];
            }
        }
        else {
            value = value.filter(e => e !== val);
            event_id = event_id.filter(e => e !== evntid);
        }
        this.setState({ value })
        this.setState({ event_id })

    }

    handlePagerChanged(page) {
        this.setState({
            currentPage: page.selected,
        });

    }

    //helper method to get current page list item
    getCurrentPageList(index) {
        const { dataList } = this.state;

        return dataList[index];
    }
    render() {
        const { handleSelectionChange, selectedOption, processing, eventSelectionprocessing } = this.props;
        const { value } = this.state;

        return (

            <div
                ref={node => this.node = node}
                className="select">

                <button onMouseUp={() => { this.toggleList() }} onClick={() => { /*this.toggleSpiiner()*/ }} className="multiSelectButton">
                    <span className={`${"select_value"} ${value && value.length > 0 ? "" : " Select-placeholder"}`}>
                        {this.renderValue()}
                    </span>

                    {_.isUndefined(processing) ?
                        <i className={`${"fa "}${this.state.iconClass}${" fa-2 resource-arrow"}`}></i>
                        :

                        this.props.services.length === 0 ?
                            <i className={"fa fa-spinner fa-spin resource-arrow " + (this.props.processing !== true && 'hide')} style={{ fontSize: 24 + "px" }}></i> :
                            <i className={`${"fa "}${this.state.iconClass}${" fa-2 resource-arrow"}`}></i>

                    }

                </button>

                <div
                    className={"list"} >

                    <div className={"select_list " + (!this.state.showList ? 'hide' : undefined)}>

                        {
                            this.state.dataList && _.map(this.state.dataList, (optionList, indx) => {

                                return <div key={indx} className={(this.state.currentPage) !== indx ? "hide" : ""} >
                                    {
                                        _.map(optionList, (item, index) => {

                                            return <ListItem value={item.label} key={index}
                                                event_id={item.value}
                                                handleClick={item._handleItemClick}

                                                onClick={(e) => handleSelectionChange(e)
                                                }
                                                event_ids={this.state.event_id}
                                                active={!_.isUndefined(_.find(selectedOption, { eventId: item.value }))}
                                                selectedOption={selectedOption}
                                                status={eventSelectionprocessing}
                                            />
                                        })
                                    }
                                </div>

                            })
                        }

                    </div>

                    <div id="react-paginate" className={(!this.state.showList ? 'hide' : undefined)}>
                        {
                            this.state.dataList.length > 1 && <ReactPaginate
                                pageCount={this.state.dataList.length}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={val => this.handlePagerChanged(val)}
                            />
                        }
                    </div>
                </div>



            </div>
        )
    }
}

ServiceListPager.propTypes = {
    serviceId: PropTypes.string,
    date: PropTypes.string,
    services: PropTypes.array,
    handleSelectionChange: PropTypes.func
};

// function mapStateToProps(state, ownProps) {
//   return {
//     // services: resourceSelector.getServices(state)
//   };
// }
export default ServiceListPager;