import React, { Component } from "react";
import { connect } from "react-redux";
import autobind from "react-autobind";
import _ from "lodash";
import moment from "moment";
import * as $ from "jquery";
import FeatureArea from "../../../components/FeatureArea/FeatureArea";

import ButtonGroup from "../../../components/Button/ButtonGroup";
import InputComponent from "../../../components/Input/Input";
import AddressLookUp from "../../../components/AddrLookUpAutoSuggest/AddrLookUpAutoSuggest";
import * as visitorSelectors from "../../../store/Visitors/reducer";
import * as keysActions from "../../../store/Keys/actions";
import * as keySelectors from "../../../store/Keys/reducer";
import * as addressLookupActions from "../../../store/AddressLookUp/actions";
import * as addressLookupSelectors from "../../../store/AddressLookUp/reducer";
import * as residentActions from "../../../store/Residents/actions";
import * as residentSelectors from "../../../store/Residents/reducer";
import DataList from "../../../components/DetailList/DetailList";
import DataListMulti from "../../../components/DetailList/DetailList5Column";
import ResidentVisitorAutoSuggest from "../../../components/TenantAutoSuggest/ResidentVisitorAutoSuggest";
import "../../../sass/containers/Keys/index.css";
import "../../../sass/components/DateTimePicker/index.css";
const searchOption = {
  Resident: "name",
  Address: "address",
  KeyCode: "barcode"
};

class keysTestScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "keys",
      search: "",
      searchType:
        searchOption.KeyCode /** This would store the search option by (name / address)  default set as name*/,
      validSearch: false,
      keysLog: [],
      keyList: [],
      keLogList: [],
      phoneNumber: "",
      email: "",
      currentDate: moment(new Date()).format("DD/MM/YYYY"),
      currentTime: moment(new Date()).format("HH:mm "),
      expectedReturnDate: "",
      name: "",
      addresslookupId: undefined,
      address: "",
      selectedKey: undefined,
      tenant: "",
      tenantId: "",
      selectedTenant: undefined,
      selectedVisitor: undefined,
      selectedDate: moment(),
      statuses: undefined,
      overdueKeyList: []
    };

    this.props.dispatch(keysActions.getAllKeys());
    this.props.dispatch(keysActions.getKeyStatuses());
    this.props.dispatch(residentActions.getAllResidents());
    this.props.dispatch(addressLookupActions.loadAddresses());
    this.assignKeyValidation = null;
    autobind(this);
  }

  componentDidMount() {
    this.addValidation();
  }

  componentWillUnmount() {
    this.assignKeyValidation.destroy();
  }
  componentWillUpdate(nextProps, nextState) {
    if (
      this.state.selectedVisitor !== nextState.selectedVisitor &&
      !_.isUndefined(nextState.selectedVisitor)
    ) {
      this.setState({
        email: nextState.selectedVisitor.email
      });
    }

    if (
      this.state.selectedTenant !== nextState.selectedTenant &&
      !_.isUndefined(nextState.selectedTenant)
    ) {
      this.setState({
        phoneNumber: nextState.selectedTenant.mobileNumber,
        email: nextState.selectedTenant.email
      });
    }

    if (
      (this.state.selectedTenant !== nextState.selectedTenant &&
        _.isUndefined(nextState.selectedTenant)) ||
      (this.state.selectedVisitor !== nextState.selectedVisitor &&
        _.isUndefined(nextState.selectedVisitor))
    ) {
      this.setState({
        email: "",
        phoneNumber: ""
      });
    }

    // if(this.state.selectedVisitor !== nextState.selectedVisitor && _.isUndefined(nextState.selectedVisitor)){
    //   this.setState({
    //     email: ""
    //   });
    // }
  }

  componentDidUpdate(prevProps, prevState) {
    // if (this.state.selectedKey !== nextState.selectedKey) {
    //   console.log("change triggered");
    //   this.setState({
    //     selectedKey: nextState.selectedKey,
    //     addresslookupId: !_.isUndefined(nextState.selectedKey)
    //       ? nextState.selectedKey.lookupAddressId
    //       : undefined
    //   });
    // }
    // if (this.state.addresslookupId !== nextState.addresslookupId) {
    //   this.setState({
    //     addresslookupId: nextState.addresslookupId
    //   });
    // }

    /**load keys statuses */
    if (
      prevProps.statuses !== this.props.statuses &&
      !_.isUndefined(this.props.statuses)
    ) {
      this.setState({
        statuses: this.props.statuses
      });
    }

    /**find over due keys */
    if (prevProps.keys !== this.props.keys && this.props.keys.length > 0) {
      // let ovKeyList = _.filter(nextProps.keys, o => o.status === "overdue");
      // this.setState({
      //   overdueKeyList: _.filter(
      //     _.map(ovKeyList, o => {
      //       var addr = _.find(
      //         nextProps.addressLookup,
      //         address => address.id === o.lookupAddressId
      //       );
      //       if (!_.isUndefined(addr)) {
      //         let item = {
      //           id: o.id,
      //           status: _.startCase(_.camelCase(o.status)),
      //           address: `${addr.addr2BuildingName} ${","} ${
      //             addr.addr1FlatNumber
      //           }`
      //         };
      //         return item;
      //       }
      //       return null;
      //     }),
      //     val => {
      //       return val !== null;
      //     }
      //   )
      // });
    }

    if (
      prevProps.keysLog !== this.props.keysLog &&
      this.props.keysLog.length > 0
    ) {
      this.setState({
        keysLog: _.filter(this.props.keysLog, o => o !== null)
      });
    }
  }

  handleKeyLogList(data) {
    this.props.dispatch(keysActions.getKeyLogByKeyStatus(data));
  }
  handleChange(e) {
    var field = e.target.name;
    var value = e.target.value;
    this.setState({
      [field]: value
    });
  }

  /**handle selected option, deselected to default option (barcode) */
  handleSearchOption(selected) {
    this.setState({
      searchType:
        this.state.searchType === selected ? searchOption.KeyCode : selected
    });
  }
  handleKeyPressSearch(e) {
    const { search, searchType } = this.state;
    const { addressLookup, keys } = this.props;

    let addr_data = undefined;
    let key_data = [];
    let keyList = [];

    if (e.key === "Enter") {
      if (searchType === searchOption.Address) {
        this.setState({
          validSearch: true
        });

        /**Find address from lookup */
        addr_data = _.find(
          addressLookup,
          address =>
            address.addr1FlatNumber.toLowerCase() === search.toLowerCase()
        );

        /**Match addresslookup id to key addresslookid */
        key_data = !_.isUndefined(addr_data)
          ? _.filter(keys, item => item.lookupAddressId === addr_data.id)
          : [];

        /**for each key record foun, re form data to custom list for display */
        keyList = _.filter(
          _.map(key_data, data => {
            if (data.lookupAddressId === addr_data.id) {
              let key_Addr = {
                id: data.id,
                status: _.startCase(_.camelCase(data.status)),
                address: `${addr_data.addr2BuildingName} ${","} ${
                  addr_data.addr1FlatNumber
                  }`
              };
              return key_Addr;
            }
            return null;
          }),
          item => item !== null
        );
      } else if (searchType === searchOption.Resident) {
        this.setState({
          validSearch: true
        });

        /**Check search data for paired name value */
        var split = search
          .trim()
          .split(" ")
          .filter(value => {
            return value !== "";
          });

        let residents = [];

        /**First name condition used */
        if (split.length === 1) {
          residents = _.filter(
            this.props.residents,
            item =>
              item.firstName.toLowerCase() === split[0].toLowerCase() ||
              item.lastName.toLowerCase() === split[0].toLowerCase()
          );
        } else if (split.length > 1) {
          /**paired name condition. split to first and last name */
          residents = _.filter(
            this.props.residents,
            item =>
              item.firstName.toLowerCase() === split[0].toLowerCase() &&
              item.lastName.toLowerCase() === split[1].toLowerCase()
          );
        }

        /**Filter key records with corresponding resident address lookup id value  */
        key_data = _.filter(keys, keyItem => {
          let k_val = _.find(
            residents,
            r => r.addressLookupId === keyItem.lookupAddressId
          );

          return !_.isUndefined(k_val);
        });

        /**for each key record foun, re form data to custom list for display */
        keyList = _.map(key_data, keyItem => {
          let addr = _.find(
            addressLookup,
            o => o.id === keyItem.lookupAddressId
          );
          if (keyItem.lookupAddressId === addr.id) {
            let key_val = {
              id: keyItem.id,
              status: keyItem.status,
              address: `${addr.addr2BuildingName} ${","} ${
                addr.addr1FlatNumber
                }`
            };

            return key_val;
          }

          return null;
        });
      } else {
        keyList = _.map(_.filter(keys, o => o.barcode === search), item => {
          let addr = _.find(
            addressLookup,
            address => address.id === item.lookupAddressId
          );
          return {
            id: item.id,
            status: item.status,
            address: `${addr.addr2BuildingName} ${","} ${addr.addr1FlatNumber}`
          };
        });
      }
    }

    let logLst = _.map(keyList, o => {
      return { KeyId: o.id };
    });

    if (logLst && logLst.length > 0) this.handleKeyLogList(logLst);

    this.setState({
      keyList: keyList
    });
  }

  handleViewClick(e) {
    let selectKey = _.find(this.props.keys, o => o.id === e);

    this.setState({
      selectedKey: selectKey,
      addresslookupId: !_.isUndefined(selectKey)
        ? selectKey.lookupAddressId
        : undefined
    });
  }

  handleDateChange(val) {
    this.setState({
      selectedDate: val
    });
  }

  addValidation() {
    this.assignKeyValidation = $(this.refs.validate).parsley({
      classHandler: function (el) {
        return el.$element.closest("div:not(.input-wrapper):not(.Select)");
      }
    });
  }
  validate(fromSubmit = false) {
    this.assignKeyValidation.validate({ force: true });
    const valid = this.assignKeyValidation.isValid();

    this.setState({
      assignKeyFormValid: valid,
      assignKeyFormUsed: true
    });

    if (valid === true && fromSubmit === true) {
      this.formReset();

      this.handleAssign();
      this.setState({
        assignKeyFormUsed: false
      });
    }
  }
  formReset() {
    this.setState({
      email: "",
      addresslookupId: "",
      phone: "",
      address: "",
      selectedKey: undefined,
      tenant: "",
      tenantId: "",
      selectedTenant: undefined,
      selectedDate: moment()
    });

    this.refs["tenant-auto-suggest"].getWrappedInstance().reset();
    this.refs["addr-auto-suggest"].getWrappedInstance().reset();

    $(this.refs.validate)
      .get(0)
      .reset();

    this.assignKeyValidation.destroy();

    // Reset all the select input components to the placeholder value
    for (let key in this.refs) {
      if (key.indexOf("formSelect") === 0) {
        this.refs[key].reset();
      }
    }

    setTimeout(() => {
      this.addValidation();
    }, 0);
  }
  handleAssign() {
    const { selectedKey, selectedTenant } = this.state;

    let data;
    if (selectedKey) {
      data = {
        keyId: selectedKey.id,
        memberId: this.state.tenantId,
        expectedDateReturn: this.state.selectedDate,
        recipientType: !_.isUndefined(selectedTenant)
          ? this.state.selectedTenant.memberType
          : !_.isUndefined(this.state.selectedVisitor) ? "Visitor" : "",
        visitorId: !_.isUndefined(this.state.selectedVisitor)
          ? this.state.selectedVisitor.id
          : ""
      };

      this.props.dispatch(keysActions.assignKey(data, success => { }));
    }
  }
  render() {
    const {
      phoneNumber,
      email,
      address,

      tenant,

      currentDate,
      currentTime,
      selectedDate,
      keyList
    } = this.state;

    const { keysLog, keys, addressLookup } = this.props;

    let currentKeyList = _.map(keyList, o => {
      var val = _.find(keysLog, item => item.keyId === o.id);

      if (o.status !== "available" && !_.isUndefined(val)) {
        let recipient = {};

        if (val.recipientType === "Visitor") {
          recipient = {
            name: `${val.visitor.firstName} ${val.visitor.surname}`
          };
        } else {
          recipient = {
            name: `${val.resident.firstName} ${val.resident.lastName}`
          };
        }

        return {
          id: val.keyId,
          status: _.startCase(_.camelCase(o.status)),
          type: val.recipientType,
          name: recipient ? recipient.name : "",
          address: o.address
        };
      }

      return {
        id: o.id,
        status: _.startCase(_.camelCase(o.status)),
        type: " ",
        name: " ",
        address: o.address
      };
    });

    let overdueKeyList = [];
    let ovKeyList = _.filter(keys, o => o.status === "overdue");

    if (ovKeyList.length > 0)
      overdueKeyList = _.filter(
        _.map(ovKeyList, o => {
          var addr = _.find(
            addressLookup,
            address => address.id === o.lookupAddressId
          );
          if (!_.isUndefined(addr)) {
            let item = {
              id: o.id,
              status: _.startCase(_.camelCase(o.status)),
              address: `${addr.addr2BuildingName} ${","} ${
                addr.addr1FlatNumber
                }`
            };

            return item;
          }
          return null;
        }),
        val => {
          return val !== null;
        }
      );

    //console.log(this.props);
    //console.log(this.state);
    return (
      <div>
        <div className={"container"}>
          <FeatureArea
            type={this.state.type}
            areaTagline="Keys Test Screen"
            pageHeader=""
            areaTitle="Keys"
            overlayColor={""}
          />

          {/**Search form */}

          <div className={"content-wrapper type-" + this.state.type}>
            <label>Search</label>
            <input
              type="text"
              name="search"
              onChange={this.handleChange}
              placeholder="Search by name or address"
              onKeyPress={e => this.handleKeyPressSearch(e)}
            />

            <div className="options">
              <div
                id="addressOption"
                className={
                  this.state.searchType === searchOption.Address
                    ? "underLine"
                    : ""
                }
                onClick={e => {
                  this.handleSearchOption(searchOption.Address);
                }}
              >
                Address
              </div>
              <div className="bar" />
              <div
                id="residentOption"
                className={
                  this.state.searchType === searchOption.Resident
                    ? "underLine"
                    : ""
                }
                onClick={e => {
                  this.handleSearchOption(searchOption.Resident);
                }}
              >
                Name
              </div>
            </div>

            {
              <DataListMulti
                data={currentKeyList}
                listType="key-view-list"
                headingsAlone={true}
                actionClick={e => this.handleViewClick(e)}
                emptyMessage="We could not find any keys related to your search"
              />

              /** <DataList
              data={this.state.keyList}
              listType="key-view-list"
              headingsAlone={true}
              actionClick={e => this.handleViewClick(e)}
              emptyMessage="We could not find any keys related to your search"
           />*/
            }

            <h1>Keys Overdue</h1>

            <DataList
              data={overdueKeyList}
              listType="key-view-list"
              headingsAlone={false}
              actionClick={e => {
                console.log("an overdue key has been selected");
              }}
              emptyMessage="There are currently no overdue keys"
            />

            {/** End of search form and list*/}
          </div>
          {/** Key info and assign*/}

          {
            <form
              className="content-wrapper double-margin-top"
              ref="validate"
              onSubmit={e => {
                e.preventDefault();
              }}
              data-parsley-errors-container="#validation-messages"
              data-parsley-focus="none"
            >
              <div className="inputs">
                <ResidentVisitorAutoSuggest
                  ref="tenant-auto-suggest"
                  label="Name"
                  placeHolder="Look up"
                  clearableIfValid={true}
                  value={tenant}
                  //defaultAddressId={this.state.selectedKey.lookupAddressId}
                  onSelected={data => {
                    console.log(data);
                    this.setState({
                      tenantId: data.memberId,
                      tenant: data.tenant,
                      selectedTenant:
                        data.type !== "Visitor"
                          ? _.find(
                            this.props.residents,
                            o => o.id === data.memberId
                          )
                          : undefined,
                      selectedVisitor:
                        data.type === "Visitor"
                          ? _.find(
                            this.props.visitors,
                            x => x.id === data.memberId
                          )
                          : undefined
                    });
                    setTimeout(() => {
                      this.state.assignKeyFormUsed && this.validate();
                    }, 0);
                  }}
                  defaultText={dataVal => { }}
                />
                {/*<TenentAutoSelect
                  ref="tenant-auto-suggest"
                  label="Name"
                  placeHolder="Look up"
                  clearableIfValid={true}
                  value={tenant}
                  //defaultAddressId={this.state.selectedKey.lookupAddressId}
                  onSelected={data => {
                    this.setState({
                      tenantId: data.memberId,
                      tenant: data.tenant,
                      selectedTenant: _.find(
                        this.props.residents,
                        o => o.id === data.memberId
                      )
                    });
                    setTimeout(() => {
                      this.state.assignKeyFormUsed && this.validate();
                    }, 0);
                  }}
                  defaultText={dataVal => {}}
                />*/}
              </div>
              <div className="inputs two-column">
                <InputComponent
                  label="Phone Number"
                  name="phoneNumber"
                  placeHolder="phone number"
                  handleChange={e => {
                    this.handleChange(e);
                  }}
                  value={phoneNumber}
                  parsley={{
                    "data-parsley-type": "digits",
                    "data-parsley-minlength": 11,
                    "data-parsley-error-message":
                      "Please enter a valid phone number"
                  }}
                />
                <InputComponent
                  label="Email"
                  name="email"
                  placeHolder="email"
                  handleChange={e => {
                    this.handleChange(e);
                  }}
                  value={email}
                  parsley={{
                    "data-parsley-type": "email",
                    "data-parsley-validate-if-empty": false,
                    "data-parsley-error-message": "Please enter a valid email"
                  }}
                />
              </div>
              <div className="inputs">
                <AddressLookUp
                  ref="addr-auto-suggest"
                  label="Key Address"
                  placeHolder="Search Address"
                  clearableIfValid={false}
                  value={address}
                  defaultAddressId={
                    !_.isUndefined(this.state.selectedKey)
                      ? this.state.selectedKey.lookupAddressId
                      : undefined
                  }
                  onSelected={data => {
                    this.setState({
                      addresslookupId: data.addresslookupId,
                      address: data.address
                    });
                    setTimeout(() => {
                      this.state.assignKeyFormUsed && this.validate();
                    }, 0);
                  }}
                  defaultText={dataVal => { }}
                />
              </div>
              <div className="inputs two-column">
                <div className="inputs two-column">
                  <InputComponent
                    label="Date"
                    value={currentDate}
                    handleChange={e => this.handleChange(e)}
                  />
                  <InputComponent
                    label="Time"
                    value={currentTime}
                    handleChange={e => this.handleChange(e)}
                  />
                </div>

                <InputComponent
                  type="datePicker"
                  label="Expected Return"
                  name="selectedDate"
                  defaultMin={moment()}
                  defaultSelect={selectedDate}
                  handleDateChange={this.handleDateChange}
                />
              </div>
              <div
                className={
                  "validation-messages" +
                  (this.state.assignKeyFormValid ? " valid" : " invalid")
                }
                id="validation-messages"
              >
                <div>
                  {this.props.keyActionStatus &&
                    !_.isUndefined(this.props.keyActionStatus.action) &&
                    (this.props.keyActionStatus.action === true ? (
                      <p className="message type-success">Key Assigned</p>
                    ) : (
                        this.state.error && (
                          <p className="message error"> {this.state.error}</p>
                        )
                      ))}
                </div>
              </div>
              <div className="align-center double-margin-top">
                <ButtonGroup
                  sizes={["smedium", "medium"]}
                  leftText="Cancel"
                  rightText="Assign Key"
                  //rightDisabled={this.state.addResidentFormValid === false}
                  leftClick={() => { }}
                  rightClick={e => {
                    this.validate(true);
                  }}
                />
              </div>
            </form>
          }

          {/**End of key assign section */}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    keys: keySelectors.loadAllKeys(state),
    addressLookup: addressLookupSelectors.getAddresses(state),
    residents: residentSelectors.getResidents(state),
    keyActionStatus: keySelectors.getKeyActionStatus(state),
    assignedKey: keySelectors.assignedKey(state),
    statuses: keySelectors.loadkeyStatuses(state),
    keysLog: keySelectors.loadKeysLog(state),
    visitors: visitorSelectors.getVisitors(state)
  };
}

export default connect(mapStateToProps, null, null, { forwardRef: true })(
  keysTestScreen
);
