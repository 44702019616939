export const VISITORS_LOADED_SUCCESS = 'VISITORS_LOADED_SUCCESS';
export const VISITORS_LOADED_FAIL = 'VISITORS_LOADED_FAIL';

export const VISITOR_RECORD_LOADED_SUCCESS = 'VISITOR_RECORD_LOADED_SUCCESS';
export const VISITOR_SELECTED = 'VISITOR_SELECTED';
export const VISITOR_MEETING_LOADED_SUCCESS = 'VISITOR_MEETING_LOADED_SUCCESS';
export const VISITOR_SELF_CHECKIN_CANCELED = 'VISITOR_CHECKIN_CANCELED';
export const VISITOR_SELF_CHECKIN_SUCCESS = 'VISITOR_SELF_CHECKIN_SUCCESS';
export const UPDATE_CHECKOUT_STATUS_SUCCESS = 'VISITOR_SELF_CHECKOUT_SUCCESS';
export const VISITORS_RECORDS_LOADED_SUCCESS = 'VISITORS_RECORDS_LOADED_SUCCESS';
export const VISITOR_CHECKIN_SUCCESS = 'VISITOR_CHECKIN_SUCCESS';
export const VISITOR_CHECKOUT_SUCCESS = 'VISITOR_CHECKOUT_SUCCESS';
export const VISITOR_CHECKIN_FAIL = 'VISITOR_CHECKIN_FAIL';
export const VISITOR_CHECKOUT_FAIL = 'VISITOR_CHECKOUT_FAIL';
export const VISITOR_RECORD_LOADED_FAIL = 'VISITOR_RECORD_LOADED_FAIL';
export const VISITOR_MEETING_LOADED_FAIL = 'VISITOR_MEETING_LOADED_FAIL';
export const VISITORS_RECORDS_LOADED_FAIL = 'VISITORS_RECORDS_LOADED_FAIL';
export const VISITOR_SEARCH_DATA = 'VISITOR_SEARCH_DATA';
export const VISITOR_EVENT = 'VISITOR_EVENT';
export const CANCEL_MEETING_LOAD = 'CANCEL_MEETING_LOAD';
export const VISITOR_CAR_REG_UPDATED = 'VISITOR_CAR_REG_UPDATED';
export const VISITOR_CAR_REG_SET = 'VISITOR_CAR_REG_SET';
export const VISITOR_SPECIAL_INSTRUCTIONS = 'VISITOR_SPECIAL_INSTRUCTIONS';
export const VISITOR_SEARCH_STORED_SUCCESSFULLY = 'VISITOR_SEARCH_STORED_SUCCESSFULLY';
export const VISITOR_SEARCH_FROM_REDIRECT = 'VISITOR_SEARCH_FROM_REDIRECT';
export const VISITOR_CHECKEDIN_RESET_SUCCESSFULLY = 'VISITOR_CHECKEDIN_RESET_SUCCESSFULLY';
