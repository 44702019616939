import * as config from "./config";
import authService from "./loginService";

class MeetingServices {
  async getMeetingById(id) {
    const url = `${config.MEETING_RECORD_ENDPOINT}`;

    var requestHeader = new Headers();

    const response = await fetch(url + "?id=" + id, {
      method: "GET",
      headers: requestHeader
    });

    if (!response.ok) {
      config.errorPageRedirect(response);
      throw new Error(
        `getMeetingById service failed, HTTP status ${response.status}`
      );
    }

    const result = await response.json();

    return result;
  }

  async getMeetingsById(data) {
    const url = `${config.MEETING_RECORD_ENDPOINT}`;

    const authToken = authService.getToken();
    
    var requestHeader = new Headers();
    requestHeader.append("Authorization", "Bearer " + authToken);
    requestHeader.append("Accept", "application/json");
    requestHeader.append("Content-Type", "application/json");

    const response = await fetch(url, {
      method: "POST",
      headers: requestHeader,
      body: JSON.stringify(data)
    });

    if (!response.ok) {
      config.errorPageRedirect(response);
      throw new Error(
        `getMeetingsById service failed, HTTP status ${response.status}`
      );
    }

    const result = await response.json();

    return result;
  }

  async updateVisitorStatus(data) {
    const url = `${config.MEETING_RECORD_ENDPOINT}`;

    var requestHeader = new Headers();
    requestHeader.append("Accept", "application/json");
    requestHeader.append("Content-Type", "application/json");

    const response = await fetch(url, {
      method: "POST",
      headers: requestHeader,
      body: JSON.stringify(data)
    });

    if (!response.ok) {
      config.errorPageRedirect(response);
      throw new Error(
        `getMeetingById service failed, HTTP status ${response.status}`
      );
    }

    const result = await response.json();

    return result;
  }

  async getTenants() {
    const url = `${config.TENANTS_ENDPOINT}`;
    const memberType = "tenants";
    var requestHeader = new Headers();

    const response = await fetch(url + "?type=" + memberType, {
      method: "GET",
      headers: requestHeader
    });

    if (!response.ok) {
      config.errorPageRedirect(response);
      throw new Error(
        `getTenants service failed, HTTP status ${response.status}`
      );
    }

    const result = await response.json();

    return result;
  }

  async checkinVisitor(data) {
    const url = `${config.CHECKIN_VISITOR_ENPOINT}`;

    var requestHeader = new Headers();
    requestHeader.append("Accept", "application/json");
    requestHeader.append("Content-Type", "application/json");

    const response = await fetch(url, {
      method: "POST",
      headers: requestHeader,
      body: JSON.stringify(data)
    });

    if (!response.ok) {
      config.errorPageRedirect(response);
      throw new Error(
        `checkinVisitor service failed, HTTP status ${response.status}`
      );
    }

    const result = await response.json();

    return result;
  }

  async checkOutVisitor(data) {
    const url = `${config.CHECKOUT_VISITOR_ENPOINT}`;

    var requestHeader = new Headers();
    requestHeader.append("Accept", "application/json");
    requestHeader.append("Content-Type", "application/json");

    const response = await fetch(url, {
      method: "POST",
      headers: requestHeader,
      body: JSON.stringify(data)
    });

    if (!response.ok) {
      config.errorPageRedirect(response);
      throw new Error(
        `checkOutVisitor service failed, HTTP status ${response.status}`
      );
    }

    const result = await response.json();

    return result;
  }

  async createMeeting(data) {
    const url = `${config.CREATE_NEW_MEETING_ENDPOINT}`;

    var requestHeader = new Headers();
    requestHeader.append("Accept", "application/json");
    requestHeader.append("Content-Type", "application/json");

    const response = await fetch(url, {
      method: "POST",
      headers: requestHeader,
      body: JSON.stringify(data)
    });

    if (!response.ok) {
      config.errorPageRedirect(response);
      throw new Error(
        `createMeeting service failed, HTTP status ${response.status}`
      );
    }

    const result = await response.json();
    console.log(result);
    return result;
  }

  async createMeetings(data) {
    const url = `${config.CREATE_NEW_MEETINGS_ENDPOINT}`;

    var requestHeader = new Headers();
    requestHeader.append("Accept", "application/json");
    requestHeader.append("Content-Type", "application/json");

    const response = await fetch(url, {
      method: "POST",
      headers: requestHeader,
      body: JSON.stringify(data)
    });

    if (!response.ok) {
      config.errorPageRedirect(response);
      throw new Error(
        `createMeeting service failed, HTTP status ${response.status}`
      );
    }

    const result = await response.json();

    return result;
  }

  async getMeetingsByDate(date) {
    const url = `${config.MEETING_RECORD_ENDPOINT}`;
    const authToken = authService.getToken();

    var requestHeader = new Headers();
    requestHeader.append("Authorization", "Bearer " + authToken);

    const response = await fetch(url + "?date=" + date, {
      method: "GET",
      headers: requestHeader
    });

    if (!response.ok) {
      config.errorPageRedirect(response);
      throw new Error(
        `getMeetingsByDate service failed, HTTP status ${response.status}`
      );
    }

    const result = await response.json();

    return result;
  }

  async updateMeetingRecord(data) {
    const url = config.UPDATE_MEETING_RECORD_ENDPOINT;
    var requestHeader = new Headers();
    requestHeader.append("Accept", "application/json");
    requestHeader.append("Content-Type", "application/json");

    const response = await fetch(url, {
      method: "POST",
      headers: requestHeader,
      body: JSON.stringify(data)
    });

    if (!response.ok) {
      //config.errorPageRedirect(response);
      throw new Error(
        `updateMeetingRecord service failed, HTTP status ${response.status}`
      );
    }

    const result = await response.json();

    return result;

  }
  async resourceBookingNotification(booking) {
    const url = `${config.RESOURCE_BOOKING_NOTIFY_ENDPOINT}`;

    var requestHeader = new Headers();
    requestHeader.append("Accept", "application/json");
    requestHeader.append("Content-Type", "application/json");

    const response = await fetch(url, {
      method: "POST",
      headers: requestHeader,
      body: JSON.stringify(booking)
    });

    if (!response.ok) {
      //config.errorPageRedirect(response);
      throw new Error(
        `resourceBookingNotification service failed, HTTP status ${
        response.status
        }`
      );
    }

    const result = await response.json();

    return result;
  }

  async passCollectedById(id, collected = true) {
    const url = `${config.VISITOR_PASS_COLLECTED}`;

    var requestHeader = new Headers();
    requestHeader.append("Accept", "application/json");
    requestHeader.append("Content-Type", "application/json");

    const response = await fetch(url, {
      //  + "?id=" + id
      method: "POST",
      headers: requestHeader,
      body: JSON.stringify({ id: id, passCollected: collected })
    });

    if (!response.ok) {
      config.errorPageRedirect(response);
      throw new Error(
        `passCollectedVisitorById service failed, HTTP status ${
        response.status
        }`
      );
    }

    const result = await response.json();

    return result;
  }

  async notifiCheckedIn(meetings, collected = true) {
    const url = `${config.NOTIFY_CHECKING_ENPOINT}`;

    var requestHeader = new Headers();
    requestHeader.append("Accept", "application/json");
    requestHeader.append("Content-Type", "application/json");

    const response = await fetch(url, {
      method: "POST",
      headers: requestHeader,
      body: JSON.stringify(meetings)
    });

    if (!response.ok) {
      //config.errorPageRedirect(response);
      throw new Error(
        ` notifiCheckedIn service failed, HTTP status ${response.status}`
      );
    }

    const result = await response.json();

    return result;
  }

  async resourceBookingCancelledNotification(booking) {
    const url = `${config.RESOURCE_BOOKING_CANCELLED_NOTIFY_ENDPOINT}`;

    var requestHeader = new Headers();
    requestHeader.append("Accept", "application/json");
    requestHeader.append("Content-Type", "application/json");

    const response = await fetch(url, {
      method: "POST",
      headers: requestHeader,
      body: JSON.stringify(booking)
    });

    if (!response.ok) {
      config.errorPageRedirect(response);
      throw new Error(
        `resourceBookingNotification service failed, HTTP status ${
        response.status
        }`
      );
    }

    const result = await response.json();

    return result;
  }
  async resourceBookingUpdatedNotification(booking) {
    const url = `${config.RESOURCE_BOOKING_UPDATED_NOTIFY_ENDPOINT}`;

    var requestHeader = new Headers();
    requestHeader.append("Accept", "application/json");
    requestHeader.append("Content-Type", "application/json");

    const response = await fetch(url, {
      method: "POST",
      headers: requestHeader,
      body: JSON.stringify(booking)
    });

    if (!response.ok) {
      config.errorPageRedirect(response);
      throw new Error(
        `resourceBookingNotification service failed, HTTP status ${
        response.status
        }`
      );
    }

    const result = await response.json();

    return result;
  }

  async tenantResourceBookingNotification(booking) {
    const url = `${config.NOTIFY_TENANT_RESOURCE_BOOKED}`;

    var requestHeader = new Headers();
    requestHeader.append("Accept", "application/json");
    requestHeader.append("Content-Type", "application/json");

    const response = await fetch(url, {
      method: "POST",
      headers: requestHeader,
      body: JSON.stringify(booking)
    });

    if (!response.ok) {
      //config.errorPageRedirect(response);
      throw new Error(
        `tenantresourceBookingNotification service failed, HTTP status ${
        response.status
        }`
      );
    }

    const result = await response.json();

    return result;
  }

  async houseManagerResourceBookingNotification(booking) {
    const url = `${config.NOTIFY_HOUSE_MANAGER_RESOURCE_BOOKED}`;

    var requestHeader = new Headers();
    requestHeader.append("Accept", "application/json");
    requestHeader.append("Content-Type", "application/json");

    const response = await fetch(url, {
      method: "POST",
      headers: requestHeader,
      body: JSON.stringify(booking)
    });

    if (!response.ok) {
      //config.errorPageRedirect(response);
      throw new Error(
        `houseManagerResourceBookingNotification service failed, HTTP status ${
        response.status
        }`
      );
    }

    const result = await response.json();

    return result;
  }

  async financeManagerResourceBookingNotification(booking) {
    const url = `${config.NOTIFY_RAISECHARGE_FINANCE_MANAGER_RESOURCE_BOOKED}`;

    var requestHeader = new Headers();
    requestHeader.append("Accept", "application/json");
    requestHeader.append("Content-Type", "application/json");

    const response = await fetch(url, {
      method: "POST",
      headers: requestHeader,
      body: JSON.stringify(booking)
    });

    if (!response.ok) {
      //config.errorPageRedirect(response);
      throw new Error(
        `financeManagerResourceBookingNotification service failed, HTTP status ${
        response.status
        }`
      );
    }

    const result = await response.json();

    return result;
  }


}

export default new MeetingServices();
