import React, { Component } from "react";

import "../../sass/components/ToggleButton/index.css";

var PropTypes = require('prop-types');


/**
 * Switch toggle button 
 */
class ToggleButton extends Component {

    render() {
        return (
            <div className={this.props.active === true ? "switch-wrapper active" : "switch-wrapper"} >
                <div className="switch" onClick={this.props.handleClick}>
                    <input type="checkbox" name="switchToggle" onChange={() => { }} checked={this.props.active} />
                    <div className="slider round">
                        <div className="slider-text">
                            <span>Yes</span>
                            <span>No</span>
                        </div>
                    </div>
                </div>
            </ div>
        );
    }
}
ToggleButton.propTypes = {
    /**Value holds current checked status of button, true / false */
    active: PropTypes.bool.isRequired,

    /**Function to return switch status */
    handleClick: PropTypes.func.isRequired
};

export default ToggleButton;